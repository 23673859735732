import React from 'react';

import { ActionButton } from '@kwara/components/src/Button';

type Props = {
  saccoName: string,
  onClick?: () => void
};

export function Navigation({ saccoName = ' ', onClick }: Props) {
  return (
    <div className="pt3 w-100">
      {onClick && <ActionButton onClick={onClick} hideBorder col="black" type={'backMobile'} />}
      <div className="w-100 tc fl mobile-text-medium">
        <p>{saccoName}</p>
      </div>
    </div>
  );
}
