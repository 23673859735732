import React from 'react';

import { FOUR_DIGIT_PIN } from '@kwara/components/src/Form';
import { ERRORS } from '@kwara/lib/src/validator';

import { PINInputs } from './PINInputs';

import auth from '../../../lib/auth';

export function CreatePIN({ StackChild }) {
  return (
    <StackChild>
      <div className="pv4">
        <PINInputs length={FOUR_DIGIT_PIN} />
      </div>
    </StackChild>
  );
}

CreatePIN.validate = {
  pin1: {
    isRequired: () => true,
    minlength: FOUR_DIGIT_PIN
  },
  pin2: {
    isRequired: () => true,
    minlength: FOUR_DIGIT_PIN,
    custom: (target, allData) => {
      const { pin1 } = allData;
      if (pin1 !== target) {
        return ERRORS.pinMismatch;
      }
      return null;
    }
  }
};

export type Data = {
  phone: string;
  newPin: string;
  id_type: string;
  id_number: string;
  device_token: string;
  referralCode: string;
  [key: string]: any;
};

CreatePIN.createMember = (data: Data) => {
  const { pin1: pin, id_type, id_number, device_token, phone: phone_number, referralCode: referral_code } = data;

  const payload = {
    data: {
      attributes: {
        phone_number,
        pin,
        id_type,
        id_number,
        device_token,
        referral_code
      }
    }
  };

  return auth.createAndActivateMember(payload).catch(({ errors }) => {
    throw errors;
  });
};
