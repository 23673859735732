// @flow
import * as React from 'react';
import min from 'lodash/fp/min';
import ceil from 'lodash/fp/ceil';

import { type SubStepComponentProps } from '@kwara/components/src/Wizard/deprecated/Wizard';
import { Text, Currency } from '@kwara/components/src/Intl';
import { segmentTrack } from '@kwara/components/src/Segment';

function findLoanMaximun(maximumAmountPerTransaction: number, totalBalance: number): ?number {
  return min([Number(maximumAmountPerTransaction), ceil(Number(totalBalance))]);
}

export const RepaymentAmount = ({ StackChild, TextField, data }: SubStepComponentProps<{}>) => {
  const { mpesaLimit, loan } = data;

  const { maximumAmountPerTransaction } = mpesaLimit;
  const { totalBalance } = loan;

  const loanMaximum = findLoanMaximun(maximumAmountPerTransaction, totalBalance);

  React.useEffect(() => {
    segmentTrack('Loan Repayment Amount page loaded');
  }, []);

  return (
    <StackChild>
      <TextField
        required
        name="amount"
        type="number"
        isCurrency
        labelId="LoanRepayment.RepaymentAmount.amount.label"
        sensitiveValidation
        leftGlyph="Currency.orgCurrency"
        inputMode="decimal"
        pattern="\\d*"
        info={
          loanMaximum && (
            <div className="flex flex-column">
              <div>
                <Text
                  id={'LoanRepayment.RepaymentAmount.amount.info.maximum'}
                  values={{
                    maximum: <Currency format="currency" value={loanMaximum} />
                  }}
                />
              </div>
            </div>
          )
        }
      />
    </StackChild>
  );
};

export const validateConfig = {
  amount: {
    isRequired: () => true,
    currency: true,
    nonZero: true,
    custom: (target, { loan, mpesaLimit }) => {
      const { minimumAmountPerTransaction, maximumAmountPerTransaction } = mpesaLimit;
      const { totalBalance } = loan;

      const loanMaximum = findLoanMaximun(maximumAmountPerTransaction, totalBalance);

      if (Number(target) > Number(loanMaximum)) {
        return 'rangeOverflow';
      }

      if (Number(target) < Number(minimumAmountPerTransaction)) {
        return 'rangeUnderflow';
      }
      return null;
    }
  }
};

RepaymentAmount.validateConfig = validateConfig;
