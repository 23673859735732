import axios from 'axios';

import { attr, hasOne, belongsTo } from 'spraypaint';

import Base, { BaseModel } from './Base';

type ReviewData = {
  event: 'approve' | 'reject';
  checkable_item_ids: string[];
  notes?: string;
};

const checkable_item_ids = 'checkable_item_ids';
const CheckableItemStates = Object.freeze({
  pending: 'pending_review',
  approved: 'approved',
  failed: 'failed',
  rejected: 'rejected',
  processing: 'processing'
});

function jsonapiData(data: ReviewData) {
  return { data: { attributes: data } };
}

const CheckableItem = Base.extend({
  static: {
    jsonapiType: 'checkable_items',
    endpoint: '/checkable/items',

    review(data: ReviewData) {
      const url = CheckableItem.url();
      const opts = CheckableItem.fetchOptions();

      return axios.put(url, jsonapiData(data), opts);
    }
  },
  attrs: {
    state: attr(),
    checkable: hasOne(),
    createdBy: belongsTo(),
    reviewedBy: belongsTo()
  },
  methods: {
    isPending() {
      return this.state === CheckableItemStates.pending;
    }
  }
});

CheckableItem.states = CheckableItemStates;
CheckableItem.journalEntries = CheckableItem.where({
  checkable_type: 'checkable_journal_entry'
}).includes('checkable');

export { CheckableItem, CheckableItemStates, checkable_item_ids };

export interface CheckableItemT extends BaseModel<CheckableItemT> {
  state: string;
  checkable: boolean;
  createdBy: string;
  reviewedBy: string;
  isPending(): boolean;
}
