import React from 'react';

import { useParams, useHistory } from 'react-router-dom';

import Wizard, { getSubstepFromMatchParams } from '@kwara/components/src/Wizard/deprecated/Wizard';

import { steps } from './config';
import { useSubmit } from './useSubmit';
import { useNewUserStore } from '../../models/NewUserStore';

type ParamsType = {
  step: string;
  subStep: string;
};

export function CancelMembershipApplication() {
  const history = useHistory();
  const params = useParams<ParamsType>();
  const store = useNewUserStore();
  const submit = useSubmit();

  return (
    <Wizard
      steps={steps}
      history={history}
      startId="identity"
      cancelReturnsTo="/"
      baseUrl="/cancelMembershipApplication"
      analyticsId="CancelMembershipApplication"
      titleId="SecureFourPinUpdate.EnterOTP.title"
      onSubmit={submit}
      currentStep={params.step}
      currentSubStep={getSubstepFromMatchParams(params)}
      initialData={{ phone: store.storePhone }}
    />
  );
}
