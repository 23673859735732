import * as React from 'react';
import { Field as FinalFormField } from 'react-final-form';
import noop from 'lodash/fp/noop';

type ConditionProps = {|
  when: string,
  children: React.Node,
  [conditionClause: 'is' | 'not' | 'evalCondition']: string | ((v: string) => boolean)
|};

export const Condition = ({ when, is, not, children, evalCondition = noop }: ConditionProps) => (
  <FinalFormField name={when} subscription={{ value: true }}>
    {({ input }) => {
      const { value } = input;
      const passes = (is != null && value === is) || (not != null && value !== not) || evalCondition(value);

      if (!passes) {
        return null;
      }

      if (typeof children === 'function') {
        return children({ input });
      }

      return children;
    }}
  </FinalFormField>
);
