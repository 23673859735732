import React from 'react';

import { Route, Redirect, RouteProps } from 'react-router-dom';

import { ROUTES } from './routes';

interface ProtectedRoutePropTypes extends RouteProps {
  isPermitted: boolean;
  fallback?: string;
}

export function ProtectedRoute({ isPermitted, fallback, ...routeProps }: ProtectedRoutePropTypes) {
  if (isPermitted) return <Route {...routeProps} />;

  return <Redirect to={{ pathname: fallback || ROUTES.home, state: { from: routeProps.location } }} />;
}
