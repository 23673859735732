import * as React from 'react';

import { Completion } from '@kwara/components/src/Completion';
import { Text } from '@kwara/components/src/Intl';

type LoanCompletionProps = {
  onConfirm: () => void;
  hasGuarantors: boolean;
};

export const LoanCompletion = ({ onConfirm, hasGuarantors }: LoanCompletionProps) => {
  return (
    <Completion
      onConfirm={onConfirm}
      titleId="LoanAdd.Completion.Success"
      subtitle={<Text id={`LoanAdd.Completion.${hasGuarantors ? 'WithGuarantors' : 'NoGuarantors'}`} />}
      type="good"
      buttonTextId="LoanAdd.Completion.Ok"
    />
  );
};
