// @flow

import some from 'lodash/fp/some';
import filter from 'lodash/fp/filter';
import concat from 'lodash/fp/concat';
import getOr from 'lodash/fp/getOr';
import keyBy from 'lodash/fp/keyBy';
import size from 'lodash/fp/size';
import intersection from 'lodash/fp/intersection';
import find from 'lodash/fp/find';
import remove from 'lodash/remove';
import toUpper from 'lodash/fp/toUpper';
import pipe from 'lodash/fp/pipe';

import { getCurrentDate } from '@kwara/lib/src/dates';
import { Logger } from '@kwara/lib/src/logger';
import {
  Attachments,
  AttachmentT,
  AttachmentContext,
  AttachmentMeta,
  attachmentsMeta,
  v1AttachmentNamesMapping
} from '@kwara/models/src/models/Attachment';
import { toBase64 } from '@kwara/components/src/UploadWidgetUI';
import { mimeTypesMaps, FileType } from '@kwara/models/src';

export const mimeTypeToExtension = mimeType => getOr('UNKNOWN', mimeType, mimeTypesMaps);

export type FileNameT = AttachmentMeta['name'] | 'profile';
type ConvertReturnee = {
  content: string;
  name: FileNameT;
  id: string;
  type: FileType;
};

// Return a list of document types that match the visibility specified in "contexts"
// You can pass either a string or an array of strings
// AND logic
const typesByContext = (contexts: AttachmentContext | AttachmentContext[], nameToIgnore?: string) => {
  const contextsList = concat([], contexts);
  return filter<AttachmentMeta>(
    (o: AttachmentMeta) => size(intersection(o.contexts, contextsList)) > 0 && o.name !== nameToIgnore,
    attachmentsMeta
  );
};

// Filter a list of attachments based on its context(s)
const fileNamesDictionary = keyBy('name', attachmentsMeta);
const filterByContext = (contexts: AttachmentContext | AttachmentContext[], attachments: AttachmentT[]) => {
  const contextsList = concat([], contexts);
  return filter<AttachmentT>((o: AttachmentT) => {
    const meta = getOr({} as AttachmentMeta, o.name, fileNamesDictionary);
    return size(intersection(meta.contexts, contextsList)) > 0;
  }, attachments);
};

export const allowedAttachments = {
  list: attachmentsMeta,
  typesByContext,
  filterByContext
};

// Form helpers to add/remove/check attachments on forms
export function addAttachment(data: { [k: string]: any; attachments: AttachmentT[] }, value: AttachmentT) {
  // We need to mutate the instance here to trigger Spraypaint change detection :(
  const att = new Attachments(value);

  if (!data.attachments || !Array.isArray(data.attachments)) {
    data.attachments = [];
  }

  data.attachments.push(att);

  return data;
}

export function removeAttachment(data: { [k: string]: any; attachments: AttachmentT[] }, fileName: string) {
  // We need to mutate array here to trigger Spraypaint change detection :(
  remove(data.attachments, e => e.name === fileName);
  return data;
}

export function isDirty(attachments: AttachmentT[] = [], fileName: string) {
  return some(att => att.name === fileName, attachments);
}

const fallback = {};
export const findProfilePicture = (attachments: AttachmentT[]) =>
  find(attachment => attachment.name === 'profile', attachments) || fallback;

export async function convert(
  evt: React.SyntheticEvent<HTMLInputElement>,
  fileName: FileNameT
): Promise<ConvertReturnee | {}> {
  const [file] = (evt.target as HTMLInputElement).files;

  try {
    const content = await toBase64(file);
    return new Attachments({
      content,
      name: fileName,

      // legacy
      // filename is unique per user so we can use it as a temp ID. Having a temp ID makes it easier to handle viewing usaved docs with <Viewer />
      // This temp id will be discarded when saving as the server will use its own ID logic to assign it.
      id: `temp-id-${fileName}-${getCurrentDate().toISOString()}`,
      type: pipe(mimeTypeToExtension, toUpper)(file?.type),

      // v1
      file,
      v1Name: v1AttachmentNamesMapping.get(fileName)
    });
  } catch (e) {
    Logger.error('Error at UploadWidget > convert', JSON.stringify(e));
    return {};
  }
}
