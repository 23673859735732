import { BuyingFor } from './components/BuyingFor';
import { PaymentOption } from './components/PaymentOption';

export const analyticsId = 'PurchaseAirtime';

export const steps = {
  buyingFor: {
    titleId: 'Airtime.buyingFor.title',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'paymentOption'
      }
    ],
    children: [
      {
        titleId: 'Airtime.buyingFor.title',
        subtitleId: 'Airtime.buyingFor.subtitle',
        Component: BuyingFor,
        validate: BuyingFor.validateConfig
      }
    ]
  },
  paymentOption: {
    titleId: 'Airtime.paymentOption.title',
    actions: [
      {
        appearsAs: 'buy',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'Airtime.paymentOption.title',
        subtitleId: 'Airtime.paymentOption.subtitle',
        Component: PaymentOption,
        validate: PaymentOption.validateConfig
      }
    ]
  }
};
