// @flow

import * as React from 'react';

import { type LoanType } from '@kwara/models/src';
import StatusTag from '@kwara/components/src/StatusTag';
import { DateTime, Text } from '@kwara/components/src/Intl';
import { BackButton } from '@kwara/components/src/Button';
import { ButtonBar } from '@kwara/components/src/ButtonBar';

export const Metadata = ({ loan }: { laon: LoanType }) => {
  const back = <BackButton type="secondary" to={{ url: '/overview/loans' }} />;

  return (
    <>
      <ButtonBar className="mb2 pb3" left={[back]} />
      <div className="mb2 grey-400">
        <StatusTag state={loan.state.current} /> &middot; <Text id="LoanDetail.updated" />{' '}
        <DateTime value={loan.updatedAt} />
      </div>
    </>
  );
};
