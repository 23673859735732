import * as React from 'react';
import size from 'lodash/fp/size';

import Modal from '@kwara/components/src/Modal';
import { Loadable } from '@kwara/components/src/Loadable';
import { useMemberLoans } from '@kwara/models/src/models/request/hooks';
import { Stack } from '@kwara/components/src/Stack';
import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';
import { ActionButton } from '@kwara/components/src/Button';
import { type LoanType } from '@kwara/models/src';

import { EmptyModal } from '../../components/EmptyModal'; 
import { useStore } from '../../models/Member';
import { LoansToRepayList } from './LoansToRepayList';

const loanIncludes = ['repayments'];

const  LoansToRepayContent = ({loans}) => {
  const store = useStore();

  const filteredLoans = loans.filter(loan => loan.canMakeRepayment() && store.isMpesaLoanRepaymentEnabled)

  const hasLoansToRepay = size(filteredLoans) > 0;


  return(
    <>
      <If
        condition={!hasLoansToRepay}
        do={ 
          <EmptyModal to='/loans/select' titleId='LoansToRepay.Empty.Modal.title' subtitleId='LoansToRepay.Empty.Modal.subtitle' actionId='LoansToRepay.Empty.Modal.action'
          cancelId={'LoansToRepay.Empty.Modal.cancel'}/>
        }
        else={
          <Modal isOpen={true}>
          <div className="flex items-center self-start mh2">
            <ActionButton to="/overview/loans" hideBorder col="black" type="back" />
            <h3 className="kw-weight-bold kw-text-x-large ml3 mb3">
              <Text id={'LoanAdd.Connect.RepayLoans.title'} />
            </h3>
          </div>
          <div className={'flex flex-column h-100 overflow-scroll'}>
          <Stack.Child>
            <LoansToRepayList loans={filteredLoans} />
          </Stack.Child>
          </div>
        </Modal>
        }
      />
    </>
  )
   
  
}

export const LoansToRepay = () => {
  const store = useStore();
  const response = useMemberLoans(store.memberId, loanIncludes);

  return (
        <Loadable {...response}>
          {(loans: LoanType[]) => (
            <LoansToRepayContent loans={loans}/>
          )}
        </Loadable>
  );
};
