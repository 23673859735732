import * as React from 'react';
import { useHistory, Redirect } from 'react-router-dom';

import Button from '@kwara/components/src/Button';

import { If } from '@kwara/components/src/If/If';
import { Link } from '@kwara/components/src/Link';
import { Text } from '@kwara/components/src/Intl';

import { Container } from '../../../../layouts';
import { useNewUserStore } from '../../../../models/NewUserStore';
import { CloseIconButton } from '../../../../components/CloseIconButton';

import styles from './index.module.scss';
import iconJoinSaccoBg from '../../assets/iconJoinSaccoBg.png';

export function JoinASaccoToday() {
  const history = useHistory();
  const { storePhone } = useNewUserStore();

  return (
    <If
      condition={!storePhone}
      do={<Redirect to="/" />}
      else={
        <Container className={styles['container']}>
          <header>
            <h1 className="mobile-text-x-large">
              <Text id="SaccoRecommendationFlow.JoinASacco.title:1" />
              <br />
              <Text id="SaccoRecommendationFlow.JoinASacco.title:2" />
            </h1>

            <div>
              <CloseIconButton
                data-testid="closeJoinASaccoPage"
                labelId="SaccoRecommendationFlow.JoinASacco.Accessibility.closeBtn"
                onClick={() => history.push('/')}
              />
            </div>
          </header>

          <div className={styles['body']}>
            <img alt="" src={iconJoinSaccoBg} />

            <div className="pt3">
              <p className="mobile-text-small">
                <Text id="SaccoRecommendationFlow.JoinASacco.paragraph:1" />
              </p>

              <p className="mobile-text-small">
                <Text id="SaccoRecommendationFlow.JoinASacco.paragraph:2" />
              </p>

              <div>
                <Button to="/saccoRecommendation" className={styles['continue']}>
                  <Text id="SaccoRecommendationFlow.JoinASacco.continue" />
                </Button>
                <Button to="/organisations" className={styles['pick']}>
                  <Text id="SaccoRecommendationFlow.JoinASacco.pick" />
                </Button>
              </div>
            </div>

            <footer>
              <Link to="/" underline={false} className="white" type="primary" size="medium">
                <Text id="SaccoRecommendationFlow.JoinASacco.backHome" />
              </Link>
            </footer>
          </div>
        </Container>
      }
    />
  );
}
