import * as React from 'react';

import { FinancialProduct, IncomeRange, AgeGroup } from 'RecommendedOrganisationTypes';

import { Text } from '@kwara/components/src/Intl';

import iconLoans from '../../pages/SaccoRecommendationFlow/assets/iconLoans.png';
import icon18to25 from '../../pages/SaccoRecommendationFlow/assets/icon18to25.png';
import icon26to29 from '../../pages/SaccoRecommendationFlow/assets/icon26to29.png';
import icon30to39 from '../../pages/SaccoRecommendationFlow/assets/icon30to39.png';
import icon40to49 from '../../pages/SaccoRecommendationFlow/assets/icon40to49.png';
import icon50plus from '../../pages/SaccoRecommendationFlow/assets/icon50plus.png';
import iconOthers from '../../pages/SaccoRecommendationFlow/assets/iconOthers.png';
import iconSavings from '../../pages/SaccoRecommendationFlow/assets/iconSavings.png';
import iconInsurance from '../../pages/SaccoRecommendationFlow/assets/iconInsurance.png';
import iconInvestment from '../../pages/SaccoRecommendationFlow/assets/iconInvestment.png';
import icon17andBelow from '../../pages/SaccoRecommendationFlow/assets/icon17andBelow.png';

export const FINANCIAL_PRODUCTS: Record<string, FinancialProduct> = {
  SAVINGS: 'savings',
  INVESTMENT: 'investment',
  LOANS: 'loans',
  INSURANCE: 'insurance',
  OTHERS: 'others'
};

export const INCOME_RANGE: Record<string, IncomeRange> = {
  LESS_THAN_TWENTY_THOUSAND: 'less_than_20k',
  TWENTY_THOUSAND_TO_FIFTY_THOUSAND: '20k_to_50k',
  FIFTY_THOUSAND_TO_HUNDRED_THOUSAND: '50k_to_100k',
  HUNDRED_THOUSAND_AND_ABOVE: 'more_than_100k'
};

export const AGE_GROUP: Record<string, AgeGroup> = {
  LESS_THAN_EIGHTEEN: 'less_than_18',
  EIGHTEEN_TO_TWENTY_FIVE: '18_to_25',
  TWENTY_SIX_TO_TWENTY_NINE: '26_to_29',
  THIRTY_TO_THIRTY_NINE: '30_to_39',
  FORTY_TO_FORTY_NINE: '40_to_49',
  FIFTY_PLUS: '50_and_more'
};

export class Data {
  public getFinancialProducts() {
    return [
      {
        name: (
          <Text
            id="SaccoRecommendationFlow.FinancialProducts.Savings"
            values={{
              icon: <img alt="" src={iconSavings} />
            }}
          />
        ),
        value: FINANCIAL_PRODUCTS.SAVINGS
      },
      {
        name: (
          <Text
            id="SaccoRecommendationFlow.FinancialProducts.Investment"
            values={{ icon: <img alt="" src={iconInvestment} /> }}
          />
        ),
        value: FINANCIAL_PRODUCTS.INVESTMENT
      },
      {
        name: (
          <Text
            id="SaccoRecommendationFlow.FinancialProducts.Loans"
            values={{ icon: <img alt="" src={iconLoans} /> }}
          />
        ),
        value: FINANCIAL_PRODUCTS.LOANS
      },
      {
        name: (
          <Text
            id="SaccoRecommendationFlow.FinancialProducts.Insurance"
            values={{ icon: <img alt="" src={iconInsurance} /> }}
          />
        ),
        value: FINANCIAL_PRODUCTS.INSURANCE
      },
      {
        name: (
          <Text
            id="SaccoRecommendationFlow.FinancialProducts.Others"
            values={{ icon: <img alt="" src={iconOthers} /> }}
          />
        ),
        value: FINANCIAL_PRODUCTS.OTHERS
      }
    ];
  }

  public getIncomeRanges() {
    return [
      {
        name: <Text id="SaccoRecommendationFlow.IncomeRange.lessThanTwentyThousand" />,
        value: INCOME_RANGE.LESS_THAN_TWENTY_THOUSAND
      },
      {
        name: <Text id="SaccoRecommendationFlow.IncomeRange.twentyThousandToFiftyThousand" />,
        value: INCOME_RANGE.TWENTY_THOUSAND_TO_FIFTY_THOUSAND
      },
      {
        name: <Text id="SaccoRecommendationFlow.IncomeRange.fiftyThousandToHundredThousand" />,
        value: INCOME_RANGE.FIFTY_THOUSAND_TO_HUNDRED_THOUSAND
      },
      {
        name: <Text id="SaccoRecommendationFlow.IncomeRange.hundredThousandAndAbove" />,
        value: INCOME_RANGE.HUNDRED_THOUSAND_AND_ABOVE
      }
    ];
  }

  public getAgeGroup() {
    return [
      {
        name: (
          <Text
            id="SaccoRecommendationFlow.AgeGroup.lessThanEighteen"
            values={{ icon: <img alt="" src={icon17andBelow} /> }}
          />
        ),
        value: AGE_GROUP.LESS_THAN_EIGHTEEN
      },
      {
        name: (
          <Text
            id="SaccoRecommendationFlow.AgeGroup.eighteenToTwentyFive"
            values={{ icon: <img alt="" src={icon18to25} /> }}
          />
        ),
        value: AGE_GROUP.EIGHTEEN_TO_TWENTY_FIVE
      },
      {
        name: (
          <Text
            id="SaccoRecommendationFlow.AgeGroup.twentySixToTwentyNine"
            values={{ icon: <img alt="" src={icon26to29} /> }}
          />
        ),
        value: AGE_GROUP.TWENTY_SIX_TO_TWENTY_NINE
      },
      {
        name: (
          <Text
            id="SaccoRecommendationFlow.AgeGroup.thirtyToThirtyNine"
            values={{ icon: <img alt="" src={icon30to39} /> }}
          />
        ),
        value: AGE_GROUP.THIRTY_TO_THIRTY_NINE
      },
      {
        name: (
          <Text
            id="SaccoRecommendationFlow.AgeGroup.fortyToFortyNine"
            values={{ icon: <img alt="" src={icon40to49} /> }}
          />
        ),
        value: AGE_GROUP.FORTY_TO_FORTY_NINE
      },
      {
        name: (
          <Text id="SaccoRecommendationFlow.AgeGroup.fiftyPlus" values={{ icon: <img alt="" src={icon50plus} /> }} />
        ),
        value: AGE_GROUP.FIFTY_PLUS
      }
    ];
  }
}
