// @flow
import * as React from 'react';
import classnames from 'classnames';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';
import get from 'lodash/fp/get';
import invoke from 'lodash/fp/invoke';

import { Currency, Text } from '@kwara/components/src/Intl';
import Empty from '@kwara/components/src/Empty';
import { type LoanType, type GuaranteeType, type CollateralT } from '@kwara/models/src';

import Section from '../../components/Section';
import styles from './SecurityList.module.scss';

const SecurityItem = ({ securityType, amount }) => (
  <div className={(classnames('ma2 kw-text-regular'), styles.item)}>
    <div className="mt3">
      <div className={'grey-400 mb2'}>
        <Text id={securityType.titleId}></Text>
      </div>
      <div className={'black'}>
        <Text id={securityType.value}></Text>
      </div>
    </div>
    <div className="mt3">
      <div className={'grey-400 mb2'}>
        <Text id={amount.titleId}></Text>
      </div>
      <div className={'black'}>
        <Currency format="currency" value={amount.value} />
      </div>
    </div>
  </div>
);

const Guarantors = ({ guarantors }) => (
  <div className={styles.container}>
    {map<GuaranteeType, React.Node>(
      guarantor => (
        <SecurityItem
          securityType={{
            titleId: 'Security.guarantor',
            value: invoke('fullName', get('member', guarantor))
          }}
          amount={{
            titleId: 'Security.guaranteeing',
            value: get('amount', guarantor)
          }}
        />
      ),
      guarantors
    )}
  </div>
);

const Collaterals = ({ collaterals }) => (
  <div className={styles.container}>
    {map<CollateralT, React.Node>(
      collateral => (
        <SecurityItem
          securityType={{
            titleId: 'Security.collateral',
            value: `LoanDetail.Security.Collateral.Type.${collateral.assetName}`
          }}
          amount={{
            titleId: 'Security.amount',
            value: get('amount', collateral)
          }}
        />
      ),
      collaterals
    )}
  </div>
);

export const SecurityList = ({ loan }: { loan: LoanType }) => {
  const hasGuarantors = size(loan.guarantors) > 0;
  const hasCollaterals = size(loan.collaterals) > 0;

  return (
    <>
      <Section titleId="Guarantors">
        {hasGuarantors ? (
          <Guarantors guarantors={get('guarantors', loan)} />
        ) : (
          <Empty>
            <Text id="Security.noGuarantors" />
          </Empty>
        )}
      </Section>

      <Section titleId="Collaterals">
        {hasCollaterals ? (
          <Collaterals collaterals={get('collaterals', loan)} />
        ) : (
          <Empty>
            <Text id="Security.noCollaterals" />
          </Empty>
        )}
      </Section>
    </>
  );
};
