import { useState } from 'react';
import { useQuery } from 'react-query';

import { StatusType } from 'AirtimePurchaseTypes';

import { useInterval } from '@kwara/models/src/models/request/hooks';
import {
  AirtimePurchase,
  STATUS,
  STK_TIMEOUT_COUNTER,
  STATUS_POLLING_INTERVAL
} from '../../../models/AirtimePurchases';

export function useGetPurchasedAirtime(id: string, onError?: (err: unknown) => void) {
  const query = useQuery(['airtime_purchases', id], {
    async queryFn() {
      const airtimePurchase = new AirtimePurchase();
      const response = await airtimePurchase.getPurchasedAirtime(id);

      return response.data.data.attributes;
    },
    enabled: id != undefined,
    onError,
    retry: false
  });

  return query;
}

export function usePollPurchasedAirtime(id: string) {
  const [isPolling, setIsPolling] = useState(true);
  const [stkTimeoutCounter, setStkTimeoutCounter] = useState(0);
  const [status, setStatus] = useState<StatusType>(STATUS.PENDING);
  const { data, refetch } = useGetPurchasedAirtime(id, function onGetPurchasedAirtimeError() {
    setIsPolling(false);
    setStatus(STATUS.GENERIC_FAILURE);
  });

  const INTERVAL = isPolling ? STATUS_POLLING_INTERVAL : null;

  function onRefetch() {
    setStatus(STATUS.PENDING);
    setIsPolling(true);
    setStkTimeoutCounter(0);
  }

  useInterval(() => {
    if (!data?.purchase_status) return;

    const { purchase_status } = data;
    const isStkTimeout = stkTimeoutCounter >= STK_TIMEOUT_COUNTER;
    const isDoneProcessing = ![STATUS.PROCESSING, STATUS.PENDING].includes(purchase_status as StatusType);

    if (isDoneProcessing || isStkTimeout) setIsPolling(false);

    if (isStkTimeout) setStatus(STATUS.TIMEOUT);
    else setStatus(purchase_status as StatusType);

    refetch();
    setStkTimeoutCounter(prevStkTimeoutCounter => prevStkTimeoutCounter + 1);
  }, INTERVAL);

  return {
    data,
    isPolling,
    status,
    refetch: onRefetch
  };
}
