import { attr, belongsTo } from 'spraypaint';

import Base, { BaseModel } from './Base';
import { User, UserT } from './User';

export const LoanDisbursementAppraisals = Base.extend({
  static: {
    jsonapiType: 'loan_disbursement_appraisals'
  },
  attrs: {
    state: attr(),
    amount: attr(),
    notes: attr(),
    user: belongsTo({ type: User })
  },
  methods: {
    createdBy() {
      if (!this.user) {
        return '';
      }
      return `${this.user.fullName()} (${this.user.id})`;
    }
  }
});

export interface LoanDisbursementAppraisalsType extends BaseModel<LoanDisbursementAppraisalsType> {
  state: string;
  amount: number;
  user: UserT;
  createdBy(): string;
  notes: string;
}
