import groupBy from 'lodash/fp/groupBy';
import get from 'lodash/fp/get';
import indexOf from 'lodash/fp/indexOf';

// Permission groups
//
const MEMBER = 'member';
const LOAN = 'loan';
const SAVINGS = 'savings';
const BATCH_TRANSACTIONS = 'batchTransactionImport';
const SAVING_TRANSACTIONS = 'savingTransaction';
const LOAN_TRANSACTIONS = 'loanTransaction';
const DIRECT_DEBIT_REPORTS = 'directDebitReport';
const TILL = 'till';
const TOPUP = 'topup';
const JOURNAL_ENTRIES = 'journalEntries';
const INVITATION = 'invitation';
const OTHER = 'other';

export const groups = [
  MEMBER,
  SAVINGS,
  SAVING_TRANSACTIONS,
  LOAN,
  LOAN_TRANSACTIONS,
  DIRECT_DEBIT_REPORTS,
  BATCH_TRANSACTIONS,
  TILL,
  TOPUP,
  JOURNAL_ENTRIES,
  INVITATION,
  OTHER
];

// Permission actions
//
const MANAGE = 'manage';
const VERIFY = 'verify';
const VIEW = 'view';
const VIEW_ALL = 'view_all';
const CREATE = 'create';
const APPROVE = 'approve';
const UNAPPROVE = 'unapprove';
const UPDATE = 'update';
const REJECT = 'reject';
const REVIEW = 'review';
const OPEN = 'open';
const CLOSE = 'close';
const RESTORE = 'restore';
const REOPEN = 'reopen';

const OTHER_VERB = 'other';

// Permissions are sorted by these "verbs", lowest index first
// i.e VIEW permission will always be first, and so on.
//
const verbs = [
  MANAGE,
  VERIFY,
  VIEW,
  VIEW_ALL,
  CREATE,
  UPDATE,
  APPROVE,
  REJECT,
  REVIEW,
  OPEN,
  CLOSE,
  OTHER_VERB,
  RESTORE,
  REOPEN
];

export const sortByVerb = permission => indexOf(permission.verb(), verbs);

function determineGroup(permission) {
  return get([permission.name, 'group'], KwaraPermissionsConfig) || OTHER;
}

export function groupPermissions(permissions = []) {
  return groupBy(determineGroup, permissions);
}

export const KwaraPermissionsConfig = Object.freeze({
  VIEW_ACCOUNTING_REPORTS: {
    id: 'VIEW_ACCOUNTING_REPORTS',
    group: OTHER,
    verb: VIEW
  },
  VIEW_BATCH_TRANSACTION_IMPORTS: {
    id: 'VIEW_BATCH_TRANSACTION_IMPORTS',
    group: BATCH_TRANSACTIONS,
    verb: VIEW
  },
  CREATE_BATCH_TRANSACTION_IMPORTS: {
    id: 'CREATE_BATCH_TRANSACTION_IMPORTS',
    group: BATCH_TRANSACTIONS,
    verb: CREATE
  },
  REVIEW_BATCH_TRANSACTION_IMPORTS: {
    id: 'REVIEW_BATCH_TRANSACTION_IMPORTS',
    group: BATCH_TRANSACTIONS,
    verb: REVIEW
  },

  VIEW_SACCO_EVENTS: { id: 'VIEW_SACCO_EVENTS', group: OTHER, verb: VIEW },

  VIEW_INVITATIONS: {
    id: 'VIEW_INVITATIONS',
    group: INVITATION,
    verb: VIEW
  },
  CREATE_INVITATIONS: {
    id: 'CREATE_INVITATIONS',
    group: INVITATION,
    verb: CREATE
  },

  VIEW_JOURNAL_ENTRIES: {
    id: 'VIEW_JOURNAL_ENTRIES',
    group: JOURNAL_ENTRIES,
    verb: VIEW
  },
  CREATE_JOURNAL_ENTRIES: {
    id: 'CREATE_JOURNAL_ENTRIES',
    group: JOURNAL_ENTRIES,
    verb: CREATE
  },

  REVIEW_CHECKABLE_JOURNAL_ENTRIES: {
    id: 'REVIEW_CHECKABLE_JOURNAL_ENTRIES',
    group: JOURNAL_ENTRIES,
    verb: REVIEW
  },

  MANAGE_SASRA_REPORTS: {
    id: 'MANAGE_SASRA_REPORTS',
    group: OTHER,
    verb: MANAGE
  },
  MANAGE_SASRA_REPORT_CONFIG: {
    id: 'MANAGE_SASRA_REPORT_CONFIG',
    group: OTHER,
    verb: MANAGE
  },
  VIEW_OPERATIONAL_REPORTS: {
    id: 'VIEW_OPERATIONAL_REPORTS',
    group: OTHER,
    verb: VIEW
  },
  CREATE_OPERATIONAL_REPORTS: {
    id: 'CREATE_OPERATIONAL_REPORTS',
    group: OTHER,
    verb: CREATE
  },

  VIEW_TILLS: { id: 'VIEW_TILLS', group: TILL, verb: VIEW },
  VIEW_ALL_TILLS: { id: 'VIEW_ALL_TILLS', group: TILL, verb: VIEW_ALL },
  OPEN_TILL: { id: 'OPEN_TILL', group: TILL, verb: OPEN },
  CLOSE_TILL: { id: 'CLOSE_TILL', group: TILL, verb: CLOSE },

  MANAGE_TILL_ACCESS: { id: 'MANAGE_TILL_ACCESS', group: TILL, verb: MANAGE },

  VIEW_TOPUP_REQUESTS: {
    id: 'VIEW_TOPUP_REQUESTS',
    group: TOPUP,
    verb: VIEW
  },
  VIEW_ALL_TOPUP_REQUESTS: {
    id: 'VIEW_ALL_TOPUP_REQUESTS',
    group: TOPUP,
    verb: VIEW_ALL
  },
  CREATE_TOPUP_REQUESTS: {
    id: 'CREATE_TOPUP_REQUESTS',
    group: TOPUP,
    verb: CREATE
  },
  APPROVE_TOPUP_REQUESTS: {
    id: 'APPROVE_TOPUP_REQUESTS',
    group: TOPUP,
    verb: APPROVE
  },
  REJECT_TOPUP_REQUESTS: {
    id: 'REJECT_TOPUP_REQUESTS',
    group: TOPUP,
    verb: REJECT
  },
  VIEW_TILL_TRANSACTIONS: {
    id: 'VIEW_TILL_TRANSACTIONS',
    group: TILL,
    verb: VIEW
  },
  CREATE_UTILITY_TRANSACTIONS: {
    id: 'CREATE_UTILITY_TRANSACTIONS',
    group: TILL,
    verb: CREATE
  },

  VIEW_SUPERVISOR_METRICS: {
    group: TILL,
    id: 'VIEW_SUPERVISOR_METRICS',
    verb: VIEW
  },

  CREATE_SAVINGS_TRANSACTIONS: {
    group: SAVING_TRANSACTIONS,
    verb: CREATE,
    id: 'CREATE_SAVINGS_TRANSACTIONS'
  },
  VIEW_SAVINGS_TRANSACTIONS: {
    group: SAVING_TRANSACTIONS,
    verb: VIEW,
    id: 'VIEW_SAVINGS_TRANSACTIONS'
  },
  VIEW_LOAN_DISBURSEMENTS: {
    group: LOAN_TRANSACTIONS,
    verb: VIEW,
    id: 'VIEW_LOAN_DISBURSEMENTS'
  },
  APPROVE_LOAN_DISBURSEMENTS: {
    group: LOAN_TRANSACTIONS,
    verb: APPROVE,
    id: 'APPROVE_LOAN_DISBURSEMENTS'
  },
  REJECT_LOAN_DISBURSEMENTS: {
    group: LOAN_TRANSACTIONS,
    verb: REJECT,
    id: 'REJECT_LOAN_DISBURSEMENTS'
  },
  APPROVE_SAVINGS_TRANSACTIONS: {
    group: SAVING_TRANSACTIONS,
    verb: APPROVE,
    id: 'APPROVE_SAVINGS_TRANSACTIONS'
  },
  REJECT_SAVINGS_TRANSACTIONS: {
    group: SAVING_TRANSACTIONS,
    verb: REJECT,
    id: 'REJECT_SAVINGS_TRANSACTIONS'
  },

  CREATE_LOAN_TRANSACTIONS: {
    group: LOAN_TRANSACTIONS,
    verb: CREATE,
    id: 'CREATE_LOAN_TRANSACTIONS'
  },
  VIEW_LOAN_TRANSACTIONS: {
    group: LOAN_TRANSACTIONS,
    verb: VIEW,
    id: 'VIEW_LOAN_TRANSACTIONS'
  },
  APPROVE_LOAN_TRANSACTIONS: {
    group: LOAN_TRANSACTIONS,
    verb: APPROVE,
    id: 'APPROVE_LOAN_TRANSACTIONS'
  },
  REJECT_LOAN_TRANSACTIONS: {
    group: LOAN_TRANSACTIONS,
    verb: REJECT,
    id: 'REJECT_LOAN_TRANSACTIONS'
  },
  SAFARICOM_IMSI_VERIFY_PHONE: {
    id: 'SAFARICOM_IMSI_VERIFY_PHONE',
    verb: VERIFY,
    group: OTHER
  },
  CREATE_LOAN_FEE: {
    id: 'CREATE_LOAN_FEE',
    verb: CREATE,
    group: LOAN_TRANSACTIONS
  },
  CREATE_LOAN_REPAYMENT: {
    id: 'CREATE_LOAN_REPAYMENT',
    verb: CREATE,
    group: LOAN_TRANSACTIONS
  },
  ADJUST_LOAN_TRANSACTIONS: {
    id: 'ADJUST_LOAN_TRANSACTIONS',
    verb: CREATE,
    group: LOAN_TRANSACTIONS
  },
  PAYOFF_LOAN_ACCOUNTS: {
    id: 'PAYOFF_LOAN_ACCOUNTS',
    verb: OTHER,
    group: LOAN
  },
  TOPUP_LOAN_ACCOUNTS: {
    id: 'TOPUP_LOAN_ACCOUNTS',
    verb: OTHER,
    group: LOAN
  },
  WRITEOFF_LOAN_ACCOUNTS: {
    id: 'WRITEOFF_LOAN_ACCOUNTS',
    verb: OTHER,
    group: LOAN
  },
  REFINANCE_LOAN_ACCOUNTS: {
    id: 'REFINANCE_LOAN_ACCOUNTS',
    verb: OTHER,
    group: LOAN
  },
  RESCHEDULE_LOAN_ACCOUNTS: {
    id: 'RESCHEDULE_LOAN_ACCOUNTS',
    verb: OTHER,
    group: LOAN
  },
  DISBURSE_LOAN_ACCOUNTS: {
    id: 'DISBURSE_LOAN_ACCOUNTS',
    verb: OTHER,
    group: LOAN
  },
  APPROVE_LOAN_ACCOUNTS: {
    id: 'APPROVE_LOAN_ACCOUNTS',
    verb: APPROVE,
    group: LOAN
  },
  REJECT_LOAN_ACCOUNTS: {
    id: 'REJECT_LOAN_ACCOUNTS',
    verb: REJECT,
    group: LOAN
  },
  CREATE_LOAN_ACCOUNTS: {
    id: 'CREATE_LOAN_ACCOUNTS',
    verb: CREATE,
    group: LOAN
  },
  UPDATE_LOAN_ACCOUNTS: {
    id: 'UPDATE_LOAN_ACCOUNTS',
    verb: UPDATE,
    group: LOAN
  },
  VIEW_LOAN_ACCOUNTS: {
    id: 'VIEW_LOAN_ACCOUNTS',
    verb: VIEW,
    group: LOAN
  },
  VIEW_LOAN_METRICS: {
    id: 'VIEW_LOAN_METRICS',
    verb: VIEW,
    group: LOAN
  },
  VIEW_CHEQUE_TRANSACTIONS: {
    id: 'VIEW_CHEQUE_TRANSACTIONS',
    verb: VIEW,
    group: OTHER
  },
  VIEW_MEMBER: {
    id: 'VIEW_MEMBER',
    verb: VIEW,
    group: MEMBER
  },
  VIEW_MEMBER_METRICS: {
    id: 'VIEW_MEMBER_METRICS',
    verb: VIEW,
    group: MEMBER
  },
  CREATE_MEMBER: {
    id: 'CREATE_MEMBER',
    verb: CREATE,
    group: MEMBER
  },
  APPROVE_MEMBER: {
    id: 'APPROVE_MEMBER',
    verb: APPROVE,
    group: MEMBER
  },
  UNAPPROVE_MEMBER: {
    id: 'UNAPPROVE_MEMBER',
    verb: UNAPPROVE,
    group: MEMBER
  },
  REJECT_MEMBER: {
    id: 'REJECT_MEMBER',
    verb: REJECT,
    group: MEMBER
  },
  UPDATE_MEMBER: {
    id: 'UPDATE_MEMBER',
    verb: UPDATE,
    group: MEMBER
  },
  EXIT_MEMBER: {
    id: 'EXIT_MEMBER',
    verb: CLOSE,
    group: MEMBER
  },
  RESTORE_MEMBER: {
    id: 'RESTORE_MEMBER',
    verb: RESTORE,
    group: MEMBER
  },
  VIEW_SAVINGS_ACCOUNTS: {
    id: 'VIEW_SAVINGS_ACCOUNTS',
    verb: VIEW,
    group: SAVINGS
  },
  VIEW_SAVINGS_METRICS: {
    id: 'VIEW_SAVINGS_METRICS',
    verb: VIEW,
    group: SAVINGS
  },
  CREATE_SAVINGS_ACCOUNTS: {
    id: 'CREATE_SAVINGS_ACCOUNTS',
    verb: CREATE,
    group: SAVINGS
  },
  APPROVE_SAVINGS_ACCOUNTS: {
    id: 'APPROVE_SAVINGS_ACCOUNTS',
    verb: APPROVE,
    group: SAVINGS
  },
  UPDATE_SAVINGS_ACCOUNTS: {
    id: 'UPDATE_SAVINGS_ACCOUNTS',
    verb: UPDATE,
    group: SAVINGS
  },
  CLOSE_SAVINGS_ACCOUNTS: {
    id: 'CLOSE_SAVINGS_ACCOUNTS',
    verb: CLOSE,
    group: SAVINGS
  },
  REOPEN_SAVINGS_ACCOUNTS: {
    id: 'REOPEN_SAVINGS_ACCOUNTS',
    verb: REOPEN,
    group: SAVINGS
  },
  CREATE_INVITATONS: {
    id: 'CREATE_INVITATONS',
    verb: CREATE,
    group: INVITATION
  },
  CREATE_DEPOSIT: {
    id: 'CREATE_DEPOSIT',
    verb: CREATE,
    group: SAVING_TRANSACTIONS
  },
  CREATE_WITHDRAWAL: {
    id: 'CREATE_WITHDRAWAL',
    verb: CREATE,
    group: SAVING_TRANSACTIONS
  },
  CREATE_TRANSFER: {
    id: 'CREATE_TRANSFER',
    verb: CREATE,
    group: SAVING_TRANSACTIONS
  },
  CREATE_SAVINGS_FEE: {
    id: 'CREATE_SAVINGS_FEE',
    verb: CREATE,
    group: SAVING_TRANSACTIONS
  },
  ADJUST_SAVINGS_TRANSACTIONS: {
    id: 'ADJUST_SAVINGS_TRANSACTIONS',
    verb: CREATE,
    group: SAVING_TRANSACTIONS
  },
  VIEW_DIRECT_DEBIT_REPORTS: {
    id: 'VIEW_DIRECT_DEBIT_REPORTS',
    verb: VIEW,
    group: DIRECT_DEBIT_REPORTS
  },
  GENERATE_DIRECT_DEBIT_REPORTS: {
    id: 'GENERATE_DIRECT_DEBIT_REPORTS',
    verb: CREATE,
    group: DIRECT_DEBIT_REPORTS
  },
  UPDATE_ORGANISATION_SETTINGS: {
    id: 'UPDATE_ORGANISATION_SETTINGS',
    verb: UPDATE,
    group: OTHER
  },
  VIEW_MPESA_CREDENTIALS: {
    id: 'VIEW_MPESA_CREDENTIALS',
    verb: VIEW,
    group: OTHER
  },
  VIEW_MPESA_TRANSACTIONS: {
    id: 'VIEW_MPESA_TRANSACTIONS',
    verb: VIEW,
    group: OTHER
  },
  VIEW_RECONCILIATION_STATEMENTS: {
    id: 'VIEW_RECONCILIATION_STATEMENTS',
    verb: VIEW,
    group: OTHER
  },
  CREATE_RECONCILIATION_STATEMENTS: {
    id: 'CREATE_RECONCILIATION_STATEMENTS',
    verb: CREATE,
    other: OTHER
  },
  MANAGE_USER_ACCESS: {
    id: 'MANAGE_USER_ACCESS',
    verb: MANAGE,
    group: OTHER
  },
  VIEW_DASHBOARD_INDICATORS: {
    id: 'VIEW_DASHBOARD_INDICATORS',
    verb: VIEW,
    group: OTHER
  },
  VIEW_DASHBOARD_SAVINGS_TRANSACTIONS_STATS: {
    id: 'VIEW_DASHBOARD_SAVINGS_TRANSACTIONS_STATS',
    verb: VIEW,
    group: OTHER
  },
  VIEW_DASHBOARD_MEMBER_BASE_STATS: {
    id: 'VIEW_DASHBOARD_MEMBER_BASE_STATS',
    verb: VIEW,
    group: OTHER
  },
  VIEW_DASHBOARD_LOAN_DISBURSEMENTS_STATS: {
    id: 'VIEW_DASHBOARD_LOAN_DISBURSEMENTS_STATS',
    verb: VIEW,
    group: OTHER
  },
  VIEW_DASHBOARD_MOBILE_BANKING_USERS_STATS: {
    id: 'VIEW_DASHBOARD_MOBILE_BANKING_USERS_STATS',
    verb: VIEW,
    group: OTHER
  },
  VIEW_DIVIDEND_REPORTS: {
    id: 'VIEW_DIVIDEND_REPORTS',
    verb: VIEW,
    group: OTHER
  }
});
