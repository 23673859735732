// @flow

import { attr } from 'spraypaint';
import replace from 'lodash/fp/replace';
import Base from './Base';

export const MpesaTransactionStatus = Object.freeze({
  PENDING: 'pending',
  SUCCEEDED: 'succeeded',
  JOINING_FEE_SUCCEEDED: 'joining_fee_succeeded', //specific status when registering a new user and paying with mpesa
  FAILED: 'failed',
  TIMEOUT: 'timeout',
  CANCELLED: 'cancelled',
  INSUFFICIENT_AMOUNT: 'insufficient_amount'
});

export const MpesaTransactionTypes = Object.freeze({
  REPAYMENT: 'repayment',
  DEPOSIT: 'deposit'
});

// API expects phone numbers without +
export const formatPhoneNumber = (phoneNumber: string) => replace('+', '', phoneNumber);

export const MpesaTransaction = Base.extend({
  static: {
    jsonapiType: 'stk_push_transactions',
    endpoint: '/payments/mpesa/stk_push_transactions'
  },
  attrs: {
    id: attr(),
    phoneNumber: attr(),
    amount: attr(),
    accountId: attr(),
    paymentType: attr(),
    transactionStatus: attr(),
    checkoutRequestId: attr()
  }
});

export type MpesaTransactionType = MpesaTransaction;
