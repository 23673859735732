import React from 'react';
import { Text } from '@kwara/components/src/Intl';

type RepaymentDetailsProps = {
  installments?: number;
  duration: string;
  repaymentPeriodUnit: string;
};

export const RepaymentDetails = ({ installments, duration, repaymentPeriodUnit }: RepaymentDetailsProps) => {
  return (
    <>
      <div className="flex">
        {installments && (
          <div className="ml1 w-50 mobile-text-small">
            <Text id="LoanAdd.Summary.Loan.installments" />
          </div>
        )}
        <div className="mobile-text-small">
          <Text id="LoanAdd.Summary.Loan.duration" />
        </div>
      </div>
      <div className="flex">
        {installments && (
          <div className="ml1 w-50 mobile-neutral-900">
            <span>{installments}</span>
          </div>
        )}
        <div className="mobile-neutral-900">
          <Text
            id={`LoanAdd.Summary.Loan.frequency.${repaymentPeriodUnit}`}
            values={{ value: duration, unit: repaymentPeriodUnit }}
          />
        </div>
      </div>
    </>
  );
};
