// @flow

import * as React from 'react';

import size from 'lodash/fp/size';
import get from 'lodash/fp/get';

import { type LoanType } from '@kwara/models/src';
import Empty from '@kwara/components/src/Empty';
import { Text } from '@kwara/components/src/Intl';

import Section from '../../components/Section';
import { TransactionsTable } from '../../components/TransactionsTable';

export const LoanTransactions = ({ loan }: { loan: LoanType }) => {
  const transactions = get('transactions', loan);
  const hasTransactions = size(transactions) > 0;

  return (
    <Section titleId="SavingDetail.transactions">
      <div>
        {hasTransactions ? (
          <TransactionsTable transactions={transactions} />
        ) : (
          <Empty>
            <Text id="TransactionsTable.empty" />
          </Empty>
        )}
      </div>
    </Section>
  );
};
