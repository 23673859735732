// @flow

import * as React from 'react';

import map from 'lodash/fp/map';

import { Text } from '@kwara/components/src/Intl';

import Collapsible from '../../components/Collapsible';
import { StatisticInfo } from './StatisticInfo';
import styles from './index.module.scss';

export const Identification = ({ member, className }) => (
  <Collapsible className={className} summary={<Text id="MemberDetail.identification" />}>
    <section className={styles.InfoSection}>
      {map(
        document => (
          <StatisticInfo
            key={`${document.documentId}${document.type}`}
            title={<Text id={`AddMember.Identity.Type.${document.type}`} />}
            value={document.documentId}
          />
        ),
        member.idDocuments
      )}
      <StatisticInfo title={<Text id={`AddMember.Identity.KRA.label`} />} value={member.kraPin} />
    </section>
  </Collapsible>
);
