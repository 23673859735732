export const ROUTES = Object.freeze({
  home: '/',
  start: '/',
  cancelMembershipApplication: '/cancelMembershipApplication/:step?/:subStep?',
  forgotPin: '/forgotPin/:step?/:subStep?',
  fourDigitPINUpdate: '/fourDigitPINUpdate/:step?/:subStep?',
  overviewBase: '/overview/',
  savingsDeposit: '/savings/:accountId/deposit/:step?/:subStep?',
  overviewSavingDeposit: '/overview/:accountId/deposit/:step?/:subStep?',
  savingOverview: '/savings/:accountId/overview',
  savingDetails: '/savings/:accountId/overview/details',
  savingActivity: '/savings/:accountId/overview/activity',
  savingsSelect: '/savings/select',
  loanSelect: '/loans/select',
  instantLoansSelect: '/instantLoans/select',
  loansRepay: '/loans/repay',
  loanCreate: '/loans/create/:step?/:subStep?',
  loanInstantCreate: '/loans/instant/create/:step?/:subStep?',
  airtime: '/airtime/:step?/:subStep?',
  loanOverview: '/loans/:loanId/overview',
  loanDetail: '/loans/:loanId/overview/details',
  loanSecurity: '/loans/:loanId/overview/security',
  loanDocuments: '/loans/:loanId/overview/documents',
  loanActivity: '/loans/:loanId/overview/activity',
  loanRepayment: '/loans/:loanId/repayment/:step?/:subStep?',
  overviewRepayment: '/overview/:loanId/repayment/:step?/:subStep?',
  loanAcceptGuarantorship: '/loan/acceptGuarantorship',
  overviewLoans: '/overview/loans',
  overviewSavings: '/overview/savings',
  overviewHistory: '/overview/history',
  overviewProfile: '/overview/profile',
  statement: '/statement/:step?/:subStep?',
  memberCreate: '/members/create/:step?/:subStep?',
  register: '/register',
  joinSacco: '/joinSacco',
  saccoRecommendation: '/saccoRecommendation/:step?/:subStep?',
  organisations: '/organisations',
  joiningFee: '/joiningFee',
  secureFourPinUpdate: '/secureFourPinUpdate/:step?/:subStep?',
  login: '/login',
  loginFivePIN: '/loginFivePIN',
  referral: '/referral',
  membershipsActivation: '/memberships/activation/:organisationPermalink/:step?/:subStep?',
  genericPath: '*'
});
