import { useCallback } from 'react';
import last from 'lodash/fp/last';
import compact from 'lodash/fp/compact';

import { ModelError } from 'GlobalTypes';

import { segmentTrack } from '@kwara/components/src/Segment';
import { SharedLoanAddValues, ONE_MONTH } from '@kwara/components/src/pages/LoanAdd/components/Configure/Installments';

import { InstantLoan } from '../../../models/InstantLoan';

export function useCreateLoan() {
  const conditionallyThrowError = (condition: boolean, error: Array<ModelError>) => {
    if (condition) throw error;
  };

  const createLoan = useCallback(async (data: SharedLoanAddValues) => {
    const loan = new InstantLoan({
      productId: data.product.id,
      amount: data.amount,
      installments: Math.floor(Number(data.installments || ONE_MONTH)),
      loanClassificationId: last(compact(data.classifications))
    });
    const success = await loan.save();

    conditionallyThrowError(!success, loan.errors);

    segmentTrack('Instant loan Created');
  }, []);

  return createLoan;
}
