// @flow

import { attr } from 'spraypaint';

import Base, { type BaseModel } from './Base';

export const SmsPaymentStatus = Object.freeze({
  PENDING: 'pending',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  TIMEOUT: 'timeout',
  CANCELLED: 'cancelled',
  INSUFFICIENT_AMOUNT: 'insufficient_amount'
});
export type SmsPaymentStatusT = $Values<typeof SmsPaymentStatus>;

export const SmsPayment = Base.extend({
  static: {
    jsonapiType: 'sms_payments'
  },
  attrs: {
    numberOfSms: attr(),
    purchasePhoneNumber: attr(),
    paymentStatus: attr()
  }
});

export interface SmsPaymentT extends BaseModel<SmsPaymentT> {
  numberOfSms: string;
  purchasePhoneNumber: string;
  paymentStatus: SmsPaymentStatusT;
}
