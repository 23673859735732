import _ from 'lodash/fp';

import { throwError } from '@kwara/lib/src/utils/throwError';

export type Window = Record<string, any>;
type CB = (method: string, value: Array<string>) => void;
type Data = { method: string; args: Array<string> };

const freshChatWidgetMethods = [
  'close',
  'destroy',
  'hide',
  'init',
  'isInitialized',
  'isLoaded',
  'isOpen',
  'off',
  'on',
  'open',
  'setConfig',
  'setExternalId',
  'setFaqTags',
  'setTags',
  'track',
  'user.show',
  'user.track',
  'user.user',
  'user.clear',
  'user.create',
  'user.get',
  'user.isExists',
  'user.setEmail',
  'user.setFirstName',
  'user.setLastName',
  'user.setMeta',
  'user.setPhone',
  'user.setPhoneCountryCode',
  'user.setProperties',
  'user.update'
];

class Queue {
  private data: Array<Data> = [];
  private index: number = 0;

  private get isEmpty() {
    return this.index >= this.data.length;
  }

  private reset() {
    this.data.length = 0;
    this.index = 0;
  }

  public enqueue(value: Data) {
    this.data.push(value);
  }

  public dequeue() {
    if (this.index >= 0 && this.index < this.data.length) {
      const result = this.data[this.index++];

      if (this.isEmpty) this.reset();

      return result;
    }
  }

  public dequeueAll(cb: CB) {
    if (!_.isFunction(cb)) throwError('FreshChatDequeueAllError', 'Please provide a callback', this.dequeueAll);

    while (!this.isEmpty) {
      const { method, args } = this.dequeue();

      cb(method, args);
    }
  }
}

const freshChatQueue = new Queue();

function queueMethod(method: string) {
  return (...args: Array<string>) => freshChatQueue.enqueue({ method, args });
}

function setFreshChatMethods(methods: Array<string>) {
  return methods.reduce((obj, method) => {
    return _.set(method, queueMethod(method), obj);
  }, {});
}

let initialMethods: Record<string, any> | undefined;

function widget(methods = initialMethods) {
  if ((window as Window).fcWidget) return (window as Window).fcWidget;
  if (!methods) methods = setFreshChatMethods(freshChatWidgetMethods);
  return methods;
}

function loadScript() {
  const id = 'freshChatLib';

  if (document.getElementById(id) || (window as Window).fcWidget) return;

  const script = document.createElement('script');
  script.async = true;
  script.type = 'text/javascript';
  script.src = process.env.REACT_APP_FRESHCHAT_API_WIDGET_SCRIPT;
  script.id = id;
  document.head.appendChild(script);
}

function clearFreshchatClientCache() {
  if ((window as Window)?.fcWidget) (window as Window).fcWidget.user.clear();
}

export { widget, loadScript, freshChatQueue, clearFreshchatClientCache };
