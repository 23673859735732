import React from 'react';

import { QueryClientProvider, QueryClient } from 'react-query';

import '@kwara/components/src/foundations';

import Auth from '@kwara/models/src/lib/Auth';

import { appName } from '@kwara/lib/src/utils';
import { configureModels } from '@kwara/models/src';
import { configureStores } from '@kwara/components/src/configureStores';
import { Provider as IntlProvider, prepareTranslations } from '@kwara/components/src/Intl';
import { NotificationProvider } from '@kwara/components/src/Notification/NotificationProvider';

import config from './config';
import en from './translations/en.json';

import { TOKEN_KEY } from './lib/auth';
import { store } from './models/Store';
import { Router } from './routes/Router';
import { MemberProvider } from './models/Member';

configureModels({ apiBaseUrl: config.API_BASE_URL, jwtStorageKey: TOKEN_KEY });
configureStores(store);
appName.current = config.appName;

const translations = prepareTranslations(en);
const memberClient = new QueryClient();

type AppPropTypes = { auth: Auth };

export default function App(props: AppPropTypes) {
  return (
    <IntlProvider messages={translations}>
      <QueryClientProvider client={memberClient}>
        <NotificationProvider>
          <MemberProvider auth={props.auth}>
            <Router auth={props.auth} />
          </MemberProvider>
        </NotificationProvider>
      </QueryClientProvider>
    </IntlProvider>
  );
}
