// @flow

import * as React from 'react';
import get from 'lodash/fp/get';
import forEach from 'lodash/fp/forEach';

import Wizard, { getSubstepFromMatchParams } from '@kwara/components/src/Wizard/deprecated/Wizard';
import {
  MembershipApplications,
  type MembershipApplicationsType,
  type AttachmentsT,
  type OrganisationT
} from '../../models/MembershipApplications';

import { useNewUserStore } from '../../models/NewUserStore';
import { steps } from './config';

const translateAttachmentsToObject = (attachments: []) => {
  const attachmentsObj: AttachmentsT = {};
  forEach(attachment => {
    attachmentsObj[attachment.name] = {
      content: attachment.content,
      type: attachment.type
    };
  }, attachments);

  return attachmentsObj;
};

const saveMember = async (member: MembershipApplicationsType, storeOrganisation: OrganisationT, history) => {
  member.orgPermalink = get('permalink', storeOrganisation);
  member.kin = [member.kinPhoneNumber, member.kinFullName, member.kinDateOfBirth, member.kinRelationship];
  member.attachments = translateAttachmentsToObject(member.attachments);

  const success = await member.save({ with: ['idDocuments'] });
  if (!success) {
    throw member.errors;
  }

  history.replace('/joiningFee');
};

export const Register = ({ match, baseUrl, history }) => {
  const { storePhone, storeOrganisation, setPayLaterEnabled } = useNewUserStore();

  React.useEffect(() => {
    setPayLaterEnabled(true);
  }, [setPayLaterEnabled]);

  return (
    <>
      <Wizard
        analyticsId="AddMember"
        baseUrl={baseUrl}
        history={history}
        cancelReturnsTo={'/'}
        currentStep={match.params.step}
        currentSubStep={getSubstepFromMatchParams(match.params)}
        initialData={MembershipApplications.new({ phoneNumber: storePhone })}
        onSubmit={member => saveMember(member, storeOrganisation, history)}
        showCompletion={false}
        steps={steps()}
        startId="about"
        titleId={get('name', storeOrganisation)}
        animated={false}
        completionAutoConfirm={false}
        successSubtitleId="Register.success.subtitle"
        successButtonId="Register.success.button"
      />
    </>
  );
};
