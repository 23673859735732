import { attr, hasMany, belongsTo } from 'spraypaint';
import axios from 'axios';
import find from 'lodash/find';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import round from 'lodash/round';
import size from 'lodash/fp/size';
import join from 'lodash/fp/join';
import pipe from 'lodash/fp/pipe';
import split from 'lodash/fp/split';
import filter from 'lodash/fp/filter';
import values from 'lodash/fp/values';
import includes from 'lodash/includes';
import compact from 'lodash/fp/compact';
import toUpper from 'lodash/fp/toUpper';
import isEmpty from 'lodash/fp/isEmpty';
import _capitalize from 'lodash/fp/capitalize';

import { ValueOf, KeyOf, InferredModel } from 'GlobalTypes';

import { add } from '@kwara/lib/src/currency';
import { Logger } from '@kwara/lib/src/logger';
import { formatHumanDate } from '@kwara/lib/src/dates';
import { blobHeaders } from '@kwara/lib/src/fileDownload';

import { LoanType } from '../Loan';
import { IdDocument } from '../IdDocument';
import { AttachmentT } from '../Attachment';
import { ActivityType } from '../Activity';
import { Branch, BranchT } from '../Branch';
import MemberAddress, { MemberAddressType } from '../MemberAddress';
import { GuaranteeType } from '../Guarantee';
import { NextOfKin, NextOfKinT } from '../NextOfKin';
import Base, { IncludesT } from '../Base';
import filterEmptyValues from '../../lib/filterEmptyValues';
import { DeprecatedNextOfKin } from '../DeprecatedNextOfKin';
import { createCancellablePromise, SearchCancellation, SavingType, SavingProductId, IdDocumentType } from '../..';
import createModelErrors, { createErrorsFromApiResponse } from '../createModelErrors';

// Legacy
export const MemberStates = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  EXITED: 'EXITED',
  BLACKLISTED: 'BLACKLISTED'
};

// V1
export const MemberStatesV1 = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  EXITED: 'EXITED'
};

export const Titles = ['Mr', 'Mrs', 'Ms', 'Dr', 'Hon', 'Rev', 'Sis'] as const;
type TitleT = typeof Titles[number];

export const MemberEvents = {
  APPROVE: 'approve',
  ACTIVATE: 'activate', // v1 synonym for approve
  REJECT: 'reject',
  SOFT_REJECT: 'soft_reject',
  EXIT: 'exit',
  CLOSE: 'close' // v1 synonym for exit
};

export type MemberState = ValueOf<typeof MemberStates>;
export type MemberEvent = KeyOf<typeof MemberEvents>;

// Freeze so Flow can type correctly https://stackoverflow.com/a/51166430/1446845
export const EmploymentStatuses = Object.freeze({
  EMPLOYED: 'employed',
  SELF_EMPLOYED: 'self_employed',
  OTHER: 'other',
  STUDENT: 'student'
});
export type EmploymentStatusT = ValueOf<typeof EmploymentStatuses>;

type ApproveParams = {
  product_id?: SavingProductId;
  amount?: number;
  share_capital_amount?: number;
  notes?: string;
  comment?: string;
};

type RejectParams = {
  comment?: string;
};

type ExitParams = {
  comment?: string;
};

type TransitionParams = ApproveParams | ExitParams;

export const capitalize = (name: string) => {
  return pipe(split(' '), map(_capitalize), join(' '))(name);
};

export const fullName = (arr: string[]) => {
  return pipe(compact, map(capitalize), join(' '))(arr);
};

const Member = Base.extend({
  static: {
    jsonapiType: 'members',

    search({
      term,
      states = [MemberStates.ACTIVE, MemberStates.INACTIVE],
      limit
    }: {
      term: string;
      states?: MemberState[];
      limit?: number;
    }) {
      const cancelSource = axios.CancelToken.source();

      let url = `${Member.fullBasePath()}/search?q=${encodeURIComponent(term)}&filter[state]=${states.join(',')}`;

      if (limit) {
        url += `&limit=${limit}`;
      }

      const options = { ...Member.fetchOptions(), cancelToken: cancelSource.token };

      const promise = axios.get(url, options).then(
        response => {
          if (response.status > 299) throw new Error(`Response not OK`);

          const jsonResult = response.data;

          // See: https://github.com/jsonapi-suite/jsorm/blob/master/src/scope.ts#L335
          return jsonResult.data.map(record => {
            return Member.fromJsonapi(record, jsonResult.data);
          });
        },
        err => {
          if (axios.isCancel(err)) throw new SearchCancellation();

          throw err;
        }
      );

      return createCancellablePromise(promise, cancelSource);
    }
  },
  attrs: {
    id: attr(),
    orgPermalink: attr(),
    title: attr(),
    firstName: attr(),
    middleName: attr(),
    lastName: attr(),

    source: attr(),

    gender: attr(),
    dateOfBirth: attr(),
    maritalStatus: attr(),

    attachments: hasMany(),
    shareCapitalAccountId: attr(),
    shareCapitalAmount: attr(),

    addresses: hasMany('member_addresses'),

    // Live balance of the accounts
    totalLoansBalance: attr(),
    // Original amount of the loans regardless of how much has been paid off since
    totalLoans: attr(),
    totalSavings: attr(),
    totalGuaranteed: attr(),
    totalLiabilities: attr(),
    totalSelfGuaranteed: attr(),
    eligibleAmount: attr(),
    eligibilityMultiplier: attr(),
    standing: attr(),
    accruedInterest: attr(),

    mbankingStatus: attr(),

    guaranteedLoans: hasMany('loans'),
    dbGuarantees: hasMany('guarantees'),

    // Write:
    phoneNumber: attr(),
    secondaryPhoneNumber: attr(),
    email: attr(),
    subscribedToMbanking: attr(),

    kraPin: attr(),

    idDocuments: hasMany('members_id_documents'),

    notes: attr(),

    state: attr({ persist: false }),

    activities: hasMany(), // Only available in Member API
    savings: hasMany(),
    loans: hasMany(),
    nextOfKins: hasMany(),

    // Legacy data
    nextOfKin: belongsTo({ type: DeprecatedNextOfKin }),

    // Write
    kin: attr(),

    deprecatedNextOfKins: attr({ persist: false }),
    profession: attr(),
    employer: attr(),
    staffId: attr(),
    business: attr(),
    workEmail: attr(),
    employerEmail: attr(),
    employerPhoneNumber: attr(),

    employmentStatus: attr(),
    grossIncome: attr(),
    guaranteeableLoanAmount: attr(),
    netIncome: attr(),
    otherDeductibles: attr(),
    otherIncomeAmount: attr(),
    disposableIncomeAmount: attr(),
    incomeSource: attr(),
    termsOfService: attr(),
    availableIncome: attr(),
    totalMonthlyLoanPayments: attr(),

    govEmployee: attr(),
    isStaff: attr(),
    isDelegate: attr(),
    isDirector: attr(),
    isGroup: attr(),

    memberBankName: attr(),
    memberBankBranch: attr(),
    memberBankAccount: attr(),

    joiningFeeReference: attr(),
    branch: belongsTo({ type: Branch }) //Branch data only exists for Member App
  },
  methods: {
    // For some reason atm the models sent to the backend for saving a resource are slightly different
    // than the ones returned when querying for the same resource (WTF?).
    // For example when we request a member, his phone number and email are under:
    // { contact: phone_number: '+1234', email: 'a@b.c' }
    // But when we save it the backend expects all flattened fields, like:
    // { phone_number: '+1234', email: 'a@b.c' }
    // This means that we need to go back and forth from one shape to the other when receiving data,
    // using it prefill a form and then use the updated form to update the DB.
    // This is a hack to workaround this problem. Call this method after fetching and it will
    // prefill the form, so the shape expected in the frontend is rehydrated in the model.
    deserialize() {
      if (this.gender) {
        this.gender = toUpper(this.gender);
      }

      if (this.maritalStatus) {
        this.maritalStatus = toUpper(this.maritalStatus);
      }

      this.addresses = isEmpty(this.addresses) ? [new MemberAddress()] : this.addresses;
      this.deprecatedNextOfKins = pipe(get('attributes'), values, compact)(this.nextOfKin);

      if (isEmpty(this.nextOfKins)) {
        this.nextOfKins = [new NextOfKin({})];
      }

      return this;
    },
    totalObligations() {
      return add(this.totalLiabilities || 0, this.totalSelfGuaranteed || 0);
    },
    oneThirdNetIncome() {
      return round(Number(this.netIncome) / 3, 2);
    },
    isEventPermitted(event: MemberEvent) {
      return this.state && includes(this.state.permitted_events, event);
    },
    fullName() {
      return fullName([this.firstName, this.middleName, this.lastName]);
    },
    nameWithTitle() {
      if (this.title) {
        return `${this.title}. ${this.fullName()}`;
      }
      return this.fullName();
    },
    formattedDateOfBirth() {
      return this.dateOfBirth ? formatHumanDate(this.dateOfBirth) : null;
    },
    isPendingApproval() {
      return this.state.current === MemberStates.PENDING_APPROVAL;
    },
    pendingLoans() {
      return filter(l => l.isPendingApproval(), this.loans);
    },
    numberPendingLoans() {
      return size(this.pendingLoans());
    },
    isMbankingBlacklisted() {
      return this.mbankingStatus === 'DISALLOWED';
    },
    isApproved() {
      return [MemberStates.INACTIVE, MemberStates.ACTIVE].includes(this.state.current);
    },
    isExited() {
      return this.state.current === MemberStates.EXITED;
    },
    canBeExited() {
      return this.isEventPermitted(MemberEvents.EXIT) || this.isEventPermitted(MemberEvents.CLOSE);
    },
    joinedAt() {
      return this.createdAt;
    },
    getIdDocument(type) {
      const document = find(this.idDocuments, { type });
      return document ? document.documentId : null;
    },
    nationalId() {
      return this.getIdDocument('NATIONAL');
    },
    historicalId() {
      return this.getIdDocument('Historical Member ID');
    },
    address(type: 'physical' | 'postal') {
      return this.addresses.length > 0 ? this.addresses[0][`${type}Address`] : null;
    },
    savingsEligibleForTransactions() {
      return filter(saving => saving.transactionsPermitted(), this.savings);
    },
    loansEligibleForRepayment() {
      return filter(loan => loan.isApproved() && !loan.isPaidOff(), this.loans);
    },
    isEligibleForLoan() {
      return this.eligibleAmount > 0;
    },
    downloadStatement({
      from,
      to,
      include_additional_states
    }: {
      from: string;
      to: string;
      include_additional_states: boolean;
    }) {
      const memberId = this.id;
      const opts = Member.fetchOptions();
      const url = `${Member.url()}/${memberId}/statement.pdf`;
      const options = {
        params: { from, to, include_additional_states },
        ...blobHeaders(opts)
      };

      return Base.downloadFileFromUrl(url, options);
    },
    guarantorPDFfilename() {
      return join('_', ['member', this.id, 'loans_guaranteeing']);
    },
    downloadGuarantorPDF() {
      const memberId = this.id;
      const opts = Member.fetchOptions();
      const options = blobHeaders(opts);
      const fileName = this.guarantorPDFfilename();
      const url = `${Member.url()}/${memberId}/guarantees.pdf`;

      return Base.downloadFileFromUrl(url, options, fileName);
    },
    async approve(params: ApproveParams = {}): Promise<boolean> {
      if (this.isEventPermitted(MemberEvents.APPROVE) || this.isEventPermitted(MemberEvents.ACTIVATE)) {
        return await this.transition(MemberEvents.APPROVE, params);
      }

      this.errors = createModelErrors({
        base: 'APP_MEMBER_INVALID_STATE_TRANSITION'
      });

      return false;
    },
    async reject({ comment }: RejectParams = {}): Promise<boolean> {
      if (this.isEventPermitted(MemberEvents.REJECT)) {
        return await this.transition(MemberEvents.REJECT, { comment });
      }

      this.errors = createModelErrors({
        base: 'APP_MEMBER_INVALID_STATE_TRANSITION'
      });

      return false;
    },
    async softReject({ comment }: RejectParams = {}): Promise<boolean> {
      if (this.isEventPermitted(MemberEvents.SOFT_REJECT)) {
        if (comment) {
          return await this.transition(MemberEvents.SOFT_REJECT, { comment });
        } else {
          return await Promise.resolve(true);
        }
      }

      this.errors = createModelErrors({
        base: 'APP_MEMBER_INVALID_STATE_TRANSITION'
      });

      return false;
    },
    async exit({ comment }: ExitParams) {
      if (this.canBeExited()) {
        return await this.transition(MemberEvents.EXIT, { comment });
      }

      this.errors = createModelErrors({
        base: 'UI_APP_MEMBER_INVALID_STATE_TRANSITION_EXIT'
      });

      return false;
    },
    async transition(event: MemberEvent, params: TransitionParams | null = {}) {
      const url = `${Member.url(this.id)}/state`;
      const options = {
        ...Member.fetchOptions(),
        method: 'PUT',
        body: JSON.stringify({ data: { attributes: filterEmptyValues({ event, ...params }) } })
      };

      try {
        const response = await window.fetch(url, options);

        if (!response.ok) {
          const body = await response.json();
          this.errors = createErrorsFromApiResponse(body);
          return false;
        }
        return true;
      } catch (error) {
        Logger.error('Error transitioning member states', JSON.stringify(error));
        this.errors = createModelErrors({ base: 'APP_NETWORK_ERROR' });
        return false;
      }
    }
  }
});

/**
 * Returns a member will all included scopes
 *
 */
Member.full = () =>
  Member.includes('loans')
    .includes('savings')
    .includes('addresses')
    .includes('next_of_kins')
    .includes('attachments') // can't make it work atm
    .includes('id_documents');
// TODO: Enable this when loans member is guaranteeing
//       should be displayed
//.includes({ guaranteed_loans: 'product' })

// This is like calling new Member(), but it also initialises
// the relationships the we always want to have in the basic member
Member.new = (props: any = {}) => {
  const { idDocuments = [{}] } = props; // the default here makes sure we always have at least an empty Document model
  const idDocumentsList = idDocuments.map(d => new IdDocument(d));
  const addresses = [new MemberAddress()];
  return new Member({ ...props, idDocuments: idDocumentsList, addresses });
};

type MemberStateT = {
  current: 'ACTIVE' | 'INACTIVE';
  permittedEvents: string[];
};

export interface MemberType extends Omit<InferredModel<MemberType>, 'errors'> {
  orgPermalink: string;
  activities: ActivityType[];
  id: string;
  title: TitleT;
  firstName: string;
  middleName: string;
  lastName: string;
  totalSavings: number;
  totalLoansBalance: number;
  totalGuaranteed: number;
  totalSelfGuaranteed: number;
  totalLiabilities: number;
  totalObligations: () => string;
  eligibleAmount: number;
  dateOfBirth: string;
  contact: any;
  idDocuments: IdDocumentType[];
  phoneNumber: string;
  secondaryPhoneNumber: string;
  email: string;
  subscribedToMbanking: 'YES' | 'NO' | null;
  notes: string;
  state: MemberStateT;
  source: string;
  kin: string[];
  createdAt: string;
  maritalStatus: string | null;
  employer: string;
  staffId: string;
  business: string;
  mbankingStatus: 'DISALLOWED' | 'ALLOWED' | 'YES' | 'NO' | string;
  incomeSource: string;
  otherIncomeAmount: string;
  disposableIncomeAmount: string;
  availableIncome: string;
  totalMonthlyLoanPayments: string;
  gender: 'MALE' | 'FEMALE' | 'NON_BINARY' | 'OTHER' | null;
  totalLoans: number;
  standing: number;
  shareCapitalAccountId: string;
  shareCapitalAmount: number;
  accruedInterest: number;
  savings: SavingType[];
  address: (t: string) => string;
  formattedDateOfBirth: () => string;
  profession: string;
  kraPin: string;
  documents: [
    {
      type: 'passport' | 'national';
      id: string;
    }
  ];
  loans: LoanType[];
  guaranteedLoans: LoanType[];
  dbGuarantees: GuaranteeType[];
  employmentStatus: EmploymentStatusT | null;
  joinedAt: () => string;
  fullName: () => string;
  nameWithTitle: () => string;
  canBeExited: () => boolean;
  loansEligibleForRepayment: () => LoanType[];
  savingsEligibleForTransactions: () => SavingType[];
  isEligibleForLoan: () => boolean;
  historicalId: () => string;
  exit: (params: ExitParams) => Promise<boolean>;
  isExited: () => boolean;
  downloadGuarantorPDF: () => Promise<void>;
  downloadStatement: ({ from, to }: { from: string; to: string }) => Promise<void>;
  isPendingApproval(): boolean;
  pendingLoans(): LoanType[];
  numberPendingLoans(): number;
  isMbankingBlacklisted(): boolean;
  isApproved(): boolean;
  guarantorPDFfilename(): string;
  approve(params?: ApproveParams): Promise<boolean>;
  reject({ comment }?: RejectParams): Promise<boolean>;
  softReject({ comment }?: RejectParams): Promise<boolean>;

  employerPhoneNumber: string;
  joiningFeeReference?: string;
  attachments: AttachmentT[];
  workEmail: string;
  employerEmail: string;

  grossIncome: string;
  guaranteeableLoanAmount: string;
  netIncome: string;
  oneThirdNetIncome: () => number;
  otherDeductibles: string;

  termsOfService: 'Contract' | 'Permanent';
  govEmployee: boolean;
  isStaff: boolean;
  isDelegate: boolean;
  isDirector: boolean;
  isGroup: boolean;

  memberBankName: string;
  memberBankBranch: string;
  memberBankAccount: string;

  createdAtDate: () => Date;
  includes: (p: IncludesT) => MemberType;
  find: (p: string) => Promise<{ data: MemberType }>;
  errors: string[];
  branch: BranchT; //Branch data only exists for Member App

  nextOfKins: NextOfKinT[];

  addresses: MemberAddressType[];
}

export default Member;
