import { attr, belongsTo } from 'spraypaint';

import Base, { BaseModel } from './Base';
import { User, UserT } from './User';
import { TillT } from './Till';

export const TillTellerLink = Base.extend({
  static: {
    jsonapiType: 'till_teller_links'
  },
  attrs: {
    tellerId: attr(),
    tillId: attr(),
    teller: belongsTo({ type: User }),
    till: belongsTo()
  }
});

export interface TillTellerLinkT extends BaseModel<TillTellerLinkT> {
  tellerId: string;
  tillId: string;
  teller: UserT;
  till: TillT;
}
