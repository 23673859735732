import 'babel-polyfill';
import 'raf/polyfill'; // React and old IE support

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import auth from './lib/auth';

ReactDOM.render(
  <React.StrictMode>
    <App auth={auth} />
  </React.StrictMode>,
  document.getElementById('root')
);
