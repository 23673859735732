import { attr, hasMany, hasOne } from 'spraypaint';
import axios from 'axios';

import filter from 'lodash/fp/filter';
import reduce from 'lodash/fp/reduce';
import size from 'lodash/fp/size';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import some from 'lodash/fp/some';
import isEmpty from 'lodash/fp/isEmpty';
import values from 'lodash/fp/values';
import keys from 'lodash/fp/keys';

import { InferredModel } from 'GlobalTypes';

import { sumBy, add } from '@kwara/lib/src/currency';

import Base from './Base';
import { UserT } from './User';

import { LoanApplicationApproval, LoanApplicationApprovalT } from './LoanApplicationApproval';
import { PendingLoanDisbursement, LoanDisbursementType } from './LoanDisbursement';
import { LoanApplicationNote, LoanApplicationNoteT } from './LoanApplicationNote';
import Loan, { LoanType } from './Loan';
import { LoanApplicationFee } from './LoanApplicationFee';
import { LoanAttachmentT } from './LoanAttachment';

export const Actions = Object.freeze({
  REFINANCE: 'REFINANCE'
});

export type LoanApplictionActionsT = 'REFINANCE';

export function totalBalance(loans: LoanType[]) {
  return sumBy('totalBalance', loans);
}

export const LoanApplication = Base.extend({
  static: {
    jsonapiType: 'loan_applications'
  },
  attrs: {
    loanId: attr(),
    action: attr(),
    createdBy: attr(),
    loanApplicationApprovals: hasMany({ type: LoanApplicationApproval }),
    loanApplicationNotes: hasMany({ type: LoanApplicationNote }),
    payOffLoans: hasMany({ type: Loan }),
    loanApplicationFees: hasMany({ type: LoanApplicationFee }),
    totalPayoffAmount: attr(),
    totalPayoffInstallmentAmount: attr(),
    installmentAmount: attr(),
    paidOffLoanAmounts: attr(),
    employmentStatus: attr(),
    grossIncome: attr(),
    netIncome: attr(),
    otherDeductibles: attr(),
    otherIncomeAmount: attr(),
    disposableIncomeAmount: attr(),
    incomeSource: attr(),
    termsOfService: attr(),
    approvedBy: attr(),
    approvedAt: attr(),
    totalMonthlyLoanRepayment: attr(),
    disbursementType: attr(),
    requestedAmount: attr(),
    recommendedAmount: attr(),
    loanApplicationAttachments: hasMany(),
    disbursement: hasOne({ type: PendingLoanDisbursement })
  },
  methods: {
    role() {
      return get('[0].user.role.name', this.loanApplicationApprovals);
    },
    approversNeeded() {
      return size(this.loanApplicationApprovals);
    },
    approvals() {
      return size(filter(approval => get('state.current', approval) === 'approved', this.loanApplicationApprovals));
    },
    canBeApprovedBy(user: UserT) {
      return (
        isEmpty(this.loanApplicationApprovals) ||
        some(approval => get('user.id', approval) === user.id, this.loanApplicationApprovals)
      );
    },
    alreadyApprovedBy(user: UserT) {
      return some(
        approval => get('user.id', approval) === user.id && get('state.current', approval) === 'approved',
        this.loanApplicationApprovals
      );
    },
    isFinalReview() {
      return this.approvals() === this.approversNeeded() - 1;
    },
    isRefinancing() {
      return this.action === Actions.REFINANCE;
    },
    totalPayOffLoans() {
      return totalBalance(this.payOffLoans);
    },
    totalPaidOffLoans() {
      return reduce(
        (acc: string | number, curr: string | number) => add(acc, curr),
        0,
        values(this.paidOffLoanAmounts)
      );
    },
    paidOffLoans() {
      const ids = keys(this.paidOffLoanAmounts);
      return map(
        id => ({
          id,
          amount: get(['paidOffLoanAmounts', id], this),
          state: { current: 'CLOSED' }
        }),
        ids
      );
    },
    getAttachmentFromId(attachmentId: string) {
      const url = `${LoanApplication.url()}/${this.id}/attachments/${attachmentId}`;
      const opts = LoanApplication.fetchOptions();
      return axios
        .get(url, opts)
        .then(r => r.data)
        .then(r => ({ ...r, id: attachmentId }));
    }
  }
});

export type FeeApplicationT = {
  feeId: string;
  amount?: string;
  isMarkedForDestruction?: boolean;
  dup(): FeeApplicationT;
};

export type DisbursementTypeT = null | 'normal' | 'early_release' | 'staggered';

export interface LoanApplicationT extends InferredModel<LoanApplicationT> {
  approvedBy: string | null;
  approvedAt: string | null;
  loanId: string;
  createdBy: string;
  action: LoanApplictionActionsT | null;
  loanApplicationApprovals: LoanApplicationApprovalT[];
  loanApplicationNotes: LoanApplicationNoteT[];
  loanApplicationAttachments: LoanAttachmentT[];
  payOffLoans: LoanType[];
  role: () => string;
  approversNeeded: () => number;
  approvals: () => number;
  canBeApprovedBy: (u: UserT) => boolean;
  alreadyApprovedBy: (u: UserT) => boolean;
  isFinalReview: () => boolean;
  isRefinancing: () => boolean;
  totalPayOffLoans: () => string;
  totalPayoffAmount: string;
  totalPayoffInstallmentAmount: string;
  installmentAmount: string;
  employmentStatus: string;
  grossIncome: string;
  netIncome: string;
  otherDeductibles: string;
  otherIncomeAmount: string;
  disposableIncomeAmount: string;
  incomeSource: string;
  termsOfService: string;
  totalMonthlyLoanRepayment: number;
  loanApplicationFees: FeeApplicationT[];
  disbursementType: DisbursementTypeT;
  requestedAmount: string;
  recommendedAmount: string;
  getAttachmentFromId(attachmentId: string): Promise<any>;
  disbursement: LoanDisbursementType;
}
