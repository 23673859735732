// @flow

import * as React from 'react';
import cx from 'classnames';
import concat from 'lodash/fp/concat';

import { findProfilePicture } from '@kwara/components/src/MemberUploadWidget';
import { toBase64Img } from '@kwara/components/src/UploadWidgetUI';
import { useAttachment } from '@kwara/components/src/MemberUploadWidget/components/Viewer';
import { type AttachmentT } from '@kwara/models/src';
import zIndices from '@kwara/lib/src/zIndices';

import fallback from './fallback.png';

import styles from './index.module.css';

const Size = Object.freeze({
  small: 'small',
  regular: 'regular',
  medium: 'medium',
  big: 'big'
});

interface Props {
  size: $Values<typeof Size>;
  src?: string | null;
  className?: string;
}

export const Image = ({ size, src }: Props) => (
  <div
    className={cx(
      styles.ProfilePhoto,
      styles[size],
      'bg-teal-200 flex-none',
      !src && size === 'medium' ? styles.paddingMedium : ''
    )}
    style={{
      backgroundImage: `url(${src || fallback})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundOrigin: 'content-box',
      backgroundRepeat: 'no-repeat'
    }}
  />
);

interface ProfilePhotoProps extends Props {
  memberId?: string;
  attachments?: AttachmentT | AttachmentT[];
  isExpandable?: boolean;
}

// This is a stateful version that will take care of extracting the
// image from a sequence of attachments and display its content
export const ProfilePhoto = (props: ProfilePhotoProps) => {
  const { attachments = [], size, memberId, isExpandable } = props;
  const [expanded, setExpanded] = React.useState(false);
  const profilePicture = findProfilePicture(concat([], attachments));
  const [result] = useAttachment(profilePicture.id, memberId, [profilePicture]);
  const source = result.content ? `${toBase64Img(result.content, result.type)}` : null;

  if (!isExpandable) {
    return <Image src={source} size={size} />;
  }

  return (
    <>
      {expanded ? (
        <div className={cx(styles.ExpanderOverlay, zIndices.OverlayMenus)}>
          <button className={styles.ExpandBtn} onClick={() => setExpanded(false)}>
            Close
          </button>
          <div className={cx(styles.ExpandedImageWrap)}>
            <img className={styles.ExpandedImage} src={source} alt="Profile" />
          </div>
        </div>
      ) : null}
      <div className="relative">
        <button
          className={cx(styles.ExpandBtn, {
            [styles.ExpandBtnActive]: source
          })}
          onClick={() => (source ? setExpanded(true) : null)}
        >
          Expand
        </button>
        <Image src={source} size={size} />
      </div>
    </>
  );
};

ProfilePhoto.Size = Size;
