import { attr } from 'spraypaint';

import Base, { BaseModel } from './Base';

export const GlAccount = Base.extend({
  static: {
    jsonapiType: 'proxy_gl_accounts',
    endpoint: '/gl_accounts'
  },
  attrs: {
    glCode: attr(),
    glName: attr(),
    accountType: attr()
  }
});

export interface GlAccountT extends BaseModel<GlAccountT> {
  glCode: number;
  glName: string;
  accountType: string;
}
