// @flow

import { attr, hasMany } from 'spraypaint';

import Base, { type BaseModel } from './Base';
import { AppPermission, type AppPermissionT } from './AppPermission';
import { type UserT } from './User';

export const AppRole = Base.extend({
  static: {
    jsonapiType: 'app_roles',
    endpoint: '/auth/app_roles',
    apiNamespace: ''
  },
  attrs: {
    name: attr(),
    description: attr(),
    appPermissions: hasMany({ type: AppPermission }),
    users: hasMany()
  }
});

export interface AppRoleT extends BaseModel<AppRoleT> {
  name: string;
  description: string;
  appPermissions: AppPermissionT[];
  users: UserT[];
}
