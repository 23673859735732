import React from 'react';

import styles from '../QuickAction.module.scss';

export function IcnSwitch() {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles['icn-switch']}
    >
      <circle cx="20.5" cy="20" r="20" />
      <path
        d="M13 24.1666H14.9975C15.6674 24.1665 16.3275 24.0049 16.9217 23.6955C17.5159 23.3861 18.0267 22.9379 18.4108 22.3891L18.5583 22.1783M21.6083 17.8224L21.7558 17.6108C22.14 17.0619 22.6508 16.6137 23.245 16.3043C23.8392 15.9949 24.4992 15.8333 25.1692 15.8333H28"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5 13.3333L28 15.8333L25.5 18.3333M13 15.8333H14.9975C15.6674 15.8333 16.3275 15.9949 16.9217 16.3043C17.5159 16.6137 18.0267 17.0619 18.4108 17.6108L21.7558 22.3891C22.14 22.9379 22.6508 23.3861 23.245 23.6955C23.8392 24.0049 24.4992 24.1665 25.1692 24.1666H28"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M25.5 26.6665L28 24.1665L25.5 21.6665" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
