import React, { useEffect } from 'react';

import { segmentTrack } from '@kwara/components/src/Segment';
import { SubStepComponentProps } from '@kwara/components/src/Wizard/latest/types/sharedTypes';

import { hasExactLoan } from '@kwara/components/src/pages/LoanAdd/components/Configure';
import { LoanBanner } from '@kwara/components/src/pages/LoanAdd/components/Configure/LoanBanner';
import { Installments } from '@kwara/components/src/pages/LoanAdd/components/Configure/Installments';
import { LoanTermsFields } from '@kwara/components/src/pages/LoanAdd/components/Configure/LoanTermsFields';
import { PrincipalAmountField } from '@kwara/components/src/pages/LoanAdd/components/Configure/PrincipalAmountField';
import { LoanClassificationFields } from '@kwara/components/src/pages/LoanAdd/components/Configure/LoanClassificationFields';

import { InstantLoanAddDataType } from '../..';

export function Configure(props: SubStepComponentProps<InstantLoanAddDataType>) {
  const { StackChild, TextField, data } = props;

  useEffect(() => {
    segmentTrack('Instant Loan: Configure page');
  }, []);

  return (
    <StackChild>
      <LoanBanner {...props} />
      {hasExactLoan(data) ? null : (
        <>
          <PrincipalAmountField {...props} />
          <Installments
            TextField={TextField}
            minDuration={data.product.minDuration}
            maxDuration={data.product.maxDuration}
          />
          {data.isSasraRegulated ? <LoanClassificationFields {...props} /> : null}
          <LoanTermsFields {...props} />
        </>
      )}
    </StackChild>
  );
}

Configure.validate = (_data: InstantLoanAddDataType) => ({
  ...PrincipalAmountField.validate,
  ...LoanTermsFields.validate,
  'classifications.[0]': {
    isRequired: (_: string, data: InstantLoanAddDataType) => data.isSasraRegulated
  },
  'classifications.[1]': {
    isRequired: (_: string, data: InstantLoanAddDataType) => data.isSasraRegulated
  },
  'classifications.[2]': {
    isRequired: (_: string, data: InstantLoanAddDataType) => data.isSasraRegulated
  }
});
