import { attr, belongsTo, hasOne, hasMany } from 'spraypaint';
import axios from 'axios';
import reduce from 'lodash/fp/reduce';
import map from 'lodash/fp/map';

import Base, { BaseModel } from './Base';
import { Branch, BranchT } from './Branch';
import { Organisation, OrganisationT } from './Organisation';
import { Role, RoleT } from './Role';
import { AppRole, AppRoleT } from './AppRole';
import { UserAppRole } from './UserAppRole';

import { fullName } from './Member';

const userAttributes = {
  id: attr(),
  firstName: attr(),
  lastName: attr(),
  middleName: attr(),
  email: attr(),
  phoneNumber: attr(),
  passwordExpiryAt: attr(),
  passwordExpired: attr(),
  emailConfirmed: attr(),
  currentBranchId: attr(),
  branch: belongsTo({ type: Branch }),
  role: hasOne({ type: Role }),
  appRoles: hasMany({ type: AppRole }),
  glAccountId: attr(),
  apiInstance: attr(),
  isAdmin: attr(),
  mfaActivated: attr(),
  deactivatedAt: attr(),
  organisation: belongsTo({ type: Organisation })
};

export const User = Base.extend({
  static: {
    jsonapiType: 'users',
    endpoint: '/organisations/users',
    apiNamespace: ''
  },
  attrs: {
    ...userAttributes
  },
  methods: {
    fullName() {
      return fullName([this.firstName, this.middleName, this.lastName]);
    },
    async unAssignRole(roleId) {
      try {
        await UserAppRole.remove(this.id, roleId);
        return true;
      } catch (e) {
        throw e;
      }
    },
    async assignRole(roleId) {
      try {
        await UserAppRole.add(this.id, roleId);
        return true;
      } catch (e) {
        throw e;
      }
    },
    getPermissions() {
      const permissionObjects = reduce(
        (result, current) => {
          return [...result, ...current.appPermissions];
        },
        [],
        this.appRoles
      );

      return map(p => p.name, permissionObjects);
    },
    hasActivatedMfa() {
      return Boolean(this.mfaActivated);
    },
    statusActivatedMfa() {
      return this.mfaActivated ? 'ACTIVATED' : 'NOT_ACTIVATED';
    },
    deactivate() {
      const url = `${User.baseUrl}/auth/${this.id}/deactivate`;
      const opts = User.fetchOptions();
      const data = {};

      return axios.put(url, data, opts).then(res => res.data);
    },
    reactivate() {
      const url = `${User.baseUrl}/auth/${this.id}/activate`;
      const opts = User.fetchOptions();
      const data = {};

      return axios.put(url, data, opts).then(res => res.data);
    }
  }
});

export const Profile = Base.extend({
  static: {
    endpoint: '/profile'
  },
  attrs: { ...userAttributes }
});

export interface UserT extends BaseModel<UserT> {
  id: string;
  firstName: string;
  email: string;
  emailConfirmed: boolean;
  branch: BranchT;
  role: RoleT;
  appRoles: AppRoleT[];
  passwordExpiryAt: string;
  passwordExpired: boolean;
  apiInstance: string;
  glAccountId: string;
  isAdmin: boolean;
  mfaActivated: boolean;
  deactivatedAt: string;
  currentBranchId: string;
  organisation: OrganisationT;
  fullName: () => string;
  assignRole: (id: string) => Promise<boolean>;
  unAssignRole: (id: string) => Promise<boolean>;
  deactivate: () => Promise<void>;
  reactivate: () => Promise<void>;
}
