import * as React from 'react';
import { SubscribedTextArea } from '../../../../Wizard/latest/components/FormFields/FormFields';

type LoanPurposeProps = {
  TextArea: typeof SubscribedTextArea;
};

export const LoanPurpose = ({ TextArea }: LoanPurposeProps) => {
  return (
    <TextArea
      name="purpose"
      labelId="LoanAdd.Connect.Configure.Classification.label"
      placeholderId="LoanAdd.Connect.Configure.Classification.placeholder"
    />
  );
};

LoanPurpose.validate = {
  purpose: {
    isRequired: () => true
  }
};
