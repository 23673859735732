import { InferredModel } from 'GlobalTypes';
import { attr } from 'spraypaint';

import Base from '../../Base';

export const BalanceSheetRow = Base.extend({
  static: {
    jsonapiType: 'abstract_balance_sheet_report_rows'
  },
  attrs: {
    glCode: attr(),
    glName: attr(),
    balanceType: attr(),
    balance: attr()
  }
});

export interface BalanceSheetRowT extends Omit<InferredModel<typeof BalanceSheetRow>, 'errors'> {
  glCode: string;
  glName: string;
  balanceType: string;
  balance: number;
}
