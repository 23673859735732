import { attr, hasMany } from 'spraypaint';
import queryString from 'query-string';

import { InferredModel } from 'GlobalTypes';

import Base from '../../Base';

import { GlReportRowT } from './Row';
import { downloadExcel } from '../utils';

export type DownloadXlsxParams = {
  start_date: string;
  end_date: string;
  gl_account_id: string;
};

export const GlReport = Base.extend({
  static: {
    jsonapiType: 'abstract_general_ledger_reports',
    endpoint: '/accounting/reports/general_ledger',
    downloadXlsx(params: DownloadXlsxParams) {
      const queries = queryString.stringify(params);
      const url = `${GlReport.url()}.xlsx?${queries}`;
      const opts = GlReport.fetchOptions();
      const fileName = `general_ledger.xlsx`;

      return downloadExcel({ fileName, url, opts });
    }
  },
  attrs: {
    openingBalance: attr(),
    closingBalance: attr(),
    rows: hasMany('abstract_general_ledger_report_rows')
  }
});

export interface GlReportT extends Omit<InferredModel<typeof GlReport>, 'errors'> {
  rows: GlReportRowT[];
  openingBalance: number;
  closingBalance: number;
}
