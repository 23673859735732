// @flow

import { attr } from 'spraypaint';
import Base, { type BaseModel } from './Base';

export const StkPushTransactionStates = Object.freeze({
  PENDING: 'pending',
  CANCELLED: 'cancelled',
  SUCCEEDED: 'succeeded',
  INSUFFICIENT_AMOUNT: 'insufficient_amount',
  FAILED: 'failed'
});
export type StkPushTransactionStatesT = $Values<typeof StkPushTransactionStates>;

export const StkPushTransaction = Base.extend({
  static: {
    jsonapiType: 'core_stk_push_transactions',
    endpoint: '/mpesa/stk_push_transactions'
  },
  attrs: {
    state: attr(),
    amount: attr(),
    type: attr(),
    receivedAt: attr(),
    account: attr(),
    reference: attr()
  }
});

export interface StkPushTransactionT extends BaseModel<StkPushTransactionT> {
  state: StkPushTransactionStatesT;
  amount: number;
  type: string;
  receivedAt: string;
  account: string;
  reference: string;
}
