import * as React from 'react';
import get from 'lodash/fp/get';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { FinancialProduct, IncomeRange, AgeGroup } from 'RecommendedOrganisationTypes';

import { createContext } from '@kwara/lib/src/utils/createContext';
import Wizard, { getSubstepFromMatchParams } from '@kwara/components/src/Wizard/deprecated/Wizard';

import { steps } from './config';
import { FindingSacco } from './pages/FindingSacco';
import { FINANCIAL_PRODUCTS } from '../../models/RecommendedOrganisation/data';
import { useSuggestSaccoContextValues } from './hooks/useSuggestSaccoContextValues';

const [SuggestSaccoProvider, useSuggestSaccoContext] = createContext<ReturnType<typeof useSuggestSaccoContextValues>>(
  'SuggestSaccoContext'
);

export type FinancialProductFormState = {
  type: FinancialProduct;
  value: FinancialProduct | string;
};

export type FormState = {
  financialProduct: FinancialProductFormState;
  incomeRange: IncomeRange;
  ageGroup: AgeGroup;
  financialProductCustom?: string;
};

type Params = { step: string; subStep: string };

function SaccoRecommendationFlow() {
  const history = useHistory();
  const match = useRouteMatch<Params>();
  const values = useSuggestSaccoContext();

  return (
    <Wizard
      steps={steps}
      history={history}
      analyticsId="SaccoRecommendationFlow"
      startId="financialProducts"
      baseUrl="/saccoRecommendation"
      cancelReturnsTo="/joinSacco"
      titleId="SaccoRecommendationFlow.title"
      currentStep={get('params.step', match)}
      initialData={{
        financialProduct: {
          type: Object.values(FINANCIAL_PRODUCTS).includes(values.financialProduct)
            ? values.financialProduct
            : FINANCIAL_PRODUCTS.OTHERS,
          value: values.financialProduct
        },
        incomeRange: values.incomeRange,
        ageGroup: values.ageGroup,
        financialProductCustom: values.financialProductCustom
      }}
      currentSubStep={getSubstepFromMatchParams(match.params)}
      onSubmit={values.onSubmit}
      onRenderCustomCompletionScreen={(onCancel: () => void, _state: FormState) => <FindingSacco onCancel={onCancel} />}
    />
  );
}

export { useSuggestSaccoContext, SaccoRecommendationFlow, SuggestSaccoProvider };
