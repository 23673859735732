import * as React from 'react';
import cx from 'classnames';

import styles from './picker.module.scss';

type PickerPropTypes = {
  isActive: boolean;
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export function Picker({ isActive, children, onClick }: PickerPropTypes) {
  return (
    <button
      type="button"
      className={cx(styles['picker'], isActive ? styles['picker--active'] : styles['picker--inactive'])}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
