import React from 'react';

import { LoanFormData } from 'LoanSharedTypes';

import { steps } from './config';
import { useStore } from '../../models/Member';
import { useCreateLoan } from './hooks/useCreateLoan';
import { LoanAdd, LoanAddPropTypes } from '../LoanAdd';

export interface InstantLoanAddDataType extends LoanFormData {
  isSasraRegulated: boolean;
}

export function LoanAddInstant(props: LoanAddPropTypes) {
  const createLoan = useCreateLoan();
  const store = useStore();

  return (
    <LoanAdd
      isInstantLoan
      steps={steps}
      extraData={{ isSasraRegulated: store.isSasraRegulated }}
      onSubmit={createLoan}
      {...props}
    />
  );
}
