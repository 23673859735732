import map from 'lodash/fp/map';

import { Text, Currency } from './Intl';
import Redeemer from './InvitationRedeem/components/Redeemer';
import { LoanBanner } from './pages/LoanAdd/components/Configure/LoanBanner';
import { Configure } from './pages/LoanAdd/components/Configure';
import { Segment } from '@kwara/components/src/Segment';
import { BankSelect } from '@kwara/components/src/Form';
import { Bank, BankBranch } from '@kwara/components/src/Bank';
import { JoiningFeePayment } from '@kwara/components/src/pages/MemberAdd/components/JoiningFeePayment';

// When we want to share between the 2 apps a component that needs access to the store
// (that is app-specific) we pass it to this function so the correct store is passed

export function configureStore(component, store) {
  component.appStore = store;
  return component;
}

// List here which component should be passed the store
const components = [
  Segment,
  Bank,
  BankBranch,
  Currency,
  Text,
  Redeemer,
  BankSelect,
  JoiningFeePayment,
  LoanBanner,
  Configure
];

export function configureStores(store) {
  return map(c => configureStore(c, store), components);
}
