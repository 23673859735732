//@flow
import * as React from 'react';

import { DateTime } from '@kwara/components/src/Intl';
import { ProfilePhoto } from '@kwara/components/src/ProfilePhoto';

import styles from './index.module.scss';

type Props = {
  body: React.Node,
  date: Date,
  title: React.Node,
  meta?: React.Node
};

const ActivityItem = ({ body, date, meta, title }: Props) => (
  <div className={`flex justify-start items-start ${styles.ActivityItem}`}>
    <div className={`flex flex-column ${styles.Indicator}`} />

    <div className="flex-m flex-l">
      <time className={`nowrap flex-none kw-text-small grey-300 ${styles.Timestamp}`} dateTime={date} title={date}>
        <DateTime value={date} />
      </time>

      <div className="mr3 flex-none dn db-m db-l">
        <ProfilePhoto size="small" />
      </div>

      <div className={styles.Info}>
        <h4 className="mt2 mb0 kw-text-medium kw-weight-bold grey-500">{title}</h4>
        {meta && <p className="ma0 kw-text-regular grey-400">{meta}</p>}
        <div className="mt2 kw-text-regular grey-400">{body}</div>
      </div>
    </div>
  </div>
);

export default ActivityItem;
