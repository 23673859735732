import React from 'react';
import { Currency } from '@kwara/components/src/Intl';
import { CommonHeader } from '../../../../components/CommonHeader';
import { MemberType } from 'shared-models/src/models/Member';

type GuarantorSummaryProps = {
  guarantors: { amount: number; member: MemberType }[];
};

export function GuarantorSummary({ guarantors }: GuarantorSummaryProps) {
  return (
    <>
      <CommonHeader
        labelIdLeft="LoanAdd.Summary.Subtitle.identification"
        labelIdRight="LoanAdd.Summary.Subtitle.amountGuaranteee"
      />
      {guarantors.map((guarantor, i) => (
        <div className="flex mv1" key={i.toString()}>
          <div className="flex w-50 items-center ml1">
            <span>{guarantor.member.fullName()}</span>
          </div>
          <div>
            <Currency format="currency" value={guarantor.amount} />
          </div>
        </div>
      ))}
    </>
  );
}
