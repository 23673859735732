// @flow

import { attr, belongsTo, hasMany } from 'spraypaint';
import axios from 'axios';
import keys from 'lodash/fp/keys';

import { handleDownload, SupportedExtensions, SupportedMimeTypes, blobHeaders } from '@kwara/lib/src/fileDownload';
import { prepareFileForUpload } from '@kwara/lib/src/fileUpload';
import { Logger } from '@kwara/lib/src/logger';

import { createErrorsFromApiResponse } from './createModelErrors';
import Base from './Base';
import { BatchTransaction, type BatchTransactionT } from './BatchTransaction';
import { type UserT } from './User';

const supportedMimeTypes = Object.freeze({
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
});

const mimeTypes = Object.freeze(keys(supportedMimeTypes));

export const BatchTransactionImport = Base.extend({
  static: {
    jsonapiType: 'batch_transaction_imports',
    getTemplate() {
      const opts = BatchTransactionImport.fetchOptions();
      const options = blobHeaders(opts);

      const url = `${BatchTransactionImport.fullBasePath()}/batch_transactions_template`;
      return axios(url, options).then(res => {
        try {
          handleDownload({ data: res.data, mimeTypes });
        } catch (e) {
          Logger.log('Error reading batch transactions template response ', JSON.stringify(e));
          return Promise.reject(e);
        }
        // The main result of this call is a side effect (the file download kicking off in the
        // client) so we're not interested in returning any result apart from the success/failure
        // of the operation.
        return Promise.resolve();
      });
    },
    async uploadTemplate(file: File): Promise<string> {
      const { name, content, type } = await prepareFileForUpload(file);

      const opts = BatchTransactionImport.fetchOptions();
      const data = {
        attributes: {
          name,
          type,
          content
        }
      };
      const url = `${BatchTransactionImport.fullBasePath()}/batch_transaction_imports`;
      return axios.post(url, { data }, opts).catch(({ response }) => {
        throw createErrorsFromApiResponse(response.data);
      });
    }
  },
  attrs: {
    name: attr(),
    byteSize: attr(),
    content_type: attr(),
    state: attr({ persist: false }),
    user: belongsTo(),
    batchTransactions: hasMany({ type: BatchTransaction })
  },
  methods: {
    async review(eventData) {
      const opts = BatchTransactionImport.fetchOptions();
      const data = {
        attributes: {
          ...eventData
        }
      };

      const url = `${BatchTransactionImport.fullBasePath()}/batch_transaction_imports/${this.id}/state`;

      return axios.put(url, { data }, opts).catch(({ response }) => {
        throw createErrorsFromApiResponse(response.data);
      });
    },

    async downloadBatchTransactionUI() {
      const opts = BatchTransactionImport.fetchOptions();
      const options = blobHeaders(opts);

      const url = `${BatchTransactionImport.fullBasePath()}/batch_transaction_imports/${this.id}.xlsx`;
      return axios(url, options).then(res => {
        try {
          handleDownload({
            data: res.data,
            fileName: 'Batch Report ' + this.id + ' ' + this.name
          });
        } catch (e) {
          Logger.log('Error reading batch transactions template response ', JSON.stringify(e));
          return Promise.reject(e);
        }
        // The main result of this call is a side effect (the file download kicking off in the
        // client) so we're not interested in returning any result apart from the success/failure
        // of the operation.
        return Promise.resolve();
      });
    }
  }
});
BatchTransactionImport.SupportedMimeTypes = SupportedMimeTypes;
BatchTransactionImport.SupportedExtensions = SupportedExtensions;

export interface BatchTransactionPayloadT {
  event: 'approve' | 'reject';
  notes?: string;
  batch_transactions_ids: string[];
  apply_to_all?: boolean;
}
export type BatchTransactionImportT = {
  id: string,
  name: string,
  byteSize: string,
  contentType: string,
  state: {
    current: string
  },
  user: UserT,
  batchTransactions: BatchTransactionT[],
  review: (p: BatchTransactionPayloadT) => Promise<null>
};
