// @flow
import * as React from 'react';
import get from 'lodash/fp/get';

import Wizard, { getSubstepFromMatchParams } from '@kwara/components/src/Wizard/deprecated/Wizard';
import { Loadable } from '@kwara/components/src/Loadable';
import { segmentTrack } from '@kwara/components/src/Segment';
import { useLoan, useMpesaLimit, useMpesaTransaction } from '@kwara/models/src/models/request/hooks';
import { MpesaTransaction, MpesaTransactionTypes, formatPhoneNumber } from '@kwara/models/src/models/MpesaTransaction';

import { PollMpesaTransaction } from '../../components/PollMpesaTransaction';
import { steps } from './config';

const { useState } = React;

const analyticsId = 'LoanRepayment';
const includes = ['product'];
export const LoanRepayment = props => {
  const { baseUrl, cancelReturnsTo, match, history } = props;
  const [transactionId, setTransactionId] = useState(null);

  const r1 = useMpesaLimit();
  const r2 = useLoan(match.params.loanId, includes);

  const submit = async data => {
    segmentTrack('LoanRepayment Submit Button Clicked');

    const { loan, phoneNumber, amount } = data;
    const mpesaTransaction = new MpesaTransaction({
      phoneNumber: formatPhoneNumber(phoneNumber),
      amount,
      accountId: loan.id,
      paymentType: MpesaTransactionTypes.REPAYMENT
    });

    const didSave = await mpesaTransaction.save();
    if (!didSave) {
      throw mpesaTransaction.errors;
    }

    setTransactionId(mpesaTransaction.id);
  };

  return (
    <Loadable {...r1}>
      {mpesaLimit => (
        <Loadable {...r2}>
          {loan => (
            <Wizard
              analyticsId={analyticsId}
              baseUrl={baseUrl}
              history={history}
              cancelReturnsTo={cancelReturnsTo}
              currentStep={get('params.step', match)}
              currentSubStep={getSubstepFromMatchParams(match.params)}
              initialData={{ loan, mpesaLimit }}
              onSubmit={submit}
              steps={steps}
              startId="amount"
              titleId="LoanRepayment.title"
              onRenderCustomCompletionScreen={onCancel => (
                <PollMpesaTransaction
                  analyticsId={analyticsId}
                  id={transactionId}
                  onConfirm={onCancel}
                  transactionHook={useMpesaTransaction}
                />
              )}
            />
          )}
        </Loadable>
      )}
    </Loadable>
  );
};
