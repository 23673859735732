import { attr, belongsTo } from 'spraypaint';

import Base, { BaseModel } from './Base';
import { BankT } from './Bank';

export const BankBranch = Base.extend({
  static: {
    jsonapiType: 'bank_branches'
  },
  attrs: {
    name: attr(),
    code: attr(),
    bank: belongsTo()
  }
});

export interface BankBranchT extends BaseModel<BankBranchT> {
  name: string;
  bank: BankT;
  code: string;
}
