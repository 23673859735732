import { attr } from 'spraypaint';
import Base, { BaseModel } from '../Base';

type MBUserStatT = {
  date: string;
  activations: number;
  running_total: number;
};
export const MobileBankingUsersStats = Base.extend({
  static: {
    jsonapiType: 'dashboard_mobile_banking_users_stats',
    endpoint: '/dashboard/mobile_banking_users_stats'
  },
  attrs: {
    totalMonthlyMobileBankingUsers: attr()
  }
});

export interface MobileBankingUsersStatsT extends BaseModel<MobileBankingUsersStatsT> {
  totalMonthlyMobileBankingUsers: MBUserStatT[];
}
