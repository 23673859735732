// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import find from 'lodash/fp/find';
import sortBy from 'lodash/fp/sortBy';
import filter from 'lodash/fp/filter';

import { type LoanProductType, type SavingProductType, type MemberEligibilityT } from '@kwara/models/src';

import { loanPath } from '../../lib/urls';
import { LoanProductMobileCard } from '../LoanCard';
import { useStore } from '../../models/Member';

type SupportedProductTypes = SavingProductType | LoanProductType;
type SupportedProductsTypes = SavingProductType[] | LoanProductType[];
type SelectHandler = (product: SupportedProductTypes) => void;

type BaseProps = {
  products: SupportedProductsTypes,
  onSelect: SelectHandler,
  selectable?: boolean,
  eligibilities: MemberEligibilityT[]
};
type Props = BaseProps & {
  productCard: any // TO DO: Type this correctly
};

const ProductSelector = ({
  products,
  filterInstantLoans = false,
  eligibilities = [],
  productCard: ProductCard
}: Props) => {
  const sortedByInstantLoans = sortBy(product => !product.instantLoan, products);

  // @TODO: This is a temporary fix in FE to show only instant loans for KaruraCommunitySacco
  const store = useStore();
  const shouldFilterInstantLoans = store.isKaruraCommunitySacco || filterInstantLoans;
  const visibleLoanProducts = shouldFilterInstantLoans
    ? filter(product => product.instantLoan, sortedByInstantLoans)
    : sortedByInstantLoans;

  return (
    <div data-testid="ProductSelector">
      {map(product => {
        const productEligibility = find(e => e.id === product.id, eligibilities);

        product.eligibility = productEligibility;

        const action = get('instantLoan', product) ? 'instant/create' : 'create';

        const to = loanPath({ action, productId: product.id }, { asObject: true });

        return <ProductCard key={product.id} product={product} to={to} />;
      }, visibleLoanProducts)}
    </div>
  );
};

export const LoanProductSelector = (props: BaseProps) => {
  return <ProductSelector {...props} productCard={LoanProductMobileCard} />;
};
