// @flow

import * as React from 'react';

import styles from './index.module.scss';

export type ChildProps = {
  children: React.Node,
  size?: 'regular' | 'medium' | 'wide' | 'widest' | 'stretch'
};

export const Child = ({ children, size }: ChildProps) => <div className={styles[size]}>{children}</div>;

Child.defaultProps = {
  size: 'regular'
};

type Props = {
  align?: 'left' | 'center' | 'start' | 'end',
  children: React.Node
};

export const Stack = ({ align = 'center', children }: Props) => (
  <div className={`flex flex-column items-${align}`}>{children}</div>
);

export const Simple = ({ children }: { children: React.Node }) => (
  <Stack>
    <Child>{children}</Child>
  </Stack>
);

Stack.Child = Child;
Stack.Simple = Simple;

export default Stack;
