import React, { useEffect } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

import zIndices from '@kwara/lib/src/zIndices';
import Button from '@kwara/components/src/Button';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { segmentTrack } from '@kwara/components/src/Segment';
import { Overlay } from '@kwara/components/src/Overlay/Overlay';
import { createContext } from '@kwara/lib/src/utils/createContext';
import { ModalWrapper } from '@kwara/components/src/ModalWrapper/ModalWrapper';
import { useInstantPromptValues } from './hooks/useInstantPromptValues';

import { CloseIconButton } from '../CloseIconButton';

import iconInstantPrompt from './assets/iconInstantPrompt.png';
import styles from './index.module.scss';

type InstantPromptContextType = {
  shouldMount: boolean;
  unmount: () => void;
};

const [InstantPromptProvider, useInstantPrompt] = createContext<InstantPromptContextType>('InstantPromptContext');

type InstantPromptPropTypes = {
  children: React.ReactNode;
};

function InstantPrompt(props: InstantPromptPropTypes) {
  const history = useHistory();
  const values = useInstantPromptValues();

  useEffect(() => {
    segmentTrack('Instant Loan Campaign banner launched');
  }, []);

  function onClick() {
    segmentTrack('Instant Loan Campaign "Apply Now" Clicked');

    history.push('/instantLoans/select');

    values.onClose();
  }
  return (
    <InstantPromptProvider value={values}>
      <If
        condition={values.shouldMount}
        do={
          <Overlay>
            <div className={styles['container-wrapper']}>
              <ModalWrapper
                elementType="instantprompt"
                className={cx(styles['container'], zIndices.Modal)}
                isOpen={values.shouldMount}
              >
                <div className={styles['close-btn-wrapper']}>
                  <CloseIconButton
                    labelId="InstantPrompt.accessibleCloseBtn"
                    data-testid="closeInstantPromptBtn"
                    onClick={values.onClose}
                  />
                </div>

                <img src={iconInstantPrompt} alt="Purchase airtime and get 3% cashback" />

                <h1 className="mobile-text-x-large kw-weight-bold">
                  <Text id="InstantPrompt.title" values={{ br: <br /> }} />
                </h1>

                <p className="mobile-text-medium kw-weight-normal">
                  <Text id="InstantPrompt.subTitle" values={{ br: <br /> }} />
                </p>

                <Button type="secondary" className={styles['cta-btn']} onClick={onClick}>
                  <Text id="InstantPrompt.ctaBtn" />
                </Button>
              </ModalWrapper>
            </div>
          </Overlay>
        }
      />

      {props.children}
    </InstantPromptProvider>
  );
}

export { InstantPrompt, useInstantPrompt };
