import React from 'react';

import { isValidReferralCode } from '@kwara/lib/src/validator/validators';
import { FieldCard } from '../../../components/FieldCard';

export const Referral = ({ StackChild }) => {
  return (
    <StackChild>
      <FieldCard name="referralCode" title="Referral.Input.Card.title" subtitle="Referral.Input.Card.subtitle" />
    </StackChild>
  );
};

export default Referral;

Referral.validate = {
  referralCode: {
    maxlength: 20,
    custom: referralCode => {
      if (isValidReferralCode(referralCode)) {
        return null;
      }
      return 'invalidReferralCode';
    }
  }
};
