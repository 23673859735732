// @flow

import * as React from 'react';
import find from 'lodash/fp/find';

import {
  type IncludesT,
  type WhereT,
  type SupervisorMetricT,
  type TillMetricT,
  type LoanProductType,
  type MemberType,
  type LoanType,
  type SelectT
} from '@kwara/models/src';

import { formatError } from './hooks';
export type ErrorMsg = {
  message: string,
  status?: number
};
export type ErrorObject = Response & {
  messages: ErrorMsg[]
};

export type BaseRequestProps<T> = {
  error: ErrorObject | {},
  isLoading: boolean,
  data: T | {}
};

export type RequestProps<T> = BaseRequestProps<T> & {
  refetch: () => void
};
export type requestParamsT = string | (WhereT | IncludesT | SelectT | string | number)[];

export const EMPTY = {};

export function isEMPTY(data: any) {
  return data === EMPTY;
}

export function hasErrors(error: ?ErrorMsg) {
  if (error == null) {
    return false;
  }
  return error !== EMPTY;
}

export function getFirstError(errors: ErrorMsg[]) {
  return find(hasErrors, errors) || EMPTY;
}

type SupportedEntitiesAllT = SupervisorMetricT | TillMetricT | LoanProductType;
type UserModelsRequest<T> = {
  dispatchRequest: () => Promise<any>
} & RequestProps<T>;
export function useModelsRequest<T>(
  {
    entity,
    includes,
    select,
    where = null,
    per = null,
    page = null
  }: {
    entity: SupportedEntitiesAllT,
    includes?: IncludesT,
    select?: SelectT,
    where?: WhereT,
    per: ?number,
    page: ?number
  },
  shouldRunNow?: boolean = true
): UserModelsRequest<T> {
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(EMPTY);
  const [result, setResult] = React.useState<T | {}>(EMPTY);
  const [meta, setMeta] = React.useState<T | {}>(EMPTY);
  const [reqId, setReqId] = React.useState(1);
  const refetch = React.useCallback(() => {
    setReqId(n => n + 1);
  }, []);

  const dispatchRequest = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const scope =
        per && page
          ? entity
              .stats({ total: ['count', 'pages'] })
              .where(where)
              .select(select)
              .includes(includes)
              .per(per)
              .page(page)
          : entity.where(where).includes(includes);

      return await scope.all();
    } catch (e) {
      return await formatError(e);
    } finally {
      setIsLoading(false);
    }
  }, [entity, select, includes, page, per, where]);

  React.useEffect(() => {
    if (!shouldRunNow) {
      return;
    }

    let unmounted = false;
    const req = async () => {
      try {
        setIsLoading(true);
        const scope =
          per && page
            ? entity
                .stats({ total: ['count', 'pages'] })
                .where(where)
                .select(select)
                .includes(includes)
                .per(per)
                .page(page)
            : entity.where(where).includes(includes);

        const { data, meta: resMeta } = await scope.all();

        if (!unmounted) {
          setResult(data);
          setMeta(resMeta);
        }
      } catch (e) {
        setError(await formatError(e));
      } finally {
        setIsLoading(false);
      }
    };

    req();

    return () => {
      unmounted = true;
    };
  }, [entity, select, includes, where, reqId, per, page, shouldRunNow]);

  return {
    data: result,
    error,
    isLoading,
    refetch,
    meta,
    dispatchRequest
  };
}

const cache = {};

type SupportedEntitiesByIdT = MemberType | LoanType;
export function useModelRequest<T>(
  entity: SupportedEntitiesByIdT,
  id: string,
  includes: IncludesT,
  useCache: ?boolean,
  select?: SelectT
): RequestProps<T> {
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(EMPTY);
  const [result, setResult] = React.useState<T | {}>(EMPTY);
  const [reqId, setReqId] = React.useState(1);
  const refetch = React.useCallback(() => {
    setReqId(n => n + 1);
  }, []);
  React.useEffect(() => {
    let unmounted = false;
    const req = async () => {
      try {
        setIsLoading(true);
        if (useCache && cache[id]) {
          setResult(cache[id]);
        } else {
          const { data } = await entity
            .select(select)
            .includes(includes)
            .find(id);
          if (!unmounted) {
            const res = data.deserialize();
            setResult(res);
            cache[id] = res;
          }
        }
      } catch (e) {
        setError(await formatError(e));
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      req();
    }

    return () => {
      unmounted = true;
    };
  }, [entity, id, includes, select, reqId, useCache]);
  return {
    data: result,
    error,
    isLoading,
    refetch
  };
}
