import React from 'react';
import classnames from 'classnames';

import { Text } from '@kwara/components/src/Intl';
import { MemberUploadWidget, addAttachment, removeAttachment } from '@kwara/components/src/MemberUploadWidget';

import UploadWidgetStyle from './UploadWidget.module.scss';
import styles from './index.module.scss';

type Props = {
  close?: boolean,
  data: any,
  onChange: () => void,
  hasFile: boolean,
  title: string,
  subtitle: string,
  fileName: string,
  icon?: string,
  button?: string
};

export const UploadDocument = ({
  close = true,
  data,
  onChange,
  hasFile,
  title,
  subtitle,
  fileName,
  icon,
  button = ''
}: Props) => {
  const [isClose, setIsClose] = React.useState(close);
  const [isDirty, setIsDirty] = React.useState(hasFile);

  return (
    <div
      className={classnames(
        styles.Container,
        isDirty ? styles.Dirty : ' ',
        isClose ? styles.Close : styles.Open,
        'mb3'
      )}
    >
      <div className={classnames(styles.Content)}>
        <div className={classnames(styles.Header)} onClick={() => setIsClose(!isClose)}>
          <div className={classnames(styles.Title, 'mobile-text-regular flex items-center justify-between')}>
            <span className={styles.Line} />
            <span className="ph1">
              <Text id={title} />
            </span>
            <span className={styles.Line} />
          </div>
        </div>
        <div className={classnames(styles.Body, 'flex hidden')}>
          <div className={classnames(styles.Subtitle, 'mobile-text-small')}>
            <Text id={subtitle} />
          </div>
          <div className={classnames(styles.Icon)}>{icon && <img src={icon} alt="icon" />}</div>
        </div>
        <div className={classnames(styles.Footer)}>
          <MemberUploadWidget
            customStyle={UploadWidgetStyle}
            key={fileName}
            onChange={v => {
              setIsClose(true);
              setIsDirty(true);
              onChange(addAttachment(data, v));
            }}
            onRemove={fileName => onChange(removeAttachment(data, fileName))}
            member={data}
            fileName={fileName}
            checked={isDirty}
            textId={button}
          />
        </div>
      </div>
    </div>
  );
};
