// @flow

import * as React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';
import { useLoan } from '@kwara/models/src/models/request/hooks';

import { SecurityList } from './SecurityList';

const includes = ['guarantees.member', 'collaterals'];
export const LoanSecurity = ({ loanId }) => {
  const response = useLoan(loanId, includes);

  return <Loadable {...response}>{loan => <SecurityList loan={loan} />}</Loadable>;
};
