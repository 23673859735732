import React from 'react';
import { IntlProvider } from 'react-intl';

const formats = {
  number: {
    // This is not used as it doesn't have
    // currency: { style: 'currency', currency: 'KES', currencyDisplay: 'code' },
    currency: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    noDecimal: {
      style: 'decimal',
      maximumFractionDigits: 0
    }
  },
  date: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }
  }
};

export function Provider(props) {
  return (
    <IntlProvider
      formats={formats}
      key="en-KE"
      locale="en-KE"
      messages={props.messages}
      textComponent={React.Fragment}
      {...props}
    />
  );
}
