// @flow

import * as React from 'react';
import cx from 'classnames';
import posed from 'react-pose';

import zIndices from '@kwara/lib/src/zIndices';
import styles from './index.module.scss';

const Background = posed.div({
  visible: {
    opacity: 1
  },
  hidden: {
    opacity: 0
  }
});

const Container = posed.div({
  visible: { height: 'auto' },
  hidden: { height: 0 }
});

type Props = {
  modal: React.Node
};

export const Modal = ({ modal }: Props) => {
  return (
    <div className={zIndices.Banner}>
      <Background
        className={cx(styles.Background, 'fixed bottom-0 left-0 right-0')}
        initialPose="hidden"
        pose="visible"
      ></Background>
      <Container
        className={cx(styles.Container, 'fixed bottom-0 left-0 right-0 bg-white bt b--light-grey-400')}
        initialPose="hidden"
        pose="visible"
      >
        {modal}
      </Container>
    </div>
  );
};
