import * as React from 'react';
import { appName } from '@kwara/lib/src/utils';

import { PinInputBase } from './PinInputBase';

export const PinInput = React.forwardRef((props, ref) =>
  appName.isMember ? <PinInputMobile {...props} ref={ref} /> : <PinInputCore {...props} ref={ref} />
);

const PinInputCore = React.forwardRef((props, ref) => {
  const inputStyleCore = {
    borderRadius: '5px',
    backgroundColor: '#fff',
    border: 'none',
    margin: '0px 4px'
  };

  const inputFocusStyleCore = { border: '1px solid #2D5BAE' };

  return (
    <PinInputBase
      {...props}
      inputStyle={inputStyleCore}
      inputFocusStyle={inputFocusStyleCore}
      actionButtonColor={'primary600'}
      ref={ref}
    />
  );
});
const PinInputMobile = React.forwardRef((props, ref) => {
  const inputStyleConnect = {
    borderRadius: '5px',
    backgroundColor: '#355eae21',
    border: 'none',
    margin: '0px 6px 0px 0px',
    width: '40px',
    height: '40px'
  };

  const inputFocusStyleConnect = { border: '1px solid #16b364' };

  return (
    <PinInputBase
      {...props}
      inputStyle={inputStyleConnect}
      inputFocusStyle={inputFocusStyleConnect}
      actionButtonColor={'black'}
      ref={ref}
    />
  );
});
