import * as React from 'react';
import cx from 'classnames';

import { Text } from '@kwara/components/src/Intl';

import styles from './Illustration.module.scss';

type IllustrationPropTypes = {
  src: string;
  id: string;
};

export function Illustration(props: IllustrationPropTypes) {
  return (
    <div className={cx('flex flex-column items-center mt5 tc', styles['illustration'])}>
      <img alt="EmptyImage" src={props.src} className="tc" />

      <div className={'w-70 mobile-neutral-600 mobile-text-medium mt3'}>
        <Text id={props.id} />
      </div>
    </div>
  );
}
