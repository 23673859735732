// @flow

import { attr } from 'spraypaint';
import { MpesaTransaction } from './MpesaTransaction';

export const MpesaTransactionRegistrationFee = MpesaTransaction.extend({
  static: {
    endpoint: '/payments/mpesa/stk_push_registration_fee_payments'
  },
  attrs: {
    orgPermalink: attr(),
    phone: attr(),
    mPesaPhone: attr()
  }
});

export type MpesaTransactionRegistrationFeeType = MpesaTransactionRegistrationFee;
