import * as React from 'react';

export function IconDone() {
  return (
    <svg
      role="img"
      aria-label="Done icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3044_54668)">
        <path
          d="M19 0H5C3.6744 0.00158786 2.40356 0.528882 1.46622 1.46622C0.528882 2.40356 0.00158786 3.6744 0 5L0 19C0.00158786 20.3256 0.528882 21.5964 1.46622 22.5338C2.40356 23.4711 3.6744 23.9984 5 24H19C20.3256 23.9984 21.5964 23.4711 22.5338 22.5338C23.4711 21.5964 23.9984 20.3256 24 19V5C23.9984 3.6744 23.4711 2.40356 22.5338 1.46622C21.5964 0.528882 20.3256 0.00158786 19 0V0ZM22 19C22 19.7956 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7956 22 19 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V5C2 4.20435 2.31607 3.44129 2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2H19C19.7956 2 20.5587 2.31607 21.1213 2.87868C21.6839 3.44129 22 4.20435 22 5V19Z"
          fill="#3ccb7f"
        />
        <path
          d="M9.333 15.9188L5.414 11.9998C5.22647 11.8123 4.97216 11.707 4.707 11.707C4.44184 11.707 4.18753 11.8123 4 11.9998C3.81253 12.1873 3.70721 12.4416 3.70721 12.7068C3.70721 12.972 3.81253 13.2263 4 13.4138L7.919 17.3328C8.10473 17.5186 8.32524 17.666 8.56795 17.7665C8.81065 17.8671 9.07079 17.9189 9.3335 17.9189C9.59621 17.9189 9.85635 17.8671 10.0991 17.7665C10.3418 17.666 10.5623 17.5186 10.748 17.3328L20 8.08081C20.1875 7.89328 20.2928 7.63897 20.2928 7.37381C20.2928 7.10864 20.1875 6.85434 20 6.66681C19.8125 6.47934 19.5582 6.37402 19.293 6.37402C19.0278 6.37402 18.7735 6.47934 18.586 6.66681L9.333 15.9188Z"
          fill="#3ccb7f"
        />
      </g>
      <defs>
        <clipPath id="clip0_3044_54668">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
