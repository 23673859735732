import TagManager from 'react-gtm-module';
import get from 'lodash/fp/get';

import { isDev } from '@kwara/lib/src/utils';

import config from '../config';
import { store } from '../models/Store';

const GoogleTagManagerCodes = {
  sandbox: 'GTM-WC8R94F',
  production: 'GTM-P3R9KB5'
};

const isEnabled = !isDev;

const prepareDataLayer = (event: any) => ({
  dataLayer: {
    branch: store.branchName,
    event
  },
  dataLayerName: 'PageDataLayer'
});

const initializeGoogleTagManager = () => {
  if (isEnabled) {
    const tagManagerArgs = {
      gtmId: GoogleTagManagerCodes[get('env', config)],
      dataLayerName: 'PageDataLayer'
    };
    TagManager.initialize(tagManagerArgs);
  }
};

const addLoginPageDataLayer = () => {
  if (isEnabled) {
    const dataLayerArgs = prepareDataLayer('userLogin');
    TagManager.dataLayer(dataLayerArgs);
  }
};

const addSignupPageDataLayer = () => {
  if (isEnabled) {
    const dataLayerArgs = prepareDataLayer('userSignup');
    TagManager.dataLayer(dataLayerArgs);
  }
};

export const GoogleTagManager = {
  initialize: initializeGoogleTagManager,
  triggerUserLogin: addLoginPageDataLayer,
  triggerUserSignup: addSignupPageDataLayer
};
