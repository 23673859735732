import { LoanFormData } from 'LoanSharedTypes';

import { Installments } from '@kwara/components/src/pages/LoanAdd/components/Configure/Installments';

import { Summary } from './components/Summary';
import { Configure } from './components/Configure';
import { Disbursement } from './components/Disbursement';

export const steps = {
  configure: {
    titleId: 'LoanAdd.Configure.shortTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'summary'
      }
    ],
    children: [
      {
        titleId: 'LoanAdd.Connect.Configure.title',
        Component: Configure,
        validate: (data: LoanFormData) => ({ ...Configure.validate(data), ...Installments.validate })
      },
      {
        titleId: 'LoanAdd.Connect.Disbursement.title',
        Component: Disbursement,
        validate: Disbursement.validate
      }
    ]
  },
  summary: {
    titleId: 'LoanAdd.Summary.shortTitle',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'LoanAdd.Connect.Summary.title',
        subtitleId: 'InstantLoan.Summary.subtitle',
        Component: Summary
      }
    ]
  }
};
