import React, { useEffect } from 'react';
import cx from 'classnames';
import get from 'lodash/fp/get';

import Logo from '@kwara/components/src/Logo';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { OrganisationT } from '@kwara/models/src/models/Organisation';
import { useConnectProfile } from '@kwara/models/src/models/request/hooks';

import fallback from '../../pages/OrganisationsList/assets/IconNoLogoPlaceholder.png';
import styles from './index.module.scss';

const DURATION = 1000;

/**
 * WelcomeAnimation
 */
type WelcomeAnimationPropTypes = {
  organisation: OrganisationT;
  to: string;
};

function WelcomeAnimation({ organisation, to }: WelcomeAnimationPropTypes) {
  useEffect(() => {
    const timer = setTimeout(() => window.location.replace(to), DURATION);

    return () => clearTimeout(timer);
  }, [organisation.name, to]);

  return (
    <main className={styles.Main}>
      <div className={cx(styles.Content, 'flex flex-column justify-center items-center')}>
        <div className={cx(styles.Information, 'flex flex-column justify-center items-center')}>
          <div className={cx(styles.Title, 'mobile-text-large')}>{organisation?.name}</div>
          <div className={cx(styles.Subtitle, 'mobile-text-small')}>
            <Text id="Login.welcome.subtitle" />
          </div>
          <div className={cx(styles.Image, 'mt3')}>
            <img alt="logo" src={organisation?.logoUrl || fallback} />
          </div>
        </div>
      </div>
      <div className={cx(styles.Footer, 'pb5')}>
        <p className="grey-400 tc kw-weight-bold pa0 ma0">
          <Text id="Login.welcome.poweredBy" />
        </p>
        <Logo />
      </div>
    </main>
  );
}

/**
 * Welcome
 */
type WelcomePropTypes = {
  to?: string;
};

export function Welcome({ to = '/' }: WelcomePropTypes) {
  const profileR = useConnectProfile();

  return (
    <If
      condition={!profileR.isLoading}
      do={<WelcomeAnimation to={to} organisation={get('data.organisation', profileR)} />}
    />
  );
}
