// @flow

import { attr, belongsTo } from 'spraypaint';
import { TillTransactionDeprecatedBase } from './TillTransactionDeprecated';
import { type TillSessionT } from '..';

export const TopupRequestStatus = Object.freeze({
  APPROVED: 'approved',
  PENDING: 'pending_approval',
  DECLINED: 'declined'
});

export type TopupRequestStatusT = $Values<typeof TopupRequestStatus>;

export const TopupRequest = TillTransactionDeprecatedBase.extend({
  static: {
    jsonapiType: 'topup_requests'
  },
  attrs: {
    status: attr(),
    comment: attr(),
    requestedAmount: attr(),
    approvedAmount: attr(),
    tillSessionId: attr(),
    supervisor: attr(),
    tillSession: belongsTo(),
    updatedAt: attr(),
    createdAt: attr()
  }
});

export type TopupRequestT = {
  status: TopupRequestStatusT,
  type: string,
  requestedAmount: number,
  approvedAmount: number,
  comment?: string,
  tillSession: TillSessionT,
  updatedAt: string,
  createdAt: string
};
