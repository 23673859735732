import { attr, belongsTo } from 'spraypaint';

import Base, { BaseModel } from '@kwara/models/src/models/Base';
import Loan, { LoanType } from '@kwara/models/src/models/Loan';

import { _api } from '../services';

export const GuarantorshipRequests = Base.extend({
  static: {
    jsonapiType: 'guarantorship_requests'
  },
  attrs: {
    requestinUserName: attr(),
    guaranteedAmount: attr(),
    loanDetails: belongsTo({ type: Loan }),
    state: attr()
  }
});

export interface GuarantorshipRequestsType extends BaseModel<GuarantorshipRequestsType> {
  requestinUserName: string;
  guaranteedAmount: number;
  loanDetails: () => LoanType;
  state: string;
}

export async function fetchGuarantorshipRequests() {
  const response = await _api.httpGetRequest<GuarantorshipRequestsType>('v1/guarantorship/received_requests');
  return response.data;
}

export async function approveGuarantorship(id: string) {
  try {
    const response = await _api.httpPostRequest(`v1/guarantorship/received_requests/${id}/approve`, {
      data: { id: Number(id), type: 'guarantorship_requests' }
    });
    return response.data;
  } catch (e) {
    throw e;
  }
}

export async function declineGuarantorship(id: string) {
  try {
    const response = await _api.httpPostRequest(`v1/guarantorship/received_requests/${id}/decline`, {
      data: { id: Number(id), type: 'guarantorship_requests' }
    });
    return response.data;
  } catch (e) {
    throw e;
  }
}
