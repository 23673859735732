import React from 'react';
import classnames from 'classnames';

import { If } from '@kwara/components/src/If/If';
import Asset from '@kwara/components/src/Asset';

const VARIANTS = {
  error: { color: Asset.Colours.red700, className: 'bg-red-100 red-700' },
  warning: { color: Asset.Colours.info700, className: 'bg-info-100 info-700' },
  success: { color: Asset.Colours.teal700, className: 'bg-teal-100 teal-600' }
};

export type BannerState = 'error' | 'warning' | 'success';
type PrimitiveDivProps = React.ComponentPropsWithoutRef<'div'>;
type BannerElement = React.ElementRef<'div'>;
interface BannerProps extends PrimitiveDivProps {
  hidden?: boolean;
  text: React.ReactNode;
  state: BannerState;
  innerClassName?: string;
  onDismiss?: React.MouseEventHandler<HTMLButtonElement>;
}

const Banner = React.forwardRef<BannerElement, BannerProps>(function Banner(props, forwardedRef) {
  const { text, state, hidden, onDismiss, className, innerClassName, ...restProps } = props;

  return (
    <If
      condition={!hidden}
      do={
        <div
          {...restProps}
          ref={forwardedRef}
          role="dialog"
          aria-label={`${state} banner`}
          className={classnames('br3', VARIANTS[state].className, className)}
        >
          <div className={classnames('flex justify-between pv3 ph3', innerClassName)}>
            <div className="pa0 ma0">{text}</div>

            <If
              condition={!!onDismiss}
              do={
                <div className="ml-auto">
                  <button
                    type="button"
                    aria-label="Dismiss"
                    onClick={onDismiss}
                    className="bn bg-transparent p0 m0 pointer"
                  >
                    <Asset id={Asset.Glyphs.Cross} col={VARIANTS[state].color} />
                  </button>
                </div>
              }
            />
          </div>
        </div>
      }
    />
  );
});

export default Banner;
