// @flow

import { attr, belongsTo } from 'spraypaint';

import Base, { type BaseModel } from './Base';
import { type UserT } from './User';

export const Role = Base.extend({
  static: {
    jsonapiType: 'roles'
  },
  attrs: {
    name: attr(),
    user: belongsTo()
  },
  methods: {
    // TODO: Deprecate this method on the "LoanApprovalForm"
    hasLoanApprovePermission() {
      return false;
    }
  }
});

export interface RoleT extends BaseModel<RoleT> {
  name: string;
  permissions: string[];
  user: UserT;
  hasLoanApprovePermission: () => boolean;
}
