// @flow
import React from 'react';
import ReactPinInput from 'react-pin-input';
import { ActionButton } from '@kwara/components/src/Button';

const { useState } = React;
export const DEFAULT_PIN_LENGTH = 5;
export const FOUR_DIGIT_PIN = 4;
export const SIX_DIGIT_OTP = 6;

export type PinInputProps = {
  length?: number,
  initialValue?: number,
  canHide: boolean,
  disabled?: boolean,
  defaultSecret?: boolean,
  onChange?: (value: string, index: number) => void,
  onComplete?: (value: string, index: number) => void
};

type PinInputBaseRef = React.ElementRef<typeof ReactPinInput>;

export const PinInputBase = React.forwardRef<PinInputBaseRef, PinInputProps>(
  (
    {
      length = DEFAULT_PIN_LENGTH,
      initialValue = '',
      canHide = false,
      disabled = false,
      defaultSecret = true,
      onChange,
      onComplete,
      inputStyle,
      inputFocusStyle,
      actionButtonColor
    },
    ref
  ) => {
    const [isSecret, setIsSecret] = useState<boolean>(defaultSecret);

    return (
      <div className="flex items-center">
        <ReactPinInput
          length={length}
          initialValue={initialValue}
          disabled={disabled}
          ref={ref}
          onChange={onChange}
          onComplete={onComplete}
          type="numeric"
          inputMode="number"
          style={{}}
          inputStyle={{
            ...inputStyle,
            fontFamily: isSecret ? 'password' : 'inherit'
          }}
          inputFocusStyle={inputFocusStyle}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
        {canHide && (
          <ActionButton
            hideBorder
            col={actionButtonColor}
            type={isSecret ? 'view' : 'hidden'}
            onClick={() => setIsSecret(!isSecret)}
          />
        )}
      </div>
    );
  }
);
