import * as React from 'react';

import { SubscribedPhoneField } from '@kwara/components/src/Form';

export function EnterPhoneNumber({ directions }: { directions: string }) {
  return <SubscribedPhoneField aria-label="phone" name="phone" labelId={directions} required />;
}

export const validateConfig = {
  phone: {
    isRequired: () => true,
    phoneNumber: true
  }
};

EnterPhoneNumber.validateConfig = validateConfig;
