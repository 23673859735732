import React from 'react';
import cx from 'classnames';

import Button from '@kwara/components/src/Button';
import { Text, Currency } from '@kwara/components/src/Intl';

import styles from './LoanCardBase.module.scss';
import { If } from '@kwara/components/src/If/If';

type Props = {
  title: string;
  subtitle?: React.ReactNode;
  icon: string;
  amountLabelId: string;
  amount: string;
  isSelected?: boolean;
  tagComponent?: React.ReactNode;
  detailsContent: React.ReactNode;
  footerContent?: React.ReactNode;
};

export const LoanCardBase = (props: Props) => {
  const {
    title,
    subtitle,
    icon,
    amountLabelId,
    amount,
    isSelected,
    tagComponent,
    detailsContent,
    footerContent = null
  } = props;

  const [openDetails, setOpenDetails] = React.useState(false);

  return (
    <div className={cx(styles.Container, isSelected ? styles.Selected : '')}>
      <div className={cx(styles.TagContainer, 'flex justify-end')}>{tagComponent}</div>
      <div className={cx(styles.Header, 'flex items-center mh3')}>
        <img src={icon} alt="icon" />
        <div className="ml3">
          <div className="mobile-text-medium kw-weight-bold" aria-label="LoanName">
            {title}
          </div>
          <div className={cx(styles.Subtitle, 'mobile-text-small kw-weight-regular')}>{subtitle}</div>
        </div>
      </div>
      <div className={cx(styles.Content, 'flex items-center justify-between mh3')}>
        <div className="mv3">
          <div className={cx(styles.AmountLabel, 'mobile-text-small kw-weight-regular')}>
            <Text id={amountLabelId} />
          </div>
          <div className="mobile-text-medium kw-weight-regular">
            <Currency format="currency" value={amount} />
          </div>
        </div>
        <Button
          className={styles.ShowDetailsButton}
          glyphRightId={openDetails ? Button.Glyphs.UpMobile : Button.Glyphs.DownMobile}
          onClick={() => setOpenDetails(!openDetails)}
          type={'secondary'}
          size={'regular'}
        >
          <Text id="LoanCard.Details" />
        </Button>
      </div>
      <div className={cx(styles.DetailsContainer, openDetails ? styles.Open : styles.Close, 'ph3 pv4')}>
        {detailsContent}
      </div>
      <If condition={!!footerContent} do={<div className={cx(styles.Footer, 'flex')}>{footerContent}</div>} />
    </div>
  );
};
