import { attr } from 'spraypaint';
import Base, { BaseModel } from '../Base';

export const DistributionOfLoansStats = Base.extend({
  static: {
    jsonapiType: 'dashboard_distribution_of_loans_stats',
    endpoint: '/dashboard/distribution_of_loans_stats'
  },
  attrs: {
    sumOfBalancesPerProduct: attr(),
    sumOfBalances: attr()
  }
});

interface ProductSumOfBalanceT {
  product: string;
  value: string;
}

export interface DistributionOfLoansStatsT extends BaseModel<DistributionOfLoansStatsT> {
  sumOfBalancesPerProduct: ProductSumOfBalanceT[];
  sumOfBalances: string;
}
