// @flow

import { attr } from 'spraypaint';

import Base, { type BaseModel } from './Base';

export const SmsPlan = Base.extend({
  static: {
    jsonapiType: 'sms_plans'
  },
  attrs: {
    currency: attr(),
    costPerSmsCents: attr(),
    smsBalance: attr(),
    smsUsage: attr()
  },
  methods: {
    cost() {
      return this.costPerSmsCents / 100 || 0;
    }
  }
});

export interface SmsPlanT extends BaseModel<SmsPlanT> {
  currency: string;
  costPerSmsCents: number;
  smsBalance: number;
  smsUsage: number;
}
