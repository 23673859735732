import React from 'react';
import cx from 'classnames';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Auth from '@kwara/models/src/lib/Auth';
import PageLayoutWithOverview from '@kwara/components/src/PageLayoutWithOverview';

import { If } from '@kwara/components/src/If/If';
import { AppLayout } from '@kwara/components/src/AppLayout';
import { FreshChat } from '@kwara/components/src/Freshchat';
import { MemberType } from '@kwara/models/src/models/Member';
import { NotFound404 } from '@kwara/components/src/NotFound404';
import { Body, AuthExpiryChecker, Segment } from '@kwara/components/src';

import * as pages from '../pages';
import ContactModal from '../components/ContactModal';
import MemberNavigation from '../components/MemberNavigation';

import { ROUTES } from './routes';
import { Store } from '../models/Store';
import { useRouter } from './useRouter';
import { useStore } from '../models/Member';
import { ProtectedRoute } from './ProtectedRoute';
import { InstantPrompt } from '../components/InstantPrompt';
import { PageNavigation } from '../components/PageNavigation';
import { ModalMiddleware } from '../components/Modal/Middleware';
import { SCREEN_TYPES } from '../pages/Start/hooks/useUserStatus';
import { IsMountingLoader } from '../components/IsMountingLoader';
import { SuggestSaccoProvider } from '../pages/SaccoRecommendationFlow';
import { MobileNotificationBanner } from '../components/MobileNotificationBanner';
import { useSuggestSaccoContextValues } from '../pages/SaccoRecommendationFlow/hooks/useSuggestSaccoContextValues';

import styles from './router.module.scss';

type RouterPropTypes = { auth: Auth };

export function Router({ auth }: RouterPropTypes) {
  const store = useStore() as Store;
  const values = useSuggestSaccoContextValues();
  const { contactModalOpen, activityChecker, toggleContactModal, logOut, matchSectionFromPathname } = useRouter(auth);

  return (
    <div
      className={cx({
        [styles['container']]: true,
        [styles['contact-modal-open']]: contactModalOpen
      })}
    >
      <BrowserRouter>
        <AppLayout
          flexible
          className={cx(styles['app-layout'], 'bg-indigo-50')}
          notificationBanner={<MobileNotificationBanner />}
        >
          <ModalMiddleware>
            <Segment auth={auth} />
            <ContactModal open={contactModalOpen} onClose={toggleContactModal} />
            <FreshChat
              hideChatButton={true}
              user={{
                firstName: store.profile?.firstName,
                email: store.profile?.email,
                phone: store.profile?.phone,
                externalId: (store.member as MemberType)?.id,
                restoreId: (store.member as MemberType)?.id
              }}
              faqTags={{ tags: ['Members'], filterType: 'category' }}
              onInit={widget => {
                (widget.user as any).setProperties({
                  from: 'Connect',
                  organisationName: store?.profile?.organisation.name
                });
              }}
            />
            <Switch>
              {/**
               * @begin of Generic route(a route needed by both
               * Authenticated and non-authenticated user)
               */}
              <Route
                path={ROUTES.forgotPin}
                render={props => <pages.ForgotPIN {...props} auth={auth} baseUrl="/forgotPin" cancelReturnsTo="/" />}
              />
              {/**
               * @end of Generic route
               */}
              <If
                condition={auth.isLoggedIn()}
                do={
                  <InstantPrompt>
                    <AuthExpiryChecker
                      auth={auth}
                      logOut={logOut}
                      getLastActivityTime={activityChecker.getLastActivityTime}
                    >
                      <Switch>
                        {/**
                         * `<Redirect exact from={ROUTES.home} to={ROUTES.overviewBase} />`
                         *
                         * So after login, we want to direct user from `home route` to `overview route`
                         * another way of doing this, is to be precise on where to redirect to after
                         * login
                         */}
                        <Redirect exact from={ROUTES.home} to={ROUTES.overviewBase} />
                        <Route
                          path={ROUTES.fourDigitPINUpdate}
                          render={props => (
                            <pages.FourDigitPINUpdate {...props} auth={auth} baseUrl="/fourDigitPINUpdate" />
                          )}
                        />
                        <ProtectedRoute
                          isPermitted={auth.isLoggedIn() && store.isMpesaSavingDepositEnabled}
                          path={ROUTES.savingsDeposit}
                          render={props => (
                            <pages.SavingDeposit
                              {...props}
                              baseUrl={`/savings/${props.match.params.accountId}/deposit`}
                              cancelReturnsTo={`/savings/${props.match.params.accountId}/overview/`}
                            />
                          )}
                        />
                        <ProtectedRoute
                          isPermitted={auth.isLoggedIn() && store.isMpesaSavingDepositEnabled}
                          path="/overview/:accountId/deposit/:step?/:subStep?"
                          render={props => (
                            <pages.SavingDeposit
                              {...props}
                              baseUrl={`/overview/${props.match.params.accountId}/deposit`}
                              cancelReturnsTo="/overview/savings"
                            />
                          )}
                        />
                        <Route
                          path={ROUTES.statement}
                          render={props => <pages.Statement {...props} auth={auth} baseUrl="/statement" />}
                        />
                        <Route
                          path={ROUTES.loanAcceptGuarantorship}
                          render={props => <pages.AcceptGuarantorshipRequest {...props} auth={auth} />}
                        />
                        <ProtectedRoute
                          isPermitted={auth.isLoggedIn() && store.isMpesaLoanRepaymentEnabled}
                          path={ROUTES.overviewRepayment}
                          render={props => (
                            <pages.LoanRepayment
                              {...props}
                              baseUrl={`/overview/${props.match.params.loanId}/repayment`}
                              cancelReturnsTo="/overview/loans"
                            />
                          )}
                        />
                        <ProtectedRoute
                          isPermitted={auth.isLoggedIn() && store.isMpesaLoanRepaymentEnabled}
                          path={ROUTES.loanRepayment}
                          render={props => (
                            <pages.LoanRepayment
                              {...props}
                              baseUrl={`/loans/${props.match.params.loanId}/repayment/`}
                              cancelReturnsTo={`/loans/${props.match.params.loanId}/overview/`}
                            />
                          )}
                        />
                        <ProtectedRoute
                          exact
                          isPermitted={auth.isLoggedIn()}
                          path={ROUTES.savingsSelect}
                          component={pages.SelectSavings}
                        />
                        <ProtectedRoute
                          exact
                          isPermitted={auth.isLoggedIn()}
                          path={ROUTES.loanSelect}
                          render={props => <pages.LoanSelect {...props} />}
                        />
                        <ProtectedRoute
                          exact
                          isPermitted={auth.isLoggedIn()}
                          path={ROUTES.instantLoansSelect}
                          render={props => <pages.LoanSelect {...props} filterInstantLoans={true} />}
                        />
                        <ProtectedRoute
                          exact
                          isPermitted={auth.isLoggedIn()}
                          path={ROUTES.loansRepay}
                          component={pages.LoansToRepay}
                        />
                        <ProtectedRoute
                          exact
                          isPermitted={auth.isLoggedIn()}
                          path={ROUTES.loanCreate}
                          render={props => <pages.LoanAdd {...props} baseUrl="/loans/create" />}
                        />
                        <ProtectedRoute
                          exact
                          isPermitted={auth.isLoggedIn()}
                          path={ROUTES.loanInstantCreate}
                          render={props => <pages.LoanAddInstant {...props} baseUrl="/loans/instant/create" />}
                        />
                        <ProtectedRoute
                          exact
                          isPermitted={auth.isLoggedIn()}
                          path={ROUTES.airtime}
                          render={props => <pages.PurchaseAirtime {...props} baseUrl="/airtime" cancelReturnsTo="/" />}
                        />
                        <Route
                          path={ROUTES.overviewBase}
                          render={() => (
                            <React.Suspense fallback={<IsMountingLoader />}>
                              <PageLayoutWithOverview overview={<pages.MemberOverview />}>
                                <Body pathname={location.pathname} />
                                <MemberNavigation
                                  currentSection={matchSectionFromPathname(
                                    location.pathname,
                                    MemberNavigation.Sections
                                  )}
                                />
                                <Switch>
                                  <Route exact path={ROUTES.overviewLoans} component={pages.Loans} />
                                  <Route exact path={ROUTES.overviewSavings} component={pages.Savings} />
                                  <Route exact path={ROUTES.overviewHistory} component={pages.History} />
                                  <Route
                                    exact
                                    path={ROUTES.overviewProfile}
                                    render={() => <pages.Profile auth={auth} />}
                                  />
                                  <Redirect exact to={ROUTES.overviewLoans} />
                                </Switch>
                              </PageLayoutWithOverview>
                            </React.Suspense>
                          )}
                        />
                        <Route
                          path={ROUTES.savingOverview}
                          render={props => {
                            return (
                              <React.Suspense fallback={<IsMountingLoader />}>
                                <PageLayoutWithOverview
                                  overview={<pages.SavingOverview accountId={props.match.params.accountId} />}
                                >
                                  <Body pathname={location.pathname} />
                                  <PageNavigation
                                    sections={pages.SavingDetails.Sections}
                                    currentSection={matchSectionFromPathname(
                                      location.pathname,
                                      pages.SavingDetails.Sections
                                    )}
                                  />
                                  <Switch>
                                    <Route
                                      path={ROUTES.savingDetails}
                                      render={() => <pages.SavingDetails accountId={props.match.params.accountId} />}
                                    />
                                    <Route
                                      path={ROUTES.savingActivity}
                                      render={() => <pages.SavingActivity accountId={props.match.params.accountId} />}
                                    />
                                    <Redirect exact to={ROUTES.savingDetails} />
                                  </Switch>
                                </PageLayoutWithOverview>
                              </React.Suspense>
                            );
                          }}
                        />
                        <Route
                          path={ROUTES.loanOverview}
                          render={props => {
                            return (
                              <React.Suspense fallback={<IsMountingLoader />}>
                                <PageLayoutWithOverview
                                  overview={
                                    <pages.LoanOverview history={props.history} loanId={props.match.params.loanId} />
                                  }
                                >
                                  <Body pathname={location.pathname} />
                                  <PageNavigation
                                    sections={pages.LoanDetails.Sections}
                                    currentSection={matchSectionFromPathname(
                                      location.pathname,
                                      pages.LoanDetails.Sections
                                    )}
                                  />
                                  <Switch>
                                    <Route
                                      path={ROUTES.loanDetail}
                                      render={() => <pages.LoanDetails loanId={props.match.params.loanId} />}
                                    />
                                    <Route
                                      path={ROUTES.loanSecurity}
                                      render={() => <pages.LoanSecurity loanId={props.match.params.loanId} />}
                                    />
                                    <Route
                                      path={ROUTES.loanDocuments}
                                      render={() => <pages.LoanDocuments loanId={props.match.params.loanId} />}
                                    />
                                    <Route
                                      path={ROUTES.loanActivity}
                                      render={() => <pages.LoanActivity loanId={props.match.params.loanId} />}
                                    />
                                    <Redirect exact to={ROUTES.loanDetail} />
                                  </Switch>
                                </PageLayoutWithOverview>
                              </React.Suspense>
                            );
                          }}
                        />
                        <Route
                          path={ROUTES.membershipsActivation}
                          render={() => <pages.ActivateMembership auth={auth} />}
                        />
                        <Route component={NotFound404} />
                      </Switch>
                    </AuthExpiryChecker>
                  </InstantPrompt>
                }
                else={
                  <SuggestSaccoProvider value={values}>
                    <Switch>
                      <Route
                        exact
                        path={ROUTES.secureFourPinUpdate}
                        render={props => (
                          <pages.SecureFourPinUpdate {...props} auth={auth} baseUrl="/secureFourPinUpdate" />
                        )}
                      />
                      <Route
                        exact
                        path={ROUTES.login}
                        render={props => <pages.LoginFourPIN {...props} auth={auth} />}
                      />
                      <Route
                        exact
                        path={ROUTES.loginFivePIN}
                        render={props => <pages.LoginFivePIN {...props} auth={auth} />}
                      />
                      <Route exact path={ROUTES.referral} render={props => <pages.Referral {...props} auth={auth} />} />
                      <Route exact path={ROUTES.start} render={props => <pages.Start {...props} auth={auth} />} />
                      <Route
                        exact
                        path={ROUTES.cancelMembershipApplication}
                        component={pages.CancelMembershipApplication}
                      />
                      <Route
                        exact
                        path={ROUTES.memberCreate}
                        render={props => <pages.Register {...props} baseUrl="/members/create" action="add" />}
                      />
                      <Route
                        exact
                        path={ROUTES.register}
                        render={props => <pages.Start {...props} auth={auth} screenType={SCREEN_TYPES.REGISTER} />}
                      />
                      {/**
                       * @beginning of SaccoRecommendationFlow routings
                       */}
                      <Route exact path={ROUTES.joinSacco} component={pages.JoinASaccoToday} />
                      <Route exact path={ROUTES.saccoRecommendation} component={pages.SaccoRecommendationFlow} />
                      <Route exact path={ROUTES.organisations} component={pages.OrganisationsList} />
                      <Route
                        exact
                        path={ROUTES.joiningFee}
                        render={props => <pages.JoiningFee {...props} auth={auth} />}
                      />
                      {/**
                       * @end of SaccoRecommendationFlow routings
                       */}
                      <Route path="*">
                        <Redirect to={ROUTES.home} />
                      </Route>
                    </Switch>
                  </SuggestSaccoProvider>
                }
              />
            </Switch>
          </ModalMiddleware>
        </AppLayout>
      </BrowserRouter>
    </div>
  );
}
