import axios, { AxiosRequestConfig } from 'axios';
import { attr, belongsTo } from 'spraypaint';

import Base, { BaseModel } from './Base';
import { AccountingReportT } from './AccountingReport';
import { ReportTemplateT } from './ReportTemplate';
import { UserT } from './User';

import { blobHeaders, handleDownload } from '@kwara/lib/src/fileDownload';

const mimeTypesMaps = Object.freeze({
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
});
const mimeTypes = Object.freeze(Object.keys(mimeTypesMaps));

export const SasraReport = Base.extend({
  static: {
    jsonapiType: 'sasra_reports',
    endpoint: '/reporting/sasra'
  },
  attrs: {
    name: attr(),
    state: attr(),
    startDate: attr(),
    endDate: attr(),
    reportTemplateId: attr(),
    accountingReport: belongsTo(),
    reportTemplate: belongsTo(),
    createdBy: belongsTo()
  },
  methods: {
    async download() {
      const url = `${SasraReport.url()}/${this.id}.xlsx`;
      const opts = SasraReport.fetchOptions();
      const options = blobHeaders(opts) as AxiosRequestConfig<any>;
      const fileName = `${this.name}.xlsx`;

      return axios.get(url, options).then(res => {
        try {
          handleDownload({
            data: res.data,
            fileName,
            mimeTypes
          });
        } catch (e) {
          return Promise.reject(e);
        }

        return Promise.resolve();
      });
    }
  }
});

export interface SasraReportT extends BaseModel<SasraReportT> {
  name: string;
  state: string;
  startDate: string;
  endDate: string;
  reportTemplateId: string;
  accountingReport: AccountingReportT;
  reportTemplate: ReportTemplateT;
  createdBy: UserT;
  download(): Promise<null>;
}
