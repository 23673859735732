// @flow
import * as React from 'react';

import find from 'lodash/find';

import Button from '@kwara/components/src/Button';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import { Text } from '@kwara/components/src/Intl';
import { appName } from '@kwara/lib/src/utils';
import Action, { type Action as ActionT } from '@kwara/components/src/Wizard/deprecated/Action';

const PrimaryLabel = {
  approve: 'approve',
  next: 'next',
  review: 'review',
  submit: 'submit',
  close: 'close',
  skip: 'skip',
  processing: 'processing',
  print: 'print',
  verify: 'verify',
  buy: 'buy'
};

const PrimaryLabels = {
  [PrimaryLabel.approve]: <Text id="WizardActions.approve" />,
  [PrimaryLabel.close]: <Text id="WizardActions.close" />,
  [PrimaryLabel.next]: <Text id="WizardActions.next" />,
  [PrimaryLabel.review]: <Text id="WizardActions.review" />,
  [PrimaryLabel.submit]: <Text id="WizardActions.submit" />,
  [PrimaryLabel.skip]: <Text id="WizardActions.skip" />,
  [PrimaryLabel.print]: <Text id="WizardActions.print" />,
  [PrimaryLabel.verify]: <Text id="WizardActions.verify" />,
  [PrimaryLabel.buy]: <Text id="WizardActions.buy" />
};

const PrimaryGlyphId = {
  [PrimaryLabel.approve]: null,
  [PrimaryLabel.next]: Button.Glyphs.ArrowRight,
  [PrimaryLabel.review]: Button.Glyphs.ArrowRight,
  [PrimaryLabel.submit]: null,
  [PrimaryLabel.print]: null,
  [PrimaryLabel.verify]: null
};

type Props = {
  actions: Action[],
  isProcessing?: boolean,
  className?: string,
  innerClassName?: string,
  onAction: (action: ActionT) => void,
  nextDisabled: boolean
};

// Hidden if not permitted or if hidden
const isVisible = (action: ?Action): boolean => {
  if (action == null) {
    return false;
  }

  return action.isPermitted && !action.isHidden;
};

const WizardActions = ({
  actions = [],
  isProcessing = false,
  className = '',
  innerClassName = '',
  nextDisabled,
  onAction
}: Props) => {
  const [activeAction, setActiveAction] = React.useState(null);

  const back = find(actions, { appearsAs: 'back' });
  const buttonSize = appName.isMember ? 'regular' : 'medium';
  const backButton =
    back != null && isVisible(back) ? (
      <Button
        onClick={() => onAction(back)}
        type="secondary"
        size={buttonSize}
        glyphLeftId={Button.Glyphs.ArrowLeft}
        aria-label="Back"
        disabled={isProcessing}
      >
        <Text id="WizardActions.back" />
      </Button>
    ) : null;

  const cancel = find(actions, { appearsAs: 'cancel' });
  const cancelButton =
    cancel != null && isVisible(cancel) ? (
      <Button
        onClick={() => onAction(cancel)}
        type="secondary"
        size={buttonSize}
        className=""
        aria-label="Cancel"
        disabled={isProcessing}
      >
        <Text id="WizardActions.cancel" />
      </Button>
    ) : null;

  const close = find(actions, { appearsAs: 'close' });
  const closeButton =
    close != null && isVisible(close) ? (
      <Button
        onClick={() => onAction(close)}
        type="destructive"
        size={buttonSize}
        aria-label="Close"
        disabled={isProcessing || nextDisabled}
      >
        {PrimaryLabels.close}
      </Button>
    ) : null;

  const reject = find(actions, { appearsAs: 'reject' });
  const rejectButton =
    reject != null && isVisible(reject) ? (
      <Button
        disabled={isProcessing || nextDisabled}
        onClick={() => {
          setActiveAction('reject');
          onAction(reject);
        }}
        type="destructive"
        size={buttonSize}
        className=""
        aria-label="Reject"
        glyphRightId={isProcessing ? activeAction === 'reject' && Button.Glyphs.Spinner : null}
      >
        <Text id="WizardActions.reject" />
      </Button>
    ) : null;

  const next = find(actions, { appearsAs: 'next' });
  const nextButton =
    next != null && isVisible(next) ? (
      <Button
        disabled={isProcessing || nextDisabled}
        onClick={() => onAction(next)}
        type="primary"
        size={buttonSize}
        aria-label="Next"
        glyphRightId={isProcessing ? Button.Glyphs.Spinner : PrimaryGlyphId.next}
      >
        {PrimaryLabels.next}
      </Button>
    ) : null;

  const verify = find(actions, { appearsAs: 'verify' });
  const verifyButton =
    verify != null && isVisible(verify) ? (
      <Button
        disabled={isProcessing || nextDisabled}
        onClick={() => onAction(verify)}
        type="primary"
        size={buttonSize}
        aria-label="Verify"
        glyphRightId={isProcessing ? Button.Glyphs.Spinner : PrimaryGlyphId.verify}
      >
        {PrimaryLabels.verify}
      </Button>
    ) : null;

  const submit = find(actions, { appearsAs: 'submit' });
  const submitButton =
    submit != null && isVisible(submit) ? (
      <Button
        disabled={isProcessing || nextDisabled}
        onClick={() => onAction(submit)}
        type="primary"
        size={buttonSize}
        aria-label="Submit"
        glyphRightId={isProcessing ? Button.Glyphs.Spinner : PrimaryGlyphId.submit}
      >
        {PrimaryLabels.submit}
      </Button>
    ) : null;

  const buy = find(actions, { appearsAs: 'buy' });
  const buyButton =
    buy != null && isVisible(buy) ? (
      <Button
        disabled={isProcessing || nextDisabled}
        onClick={() => onAction(buy)}
        type="primary"
        size={buttonSize}
        aria-label="Buy"
        glyphRightId={isProcessing ? Button.Glyphs.Spinner : PrimaryGlyphId.buy}
      >
        {PrimaryLabels.buy}
      </Button>
    ) : null;

  const print = find(actions, { appearsAs: 'print' });
  const printButton =
    print != null && isVisible(print) ? (
      <Button
        disabled={isProcessing || nextDisabled}
        onClick={() => onAction(print)}
        type="primary"
        size={buttonSize}
        aria-label="Print"
        glyphRightId={isProcessing ? Button.Glyphs.Spinner : PrimaryGlyphId.print}
      >
        {PrimaryLabels.print}
      </Button>
    ) : null;

  const approve = find(actions, { appearsAs: 'approve' });
  const approveButton =
    approve != null && isVisible(approve) ? (
      <Button
        disabled={isProcessing || nextDisabled}
        onClick={() => {
          setActiveAction('approve');
          onAction(approve);
        }}
        type="primary"
        size={buttonSize}
        aria-label="Approve"
        glyphRightId={isProcessing ? activeAction === 'approve' && Button.Glyphs.Spinner : PrimaryGlyphId.approve}
      >
        {PrimaryLabels.approve}
      </Button>
    ) : null;

  const review = find(actions, { appearsAs: 'review' });
  const reviewButton =
    review != null && isVisible(review) ? (
      <Button
        disabled={isProcessing || nextDisabled}
        onClick={() => onAction(review)}
        type="primary"
        size={buttonSize}
        aria-label="Review"
        glyphRightId={isProcessing ? Button.Glyphs.Spinner : PrimaryGlyphId.review}
      >
        {PrimaryLabels.review}
      </Button>
    ) : null;

  const skip = find(actions, { appearsAs: 'skip' });
  const skipButton = isVisible(skip) ? (
    <Button
      disabled={isProcessing}
      onClick={() => onAction(skip)}
      type="secondary"
      size={buttonSize}
      aria-label="Skip"
      glyphRightId={isProcessing ? Button.Glyphs.Spinner : null}
    >
      {PrimaryLabels.skip}
    </Button>
  ) : null;

  const left = [backButton, cancelButton];
  const right = [
    rejectButton,
    closeButton,
    skipButton,
    nextButton,
    approveButton,
    reviewButton,
    submitButton,
    printButton,
    verifyButton,
    buyButton
  ];

  return (
    <ButtonBar
      left={left}
      right={right}
      innerClassName={innerClassName}
      className={`w-100 pv3 bt b--light-grey-500 ${className}`}
    />
  );
};

export default WizardActions;
