import React from 'react';

import Asset from '@kwara/components/src/Asset';
import Button from '@kwara/components/src/Button';

import { Text } from '@kwara/components/src/Intl';

type AddGuarantorsButtonPropTypes = {
  textId: string;
  ariaLabel?: string;
  onClick(): void;
};

export function AddGuarantorsButton({ textId, onClick, ariaLabel }: AddGuarantorsButtonPropTypes) {
  return (
    <div className="flex justify-center">
      <Button type="secondary" size="regular" glyphLeftId={Asset.Glyphs.Add} aria-label={ariaLabel} onClick={onClick}>
        <Text id={textId} />
      </Button>
    </div>
  );
}
