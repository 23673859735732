// @flow

import * as React from 'react';
import classnames from 'classnames';

import groupBy from 'lodash/fp/groupBy';
import orderBy from 'lodash/fp/orderBy';

import { Currency, Text } from '@kwara/components/src/Intl';
import { TransactionItemType } from '@kwara/components/src/TransactionItemType';
import { type TransactionType } from '@kwara/models/src';
import { Date } from '@kwara/components/src/Intl';
import { getYear } from '@kwara/lib/src/dates';

import Collapsible from '../Collapsible';
import styles from './index.module.scss';

const TableItem = ({ transaction }) => (
  <div className={classnames(styles.tableItem, 'bt b--light-grey-300 tl pa3 bg-light-grey-300 kw-text-regular')}>
    <div className={classnames(styles.tableItem1, 'grey-400')}>
      <Date value={transaction.createdAt} />
    </div>
    <div className={classnames(styles.tableItem2, 'kw-text-small grey-400')}>
      <div className="grey-300">{transaction.reference}</div>
      <TransactionItemType transaction={transaction} />
      {transaction.notes && <div className="grey-300">{transaction.notes}</div>}
    </div>
    <div className={classnames(styles.tableItem3, '')}>
      <Currency format="currency" value={transaction.amount} />
    </div>
    <div className={classnames(styles.tableItem4, 'kw-text-small')}>
      <Currency format="currency" value={transaction.balance} />
    </div>
  </div>
);

const TransactionsTableTitles = () => (
  <div className={styles.tableTitles}>
    <div className={classnames(styles.tableItem, 'kw-text-small bb grey-300')}>
      <div className={classnames(styles.tableItem1)}>
        <Text id={'TransactionsTable.date'}></Text>
      </div>
      <div className={classnames(styles.tableItem2, '')}>
        <Text id={'TransactionsTable.type'}></Text>
      </div>
      <div className={classnames(styles.tableItem3, '')}>
        <Text id={'TransactionsTable.amount'}></Text>
      </div>
      <div className={classnames(styles.tableItem4, '')}>
        <Text id={'TransactionsTable.balance'}></Text>
      </div>
    </div>
  </div>
);

const summary = year => <div className={'kw-text-medium'}>{year}</div>;

export const TransactionsTable = ({ transactions }) => {
  const orderedTransactions = orderBy(['bookingDate'], ['desc'], transactions);
  const groupedTransactions = groupBy(
    (transaction: TransactionType) => getYear(transaction.createdAt),
    orderedTransactions
  );

  return (
    <>
      {Object.keys(groupedTransactions)
        .reverse()
        .map(year => (
          <Collapsible key={year} summary={summary(year)}>
            <div className="pt3">
              <TransactionsTableTitles />
              {groupedTransactions[year].map(transaction => (
                <TableItem key={transaction.id} transaction={transaction} />
              ))}
            </div>
          </Collapsible>
        ))}
    </>
  );
};
