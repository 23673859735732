// @flow

import * as React from 'react';

import { mapIndexed } from '@kwara/lib/src/lodash';
import { Text } from '@kwara/components/src/Intl';

import Collapsible from '../../components/Collapsible';
import { StatisticInfo } from './StatisticInfo';
import styles from './index.module.scss';

export const NextOfKin = ({ member, className }) => (
  <Collapsible className={className} summary={<Text id="MemberDetail.nextOfKin" />}>
    <section className={styles.InfoSection}>
      {mapIndexed(
        (nextOfKin, index) => (
          <StatisticInfo
            key={index}
            title={<Text id="MemberDetail.NextOfKin.title" values={{ index: index + 1 }} />}
            value={nextOfKin}
          />
        ),
        member.kin
      )}
    </section>
  </Collapsible>
);
