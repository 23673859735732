// @flow
import * as React from 'react';
import get from 'lodash/fp/get';

import Wizard, { getSubstepFromMatchParams } from '@kwara/components/src/Wizard/deprecated/Wizard';
import { formatIsoDate } from '@kwara/lib/src/dates';

import { EnterPIN } from '../../components/EnterPIN';
import { useStore } from '../../models/Member';
import { steps } from './config';

const submit = async (data, auth) => {
  try {
    await auth.emailStatement({
      date_from: formatIsoDate(data.from),
      date_to: formatIsoDate(data.to),
      email: data.email
    });
  } catch ({ errors }) {
    throw errors;
  }
};
export const Statement = ({ match, baseUrl, history, auth }) => {
  const store = useStore();
  return (
    <Wizard
      analyticsId="Statement"
      baseUrl={baseUrl}
      history={history}
      cancelReturnsTo={'/overview/savings'}
      currentStep={get('params.step', match)}
      currentSubStep={getSubstepFromMatchParams(match.params)}
      initialData={{ email: store.memberEmail }}
      onSubmit={data => submit(data, auth)}
      steps={steps}
      startId="dateRange"
      titleId="Statement.title"
      EnterPIN={EnterPIN}
    />
  );
};
