import * as React from 'react';
import cx from 'classnames';

import { Text } from '@kwara/components/src/Intl';
import { Currency } from '@kwara/components/src/Intl';

import styles from './TwoThirdsModal.module.scss';

export function Breakdown({ data, availableIncome, totalMonthlyLoanPayments }) {
  const { member } = data;
  const { netIncome, otherDeductibles, otherIncomeAmount } = member;

  return (
    <div className={cx('pa3', styles.Breakdown)}>
      <table className="w-100 kw-text-extra-small">
        <tbody>
          <tr className="b">
            <td>
              <Text id="LoanAdd.Configure.TwoThirdsModal.Breakdown.netIncome" />
            </td>
            <td className="tr">
              <Currency hideDecimals={false} value={netIncome} />
            </td>
          </tr>
          <tr>
            <td>
              <Text id="LoanAdd.Configure.TwoThirdsModal.Breakdown.twoThirdsNetIncome" />
            </td>
            <td className="tr">
              <Currency hideDecimals={false} value={(2 * Number(netIncome)) / 3} />
            </td>
          </tr>
          <tr>
            <td>
              <Text id="LoanAdd.Configure.TwoThirdsModal.Breakdown.otherIncome" />
            </td>
            <td className="tr">
              <Currency hideDecimals={false} value={otherIncomeAmount} />
            </td>
          </tr>
          <tr>
            <td>
              <Text id="LoanAdd.Configure.TwoThirdsModal.Breakdown.deductions" />
            </td>
            <td className="tr red-600">
              <Currency hideDecimals={false} value={otherDeductibles} />
            </td>
          </tr>
          <tr>
            <td>
              <Text id="LoanAdd.Configure.TwoThirdsModal.Breakdown.loanPayments" />
            </td>
            <td className="tr bb b--black red-600">
              <Currency hideDecimals={false} value={totalMonthlyLoanPayments} />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr className="b">
            <td>
              <Text id="LoanAdd.Configure.TwoThirdsModal.Breakdown.availableIncome" />
            </td>
            <td className="tr">
              <Currency hideDecimals={false} value={availableIncome} />
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="mt2 kw-text-extra-small tc i">
        <Text id="LoanAdd.Configure.TwoThirdsModal.Breakdown.helperText" />
      </div>
    </div>
  );
}
