// @flow

import * as React from 'react';
import classnames from 'classnames';

import { Currency, Percent, Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import { type SavingType } from '@kwara/models/src';

import styles from './Metrics.module.scss';

export const Metrics = ({ saving }: { saving: SavingType }) => {
  const border = 'bt b--light-grey-400';

  return (
    <>
      <section className={classnames(styles.PrimarySection)}>
        <Statistic
          title={<Text id="SavingDetail.Overview.balance" />}
          value={<Currency value={saving.balance} />}
          color="teal-600"
          size={'superLarge'}
          containerClassName={border}
        />
        <Statistic
          title={<Text id="SavingDetail.Overview.accruedInterest" />}
          value={<Currency value={saving.accruedInterest} />}
          color="red-600"
          size={'superLarge'}
          containerClassName={border}
        />
        <Statistic
          title={<Text id="SavingDetail.Overview.name" />}
          value={saving.name || 'Unknown'}
          size={'superLarge'}
          containerClassName={border}
        />
        <Statistic
          data-testid="SavingDetail.interestRate"
          title={<Text id="SavingDetail.Overview.interestRate" />}
          value={<Percent value={saving.interestObject().rate / 100} />}
          size={'superLarge'}
          containerClassName={border}
        />
        <Statistic
          title={<Text id="SavingDetail.Overview.minimumDeposit" />}
          value={'Unknown'}
          size={'regular'}
          containerClassName={border}
        />
        <Statistic
          title={<Text id="SavingDetail.Overview.minimumBalance" />}
          value={<Currency value={saving.product.minimumOpeningBalance || 0} />}
          size={'regular'}
          containerClassName={border}
        />
      </section>
    </>
  );
};
