import * as React from 'react';

export function SimpleButton({
  isSubmit,
  disabled,
  className,
  onClick,
  title,
  alt,
  role,
  style,
  hidden,
  children,
  ...rest
}) {
  return (
    <button
      aria-label={rest['aria-label']}
      type={isSubmit ? 'submit' : 'button'}
      disabled={disabled}
      className={className}
      onClick={onClick}
      title={title}
      alt={alt}
      role={role}
      style={style}
      hidden={hidden}
      {...(rest['data-testid'] && { 'data-testid': rest['data-testid'] })}
    >
      {children}
    </button>
  );
}
