// @flow
import entries from 'lodash/entries';
import getOr from 'lodash/fp/getOr';
import get from 'lodash/get';
import set from 'lodash/set';

import * as validates from './validators';
import * as recordValidators from './records';
import { Logger } from '../logger';

export const VALID = 'VALID';
export const isValid = {
  ...recordValidators,
  ...validates
};

// Following discussion in story [ch6510]
// https://app.clubhouse.io/getkwara/story/6510/member-withdrawal-using-reactfinalform-including-validation
export const DEFAULT_MAX_CURRENCY = 100000000;

export const ERRORS = Object.freeze({
  decimals: 'Decimals',
  invalidEmail: 'invalidEmail',
  emailMismatch: 'emailMismatch',
  pinMismatch: 'pinMismatch',
  endsWithDecimal: 'EndsWithDecimal',
  future: 'future',
  invalidDate: 'invalidDate',
  invalidDay: 'invalidDay',
  invalidMonth: 'invalidMonth',
  invalidYear: 'invalidYear',
  isNegative: 'Negative',
  isZero: 'Zero',
  nationalFormat: 'nationalFormat',
  noComma: 'NoComma',
  noPlusSign: 'NoPlusSign',
  notAlphaNum: 'notAlphaNum',
  notANumber: 'NotANumber',
  notPositiveInteger: 'notPositiveInteger',
  noUpperCase: 'noUpperCase',
  noLowerCase: 'noLowerCase',
  mustContainSpecialCharacter: 'mustContainSpecialCharacter',
  mustNotContainSpecialCharacters: 'mustNotContainSpecialCharacters',
  passportFormat: 'passportFormat',
  patternMismatch: 'patternMismatch',
  rangeOverflow: 'rangeOverflow',
  rangeUnderflow: 'rangeUnderflow',
  tooLong: 'tooLong',
  tooShort: 'tooShort',
  under18: 'under18',
  valueMissing: 'valueMissing'
});

type ValidationError = $Values<typeof ERRORS>;
export type ValidationResultT = ValidationError | 'VALID';

type RecordData = {
  [recordName: string]: string | number | boolean | null | void | RecordData
};

type ValidationRuleset = {
  isRequired?: (target: any, allData: RecordData) => boolean,
  custom?: (target: any, allData: RecordData) => boolean | string | null | void,
  pattern?: RegExp,
  minlength?: number,
  maxlength?: number,
  currency?: boolean,
  phoneNumber?: boolean,
  positiveInteger?: boolean,
  containsAlphaNum?: boolean,
  hasLowerCase?: boolean,
  hasUpperCase?: boolean,
  requiresSpecialCharacter?: boolean,
  min?: number,
  max?: number,
  email?: boolean,
  isNotFuture?: boolean
};

export type ValidationErrors = {
  [recordName: string]: ValidationErrors | ValidationError
};

export type ValidationRules = {
  [recordName: string]: ValidationRuleset
};

const setError = (errors: ValidationErrors, recordName, error) => {
  const all = getOr([], recordName, errors);
  all.push(error);
  set(errors, recordName, all);
};

export default (attributes: ValidationRules) => {
  return (data: RecordData = {}): ValidationErrors => {
    const errors = {};
    entries(attributes).forEach(([recordName, ruleset]) => {
      const record = get(data, recordName);

      const isEmpty = record == null || record === '';

      // `isRequired` is a special case since all the other
      // rules may pass if they have no data and are not required

      if (ruleset && ruleset.isRequired && ruleset.isRequired(record, data) && validates.required(record) === false) {
        setError(errors, recordName, ERRORS.valueMissing);

        // fail early as only one error per recordset currently reported
        return;
      }

      entries(ruleset).forEach(([validationType, validationValue]) => {
        // Only apply validations to non-empty records
        if (isEmpty) {
          return;
        }

        switch (validationType) {
          // do nothing, but handled above
          case 'isRequired':
            break;
          case 'pattern':
            if (!validates.pattern(validationValue, record)) {
              setError(errors, recordName, ERRORS.patternMismatch);
            }
            break;
          case 'email':
            if (!validates.email(record)) {
              setError(errors, recordName, ERRORS.invalidEmail);
            }
            break;
          case 'minlength':
            if (!validates.minlength(validationValue, record)) {
              setError(errors, recordName, ERRORS.tooShort);
            }
            break;
          case 'maxlength':
            if (!validates.maxlength(validationValue, record)) {
              setError(errors, recordName, ERRORS.tooLong);
            }
            break;
          case 'max':
          case 'min':
            {
              const result = recordValidators[validationType](validationValue, record);
              if (result !== VALID) {
                setError(errors, recordName, result);
              }
            }
            break;
          case 'isNotFuture':
          case 'currency':
          case 'percentage':
          case 'nonZero':
          case 'phoneNumber':
          case 'positiveInteger': {
            const result = recordValidators[validationType](record);
            if (result !== VALID) {
              setError(errors, recordName, result);
            }
            break;
          }
          case 'containsAlphaNum':
            if (!validates.containsAlphaNum(record)) {
              setError(errors, recordName, ERRORS.notAlphaNum);
            }
            break;
          case 'hasUpperCase':
            if (!validates.hasUpperCase(record)) {
              setError(errors, recordName, ERRORS.noUpperCase);
            }
            break;
          case 'hasLowerCase':
            if (!validates.hasLowerCase(record)) {
              setError(errors, recordName, ERRORS.noLowerCase);
            }
            break;
          case 'noSpecialCharacters':
            if (!validates.noSpecialCharacters(record)) {
              setError(errors, recordName, ERRORS.mustNotContainSpecialCharacters);
            }
            break;
          case 'requiresSpecialCharacter':
            if (!validates.hasSpecialCharacter(record)) {
              setError(errors, recordName, ERRORS.mustContainSpecialCharacter);
            }
            break;
          case 'custom': {
            const result = validationValue(record, data);

            if (typeof result === 'boolean' && result === false) {
              setError(errors, recordName, 'customError');
            } else if (typeof result === 'string') {
              setError(errors, recordName, result);
            }
            break;
          }
          default:
            Logger.warn(`No validation rule for "${validationType}" (record name: ${recordName})`);
        }
      });
    });

    return errors;
  };
};
