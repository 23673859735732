import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@kwara/components/src/Button';

import { Auth } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { appName, APP_NAME } from '@kwara/lib/src/utils';
import { WithNotification, notification as Notification } from '@kwara/components/src/Notification';

const UPDATE_CHECK_INTERVAL_MS = 1000 * 60;
const EXPIRY_WINDOW_MS = 1000 * 60 * 5;
const ACTIVITY_WINDOW_MS = 1000 * 60 * 5;

function generateTextJSX(id: string) {
  return <Text id={id} />;
}

function generateLogoutWarningJSX(onStayLoggedIn: () => Promise<void>) {
  return (
    <>
      <Text id="Notifications.signOutWarn" />
      <Button size="small" type="secondary" className="mh3" onClick={onStayLoggedIn}>
        <Text id="Notifications.stayLoggedIn" />
      </Button>
    </>
  );
}

type AuthExpiryCheckerPropTypes<Permissions> = {
  logOut(): void;
  getLastActivityTime(): number;
  notification: Notification;
  auth: Auth<Permissions>;
  children?: React.ReactNode;
};

function AuthExpiryChecker<Permissions>(props: AuthExpiryCheckerPropTypes<Permissions>) {
  const { logOut, getLastActivityTime, notification, auth, children = null } = props;

  const [inactivityWarningDisplayed, setInactivityWarningDisplayed] = useState(false);
  const timerId = useRef<ReturnType<typeof setTimeout>>(null);
  const checkExpirationRef = useRef<Function>(null);
  const history = useHistory();

  /**
   * refreshToken
   */
  const refreshToken = useCallback(async () => {
    if (appName.type === APP_NAME.MEMBER) await auth.refreshTokenConnect();
    else await auth.refreshTokenCore();

    if (inactivityWarningDisplayed) setInactivityWarningDisplayed(false);
  }, [auth, inactivityWarningDisplayed]);

  /**
   * loginExpiredHandler
   */
  const loginExpiredHandler = useCallback(() => {
    notification?.displayWarning?.(generateTextJSX('Notifications.signedOut'));
    logOut();
    history.push('/');
  }, [history, logOut, notification]);

  /**
   * onStayLoggedIn
   */
  const onStayLoggedIn = useCallback(async () => {
    await refreshToken();

    notification?.displayMessage?.(generateTextJSX('Notifications.keepSignedIn'));
  }, [notification, refreshToken]);

  /**
   * displayLogOutWarning
   */
  const displayLogOutWarning = useCallback(() => {
    setInactivityWarningDisplayed(true);
    notification?.displayWarning?.(generateLogoutWarningJSX(onStayLoggedIn));
  }, [notification, onStayLoggedIn]);

  /**
   * @scheduleCheck this function registers "checkExpirationRef.current"  in the
   * JS task queue to be ran after "UPDATE_CHECK_INTERVAL_MS" timestamp
   *
   * @note on every rerender of this component, the "checkExpirationRef.current"
   * will get registered into the JS runtime task queue
   */
  const scheduleCheck = useCallback(() => {
    timerId.current = (setTimeout(checkExpirationRef.current, UPDATE_CHECK_INTERVAL_MS) as unknown) as ReturnType<
      typeof setTimeout
    >;
  }, []);

  /**
   * loginValidHandler
   */
  const loginValidHandler = useCallback(() => {
    const now = Date.now();
    const expiryDate = auth.getExpiryDate();
    const lastActivityTime = getLastActivityTime();
    const expiringSoonWindow = expiryDate && expiryDate.valueOf() - EXPIRY_WINDOW_MS;
    const isExpiringSoon = now > expiringSoonWindow;
    const recentActivityWindow = now - ACTIVITY_WINDOW_MS;
    const isActive = lastActivityTime > recentActivityWindow;
    const shouldRefreshToken = isExpiringSoon && isActive;
    const shouldDisplayLogOutWarning = isExpiringSoon && !isActive && appName.isSacco;

    if (shouldRefreshToken) refreshToken();
    else if (shouldDisplayLogOutWarning) displayLogOutWarning();

    scheduleCheck();
  }, [auth, displayLogOutWarning, getLastActivityTime, refreshToken, scheduleCheck]);

  /**
   * checkExpirationRef.current
   */
  checkExpirationRef.current = useCallback(() => {
    if (!auth.isLoggedIn()) loginExpiredHandler();
    else loginValidHandler();
  }, [auth, loginExpiredHandler, loginValidHandler]);

  useEffect(() => {
    if (checkExpirationRef.current) checkExpirationRef.current();

    return () => {
      clearTimeout(timerId.current);
    };
  }, []);

  return children;
}

export default WithNotification(AuthExpiryChecker);
