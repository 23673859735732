import { useCallback } from 'react';

import auth from '../../lib/auth';

import { httpErrorHandler } from '../../services';
import { MembershipApplications, WithdrawMembershipApplicationPayload } from '../../models/MembershipApplications';

interface DataType extends Omit<WithdrawMembershipApplicationPayload, 'phone_number'> {
  phone: string;
  otp: string;
}

export function useSubmit() {
  const submit = useCallback(async ({ phone: phone_number, otp, device_token }: DataType) => {
    try {
      await auth.verifyOTPV1({ data: { attributes: { phone_number, otp, device_token } } });
      await MembershipApplications.withdraw({ phone_number, device_token });
    } catch (error) {
      const verifyOTPV1Error = error;
      const withdrawError = error?.response?.data;
      const $error = verifyOTPV1Error || withdrawError;

      throw httpErrorHandler.generateError($error, 'custom');
    }
  }, []);

  return submit;
}
