import { useCallback, useState, useEffect, useMemo } from 'react';

import { LoanFormData, LoanSubstepProps } from 'LoanSharedTypes';

import { MemberType } from '@kwara/models/src/models/Member';
import { segmentTrack } from '@kwara/components/src/Segment';
import { PotentialGuarantorType } from '@kwara/models/src/models/Guarantee';

import { useScreenStatusReducer } from './screenStatusState/useScreenStatusReducer';

export interface AddGuarantorDataType extends Omit<LoanFormData, 'guarantors'> {
  guarantors: Array<PotentialGuarantorType>;
  currentScreenStatus: Record<string, boolean>;
}

export function useAddGuarantorActions({
  addData,
  onChange,
  formProps: {
    values: { amount: loanAmount, guarantors = [] }
  }
}: LoanSubstepProps<AddGuarantorDataType>) {
  const [selectedMember, setSelectedMember] = useState<MemberType | null>(null);
  const totalAmountInGuarantee = useMemo(
    () => guarantors.reduce((total, g) => total + parseFloat(String(g.amount)), 0),
    [guarantors]
  );
  const { state: currentScreenStatus, ...action } = useScreenStatusReducer(guarantors);

  useEffect(() => {
    segmentTrack('Long term application flow: Add Guarantor Page');
  }, []);

  /**
   * updateExistingGuarantor
   */
  const updateExistingGuarantor = useCallback(
    async (member: MemberType, amount: string) => {
      const preexistingGuarantorIndex = guarantors.findIndex(guarantor => guarantor.member.id === member.id);
      const updatedGuarantors = [
        ...guarantors.slice(0, preexistingGuarantorIndex),
        { member, amount },
        ...guarantors.slice(preexistingGuarantorIndex + 1, guarantors.length)
      ] as Array<PotentialGuarantorType>;

      await onChange({ guarantors: updatedGuarantors });
      await addData({ guarantors: updatedGuarantors });
    },
    [addData, guarantors, onChange]
  );

  /**
   * addNewGuarantor
   */
  const addNewGuarantor = useCallback(
    async (member: MemberType, amount: string) => {
      const updatedGuarantors = [...guarantors, { member, amount }] as Array<PotentialGuarantorType>;

      await onChange({ guarantors: updatedGuarantors });
      await addData({ guarantors: updatedGuarantors });
    },
    [addData, guarantors, onChange]
  );

  /**
   * updateGuarantors
   */
  const updateGuarantors = useCallback(
    async (member: MemberType, amount: string) => {
      const foundGuarantor = guarantors.findIndex(guarantor => guarantor.member.id === member.id) >= 0;

      if (foundGuarantor) updateExistingGuarantor(member, amount);
      else addNewGuarantor(member, amount);
    },
    [addNewGuarantor, guarantors, updateExistingGuarantor]
  );

  /**
   * onFindGuarantor
   */
  const onFindGuarantor = useCallback(
    async (member: MemberType) => {
      setSelectedMember(member);

      if (member) action.onFindGuarantor();
      else action.onFindGuarantorError();
      await addData({ currentScreenStatus });
    },
    [action, addData, currentScreenStatus]
  );

  /**
   * onErrorFindingGuarantor
   */
  const onErrorFindingGuarantor = useCallback(async () => {
    action.onFindGuarantorError();
    await addData({ currentScreenStatus });
  }, [action, addData, currentScreenStatus]);

  /**
   *  onSaveGuarantor
   */
  const onSaveGuarantor = useCallback(
    async (member: MemberType, amountToGuarantee: string) => {
      updateGuarantors(member, amountToGuarantee);
      action.onSaveGuarantor();
      await addData({ currentScreenStatus });
      segmentTrack('Add guarantors: Save Guarantor CTA');
    },
    [action, addData, currentScreenStatus, updateGuarantors]
  );

  /**
   * onRemoveGuarantor
   */
  const onRemoveGuarantor = useCallback(
    async (member: MemberType) => {
      const guarantorsUpdated = guarantors.filter(guarantor => guarantor.member.id !== member.id);

      action.onRemoveGuarantor();
      await onChange({ guarantors: guarantorsUpdated });
      await addData({ guarantors: guarantorsUpdated, currentScreenStatus });
    },
    [action, addData, currentScreenStatus, guarantors, onChange]
  );

  /**
   * onEditGuarantor
   */
  const onEditGuarantor = useCallback(
    async (member: MemberType) => {
      setSelectedMember(member);
      action.onEditGuarantor();
      await addData({ currentScreenStatus });
    },
    [action, addData, currentScreenStatus]
  );

  /**
   * onAddFirstGuarantors
   */
  const onAddFirstGuarantors = useCallback(async () => {
    action.onAddFirstGuarantor();
    await addData({ currentScreenStatus });
  }, [action, addData, currentScreenStatus]);

  /**
   * onAddMoreGuarantors
   */
  const onAddMoreGuarantors = useCallback(async () => {
    segmentTrack('Add guarantors: Clicked Guarantor CTA');
    action.onFindMoreGuarantors();
    await addData({ currentScreenStatus });
  }, [action, addData, currentScreenStatus]);

  return {
    guarantors,
    loanAmount,
    currentScreenStatus,
    totalAmountInGuarantee,
    selectedMember,
    onFindGuarantor,
    onSaveGuarantor,
    onAddMoreGuarantors,
    onRemoveGuarantor,
    onEditGuarantor,
    onErrorFindingGuarantor,
    onAddFirstGuarantors
  };
}
