import { CreatePIN } from '../SecureFourPinUpdate/components/CreatePIN';

export const steps = {
  createPIN: {
    titleId: 'CreatePin.title',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'CreatePin.title',
        Component: CreatePIN,
        validate: CreatePIN.validate
      }
    ]
  }
};
