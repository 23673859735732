import * as React from 'react';

import map from 'lodash/fp/map';
import get from 'lodash/fp/get';

import { Data } from './EnterOTP';

import auth from '../../../lib/auth';

const documentTypes = ['passport', 'national_id'];
const translationIds = {
  passport: 'PASSPORT',
  national_id: 'NATIONAL'
};

export const Identity = ({ SelectField, TextField, StackChild, formProps }) => {
  const { values } = formProps;
  const idType = get(`id_type`, values);

  return (
    <StackChild>
      <div className="bb b--light-gray pv3">
        <span>
          <SelectField name="id_type" required labelId="Register.Identity.ID.Type.title">
            <SelectField.Option translationId="Register.Identity.ID.Type.NONE" value="" />
            {map(
              idDocumentType => (
                <SelectField.Option
                  translationId={`Register.Identity.Option.${translationIds[idDocumentType]}`}
                  value={translationIds[idDocumentType].toLowerCase()}
                  key={idDocumentType}
                />
              ),
              documentTypes
            )}
          </SelectField>
        </span>

        {idType ? (
          <TextField
            name="id_number"
            required
            labelId="Register.Identity.ID.No.title"
            placeholderId="Register.Identity.ID.No.placeholder"
          />
        ) : null}
      </div>
    </StackChild>
  );
};

Identity.verify = async (
  { id_type, id_number, phone }: Data,
  onChange: (data: Record<'device_token', string>) => Promise<unknown>
) => {
  try {
    const response = await auth.optDevice({ data: { attributes: { phone_number: phone, id_type, id_number } } });
    onChange({ device_token: response.data.attributes.device_token });
  } catch ({ errors }) {
    throw errors;
  }
};

Identity.validateConfig = {
  id_type: {
    isRequired: () => true
  },
  id_number: {
    isRequired: () => true
  }
};
