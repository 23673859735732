import React, { useState } from 'react';
import get from 'lodash/fp/get';
import { RouteChildrenProps } from 'react-router-dom';

import Auth from '@kwara/models/src/lib/Auth';
import Wizard, { getSubstepFromMatchParams, StepConfig } from '@kwara/components/src/Wizard/deprecated/Wizard';

import { steps } from './config';
import { useStore } from '../../models/Member';
import { Welcome } from '../../components/Welcome';
import { Data, CreatePIN } from './components/CreatePIN';
import { useNewUserStore } from '../../models/NewUserStore';
import { StatusApprovalModal } from '../../components/StatusApprovalModal';

/**
 * @loginUser programmatically login User and
 * redirect to Home Screen
 */
async function loginUser(auth: Auth, phone: string, pin: string) {
  try {
    await auth.connectWithFourDigitLogIn({ phone, pin });

    window.location.replace('/');
  } catch (error) {
    throw error;
  }
}

interface SecureFourPinUpdatePropTypes extends RouteChildrenProps {
  auth: Auth;
  baseUrl: string;
  startId?: string;
  onCancel?(): void;
  configSteps?: StepConfig;
  onSubmit?: (d: Data, auth: Auth) => Promise<any>;
}

// This is the Activation flow
export function SecureFourPinUpdate({
  configSteps = steps,
  auth,
  match,
  baseUrl,
  history,
  onCancel,
  startId = 'identity',
  onSubmit
}: SecureFourPinUpdatePropTypes) {
  const [pin, setPin] = useState('');
  const [showWelcome, setShowWelcome] = useState(false);

  /**
   * @Note It is possible to get to ForgotPIN from login page
   * and any page that the user needs to enter PIN again,
   * therefore the origin of the phone number can change
   */
  const { storePhone: unAuthPhone } = useNewUserStore(); // User data before log in
  const store = useStore();

  const params = new URLSearchParams(history.location.search);
  const authPhone = get('member.phoneNumber', store); // Logged in user data
  const phone: string = unAuthPhone || authPhone;

  return (
    <Wizard
      steps={configSteps}
      startId={startId}
      baseUrl={baseUrl}
      history={history}
      onCancel={onCancel}
      initialData={{ ...params, phone }}
      cancelReturnsTo="/"
      analyticsId="FourDigitPINUpdate"
      titleId="CreatePin.connect.title"
      currentStep={get('params.step', match)}
      currentSubStep={getSubstepFromMatchParams(match.params)}
      onSubmit={(data: Data) => {
        setPin(data.pin1);
        if (onSubmit) onSubmit(data, auth);
        else CreatePIN.createMember(data);
      }}
      onRenderCustomCompletionScreen={() => {
        if (showWelcome) return <Welcome />;

        return (
          <StatusApprovalModal
            show={true}
            type="good"
            titleId="StatusApprovalModal.create4PIN.title"
            subtitleId="StatusApprovalModal.create4PIN.subtitle"
            buttonId="StatusApprovalModal.create4PIN.action"
            onConfirm={() => {
              setShowWelcome(true);
              loginUser(auth, phone, pin);
            }}
          />
        );
      }}
    />
  );
}
