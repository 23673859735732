//@flow
import React from 'react';
import cx from 'classnames';
import { Form } from 'react-final-form';

import zIndices from '@kwara/lib/src/zIndices';
import { Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';
import { SubscribedTextField } from '@kwara/components/src/Form';
import { type MemberType } from '@kwara/models/src';
import createValidator from '@kwara/lib/src/validator';

import { useStore } from '../../models/Member';
import styles from './index.module.scss';

type AddGuarantorCardProps = {
  member: MemberType | null,
  onSaveGuarantor: (member: MemberType, amount: string) => void,
  onRemoveGuarantor: (member: MemberType) => void,
  loanAmount: number,
  totalAmountInGuarantee: number
};

export const AddGuarantorCard = ({
  member,
  onSaveGuarantor,
  onRemoveGuarantor,
  loanAmount,
  totalAmountInGuarantee
}: AddGuarantorCardProps) => {
  const store = useStore();

  const onSaveClick = (values: { amountToGuarantee: string }) => {
    const { amountToGuarantee } = values;
    if (member) {
      onSaveGuarantor(member, amountToGuarantee);
    }
  };

  return (
    <div className={cx(styles.Container, 'mb3')}>
      <Form
        onSubmit={() => {}}
        validate={createValidator({
          amountToGuarantee: {
            isRequired: () => true,
            custom: target => {
              if (Number(target) + Number(totalAmountInGuarantee) > Number(loanAmount)) {
                return 'amountToGuaranteeExceeded';
              }
              return null;
            },
            currency: true
          }
        })}
        render={({ values, invalid }) => {
          return (
            <div className={styles.Content}>
              <div className={cx(styles.Body, ` flex-1 hidden ${zIndices.OverlayMenus}`)}>
                <div className={cx(styles.Subtitle, 'mobile-text-small')}>
                  <div className="mv3">
                    <div className="mobile-text-x-small">
                      <Text id={'AddGuarantorCard.member'} />
                    </div>
                    <div>{member.fullName()}</div>
                  </div>
                  <SubscribedTextField
                    name="amountToGuarantee"
                    labelId="AddGuarantorCard.amountToGuarantee"
                    className="bg-white"
                    placeholderId="AddGuarantorCard.amountToGuarantee.placeholder"
                    values={{ currency: store.currency }}
                    size="medium"
                    required
                    isCurrency
                  />
                </div>
              </div>
              <div className={cx(styles.Footer, `flex justify-center m3 ${zIndices.OverlayMenus}`)}>
                <Button
                  aria-label="Remove"
                  className={cx(styles.Remove, 'mr3 w-100 flex justify-center')}
                  type="secondary"
                  size="regular"
                  onClick={() => onRemoveGuarantor(member)}
                >
                  <Text id="AddGuarantorCard.remove" />
                </Button>
                <Button
                  aria-label="Save"
                  onClick={() => onSaveClick({ amountToGuarantee: values.amountToGuarantee })}
                  className="ml3 w-100 flex justify-center"
                  type="primary"
                  size="regular"
                  disabled={invalid}
                >
                  <Text id="AddGuarantorCard.save" />
                </Button>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};
