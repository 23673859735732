import * as React from 'react';
import cx from 'classnames';
import noop from 'lodash/fp/noop';

import { Link } from '@kwara/components/src/Link';
import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';
import { Checkbox } from '@kwara/components/src/Form';
import { AttachmentMeta } from '@kwara/models/src/models/Attachment';

import { accept } from './utils';
import { IconUpload } from './assets/IconUpload.svg';
import { IconDone } from './assets/IconDone.svg';

import styles from './UploadWidgetUI.module.scss';

export type FileNameT = AttachmentMeta['name'] | 'profile';

export interface UploadWidgetProps {
  labelId: string;
  infoId?: string;
  fileName: FileNameT;
  onRemove: (event?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onFileInputChange: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  onView: (event?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  checked: boolean;
  style?: any;
}

export function UploadWidgetUI({
  labelId,
  fileName,
  onRemove,
  onFileInputChange,
  onView,
  checked,
  style = styles
}: UploadWidgetProps) {
  return (
    <div className={cx(style.Content, 'relative b--dashed br3 bw1 b--light-gray flex items-center')}>
      <div className={cx(style.CoreContent, 'pt2 dib')}>
        <Checkbox checked={checked} labelId={labelId} name={fileName} readOnly compact />
      </div>

      <If
        condition={checked}
        do={<div />}
        else={
          <>
            <div className={cx(style.CoreContent)}>
              <Link className={cx(style.LinkContainer)} onClick={noop} size="regular" type="primary" underline active>
                <span className={style.InputText}>Upload</span>
              </Link>
            </div>
            <div className={cx(style.MobileContent, 'flex items-center')}>
              <div className="mr1">
                <Text id={labelId} />
              </div>
              <IconUpload />
            </div>
          </>
        }
      />
      {/**
       * @dirty
       *
       * If input is dirty we allow removing the file
       * If not we allow uploading a new file
       *
       */}

      <If
        condition={checked}
        do={
          <>
            <div className={cx(style.CoreContent, 'flex')}>
              <span role="button" className="pointer kw-text-regular red-500 underline red" onClick={onRemove}>
                <Text id="DocumentUploads.Remove.label" />
              </span>{' '}
              <span role="button" className="pl2 pointer kw-text-regular indigo-500 underline" onClick={onView}>
                <Text id="DocumentUploads.View.label" />
              </span>
            </div>
            <div className={cx(style.MobileContent, 'flex items-center')}>
              <div className={cx(style.CompleteLabel, 'mr1')}>
                <Text id="DocumentUploads.Uploaded" />
              </div>
              <div>
                <IconDone />
              </div>
              {/* On mobile we are able to update the file*/}
              <input
                type="file"
                data-testid="file-input"
                className={style.InputFile}
                accept={accept}
                onChange={onFileInputChange}
              />
            </div>
          </>
        }
        else={
          <input
            data-testid="file-input"
            type="file"
            className={style.InputFile}
            accept={accept}
            onChange={onFileInputChange}
          />
        }
      />
    </div>
  );
}
