// @flow
import React from 'react';
import { FormattedNumber } from 'react-intl';

type Props = {
  value: number
};

export default ({ value }: Props) => (
  // eslint-disable-next-line react/style-prop-object
  <FormattedNumber style="percent" value={value} maximumFractionDigits={2} />
);
