import React from 'react';

import { LoanFormData } from 'LoanSharedTypes';

import { If } from '@kwara/components/src/If/If';
import { SubscribedTextField } from '@kwara/components/src/Form';

import { derivedInstallmentMeta, useInstallments, ONE_MONTH } from './useInstallments';

export interface SharedLoanAddValues extends LoanFormData {
  installments: string | undefined | null;
}

type InstallmentsPropTypes = {
  minDuration?: string | null;
  maxDuration?: string | null;
  TextField: typeof SubscribedTextField;
};

function Installments({ minDuration, maxDuration, TextField }: InstallmentsPropTypes) {
  const { min, max, renderable } = useInstallments(minDuration, maxDuration);

  return (
    <If
      condition={renderable}
      do={
        <TextField
          required
          type="number"
          name="installments"
          min={min}
          max={max}
          labelId="LoanAdd.Connect.Configure.InstantLoan.Installments.label"
        />
      }
    />
  );
}

Installments.validate = {
  installments: {
    isRequired: () => true,
    custom(installments: string, { product }: SharedLoanAddValues) {
      const { min, max } = derivedInstallmentMeta(product.minDuration, product.maxDuration);
      if (Number(installments) < min) return 'rangeUnderflow';

      if (Number(installments) > max) return 'rangeOverflow';

      return null;
    }
  }
};

export { Installments, ONE_MONTH };
