import React, { useCallback } from 'react';
import noop from 'lodash/noop';

import { createContext } from '@kwara/lib/src/utils/createContext';

const LEVELS = {
  error: 'error',
  success: 'success',
  warning: 'warning'
} as const;

type Levels = keyof typeof LEVELS;

type NotificationType = {
  level: Levels;
  message: React.ReactNode;
  onDismiss?: () => void;
  customOnDismiss?: () => void;
};

type Handler = (message: React.ReactNode) => void;

export type NotificationContextType = {
  current: NotificationType | null;
  displayError: Handler;
  displayWarning: Handler;
  displayMessage: Handler;
  dismiss: () => void;
};

type StateType = {
  current: NotificationType | null;
};

const [NotificationContextProvider, useNotificationContext, NotificationContextConsumer] = createContext<
  NotificationContextType
>('NotificationContext');

function NotificationProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = React.useState<StateType>({ current: null });

  function addNotification(notification: NotificationType) {
    setState({ current: notification });
  }

  const displayError = useCallback((message: React.ReactNode) => {
    addNotification({ level: LEVELS.error, message });
  }, []);

  const displayMessage = useCallback((message: React.ReactNode) => {
    addNotification({ level: LEVELS.success, message });
  }, []);

  const displayWarning = useCallback((message: React.ReactNode, customOnDismiss: () => void = noop) => {
    addNotification({ level: LEVELS.warning, message, customOnDismiss });
  }, []);

  const dismiss = useCallback(() => {
    const { customOnDismiss = noop } = state.current || {};

    customOnDismiss();

    setState({ current: null });
  }, [state]);

  const values = React.useMemo(
    () => ({
      current: state.current,
      displayError,
      displayWarning,
      displayMessage,
      dismiss
    }),
    [state, displayError, displayWarning, displayMessage, dismiss]
  );

  return <NotificationContextProvider value={values}>{children}</NotificationContextProvider>;
}

export { useNotificationContext, NotificationProvider, NotificationContextConsumer };
