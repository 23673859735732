// @flow

import { attr, hasOne } from 'spraypaint';
import axios from 'axios';

import { csvHeaders, blobToFileDownload } from '@kwara/lib/src/fileDownload';

import Base, { type BaseModel } from './Base';
import SavingProduct, { type SavingProductType } from './SavingProduct';

export const CalculationTypes = Object.freeze({
  FLAT: 'FLAT',
  WEIGHTED_AVERAGE: 'WEIGHTED_AVERAGE'
});
export type CalculationTypesT = $Values<typeof CalculationTypes>;

const States = Object.freeze({
  QUEUED_UP: 'queued_up',
  CALCULATING: 'calculating',
  FAILED: 'failed',
  CALCULATED: 'calculated'
});
export type DividendCalculationStatesT = $Values<typeof States>;

export const DividendCalculation = Base.extend({
  static: {
    jsonapiType: 'dividend_calculation',
    endpoint: '/dividend_calculations'
  },
  attrs: {
    savingsProduct: attr(),
    calculationMethod: attr(),
    state: attr(),
    result: attr(),
    beginningOfFinancialYear: attr(),
    endOfFinancialYear: attr(),
    product: hasOne({ type: SavingProduct })
  },
  methods: {
    calculated() {
      return this.state === States.CALCULATED;
    },
    downloadCSV(percent: string) {
      const url = `${DividendCalculation.url()}/${this.id}/download_csv?percentage=${percent}`;
      const opts = DividendCalculation.fetchOptions();
      const options = csvHeaders(opts);

      return axios.get(url, options).then(res => {
        const blob = new Blob(['\ufeff' + res.data], {
          type: 'text/csv;charset=utf-8;'
        });

        try {
          blobToFileDownload(blob, `dividends_${this.id}_${this.calculationMethod}.csv`);
        } catch (e) {
          return Promise.reject(e);
        }

        return Promise.resolve();
      });
    }
  }
});

export interface DividendCalculationT extends BaseModel<DividendCalculationT> {
  savingsProduct: SavingProductType;
  calculationMethod: CalculationTypesT;
  state: DividendCalculationStatesT;
  calculated: () => boolean;
  product: SavingProductType;
  result: ?number;
  beginningOfFinancialYear: string;
  endOfFinancialYear: string;
  downloadCSV: (interest: string) => Promise<void>;
}
