import { attr, hasMany } from 'spraypaint';
import get from 'lodash/fp/get';

import Base, { BaseModel } from './Base';
import { BankBranchT } from './BankBranch';

export const Bank = Base.extend({
  static: {
    jsonapiType: 'banks'
  },
  attrs: {
    name: attr(),
    code: attr(),
    bankBranches: hasMany(),
    directDebitOriginatingReferencePrefix: attr(),
    directDebitFee: attr()
  },
  methods: {
    deserialize() {
      this.bankBranchId = get('bankBranch.id', this);
      return this;
    }
  }
});

export interface BankT extends BaseModel<BankT> {
  id: string;
  name: string;
  code: string;
  directDebitOriginatingReferencePrefix: string;
  directDebitFee: number;
  bankBranches: BankBranchT[];
}
