import { attr } from 'spraypaint';
import Base, { BaseModel } from './Base';

export const LoanApplicationNote = Base.extend({
  static: {
    jsonapiType: 'loan_application_notes'
  },
  attrs: {
    id: attr(),
    flow: attr(),
    step: attr(),
    section: attr(),
    value: attr()
  }
});

export interface LoanApplicationNoteT extends BaseModel<LoanApplicationNoteT> {
  id: string;
  flow: 'no_flow' | 'loan_application' | 'loan_appraisal';
  step:
    | 'no_step'
    | 'member_profile'
    | 'loan_product'
    | 'pay_off'
    | 'security'
    | 'loan_details'
    | 'income_and_security'
    | 'credit_check'
    | 'recommendations'
    | 'summary_and_review';
  section: 'no_section' | 'income' | 'collateral' | 'self_guarantee';
  value: string;
}
