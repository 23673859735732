import React, { useState, useMemo, useRef, useCallback } from 'react';

import {
  FinancialProduct,
  IncomeRange,
  AgeGroup,
  RecommendedOrganisationResolvedResponse,
  RecommendedOrganisationData,
  RecommendedOrganisationFunction
} from 'RecommendedOrganisationTypes';

import cameliseObjectKeys from '@kwara/models/src/lib/cameliseObjectKeys';

import { httpErrorHandler } from '@kwara/lib/src/services';
import { Organisation } from '@kwara/models/src/models/Organisation';

import { FormState } from '..';
import { Data, FINANCIAL_PRODUCTS } from '../../../models/RecommendedOrganisation/data';

export function useSuggestSaccoContextValues() {
  const { current: data } = useRef<Data>(new Data());
  const [incomeRange, setIncomeRange] = useState<IncomeRange>(null!);
  const [financialProduct, setFinancialProduct] = useState<FinancialProduct>(null!);
  const [ageGroup, setAgeGroup] = useState<AgeGroup>(null!);
  const [financialProductCustom, setFinancialProductCustom] = useState<string | undefined>(undefined!);
  const [isRecommending, setIsRecommending] = useState(false);
  const [apiData, setApiData] = useState<RecommendedOrganisationData>(null);
  const [error, setError] = useState<ReturnType<typeof httpErrorHandler.generateError>>(null);
  const [deviceToken, setDeviceToken] = useState<string>(null);

  const isOthersSelectedAsFinancialProduct = financialProduct === FINANCIAL_PRODUCTS.OTHERS;

  const onSetFinancialProduct = useCallback(
    (value: FinancialProduct, addData: (updated: Partial<FormState>) => void) => {
      return function onClickHandler(_ev: React.MouseEvent<HTMLButtonElement>) {
        setFinancialProduct(value);
        addData({ financialProduct: { type: value, value: value === FINANCIAL_PRODUCTS.OTHERS ? '' : value } });
      };
    },
    []
  );

  const onSetIncomeRange = useCallback((value: IncomeRange, addData: (updated: Partial<FormState>) => void) => {
    return function onClickHandler(_ev: React.MouseEvent<HTMLButtonElement>) {
      setIncomeRange(value);
      addData({ incomeRange: value });
    };
  }, []);

  const onSetAgeGroup = useCallback((value: AgeGroup, addData: (updated: Partial<FormState>) => void) => {
    return function onClickHandler(_ev: React.MouseEvent<HTMLButtonElement>) {
      setAgeGroup(value);
      addData({ ageGroup: value });
    };
  }, []);

  const checkIsActiveFinancialProduct = useCallback((value: FinancialProduct) => value === financialProduct, [
    financialProduct
  ]);

  const checkIsActiveIncomeRange = useCallback((value: IncomeRange) => value === incomeRange, [incomeRange]);

  const checkIsActiveAgeGroup = useCallback((value: AgeGroup) => value === ageGroup, [ageGroup]);

  const onOthersOnChangeHandler = React.useCallback((addData: (updated: Partial<FormState>) => void) => {
    return (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
      addData({ financialProduct: { type: FINANCIAL_PRODUCTS.OTHERS, value: ev.target.value } });

      setFinancialProductCustom(ev.target.value);
    };
  }, []);

  const onSubmit = useCallback(async () => {
    setIsRecommending(true);

    try {
      const response = await (Organisation as RecommendedOrganisationFunction).recommend({
        financialProduct,
        incomeRange,
        ageGroup,
        financialProductCustom: financialProductCustom || undefined
      });

      setApiData(cameliseObjectKeys((response.data as RecommendedOrganisationResolvedResponse).data.attributes));
    } catch (err) {
      setError(httpErrorHandler.generateError(err));

      /**
       * we throw the error and wizard catches
       * it and display the message nicely
       */
      throw err;
    } finally {
      setIsRecommending(false);
    }
  }, [ageGroup, financialProduct, financialProductCustom, incomeRange]);

  return useMemo(
    () => ({
      data,
      apiData,
      error,
      ageGroup,
      incomeRange,
      financialProduct,
      financialProductCustom,
      isRecommending,
      onSubmit,
      onSetAgeGroup,
      onSetIncomeRange,
      onSetFinancialProduct,
      checkIsActiveAgeGroup,
      onOthersOnChangeHandler,
      checkIsActiveIncomeRange,
      checkIsActiveFinancialProduct,
      isOthersSelectedAsFinancialProduct,
      deviceToken,
      setDeviceToken
    }),
    [
      ageGroup,
      apiData,
      checkIsActiveAgeGroup,
      checkIsActiveFinancialProduct,
      checkIsActiveIncomeRange,
      data,
      deviceToken,
      error,
      financialProduct,
      financialProductCustom,
      incomeRange,
      isOthersSelectedAsFinancialProduct,
      isRecommending,
      onOthersOnChangeHandler,
      onSetAgeGroup,
      onSetFinancialProduct,
      onSetIncomeRange,
      onSubmit
    ]
  );
}
