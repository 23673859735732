import React, { useState } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

import Asset from '@kwara/components/src/Asset';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import { ErrorTextWithI18n } from '@kwara/components/src/ErrorText';
import { AccessibleLoader } from '@kwara/components/src/AccessibleLoader';

import { Item } from './Item';
import { Loader } from './Loaders';
import { Store } from '../../models/Store';
import { useStore } from '../../models/Member';
import { useGroupMemberships } from './useGroupMemberships';
import { useSetPrimaryMemberships } from './useSetPrimaryMemberships';
import { MembershipsType, useGetMemberships } from './useGetMemberships';

import styles from './index.module.scss';

type PrimitiveDivPropTypes = React.ComponentPropsWithoutRef<'div'>;
type MembershipsElement = React.ElementRef<'div'>;

interface MembershipsPropTypes extends PrimitiveDivPropTypes {
  toggle(): void;
}

export const Memberships = React.forwardRef<MembershipsElement, MembershipsPropTypes>(function Memberships(
  { toggle, className, ...rest },
  forwardedRef
) {
  const [clickedPrimarySacco, setClickedPrimarySacco] = useState(null);
  const history = useHistory();
  const store = useStore() as Store;
  const getMQuery = useGetMemberships();
  const setMQuery = useSetPrimaryMemberships();
  const groupMemberships = useGroupMemberships();

  return (
    <div ref={forwardedRef} {...rest} className={cx(styles['container'], className)}>
      <div className={styles['container-row1']}>
        <button type="button" title="Close" onClick={toggle}>
          <Asset id={Asset.Glyphs.CrossCircle} col={Asset.Colours.black} />
        </button>
      </div>
      <If
        condition={setMQuery.isLoading}
        do={
          <AccessibleLoader
            itsTextLoader
            isLoading={setMQuery.isLoading}
            loadingTextId="Memberships.settingPrimaryMembership"
          />
        }
      />
      <If
        condition={setMQuery.isError && clickedPrimarySacco}
        do={
          <ErrorTextWithI18n
            className="pb3"
            id="Memberships.Errors.settingPrimaryMembership"
            values={{ orgName: clickedPrimarySacco }}
          />
        }
      />
      <Loadable {...getMQuery} loading={<Loader />}>
        {(memberships: MembershipsType) => {
          const { active, inactive } = groupMemberships(memberships);

          return (
            <>
              <ul className={cx({ [styles['disabled-list']]: setMQuery.isLoading })}>
                {active.map(org => {
                  const isPrimarySacco = org.attributes.org_permalink === store.organisation?.permalink;

                  return (
                    <Item
                      {...org}
                      key={org.id}
                      onClick={() => {
                        setClickedPrimarySacco(org.attributes.org_name);
                        setMQuery.onSetPrimaryMembership(org.attributes.org_permalink);
                      }}
                      meta={isPrimarySacco ? <Text id="Memberships.primarySacco" /> : undefined}
                      className={cx({ [styles['disabled-item']]: isPrimarySacco })}
                    />
                  );
                })}
              </ul>
              <ul className={cx({ [styles['disabled-list']]: setMQuery.isLoading })}>
                {inactive.map(org => (
                  <Item
                    {...org}
                    key={org.id}
                    onClick={() => history.push(`/memberships/activation/${org.attributes.org_permalink}`)}
                    meta={<Text id="Memberships.verificationReq" />}
                  />
                ))}
              </ul>
            </>
          );
        }}
      </Loadable>
    </div>
  );
});
