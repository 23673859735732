// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import find from 'lodash/fp/find';
import get from 'lodash/fp/get';

import { SubscribedSelectField as SelectField } from '..';

export const BankSelect = ({
  name,
  banks = BankSelect.appStore.banks,
  labelId,
  titleId,
  inputOnChange = () => {}
}: {
  name: string,
  banks?: any,
  labelId?: ?string,
  titleId?: string,
  inputOnChange?: Function
}) => {
  const isControlledLabel = labelId !== undefined;

  return (
    <SelectField
      name={name}
      titleId={titleId}
      labelId={isControlledLabel ? labelId : 'Forms.TransactionForm.Bank.label'}
      required
      inputOnChange={inputOnChange}
    >
      <SelectField.Option translationId={`Forms.TransactionForm.Bank.default`} value="" />
      {map(
        o => (
          <SelectField.Option value={o.id} key={o.id}>
            {o.name}
          </SelectField.Option>
        ),
        banks
      )}
    </SelectField>
  );
};

export const BranchSelect = ({
  name = 'bankBranch',
  bankId,
  labelId,
  banks = BankSelect.appStore.banks,
  required = true
}: {
  name: string,
  bankId: string,
  banks?: any,
  required?: boolean,
  labelId?: ?string
}) => {
  const isControlledLabel = labelId !== undefined;
  const bank = find(b => Number(b.id) === Number(bankId), banks);
  const branches = get('bankBranches', bank) || [];

  return (
    <SelectField
      required={required}
      name={name}
      labelId={isControlledLabel ? labelId : 'Forms.TransactionForm.Branch.label'}
    >
      <SelectField.Option value="" translationId={`Forms.TransactionForm.Branch.default`} />
      {map(
        branch => (
          <SelectField.Option value={branch.id} key={branch.id}>
            {branch.name}
          </SelectField.Option>
        ),
        branches
      )}
    </SelectField>
  );
};
