// @flow
import * as React from 'react';
import { useQuery, OptionsT } from 'react-query';
import get from 'lodash/fp/get';
import first from 'lodash/fp/first';

import { type MemberType } from '@kwara/models/src';
import { type RequestProps, type LoadableBasePropsT } from '@kwara/components/src/Loadable';

import {
  Bank,
  BankAccount,
  BankGlAccount,
  ConnectUserBase,
  Member,
  MemberGuarantee,
  Loan,
  LoanV2,
  LoanClassification,
  LoanProduct,
  Saving,
  Schedule,
  SmsPlan,
  MpesaCredentials,
  SmsPayment,
  MpesaLimit,
  MpesaTransaction,
  MpesaTransactionRegistrationFee,
  type IncludesT,
  type LoanType,
  type LoanV2Type,
  type SavingType,
  type ScheduleType,
  type MpesaTransactionType
} from '../..';

import { useModelRequest, useModelsRequest, isEMPTY } from '.';

export { isEMPTY };

export interface HttpErrorObject {
  status: number;
  detail?: string;
  title: string;
  code?: string;
  message?: string;
}

export interface HttpError1 {
  errors: HttpErrorObject[];
}

async function fetchConnectProfile({ queryKey }) {
  const [_, includes] = queryKey;
  const res = await ConnectUserBase.includes(includes).find();
  return res.data;
}

const defaultConnectProfileIncludes = ['organisation.organisation_setting'];
export const useConnectProfile = (includes: IncludesT = defaultConnectProfileIncludes) =>
  useQuery(['connect_profile', includes], fetchConnectProfile);

const defaultMemberIncludes = ['id_documents', 'attachments', 'addresses'];

export const formatError = async e => {
  const status = get('response.status', e);

  try {
    const parsed = await e.response.json();
    const response = { messages: first(parsed.errors) };
    return { status, response };
  } catch (_) {
    return { status, response: { messages: { title: 'Unknown error' } } };
  }
};

export async function fetchMember({ queryKey }): Promise<MemberType> {
  const [_, id, includes, fields] = queryKey;
  try {
    if (!id) {
      return Promise.resolve(null);
    }
    const res = await Member.select(fields)
      .includes(includes)
      .find(id);

    return res.data.deserialize();
  } catch (e) {
    throw await formatError(e);
  }
}

// inexpensive member data
const fieldsMemberLight = [
  'id',
  'first_name',
  'middle_name',
  'last_name',
  'title',
  'date_of_birth',
  'phone_number',
  'secondary_phone_number',
  'email',
  'work_email',
  'state',
  'source',
  'kin',
  'created_at',
  'updated_at',
  'marital_status',
  'employer',
  'staff_id',
  'business',
  'employment_status',
  'income_source',
  'other_income_amount',
  'disposable_income_amount',
  'employer_phone_number',
  'employer_email',
  'gross_income',
  'guaranteeable_loan_amount',
  'net_income',
  'other_deductibles',
  'terms_of_service',
  'joining_fee_reference',
  'gender',
  'profession',
  'kra_pin',
  'gov_employee',
  'is_staff',
  'is_delegate',
  'is_director',
  'is_group',
  'mbanking_status'
];

export const useMemberLight = (id: string, includes: IncludesT = defaultMemberIncludes) =>
  useQuery(['member_light', id, includes, fieldsMemberLight], fetchMember, {
    retry: (failCount, error) => (error.status === 404 ? false : failCount < 3),
    refetchOnWindowFocus: false
  });

// expensive member data
const fieldsMemberMetrics = [
  'total_savings',
  'total_loans',
  'total_loans_balance',
  'total_guaranteed',
  'total_self_guaranteed',
  'total_liabilities',
  'eligible_amount',
  'standing',
  'share_capital_amount'
];

export const useMemberMetrics = (id: string) =>
  useQuery(['member_details_metrics', id, [], fieldsMemberMetrics], fetchMember, {
    retry: (failCount, error) => (error.status === 404 ? false : failCount < 3)
  });

export const useMember = (id: string, includes: IncludesT = [...defaultMemberIncludes, 'loans'], opts: OptionsT = {}) =>
  useQuery(['member', id, includes], fetchMember, {
    retry: (failCount, error) => (error.status === 404 ? false : failCount < 3),
    ...opts
  });

const nextOfKinsIncludes = ['next_of_kins', 'next_of_kin'];
const nextOfKinsFields = ['id'];
export const useNextOfKins = (memberId: string) =>
  useQuery(['next_of_kins', memberId, nextOfKinsIncludes, nextOfKinsFields], fetchMember);

async function fetchMemberGuarantees({ queryKey }) {
  const [_, id, includes] = queryKey;
  const res = await MemberGuarantee(id)
    .includes(includes)
    .all();
  return res.data;
}

const defaultMemberGuaranteesIncludes = ['loan'];
type UseMemberGuaranteesArgs = {
  memberId: string,
  includes?: IncludesT,
  options?: OptionsT
};
export const useMemberGuarantees = ({
  memberId,
  includes = defaultMemberGuaranteesIncludes,
  options
}: UseMemberGuaranteesArgs) => useQuery(['member_guarantees', memberId, includes], fetchMemberGuarantees, options);

async function fetchBankGlAccounts({ queryKey }) {
  const [_, includes] = queryKey;
  const res = await BankGlAccount.includes(includes).all();
  return res.data;
}

const defaultBankGlAccountsIncludes = [];
export const useBankGlAccounts = (includes: IncludesT = defaultBankGlAccountsIncludes) =>
  useQuery(['bank_gl_accounts', includes], fetchBankGlAccounts);

export async function fetchLoan({ queryKey }) {
  const [_, id, includes] = queryKey;
  try {
    if (!id) {
      return Promise.resolve(null);
    }
    const res = await Loan.includes(includes).find(id);
    return res.data.deserialize();
  } catch (e) {
    throw await formatError(e);
  }
}

export const defaultLoanIncludes = [
  'member.attachments',
  'member',
  'product',
  'remittance.collecting_bank',
  'remittance.bank_account.bank_branch.bank',
  'loan_application.pay_off_loans.product',
  'loan_application.loan_application_fees',
  'loan_application.loan_application_notes',
  'loan_application.loan_application_attachments',
  'loan_application.disbursement.appraisals.user',
  'loan_purpose.classification.parent.parent'
];

export const useLoan = (id: string, includes: IncludesT = defaultLoanIncludes): LoadableBasePropsT<LoanType> =>
  useQuery(['loan', id, includes], fetchLoan, {
    retry: (failCount, error) => (error.status === 404 ? false : failCount < 3)
  });

export const defaultFullLoanIncludes = [...defaultLoanIncludes, 'guarantees.member', 'collaterals.attachments'];

export const useFullLoan = (id: string, includes: IncludesT = defaultFullLoanIncludes): LoadableBasePropsT<LoanType> =>
  useQuery(['full_loan', id, includes], fetchLoan);

const defaultLoansGuaranteedIncludes = ['product', 'guarantees.member', 'collaterals', 'member.attachments'];

// @TODO This is a temproray solution to separate the load of security data.
// We need to add a separate endpoint to fetch security data and not side-load them.
export const useLoansGuaranteed = (
  id: string,
  includes: IncludesT = defaultLoansGuaranteedIncludes
): LoadableBasePropsT<LoanType> => useQuery(['loans_guaranteed]', id, includes], fetchLoan);

export const useLoans = (includes: IncludesT = defaultLoanIncludes): LoadableBasePropsT<LoanType> =>
  useModelsRequest<LoanType>({ entity: Loan, includes });

async function fetchLoanExtras({ queryKey }) {
  const [_, id, includes] = queryKey;
  if (!id) {
    return Promise.resolve(null);
  }
  const res = await LoanV2.includes(includes).find(id);
  return res.data.deserialize();
}

const defaultLoanExtraIncludes = ['product'];
export const useLoanExtras = (
  id: string,
  includes: IncludesT = defaultLoanExtraIncludes
): LoadableBasePropsT<LoanV2Type> => useQuery(['loan_extras', id, includes], fetchLoanExtras);

async function fetchSaving({ queryKey }) {
  const [_, id, includes] = queryKey;
  try {
    if (!id) {
      return Promise.resolve(null);
    }
    const res = await Saving.includes(includes).find(id);
    return res.data.deserialize();
  } catch (e) {
    throw await formatError(e);
  }
}
export const defaultSavingIncludes = [
  'member',
  'product',
  'remittance.bank_account.bank_branch.bank',
  'remittance.collecting_bank'
];

export const useSaving = (id: string, includes: IncludesT = defaultSavingIncludes): RequestProps<SavingType> =>
  useQuery(['saving', id, includes], fetchSaving, {
    retry: (failCount, error) => (error.status === 404 ? false : failCount < 3)
  });

async function fetchSchedule({ queryKey }) {
  const [_, id, includes] = queryKey;
  if (!id) {
    return Promise.resolve(null);
  }
  const res = await Schedule.includes(includes).find(id);
  return res.data.deserialize();
}

export const defaultScheduleIncludes = [];
export const useSchedule = (id: string, includes: IncludesT = defaultScheduleIncludes): RequestProps<ScheduleType> =>
  useQuery(['schedule', id, includes], fetchSchedule);

const defaultBankIncludes = [];
export const useBanks = (includes: IncludesT = defaultBankIncludes) =>
  useModelsRequest<BankT>({ entity: Bank, includes });

const collectingBankFilter = { collecting_bank: 'true' };
export const useCollectingBank = () =>
  useModelsRequest<BankT>({ entity: Bank, includes: defaultBankIncludes, where: collectingBankFilter });

const defaultBankAccountIncludes = [];
export const useBankAccounts = (
  memberId: string,
  includes: IncludesT = defaultBankAccountIncludes,
  enabled?: boolean
) => {
  const where = React.useMemo(() => ({ mambu_id: memberId }), [memberId]);
  return useModelsRequest<BankAccountT>({ entity: BankAccount, includes, where }, enabled);
};

const defaultMpesaLimitIncludes = [];
export const useMpesaLimit = (includes: IncludesT = defaultMpesaLimitIncludes) =>
  useModelRequest<MpesaLimitT>(MpesaLimit, includes);

const defaultMpesaTransactionIncludes = [];
export const useMpesaTransaction = (id: string, includes: IncludesT = defaultMpesaTransactionIncludes) =>
  useModelRequest<MpesaTransactionType>(MpesaTransaction, id, includes);

export const useMpesaTransactionRegistrationFee = (
  id: string,
  includes: IncludesT = defaultMpesaTransactionIncludes
) => {
  return useModelRequest<MpesaTransactionType>(MpesaTransactionRegistrationFee, id, includes);
};

const defaultSmsPaymentIncludes = [];
export const useSmsPayment = (id: string, includes: IncludesT = defaultSmsPaymentIncludes) =>
  useModelRequest<SmsPaymentT>(SmsPayment, id, includes);

const defaultMemberLoansIncludes = [
  'product',
  'transactions',
  'remittance.collecting_bank',
  'remittance.bank_account.bank_branch.bank',
  'guarantees.member'
];

async function fetchMemberLoans({ queryKey }) {
  const [_, memberId, includes] = queryKey;
  const res = await Loan.includes(includes)
    .where({ member_id: memberId })
    .all();
  return res.data;
}

export const useMemberLoans = (memberId: string, includes: IncludesT = defaultMemberLoansIncludes) =>
  useQuery(['member_loans', memberId, includes], fetchMemberLoans);

const defaultMemberSavingsIncludes = [
  'latest_pending_authorization_hold',
  'product',
  'transactions',
  'remittance.collecting_bank',
  'remittance.bank_account.bank_branch.bank',
  'latest_pending_authorization_hold'
];

async function fetchMemberSavings({ queryKey }) {
  const [_, memberId, includes] = queryKey;
  const res = await Saving.includes(includes)
    .where({ member_id: memberId })
    .all();
  return res.data;
}

export const useMemberSavings = (memberId: string, includes: IncludesT = defaultMemberSavingsIncludes) =>
  useQuery(['member_savings', memberId, includes], fetchMemberSavings, { enabled: !!memberId });

async function fetchSmsPlan() {
  const res = await SmsPlan.find();
  return res.data;
}

export const useSmsPlan = () => useQuery(['sms_plan'], fetchSmsPlan);

async function fetchLoanClassifications({ queryKey }) {
  const [_, parentId] = queryKey;
  const where = parentId ? { parent_id: parentId } : null;
  const res = await LoanClassification.where(where).all();
  return res.data;
}

export const useLoanClassifications = (parentId: ?string) =>
  useQuery(['loan_classifications', parentId], fetchLoanClassifications);

const defaultLoanProductsIncludes = [];
export const useLoanProducts = (includes: IncludesT = defaultLoanProductsIncludes, per = 1000, page = 1) => {
  // TO DO: Ideally we would not need to specify the result per page to 1000.
  // Because of the way Mambu is set up we have to filter products by branch availability because
  // Mambu returns all loan products for a mambu instance (we expect it to return by branch).
  // See ch: https://app.clubhouse.io/getkwara/story/8553/product-settings-page-loan-products
  return useModelsRequest<LoanProductType[]>({ entity: LoanProduct, includes, where: null, per, page });
};

const defaultLoanProductIncludes = ['product_config'];
export const useLoanProduct = (id: string, includes: IncludesT = defaultLoanProductIncludes) =>
  useModelRequest<LoanProductType>(LoanProduct, id, includes);

async function fetchMpesaCredentials() {
  const res = await MpesaCredentials.find();
  return res.data || new MpesaCredentials({});
}

export const useMpesaCredentials = () => useQuery(['mpesa_credentials'], fetchMpesaCredentials);

const MINUTE = 1000 * 60;
// See: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback: () => void, delay: ?number = MINUTE) {
  const savedCallback = React.useRef();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
