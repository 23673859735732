// @flow

import * as React from 'react';
import cx from 'classnames';

import groupBy from 'lodash/fp/groupBy';
import last from 'lodash/fp/last';
import first from 'lodash/fp/first';
import map from 'lodash/fp/map';
import isEmpty from 'lodash/fp/isEmpty';

import { mapIndexed } from '@kwara/lib/src/lodash';
import { sumBy } from '@kwara/lib/src/currency';
import { Currency, Text } from '@kwara/components/src/Intl';
import { type RepaymentType } from '@kwara/models/src';
import { getYear } from '@kwara/lib/src/dates';

import Collapsible from '../Collapsible';
import { TableRowMonth } from './components/TableRowMonth';

import styles from './index.module.scss';

const yearSummary = (year, items) => {
  const smallTextClasses = 'kw-text-small grey-400';

  return (
    <div className={cx(styles.yearSummary, 'flex-auto')}>
      <div className={cx(styles.year, 'kw-text-medium')}>{year}</div>
      <div className={cx(styles.principal, smallTextClasses)}>
        <div className={styles.gridText}>
          <Text id="RepaymentsTable.principal" />
          {': '}
        </div>
        <div className={styles.gridText}>
          <Currency format={'currency'} value={sumBy('principal.due', items)} />
        </div>
      </div>
      <div className={cx(styles.interest, smallTextClasses)}>
        <div className={styles.gridText}>
          <Text id="RepaymentsTable.interest" />
          {': '}
        </div>
        <div className={styles.gridText}>
          <Currency format={'currency'} value={sumBy('interest.due', items)} />
        </div>
      </div>
      <div className={cx(styles.outstanding, smallTextClasses)}>
        <div className={styles.gridText}>
          <Text id="RepaymentsTable.outstanding" />
          {': '}
        </div>
        <div className={styles.gridText}>
          <Currency format={'currency'} value={last(items) && last(items).outstanding()} />
        </div>
      </div>
      <div className={cx(styles.balance, smallTextClasses)}>
        <div className={styles.gridText}>
          <Text id="RepaymentsTable.balance" />
          {': '}
        </div>
        <div className={styles.gridText}>
          <Currency format={'currency'} value={first(items).startingBalance} />
        </div>
      </div>
    </div>
  );
};

export const ScheduleRepaymentsTable = ({ repayments }) => {
  if (repayments == null || isEmpty(repayments)) {
    return null;
  }

  const groups = groupBy((repayment: RepaymentType) => getYear(repayment.dueAt), repayments);

  return mapIndexed(
    (items, year) => (
      <Collapsible className="mt3" key={year} summary={yearSummary(year, items)}>
        <div className="mt3">
          {map(
            repayment => (
              <TableRowMonth key={repayment.id} repayment={repayment} />
            ),
            items
          )}
        </div>
      </Collapsible>
    ),
    groups
  );
};
