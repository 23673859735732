import get from 'lodash/fp/get';
import { decorate, observable } from 'mobx';

import { MemberType } from '@kwara/models/src/models/Member';
import { Store as BaseStore } from '@kwara/models/src/lib/Store';
import { ConnectUserT } from '@kwara/models/src/models/ConnectUser';
import { GoogleTagManager } from '../lib/GoogleTagManager';
import { isNonProdEnv, isProd } from '@kwara/lib/src/utils';

export type Branch = {
  name: string;
  phoneNumber: String;
  url: string;
  email: string;
};

export class Store extends BaseStore {
  member: MemberType | object = {};
  profile: ConnectUserT | object = {};

  updateMember(m: MemberType) {
    this.member = m;
  }

  setProfile(profile: ConnectUserT) {
    this.profile = profile;
  }

  get organisation() {
    return get('organisation', this.profile);
  }

  get memberId() {
    return 'me';
  }

  get memberEmail() {
    return get('email', this.member);
  }

  get joiningFeeAmount() {
    return get('joiningFee', this.organisation);
  }

  get businessNumber() {
    return get('businessNumber', this.organisation);
  }

  get paymentInstructions() {
    return get('joiningFeeType', this.organisation);
  }

  get joiningFeeAccountPattern() {
    return get('organisation.joiningFeeAccount', this) || this.defaultJoiningFeeAccountPattern;
  }

  get attachments() {
    return get('attachments', this.member);
  }

  get googleTagManager() {
    return GoogleTagManager;
  }

  get currency() {
    const curr = get('organisation.currency', this);

    return curr || 'KES';
  }

  get termsAndConditions() {
    return get('termsAndConditions', this.organisation);
  }

  get branch() {
    return get('branch', this.member);
  }

  get branchName() {
    return get('branch.name', this.member);
  }

  get organisationPermanlink() {
    return get('permalink', this.organisation);
  }

  get organisationName() {
    return get('name', this.organisation);
  }

  get refinanceEnabled() {
    return false;
  }

  // @TODO handle this with backend toggle
  // BRANCHES
  get isKBS() {
    const orgId = get('organisation.id', this);
    const isKBSNonProd = isNonProdEnv && orgId === '6';
    const isKBSProd = isProd && orgId === '8';

    return isKBSNonProd || isKBSProd;
  }

  get isDynastySacco() {
    return get('member.branch.id', this) === 'Dynasty_Sacco';
  }

  get isE2E() {
    return get('member.branch.id', this) === 'e2e_branch_kwarakenya';
  }

  get isKwaraKenya() {
    return get('member.branch.id', this) === 'arena_sacco';
  }

  get isKwaraKenyaOrE2E() {
    return this.isKwaraKenya || this.isE2E;
  }

  get isAAKSacco() {
    return get('member.branch.id', this) === 'AAK_SACCO_MAIN';
  }

  get isUbunifu() {
    return get('member.branch.id', this) === 'UBS';
  }

  get isMakBiashara() {
    return get('member.branch.id', this) === 'kwara42_main';
  }

  get isKaruraCommunitySacco() {
    return get('member.branch.id', this) === 'Karura Community SACCO';
  }

  get isKCS() {
    return get('member.branch.id', this) === 'KCS';
  }

  get isApollosureSacco() {
    return get('member.branch.id', this) === 'kwara27_main';
  }

  get isFirstAssetSacco() {
    return get('member.branch.id', this) === 'kwara44_main';
  }

  get isMultipleSacco() {
    return get('member.branch.id', this) === 'kwara26_main';
  }

  get isMerinetSacco() {
    return get('member.branch.id', this) === 'kwara52_main';
  }

  get isOxfordSacco() {
    return get('member.branch.id', this) === 'OX001';
  }

  get isTranwaySacco() {
    return get('member.branch.id', this) === 'Tranway_main';
  }

  get isStonesToRubiesSacco() {
    return get('member.branch.id', this) === 'kwara45_main';
  }

  // @TODO handle this with backend toggle
  // SPECIFIC SHARE CAPITAL ACCOUNT ID

  get isShekinahSaccoShareCapitalAccount() {
    return get('member.shareCapitalAccountId', this) === 'SC01';
  }

  // FEATURES PER BRANCH

  // @TODO handle this with backend toggle
  // See: https://app.clubhouse.io/getkwara/story/22768/new-member-application-update-payment-step
  get isJoiningFeeRequired() {
    return false;
  }

  get isLoanAddEnabled() {
    return get('organisationSetting.loanAddEnabledOnConnect', this.organisation);
  }

  get isMpesaLoanRepaymentEnabled() {
    return get('organisationSetting.loanRepaymentEnabledOnConnect', this.organisation);
  }

  get isMpesaSavingDepositEnabled() {
    return get('organisationSetting.savingsDepositEnabledOnConnect', this.organisation);
  }

  get isSasraRegulated() {
    return !!get('organisationSetting.sasraRegulated', this.organisation);
  }
}

decorate(Store, {
  member: observable
});

export const store = new Store();
