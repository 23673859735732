import { attr, belongsTo } from 'spraypaint';

import Base, { type BaseModel } from './Base';
import { User, type UserT } from './User';

const TransactionReviewSet = Base.extend({
  attrs: {
    event: attr(),
    state: attr(),
    user: belongsTo({ type: User })
  }
});

export const SavingsTransactionReviewSet = TransactionReviewSet.extend({
  static: {
    jsonapiType: 'savings_transaction_review_set'
  }
});

export const LoanTransactionReviewSet = TransactionReviewSet.extend({
  static: {
    jsonapiType: 'loan_transaction_review_set'
  }
});

export interface TransactionReviewSetT extends BaseModel<TransactionReviewSetT> {
  event: 'approve' | 'reject';
  state: string;
  user: UserT;
}
