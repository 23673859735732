// @flow
import * as React from 'react';

import { type SubStepComponentProps } from '@kwara/components/src/Wizard/deprecated/Wizard';
import { Text, Currency } from '@kwara/components/src/Intl';
import { segmentTrack } from '@kwara/components/src/Segment';

export const DepositAmount = ({ StackChild, TextField, data }: SubStepComponentProps<>) => {
  const { saving, mpesaLimit } = data;

  React.useEffect(() => {
    segmentTrack('Deposit Amount page loaded');
  }, []);

  return (
    <StackChild>
      <TextField
        type="number"
        name="amount"
        isCurrency
        labelId="SavingDeposit.DepositAmount.amount.label"
        leftGlyph="Currency.orgCurrency"
        required
        info={
          <div className="flex flex-column">
            <div>
              <Text
                id={'SavingDeposit.DepositAmount.amount.info.balance'}
                values={{
                  balance: <Currency format="currency" value={saving.balance} />
                }}
              />
            </div>
            <div>
              <Text
                id={'SavingDeposit.DepositAmount.amount.info.maximum'}
                values={{
                  maximum: <Currency format="currency" value={mpesaLimit.maximumAmountPerTransaction} />
                }}
              />
            </div>
          </div>
        }
      />
    </StackChild>
  );
};

export const validateConfig = {
  amount: {
    isRequired: () => true,
    currency: true,
    nonZero: true,
    custom: (target, { mpesaLimit }) => {
      const { minimumAmountPerTransaction, maximumAmountPerTransaction } = mpesaLimit;

      if (Number(target) > Number(maximumAmountPerTransaction)) {
        return 'rangeOverflow';
      }

      if (Number(target) < Number(minimumAmountPerTransaction)) {
        return 'rangeUnderflow';
      }
      return null;
    }
  }
};

DepositAmount.validateConfig = validateConfig;
