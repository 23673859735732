// @flow

import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { excludedEmploymentField } from '@kwara/lib/src/modelUtils/member';

import Collapsible from '../../components/Collapsible';
import { StatisticInfo } from './StatisticInfo';
import styles from './index.module.scss';

export const EmploymentDetails = ({ member, className }) => {
  const shouldHide = excludedEmploymentField(member);
  return (
    <Collapsible className={className} summary={<Text id="MemberDetail.employment" />}>
      <section className={styles.InfoSection}>
        <StatisticInfo
          title={<Text id="AddMember.Employment.Profession.label" />}
          value={member.profession}
          hidden={shouldHide('profession')}
        />
        <StatisticInfo
          title={<Text id="AddMember.Employment.Type.label" />}
          value={member.employmentStatus ? <Text id={`Employment.Type.${member.employmentStatus}`} /> : null}
        />
        <StatisticInfo
          title={<Text id="AddMember.Employment.Employer.label" />}
          value={member.employer}
          hidden={shouldHide('employer')}
        />
        <StatisticInfo
          title={<Text id="AddMember.Employment.StaffId.label" />}
          value={member.staffId}
          hidden={shouldHide('staffId')}
        />
        <StatisticInfo
          title={<Text id="AddMember.Employment.Business.label" />}
          value={member.business}
          hidden={shouldHide('business')}
        />
        <StatisticInfo
          title={<Text id="AddMember.Employment.Income.label" />}
          value={member.incomeSource}
          hidden={shouldHide('incomeSource')}
        />
        <StatisticInfo
          title={<Text id="AddMember.Employment.EmployerEmail.label" />}
          value={member.employerEmail}
          hidden={shouldHide('employerEmail')}
        />
        <StatisticInfo
          title={<Text id="AddMember.Employment.EmployerPhoneNumber.label" />}
          value={member.employerPhoneNumber}
          hidden={shouldHide('employerPhoneNumber')}
        />
        <StatisticInfo
          title={<Text id="AddMember.Employment.MemberWorkEmail.label" />}
          value={member.workEmail}
          hidden={shouldHide('workEmail')}
        />
        <StatisticInfo title={<Text id="AddMember.Employment.GrossIncome.label" />} value={member.grossIncome} />
        <StatisticInfo title={<Text id="AddMember.Employment.NetIncome.label" />} value={member.netIncome} />
        <StatisticInfo
          title={<Text id="AddMember.Employment.OtherDeductibles.label" />}
          value={member.otherDeductibles}
        />
        <StatisticInfo
          title={<Text id="AddMember.Employment.OtherIncomeAmount.label" />}
          value={member.otherIncomeAmount}
        />
        <StatisticInfo title={<Text id="AddMember.Employment.IncomeSource.label" />} value={member.incomeSource} />
        <StatisticInfo
          title={<Text id="AddMember.Employment.DisposableIncomeAmount.label" />}
          value={member.disposableIncomeAmount}
        />

        <StatisticInfo
          title={<Text id="AddMember.Employment.tos.label" />}
          hidden={shouldHide('termsOfService')}
          value={member.termsOfService ? <Text id={`AddMember.Employment.tos.${member.termsOfService}`} /> : null}
        />
      </section>
    </Collapsible>
  );
};
