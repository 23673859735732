import React from 'react';

export function IcnStatement() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#099250" />
      <g clipPath="url(#clip0_2553_27546)">
        <path
          d="M18.4084 24.4976C18.6174 24.7206 18.8655 24.8975 19.1386 25.0182C19.4116 25.1389 19.7043 25.2011 19.9999 25.2011C20.2955 25.2011 20.5882 25.1389 20.8613 25.0182C21.1344 24.8975 21.3825 24.7206 21.5914 24.4976L23.9997 21.9288C24.1288 21.7765 24.1981 21.577 24.1931 21.3717C24.1882 21.1664 24.1094 20.971 23.9731 20.826C23.8368 20.6809 23.6535 20.5973 23.461 20.5925C23.2686 20.5876 23.0817 20.6619 22.9392 20.8L20.7447 23.1416L20.7499 10.8C20.7499 10.5878 20.6709 10.3843 20.5303 10.2343C20.3896 10.0843 20.1988 10 19.9999 10V10C19.801 10 19.6102 10.0843 19.4696 10.2343C19.3289 10.3843 19.2499 10.5878 19.2499 10.8L19.2432 23.1264L17.0607 20.8C16.9199 20.65 16.7291 20.5658 16.5302 20.5658C16.3312 20.5659 16.1404 20.6503 15.9998 20.8004C15.8592 20.9505 15.7802 21.1541 15.7803 21.3663C15.7803 21.5785 15.8594 21.782 16.0002 21.932L18.4084 24.4976Z"
          fill="white"
        />
        <path
          d="M28.25 22.7998C28.0511 22.7998 27.8603 22.8841 27.7197 23.0341C27.579 23.1841 27.5 23.3876 27.5 23.5998V26.7998C27.5 27.012 27.421 27.2155 27.2803 27.3655C27.1397 27.5155 26.9489 27.5998 26.75 27.5998H13.25C13.0511 27.5998 12.8603 27.5155 12.7197 27.3655C12.579 27.2155 12.5 27.012 12.5 26.7998V23.5998C12.5 23.3876 12.421 23.1841 12.2803 23.0341C12.1397 22.8841 11.9489 22.7998 11.75 22.7998C11.5511 22.7998 11.3603 22.8841 11.2197 23.0341C11.079 23.1841 11 23.3876 11 23.5998V26.7998C11 27.4363 11.2371 28.0468 11.659 28.4969C12.081 28.947 12.6533 29.1998 13.25 29.1998H26.75C27.3467 29.1998 27.919 28.947 28.341 28.4969C28.7629 28.0468 29 27.4363 29 26.7998V23.5998C29 23.3876 28.921 23.1841 28.7803 23.0341C28.6397 22.8841 28.4489 22.7998 28.25 22.7998Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2553_27546">
          <rect width="18" height="19.2" fill="white" transform="translate(11 10)" />
        </clipPath>
      </defs>
    </svg>
  );
}
