// @flow

import { MpesaContact } from '../../components/Wizard/components/MpesaContact';
import { RepaymentAmount } from './components/RepaymentAmount';

export const steps = {
  amount: {
    titleId: 'LoanRepayment.Step.amount',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'contact'
      }
    ],
    children: [
      {
        titleId: 'LoanRepayment.RepaymentAmount.title',
        Component: RepaymentAmount,
        validate: RepaymentAmount.validateConfig
      }
    ]
  },
  contact: {
    titleId: 'LoanRepayment.Step.contact',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'MpesaContact.title',
        subtitleId: 'MpesaContact.subtitle',
        Component: MpesaContact,
        validate: MpesaContact.validateConfig
      }
    ]
  }
};
