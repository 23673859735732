// @flow

import * as React from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import posed from 'react-pose';

import Asset from '@kwara/components/src/Asset';
import Empty from '@kwara/components/src/Empty';

import { store } from '../../models/Store';

const Container = posed.div({
  opened: { height: 'auto' },
  closed: { height: 0 }
});

type Props = {
  onClose: () => any,
  open: boolean
};

const ContactModal = ({ onClose, open = false }: Props) => {
  if (isEmpty(store.branch)) {
    return null;
  }

  const pose = open ? 'opened' : 'closed';
  const { name, email, phoneNumber } = store.branch;

  return (
    <Container className="overflow-hidden fixed bottom-0 left-0 right-0 bg-white bt b--light-grey-400" pose={pose}>
      <div className="flex ma3">
        <div className="ml-auto pointer" onClick={onClose} aria-label="Close">
          <Asset id={Asset.Glyphs.Cross} col={Asset.Colours.grey300} />
        </div>
      </div>
      <div className="ma2 pv2">
        <Empty>
          <div className="mb3 flex flex-column content-between-l kw-text-medium">
            <h3>{name}</h3>
            <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer" style={{ color: 'grey' }}>
              {email}
            </a>
            <a href={`tel:${phoneNumber}`} style={{ color: 'grey' }}>
              {phoneNumber}
            </a>
          </div>
        </Empty>
      </div>
    </Container>
  );
};

export default ContactModal;
