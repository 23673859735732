import React from 'react';
import getOr from 'lodash/fp/getOr';
import get from 'lodash/fp/get';
import invoke from 'lodash/fp/invoke';

import { appName } from '@kwara/lib/src/utils';
import { ERRORS as err } from '@kwara/lib/src/validator';
import { Text, Currency, getTranslationIdByApp } from '@kwara/components/src/Intl';

export function getMinMaxLoanProductAmount(data) {
  const { maximum: principalMax, minimum: principalMin } = getOr({}, 'product.amount', data);

  return { principalMax, principalMin };
}

// *** Principal Loan amount field ***
//  i.e. 50,000 KES
// !! This component is used in other pages too:
// !! LoanDetail, LoanRefinance, LoanReschedule, LoanSchedulePreview
export const PrincipalAmountField = ({ data, TextField }) => {
  const { product } = data;
  const eligibleAmount = get('eligibility.eligibleAmount', product);
  const fullName = invoke('member.fullName', data);
  const { principalMax, principalMin } = getMinMaxLoanProductAmount(data);
  const instantLoan = get('instantLoan', product);

  const principalHintConnect = (
    <>
      {principalMax && (
        <li>
          <Text
            id={'LoanAdd.Connect.Configure.PrincipalAmount.maximumAmount'}
            values={{ principalMax: <Currency value={principalMax} /> }}
          />
        </li>
      )}

      {principalMin && (
        <li>
          <Text
            id={'LoanAdd.Connect.Configure.PrincipalAmount.minimumAmount'}
            values={{
              principalMin: <Currency value={principalMin} />
            }}
          />
        </li>
      )}
      {instantLoan && (
        <li>
          <Text
            id={'LoanAdd.Connect.Configure.EligibilityAmount'}
            values={{
              eligibleAmount: <Currency value={eligibleAmount || 0} />
            }}
          />
        </li>
      )}
    </>
  );

  const principalHintSacco = principalMax && (
    <>
      <li>
        <Text
          id={'LoanAdd.Configure.PrincipalAmount.maximumAmount'}
          values={{
            principalMax,
            principalMin
          }}
        />
      </li>
      {eligibleAmount ? (
        <li>
          <Text
            id="LoanAdd.Configure.PrincipalAmount.eligibleAmount"
            values={{
              eligibleAmount
            }}
          />
        </li>
      ) : null}
    </>
  );

  const amountInfo = <ul>{appName.isMember ? principalHintConnect : principalHintSacco}</ul>;

  return (
    <TextField
      margin={false}
      errorBaseId="LoanAdd.Configure.PrincipalAmount"
      errorValues={{
        principalMax,
        principalMin,
        fullName,
        eligibleAmount
      }}
      name="amount"
      required={true}
      leftGlyph="Currency.orgCurrency"
      labelId={getTranslationIdByApp('LoanAdd.Configure.PrincipalAmount.title')}
      info={amountInfo}
      isCurrency
      type="number"
      inputMode="decimal"
      pattern="\\d*"
      sensitiveValidation
    />
  );
};

PrincipalAmountField.validate = {
  amount: {
    isRequired: () => true,
    currency: true,
    positiveInteger: true,
    nonZero: true,
    custom: (target, allData) => {
      const { principalMax, principalMin } = getMinMaxLoanProductAmount(allData);

      const num = Number(target);

      // Disabled until we sort out eligibleAmount
      // if (num > Number(get('member.eligibleAmount', allData))) {
      //   return 'insufficientFunds';
      // }

      if (principalMin != null && num < principalMin) {
        return err.rangeUnderflow;
      }

      if (principalMax != null && num > principalMax) {
        return err.rangeOverflow;
      }

      return null;
    }
  }
};
