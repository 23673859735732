import * as React from 'react';

import Empty from '@kwara/components/src/Empty';
import { Text } from '@kwara/components/src/Intl';

export const PermissionDenied = ({ message }: { message?: string }) => (
  <div className="flex flex-column h-100 justify-center" aria-label="Permission Denied">
    <Empty>
      <div>
        <h2 className="grey-500">
          <Text id="PermissionDenied.title" />
        </h2>
        <p>
          <Text id="PermissionDenied.page.message" />
        </p>
        {message ? <p className="red-600">{message}</p> : null}
      </div>
    </Empty>
  </div>
);
