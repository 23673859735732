// @flow

import React from 'react';

import { Swipeable } from '../Swipeable';
import { MemberOverviewCard } from '../MemberOverviewCard';

type Props = {
  totalSavings?: number,
  totalLoans?: number,
  shareCapitalAmount?: number
};

export function MemberOverviewCarousel({ totalSavings, totalLoans, shareCapitalAmount }: Props) {
  const carouselData = [
    { titleId: 'MemberOverview.mySavings', quantity: totalSavings, segment: 'savings' },
    { titleId: 'MemberOverview.myLoans', quantity: totalLoans, segment: 'loans' },
    {
      titleId: 'MemberOverview.shareCapital',
      quantity: shareCapitalAmount,
      isShareCapitalCard: true,
      segment: 'share capital'
    }
  ];

  return (
    <Swipeable>
      {carouselData.map((element, i) => (
        <MemberOverviewCard
          key={i.toString()}
          titleId={element.titleId}
          quantity={element.quantity}
          isShareCapitalCard={element.isShareCapitalCard}
          segment={element.segment}
        />
      ))}
    </Swipeable>
  );
}
