//@flow

import * as React from 'react';
import get from 'lodash/fp/get';
import classnames from 'classnames';

import { Currency } from '@kwara/components/src/Intl';
import StatusTag from '@kwara/components/src/StatusTag';
import { useStore } from '../../models/Member';
import Collapsible from '../Collapsible';
import { OverviewLabel } from '../OverviewLabel';

import styles from './index.module.scss';

const AmountDisplayTypes = {
  credit: 'credit',
  debit: 'debit',
  neutral: 'neutral'
};

type AmountDisplayType = $Values<typeof AmountDisplayTypes>;

type Props = {
  children: React.Node,
  id: string,
  name: string,
  status: string,
  value: number,
  type: AmountDisplayType
};

const coloursForType: { [type: AmountDisplayType]: string } = {
  credit: 'teal-600',
  debit: 'red-600',
  neutral: 'grey-600'
};

const Account = ({ children, id, name, status, type = 'neutral', value }: Props) => {
  const store = useStore();
  const shareCapitalAccountId = get('member.shareCapitalAccountId', store);
  const isShareCapitalAccount = shareCapitalAccountId === id;
  const shouldCollapse = React.Children.count(children) > 0;

  const account = (
    <div className={classnames(styles.Account, 'flex-auto')}>
      <div className={classnames(styles.info, 'kw-text-small w-100')}>
        <div className="kw-weight-bold grey-500">{name}</div>
        <div className="flex pv1">
          <StatusTag state={status} size="small" />
          {isShareCapitalAccount ? (
            <div className="pl2">
              <OverviewLabel labelId="MemberOverview.notWithdrawable" />
            </div>
          ) : null}
        </div>
      </div>

      <div className={classnames(styles.amount, 'kw-text-large kw-weight-light no-break', coloursForType[type])}>
        <Currency value={value} />
      </div>
    </div>
  );

  return shouldCollapse ? (
    <div className="mb4">
      <Collapsible summary={account}>{children}</Collapsible>
    </div>
  ) : (
    <div className="mb4">{account}</div>
  );
};

Account.DisplayTypes = AmountDisplayTypes;

export default Account;
