import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { throwError } from '@kwara/lib/src/utils/throwError';

type HttpServiceArg = {
  baseURL: string;
  getToken?(): string;
  authRequest?: boolean;
};

export class HttpService {
  __httpService__: AxiosInstance;

  constructor({ baseURL, getToken, authRequest = true }: HttpServiceArg) {
    this.__httpService__ = axios.create({ baseURL, headers: { 'Content-Type': 'application/json' } });

    if (authRequest && getToken != undefined) this.appendAuthorizationToEveryRequest(getToken);
  }

  private static validateToken(token: string) {
    if (!token) throwError('MissingTokenError', 'Invalid token.', HttpService.validateToken);
  }

  private appendAuthorizationToEveryRequest(getToken: () => string) {
    this.__httpService__.interceptors.request.use(
      async function(config) {
        /**
         * Lets prevent any out-going private api
         * request if token is invalid
         */
        HttpService.validateToken(getToken());

        config.headers.Authorization = `Bearer ${getToken()}`;

        return config;
      },
      function(error) {
        /**
         * We safely catch the error here so it doesn't
         * break the application and then send it back
         * as a rejected Promise for the service consumer
         */
        return Promise.reject(error);
      }
    );
  }

  public httpGetRequest<ResponseType = Record<string, any>, C = any>(url: string, config?: AxiosRequestConfig<C>) {
    return this.__httpService__.get<ResponseType>(url, config);
  }

  public httpPostRequest<D = Record<string, string>, ResponseType = any, C = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<C extends D ? any : any>
  ) {
    return this.__httpService__.post<ResponseType>(url, data, config);
  }

  public httpPutRequest<D = Record<string, string>, ResponseType = any, C = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<C extends D ? any : any>
  ) {
    return this.__httpService__.put<ResponseType>(url, data, config);
  }

  public httpPatchRequest<D = Record<string, string>, ResponseType = any, C = any>(
    url: string,
    data: D,
    config?: AxiosRequestConfig<C extends D ? any : any>
  ) {
    return this.__httpService__.patch<ResponseType>(url, data, config);
  }

  public httpDeleteRequest<ResponseType = any, C = any>(url: string, config?: AxiosRequestConfig<C>) {
    return this.__httpService__.delete<ResponseType>(url, config);
  }
}
