import * as React from 'react';
import cx from 'classnames';

import { Text } from '@kwara/components/src/Intl';

type PrimitiveDivProps = React.ComponentPropsWithoutRef<'div'>;
type ErrorTextElement = React.ElementRef<'div'>;
interface ErrorTextPropTypes extends Omit<PrimitiveDivProps, 'role'> {
  role?: React.AriaRole;
}

export const ErrorText = React.forwardRef<ErrorTextElement, ErrorTextPropTypes>(function ErrorText(
  { className, role = 'alert', ...restProps },
  forwardedRef
) {
  return (
    <div
      {...restProps}
      ref={forwardedRef}
      role={role}
      aria-atomic="true"
      aria-live="assertive"
      className={cx('red-500', className)}
    />
  );
});

interface ErrorTextWithI18nPropTypes extends Omit<ErrorTextPropTypes, 'id'> {
  id: string;
  values?: Record<string, string | number | null | undefined | React.ReactElement>;
}

export const ErrorTextWithI18n = React.forwardRef<ErrorTextElement, ErrorTextWithI18nPropTypes>(
  function ErrorTextWithI18n({ id, values, ...restProps }, forwardedRef) {
    return (
      <ErrorText ref={forwardedRef} {...restProps}>
        <Text id={id} values={values} />
      </ErrorText>
    );
  }
);
