import React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { fields } from '@kwara/models/src/models/Loan';
import { Record, Section } from '@kwara/components/src/Summary';
import { SubscribedPaymentSelectField } from '@kwara/components/src/Form';
import { SubStepComponentProps } from '@kwara/components/src/Wizard/deprecated/Wizard';
import { getTransactionTypes, contexts, TransactionChannels } from '@kwara/models/src/models/Transactions';

const VALID_MPESA_METHODS: Array<string> = [TransactionChannels.mpesa, TransactionChannels.mobileMpesaInstant];

export const Disbursement = ({ StackChild, Condition, formProps, data }: SubStepComponentProps) => {
  return (
    <StackChild>
      <div className="flex mt3">
        <div className="flex-auto">
          <SubscribedPaymentSelectField
            name={fields.disbursement.mode}
            labelId="LoanAdd.Disbursement.Method.label"
            config={{ methods: getTransactionTypes(contexts.InstantLoanDisbursement).values }}
            required
          />
          <Condition
            when={fields.disbursement.mode}
            evalCondition={(method: string) => VALID_MPESA_METHODS.includes(method)}
          >
            <Section titleId="LoanAdd.Summary.Disbursement.title">
              <Record
                labelId="LoanAdd.Summary.Disbursement.method"
                value={<Text id={`TransactionMethod.${formProps.values[fields.disbursement.mode]}`} />}
              />
              <Record labelId="LoanAdd.Summary.Disbursement.phone" value={data.member.phoneNumber} />
            </Section>
            <div className="grey-500-design-system kw-text-x-small mt2">
              <Text id="InstantLoan.Disbursement.mpesa.label" />
            </div>
          </Condition>
        </div>
      </div>
    </StackChild>
  );
};

Disbursement.validate = {
  [fields.disbursement.mode]: {
    isRequired: () => true
  }
};
