import get from 'lodash/fp/get';

import { LoanFormData } from 'LoanSharedTypes';

import { UIDurationObjectT } from '@kwara/models/src/models/util';

type ReturnType = {
  maxDurationUnit: UIDurationObjectT;
  maxDurationValue: UIDurationObjectT;
  fixedRepayment: boolean;
};

export function getRepaymentTerms(data: LoanFormData): ReturnType {
  const product = get('product', data);

  if (!product) return {} as ReturnType;

  const { unit: maxDurationUnit, value: maxDurationValue } = product.maxDurationUI();
  const fixedRepayment = product.hasFixedRepaymentFrequency();
  const repaymentData = { maxDurationUnit, maxDurationValue, fixedRepayment };

  return repaymentData;
}
