import { attr, belongsTo, hasMany } from 'spraypaint';

import { ValueOf, InferredModel } from 'GlobalTypes';
import { ModelErrors } from '@kwara/models/src';

import Base from './Base';

import { UserT } from './User';
import { LoanV2, LoanV2Type } from './LoanV2';
import { LoanApplicationT } from './LoanApplication';
import { ApiRepaymentPeriodT, LoanState } from './Loan';
import createModelErrors, { createErrorsFromApiResponse } from './createModelErrors';
import { LoanDisbursementAppraisals, LoanDisbursementAppraisalsType } from './LoanDisbursementAppraisals';

export const DisbursementStates = Object.freeze({
  PENDING_INITIAL_APPROVALS: 'pending_initial_approvals',
  PENDING_MAKER_CHECKER_APPROVAL: 'pending_maker_checker_approval',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  IMPORTING: 'importing',
  IMPORTED: 'imported',
  FAILED: 'failed'
});

export const OnlinePayoutStatus = Object.freeze({
  NOT_APPLICABLE: 'not_applicable',
  NOT_STARTED: 'not_started',
  PROCESSING: 'processing',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed'
});

export type DisbursementStatesType = ValueOf<typeof DisbursementStates>;

export const PendingLoanDisbursement = Base.extend({
  static: {
    jsonapiType: 'loan_disbursements',
    endpoint: '/pending_loan_disbursements'
  },
  attrs: {
    notes: attr(),
    state: attr(),
    loanId: attr(),
    amount: attr(),
    drawer: attr(),
    bankName: attr(),
    memberId: attr(),
    bankGlId: attr(),
    accountId: attr(),
    reference: attr(),
    valueDate: attr(),
    createdBy: attr(),
    memberName: attr(),
    bankBranch: attr(),
    reviewedBy: attr(),
    chequeNumber: attr(),
    paymentMethod: attr(),
    glAccountCode: attr(),
    onlinePayoutStatus: attr(),
    user: belongsTo(),
    loan: belongsTo({ type: LoanV2 }),
    loanApplication: belongsTo(),
    appraisals: hasMany({ type: LoanDisbursementAppraisals })
  },
  methods: {
    async transition(params: {
      event: 'approve' | 'reject';
      raw_loan_disbursement_ids: string[];
      notes?: string;
      onSuccess: () => void;
      onError: (errors: ModelErrors) => void;
    }) {
      const { onSuccess, onError, ...restParams } = params;
      const options = {
        ...PendingLoanDisbursement.fetchOptions(),
        method: 'PUT',
        body: JSON.stringify({ data: { attributes: restParams } })
      };

      try {
        const response = await window.fetch(PendingLoanDisbursement.url(), options);

        if (!response.ok) {
          const body = await response.json();
          this.errors = createErrorsFromApiResponse(body);

          onError(this.errors);

          return;
        }

        onSuccess();
      } catch (errors) {
        this.errors = createModelErrors({
          base: 'APP_NETWORK_ERROR'
        });

        onError(this.errors);
      }
    }
  }
});

export interface LoanType extends LoanV2Type {
  name: string;
  amount: number;
  duration: string;
  disbursementDate: string | null;
  totalDue: string | number;
  totalPaid: string | number;
  repaymentFrequency: string;
  firstRepaymentDate: string | null;
  totalBalance: string | number;
  futurePaymentsAcceptance: string;
  repaymentPeriod: ApiRepaymentPeriodT;
  interestRate: {
    percentage: number;
    chargeFrequency: string;
    calculationMethod: string;
  };
  state: {
    current: LoanState;
    permittedEvents: { name: string }[];
  };
}

export interface LoanDisbursementType extends InferredModel<LoanDisbursementType> {
  loanId: string;
  notes: string;
  amount: number;
  drawer: string | null;
  memberId: string;
  createdBy: string;
  reference: string;
  bankGlId: string | null;
  valueDate: string | null;
  accountId: string | null;
  memberName: string;
  bankName: string | null;
  bankBranch: string | null;
  reviewedBy: string | null;
  paymentMethod: string;
  glAccountCode: string | null;
  loan?: LoanType;
  appraisals: LoanDisbursementAppraisalsType[];
  onlinePayoutStatus: ValueOf<typeof OnlinePayoutStatus>;
  state: DisbursementStatesType;
  loanApplication: LoanApplicationT;
  user: UserT;
}
