// @flow

import * as React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';
import { useMemberSavings } from '@kwara/models/src/models/request/hooks';

import { useStore } from '../../models/Member';
import { SavingsList } from './SavingsList';
import { Transactions } from './Transactions';
import { CardLoader } from '../../components/CardLoader';

const savingsIncludes = ['product', 'transactions'];

export const Savings = () => {
  const store = useStore();
  const response = useMemberSavings(store.memberId, savingsIncludes);

  return (
    <Loadable {...response} loading={<CardLoader />}>
      {savings => (
        <>
          <SavingsList accounts={savings} />
          <Transactions accounts={savings} />
        </>
      )}
    </Loadable>
  );
};
