import * as React from 'react';
import get from 'lodash/fp/get';

import { Type } from '@kwara/components/src/Completion/CompletionBase';
import { Completion, Types as CompletionTypes } from '@kwara/components/src/Completion';
import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import { useNewUserStore } from '../../models/NewUserStore';
import styles from './index.module.scss';

type Props = {
  type?: Type;
  show: boolean;
  titleId: string;
  subtitleId: string;
  subtitleValues?: Record<string, string>;
  buttonId: string;
  cancelButtonId?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

const Modal = ({ onConfirm, type, titleId, subtitleId, subtitleValues, buttonId, cancelButtonId, onCancel }) => {
  const { storeOrganisation } = useNewUserStore();
  const values = subtitleValues ? subtitleValues : { saccoName: get('name', storeOrganisation) };
  return (
    <div className={styles.Container}>
      <div className={styles.ModalWrapper}>
        <Completion
          onConfirm={onConfirm}
          subtitle={<Text id={subtitleId} values={values} />}
          type={CompletionTypes[type]}
          buttonTextId={buttonId}
          titleId={titleId}
          children={
            cancelButtonId && (
              <Button className="bn underline white mv2" type="secondary" onClick={() => onCancel()}>
                <Text id={cancelButtonId} />
              </Button>
            )
          }
        />
      </div>
    </div>
  );
};

export const StatusApprovalModal = ({
  type = 'good',
  show,
  titleId,
  subtitleId,
  subtitleValues,
  buttonId,
  onConfirm,
  cancelButtonId,
  onCancel
}: Props) =>
  show && (
    <Modal
      onConfirm={onConfirm}
      onCancel={onCancel}
      type={type}
      titleId={titleId}
      subtitleId={subtitleId}
      subtitleValues={subtitleValues}
      buttonId={buttonId}
      cancelButtonId={cancelButtonId}
    />
  );
