// @flow

import { attr } from 'spraypaint';

import Base, { type BaseModel } from './Base';

export const RepaymentAmountBase = Base.extend({
  static: {
    jsonapiType: 'calculated_loan_repayment_amount'
  },
  attrs: {
    message: attr(),
    repaymentAmount: attr()
  }
});

export const RepaymentAmount = (productId: string) =>
  Base.extend({
    static: {
      endpoint: `/loans/calculations/repayment_amount/${productId}`
    }
  });

export interface RepaymentAmountT extends BaseModel<RepaymentAmountT> {
  message: string;
  repaymentAmount: string;
}
