import axios from 'axios';

import { attr } from 'spraypaint';

import { InferredModel, ApiError } from 'GlobalTypes';

import Base from '../Base';

export type Handlers = {
  onStart?(): void;
  onSuccess?(data: PhoneNumberInfoType): void;
  onError?(error: ApiError): void;
};

export interface PhoneNumberInfoType extends InferredModel<PhoneNumberInfoType> {
  phoneNumber: string;
  carrier: string;
}

type ResponseType = { data: { data: { attributes: PhoneNumberInfoType } } };

export const PhoneNumberInfo = Base.extend({
  static: {
    jsonapiType: 'mobile_phone_info',
    endpoint: '/misc/phone_number_info',

    async getPhoneNumberInfo(phoneNumber: string, handlers?: Handlers): Promise<PhoneNumberInfoType> {
      handlers?.onStart?.();
      try {
        const response = await axios.get<never, ResponseType>(
          `${PhoneNumberInfo.url()}?phone_number=${encodeURIComponent(phoneNumber)}`,
          PhoneNumberInfo.fetchOptions()
        );
        handlers?.onSuccess?.(response.data.data.attributes);
        return response.data.data.attributes;
      } catch (errors) {
        handlers?.onError?.(errors);
        return errors;
      }
    }
  },
  attrs: {
    phoneNumber: attr(),
    carrier: attr()
  }
});
