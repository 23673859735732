import en from '../translations/en.json';

import { type TranslationId } from '.';
type Translations = { [id: TranslationId]: string };

export const flattenTranslations = (translations: Translations): Translations => {
  const flattened = {};

  Object.entries(translations).forEach(([parent, children]) => {
    Object.entries(children).forEach(
      ([translationKey, translation]) => (flattened[`${parent}.${translationKey}`] = translation)
    );
  });

  return flattened;
};

export const prepareTranslations = (...translationObjects: Translations[]): Translations => {
  let prepared = flattenTranslations(en);

  translationObjects.forEach(translations => {
    prepared = {
      ...prepared,
      ...flattenTranslations(translations)
    };
  });

  return prepared;
};
