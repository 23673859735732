import * as React from 'react';
import cx from 'classnames';

import styles from './index.module.scss';

type PrimitiveDivPropTypes = React.ComponentPropsWithoutRef<'div'>;
type CardWrapperElement = React.ElementRef<'div'>;

export const CardWrapper = React.forwardRef<CardWrapperElement, PrimitiveDivPropTypes>(function CardWrapper(
  { className, ...restProps },
  forwardedRef
) {
  return <div {...restProps} ref={forwardedRef} className={cx(styles['container'], className)} />;
});
