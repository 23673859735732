// @flow

import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';

import Collapsible from '../../components/Collapsible';
import { StatisticInfo } from './StatisticInfo';
import styles from './index.module.scss';

export const Bank = ({ member, className }) => (
  <Collapsible className={className} summary={<Text id="MemberDetail.bank" />}>
    <section className={styles.InfoSection}>
      <StatisticInfo title={<Text id="AddMember.Bank.name.label" />} value={member.memberBankName} />
      <StatisticInfo title={<Text id="AddMember.Bank.branch.label" />} value={member.memberBankBranch} />
      <StatisticInfo title={<Text id="AddMember.Bank.account.label" />} value={member.memberBankAccount} />
    </section>
  </Collapsible>
);
