import React from 'react';

import { appName } from '@kwara/lib/src/utils';
import { AttachmentMeta } from '@kwara/models/src/models/Attachment';

import { MemberUploadWidgetBase } from './components/MemberUploadWidgetBase';
import defaultStyle from './index.module.scss';

export { accept } from '@kwara/components/src/UploadWidgetUI/utils';
export { convert, addAttachment, allowedAttachments, removeAttachment, isDirty, findProfilePicture } from './utils';
export { WithViewer } from './components/Viewer';

export type FileNameT = AttachmentMeta['name'] | 'profile';

export const MemberUploadWidget = props =>
  appName.isMember ? <UploadWidgetMobile {...props} /> : <UploadWidgetCore {...props} />;

const UploadWidgetCore = props => {
  const { size, customStyle } = props;
  return <MemberUploadWidgetBase {...props} style={customStyle || defaultStyle} className={`kw-text-${size}`} />;
};

const UploadWidgetMobile = props => {
  const { customStyle } = props;
  return (
    <MemberUploadWidgetBase
      {...props}
      size={'small'}
      style={customStyle || defaultStyle}
      className="mobile-text-small"
    />
  );
};
