import map from 'lodash/fp/map';
import toLower from 'lodash/fp/toLower';
import pipe from 'lodash/fp/pipe';
import join from 'lodash/fp/join';
import split from 'lodash/fp/split';
import last from 'lodash/fp/last';

import { allowedExtensions } from '@kwara/models/src/models/Attachment';

// Accept files for only ".png,.jpg,etc"
export const accept = pipe(
  map((ext: string) => `.${toLower(ext)}`),
  join(',')
)(allowedExtensions);

/**
 * @trimScheme
 *
 * Trims the data URI scheme from the base64 encoded image
 * in: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAAC=="
 * out: "iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAAC=="
 *
 */
export const trimScheme = (fileName: string) => pipe(split('base64,'), last)(fileName);

export function toBase64(file: File) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(trimScheme(reader.result as string));
    reader.onerror = reject;
  });
}

export function toBase64Img(base64: string, type: string) {
  return `data:image/${toLower(type)};base64,${base64}`;
}
