import { attr } from 'spraypaint';
import get from 'lodash/fp/get';

import Base, { BaseModel } from '../Base';

import { KwaraPermissionsConfig } from './config';

export { KwaraPermissionsConfig, groupPermissions, groups, sortByVerb } from './config';

export const AppPermission = Base.extend({
  static: {
    jsonapiType: 'app_permissions',
    endpoint: '/auth/app_permissions',
    apiNamespace: ''
  },
  attrs: {
    name: attr()
  },
  methods: {
    verb() {
      return get([this.name, 'verb'], KwaraPermissionsConfig);
    }
  }
});

export interface AppPermissionT extends BaseModel<AppPermissionT> {
  name: string;
  verb: () => string;
}
