import { useRef } from 'react';
import uniqid from 'uniqid';

import { throwError } from '@kwara/lib/src/utils/throwError';

export function useUniqueIds(count: number) {
  if (count < 1) {
    throwError('useUniqueIdsError', 'To use useUniqueIds, you need to pass in a number not less than 1', useUniqueIds);
  }

  const roundedCount = Math.floor(count);
  const uniqueIds = useRef([...new Array(roundedCount)].map(() => uniqid())).current;

  return uniqueIds;
}
