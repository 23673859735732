import React from 'react';
import cx from 'classnames';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import styles from './index.module.scss';

type BinaryActionProps = {
  leftOnClick: React.MouseEventHandler<HTMLButtonElement>;
  leftLabelId: string;
  rightOnClick: React.MouseEventHandler<HTMLButtonElement>;
  rightLabelId: string;
};

export const BinaryAction = ({ leftOnClick, leftLabelId, rightOnClick, rightLabelId }: BinaryActionProps) => {
  return (
    <div className="flex justify-center w-100">
      <Button
        className={cx(styles.DeclineButton, 'mv3 mh3 flex justify-center w-100')}
        onClick={leftOnClick}
        type="secondary"
        size="regular"
      >
        <Text id={leftLabelId} />
      </Button>
      <Button className="mh2 mv3 ph1 w-100" onClick={rightOnClick} type="primary" size="regular">
        <Text id={rightLabelId} />
      </Button>
    </div>
  );
};

type MainCardProps = {
  loanId: string;
  canRepay: boolean;
  showMoreDetailsButton: boolean;
};

export const MainCardActions = ({ loanId, canRepay, showMoreDetailsButton = true }: MainCardProps) => (
  <div className="w-100 flex justify-center">
    {showMoreDetailsButton && (
      <Button
        aria-label={'view'}
        className={cx(styles.ShowDetailsButton, 'mv3 mh3 flex justify-center w-100')}
        to={`/loans/${loanId}/overview`}
        type="secondary"
      >
        <Text id="LoanCard.Action.ViewMore" />
      </Button>
    )}
    <Button
      data-testid={loanId}
      className="mh2 mv3 ph1 w-100"
      disabled={!canRepay}
      to={`/overview/${loanId}/repayment`}
      type="primary"
      size="regular"
    >
      <Text id="LoanCard.Action.Repay" />
    </Button>
  </div>
);
