import * as React from 'react';
import cx from 'classnames';

import Asset from '@kwara/components/src/Asset';

import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';
import { IconInfo } from '@kwara/components/src/Asset/IconInfo';
import { VisuallyHidden } from '@kwara/components/src/VisuallyHidden/VisuallyHidden';
import { AccessibleIcon } from '@kwara/components/src/AccessibleIcon/AccessibleIcon';

import { useAccessibleLoader } from './useAccessibleLoader';

function getLoadingTextId(hasError: boolean, loadingTextId: string) {
  if (loadingTextId) return loadingTextId;

  if (hasError) return 'Loading.error';

  return 'Loading.loading';
}

type AccessibleLoaderPropTypes<ErrorType> = {
  itsTextLoader?: boolean;
  isLoading: boolean;
  time?: number;
  icon?: React.ReactElement;
  loadingTextId?: string;
  labelId?: React.ReactNode;
  error?: ErrorType;
  infoId?: string;
  className?: string;
};

export function AccessibleLoader<ErrorType = any>(props: AccessibleLoaderPropTypes<ErrorType>) {
  const {
    isLoading,
    icon,
    error,
    loadingTextId,
    itsTextLoader,
    infoId,
    className,
    time = 1000,
    labelId = 'Accessibility.loading'
  } = props;

  const { shownLoader, isDoneLoading } = useAccessibleLoader(isLoading, time);

  return (
    <>
      <If
        condition={!!infoId && shownLoader}
        do={
          <div className="flex items-center kw-weight-bold">
            <span className="pr2">
              <IconInfo />
            </span>
            <Text id={infoId} />
          </div>
        }
      />

      <If
        condition={isDoneLoading}
        do={
          <VisuallyHidden>
            <p>
              <Text id="Accessibility.doneLoading" />
            </p>
          </VisuallyHidden>
        }
      />

      <If
        condition={shownLoader}
        do={
          <If
            condition={!!itsTextLoader}
            do={
              <div aria-busy="true" aria-live="polite" className={cx('flex justify-center items-center', className)}>
                <Text id={getLoadingTextId(!!error, loadingTextId)} />
              </div>
            }
            else={
              <AccessibleIcon label={<Text id={labelId as string} />}>
                <If
                  condition={!!icon}
                  do={icon}
                  else={
                    <div className="w-100 h-100 flex justify-center items-center">
                      <Asset id="Spinner" col="indigo500" />
                    </div>
                  }
                />
              </AccessibleIcon>
            }
          />
        }
      />
    </>
  );
}
