// @flow

import React from 'react';
import { observer } from 'mobx-react';
import pipe from 'lodash/fp/pipe';
import replace from 'lodash/fp/replace';

import { appName } from '@kwara/lib/src/utils';
import { Text } from '@kwara/components/src/Intl';
import { getTranslationIdByApp } from '@kwara/components/src/Intl';
import { NoteContainer, NoteItem, NoteTitle } from '@kwara/components/src/Note';

export function getAccountNumber(pattern: string, firstName: string, lastName: string) {
  return pipe(replace('%FIRST_NAME%', firstName), replace('%LAST_NAME%', lastName))(pattern);
}

const InstructionsTill = ({ businessNumber, amount }: { businessNumber: string, amount: string }) => {
  return (
    <NoteContainer>
      <NoteTitle titleId="AddMember.JoiningFee.instructions" />
      <NoteItem notes={<Text id="AddMember.JoiningFee.instructions.till.step1" />} />
      <NoteItem notes={<Text id="AddMember.JoiningFee.instructions.till.step2" />} />
      <NoteItem notes={<Text id="AddMember.JoiningFee.instructions.till.step3" values={{ businessNumber }} />} />
      <NoteItem notes={<Text id="AddMember.JoiningFee.instructions.till.step4" values={{ amount }} />} />
    </NoteContainer>
  );
};

const InstructionsPaybill = ({
  businessNumber,
  amount,
  accountNumber
}: {
  businessNumber: string,
  amount: string,
  accountNumber: string
}) => {
  return (
    <NoteContainer>
      <NoteTitle titleId="AddMember.JoiningFee.instructions" />
      <NoteItem notes={<Text id="AddMember.JoiningFee.instructions.paybill.step1" />} />
      <NoteItem notes={<Text id="AddMember.JoiningFee.instructions.paybill.step2" />} />
      <NoteItem notes={<Text id="AddMember.JoiningFee.instructions.paybill.step3" values={{ businessNumber }} />} />
      <NoteItem notes={<Text id="AddMember.JoiningFee.instructions.paybill.step4" values={{ accountNumber }} />} />
      <NoteItem notes={<Text id="AddMember.JoiningFee.instructions.paybill.step5" values={{ amount }} />} />
    </NoteContainer>
  );
};

export const JoiningFeePayment = observer(({ StackChild, TextField, data }) => {
  const { firstName, lastName } = data;

  const store = JoiningFeePayment.appStore;

  const { amount, businessNumber, accountNumberPattern, isPaybill, isTill } = store.joiningFeeSettings;

  const accountNumber = getAccountNumber(accountNumberPattern, firstName, lastName);

  return (
    <StackChild>
      {appName.isSacco ? (
        <>
          <h2 className="kw-text-extra-large kw-weight-bold mb0">
            <Text id="AddMember.JoiningFee.section.title" />
          </h2>
          <p className="kw-text-regular grey-400">
            <Text id="AddMember.JoiningFee.section.text" values={{ amount }} />
          </p>
        </>
      ) : null}
      {isPaybill ? (
        <InstructionsPaybill businessNumber={businessNumber} amount={amount} accountNumber={accountNumber} />
      ) : null}

      {isTill ? <InstructionsTill businessNumber={businessNumber} amount={amount} /> : null}

      <TextField
        required={store.isJoiningFeeRequired}
        name="joiningFeeReference"
        labelId={getTranslationIdByApp('AddMember.joiningFee.Reference.label')}
        placeholderId="AddMember.joiningFee.Reference.placeholder"
      />
    </StackChild>
  );
});

JoiningFeePayment.validate = (isJoiningFeeRequired = true) => ({
  joiningFeeReference: {
    isRequired: () => isJoiningFeeRequired
  }
});
