import * as React from 'react';
import { Form } from 'react-final-form';
import { useQuery } from 'react-query';
import cx from 'classnames';

import Button from '@kwara/components/src/Button';
import { Text, Currency } from '@kwara/components/src/Intl';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import Modal from '@kwara/components/src/Modal';
import { Panel } from '@kwara/components/src/Panel';
import { InfoPanel } from '@kwara/components/src/InfoPanel';
import { fetchMember } from '@kwara/models/src/models/request/hooks';
import { Loadable } from '@kwara/components/src/Loadable';
import { minus } from '@kwara/lib/src/currency';
import { segmentTrack } from '@kwara/components/src/Segment';

import { Breakdown } from './Breakdown';
import styles from './TwoThirdsModal.module.scss';
import { avgMonthlyPayment } from '../utils';

const fields = ['available_income', 'total_monthly_loan_payments'];
function useMemberIncome(id: string) {
  return useQuery(['member_income', id, null, fields], fetchMember);
}

export const TwoThirdsModal = ({ onBypass, data, onChange, addDataAndContinue, TextField, onClose }) => {
  const { member } = data;
  const avgMonthly = avgMonthlyPayment(data);
  const r = useMemberIncome(member.id);

  const onSave = React.useCallback(
    async updates => {
      member.otherIncomeAmount = updates.otherIncomeAmount;
      member.incomeSource = updates.incomeSource;
      await member.save();
      await onChange({ member });
      await addDataAndContinue({ member });
      segmentTrack('Two-thirds Rule Income Updated');
      onClose();
    },
    [member, onChange, addDataAndContinue, onClose]
  );
  const [incomeView, setIncomeView] = React.useState(false);
  const confirmView = !incomeView;

  return (
    <Modal ariaLabel="Two-thirds Modal" isOpen>
      <div className="flex w-100 h-100 items-center justify-center">
        {confirmView ? (
          <Loadable {...r}>
            {memberIncome => {
              const difference = minus(avgMonthly, memberIncome.availableIncome);

              return (
                <Panel className={styles.Panel}>
                  <div className="flex justify-between">
                    <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
                      <Text id="LoanAdd.Configure.TwoThirdsModal.Confirm.title" />
                    </h2>
                    <InfoPanel>
                      <Breakdown data={data} totalMonthlyLoanPayments={memberIncome.totalMonthlyLoanPayments} />
                    </InfoPanel>
                  </div>
                  <p className="kw-weight-light">
                    <Text
                      id="LoanAdd.Configure.TwoThirdsModal.Confirm.subtitle"
                      values={{
                        firstName: member.firstName,
                        val: <Currency format="currency" value={difference} />
                      }}
                    />
                  </p>
                  <ButtonBar
                    className="mt4"
                    left={
                      <Button onClick={() => setIncomeView(true)}>
                        <Text id="LoanAdd.Configure.TwoThirdsModal.Confirm.button.moreIncome" />
                      </Button>
                    }
                    right={
                      <Button
                        classNames="mr3"
                        type="destructive"
                        onClick={() => {
                          segmentTrack('Two-thirds Rule Bypassed');
                          onBypass();
                        }}
                      >
                        <Text id="LoanAdd.Configure.TwoThirdsModal.Confirm.button.bypass" />
                      </Button>
                    }
                  />
                </Panel>
              );
            }}
          </Loadable>
        ) : null}

        {incomeView ? (
          <Panel className={cx(styles.Panel, styles.background)}>
            <Form
              initialValues={{
                incomeSource: member.incomeSource,
                otherIncomeAmount: member.otherIncomeAmount
              }}
              onSubmit={onSave}
              render={({ form, submitting }) => {
                return (
                  <>
                    <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
                      <Text id="LoanAdd.Configure.TwoThirdsModal.Income.title" />
                    </h2>

                    <TextField
                      name="otherIncomeAmount"
                      labelId="LoanAdd.Configure.TwoThirdsModal.Income.otherIncomeAmount.label"
                      info={<Text id="LoanAdd.Configure.TwoThirdsModal.Income.otherIncomeAmount.info" />}
                    />
                    <TextField
                      name="incomeSource"
                      labelId="LoanAdd.Configure.TwoThirdsModal.Income.incomeSource.label"
                    />

                    <ButtonBar
                      className="mt4"
                      left={
                        <Button disabled={submitting} onClick={() => setIncomeView(false)}>
                          <Text id="LoanAdd.Configure.TwoThirdsModal.Income.button.back" />
                        </Button>
                      }
                      right={
                        <Button
                          disabled={submitting}
                          classNames="mr3"
                          type="primary"
                          onClick={form.submit}
                          glyphRightId={submitting ? Button.Glyphs.Spinner : null}
                        >
                          <Text id="LoanAdd.Configure.TwoThirdsModal.Income.button.save" />
                        </Button>
                      }
                    />
                  </>
                );
              }}
            />
          </Panel>
        ) : null}
      </div>
    </Modal>
  );
};
