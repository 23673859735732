// @flow
import React from 'react';

import Button from '@kwara/components/src/Button';
import Asset from '../Asset';

declare class PrintButtonT extends HTMLButtonElement {
  onClick?: () => void;
  size?: 'small' | 'regular' | 'medium';
  ariaLabel?: string;
}

export function printAction() {
  global.window.print();
}

export const PrintButton = ({
  className = '',
  disabled,
  onClick = printAction,
  size = 'medium',
  ariaLabel = 'print',
  to
}: PrintButtonT) => (
  <Button
    className={className}
    type="secondary"
    onClick={onClick}
    disabled={disabled}
    size={size}
    aria-label={ariaLabel}
    to={to}
  >
    <Asset size={size} col={disabled ? Asset.Colours.grey300 : Asset.Colours.black} id={Asset.Glyphs.Printer} />
  </Button>
);
