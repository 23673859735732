import * as React from 'react';
import cx from 'classnames';

import { LoadingSkeleton, LoadingSkeletonPropTypes } from '@kwara/components/src/Loadable/LoadingSkeleton';

import styles from './index.module.scss';

function getLoadingSkeletonProps(props?: LoadingSkeletonPropTypes): LoadingSkeletonPropTypes {
  return {
    noChildren: true,
    shape: 'radius-horizone',
    width: '90%',
    height: '12px',
    ...props
  };
}

const loadingSkeletonProps70 = getLoadingSkeletonProps({ width: '70%' });
const loadingSkeletonProps80 = getLoadingSkeletonProps({ width: '80%' });
const loadingSkeletonProps90 = getLoadingSkeletonProps();

export function CardLoader() {
  return (
    <div className={styles['container']} data-testid="cardLoader">
      <div className={cx(styles['col'], styles['col1'])}>
        <LoadingSkeleton {...loadingSkeletonProps90} />
        <LoadingSkeleton {...loadingSkeletonProps70} />
        <LoadingSkeleton {...loadingSkeletonProps80} />
        <LoadingSkeleton {...loadingSkeletonProps70} />
        <LoadingSkeleton {...loadingSkeletonProps90} />
      </div>

      <div className={cx(styles['col'], styles['col2'])}>
        <div>
          <LoadingSkeleton {...loadingSkeletonProps90} />
          <LoadingSkeleton {...loadingSkeletonProps70} />
          <LoadingSkeleton {...loadingSkeletonProps80} />
        </div>

        <div>
          <LoadingSkeleton {...loadingSkeletonProps90} />
          <LoadingSkeleton {...loadingSkeletonProps70} />
          <LoadingSkeleton {...loadingSkeletonProps80} />
        </div>
      </div>
    </div>
  );
}
