// @flow

import { attr } from 'spraypaint';
import Base, { type BaseModel } from './Base';

export const B2CTransactionStates = Object.freeze({
  PENDING: 'pending',
  PROCESSING: 'processing',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed'
});
export type B2CTransactionStatesT = $Values<typeof B2CTransactionStates>;

export const B2CTransaction = Base.extend({
  static: {
    jsonapiType: 'b2_c_transactions',
    endpoint: '/mpesa/b2_c_transactions'
  },
  attrs: {
    state: attr(),
    amount: attr(),
    type: attr(),
    receivedAt: attr(),
    account: attr(),
    reference: attr()
  }
});

export interface B2CTransactionT extends BaseModel<B2CTransactionT> {
  state: B2CTransactionStatesT;
  amount: number;
  type: string;
  receivedAt: string;
  account: string;
  reference: string;
}
