import React from 'react';

import { Text } from '@kwara/components/src/Intl';

import { CardLoader } from '../components/CardLoader';

const separator = <span className="grey-300 dib ph1"> &middot; </span>;

export function Loader() {
  return (
    <>
      <CardLoader />
      <div className="mv3">
        <CardLoader />
      </div>
      <div className="mt4 mb3 grey-300 flex items-center pa3 list-reset bb b--light-grey-300">
        <div className="pr1">
          <Text id="MemberNavigation.loans" />
        </div>
        {separator}
        <div className="pr1">
          <Text id="MemberNavigation.savings" />
        </div>
        {separator}
        <div className="pr1">
          <Text id="MemberNavigation.history" />
        </div>
        {separator}
        <div className="pr1">
          <Text id="MemberNavigation.profile" />
        </div>
      </div>
      <CardLoader />
    </>
  );
}
