import { attr, hasMany } from 'spraypaint';

import Base, { BaseModel } from './Base';
import { ReportFieldGlAccountLinkT } from './ReportFieldGlAccountLink';

export const ReportField = Base.extend({
  static: {
    jsonapiType: 'report_fields'
  },
  attrs: {
    name: attr(),
    code: attr(),
    fieldType: attr(),
    reportFieldGlAccountLinks: hasMany()
  },
  methods: {
    isGlField() {
      return this.fieldType === 'gl';
    }
  }
});

export const ReportFields = (reportTemplateId: string) =>
  Base.extend({
    static: {
      endpoint: `/reporting/templates/${reportTemplateId}/fields`
    }
  });

export interface ReportFieldT extends BaseModel<ReportFieldT> {
  name: string;
  code: string;
  fieldType: 'gl' | 'default';
  reportFieldGlAccountLinks: ReportFieldGlAccountLinkT[];
}
