import { attr } from 'spraypaint';
import Base from './Base';

export const DeprecatedNextOfKin = Base.extend({
  static: {
    jsonapiType: 'next_of_kin'
  },
  attrs: {
    nextOfKin1: attr(),
    nextOfKin2: attr(),
    nextOfKin3: attr(),
    nextOfKin4: attr()
  }
});
