// @flow

import keys from 'lodash/fp/keys';
import { attr } from 'spraypaint';
import axios from 'axios';

import { parse } from '@kwara/lib/src/dates';
import { handleDownload, blobHeaders } from '@kwara/lib/src/fileDownload';
import { Logger } from '@kwara/lib/src/logger';

import Base, { type BaseModel } from './Base';

// @TODO
// Requirement from https://app.clubhouse.io/getkwara/story/21787/can-display-loan-disbursement-report
// The date range picker should be active from 1st May 2021
// Change will be made before deploy to production.
export const ACTIVE_FROM = parse('2021-03-01');

const mimeTypesMaps = Object.freeze({
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
});

const mimeTypes = Object.freeze(keys(mimeTypesMaps));

export const DisbursedLoan = Base.extend({
  static: {
    jsonapiType: 'disbursed_loans',

    generateReport({ from, to, product_name }) {
      const url = `${DisbursedLoan.url()}.xlsx`;

      const fileName = `disbursed_loans_${from}_to_${to}`;

      const opts = DisbursedLoan.fetchOptions();
      const options = {
        params: {
          from,
          to,
          ...(product_name ? { product_name } : {})
        },
        ...blobHeaders(opts)
      };

      return axios(url, options)
        .then(res => {
          try {
            handleDownload({ data: res.data, fileName, mimeTypes });
          } catch (e) {
            Logger.log('Error reading disbursed loans report response', JSON.stringify(e));
            return Promise.reject(e);
          }
          return Promise.resolve();
        })
        .catch(e => {
          Logger.log('Error fetching disbursed loans data', JSON.stringify(e));
          return Promise.reject(e);
        });
    }
  },
  attrs: {
    disbursementMode: attr(),
    disbursementDate: attr(),
    disbursementFee: attr(),
    memberId: attr(),
    loanAmountAppliedFor: attr(),
    loanAmountApproved: attr(),
    totalAmountForLoansPaidOff: attr(),
    netDisbursedAmount: attr(),
    productName: attr(),
    state: attr()
  },
  methods: {}
});

export interface DisbursedLoanT extends BaseModel<DisbursedLoanT> {
  disbursementMode: string;
  disbursementDate: Date;
  disbursementFee: number;
  memberId: string;
  loanAmountAppliedFor: number;
  loanAmountApproved: number;
  totalAmountForLoansPaidOff: number;
  netDisbursedAmount: number;
  productName: string;
  state: string;
}
