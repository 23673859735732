import { Type } from '@kwara/components/src/Completion/CompletionBase';

export const OPEN_MODAL = 'modal';
export const ERROR = 'error';

export const USER_STATUS: Record<string, string> = {
  USER_ACTIVE: 'USER_ACTIVE',
  USER_FOUR_DIGIT_PIN_NOT_SET: 'USER_FOUR_DIGIT_PIN_NOT_SET',
  USER_PIN_LOCKED: 'USER_PIN_LOCKED',
  USER_PIN_RESET_REQUIRED: 'USER_PIN_RESET_REQUIRED',
  APPLICATION_PENDING_PIN_CONFIGURATION: 'APPLICATION_PENDING_PIN_CONFIGURATION',
  UNKNOWN_NUMBER: 'UNKNOWN_NUMBER',
  REGISTER_UNKNOWN_NUMBER: 'REGISTER_UNKNOWN_NUMBER', //to handle user coming from Register/Sign up screen
  APPLICATION_PENDING_REGISTRATION_FEE_PAYMENT: 'APPLICATION_PENDING_REGISTRATION_FEE_PAYMENT',
  APPLICATION_PENDING_APPROVAL: 'APPLICATION_PENDING_APPROVAL',
  APPLICATION_REJECTED: 'APPLICATION_REJECTED',
  MEMBER_ALLOWED_FOR_CONNECT: 'MEMBER_ALLOWED_FOR_CONNECT',
  MEMBER_PENDING_APPROVAL: 'MEMBER_PENDING_APPROVAL',
  MEMBER_REJECTED: 'MEMBER_REJECTED',
  MEMBER_DATA_ERROR: 'MEMBER_DATA_ERROR',
  MEMBER_NONEXISTENT_AND_ORG_WITH_NO_FEES: 'MEMBER_NONEXISTENT_AND_ORG_WITH_NO_FEES'
} as const;

export const STATUS_NEXT: Record<string, string> = {
  USER_ACTIVE: '/login',
  USER_FOUR_DIGIT_PIN_NOT_SET: '/loginFivePIN',
  USER_PIN_LOCKED: OPEN_MODAL,
  USER_PIN_RESET_REQUIRED: '/forgotPin',
  APPLICATION_PENDING_PIN_CONFIGURATION: OPEN_MODAL,
  UNKNOWN_NUMBER: '/joinSacco',
  REGISTER_UNKNOWN_NUMBER: '/joinSacco', //to handle user coming from Register/Sign up screen
  APPLICATION_PENDING_REGISTRATION_FEE_PAYMENT: OPEN_MODAL,
  APPLICATION_PENDING_APPROVAL: OPEN_MODAL,
  APPLICATION_REJECTED: OPEN_MODAL,
  MEMBER_ALLOWED_FOR_CONNECT: OPEN_MODAL,
  MEMBER_PENDING_APPROVAL: OPEN_MODAL,
  MEMBER_REJECTED: OPEN_MODAL,
  MEMBER_DATA_ERROR: OPEN_MODAL,
  MEMBER_NONEXISTENT_AND_ORG_WITH_NO_FEES: OPEN_MODAL
} as const;

export type ModalStatus = {
  [key: string]: {
    type: Type;
    title: string;
    subtitle: string;
    button: string;
    next: string;
    cancelRoute: string | null;
    cancelId?: string;
  };
};
export const MODAL_STATUS: ModalStatus = {
  APPLICATION_PENDING_REGISTRATION_FEE_PAYMENT: {
    type: 'good',
    title: 'StatusApprovalModal.applicationFound.title',
    subtitle: 'StatusApprovalModal.applicationFound.subtitle',
    button: 'StatusApprovalModal.applicationFound.button',
    cancelId: 'StatusApprovalModal.applicationFound.cancel',
    next: '/joiningFee',
    cancelRoute: '/cancelMembershipApplication'
  },
  APPLICATION_PENDING_APPROVAL: {
    type: 'info',
    title: 'StatusApprovalModal.pending.title',
    subtitle: 'StatusApprovalModal.pending.subtitle',
    button: 'StatusApprovalModal.pending.button',
    next: '/',
    cancelRoute: null
  },
  APPLICATION_PENDING_PIN_CONFIGURATION: {
    type: 'good',
    title: 'StatusApprovalModal.approved.title',
    subtitle: 'StatusApprovalModal.approved.subtitle',
    button: 'StatusApprovalModal.approved.button',
    next: '/secureFourPinUpdate',
    cancelRoute: null
  },
  APPLICATION_REJECTED: {
    type: 'bad',
    title: 'StatusApprovalModal.denied.title',
    subtitle: 'StatusApprovalModal.denied.subtitle',
    button: 'StatusApprovalModal.denied.button',
    next: '/',
    cancelRoute: null
  },
  MEMBER_PENDING_APPROVAL: {
    type: 'info',
    title: 'StatusApprovalModal.pending.title',
    subtitle: 'StatusApprovalModal.pending.subtitle',
    button: 'StatusApprovalModal.pending.button',
    next: '/',
    cancelRoute: null
  },
  MEMBER_ALLOWED_FOR_CONNECT: {
    type: 'good',
    title: 'StatusApprovalModal.approved.title',
    subtitle: 'StatusApprovalModal.approved.subtitle',
    button: 'StatusApprovalModal.approved.button',
    next: '/secureFourPinUpdate',
    cancelRoute: null
  },
  MEMBER_REJECTED: {
    type: 'bad',
    title: 'StatusApprovalModal.denied.title',
    subtitle: 'StatusApprovalModal.denied.subtitle',
    button: 'StatusApprovalModal.denied.button',
    next: '/',
    cancelRoute: null
  },
  USER_PIN_LOCKED: {
    type: 'bad',
    title: 'StatusApprovalModal.denied.title',
    subtitle: 'StatusApprovalModal.denied.subtitle',
    button: 'StatusApprovalModal.denied.button',
    next: '/',
    cancelRoute: null
  },
  MEMBER_DATA_ERROR: {
    type: 'info',
    title: 'StatusApprovalModal.error.title',
    subtitle: 'StatusApprovalModal.error.subtitle',
    button: 'StatusApprovalModal.error.button',
    next: '/',
    cancelRoute: null
  },
  MEMBER_NONEXISTENT_AND_ORG_WITH_NO_FEES: {
    type: 'info',
    title: 'StatusApprovalModal.orgNoFees.title',
    subtitle: 'StatusApprovalModal.orgNoFees.subtitle',
    button: 'StatusApprovalModal.orgNoFees.button',
    next: '/',
    cancelRoute: null
  },
  UNKNOWN_NUMBER: {
    type: 'info',
    title: 'StatusApprovalModal.unknownNumber.title',
    subtitle: 'StatusApprovalModal.unknownNumber.subtitle',
    button: 'StatusApprovalModal.unknownNumber.button',
    next: '/joinSacco',
    cancelRoute: '/'
  }
} as const;
