import { useState, useRef, useCallback, useMemo, useEffect } from 'react';

import { instantPrompt as iLP } from '../models/InstantPrompt';

export function useInstantPromptValues() {
  const { current: instantPrompt } = useRef(iLP);
  const [shouldMount, setShouldMount] = useState(instantPrompt.shouldMount);

  const mount = useCallback(() => {
    instantPrompt.mount();
    setShouldMount(true);
  }, [instantPrompt]);

  const unmount = useCallback(() => {
    instantPrompt.unmount();
    setShouldMount(false);
  }, [instantPrompt]);

  const onClose = useCallback(() => {
    setShouldMount(false);
  }, []);

  useEffect(() => {
    if (shouldMount) mount();
  }, [shouldMount, mount]);

  const values = useMemo(() => ({ shouldMount, unmount, onClose }), [onClose, shouldMount, unmount]);

  return values;
}
