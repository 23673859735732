class InstantPrompt {
  private static YES = 'YES';
  private static KEY = 'hasMountedInstantPrompt';

  public mount() {
    globalThis.localStorage.setItem(InstantPrompt.KEY, InstantPrompt.YES);
  }

  public unmount() {
    globalThis.localStorage.removeItem(InstantPrompt.KEY);
  }

  public shouldMount() {
    return globalThis.localStorage.getItem(InstantPrompt.KEY) !== InstantPrompt.YES;
  }
}

export const instantPrompt = new InstantPrompt();
