// @flow
import * as React from 'react';
import styles from './index.module.scss';

const addClassToButtons = (buttons, className) =>
  React.Children.map(
    buttons,
    button =>
      button &&
      React.cloneElement(button, {
        className: `${button.props.className || ''} ${className}`
      })
  );

type Props = {
  left: React.Node,
  right: React.Node,
  className?: string,
  innerClassName?: string
};

export const ButtonBar = ({ left, right, className = '', innerClassName = '' }: Props) => (
  <div className={`${className}`}>
    <div className={`flex ${innerClassName}`}>
      <div className="flex-auto flex" data-testid="ButtonBar-Left">
        {addClassToButtons(left, styles.ButtonLeft)}
      </div>
      <div className="flex-auto flex justify-end" data-testid="ButtonBar-Right">
        {addClassToButtons(right, styles.ButtonRight)}
      </div>
    </div>
  </div>
);
