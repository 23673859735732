// @flow

import * as React from 'react';
import get from 'lodash/fp/get';

import StatusTag from '@kwara/components/src/StatusTag';
import { DateTime, Text } from '@kwara/components/src/Intl';
import { BackButton } from '@kwara/components/src/Button';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import { type SavingType } from '@kwara/models/src';

import { OverviewLabel } from '../../components/OverviewLabel';

import { useStore } from '../../models/Member';

export const Metadata = ({ saving }: { saving: SavingType }) => {
  const store = useStore();
  const back = <BackButton type="secondary" to={{ url: '/overview/savings' }} />;
  const shareCapitalAccountId = get('member.shareCapitalAccountId', store);
  const showOverviewLabel = saving.id === shareCapitalAccountId;
  return (
    <>
      <ButtonBar className="mb2 pb3" left={[back]} />
      <div className="mb2 grey-400">
        <div className="flex">
          <StatusTag state={saving.state.current} />{' '}
          {showOverviewLabel ? (
            <div className="mh2">
              <OverviewLabel labelId="MemberOverview.notWithdrawable" />
            </div>
          ) : null}
        </div>
        <div className="flex mt1">
          <Text id="SavingDetail.updated" /> <DateTime value={saving.updatedAt} />
        </div>
      </div>
    </>
  );
};
