import * as React from 'react';

import Empty from '@kwara/components/src/Empty';
import { Link } from '@kwara/components/src/Link';
import { Stack, Child } from '@kwara/components/src/Stack';

export const NotFound404 = () => (
  <div className="flex flex-column h-100 justify-center">
    <Stack>
      <Child size="wide">
        <Empty>
          <div>
            <h2 className="grey-500">404</h2>
            <p>Page Not Found</p>
            <Link to="/" size="medium" type="primary" active>
              Back Home
            </Link>
          </div>
        </Empty>
      </Child>
    </Stack>
  </div>
);
