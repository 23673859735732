import * as React from 'react';
import classnames from 'classnames';

import CardContainer from '@kwara/components/src/CardContainer';
import { appName } from '@kwara/lib/src/utils';

import styles from './index.module.scss';

type Props = {
  children: any;
  overview: React.ReactNode;
  className?: string;
  printOverview?: boolean;
};

function PageLayoutWithOverview({ children, overview, className, printOverview = false }: Props) {
  const classes = ['min-vh-100 flex flex-column content-stretch'];

  return (
    <div className={classnames(classes, className)}>
      <div className={classnames([styles.Overview, printOverview ? null : 'hide-on-print'])}>{overview}</div>
      <CardContainer className={appName.isMember ? styles.NoBorderRadius : ''}>
        <div className={styles.Content}>{children}</div>
      </CardContainer>
    </div>
  );
}

export default PageLayoutWithOverview;
