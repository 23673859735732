import React from 'react';

import { LoanSubstepProps, LoanFormData } from 'LoanSharedTypes';

import { Logger } from '@kwara/lib/src/logger';
import { Text, getTranslationIdByApp } from '@kwara/components/src/Intl';
import { calculateNumberInstallments } from '@kwara/models/src/models/Loan';

import { getRepaymentTerms } from './getRepaymentTerms';

export function DurationField(props: LoanSubstepProps<LoanFormData>) {
  const {
    TextField,
    Condition,
    data,
    formProps: {
      values: { repaymentPeriodUnit }
    }
  } = props;
  const { maxDurationUnit, maxDurationValue } = getRepaymentTerms(data);

  // If the api returns a maxDurationUnit and a repaymentPeriodUnit,
  // they  should always be the same. If not, we have a problem.
  if (repaymentPeriodUnit && maxDurationUnit && repaymentPeriodUnit !== maxDurationUnit) {
    Logger.error('Repayment Unit does not equal to Maximum duration Unit');
  }

  const installmentsInfo = (
    <>
      {!!maxDurationValue && (
        <>
          <Text
            id={`LoanAdd.Configure.maxDuration.${maxDurationUnit}`}
            values={{ value: maxDurationValue, unit: maxDurationUnit }}
          />
          <br />
        </>
      )}

      <Condition when="repaymentPeriod" not="">
        {({ input: { value: repaymentPeriod } }) => (
          <Condition when="repaymentPeriodUnit" not="">
            <Condition when="loanDuration" not="">
              {({ input: { value: loanDuration } }) => (
                <Text
                  id="LoanAdd.Configure.installments"
                  values={{ num: calculateNumberInstallments({ period: repaymentPeriod, loanDuration }) }}
                />
              )}
            </Condition>
          </Condition>
        )}
      </Condition>
    </>
  );

  return (
    <TextField
      name="loanDuration"
      errorBaseId="LoanAdd.Configure.LoanDuration"
      labelId={getTranslationIdByApp('LoanAdd.Configure.LoanDuration.title')}
      rightGlyph={repaymentPeriodUnit && `LoanAdd.Configure.${repaymentPeriodUnit}`}
      info={installmentsInfo}
      required
    />
  );
}
