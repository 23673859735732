import * as React from 'react';
import map from 'lodash/map';
import isEmpty from 'lodash/fp/isEmpty';
import { injectIntl, IntlShape } from 'react-intl';

import { If } from '@kwara/components/src/If/If';
import { getTranslation, Text } from '@kwara/components/src/Intl';
import { ModelErrors, ModelError } from '@kwara/models/src';
import Banner, { BannerState } from '@kwara/components/src/Banner';

type ErrorValues = { [key: string]: string | number | null };
type SharedProps = { errors: ModelErrors; errorBaseId: string; errorValues?: ErrorValues };

const getMessageForStatus = (statusCode, intl, errorValues = null) => {
  const id = `Errors.${statusCode}`;
  const translatedText = getTranslation(intl, id, { errorValues });

  if (!statusCode || translatedText === id) {
    return null;
  }
  return translatedText;
};

/******************************************************** */
type ErrorItemPropTypes = {
  intl: IntlShape;
  error: ModelError;
  errorBaseId: string;
  errorValues?: ErrorValues;
};

const ErrorItem = injectIntl(({ error, errorBaseId, errorValues, intl }: ErrorItemPropTypes) => {
  const attribute = error?.attribute ?? '';
  const { code: statusCode, title, detail, fullMessage, rowIndex } = error || {};
  const message =
    getMessageForStatus(statusCode, intl, errorValues) ||
    (fullMessage && rowIndex && `Row ${rowIndex} - ${attribute} ${fullMessage}`) ||
    fullMessage ||
    title ||
    detail;
  let node = null;

  if (!message) {
    node = statusCode || <Text id="Errors.Generic" />;
  } else if (errorBaseId) {
    node = <Text id={`${errorBaseId}.Errors.${attribute}`} values={errorValues} />;
  } else if (typeof message == 'string') {
    node = message.charAt(0).toUpperCase() + message.slice(1);
  } else {
    node = <Text id="Errors.Generic" />;
  }

  return <li>{node}</li>;
});

ErrorItem.defaultProps = {
  errorBaseId: ''
};

/******************************************************** */
interface ErrorListPropTypes extends SharedProps {}

function ErrorList(props: ErrorListPropTypes) {
  return (
    <ul className="ma0 pa1 list">
      {map(props.errors, (error: ModelError, attribute: string) => {
        return (
          <ErrorItem key={attribute} error={error} errorValues={props.errorValues} errorBaseId={props.errorBaseId} />
        );
      })}
    </ul>
  );
}

/******************************************************** */
interface ModelErrorBannerPropTypes extends Omit<SharedProps, 'errorBaseId'> {
  state: BannerState;
  errorBaseId?: string;
  className?: string;
}

function ModelErrorBanner({ errors, errorBaseId, errorValues, ...restProps }: ModelErrorBannerPropTypes) {
  return (
    <If
      condition={!isEmpty(errors)}
      do={
        <Banner
          {...restProps}
          text={<ErrorList errors={errors} errorBaseId={errorBaseId} errorValues={errorValues} />}
        />
      }
    />
  );
}

ModelErrorBanner.defaultProps = {
  state: 'error',
  errorBaseId: '',
  errorValues: {}
};

export { ErrorList, ErrorItem, ModelErrorBanner };
