// @flow

import * as React from 'react';

import get from 'lodash/fp/get';
import size from 'lodash/fp/size';

import Empty from '@kwara/components/src/Empty';
import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import { useSchedule } from '@kwara/models/src/models/request/hooks';

import Section from '../../components/Section';
import { ScheduleRepaymentsTable } from '../../components/ScheduleRepaymentsTable';

export const LoanSchedule = ({ loan }) => {
  const response = useSchedule(get('id', loan));

  return (
    <Loadable {...response}>
      {schedule => {
        const repayments = get('repayments', schedule);
        const hasRepayments = size(repayments) > 0;
        return (
          <Section titleId="LoanDetail.schedule">
            <div>
              {hasRepayments ? (
                <ScheduleRepaymentsTable repayments={repayments} />
              ) : (
                <Empty>
                  <Text id="RepaymentsTable.empty" />
                </Empty>
              )}
            </div>
          </Section>
        );
      }}
    </Loadable>
  );
};
