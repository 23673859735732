// @flow
import * as React from 'react';
import noop from 'lodash/fp/noop';

import { Text, type TranslationId } from '@kwara/components/src/Intl';
import Asset from '@kwara/components/src/Asset';

import styles from './RadioIcon.module.scss';

type Props = {
  disabled?: boolean,
  value: string,
  labelId: TranslationId,
  name: string,
  onChange: (evt: { target: { value: string } }) => void,
  inputOnChange: (evt: { target: { value: string } }) => void,
  assetId: string
};

export const RadioIcon = ({
  disabled,
  name,
  labelId,
  onChange,
  inputOnChange = noop,
  value,
  assetId,
  ...rest
}: Props) => {
  const id = `${name}-${value}`;
  return (
    <div className={`${styles.container} mr3`}>
      <input
        className={styles.radioInput}
        disabled={disabled}
        type="radio"
        id={id}
        name={name}
        value={value}
        onChange={e => {
          onChange(e);
          inputOnChange(e);
        }}
        {...rest}
      />
      <label className={`${styles.label}`} htmlFor={id}>
        <Asset id={assetId} />
        <div className="flex justify-center kw-text-extra-small mt1 tc">{labelId ? <Text id={labelId} /> : null}</div>
      </label>
    </div>
  );
};

export default RadioIcon;
