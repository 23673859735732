import { attr, hasOne, hasMany } from 'spraypaint';

import Base, { type BaseModel } from './Base';
import { BankAccount, type BankAccountT } from './BankAccount';
import { Bank, type BankT } from './Bank';
import { Remittance, type RemittanceT } from './Remittance';

export const DirectDebitOrder = Base.extend({
  static: {
    jsonapiType: 'direct_debit_orders'
  },
  attrs: {
    amount: attr(),
    bankAccount: hasOne({ type: BankAccount }),
    collectingBank: hasOne({ type: Bank }),
    remittances: hasMany({ type: Remittance }),
    collectionDay: attr()
  }
});

export interface DirectDebitOrderT extends BaseModel<DirectDebitOrderT> {
  amount: number;
  bankAccount: BankAccountT;
  collectingBank: BankT;
  remittances: RemittanceT[];
  collectionDay: string;
}
