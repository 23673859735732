import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { segmentTrack } from '@kwara/components/src/Segment';

const guarantorRequirementsTitleIds = ['Guarantor.List.Number', 'Guarantor.List.Member', 'Guarantor.List.Amount'];

const collateralsRequirementsTitleIds = [
  'Collaterals.List.Ownership',
  'Collaterals.List.NSE',
  'Collaterals.List.LifeCover',
  'Collaterals.List.Security'
];

type RequirementsListProps = {
  titleId: string;
  requirementTitleIds: string[];
};

const RequirementsList = ({ titleId, requirementTitleIds }: RequirementsListProps) => {
  return (
    <div className="mv4">
      <h3 className="kw-weight-bold mv1">
        <Text id={titleId} />
      </h3>
      <ul className="ph3 mv1">
        {requirementTitleIds.map((element, i) => (
          <li key={`list.${i}`}>
            <p data-testid="wizard-title-id" className="grey-400 mv1">
              <Text id={`LoanAdd.Information.${element}`} />
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const LoanInformation = () => {
  React.useEffect(() => {
    segmentTrack('Long term application flow: Information page');
  }, []);

  return (
    <div className="ph4">
      <div className="mv4">
        <h3 data-testid="loan-add-title-id" className="kw-weight-bold mv1">
          <Text id="LoanAdd.Information.About.Title" />
        </h3>
        <p className="grey-400 mv1">
          <Text id="LoanAdd.Information.About.Subtitle" />
        </p>
      </div>
      <RequirementsList
        titleId="LoanAdd.Information.Collaterals.Title"
        requirementTitleIds={collateralsRequirementsTitleIds}
      />
      <RequirementsList
        titleId="LoanAdd.Information.Guarantor.Title"
        requirementTitleIds={guarantorRequirementsTitleIds}
      />
    </div>
  );
};
