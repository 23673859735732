import * as React from 'react';
import map from 'lodash/fp/map';

import { Loadable } from '@kwara/components/src/Loadable';
import { useBankAccounts } from '@kwara/models/src/models/request/hooks';
import { fields } from '@kwara/models/src/models/Remittance';
import { BankAccountT } from '@kwara/models/src';

import { SubscribedSelectField as SelectField } from '..';

export function MemberBankAccountSelectBase({
  name,
  labelId,
  accounts,
  addData
}: {
  name: string;
  labelId: string;
  accounts: BankAccountT[];
  addData?: Function;
}) {
  React.useEffect(() => {
    if (addData) {
      addData({ bankAccounts: accounts });
    }
  }, [addData, accounts]);

  return (
    <SelectField required name={name} labelId={labelId}>
      <SelectField.Option translationId={`Forms.TransactionForm.Bank.default`} value="" />
      {map(account => {
        return (
          <SelectField.Option key={account.id} value={account.id}>
            {account.display()}
          </SelectField.Option>
        );
      }, accounts)}
    </SelectField>
  );
}

const includes = ['bank_branch.bank'];
export const MemberBankAccountSelect = ({
  memberId,
  name = fields.remittance.bankAccount,
  labelId = 'Forms.MemberBankAccountSelect.label',
  addData
}: {
  memberId: string;
  name: string;
  labelId?: string;
  addData?: Function;
}) => {
  const r = useBankAccounts(memberId, includes);

  return (
    <Loadable {...r}>
      {(accounts: BankAccountT[]) => (
        <MemberBankAccountSelectBase accounts={accounts} name={name} labelId={labelId} addData={addData} />
      )}
    </Loadable>
  );
};
