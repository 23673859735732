// @flow

import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import { ProfilePhoto } from '@kwara/components/src/ProfilePhoto';
import { useSaving } from '@kwara/models/src/models/request/hooks';

import { Metadata } from './Metadata';
import { Metrics } from './Metrics';
import { useStore } from '../../models/Member';
import { isAddMoneyButtonAvailable } from '../../lib/helpers';

const includes = ['product'];
export const SavingOverview = ({ accountId }) => {
  const store = useStore();
  const response = useSaving(accountId, includes);

  return (
    <Loadable {...response}>
      {saving => {
        return (
          <div>
            <section className="mt1 pt3 mb1 flex justify-between items-center">
              <div>
                <Metadata saving={saving} />
                <h2 aria-label={'Overview Saving Id'} className="ma0 kw-text-super-large kw-weight-bold">
                  {saving.id}
                </h2>
              </div>

              <ProfilePhoto size="regular" memberId={store.memberId} attachments={store.attachments} />
            </section>
            <Metrics saving={saving} />
            {isAddMoneyButtonAvailable(saving, store) ? (
              <div className="flex flex-column mb3">
                <Button type="primary" size="large" to={`/savings/${accountId}/deposit`} aria-label="deposit">
                  <Text id="SavingDeposit.SavingDetail.button.label" />
                </Button>
              </div>
            ) : null}
          </div>
        );
      }}
    </Loadable>
  );
};
