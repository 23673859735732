// @flow
import * as React from 'react';

import type { RouterHistory, LocationShape } from 'react-router-dom';
import Auth from '@kwara/models/src/lib/Auth';

import { SecureFourPinUpdate } from '../SecureFourPinUpdate';

import { steps } from './config';

const submit = async (data, auth) => {
  const { pin1: pin, phone: phone_number, device_token, id_type, id_number } = data;
  try {
    await auth.resetFourPIN({
      data: {
        attributes: {
          phone_number,
          device_token,
          pin,
          id_type,
          id_number
        }
      }
    });
  } catch ({ errors }) {
    throw errors;
  }
};

type Props = {
  baseUrl: string,
  cancelReturnsTo: () => void,
  match: {
    params: {
      step: string,
      subStep: string
    }
  },
  history: RouterHistory,
  location: LocationShape,
  auth: Auth
};

export const ForgotPIN = ({ baseUrl, cancelReturnsTo, match, history, auth }: Props) => {
  return (
    <SecureFourPinUpdate
      configSteps={steps}
      auth={auth}
      cancelReturnsTo={cancelReturnsTo}
      baseUrl={baseUrl}
      match={match}
      history={history}
      onSubmit={submit}
    />
  );
};
