// @flow

import * as React from 'react';
import cx from 'classnames';

import { Currency, Text } from '@kwara/components/src/Intl';
import { type RepaymentType } from '@kwara/models/src';
import { Date } from '@kwara/components/src/Intl';
import StatusTag from '@kwara/components/src/StatusTag';

import InfoList from '../../../InfoList';
import Collapsible from '../../../Collapsible';
import styles from './index.module.scss';

const monthSummary = (repayment: RepaymentType) => (
  <div className={cx(styles.tableItem, 'flex-auto  tl pa1')}>
    <div className={cx(styles.tableItem1, 'kw-text-regular grey-400')}>
      <Date value={repayment.dueAt} />
    </div>
    <div className={cx(styles.tableItem2, 'kw-text-small grey-400')}>
      <StatusTag state={repayment.state} size="small" />
    </div>
    <div className={cx(styles.tableItem3, 'kw-text-regular red-600')}>
      <Currency format={'currency'} value={repayment.outstanding()} />
    </div>
  </div>
);

export const TableRowMonth = ({ repayment }: { repayment: RepaymentType }) => (
  <Collapsible className={'bg-light-grey-300 bt b--light-grey-300 pa2'} summary={monthSummary(repayment)}>
    <InfoList className={'kw-text-small pl2 pr2 mt2 mb3 bg-white'}>
      <InfoList.Item
        label={<Text id="RepaymentsTable.principal" />}
        value={<Currency format={'currency'} value={repayment.principal.due} />}
      />
      <InfoList.Item
        label={<Text id="RepaymentsTable.interest" />}
        value={<Currency format={'currency'} value={repayment.interest.due} />}
      />
      <InfoList.Item
        label={<Text id="RepaymentsTable.outstanding" />}
        value={<Currency format={'currency'} value={repayment.outstanding()} />}
      />
      <InfoList.Item
        label={<Text id="RepaymentsTable.balance" />}
        value={<Currency format={'currency'} value={repayment.startingBalance} />}
      />
    </InfoList>
  </Collapsible>
);
