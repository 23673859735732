import get from 'lodash/fp/get';
import find from 'lodash/fp/find';
import invokeArgs from 'lodash/fp/invokeArgs';

import { Store } from '@kwara/models/src/lib/Store';
import { fields as disbursementFields } from '@kwara/models/src/models/Loan';
import { LoanRemittanceT, BankAccountT } from '@kwara/models/src';
import { fields } from '@kwara/models/src/models/Remittance';

type DataT = {
  remittance: {
    bankAccount: { id: string };
    collectingBank: { id: string };
  };
};

function determineBankAccount({ bankAccounts, data }: { bankAccounts: BankAccountT[]; data: DataT }) {
  // TODO: Protect this logic in case the user picks a non-bank method (e.g. cash)
  // but still had a stale bankAccountId value saved in the form.
  const bankAccountId = get(fields.remittance.bankAccount, data);

  if (!bankAccountId) return null;

  // Pick an existing account to associate it with the remittance object
  return find((acc: BankAccountT) => acc.id === bankAccountId, bankAccounts);
}

export function setRemittanceDetails<S = Store>({
  remittance,
  data,
  bankAccounts,
  store
}: {
  remittance: LoanRemittanceT;
  bankAccounts: BankAccountT[];
  data: DataT;
  store: S;
}) {
  const collectingBank = (store as any).getBank(get(fields.remittance.collectingBank, data));

  const bankAccount = determineBankAccount({
    bankAccounts,
    data
  });
  remittance.collectingBank = collectingBank;
  remittance.bankAccount = bankAccount;
}

export function getDisbursementBankNameFromStore(data: any, store: Store) {
  const bankId = get(disbursementFields.disbursement.bank, data);

  return invokeArgs('getBank', [bankId], store);
}

export function getDisbursementBranchNameFromStore(disbursementBank: any, data: any, store: Store) {
  const branchId = get(disbursementFields.disbursement.branch, data);

  return invokeArgs('getBankBranch', [disbursementBank, branchId], store);
}

export function setDisbursementBankDetails(data: any, store: Store) {
  const { disbursementBankDetails } = data;

  if (!get('bank', disbursementBankDetails)) return disbursementBankDetails;

  const disbursementBank = getDisbursementBankNameFromStore(data, store);
  const disbursementBranch = getDisbursementBranchNameFromStore(disbursementBank, data, store);

  return {
    ...disbursementBankDetails,
    bank: get('name', disbursementBank),
    bank_branch: get('name', disbursementBranch)
  };
}
