import * as React from 'react';

import { If } from '@kwara/components/src/If/If';
import { ERRORS } from '@kwara/lib/src/validator';
import { SubStepComponentProps } from '@kwara/components/src/Wizard/latest/types/sharedTypes';

import { Container } from '../../../../layouts';
import { Picker } from '../../components/Picker';
import { useSuggestSaccoContext, FormState, FinancialProductFormState } from '../..';

import styles from './index.module.scss';

export function FinancialProducts({ addData, TextArea }: SubStepComponentProps<Partial<FormState>>) {
  const {
    data,
    isOthersSelectedAsFinancialProduct,
    onSetFinancialProduct,
    onOthersOnChangeHandler,
    checkIsActiveFinancialProduct
  } = useSuggestSaccoContext();

  return (
    <Container>
      <ul className={styles['financial-products']}>
        {data.getFinancialProducts().map(({ name, value }, i) => (
          <li key={i.toString()} className={styles[value.toLowerCase()]}>
            <Picker isActive={checkIsActiveFinancialProduct(value)} onClick={onSetFinancialProduct(value, addData)}>
              {name}
            </Picker>
          </li>
        ))}
      </ul>

      <If
        condition={isOthersSelectedAsFinancialProduct}
        do={
          <TextArea
            name="financialProductCustom"
            placeholderId="SaccoRecommendationFlow.FinancialProducts.Other.placeholder"
            onChange={onOthersOnChangeHandler(addData)}
          />
        }
      />
    </Container>
  );
}

FinancialProducts.validate = {
  financialProduct: {
    isRequired: () => true,
    custom(target: FinancialProductFormState) {
      if (!target.value) return ERRORS.valueMissing;
      return null;
    }
  }
};
