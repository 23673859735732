import * as React from 'react';
import cx from 'classnames';

import iconWhiteKwaraLogo from '../../assets/iconWhiteKwaraLogo.png';
import styles from './index.module.scss';

type PrimitiveLiPropTypes = React.ComponentPropsWithoutRef<'li'>;
type ImageLiElement = React.ElementRef<'li'>;

export const ImageLi = React.forwardRef<ImageLiElement, PrimitiveLiPropTypes>(function ImageLi(
  { children, className, ...restProps },
  forwardedRef
) {
  return (
    <li {...restProps} ref={forwardedRef} className={cx(styles['container'], className)}>
      <img alt="" src={iconWhiteKwaraLogo} />

      <span>{children}</span>
    </li>
  );
});
