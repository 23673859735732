import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import Auth from '@kwara/models/src/lib/Auth';

import { _api, AxiosError } from '../../services';

type ActivateMembershipDataType = {
  organisation_permalink: string;
  id_type: string;
  id_number: string;
  device_token: string;
};

export interface WizardStateType extends ActivateMembershipDataType {
  otp: string;
  phone: string;
}

const ACTIVATE_MEMBERSHIP_MUTATION_KEY = 'v1_activate_membership';

export function useSubmit(auth: Auth) {
  const queryClient = useQueryClient();
  const query = useMutation({
    mutationKey: [ACTIVATE_MEMBERSHIP_MUTATION_KEY],
    mutationFn: (data: Record<'data', Record<'attributes', ActivateMembershipDataType>>) =>
      _api.httpPostRequest('/v1/memberships', data),
    onError: error => {
      throw (error as AxiosError).response.data;
    },
    onSuccess() {
      //We re-query the resources in `/overview/loans` page
      queryClient.invalidateQueries('connect_profile');
      queryClient.invalidateQueries('member');
      queryClient.invalidateQueries('member_loans');
    },
    retry: false
  });

  const submit = useCallback(
    async ({ phone, otp, device_token, id_type, id_number, organisation_permalink }: WizardStateType) => {
      try {
        await auth.verifyOTPV1({
          data: {
            attributes: {
              phone_number: phone,
              otp,
              device_token
            }
          }
        });
        await query.mutateAsync({
          data: {
            attributes: {
              organisation_permalink,
              id_type,
              id_number,
              device_token
            }
          }
        });
      } catch ({ errors }) {
        throw errors;
      }
    },
    [auth, query]
  );

  return submit;
}
