// @flow

import * as React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';
import { useLoan } from '@kwara/models/src/models/request/hooks';

import { LoanSchedule } from './LoanSchedule';
import { LoanTransactions } from './LoanTransactions';

export const LoanDetailSections = {
  details: 'details',
  security: 'security',
  documents: 'documents',
  activity: 'activity'
};

const includes = ['transactions'];
export const LoanDetails = ({ loanId }: { loanId: string }) => {
  const response = useLoan(loanId, includes);

  return (
    <Loadable {...response}>
      {loan => {
        return (
          <>
            <LoanSchedule loan={loan} />
            <LoanTransactions loan={loan} />
          </>
        );
      }}
    </Loadable>
  );
};

LoanDetails.Sections = LoanDetailSections;
