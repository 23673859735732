// @flow

import * as React from 'react';
import classnames from 'classnames';

import { valueClasses } from '@kwara/components/src/Statistic';

import styles from './index.module.scss';

export const StatisticStack = ({ color = 'black', containerClassName = '', size = 'regular', title, value }) => {
  const containerClasses = `pv3 ${containerClassName}`;
  const titleTextClass = size === 'small' ? 'kw-text-small' : 'kw-text-regular';

  return (
    <div className={classnames(containerClasses, styles.container)}>
      <div className={classnames('grey-400 mb2', titleTextClass, styles.title)}>{title}</div>
      <div className={(styles.value, 'pl2 pb2')}>
        <span className={classnames(color, valueClasses[size])}>{value == null ? '–' : value}</span>
      </div>
    </div>
  );
};
