import * as React from 'react';

import { SubscribedPinInputField } from '@kwara/components/src/Form';
import { Text } from '@kwara/components/src/Intl';

export const PINInputs = ({ length }: { length: number }) => {
  return (
    <div className={'flex flex-column'}>
      <div className="pb4">
        <div className="kw-text-x-small pb3">
          <Text id="CreatePin.createPin" />
        </div>
        <SubscribedPinInputField name="pin1" sensitiveValidation canHide required length={length} />
      </div>

      <div className="mb4">
        <div className="kw-text-x-small pb3">
          <Text id="CreatePin.confirmPin" />
        </div>
        <SubscribedPinInputField name="pin2" sensitiveValidation canHide required length={length} />
      </div>
    </div>
  );
};
