import * as React from 'react';
import cx from 'classnames';

import { If } from '@kwara/components/src/If/If';
import { AttachmentT } from '@kwara/models/src';
import { ActionButton } from '@kwara/components/src/Button';
import { Text, Date, FileSize } from '@kwara/components/src/Intl';
import { GenericPropTypes } from '@kwara/components/src/DocumentListBase';

interface MetaDataProps extends GenericPropTypes {
  attachment: AttachmentT;
}

function MetaData({ size, attachment }: MetaDataProps) {
  const shouldUploadNow = size !== 'tiny' && attachment.createdAt == null;

  return (
    <div className={cx('grey-400', 'kw-text-small')}>
      <If
        condition={shouldUploadNow}
        do={<Text id="DocumentUploads.JustUploaded" />}
        else={
          <>
            <FileSize value={attachment.fileSize} /> &middot; <Date value={attachment.createdAt} />
          </>
        }
      />
    </div>
  );
}

interface DocumentItemPropTypes extends GenericPropTypes {
  attachment: AttachmentT;
  setShownAttachment: (attachment?: AttachmentT) => void;
}

export function DocumentItem({ size, attachment, setShownAttachment }: DocumentItemPropTypes) {
  return (
    <div className="flex justify-between mb3 br3 pa3">
      <div className="black kw-weight-regular">
        <MetaData size={size} attachment={attachment} />
        <Text id={`DocumentUploads.${attachment.name}.label`} />
      </div>
      <div>
        <ActionButton type="view" size={size} onClick={() => setShownAttachment(attachment)} />
      </div>
    </div>
  );
}
