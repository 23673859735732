import * as React from 'react';
import head from 'lodash/fp/head';
import pick from 'lodash/fp/pick';

import Banner from '@kwara/components/src/Banner';
import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { pathWithBase } from '@kwara/models/src/lib/urls';

import { hasExactLoan, matchingLoans } from '.';

export const LoanBanner = ({ data }) => {
  const store = LoanBanner.appStore;
  if (!store.refinanceEnabled) {
    return null;
  }

  if (hasExactLoan(data)) {
    const firstLoan = head(matchingLoans(data));
    const { id, name } = pick(['id', 'product.name'], firstLoan);

    return (
      <Banner
        state="warning"
        className="mb4"
        text={
          <div>
            <div className="mb3">
              <Text id="LoanAdd.Configure.Banner.ExactLoanMatch" values={{ id, name }} />
            </div>
            <div className="flex justify-center">
              <Button to={pathWithBase('loans')({ id })} type="primary">
                <Text id="LoanAdd.Configure.Banner.RefinanceButton" values={{ id }} />
              </Button>
            </div>
          </div>
        }
      />
    );
  }

  return null;
};
