// @flow
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';

export const Environments = Object.freeze({
  PRODUCTION: 'production',
  SANDBOX: 'sandbox',
  DEVELOPMENT: 'development',
  TEST: 'test'
});

export const isDev: boolean = process.env.NODE_ENV === Environments.DEVELOPMENT;
export const isProd: boolean = process.env.NODE_ENV === Environments.PRODUCTION;
const isAppRunningUnderCypress = !!window.Cypress;
export const isTest: boolean = process.env.NODE_ENV === Environments.TEST || isAppRunningUnderCypress;

// This is hacky and should really be an environment variable set on Netlify's process, but... YOLO
const hostname = get('window.location.hostname', global);
export const isSandbox: boolean = includes('.sandbox.', hostname);

export const isNonProdEnv = isSandbox || isDev || isTest;

export const getEnvName = () => {
  if (isSandbox) {
    return Environments.SANDBOX;
  }

  if (isProd) {
    return Environments.PRODUCTION;
  }

  if (isDev) {
    return Environments.DEVELOPMENT;
  }

  if (isTest) {
    return Environments.TEST;
  }
};

export const APP_NAME = {
  MEMBER: 'member',
  SACCO: 'sacco'
};

export const appName = {
  type: '',
  set current(type) {
    this.type = type;
  },
  get isMember() {
    return this.type === APP_NAME.MEMBER;
  },
  get isSacco() {
    return this.type === APP_NAME.SACCO;
  }
};
