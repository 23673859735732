import * as React from 'react';

import { KeyOf } from 'GlobalTypes';
import { StatusType } from 'AirtimePurchaseTypes';

import Button from '@kwara/components/src/Button';

import { Text } from '@kwara/components/src/Intl';
import { Completion, Types } from '@kwara/components/src/Completion';
import { SharedCompletionProps } from '@kwara/components/src/Completion/CompletionBase';

import { analyticsId } from '../config';
import { usePurchaseAirtime, FormState } from '..';
import { STATUS } from '../../../models/AirtimePurchases';

type ErrorStatusPropTypes = {
  formState: FormState;
  onCancel(): void;
};

const i18nMapper = {
  generic_failure: {
    title: 'Airtime.Status.Error.Generic.title',
    subTitle: 'Airtime.Status.Error.Generic.subTitle'
  },
  timeout: {
    title: 'Airtime.Status.Error.Generic.title',
    subTitle: 'Airtime.Status.Error.Generic.subTitle'
  },
  failed_c2b_payment: {
    title: 'Airtime.Status.Error.C2B.title',
    subTitle: 'Airtime.Status.Error.C2B.subTitle'
  },
  failed_airtime_purchase: {
    title: 'Airtime.Status.Error.Tanda.title',
    subTitle: 'Airtime.Status.Error.Tanda.subTitle'
  }
};

interface GetErrorStatusPropTypes extends SharedCompletionProps {
  status: StatusType;
}

/**
 * getErrorStatusProps
 */
function getErrorStatusProps(props: GetErrorStatusPropTypes) {
  const { buttonTextId, onConfirm, status, ...restProps } = props;

  if (status === STATUS.FAILED_AIRTIME_PURCHASE) return restProps;

  return { ...restProps, buttonTextId, onConfirm };
}

/**
 * getTitleId
 */
function getTitleId(status: StatusType) {
  return i18nMapper[status as KeyOf<typeof i18nMapper>]?.title ?? 'Airtime.Status.Error.Generic.title';
}

/**
 * getSubtitle
 */
function getSubtitle(status: StatusType) {
  return (
    <Text id={i18nMapper[status as KeyOf<typeof i18nMapper>]?.subTitle ?? 'Airtime.Status.Error.Generic.subTitle'} />
  );
}

export function ErrorStatus({ formState, onCancel }: ErrorStatusPropTypes) {
  const { isPolling, status, refetch } = usePurchaseAirtime();

  return (
    <Completion
      {...getErrorStatusProps({
        status: status,
        type: Types.good,
        isLoading: isPolling,
        analyticsId: analyticsId,
        titleId: getTitleId(status),
        subtitle: getSubtitle(status),
        buttonTextId: 'Airtime.Status.Error.retry',
        onConfirm: () => refetch(formState)
      })}
    >
      <Button size="small" type="secondary" className="mt2 bn white" onClick={onCancel}>
        <Text id="Airtime.Status.Error.close" />
      </Button>
    </Completion>
  );
}
