// @flow

import * as React from 'react';

import { type LoanType } from '@kwara/models/src';

import { MainLoanCard } from '../../components/LoanCard';


type Props = {
  loans: LoanType[]
};

export const LoansToRepayList = ({ loans }: Props) => (
    <>
      {loans.map(loan =>(
        <MainLoanCard key={loan.id} data={loan} showMoreDetailsButton={false}/>
      ))}
    </>  
)

