// @flow

import * as React from 'react';

import size from 'lodash/fp/size';
import get from 'lodash/fp/get';

import { Loadable } from '@kwara/components/src/Loadable';
import Empty from '@kwara/components/src/Empty';
import { Text } from '@kwara/components/src/Intl';
import { useSaving } from '@kwara/models/src/models/request/hooks';

import Section from '../../components/Section';
import { TransactionsTable } from '../../components/TransactionsTable';

export const SavingDetailSections = {
  details: 'details',
  activity: 'activity'
};

const includes = ['transactions'];
export const SavingDetails = ({ accountId }: { accountId: string }) => {
  const response = useSaving(accountId, includes);

  return (
    <Loadable {...response}>
      {saving => {
        const transactions = get('transactions', saving);
        const hasTransactions = size(transactions) > 0;

        return (
          <Section titleId="SavingDetail.transactions">
            <div>
              {hasTransactions ? (
                <TransactionsTable transactions={transactions} />
              ) : (
                <Empty>
                  <Text id="TransactionsTable.empty" />
                </Empty>
              )}
            </div>
          </Section>
        );
      }}
    </Loadable>
  );
};

SavingDetails.Sections = SavingDetailSections;
