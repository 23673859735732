import axios from 'axios';

import { KeyOf } from 'GlobalTypes';

// endpoint providers => https://ip-api.com/docs/legal

interface IpResponse {
  status: string;
  country: string;
  countryCode: string;
  region: string;
  regionName: string;
  city: string;
  zip: number;
  lat: number;
  lon: number;
  timezone: string;
  isp: string;
  org: string;
  as: string;
  query: string;
}

export async function detectCountry(
  resourceField?: KeyOf<IpResponse>,
  onIsLoading?: Function,
  onSuccess?: Function,
  onError?: Function
) {
  onIsLoading?.();

  try {
    const endpoint = `http://ip-api.com/json/${resourceField ? `?fields=${resourceField}` : ''}`;
    const response = await axios.get<IpResponse>(endpoint);
    onSuccess?.();
    return resourceField ? response.data[resourceField] : response.data;
  } catch (error) {
    onError?.();
    throw error;
  }
}
