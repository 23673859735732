// @flow

import { attr } from 'spraypaint';
import axios from 'axios';

import { Logger } from '@kwara/lib/src/logger';
import { handleDownload, blobHeaders } from '@kwara/lib/src/fileDownload';

import Base, { type BaseModel } from './Base';

const mimeTypesMaps = Object.freeze({
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
});

const mimeTypes = Object.freeze(Object.keys(mimeTypesMaps));

const ReportStates = Object.freeze({
  GENERATED: 'generated',
  FAILED: 'failed',
  PROCESSING: 'processing'
});

export type ReportStatesT = $Values<typeof ReportStates>;

export const LoanClassificationReport = Base.extend({
  static: {
    jsonapiType: 'loan_classification_reports'
  },
  attrs: {
    financialYear: attr(),
    startDate: attr(),
    endDate: attr(),
    state: attr()
  },
  methods: {
    fileName() {
      return `sasra_4b_${this.startDate}_to_${this.endDate}_${this.id}`;
    },
    isGenerated() {
      return this.state === ReportStates.GENERATED;
    },
    generate() {
      const url = `${LoanClassificationReport.url()}/${this.id}.xlsx`;
      const opts = LoanClassificationReport.fetchOptions();
      const options = blobHeaders(opts);
      const fileName = this.fileName();

      return axios(url, options)
        .then(res => {
          try {
            handleDownload({
              data: res.data,
              fileName,
              mimeTypes
            });
          } catch (e) {
            Logger.log('Error reading Sasra report response', JSON.stringify(e));
            return Promise.reject(e);
          }
          return Promise.resolve();
        })
        .catch(e => {
          Logger.log('Error fetching disbursed loans data', JSON.stringify(e));
          return Promise.reject(e);
        });
    }
  }
});

export interface LoanClassificationT extends BaseModel<LoanClassificationT> {
  financialYear: string;
  startDate: string;
  endDate: string;
  state: ReportStatesT;
  isGenerated: () => boolean;
  fileName: () => string;
}
