import { useEffect, useCallback, useRef } from 'react';

import { isTest } from '@kwara/lib/src/utils';

import { widget, loadScript, freshChatQueue, Window } from './util';

type Settings = Record<string, any>;
type User = Partial<{
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  externalId: string;
  restoreId: string;
}>;
export type Widget = Record<string, object>;
type FreshChatPropTypes = {
  user?: User;
  onInit?(widget: Widget): void;
  hideChatButton?: boolean;
  [key: string]: any;
};

export function FreshChat({ user, hideChatButton, ...restProps }: FreshChatPropTypes) {
  const intervalRef = useRef(null);

  const lazyInit = (settings: Settings) => {
    widget().init(settings);
    loadScript();

    intervalRef.current = setInterval(() => {
      if ((window as Window).fcWidget) {
        try {
          freshChatQueue.dequeueAll((method: string, value: Array<string>) => {
            (window as Window).fcWidget[method](...value);
          });
        } finally {
          settings?.onInit?.();
        }
      }
    }, 1000);
  };

  const init = useCallback((settings: Settings) => {
    if (settings.onInit) {
      const tmp = settings.onInit;
      settings.onInit = () => tmp(widget());
    }

    if (!(window as Window).fcWidget) return lazyInit(settings);

    (window as Window).fcWidget.init(settings);
    settings?.onInit?.();
  }, []);

  useEffect(() => {
    if (!isTest) {
      init({
        ...user,
        ...restProps,
        config: { headerProperty: { hideChatButton } },
        host: process.env.REACT_APP_FRESHCHAT_API_HOST,
        token: process.env.REACT_APP_FRESHCHAT_API_KEY
      });
    }

    return () => {
      widget().close();
      clearInterval(intervalRef.current);
    };
  }, [hideChatButton, init, restProps, user]);

  return null;
}
