// @flow

import axios from 'axios';
import { attr, belongsTo } from 'spraypaint';
import includes from 'lodash/fp/includes';
import pick from 'lodash/fp/pick';

import { textHeaders, blobHeaders } from '@kwara/lib/src/fileDownload';
import { snakeCaseObjectKeys } from '@kwara/models/src';

import { type BranchT } from './Branch';
import { type MemberType } from '..';
import Base, { type BaseModel } from './Base';
import { UtilityTypes } from './UtilityTransaction';
import { TopupRequest } from './TopupRequest';

const { PAYMENT, RECEIPT } = UtilityTypes;

export const TillTransactionTypes = Object.freeze({
  TOPUP: 'topup',
  TILL_TO_VAULT: 'till_to_vault'
});

export const TillTransaction = Base.extend({
  static: {
    jsonapiType: 'till_transactions',

    downloadTransactionsList() {
      const url = `${TillTransaction.url()}.pdf`;
      const opts = TillTransaction.fetchOptions();
      const options = blobHeaders(opts);

      return Base.downloadFileFromUrl(url, options);
    }
  },
  attrs: {
    enteredAt: attr(),
    type: attr(),
    requestedAmount: attr(),
    approvedAmount: attr(),
    amount: attr(),
    state: attr(),
    event: attr(),
    notes: attr(),
    paymentMethod: attr(),
    name: attr(),
    invoiceNumber: attr(),
    reference: attr(),
    comment: attr(),
    transactionId: attr(),
    debitAmount: attr(),
    creditAmount: attr(),
    member: belongsTo(),
    tillSession: belongsTo(),
    tillName: attr(),
    balance: attr(),
    branch: belongsTo()
  },
  methods: {
    review() {
      const url = `${TopupRequest.url()}/${this.id}`;
      const opts = TillTransaction.fetchOptions();
      const attributes = pick(['state', 'comment', 'approvedAmount'], this);
      const data = { data: { attributes: snakeCaseObjectKeys(attributes) } };

      return axios.patch(url, data, opts);
    },
    isUtility() {
      return includes(this.type, [PAYMENT, RECEIPT]);
    },
    downloadTextReceipt() {
      const url = `${TillTransaction.url()}/${this.id}.text`;
      const opts = TillTransaction.fetchOptions();
      const options = textHeaders(opts);
      const fileName = `receipt_${this.id}`;

      return Base.downloadTextFileFromUrl(url, options, fileName);
    }
  }
});

export interface TillTransactionT extends BaseModel<TillTransactionT> {
  state: 'pending_approval' | 'approved' | 'declined';
  event: 'approve' | 'decline';
  member: MemberType;
  approvedAmount: string;
  amount: string;
  requestedAmount: string;
  transactionId: string;
  debitAmount: string;
  creditAmount: string;
  name: string;
  tillName: string;
  balance: string;
  branch: BranchT;
  isUtility: () => boolean;
}
