// @flow

import * as React from 'react';
import { Form } from 'react-final-form';
import queryString from 'query-string';

import Auth from '@kwara/models/src/lib/Auth';
import Banner from '@kwara/components/src/Banner';
import Button, { BackButton } from '@kwara/components/src/Button';
import createValidator from '@kwara/lib/src/validator';
import Empty from '@kwara/components/src/Empty';
import { snakeCaseObjectKeys } from '@kwara/models/src';
import { segmentTrack } from '@kwara/components/src/Segment';
import { SubscribedPasswordField } from '@kwara/components/src/Form';
import { Text } from '@kwara/components/src/Intl';
import { Stack, Child } from '@kwara/components/src/Stack';
import { PasswordHelper } from '@kwara/components/src/PasswordHelper';
import { passwordBaseRules, passwordConfirmBaseRules } from '@kwara/lib/src/validator/ruleSets';

type Props = {
  auth: Auth,
  location: {
    search: string
  }
};

type State = {
  success: boolean,
  error: boolean
};

type FormData = {
  password: string,
  passwordConfirmation: string
};

export class PasswordReset extends React.Component<Props, State> {
  static validator = createValidator({
    password: passwordBaseRules,
    passwordConfirmation: passwordConfirmBaseRules
  });

  static validate(values: FormData) {
    return PasswordReset.validator(values);
  }

  state = {
    success: false,
    error: false
  };

  queryParams: { t: string, order?: 'seq' } = queryString.parse(this.props.location.search);

  handleSubmit = (data: FormData) => {
    this.setState({ error: false, success: false });

    const { password, passwordConfirmation } = data;

    this.props.auth
      .passwordReset(
        snakeCaseObjectKeys({
          password,
          passwordConfirmation,
          token: this.queryParams.t
        })
      )
      .then(() => {
        this.setState({
          success: true
        });
        segmentTrack('Password Reset Successful');
      })
      .catch(() => {
        this.setState({
          error: true
        });
        segmentTrack('Password Reset Failed');
      });
  };

  render() {
    const { success, error } = this.state;
    const isOrderInSeq = this.queryParams.order === 'seq';

    return (
      <div className="flex flex-column h-100 justify-center">
        <Stack>
          <Child size="wide">
            {!success && <BackButton to={{ url: '/', routeName: 'Login' }} type="secondary" />}
            <Empty>
              <div className="pv3">
                {!success && (
                  <>
                    <h2 className="grey-500">
                      <Text id="Password.Reset.title" />
                    </h2>
                    <Form
                      onSubmit={this.handleSubmit}
                      validate={PasswordReset.validate}
                      render={({ handleSubmit, invalid, errors }) => {
                        return (
                          <form onSubmit={handleSubmit}>
                            <SubscribedPasswordField
                              margin={false}
                              name="password"
                              labelId="Password.Reset.password"
                              errorBaseId="Password.Reset"
                            />
                            {/* @order=seq: if the location query string has order=seq, it means the form 
                                fields should be organized sequentially, meaning; all input fields comes first, 
                                and then the conditions(password patterns)
                            */}
                            {!isOrderInSeq && <PasswordHelper rules={passwordBaseRules} errors={errors} />}
                            {/*a way of adding padding between the two fields*/}
                            {isOrderInSeq && <div style={{ paddingTop: '10px' }} />}
                            <SubscribedPasswordField
                              margin={false}
                              name="passwordConfirmation"
                              labelId="Password.Reset.passwordConfirm"
                            />
                            {isOrderInSeq && <PasswordHelper rules={passwordBaseRules} errors={errors} />}
                            <Button isSubmit disabled={invalid} type="primary" size="medium">
                              <Text id="Password.Reset.submit" />
                            </Button>
                          </form>
                        );
                      }}
                    />
                  </>
                )}
                {success && (
                  <>
                    <h2 className="grey-500">
                      <Text id="Password.Reset.submit.success" />
                    </h2>
                    <Button className="mt2" to="/" type="primary" glyphRightId="go">
                      <Text id="SignUp.Success.button" />
                    </Button>
                  </>
                )}
                {error && <Banner className="mt3" state="error" text={<Text id="Password.Reset.submit.error" />} />}
              </div>
            </Empty>
          </Child>
        </Stack>
      </div>
    );
  }
}
