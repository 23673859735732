import React from 'react';

export function IcnRepay() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#099250" />
      <path
        d="M24.5832 17.2497V15.583C24.5832 14.4784 23.6877 13.583 22.5832 13.583H11.9165C10.8119 13.583 9.9165 14.4784 9.9165 15.583V20.7497C9.9165 21.8542 10.8119 22.7497 11.9165 22.7497H15.2856"
        stroke="white"
        strokeWidth="1.5"
      />
      <rect x="15.4165" y="17.25" width="14.6667" height="9.16667" rx="2" stroke="white" strokeWidth="1.5" />
      <ellipse cx="22.7498" cy="21.8333" rx="1.83333" ry="1.83333" stroke="white" strokeWidth="1.5" />
    </svg>
  );
}
