import { attr, belongsTo, hasOne } from 'spraypaint';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import { ValueOf } from 'GlobalTypes';

import Base, { BaseModel } from './Base';

import { UserT } from './User';
import { TransactionChannelT } from './Transactions';
import { Bank } from './Bank';
import { BankBranch } from './BankBranch';

export const TransactionState = Object.freeze({
  PENDING: 'pending_approval',
  APPROVED: 'approved',
  IMPORTING: 'importing',
  IMPORTED: 'imported',
  FAILED: 'failed',
  REJECTED: 'rejected'
});

export type TransactionStateT = ValueOf<typeof TransactionState>;

// The transaction states relevant for the Frontend
export const TransactionUIStates = Object.freeze({
  PENDING: 'PENDING_APPROVAL',
  PROCESSING: 'PROCESSING',
  APPROVED: 'APPROVED',
  FAILED: 'FAILED',
  REJECTED: 'REJECTED'
});

export type TransactionUIStateT = ValueOf<typeof TransactionUIStates>;

// Mapping: state -> ui state
const stateMapping = {
  [TransactionState.PENDING]: TransactionUIStates.PENDING,
  [TransactionState.APPROVED]: TransactionUIStates.PROCESSING,
  [TransactionState.IMPORTING]: TransactionUIStates.PROCESSING,
  [TransactionState.IMPORTED]: TransactionUIStates.APPROVED,
  [TransactionState.FAILED]: TransactionUIStates.FAILED,
  [TransactionState.REJECTED]: TransactionUIStates.REJECTED
};

export const filterMapping = {
  [TransactionUIStates.PENDING]: TransactionState.PENDING,
  [TransactionUIStates.PROCESSING]: [TransactionState.APPROVED, TransactionState.IMPORTING],
  [TransactionUIStates.APPROVED]: [TransactionState.IMPORTED],
  [TransactionUIStates.FAILED]: [TransactionState.FAILED],
  [TransactionUIStates.REJECTED]: [TransactionState.REJECTED]
};

const Transaction = Base.extend({
  static: {
    jsonapiType: 'transactions'
  },
  attrs: {
    amount: attr(),
    balance: attr(),
    bookingDate: attr(),
    type: attr(),
    notes: attr(),
    reference: attr(),
    paymentMethod: attr(),
    glCode: attr(),
    glName: attr(),
    isGlChannel: attr(),
    chequeDrawer: attr(),
    createdAt: attr(),
    enteredAt: attr(),
    isAdjustment: attr(),
    wasAdjusted: attr(),
    isAdjustable: attr(),
    adjustmentCanBeBackdated: attr(),

    fees: attr(),

    // NOTE: this is really used only by pending transactions
    // but needs to be defined here, since when extending models
    // it seems Spraypaint loses track of the relationship definitions
    // (attr() work, while belognsTo() gets deleted)
    user: belongsTo(),
    state: attr(),

    // NB: Account is not returned by the API, but sometimes we need to
    // annotate the transaction with the account it belongs to
    account: attr(),

    bank: hasOne({ type: Bank }),
    bankBranch: hasOne({ type: BankBranch })
  },
  methods: {
    getState() {
      return get('state.current', this) || get('state', this);
    },
    isPending() {
      return this.getState() === TransactionState.PENDING;
    },
    isProcessing() {
      return includes(this.getState(), [TransactionState.APPROVED, TransactionState.IMPORTING]);
    },
    status() {
      const nativeState = this.getState();

      return stateMapping[nativeState] || nativeState;
    }
  }
});

export interface TransactionType extends BaseModel<TransactionType> {
  enteredAt: Date;
  amount: number;
  balance: number;
  bookingDate: Date;
  type: string;
  notes: string;
  reference: string;
  paymentMethod: TransactionChannelT;
  glCode: string;
  glName: string;
  isGlChannel: boolean;
  glType: () => string | null;
  isAdjustment: boolean;
  wasAdjusted: boolean;
  isAdjustable: boolean;
  adjustmentCanBeBackdated: boolean;
  user: UserT;
}

export default Transaction;
