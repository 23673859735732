// @flow

import { attr, hasOne } from 'spraypaint';

import Transaction, { TransactionStateT, TransactionUIStateT } from './Transaction';

import { ImportReviewSet, ImportReviewSetT } from './ImportReviewSet';

import { BaseModel } from './Base';

type SupportedCurrencyT = 'KES';
type SupportedTransactionsT = 'DEPOSIT' | 'WITHDRAWAL' | 'REPAYMENT' | 'TRANSFER';

type EventT = 'approve' | 'reject' | 'import';

type ImportErrorT = {
  code: number;
  details: string;
  meta: {
    attribute: string;
  };
  source: {
    pointer: string;
  };
};

export const BatchTransaction = Transaction.extend({
  static: {
    jsonapiType: 'batch_transactions',
    from(batchId: string) {
      return BatchTransaction.extend({
        static: {
          endpoint: `/batch_transaction_imports/${batchId}/batch_transactions`
        }
      });
    }
  },
  attrs: {
    name: attr(),
    memberId: attr(),
    accountId: attr(),
    amount: attr(),
    type: attr(),
    productId: attr(),
    receivingAccount: attr(),
    reference: attr(),
    glCode: attr(),
    state: attr(),
    notes: attr(),
    rowIndex: attr(),
    importResultsErrors: attr(),
    importReviewSet: hasOne({ type: ImportReviewSet })
  },
  methods: {
    formattedAmount() {
      return this.amount.cents / 100;
    }
  }
});

export interface BatchTransactionT extends BaseModel<BatchTransactionT> {
  name: string;
  memberId: string;
  accountId: string;
  amount: {
    cents: number;
    currency_iso: SupportedCurrencyT;
  };
  type: SupportedTransactionsT;
  productId: string;
  receivingAccount: string;
  reference: string;
  glCode: string;
  state: {
    current: TransactionStateT;
    permitted_events: EventT[];
  };
  rowIndex: number;
  importResultsErrors: ImportErrorT[];
  importReviewSet: ImportReviewSetT;
  formattedAmount: () => number;
  isPending: () => boolean;
  isProcessing: () => boolean;
  status: () => TransactionUIStateT;
}
