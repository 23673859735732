import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Auth from '@kwara/models/src/lib/Auth';

import { steps } from './config';
import { useSubmit } from './useSubmit';
import { Store } from '../../models/Store';
import { useStore } from '../../models/Member';
import Wizard, { getSubstepFromMatchParams } from '@kwara/components/src/Wizard/deprecated/Wizard';

type ParamsType = {
  organisationPermalink: string;
  step: string;
};

type ActivateMembershipPropTypes = {
  auth: Auth;
};

export function ActivateMembership(props: ActivateMembershipPropTypes) {
  const history = useHistory();
  const params = useParams<ParamsType>();
  const store = useStore() as Store;
  const submit = useSubmit(props.auth);

  return (
    <Wizard
      steps={steps}
      startId="identity"
      cancelReturnsTo="/overview/loans"
      analyticsId="ActivateMembership"
      titleId="ActivateMembership.title"
      baseUrl={`/memberships/activation/${params.organisationPermalink}`}
      history={history}
      initialData={{
        phone: store.profile?.phone,
        organisation_permalink: params.organisationPermalink
      }}
      onSubmit={submit}
      currentStep={params.step}
      currentSubStep={getSubstepFromMatchParams(params)}
    />
  );
}
