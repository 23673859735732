import { attr } from 'spraypaint';
import Base, { BaseModel } from '../Base';

type TransactionT = {
  date: string;
  value: string;
};
export const SavingsTransactionsStats = Base.extend({
  static: {
    jsonapiType: 'dashboard_savings_transactions_stats',
    endpoint: '/dashboard/savings_transactions_stats'
  },
  attrs: {
    totalMonthlyDepositTransactions: attr()
  }
});

export interface SavingsTransactionsStatsT extends BaseModel<SavingsTransactionsStatsT> {
  totalMonthlyDepositTransactions: TransactionT[];
}
