// @flow

import * as React from 'react';
import classnames from 'classnames';

import { Form } from 'react-final-form';

import { Link } from '@kwara/components/src/Link';
import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { ModelErrorBanner } from '@kwara/components/src/ModelErrorBanner';
import createValidator from '@kwara/lib/src/validator';
import Asset from '@kwara/components/src/Asset';

import { EnterPin } from './components/EnterPin';
import image from './computing-security.svg';
import styles from './index.module.scss';

export const Login = ({ history, length, errors, errorValues = null, submit }) => {
  return (
    <div className={classnames(styles.Background, 'h-100 w-100')}>
      <div className={classnames(styles.Container)}>
        <div className="flex justify-center">
          <img className={styles.Ilustration} alt="Kwara" src={image} />
        </div>
        <div
          className={classnames(styles.FormWrap, 'bg-white flex items-top justify-center br--right-l br--left pt4 pb5')}
        >
          <div className={classnames(styles.Form, 'flex flex-column justify-between')}>
            <div className={'pt5'}>
              <div className={classnames(styles.Header, 'flex')}>
                <div>
                  <Link onClick={() => history.push('/')}>
                    <Asset id={Asset.Glyphs.ArrowBackMobile} />
                  </Link>
                </div>
                <div className={'mobile-text-large kw-weight-bold w-100'}>
                  <Text id={'Login.title'} />
                </div>
              </div>
              <Form
                onSubmit={submit}
                validate={createValidator(EnterPin.validateConfig)}
                render={({ handleSubmit, invalid }) => {
                  return (
                    <form className="mt4" onSubmit={handleSubmit}>
                      <EnterPin history={history} length={length} />
                      {errors && (
                        <ModelErrorBanner className="mt4" errors={errors} errorValues={errorValues} state="error" />
                      )}
                      <div className="flex flex-column mt4">
                        <Button
                          aria-label="Login"
                          isSubmit
                          type="primary"
                          size="medium"
                          className="fr"
                          disabled={invalid}
                        >
                          <Text id="Login.action" />
                        </Button>
                      </div>
                    </form>
                  );
                }}
              />
            </div>
            <div className={classnames(styles.Footer)}>
              <div className={'mobile-text-medium'}>
                <Link
                  onClick={() =>
                    history.replace({
                      pathname: '/'
                    })
                  }
                  type="primary"
                  underline={false}
                  aria-label="Forgot"
                  active
                >
                  <Text id="Login.security" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
