// @flow
import * as React from 'react';
import PinInputBase from 'react-pin-input';
import { Field as FinalFormField } from 'react-final-form';
import { type TranslationId } from '@kwara/components/src/Intl';
import { Field } from '@kwara/components/src/Form';
import { PinInput } from './PinInput';
import type { PinInputProps } from './PinInput';

type SubscribedPinInputFieldProps = PinInputProps & {
  name: string,
  required?: boolean,
  titleId?: TranslationId,
  labelId?: TranslationId,
  info?: React.Node,
  infoId?: TranslationId,
  sensitiveValidation?: boolean
};

export const SubscribedPinInputField = React.forwardRef<typeof PinInputBase, SubscribedPinInputFieldProps>(
  (
    {
      length,
      initialValue,
      canHide = false,
      defaultSecret,
      onComplete,
      disabled,
      name,
      required,
      titleId,
      labelId,
      info,
      infoId,
      sensitiveValidation
    },
    ref
  ) => {
    const [showError, setShowError] = React.useState(false);
    return (
      <FinalFormField
        name={name}
        render={({ input, meta }) => {
          if (!meta.visited && initialValue) {
            input.onChange(initialValue);
          }

          const metaValidationType = sensitiveValidation ? meta.dirty : meta.touched;

          return (
            <Field
              name={input.name}
              labelId={labelId}
              titleId={titleId}
              errorCodes={meta.error}
              error={showError}
              info={info}
              infoId={infoId}
              required={required}
            >
              <PinInput
                length={length}
                initialValue={initialValue}
                canHide={canHide}
                disabled={disabled}
                onChange={input.onChange}
                onComplete={(value, index) => {
                  setShowError(!!(metaValidationType && meta.error));
                  onComplete?.(value, index);
                  input.onChange(value);
                }}
                defaultSecret={defaultSecret}
                ref={ref}
              />
            </Field>
          );
        }}
      />
    );
  }
);
