import { HttpService } from '@kwara/lib/src/services';

import apiConfig from '../config';
import auth from '../lib/auth';

/**
 * @_api used for accessing private endpoints
 */
const _api = new HttpService({
  baseURL: apiConfig.API_ROOT,
  authRequest: true,
  getToken: auth.getRawToken
});

/**
 * @$api used for accessing public endpoints
 */
const $api = new HttpService({
  baseURL: apiConfig.API_ROOT,
  authRequest: false
});

export { _api, $api };
