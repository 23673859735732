// @flow

import { attr } from 'spraypaint';
import split from 'lodash/fp/split';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import join from 'lodash/fp/join';
import capitalize from 'lodash/fp/capitalize';

import Base from './Base';

type ChangeT = {
  field_change_name: string,
  field_id: number,
  new_value: string,
  original_value: string
};

// Used for field_change_name
// i.e. EMAIL_ADDRESS to Email Address
export function userFriendlyName(snakeCaseCaps: string) {
  return pipe(split('_'), map(capitalize), join(' '))(snakeCaseCaps);
}

const Activity = Base.extend({
  static: {
    jsonapiType: 'activities',

    loans(id: string) {
      return Activity.extend({
        static: {
          endpoint: `/loans/${id}/activity`
        }
      });
    },

    member(id: string) {
      return Activity.extend({
        static: {
          endpoint: `/members/${id}/activity`
        }
      });
    },

    savings(id: string) {
      return Activity.extend({
        static: {
          endpoint: `/savings/${id}/activity`
        }
      });
    }
  },
  attrs: {
    activityType: attr(),
    fieldChanges: attr(),
    linkedSavingsId: attr(),
    notes: attr(),
    timestamp: attr(),
    objectId: attr(),
    type: attr() // type of resouce this refers to
  },
  methods: {}
});

export type ActivityType = {
  timestamp: Date,
  objectId: string,
  activityType: string,
  fieldChanges: ChangeT[],
  linkedSavingsId: string,
  notes: string,
  type: string,
  id: string
};

export default (Activity: typeof Activity);
