//@flow
import * as React from 'react';

type Props = {
  children: ?React.Node
};

export default class extends React.Component<Props> {
  componentDidMount() {
    const el = this.ref.current;

    if (el) {
      // Visually scroll this element into view
      const { y } = el.getBoundingClientRect();
      window.scrollTo({ top: y, behavior: 'smooth' });

      // Shifts focus to this element for assistive devices
      el.focus();
    }
  }

  ref = React.createRef();

  render() {
    return (
      <div className="outline-transparent" tabIndex={-1} ref={this.ref}>
        {this.props.children}
      </div>
    );
  }
}

export class ScrollToTop extends React.Component<Props> {
  componentDidMount() {
    if (window) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export const ScrollToTopOnPageChange = ({ location }: { location: { pathname: string } }) => {
  React.useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return null;
};
