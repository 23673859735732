import React from 'react';

import { detectCountry } from '@kwara/lib/src/utils/detectCountry/detectCountry';

const DEFAULT_COUNTRY_CODE = 'ke';

export function useCountryCode() {
  const [countryCode, setCountryCode] = React.useState(DEFAULT_COUNTRY_CODE);

  React.useEffect(() => {
    let isMounted = true;

    const getCountryCode = async () => {
      try {
        const code = (await detectCountry('countryCode')) as string;
        if (isMounted) {
          setCountryCode(code.toLowerCase());
        }
      } catch (_) {
        if (isMounted) {
          setCountryCode(DEFAULT_COUNTRY_CODE);
        }
      }
    };

    getCountryCode();

    return () => {
      isMounted = false;
    };
  }, []);

  return countryCode;
}
