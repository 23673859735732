import * as React from 'react';
import noop from 'lodash/noop';
import cx from 'classnames';
import { injectIntl, IntlShape } from 'react-intl';

import { composeEvents } from '@kwara/lib/src/utils/composeEvents';
import Asset, { getAssetDataUrlById } from '@kwara/components/src/Asset';
import { getTranslation, TranslationId, TranslationValues } from '@kwara/components/src/Intl';

type OptionPropTypes = React.ComponentPropsWithoutRef<'option'> & {
  intl: IntlShape;
  values?: TranslationValues;
  translationId?: TranslationId;
};

function InnerOption({ translationId, values, intl, children, ...restProps }: OptionPropTypes) {
  return <option {...restProps}>{!!translationId ? getTranslation(intl, translationId, values) : children}</option>;
}

type PrimitiveSelectPropTypes = React.ComponentPropsWithoutRef<'select'>;
type SelectBaseElement = React.ElementRef<'select'>;
interface SelectBasePropTypes extends Omit<PrimitiveSelectPropTypes, 'size'> {
  size: 'regular' | 'medium';
  error?: boolean;
  inline?: boolean;
  styles?: Record<string, string>;
  inputOnChange?: (event: React.ChangeEventHandler<HTMLSelectElement>) => void;
  inputOnBlur?: (event: React.ChangeEventHandler<HTMLSelectElement>) => void;
}

const SelectBase = React.forwardRef<SelectBaseElement, SelectBasePropTypes>(function SelectBase(props, forwardedRef) {
  const {
    error,
    size,
    styles,
    className,
    children,
    onChange,
    inline = false,
    onBlur = noop,
    inputOnChange = noop,
    inputOnBlur = noop,
    ...restProps
  } = props;

  return (
    <select
      {...restProps}
      ref={forwardedRef}
      id={props.name}
      className={cx(
        'dib',
        styles['Select'],
        styles[size],
        {
          [styles['isDisabled']]: props.disabled,
          [styles['isError']]: error,
          'w-100': !inline,
          [styles['isInline']]: inline,
          [styles['isNotInline']]: !inline
        },
        className
      )}
      style={{
        backgroundImage: inline
          ? `url(${getAssetDataUrlById(Asset.Glyphs.Dropdown)})`
          : `url(${getAssetDataUrlById(Asset.Glyphs.DoubleArrow)})`
      }}
      onChange={composeEvents(onChange, inputOnChange)}
      onBlur={composeEvents(onBlur, inputOnBlur)}
    >
      {children}
    </select>
  );
});

const OptionBase = injectIntl(InnerOption);

((SelectBase as unknown) as Record<string, React.FunctionComponent<OptionPropTypes>>).OptionBase = OptionBase;

export { SelectBase, OptionBase };
