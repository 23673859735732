import join from 'lodash/fp/join';
import pipe from 'lodash/fp/pipe';
import split from 'lodash/fp/split';
import dropRight from 'lodash/fp/dropRight';

import { toBase64 } from '@kwara/components/src/UploadWidgetUI';
import { mimeTypeToExtension, UNKNOWN } from '@kwara/lib/src/fileDownload';

export async function prepareFileForUpload(
  file: File
): Promise<{ name: string; content: string; type: string } | never> {
  const { name: rawName, type: mimeType } = file;
  const type = mimeTypeToExtension(mimeType);

  if (type === UNKNOWN) return Promise.reject([{ code: 'UI_UNSUPPORTED_EXTENSION' }]);

  // make sure the extension is dropped from the filename
  const name = pipe(split('.'), dropRight(1), join(''))(rawName);
  const content = (await toBase64(file)) as string;

  return Promise.resolve({ name, content, type });
}
