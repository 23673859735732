// @flow
import * as React from 'react';

import { SubscribedPinInputField, DEFAULT_PIN_LENGTH } from '@kwara/components/src/Form';
import { Link } from '@kwara/components/src/Link';
import { Text } from '@kwara/components/src/Intl';

export const EnterPin = ({ history, length = DEFAULT_PIN_LENGTH }) => {
  return (
    <>
      <SubscribedPinInputField name="pin" labelId={`Login.pin.title.${length}`} canHide required length={length} />
      <div className={'pb3'}>
        <Link
          onClick={() =>
            history.replace({
              pathname: '/forgotPin'
            })
          }
          type="primary"
          underline={true}
          aria-label="Forgot"
          active
        >
          <Text id="Login.pin.forgot" />
        </Link>
      </div>
    </>
  );
};

export const validateConfig = {
  pin: {
    isRequired: () => true
  }
};

EnterPin.validateConfig = validateConfig;
