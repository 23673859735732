import { isDev, isProd, isTest } from './utils';

const logLevels = ['log', 'warn', 'error'];

type LoggerFn = (...message: Array<any>) => void;
type LoggerT = Record<string, LoggerFn>;

export const Logger = new Proxy(
  {},
  {
    get: (target: { [key: string]: () => void }, method: string): ((message: any) => void) => {
      /**
       * Make sure every non log method of the target object get properly called
       */
      if (logLevels.indexOf(method) < 0) return target[method];

      /**
       * On prod we throw an error on the next tick so the error can be recorded by Rollbar.
       */
      if (isProd && method === 'error') {
        return function(...message: Array<any>) {
          globalThis.setTimeout(() => {
            throw new Error(message.join(' '));
          }, 1);
        };
      }

      /**
       * Log and Warn are ignored on any other env that is not development or test
       */

      if (isDev || isTest) {
        /* eslint-disable-next-line no-console */
        return console[method];
      }

      return () => {};
    }
  }
) as LoggerT;
