// @flow

import * as React from 'react';
import { NavLink, withRouter, type LocationShape, type Match } from 'react-router-dom';
import classnames from 'classnames';

// TODO: Investigate why importing directly from ../Route causes
//       an unrelated "Glyph is null" error
import { CurrentRouteNameConsumer } from '../Route/CurrentRouteName';
import { SimpleButton } from '../Button/SimpleButton';

import styles from './index.module.css';

export type Location = string | LocationShape;

type Props = {
  alt?: ?string,
  'aria-label'?: string,
  children: React.ChildrenArray<*>,
  className?: string,
  disabled?: boolean,
  hidden?: boolean,
  isSubmit?: boolean,
  withLinkContext?: boolean,
  match: Match,
  onClick?: (evt: SyntheticEvent<>) => void,
  onBlur?: React.FocusEvent<HTMLElement>,
  title?: string,
  to?: Location,
  role?: string,
  style?: { [string]: string },
  'data-testid'?: string,
  target: '_blank' | '_self' | '_parent' | '_top'
};

const Actionable = ({
  children,
  className,
  disabled = false,
  hidden,
  isSubmit = false,
  match,
  onClick,
  title,
  to,
  withLinkContext = true,
  alt,
  role = 'button',
  style,
  target = '_self',
  ...rest
}: Props) => {
  return to == null ? (
    <SimpleButton
      aria-label={rest['aria-label']}
      type={isSubmit ? 'submit' : 'button'}
      disabled={disabled}
      className={className}
      onClick={onClick}
      title={title}
      alt={alt}
      role={role}
      style={style}
      hidden={hidden}
      {...(rest['data-testid'] && { 'data-testid': rest['data-testid'] })}
    >
      {children}
    </SimpleButton>
  ) : (
    <CurrentRouteNameConsumer>
      {(name: string) => {
        let destination:
          | {
              pathname?: ?Location,
              state?: {
                from: {
                  url: string,
                  routeName: string
                }
              } | null
            }
          | string;

        if (typeof to === 'string') {
          destination = { pathname: to, state: null };
        } else {
          destination = { ...to, state: null };
        }

        if (withLinkContext) {
          destination.state = {
            from: {
              url: match.url,
              routeName: name
            }
          };
        }

        return (
          <NavLink
            aria-label={rest['aria-label']}
            className={classnames(className, disabled ? styles.disabled : styles.enabled)}
            disabled={disabled}
            to={destination}
            target={target}
            {...(rest['data-testid'] && { 'data-testid': rest['data-testid'] })}
          >
            {children}
          </NavLink>
        );
      }}
    </CurrentRouteNameConsumer>
  );
};

export default withRouter(Actionable);
