import * as React from 'react';

import { RecommendedOrganisationData } from 'RecommendedOrganisationTypes';

import Button from '@kwara/components/src/Button';

import { If } from '@kwara/components/src/If/If';
import { Loadable } from '@kwara/components/src/Loadable';
import { Text, Currency } from '@kwara/components/src/Intl';
import { segmentTrack } from '@kwara/components/src/Segment';
import { useBoolean } from '@kwara/lib/src/hooks/useBoolean';
import { OrganisationT } from '@kwara/models/src/models/Organisation';
import { LinearSkeletonUi } from '@kwara/components/src/Loadable/LoadingSkeleton';

import { isKCS } from '../../../lib/organisationPermalinks';
import { useNewUserStore } from '../../../models/NewUserStore';
import { SaccoDetailsCard } from '../../SaccoRecommendationFlow/components/SaccoDetailsCard';
import { useGetOrganizationDetails } from '../../SaccoRecommendationFlow/hooks/useGetOrganizationDetails';

import fallback from '../assets/IconNoLogoPlaceholder.png';
import styles from './OrganisationItem.module.scss';

const ZERO_FEE = 0;

type OrganisationItemPropTypes = {
  org: OrganisationT;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadingOrganisation: React.Dispatch<React.SetStateAction<boolean>>;
};

export function OrganisationItem({ org, setShowModal, setLoadingOrganisation }: OrganisationItemPropTypes) {
  const [shownDetails, { toggle: toggleDetails }] = useBoolean();
  const { error, data, isLoading } = useGetOrganizationDetails(org.permalink, shownDetails);
  const { storePhone: phone } = useNewUserStore();

  function onSaccoDetailsCardClickHandler({ permalink, name, joiningFee }: RecommendedOrganisationData) {
    return (onNavigate: () => void) => {
      if (parseFloat(joiningFee) > ZERO_FEE) {
        segmentTrack('Potential SACCO member', { phone, organisation: { permalink: permalink, name: name } });
        setLoadingOrganisation(true);
        onNavigate();
      } else setShowModal(true);
    };
  }

  function onDetailsClickHandler(ev: React.MouseEvent<HTMLButtonElement>) {
    ev.stopPropagation();
    toggleDetails();
  }

  return (
    <div className={styles['container']}>
      <div className={styles['container_row1']}>
        <div className="flex items-center">
          <img className="br3 b--solid b--indigo-50" src={org.logoUrl || fallback} alt="" width={56} height={56} />
          <div className="mobile-text-medium tl ml3">{org.name}</div>
        </div>

        <div className="flex items-center justify-between">
          <div className="mobile-text-small tl mobile-neutral-500 mr3">
            <Text id={`Register.Organisations.item.${isKCS(org.permalink) ? 'processingFee' : 'joiningFee'}`} />
            <Currency format="currency" value={org.joiningFee} />
          </div>
          <Button
            type="secondary"
            size="regular"
            className="primary-500"
            glyphColor="primary500"
            glyphRightId={shownDetails ? Button.Glyphs.UpMobile : Button.Glyphs.DownMobile}
            onClick={onDetailsClickHandler}
          >
            <Text id="Register.Organisations.details" />
          </Button>
        </div>
      </div>

      <If
        condition={shownDetails}
        do={
          <Loadable error={error} data={data} isLoading={isLoading} loading={<LinearSkeletonUi />}>
            {(orgDetails: RecommendedOrganisationData) => (
              <div className={styles['container_row2']}>
                <SaccoDetailsCard {...orgDetails} onClickHandler={onSaccoDetailsCardClickHandler(orgDetails)} />
              </div>
            )}
          </Loadable>
        }
      />
    </div>
  );
}
