import React from 'react';
import cx from 'classnames';
import { Text } from '@kwara/components/src/Intl';

import styles from './index.module.scss';

type OverviewLabelProps = {
  labelId: string
};
export const OverviewLabel = ({ labelId }: OverviewLabelProps) => {
  return (
    <div className={cx(styles.LabelContent, 'ph2 pv1 mobile-text-small')}>
      <Text id={labelId} />
    </div>
  );
};
