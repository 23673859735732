import { Configure } from '@kwara/components/src/pages/LoanAdd/components/Configure';

import { Summary } from './components/Summary';
import { LoanInformation } from '../LoanInformation';
import { AddGuarantorScreen } from '../AddGuarantor';
import { Documents } from '../../../../webapp-sacco/src/pages/Loan/LoanAdd/components/Documents';

export const steps = {
  information: {
    titleId: 'LoanAdd.Information.shortTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'configure'
      }
    ],
    children: [{ Component: LoanInformation }]
  },
  configure: {
    titleId: 'LoanAdd.Configure.shortTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'addGuarantor'
      }
    ],
    children: [
      {
        titleId: 'LoanAdd.Connect.Configure.title',
        Component: Configure,
        validate: Configure.validate
      }
    ]
  },
  addGuarantor: {
    titleId: 'LoanAdd.Configure.shortTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'documents'
      }
    ],
    children: [
      {
        titleId: 'LoanAdd.Connect.AddGuarantor.title',
        subtitleId: 'LoanAdd.Connect.AddGuarantor.subtitle',
        Component: AddGuarantorScreen,
        validate: AddGuarantorScreen.validate
      }
    ]
  },
  documents: {
    titleId: 'LoanAdd.Documents.shortTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'summary'
      }
    ],
    children: [
      {
        titleId: 'LoanAdd.Connect.Documents.title',
        subtitleId: 'LoanAdd.Documents.subtitle',
        Component: Documents
      }
    ]
  },
  summary: {
    titleId: 'LoanAdd.Summary.shortTitle',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'LoanAdd.Connect.Summary.title',
        subtitleId: 'LoanAdd.Connect.Summary.subtitle',
        Component: Summary
      }
    ]
  }
};
