import { attr } from 'spraypaint';
import axios from 'axios';

import { snakeCaseObjectKeys } from '@kwara/models/src';

import Base, { BaseModel } from './Base';

export const OrganisationSetting = Base.extend({
  static: {
    jsonapiType: 'organisation_settings'
  },
  attrs: {
    fullMemberDetailsRequiredForLoanApplication: attr(),
    loanAddEnabledOnConnect: attr(),
    loanRepaymentEnabledOnConnect: attr(),
    savingsDepositEnabledOnConnect: attr(),
    saccoSocietyNumber: attr(),
    sasraRegulated: attr(),
    depositTaking: attr(),
    mobilePayoutSettings: attr()
  },
  methods: {
    update() {
      const url = OrganisationSetting.url();
      const opts = OrganisationSetting.fetchOptions();
      const data = { data: { attributes: { ...snakeCaseObjectKeys(this) } } };

      return axios.put(url, data, opts).then(res => res.data);
    }
  }
});

export type MobilePayoutSettings = {
  payout_provider: string | null;
  payout_transaction_channel: string;
  transaction_amount_limit: number;
  currency: string;
};

export interface OrganisationSettingT extends BaseModel<OrganisationSettingT> {
  fullMemberDetailsRequiredForLoanApplication: boolean;
  loanAddEnabledOnConnect: boolean;
  loanRepaymentEnabledOnConnect: boolean;
  savingsDepositEnabledOnConnect: boolean;
  saccoSocietyNumber: string;
  sasraRegulated: boolean;
  depositTaking: boolean;
  mobilePayoutSettings: MobilePayoutSettings | null;
  update: () => Promise<OrganisationSettingT>;
}
