//@flow
import React, { useState } from 'react';

import { Modal } from './components/Modal';
import { ModalProvider } from './Context';

export const ModalMiddleware = props => {
  const [modal, setModal] = useState(null);

  return (
    <ModalProvider setModal={setModal}>
      {props.children}
      {modal && <Modal modal={modal} />}
    </ModalProvider>
  );
};
