// @flow

import * as React from 'react';
import { Field as FinalFormField } from 'react-final-form';
import map from 'lodash/fp/map';

import { type TranslationId } from '@kwara/components/src/Intl';
import type { InputProps, FieldProps } from '@kwara/components/src';
import { composeEvents } from '@kwara/lib/src/utils/composeEvents';
import { Radio, Field, TextArea, RadioIcon } from '@kwara/components/src/Form';

type SubscribedTextAreaProps = InputProps &
  FieldProps & {
    placeholderId?: TranslationId
  };

export const SubscribedTextArea = ({
  disabled,
  name,
  errorBaseId,
  required,
  titleId,
  info,
  infoId,
  labelId,
  values,
  placeholderId,
  margin,
  onChange
}: SubscribedTextAreaProps) => (
  <FinalFormField
    name={name}
    labelId={labelId}
    render={({ input, meta }) => {
      const { onChange: inputOnChange } = input;

      return (
        <Field
          error={meta.error && meta.touched}
          name={input.name}
          required={required}
          size="medium"
          titleId={titleId}
          labelId={labelId}
          errorBaseId={errorBaseId}
          errorCodes={meta.error}
          infoId={infoId}
          values={values}
          margin={margin}
          info={info}
        >
          <TextArea
            disabled={disabled}
            name={name}
            placeholderId={placeholderId}
            size="medium"
            {...input}
            onChange={composeEvents(inputOnChange, onChange)}
          />
        </Field>
      );
    }}
  />
);

type SubscribedRadioGroupProps = InputProps &
  FieldProps & {
    disabled?: boolean,
    flex?: boolean,
    items: Array<{
      labelId: TranslationId,
      titleId?: TranslationId,
      value: string,
      assetId?: string,
      labelValues?: { [key: string]: mixed },
      inputOnChange: (evt: { target: { value: string } }) => void
    }>
  };

export const SubscribedRadioGroup = ({
  disabled,
  flex = true,
  required,
  name,
  titleId,
  labelId,
  info,
  items,
  values
}: SubscribedRadioGroupProps) => (
  <Field name={name} size="medium" required={required} titleId={titleId} labelId={labelId} info={info} values={values}>
    <div className={flex ? 'flex' : ''}>
      {map(
        ({ labelId, titleId, value, inputOnChange, assetId, labelValues }) => (
          <FinalFormField
            key={value}
            name={name}
            type="radio"
            value={value}
            render={({ input }) => {
              if (assetId) {
                return (
                  <RadioIcon
                    disabled={disabled}
                    {...input}
                    assetId={assetId}
                    labelId={labelId}
                    inputOnChange={inputOnChange}
                  />
                );
              }
              return (
                <Radio
                  disabled={disabled}
                  {...input}
                  size="medium"
                  labelId={labelId}
                  titleId={titleId}
                  labelValues={labelValues}
                  inputOnChange={inputOnChange}
                />
              );
            }}
          />
        ),
        items
      )}
    </div>
  </Field>
);
