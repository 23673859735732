import React, { Component } from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

type Props = {
  centered?: boolean,
  className?: string,
  flex?: boolean,
  highlighted?: boolean,
  full?: boolean
};

export default class CardContainer extends Component<Props> {
  static defaultProps = {
    highlighted: false,
    className: '',
    centered: false,
    flex: true,
    full: false,
    isHoverable: false
  };

  render() {
    const base = 'bg-white';
    const type = this.props.full ? styles.isFull : styles.isNotFull;
    const hoverStyle = this.props.isHoverable ? styles.isHoverable : '';
    const flexed = this.props.flex ? 'flex-auto' : '';
    const centered = this.props.centered ? 'flex justify-center items-center' : '';
    const highlighted = this.props.highlighted ? styles.isHighlighted : 'b--transparent';

    return (
      <section
        className={classNames(styles.Card, type, base, flexed, centered, highlighted, hoverStyle, this.props.className)}
      >
        {this.props.children}
      </section>
    );
  }
}
