import * as React from 'react';
import getOr from 'lodash/fp/getOr';

import {
  allowedAttachments,
  MemberUploadWidget,
  addAttachment,
  removeAttachment,
  isDirty
} from '@kwara/components/src/MemberUploadWidget';

import { type SubStepComponentProps } from '../../../components/Wizard';

const fieldNames = allowedAttachments.typesByContext(['loans']);

const getMember = getOr({}, 'member');

export const Documents = ({ StackChild, data, onChange }: SubStepComponentProps<>) => {
  return (
    <StackChild>
      {fieldNames.map(f => (
        <MemberUploadWidget
          key={f.name}
          onChange={v => onChange({ member: addAttachment(getMember(data), v) })}
          onRemove={fileName =>
            onChange({
              member: removeAttachment(getMember(data), fileName)
            })
          }
          member={getMember(data)}
          fileName={f.name}
          checked={isDirty(getMember(data).attachments, f.name)}
          textId={`DocumentUploads.${f.label}.label`}
        />
      ))}
    </StackChild>
  );
};
