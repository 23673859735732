import { useQuery } from 'react-query';

import { RecommendedOrganisationData, RecommendedOrganisationFunction } from 'RecommendedOrganisationTypes';

import cameliseObjectKeys from '@kwara/models/src/lib/cameliseObjectKeys';

import { Organisation } from '@kwara/models/src/models/Organisation';

import { useNewUserStore } from '../../../models/NewUserStore';

export function useGetOrganizationDetails(permalink: string, enabled = true) {
  const { setStoreOrganisation } = useNewUserStore();

  const query = useQuery(['organisations_details', permalink], {
    async queryFn() {
      const response = await (Organisation as RecommendedOrganisationFunction).getOrganizationDetails(permalink);
      const data = cameliseObjectKeys(response.data.data.attributes);

      setStoreOrganisation(data);

      return data as RecommendedOrganisationData;
    },
    enabled: permalink != undefined && enabled,
    retry: false
  });

  return query;
}
