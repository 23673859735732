import * as React from 'react';
import { getCurrentDate, isAfter, formatHumanDate } from '@kwara/lib/src/dates';
import { SubscribedDatePicker } from '@kwara/components/src/Form';

export const DateRange = ({ StackChild, formProps }) => {
  const { values } = formProps;
  const { from } = values;
  return (
    <StackChild>
      <SubscribedDatePicker
        required
        name="from"
        labelId="Statement.DateRange.from.label"
        disabledDays={{ after: getCurrentDate() }}
      />
      <SubscribedDatePicker
        required
        name="to"
        labelId="Statement.DateRange.to.label"
        disabledDays={{
          before: formProps.values.from,
          after: getCurrentDate()
        }}
        errorValues={{
          fromDate: formatHumanDate(from)
        }}
      />
    </StackChild>
  );
};
DateRange.validateConfig = {
  from: {
    isRequired: () => true
  },
  to: {
    isRequired: () => true,
    custom: (to, { from }) => {
      if (!to) {
        return;
      }

      if (isAfter(from, to)) {
        return 'isBefore';
      }
    }
  }
};
