import * as React from 'react';
import get from 'lodash/fp/get';
import { useRouteMatch } from 'react-router-dom';

import { ValueOf } from 'GlobalTypes';

import { PurchaseAirtimeArg, AirtimePurchaseAttributes, StatusType } from 'AirtimePurchaseTypes';

import { createContext } from '@kwara/lib/src/utils/createContext';
import Wizard, { getSubstepFromMatchParams } from '@kwara/components/src/Wizard/deprecated/Wizard';

import { steps, analyticsId } from './config';
import { useStore } from '../../models/Member';
import { StatusModal } from './components/StatusModal';
import { BUY_AIRTIME_FOR } from '../../models/AirtimePurchases';
import { usePurchaseAirtimeValues } from './hooks/usePurchaseAirtimeValues';

type Params = { step: string; subStep: string };

type PurchaseAirtimePropTypes = { baseUrl: string; cancelReturnsTo: string };

export interface FormState extends Omit<PurchaseAirtimeArg, 'amount'>, Record<string, any> {
  amount: string; //Because all form values comes as string
  hasCashback?: boolean;
  buyingFor: ValueOf<typeof BUY_AIRTIME_FOR>;
}

type PurchaseAirtimeContextType = {
  isPolling: boolean;
  isPending: boolean;
  isSuccessful: boolean;
  status: StatusType;
  data: AirtimePurchaseAttributes;
  refetch(formState: FormState): Promise<void>;
};

const [PurchaseAirtimeContextProvider, usePurchaseAirtime] = createContext<PurchaseAirtimeContextType>(
  'PurchaseAirtimeContext'
);

function PurchaseAirtime({ baseUrl, cancelReturnsTo }: PurchaseAirtimePropTypes) {
  const match = useRouteMatch<Params>();
  const store = useStore();
  const values = usePurchaseAirtimeValues();

  return (
    <Wizard
      startId="buyingFor"
      titleId="Airtime.title"
      analyticsId={analyticsId}
      baseUrl={baseUrl}
      steps={steps}
      cancelReturnsTo={cancelReturnsTo}
      currentStep={get('params.step', match)}
      currentSubStep={getSubstepFromMatchParams(match.params)}
      initialData={{ airtimePhoneNumber: store.profile?.phone, buyingFor: BUY_AIRTIME_FOR.MY_SELF }}
      onSubmit={values.submit}
      onRenderCustomCompletionScreen={(onCancel: () => void, state: FormState) => {
        return (
          <PurchaseAirtimeContextProvider value={values}>
            <StatusModal formState={state} onCancel={onCancel} />
          </PurchaseAirtimeContextProvider>
        );
      }}
    />
  );
}

export { PurchaseAirtime, usePurchaseAirtime };
