import * as React from 'react';
import cx from 'classnames';

import { ValueOf } from 'GlobalTypes';

import Asset from '@kwara/components/src/Asset';

import { Text } from '@kwara/components/src/Intl';
import { AccessibleIcon } from '@kwara/components/src/AccessibleIcon/AccessibleIcon';

import styles from './index.module.scss';

type PrimitiveButtonPropTypes = React.ComponentPropsWithoutRef<'button'>;
type CloseIconButtonElement = React.ElementRef<'button'>;
interface CloseIconButtonPropTypes extends PrimitiveButtonPropTypes {
  labelId?: string;
  color?: ValueOf<typeof Asset.Colours>;
}

export const CloseIconButton = React.forwardRef<CloseIconButtonElement, CloseIconButtonPropTypes>(
  function CloseIconButton(props, forwardedRef) {
    const { labelId, className, color = Asset.Colours.black, ...restProps } = props;

    return (
      <AccessibleIcon label={labelId ? <Text id={labelId} /> : 'Close button'}>
        <button {...restProps} ref={forwardedRef} type="button" className={cx(styles['close-btn'], className)}>
          <Asset id={Asset.Glyphs.CrossCircle} col={color} />
        </button>
      </AccessibleIcon>
    );
  }
);
