import isEmpty from 'lodash/fp/isEmpty';

import { PotentialGuarantorType } from '@kwara/models/src/models/Guarantee';

import { TYPES } from './types';

const STATE_VALUES = {
  initial: { showAddButton: true },
  saveFirstGuarantor: { showAddGuarantor: true },
  findFirstGuarantor: { showFindGuarantorCard: true },
  guarantorsList: { showListOpen: true, showAddMoreButton: true },
  saveMoreGuarantors: { showListOpen: true, showAddGuarantor: true },
  findMoreGuarantors: { showListOpen: true, showFindGuarantorCard: true },
  findGuarantorErrorEmptyGuarantors: { showBannerError: true, showFindGuarantorCard: true },
  findGuarantorError: { showListOpen: true, showBannerError: true, showFindGuarantorCard: true }
};

export type State = Partial<{
  showListOpen: boolean;
  showListClose: boolean;
  showAddButton: boolean;
  showBannerError: boolean;
  showAddGuarantor: boolean;
  showAddMoreButton: boolean;
  showFindGuarantorCard: boolean;
}>;

type Action = {
  type: TYPES;
  payload: {
    guarantors: Array<PotentialGuarantorType>;
  };
};

function screenStatusReducer(_state: State, { type, payload }: Action): State {
  const hasEmptyGuarantors = isEmpty(payload.guarantors);

  switch (type) {
    case TYPES.ON_ADD_FIRST_GUARANTOR: {
      return STATE_VALUES.findFirstGuarantor;
    }

    case TYPES.ON_EDIT_GUARANTOR: {
      return STATE_VALUES.saveMoreGuarantors;
    }

    case TYPES.ON_FIND_GUARANTOR: {
      if (hasEmptyGuarantors) return STATE_VALUES.saveFirstGuarantor;

      return STATE_VALUES.saveMoreGuarantors;
    }

    case TYPES.ON_REMOVE_GUARANTOR: {
      if (!hasEmptyGuarantors) return STATE_VALUES.guarantorsList;

      return STATE_VALUES.initial;
    }

    case TYPES.ON_SAVE_GUARANTOR: {
      return STATE_VALUES.guarantorsList;
    }

    case TYPES.ON_FIND_MORE_GUARANTORS: {
      return STATE_VALUES.findMoreGuarantors;
    }

    case TYPES.ON_FIND_GUARANTOR_ERROR: {
      if (hasEmptyGuarantors) return STATE_VALUES.findGuarantorErrorEmptyGuarantors;

      return STATE_VALUES.findGuarantorError;
    }

    default: {
      return hasEmptyGuarantors ? STATE_VALUES.initial : STATE_VALUES.guarantorsList;
    }
  }
}

export { STATE_VALUES, screenStatusReducer };
