import * as React from 'react';
import { Field as FinalFormField } from 'react-final-form';

import type { InputProps, FieldProps } from '@kwara/components/src/Form';

import { Field, Select } from '.';

type SubscribedSelectProps = InputProps &
  FieldProps & {
    children: React.ChildrenArray<React.Element<typeof Select.Option>>
  };

export const SubscribedSelect = ({ children, disabled, name, size = 'medium' }: SubscribedSelectProps) => (
  <FinalFormField
    name={name}
    render={({ input }) => (
      <Select disabled={disabled} size={size} {...input}>
        {children}
      </Select>
    )}
  />
);

SubscribedSelect.Option = Select.Option;

type SubscribedSelectFieldProps = InputProps &
  FieldProps & {
    children: React.ChildrenArray<React.Element<typeof Select.Option>>
  };

export const SubscribedSelectField = ({
  children,
  disabled,
  errorBaseId,
  required,
  name,
  titleId,
  labelId,
  infoId,
  info,
  values,
  margin,
  size = 'medium',
  inputOnChange,
  inputOnBlur,
  showInfo
}: SubscribedSelectFieldProps) => (
  <FinalFormField
    name={name}
    render={({ input, meta }) => (
      <Field
        disabled={disabled}
        error={meta.error && meta.touched}
        name={input.name}
        size={size}
        required={required}
        titleId={titleId}
        labelId={labelId}
        errorBaseId={errorBaseId}
        errorCodes={meta.error}
        infoId={infoId}
        values={values}
        margin={margin}
        info={info}
        showInfo={showInfo}
      >
        <Select
          onBlur={input.onBlur}
          disabled={disabled}
          size={size}
          inputOnChange={inputOnChange}
          inputOnBlur={inputOnBlur}
          {...input}
        >
          {children}
        </Select>
      </Field>
    )}
  />
);

SubscribedSelectField.Option = Select.Option;

SubscribedSelect.Option = Select.Option;
