const hostname = window.location.hostname;
const storage = window.localStorage;
const STORAGE_API_KEY = 'kwara-config-api-override';

type AppName = 'sacco' | 'member';

type ApiConfig = {
  local: string;
  sandbox: string;
  production: string;
};

type ApiEnvironmentName = 'local' | 'sandbox' | 'production';

export type ConfigType = {
  appName: AppName;
  getApiOverride: () => string;
  getApiConfig: () => string;
  setApiOverride: (env: ApiEnvironmentName) => void;
  unsetApiOverride: () => void;
  API_BASE_URL: string;
  API_ROOT: string;
  env: string;
  availableApiConfig: () => ApiEnvironmentName[];
};

export default function({ api, appName }: { api: ApiConfig; appName: AppName }): ConfigType {
  const app = {
    local: {
      api: api.local,
      env: 'development'
    },
    sandbox: {
      api: api.sandbox,
      env: 'sandbox'
    },
    demo: {
      api: api.production,
      env: 'demo'
    },
    production: {
      api: api.production,
      env: 'production'
    }
  };

  const configs = {
    fallback: app.sandbox,
    localhost: app.sandbox, // It's most useful to use `sandbox` API locally
    'app.kwara.com': app.production,
    'app.sandbox.kwara.com': app.sandbox,
    'demo.kwara.com': app.demo,
    'master--demo-member.netlify.com': app.demo,
    'demo-member.netlify.com': app.demo,
    'members.kwara.com': app.production,
    'members.sandbox.kwara.com': app.sandbox,
    'develop--kwara-webapp-sandbox.netlify.com': app.sandbox,
    'master--kwara-webapp-sandbox.netlify.app': app.sandbox, // netlify automatically deploys develop to this url on every merge
    'sandbox-sacco--kwara-webapp-sandbox.netlify.app': app.sandbox,
    'sandbox-member--kwara-webapp-sandbox.netlify.app': app.sandbox
  };

  let config = configs[hostname];

  if (!config) {
    config = configs.fallback;
  }

  const getApiOverride = () => storage.getItem(STORAGE_API_KEY);
  const getApiConfig = () => {
    const override = getApiOverride();

    if (override && api[override]) {
      return api[override];
    }

    return config.api;
  };

  const API_BASE_URL = getApiConfig();

  return {
    appName,
    getApiOverride,
    getApiConfig,
    setApiOverride: (override: ApiEnvironmentName) => {
      if (api[override]) {
        storage.setItem(STORAGE_API_KEY, override);
      } else {
        throw new Error(`Can't set API override to "${override}"`);
      }
    },
    unsetApiOverride: () => {
      storage.removeItem(STORAGE_API_KEY);
    },
    availableApiConfig: (): ApiEnvironmentName[] => Object.keys(api) as ApiEnvironmentName[],
    API_BASE_URL,
    API_ROOT: API_BASE_URL,
    env: config.env
  };
}
