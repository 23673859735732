import React from 'react';
import map from 'lodash/fp/map';

import { LoanSubstepProps, LoanFormData } from 'LoanSharedTypes';

import { PeriodUnits } from '@kwara/models/src';
import { Field } from '@kwara/components/src/Form';

import { getRepaymentTerms } from './getRepaymentTerms';

import styles from '../index.module.scss';

export function RepaymentPeriodFields({ TextField, SelectField, data }: LoanSubstepProps<LoanFormData>) {
  const { fixedRepayment } = getRepaymentTerms(data);

  return (
    <Field
      name="repaymentPeriod"
      size="medium"
      margin={false}
      disabled={fixedRepayment}
      labelId="LoanAdd.Configure.Repayment.label"
      required
    >
      <div className={`${styles.textSelect} flex`}>
        <TextField disabled={fixedRepayment} size="medium" name="repaymentPeriod" />
        <SelectField disabled={fixedRepayment} name="repaymentPeriodUnit" size="medium">
          {map(
            unit => (
              <SelectField.Option key={unit} translationId={`LoanAdd.Configure.Repayment.${unit}`} value={unit} />
            ),
            PeriodUnits
          )}
        </SelectField>
      </div>
    </Field>
  );
}
