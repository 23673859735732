// @flow

import { attr, hasMany } from 'spraypaint';

import Base from './Base';
import { type RepaymentType } from './Repayment';

const Schedule = Base.extend({
  static: {
    jsonapiType: 'schedules'
  },
  attrs: {
    totalDue: attr(),
    repayments: hasMany()
  }
});

export type ScheduleType = {
  totalDue: string,
  repayments: $ReadOnlyArray<RepaymentType>
};

export default (Schedule: typeof Schedule);
