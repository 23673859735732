// @flow
import * as React from 'react';
import cx from 'classnames';
import map from 'lodash/fp/map';

import styles from './index.module.scss';

export const WizardProgressMobile = ({ currentStep, steps }) => (
  <div className={cx('flex flex-row w-100 pt3 ph2', styles.MobileProgressContainer)}>
    {map(
      step => (
        <div
          key={step}
          className={cx(styles.MobileProgressItem, {
            [styles.visited]: step + 1 <= currentStep
          })}
        ></div>
      ),
      [...Array(steps).keys()]
    )}
  </div>
);
