import React from 'react';

import { Text, Currency, Duration } from '@kwara/components/src/Intl';

import MainVector from './assets/MainVector.svg';

import { LoanStateTag, NewRequestTag } from './Components/Tag';
import { TwoColumnsDetails } from './Components/DetailsContent';
import { BinaryAction } from './Components/FooterContent';

import { LoanCardBase } from './LoanCardBase';

type GuaranteeLoanData = {
  guaranteed_amount: string;
  state: string;
  requesting_user_name: string;
  loan_details: { id: string; principal: string; duration: string; name: string };
};

const createDetailsArray = (data: GuaranteeLoanData) => {
  const { guaranteed_amount, loan_details } = data;
  const { id, principal, duration } = loan_details;

  const transId = 'LoanCard.GuaranteeLoanCard.Detail';

  return [
    { key: `${transId}.LoanID`, value: id },
    {
      key: `${transId}.InstalmentAmount`,
      value: <Currency format="currency" value={guaranteed_amount} />
    },
    {
      key: `${transId}.Principal`,
      value: <Currency format="currency" value={principal} />
    },
    {
      key: `${transId}.Duration`,
      value: (
        <>
          <Duration value={duration} />
        </>
      )
    }
  ];
};

type GuarantorshipRequestCardProps = {
  data: GuaranteeLoanData;
  isSelected: boolean;
  isNewRequest: boolean;
  onDecline: () => void;
  onAccept: () => void;
};
export const GuarantorshipRequestCard = ({
  data,
  isSelected,
  isNewRequest,
  onDecline,
  onAccept
}: GuarantorshipRequestCardProps) => {
  const { guaranteed_amount, state, requesting_user_name, loan_details } = data;

  const detailsArray = createDetailsArray(data);

  return (
    <LoanCardBase
      isSelected={isSelected}
      title={loan_details?.name}
      subtitle={<Text id="LoanCard.GuaranteeLoanCard.Subtitle.For" values={{ guarantee: requesting_user_name }} />}
      icon={String(MainVector)}
      amountLabelId={
        isNewRequest ? 'LoanCard.GuaranteeLoanCard.AmountToGuarantee' : 'LoanCard.GuaranteeLoanCard.Guarantee'
      }
      amount={guaranteed_amount}
      tagComponent={isNewRequest ? <NewRequestTag /> : <LoanStateTag loanState={state} />}
      detailsContent={<TwoColumnsDetails details={detailsArray} />}
      footerContent={
        isNewRequest && (
          <BinaryAction
            leftLabelId={'LoanCard.Action.Decline'}
            leftOnClick={onDecline}
            rightLabelId={'LoanCard.Action.Accept'}
            rightOnClick={onAccept}
          />
        )
      }
    />
  );
};
