// @flow

import { attr } from 'spraypaint';

import Base, { type BaseModel } from './Base';

export const LoanProductStandingBase = Base.extend({
  static: {
    jsonapiType: 'loan_product_standing'
  },
  attrs: {
    memberId: attr(),
    loanProductId: attr(),
    factor: attr(),
    deposits: attr(),
    savingsAccountIds: attr(),
    alreadyGuaranteedAmount: attr(),
    canGuaranteeUpTo: attr()
  }
});

export const LoanProductStanding = (memberId: string, loanProductId: string) =>
  Base.extend({
    static: {
      endpoint: `/members/${memberId}/loan_product_standing/${loanProductId}`
    }
  });

export interface LoanProductStandingT extends BaseModel<LoanProductStandingT> {
  memberId: string;
  loanProductId: string;
  factor: number;
  deposits: number;
  savingsAccountIds: string[];
  alreadyGuaranteedAmount: number;
  canGuaranteeUpTo: number;
}
