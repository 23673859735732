//@flow

import * as React from 'react';
import classnames from 'classnames';

import { Date as FormattedDate, Currency } from '@kwara/components/src/Intl';

import Collapsible from '../Collapsible';

import styles from './index.module.css';

export { default as Metadata } from './Metadata';

type Props = {
  amount: number,
  children?: ?React.Node,
  date: string,
  metadata?: React.Node,
  type?: string
};

const Transaction = ({ children, date, metadata, amount }: Props) => {
  const shouldCollapse = React.Children.count(children) > 0;
  const wrapperClasses = 'mt3 pt3 mb3 bt b--light-grey-400';

  const transaction = (
    <div className={classnames(styles.Transaction, 'flex-auto')}>
      <div className={classnames(styles.info, 'grey-400')}>
        <div className="pb2 kw-text-regular">
          <FormattedDate value={date} />
        </div>
      </div>

      <div className={classnames(styles.amount, 'kw-text-regular grey-500 no-break')}>
        <Currency format="currency" value={amount} />
      </div>

      {metadata && <div className={classnames(styles.metadata, 'kw-text-small grey-400')}>{metadata}</div>}
    </div>
  );

  return shouldCollapse ? (
    <div className={wrapperClasses}>
      <Collapsible summary={transaction}>{children}</Collapsible>
    </div>
  ) : (
    <div className={wrapperClasses}>{transaction}</div>
  );
};

export default Transaction;
