import { useState, useEffect } from 'react';

export function useAccessibleLoader(isLoading: boolean, time: number) {
  const [shownLoader, setShownLoader] = useState(false);
  const [isDoneLoading, setIsDoneLoading] = useState(false);

  /**
   * triggers loader
   */
  useEffect(() => {
    const timeout: ReturnType<typeof setTimeout> = isLoading ? setTimeout(() => setShownLoader(true), time) : null!;

    return () => clearTimeout(timeout);
  }, [isLoading, time]);

  /**
   * triggers accessible done loading
   */
  useEffect(() => {
    if (!isLoading && shownLoader) {
      setShownLoader(false);

      setIsDoneLoading(true);
    }

    const timeout: ReturnType<typeof setTimeout> = isDoneLoading
      ? setTimeout(() => setIsDoneLoading(false), 1000)
      : null!;

    return () => clearTimeout(timeout);
  }, [isDoneLoading, isLoading, shownLoader, time]);

  return { shownLoader, isDoneLoading };
}
