//@flow

import * as React from 'react';
import classnames from 'classnames';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';

import styles from './index.module.css';

const Sections = {
  history: 'history',
  profile: 'profile',
  loans: 'loans',
  savings: 'savings',
  help: 'help'
};

type Section = $Values<typeof Sections>;

type Props = {
  currentSection: Section
};

type ItemProps = {
  selected: boolean,
  to: string,
  id: Section
};

const Item = ({ selected, to, id }: ItemProps) => {
  const classes = ['ma0 pa0 pb2 dib', selected ? 'bb b--grey-500' : null, styles.Item];

  return (
    <li className={classnames(classes)}>
      <Link to={to} underline={false} active={selected}>
        <Text id={`MemberNavigation.${id}`} />
      </Link>
    </li>
  );
};

const separator = <span className="grey-300 dib ph1"> &middot; </span>;

const MemberNavigation = ({ currentSection }: Props) => (
  <ul
    className={classnames(
      'flex justify-between items-center pa0 list-reset bb b--light-grey-300',
      styles.MemberNavigation
    )}
  >
    <Item to="loans" id={Sections.loans} selected={currentSection === Sections.loans} />
    {separator}
    <Item to="savings" id={Sections.savings} selected={currentSection === Sections.savings} />
    {separator}
    <Item to="history" id={Sections.history} selected={currentSection === Sections.history} />
    {separator}
    <Item to="profile" id={Sections.profile} selected={currentSection === Sections.profile} />
    {separator}
    <li className="ma0 pa0 pb2 dib grey-400 intercom-widget pointer">
      <Button
        onClick={() => {
          if (window.fcWidget) window.fcWidget.open();
        }}
        type="primary"
        size="regular"
        aria-label="Help"
      >
        <Text id={'MemberNavigation.help'} />
      </Button>
    </li>
  </ul>
);

MemberNavigation.Sections = Sections;

export default MemberNavigation;
