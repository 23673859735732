// @flow

import { attr } from 'spraypaint';
import Base, { type BaseModel } from './Base';

export const MpesaLimit = Base.extend({
  static: {
    jsonapiType: 'mpesa_limits',
    endpoint: '/payments/mpesa/limits'
  },
  attrs: {
    maximumAccountBalance: attr(),
    maximumAmountPerTransaction: attr(),
    maximumDailyTransactionAmount: attr(),
    minimumAmountPerTransaction: attr(),
    minimumAmountWithdrawableFromAgent: attr()
  },
  methods: {}
});

export interface MpesaLimitT extends BaseModel<MpesaLimitT> {
  maximumAccountBalance: string;
  maximumAmountPerTransaction: string;
  maximumDailyTransactionAmount: string;
  minimumAmountPerTransaction: string;
  minimumAmountWithdrawableFromAgent: string;
}
