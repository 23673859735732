//@flow

import * as React from 'react';

type Props = {
  account: {
    id: string,
    product: {
      name: string
    }
  },
  label: string
};

const Metadata = ({ account, label }: Props) => {
  return (
    <span>
      {account.product.name} &middot;{' '}
      <span className="nowrap">
        {label} ID {account.id}
      </span>
    </span>
  );
};

export default Metadata;
