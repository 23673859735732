interface ReturnFunctionParam1Type extends Record<string, any> {
  id?: string;
  action?: string;
  baseExtension?: string;
}

type ReturnFunctionParam2Type = {
  asObject: boolean;
};

export function pathWithBase(base: string, isIndex?: boolean) {
  return function(
    { id, action, baseExtension, ...rest }: ReturnFunctionParam1Type = {},
    { asObject = false }: ReturnFunctionParam2Type = {} as ReturnFunctionParam2Type
  ): string | { pathname: string; search: string } {
    const restKeys = Object.keys(rest);
    const pathname = [
      '/',
      id == null && action == null && isIndex ? '' : base,
      baseExtension ? '/' + baseExtension : '',
      id ? '/' + id : '',
      action ? '/' + action : ''
    ].join('');
    const search = restKeys.length > 0 ? '?' + restKeys.map(key => `${key}=${rest[key]}`).join('&') : '';

    return asObject === true ? { pathname, search } : `${pathname}${search}`;
  };
}
