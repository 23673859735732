//@flow

import * as React from 'react';

import map from 'lodash/fp/map';
import get from 'lodash/fp/get';

import { EmploymentStatuses } from '@kwara/models/src';
import { type SubStepComponentProps } from '@kwara/components/src/Wizard/deprecated/Wizard';

import { useNewUserStore } from '../../../models/NewUserStore';
import { isKCS } from '../../../lib/organisationPermalinks';

import * as recordValidations from '../../../../../webapp-sacco/src/lib/recordValidations';
import styles from './index.module.css';

const documentTypes = [{ value: 'PASSPORT' }, { value: 'NATIONAL' }];

// TODO: this is a duplicate, should be removed if ch6907 happens
// if not, consider extracting this and its duplicate(s) and moving
// where appropriate
function isRequired(fieldName, config, formProps) {
  const fieldValidate = get(`validate.${fieldName}`, config);
  if (fieldValidate && fieldValidate.isRequired) {
    return fieldValidate.isRequired(formProps.values[fieldName], formProps.values);
  }

  return false;
}

const employerOptionsByDefault = [
  {
    labelId: 'Register.Identity.Employment.employed',
    value: EmploymentStatuses.EMPLOYED
  },
  {
    labelId: 'Register.Identity.Employment.self_employed',
    value: EmploymentStatuses.SELF_EMPLOYED
  }
];

const employerWithStudentOption = [
  ...employerOptionsByDefault,
  {
    labelId: 'Register.Identity.Employment.student',
    value: EmploymentStatuses.STUDENT
  }
];

export const Identity = ({
  SelectField,
  TextField,
  StackChild,
  config,
  formProps,
  memberEdit
}: SubStepComponentProps<>) => {
  const { storeOrganisation } = useNewUserStore();
  const organisationPermanlink = get('permalink', storeOrganisation);

  const employerOptions = isKCS(organisationPermanlink) ? employerWithStudentOption : employerOptionsByDefault;

  const name = `idDocuments[0]`;
  const idType = get(`values.idDocuments.[0].type`, formProps);

  const employmentStatus = get(`values.employmentStatus`, formProps);

  return (
    <StackChild>
      <div className="bb b--light-gray pv3" key={0}>
        <span className={styles.IdTypeSelect}>
          <SelectField disabled={memberEdit} name={`${name}.type`} required labelId={'Register.Identity.ID.Type.title'}>
            <SelectField.Option translationId="Register.Identity.ID.Type.NONE" value="" />
            {map(
              o => (
                <SelectField.Option
                  translationId={`Register.Identity.Option.${o.value}`}
                  value={o.value}
                  key={o.value}
                />
              ),
              documentTypes
            )}
          </SelectField>
        </span>

        {idType ? (
          <TextField
            disabled={memberEdit}
            name={`idDocuments[0].documentId`}
            required
            labelId="Register.Identity.ID.No.title"
            placeholderId="Register.Identity.ID.No.placeholder"
          />
        ) : null}
      </div>

      <div className="pv3">
        <TextField
          name="kraPin"
          labelId="AddMember.Identity.KRA.label"
          placeholderId="AddMember.Identity.KRA.placeholder"
          required={isRequired('kraPin', config, formProps)}
        />
      </div>
      <SelectField required name="employmentStatus" labelId="Register.Identity.Employment.title">
        <SelectField.Option translationId="Register.Identity.Select.Employment.title" value="" />
        {map(
          employment => (
            <SelectField.Option translationId={employment.labelId} value={employment.value} key={employment.value} />
          ),
          employerOptions
        )}
      </SelectField>
      <TextField
        name="profession"
        errorBaseId="AddMember.Employment.Profession"
        labelId={`AddMember.Employment.${
          employmentStatus === EmploymentStatuses.STUDENT ? 'University' : 'Profession'
        }.label`}
        placeholderId={`AddMember.Employment.${
          employmentStatus === EmploymentStatuses.STUDENT ? 'University' : 'Profession'
        }.placeholder`}
      />
    </StackChild>
  );
};

Identity.validateConfig = {
  ['idDocuments[0].type']: { isRequired: () => true },
  ['idDocuments[0].documentId']: {
    isRequired: () => true,
    ...recordValidations.identityDocumentValue
  },
  ['idDocuments[1].documentId']: {
    ...recordValidations.identityDocumentValue
  },
  employmentStatus: {
    isRequired: () => true
  },
  profession: {
    isRequired: () => true
  }
};
