// @flow

import * as React from 'react';
import { type SubStepComponentProps } from '@kwara/components/src/Wizard/deprecated/Wizard';

import { passportDocuments, nationalDocuments } from './config';
import { UploadDocument } from '../../../../components/UploadDocument';

import { isDirty } from '@kwara/components/src/MemberUploadWidget';

const PASSPORT = 'PASSPORT';

const getDocumentType = data => {
  return data.idDocuments[0]._attributes.type;
};

export function Documents(props: SubStepComponentProps<>) {
  const { StackChild, data, onChange, addData } = props;
  const documentType = getDocumentType(data);
  const documents = documentType === PASSPORT ? passportDocuments : nationalDocuments;

  return (
    <StackChild>
      <div className="flex">
        <div className="flex-auto">
          {documents.map((document, i) => (
            <UploadDocument
              close={i !== 0}
              key={document.fileName}
              data={data}
              onChange={data => {
                onChange(data);
                addData(data);
              }}
              hasFile={isDirty(data.attachments, document.fileName)}
              title={document.title}
              subtitle={document.subtitle}
              fileName={document.fileName}
              icon={document.icon}
              button={document.button}
            />
          ))}
        </div>
      </div>
    </StackChild>
  );
}
