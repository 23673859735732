import { useCallback, useState } from 'react';

import pick from 'lodash/fp/pick';
import last from 'lodash/fp/last';
import compact from 'lodash/fp/compact';
import isEmpty from 'lodash/fp/isEmpty';

import { ModelError } from 'GlobalTypes';
import { LoanFormData, GuaranteeType } from 'LoanSharedTypes';

import { segmentTrack } from '@kwara/components/src/Segment';
import { default as Loan } from '@kwara/models/src/models/Loan';
import { saveAttachments } from '@kwara/models/src/models/Attachment';
import { setRemittanceDetails, setDisbursementBankDetails } from '@kwara/lib/src/remittance';

import { Store } from '../../../models/Store';

export function useSubmit() {
  const [hasGuarantors, setHasGuarantors] = useState(false);

  const conditionallyThrowError = (condition: boolean, error: Array<ModelError>) => {
    if (condition) throw error;
  };

  const getGuarantorRequests = (guarantors: Array<GuaranteeType>) => {
    return guarantors.map(guarantor => ({
      member_encoded_key: (guarantor.member as any).memberEncodedKey,
      amount: guarantor.amount
    }));
  };

  const createLoan = useCallback(async (data: LoanFormData, store: Store) => {
    setRemittanceDetails({ remittance: data.remittance, data, bankAccounts: data.bankAccounts, store });

    const loanClassificationId = last(compact(data.classifications));
    const disbursementBankDetails = setDisbursementBankDetails(data, store);
    const guarantorshipRequests = getGuarantorRequests(data.guarantors || []);
    const loan = new Loan({
      loanClassificationId,
      guarantorshipRequests,
      disbursementBankDetails,
      amount: data.amount,
      productId: data.product.id,
      remittance: data.remittance,
      payOffLoans: data.payOffLoans,
      loanDuration: data.loanDuration,
      accountHolderId: data.member.id,
      disbursementMode: data.disbursementMode,
      repaymentPeriod: data.repaymentPeriod,
      repaymentPeriodUnit: data.repaymentPeriodUnit,
      ...pick(['purpose', 'guarantors', 'specification', 'disbursementMode'], data)
    });

    const success = await loan.save({ with: ['collaterals', { remittance: ['bankAccount', 'collectingBank.id'] }] });
    conditionallyThrowError(!success, loan.errors as Array<ModelError>);

    const uploaded = await saveAttachments(data.member, data.member.attachments);
    conditionallyThrowError(!uploaded, data.member.errors as Array<ModelError>);

    segmentTrack('Long term application flow: Create long term loan');
  }, []);

  const submit = useCallback(
    (store: Store, onSubmit?: (data: LoanFormData, store: Store) => Promise<void>) => {
      return async (data: LoanFormData) => {
        setHasGuarantors(!isEmpty(data.guarantors));

        if (onSubmit) await onSubmit(data, store);
        else await createLoan(data, store);
      };
    },
    [createLoan]
  );

  return { hasGuarantors, submit };
}
