import * as React from 'react';

import { SubStepComponentProps } from '@kwara/components/src/Wizard/latest/types/sharedTypes';

import { Picker } from '../../components/Picker';
import { Container } from '../../../../layouts';
import { useSuggestSaccoContext, FormState } from '../..';

import styles from './index.module.scss';

const CLASSNAMES = ['a', 'b', 'c', 'd', 'e', 'f'];

export function AgeGroup({ addData }: SubStepComponentProps<FormState>) {
  const { data, onSetAgeGroup, checkIsActiveAgeGroup } = useSuggestSaccoContext();

  return (
    <Container>
      <ul className={styles['list']}>
        {data.getAgeGroup().map(({ name, value }, i) => (
          <li key={i.toString()} className={styles[CLASSNAMES[i]]}>
            <Picker isActive={checkIsActiveAgeGroup(value)} onClick={onSetAgeGroup(value, addData)}>
              {name}
            </Picker>
          </li>
        ))}
      </ul>
    </Container>
  );
}

AgeGroup.validate = {
  ageGroup: {
    isRequired: () => true
  }
};
