import React from 'react';
import get from 'lodash/fp/get';
import cx from 'classnames';
import { Form } from 'react-final-form';

import zIndices from '@kwara/lib/src/zIndices';
import { type MemberType } from '@kwara/models/src';
import createValidator from '@kwara/lib/src/validator';
import { Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';
import { SubscribedTextField } from '@kwara/components/src/Form';
import { segmentTrack } from '@kwara/components/src/Segment';

import { useStore } from '../../models/Member.js';
import auth from '../../lib/auth';

import styles from './index.module.scss';

type FindGuarantorCardProps = {
  onFindGuarantor: (member?: MemberType) => void,
  onErrorFindingGuarantor: () => void
};

export const FindGuarantorCard = ({ onFindGuarantor, onErrorFindingGuarantor }: FindGuarantorCardProps) => {
  const store = useStore();
  const memberId = get('member.id', store);

  const isCurrentMember = (formMemberId: string) => memberId === formMemberId;

  const onClick = async (values: { guarantorNumber: string, guarantorPhone: string }) => {
    const { guarantorNumber, guarantorPhone } = values;
    segmentTrack('Add guarantors: Click Find Guarantor');
    let member = null;
    //@todo remove %2b BE needs to fix it.
    const phoneNumber = guarantorPhone.replace('+', '%2b');
    try {
      const res = await auth.findPotentialGuarantor({ memberId: guarantorNumber, phoneNumber });
      const { id, attributes } = res.data;
      const { full_name, member_encoded_key } = attributes;
      member = { id: id, fullName: () => full_name, phoneNumber: guarantorPhone, memberEncodedKey: member_encoded_key };
      onFindGuarantor(member);
    } catch ({ errors }) {
      segmentTrack('Add guarantors: Error finding a guarantor', { errors });
      onErrorFindingGuarantor();
    }
  };

  return (
    <div className={cx(styles.Container, 'mb3')}>
      <Form
        onSubmit={() => {}}
        validate={createValidator(findGuarantorValidateConfig)}
        render={({ invalid, values }) => {
          return (
            <div className={styles.Content}>
              <div className={styles.Header}>
                <div className={cx(styles.Title, 'mobile-text-regular flex items-center justify-between')}>
                  <span className={styles.Line} />
                  <span className="ph1">
                    <Text id={'AddGuarantorCard.title'} />
                  </span>
                  <span className={styles.Line} />
                </div>
              </div>
              <div className={cx(styles.Body, `flex-1 hidden ${zIndices.OverlayMenus}`)}>
                <div className={cx(styles.Subtitle, 'mobile-text-small')}>
                  <SubscribedTextField
                    name="guarantorNumber"
                    labelId="AddGuarantorCard.guarantorsMemberNumber"
                    className="bg-white"
                    placeholderId="AddGuarantorCard.guarantorsMemberNumber.placeholder"
                    size="medium"
                    sensitiveValidation
                    required
                  />
                  <SubscribedTextField
                    name="guarantorPhone"
                    labelId="AddGuarantorCard.guarantorsPhoneNumber"
                    className="bg-white"
                    type="phone"
                    placeholderId="AddGuarantorCard.guarantorsPhoneNumber.placeholder"
                    size="medium"
                    sensitiveValidation
                    required
                  />
                </div>
              </div>
              <div className={cx(styles.Footer, `${zIndices.OverlayMenus}`)}>
                <Button
                  aria-label="Search"
                  type="secondary"
                  size="regular"
                  glyphLeftId={Button.Glyphs.Search}
                  className="w-100 flex justify-center"
                  onClick={() => {
                    onClick({ guarantorNumber: values.guarantorNumber, guarantorPhone: values.guarantorPhone });
                  }}
                  disabled={invalid || isCurrentMember(values.guarantorNumber)}
                >
                  <Text id={'AddGuarantorCard.findGuarantor'} />
                </Button>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export const findGuarantorValidateConfig = {
  guarantorNumber: {
    isRequired: () => true
  },
  guarantorPhone: {
    isRequired: () => true,
    phoneNumber: true
  }
};
