import React, { useEffect } from 'react';

import { appName } from '@kwara/lib/src/utils';
import { If } from '@kwara/components/src/If/If';

import { CompletionBase, Types, SharedCompletionProps } from './CompletionBase';

import stylesCore from './CompletionCore.module.scss';
import stylesMobile from './CompletionMobile.module.scss';

function CompletionCore(props: SharedCompletionProps) {
  return (
    <CompletionBase
      styles={stylesCore}
      textFontClasses={{ titleClassName: 'kw-text-big', subtitleClassName: 'kw-text-regular' }}
      {...props}
    />
  );
}

function CompletionMobile(props: SharedCompletionProps) {
  return (
    <CompletionBase
      loaderColor={props.type === Types.good ? 'white' : 'indigo500'}
      styles={stylesMobile}
      textFontClasses={{ titleClassName: 'mobile-text-large', subtitleClassName: 'mobile-text-medium' }}
      {...props}
    />
  );
}

function Completion(props: SharedCompletionProps) {
  return <If condition={appName.isMember} do={<CompletionMobile {...props} />} else={<CompletionCore {...props} />} />;
}

interface AutoDismissableCompletionProps extends SharedCompletionProps {
  autoconfirm?: boolean;
  autoconfirmTimeoutSecs?: number;
}

function AutoDismissableCompletion(props: AutoDismissableCompletionProps) {
  const { onConfirm, autoconfirm = false, autoconfirmTimeoutSecs = 2 } = props;

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;
    const shouldAutoConfirm = onConfirm != undefined && autoconfirm && typeof autoconfirmTimeoutSecs === 'number';

    if (shouldAutoConfirm) timeoutId = setTimeout(onConfirm, autoconfirmTimeoutSecs * 1000);

    return () => clearTimeout(timeoutId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoconfirm, autoconfirmTimeoutSecs]);

  return <Completion {...props} />;
}

AutoDismissableCompletion.Types = Types;

export { AutoDismissableCompletion, Completion, Types };
