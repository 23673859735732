import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import { HttpErrorObject } from 'GlobalTypes';

import Asset from '@kwara/components/src/Asset';
import Banner from '@kwara/components/src/Banner';

import { Link } from '@kwara/components/src';
import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import { OrganisationT } from '@kwara/models/src/models/Organisation';

import { SearchResult } from '../components/SearchResult';
import { SearchTextField } from '../../../components/SearchTextField';

type SearchableOrganisationsListPropTypes = {
  organisations: OrganisationT[];
  errors: HttpErrorObject;
};

export function SearchableOrganisationsList({ organisations, errors }: SearchableOrganisationsListPropTypes) {
  const [searchValue, setSearchValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loadingOrganisation, setLoadingOrganisation] = useState(false);
  const history = useHistory();

  return (
    <Loadable error={errors} isLoading={loadingOrganisation} errorBannerProps={{ state: 'error' }}>
      <div className="bg-white ph3 pv3 h-100">
        <div className={cx('flex flex-column items-left justify-end')}>
          <div>
            <div className="flex justify-between">
              <div className="mobile-text-x-large kw-weight-bold">
                <Text id="Register.Organisations.title" />
              </div>

              <div>
                <Link onClick={() => history.push('/')}>
                  <Asset id={Asset.Glyphs.CrossCircle} col={Asset.Colours.black} />
                </Link>
              </div>
            </div>

            <div className="mobile-text-medium mobile-neutral-600 mv3">
              <Text id="Register.Organisations.subtitle" />
            </div>
          </div>

          <SearchTextField
            name="search"
            placeholderId="Register.Organisations.Search.placeholder"
            value={searchValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
          />
        </div>

        <div className="pb3">
          <SearchResult
            organisations={organisations}
            showModal={showModal}
            searchValue={searchValue}
            setShowModal={setShowModal}
            setLoadingOrganisation={setLoadingOrganisation}
            hideModal={() => setShowModal(false)}
          />

          <Banner
            state="warning"
            className="mv3 pa0"
            text={
              <p className="pa0 ma0 flex items-center">
                <span>
                  <Asset id={Asset.Glyphs.Info} col="black" />
                </span>

                <div className="pa0 pl2 ma0">
                  <Text id="Register.Organisations.RecommendationBanner.message:1" />
                  <br />
                  <Text id="Register.Organisations.RecommendationBanner.message:2" />
                  <Link to="/financialProducts/financialProducts">
                    <Text id="Register.Organisations.RecommendationBanner.link" />
                  </Link>
                </div>
              </p>
            }
          />
        </div>
      </div>
    </Loadable>
  );
}
