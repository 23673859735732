import { AgeGroup } from './pages/AgeGroup';
import { IncomeRange } from './pages/IncomeRange';
import { FinancialProducts } from './pages/FinancialProducts';

export const steps = {
  financialProducts: {
    titleId: 'SaccoRecommendationFlow.FinancialProducts.title',
    subtitleId: 'SaccoRecommendationFlow.FinancialProducts.subTitle',
    actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'incomeRange' }],
    Component: FinancialProducts,
    validate: FinancialProducts.validate
  },
  incomeRange: {
    titleId: 'SaccoRecommendationFlow.IncomeRange.title',
    subtitleId: 'SaccoRecommendationFlow.IncomeRange.subTitle',
    actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'ageGroup' }],
    Component: IncomeRange,
    validate: IncomeRange.validate
  },
  ageGroup: {
    titleId: 'SaccoRecommendationFlow.AgeGroup.title',
    actions: [{ appearsAs: 'submit', behavesAs: 'complete' }],
    Component: AgeGroup,
    validate: AgeGroup.validate
  }
};
