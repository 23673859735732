import { attr, hasOne } from 'spraypaint';

import Base, { type BaseModel } from './Base';
import { BankAccount, type BankAccountT } from './BankAccount';
import { Bank, type BankT } from './Bank';

export const fields = {
  remittance: {
    mode: 'remittance.method',
    amount: 'remittance.amount',
    bankAccount: 'remittance.bankAccount.id',
    bank: 'remittance.bankAccount.bankBranch.bank.id',
    branch: 'remittance.bankAccount.bankBranch.id',
    account: 'remittance.bankAccount.accountNumber',
    collectingBank: 'remittance.collectingBank.id',
    startDate: 'remittance.startDate',
    frequency: 'remittance.frequency',
    collectionDate: 'remittance.collectionDay'
  }
};

export const Remittance = Base.extend({
  attrs: {
    method: attr(),
    amount: attr(),
    frequency: attr(),
    collectionDay: attr(),
    productName: attr(),
    mambuId: attr(),
    accountState: attr(),
    accountId: attr(),
    startDate: attr(),
    bankAccount: hasOne({ type: BankAccount }),
    collectingBank: hasOne({ type: Bank })
  }
});

export const LoanRemittance = Remittance.extend({
  static: {
    jsonapiType: 'loan_remittances'
  }
});

export const SavingRemittance = Remittance.extend({
  static: {
    jsonapiType: 'savings_remittances'
  }
});

export interface RemittanceT extends BaseModel<RemittanceT> {
  method: string;
  amount: string;
  frequency: string;
  collectionDay: string;
  productName: string;
  mambuId: string;
  accountId: string;
  accountState: string;
  startDate: string;
  bankAccount?: BankAccountT;
  collectingBank?: BankT;
}

export type LoanRemittanceT = RemittanceT;
export type SavingRemittanceT = RemittanceT;
