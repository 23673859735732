import * as React from 'react';
import cx from 'classnames';

import zIndices from '@kwara/lib/src/zIndices';

import { If } from '@kwara/components/src/If/If';

import styles from './index.module.scss';

type PrimitiveSpanPropTypes = React.ComponentPropsWithoutRef<'span'>;
type InfoPanelElement = React.ElementRef<'span'>;

export const InfoPanel = React.forwardRef<InfoPanelElement, PrimitiveSpanPropTypes>(function InfoPanel(
  { children, className, ...restProps },
  forwardedRef
) {
  return (
    <If
      condition={children != undefined}
      do={
        <span {...restProps} ref={forwardedRef} className={styles['wrapper']}>
          <span>
            <span className={cx('ba br-100', styles['icon'])}>i</span>
          </span>
          <div className={cx('bg-white br3', styles['info-panel'], zIndices.OverlayMenus, className)}>{children}</div>
        </span>
      }
    />
  );
});
