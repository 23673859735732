import React from 'react';
import { Text } from '@kwara/components/src/Intl';

interface CommonHeaderProps {
  labelIdLeft: string;
  labelIdRight: string;
}
export function CommonHeader({ labelIdLeft, labelIdRight }: CommonHeaderProps) {
  return (
    <div className="flex mv1 items-center mobile-text-small">
      <div className="w-50 ml1 ">
        <Text id={labelIdLeft} />
      </div>
      <div className="ml1 w-50">
        <Text id={labelIdRight} />
      </div>
    </div>
  );
}
