// @flow

import * as React from 'react';
import cx from 'classnames';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import { useMemberLoans } from '@kwara/models/src/models/request/hooks';
import { segmentTrack } from '@kwara/components/src/Segment';

import { useStore } from '../../models/Member';
import { LoansList } from './LoansList';
import { Guaranteed } from './Guaranteed';
import { CardLoader } from '../../components/CardLoader';
import styles from './index.module.scss';

const loanIncludes = ['product', 'transactions', 'repayments'];

export const Loans = () => {
  const store = useStore();
  const response = useMemberLoans(store.memberId, loanIncludes);

  return (
    <Loadable {...response} loading={<CardLoader />}>
      {loans => (
        <>
          {store.isLoanAddEnabled ? (
            <div className={cx(styles.Button, 'flex flex-column mt4')}>
              <Button
                type="primary"
                size="large"
                to="/loans/select"
                aria-label="LoanAdd"
                onClick={() => {
                  segmentTrack('New Loan button clicked');
                }}
              >
                <Text id="Loans.newLoanButtonTitle" />
              </Button>
            </div>
          ) : null}
          <LoansList loans={loans} />
          <Guaranteed />
        </>
      )}
    </Loadable>
  );
};
