import * as React from 'react';

type PrimitiveSvgProps = React.ComponentPropsWithoutRef<'svg'>;
type ArrowElement = React.ElementRef<'svg'>;
interface ArrowPropTypes extends PrimitiveSvgProps {}

export const IconArrow = React.forwardRef<ArrowElement, ArrowPropTypes>(function IconArrow(
  { fill, width, height, ...restProps },
  forwardedRef
) {
  return (
    <svg
      {...restProps}
      ref={forwardedRef}
      role="img"
      width={width}
      height={height}
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.71 1.20986C13.617 1.11613 13.5064 1.04174 13.3846 0.990969C13.2627 0.9402 13.132 0.914062 13 0.914062C12.868 0.914062 12.7373 0.9402 12.6154 0.990969C12.4936 1.04174 12.383 1.11613 12.29 1.20986L7.71 5.78985C7.61704 5.88358 7.50644 5.95797 7.38458 6.00874C7.26272 6.05951 7.13202 6.08565 7 6.08565C6.86799 6.08565 6.73729 6.05951 6.61543 6.00874C6.49357 5.95797 6.38297 5.88358 6.29 5.78985L1.71 1.20986C1.61704 1.11613 1.50644 1.04174 1.38458 0.990969C1.26272 0.9402 1.13201 0.914062 1 0.914062C0.867988 0.914062 0.737282 0.9402 0.615423 0.990969C0.493563 1.04174 0.382962 1.11613 0.289999 1.20986C0.103748 1.39722 -0.000793457 1.65067 -0.000793457 1.91486C-0.000793457 2.17904 0.103748 2.4325 0.289999 2.61986L4.88 7.20985C5.4425 7.77165 6.205 8.08721 7 8.08721C7.79501 8.08721 8.5575 7.77165 9.12 7.20985L13.71 2.61986C13.8963 2.4325 14.0008 2.17904 14.0008 1.91486C14.0008 1.65067 13.8963 1.39722 13.71 1.20986Z"
        fill={fill}
      />
    </svg>
  );
});

IconArrow.defaultProps = {
  fill: '#475569',
  width: '14',
  height: '9'
};
