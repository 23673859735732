import { CreatePIN } from './components/CreatePIN';
import { EnterOTP } from './components/EnterOTP';
import { Identity } from './components/Identity';
import { Referral } from './components/Referral';

export const steps = {
  identity: {
    titleId: 'SecureFourPinUpdate.Identity.title',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'nextWithPromise',
        destination: 'enterOtp',
        onNext: Identity.verify
      }
    ],
    children: [
      {
        titleId: 'SecureFourPinUpdate.Identity.title',
        subtitleId: 'SecureFourPinUpdate.Identity.subtitle',
        Component: Identity,
        validate: Identity.validateConfig
      }
    ]
  },
  enterOtp: {
    titleId: 'SecureFourPinUpdate.EnterOTP.title',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'nextWithPromise',
        destination: 'createPIN',
        onNext: EnterOTP.verify
      }
    ],
    children: [
      {
        titleId: 'SecureFourPinUpdate.EnterOTP.title',
        Component: EnterOTP,
        validate: EnterOTP.validateConfig
      }
    ]
  },
  createPIN: {
    titleId: 'CreatePin.title',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'referral'
      }
    ],
    children: [
      {
        titleId: 'CreatePin.title',
        Component: CreatePIN,
        validate: CreatePIN.validate
      }
    ]
  },
  referral: {
    titleId: 'Referral.title',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'Referral.title',
        Component: Referral,
        validate: Referral.validate
      }
    ]
  }
};
