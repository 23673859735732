import { attr } from 'spraypaint';
import Base, { BaseModel } from '../Base';

type DisbursementStatT = {
  date: string;
  value: string;
};
export const LoanDisbursementsStats = Base.extend({
  static: {
    jsonapiType: 'dashboard_loan_disbursements_stats',
    endpoint: '/dashboard/loan_disbursements_stats'
  },
  attrs: {
    totalMonthlyLoanDisbursements: attr()
  }
});

export interface LoanDisbursementsStatsT extends BaseModel<LoanDisbursementsStatsT> {
  totalMonthlyLoanDisbursements: DisbursementStatT[];
}
