const durationMonths = (duration: string, units: 'MONTHS' | 'DAYS' | 'WEEKS') => {
  switch (units) {
    case 'MONTHS':
      return Number(duration);
    case 'WEEKS':
      return Number(duration) / 4.28;
    case 'DAYS':
      return Number(duration) / 30;
    default:
      throw 'Unit must be either: MONTHS, DAYS, WEEKS';
  }
};

export function avgMonthlyPayment(data = {}) {
  const { amount, loanDuration, repaymentPeriodUnit } = data;

  return Number(amount) / durationMonths(loanDuration, repaymentPeriodUnit);
}
