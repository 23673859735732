import { attr, hasOne } from 'spraypaint';

import Base, { BaseModel } from './Base';

export const LoanClassification = Base.extend({
  static: {
    jsonapiType: 'loan_classifications'
  },
  attrs: {
    name: attr(),
    code: attr(),
    parent: hasOne()
  }
});

export interface LoanClassificationT extends BaseModel<LoanClassificationT> {
  name: string;
  code: string;
  parent: LoanClassificationT;
}
