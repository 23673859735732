import { hasMany } from 'spraypaint';
import queryString from 'query-string';

import { InferredModel } from 'GlobalTypes';

import Base from '../../Base';

import { ProfitLossRowT } from './Row';
import { downloadExcel } from '../utils';

export type DownloadXlsxParams = {
  start_date: string;
  end_date: string;
};

export const ProfitLoss = Base.extend({
  static: {
    jsonapiType: 'abstract_profit_loss_reports',
    endpoint: '/accounting/reports/profit_loss',
    downloadXlsx(params: DownloadXlsxParams) {
      const queries = queryString.stringify(params);
      const url = `${ProfitLoss.url()}.xlsx?${queries}`;
      const opts = ProfitLoss.fetchOptions();
      const fileName = `profit_loss.xlsx`;

      return downloadExcel({ fileName, url, opts });
    }
  },
  attrs: {
    rows: hasMany('abstract_profit_loss_report_rows')
  }
});

export interface ProfitLossT extends Omit<InferredModel<typeof ProfitLoss>, 'errors'> {
  rows: ProfitLossRowT[];
}
