import { attr } from 'spraypaint';

import Base, { type BaseModel } from './Base';

export const LoanProductConfig = Base.extend({
  static: {
    jsonapiType: 'loan_product_configs'
  },
  attrs: {
    guarantorMultiplierFactor: attr(),
    guarantorMultiplierSavingsProductId: attr()
  }
});

export interface LoanProductConfigT extends BaseModel<LoanProductConfigT> {
  guarantorMultiplierFactor: number;
  guarantorMultiplierSavingsProductId: string;
}
