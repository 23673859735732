// @flow

import { attr } from 'spraypaint';
import Base, { type BaseModel } from './Base';

export const C2BTransactionStates = Object.freeze({
  PENDING: 'pending',
  IN_PROGRESS: 'in_progress',
  CREATED: 'created',
  FAILED: 'failed',
  SUSPENSE: 'suspense'
});
export type C2BTransactionStatesT = $Values<typeof C2BTransactionStates>;

export const C2BTransaction = Base.extend({
  static: {
    jsonapiType: 'c2_b_transactions',
    endpoint: '/mpesa/c2_b_transactions'
  },
  attrs: {
    state: attr(),
    amount: attr(),
    type: attr(),
    receivedAt: attr(),
    account: attr(),
    reference: attr()
  }
});

export interface C2BTransactionT extends BaseModel<C2BTransactionT> {
  state: C2BTransactionStatesT;
  amount: number;
  type: string;
  receivedAt: string;
  account: string;
  reference: string;
}
