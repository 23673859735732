import * as React from 'react';
import { ERRORS } from '@kwara/lib/src/validator';

export const Email = ({ StackChild, TextField }) => {
  return (
    <StackChild>
      <TextField
        name="email"
        labelId="Statement.Email.label"
        placeholderId="Statement.Email.placeholder"
        sensitiveValidation
        required
      />

      <TextField
        name="confirmEmail"
        labelId="Statement.Confirm.Email.label"
        placeholderId="Statement.Email.placeholder"
        sensitiveValidation
        required
      />
    </StackChild>
  );
};

Email.validateConfig = {
  email: {
    email: true,
    isRequired: () => true
  },
  confirmEmail: {
    email: true,
    isRequired: () => true,
    custom: (value, { email }) => (value !== email ? ERRORS.emailMismatch : null)
  }
};
