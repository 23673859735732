import { useState } from 'react';

import { PurchaseAirtimeArg } from 'AirtimePurchaseTypes';

import { segmentTrack } from '@kwara/components/src/Segment';

import { httpErrorHandler } from '../../../services';
import { AirtimePurchase } from '../../../models/AirtimePurchases';

export function usePostPurchaseAirtime() {
  const [transactionId, setTransactionId] = useState<string | null>(null);

  async function submit(data: PurchaseAirtimeArg & Record<string, any>) {
    segmentTrack('Airtime Buy Button Clicked');

    try {
      const { airtimePhoneNumber, amount, paymentPhoneNumber } = data;
      const response = await AirtimePurchase.purchaseAirtime({
        airtimePhoneNumber,
        amount,
        paymentPhoneNumber
      });

      setTransactionId(response.data.data.id);
    } catch (error) {
      throw httpErrorHandler.generateError(error);
    }
  }

  return { transactionId, submit };
}
