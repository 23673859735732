import { useMemo, useCallback } from 'react';

import { FormState } from '..';
import { STATUS } from '../../../models/AirtimePurchases';
import { usePostPurchaseAirtime } from './usePostPurchaseAirtime';
import { usePollPurchasedAirtime } from './usePollPurchasedAirtime';

export function usePurchaseAirtimeValues() {
  const { transactionId, submit } = usePostPurchaseAirtime();
  const { isPolling, status, data, refetch } = usePollPurchasedAirtime(transactionId);

  const isPending = status === STATUS.PENDING || status === STATUS.PROCESSING;
  const isSuccessful = status === STATUS.SUCCEEDED;

  const onRefetch = useCallback(
    async (formState: FormState) => {
      await submit(formState);

      refetch();
    },
    [refetch, submit]
  );

  const values = useMemo(() => ({ isPolling, isPending, isSuccessful, status, data, refetch: onRefetch, submit }), [
    data,
    isPending,
    isPolling,
    isSuccessful,
    onRefetch,
    status,
    submit
  ]);

  return values;
}
