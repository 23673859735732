//@flow

import React from 'react';
import posed from 'react-pose';
import cx from 'classnames';
import zIndices from '@kwara/lib/src/zIndices';

import { useNotificationContext } from '@kwara/components/src/Notification/NotificationProvider';

import { MobileBanner } from '../MobileBanner';

export const MobileNotificationBanner = () => {
  const { current, dismiss } = useNotificationContext();

  const Container = posed.div({
    visible: { y: -50 },
    hidden: { y: 0 }
  });

  return (
    <>
      {current && (
        <Container
          className={cx('absolute flex items-center bottom-0 left-0 right-0 mh3', zIndices.Modal)}
          initialPose="hidden"
          pose="visible"
        >
          <MobileBanner text={current.message} state={current.level} onDismiss={dismiss} />
        </Container>
      )}
    </>
  );
};
