import { InferredModel } from 'GlobalTypes';
import { attr } from 'spraypaint';

import Base from '../../Base';

export const GlReportRow = Base.extend({
  static: {
    jsonapiType: 'abstract_general_ledger_report_rows'
  },
  attrs: {
    creditAmount: attr(),
    debitAmount: attr(),
    runningBalance: attr(),
    entryDate: attr(),
    description: attr()
  }
});

export interface GlReportRowT extends Omit<InferredModel<typeof GlReportRow>, 'errors'> {
  creditAmount: number;
  debitAmount: number;
  runningBalance: number;
  entryDate: Date;
  description: string;
}
