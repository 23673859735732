// @flow

import { Identity } from './components/Identity';
import { About } from './components/About';
import { Documents } from './components/Documents';
import { NextOfKin } from './components/NextOfKin';
import { Summary } from './components/Summary';

export const steps = () => ({
  about: {
    titleId: 'AddMember.Step.about',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'identity'
      }
    ],
    children: [
      {
        titleId: 'AddMember.Connect.Personal.title',
        Component: About,
        validate: About.validate
      }
    ]
  },
  identity: {
    titleId: 'AddMember.Step.identity',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'documents'
      }
    ],
    children: [
      {
        titleId: 'Register.Identity.title',
        Component: Identity,
        validate: Identity.validateConfig
      }
    ]
  },
  documents: {
    titleId: 'AddMember.Step.documents',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'nextOfKin'
      }
    ],
    children: [
      {
        titleId: 'AddMember.Connect.Documents.title',
        subtitleId: 'AddMember.Connect.Documents.subtitle',
        Component: Documents
      }
    ]
  },
  nextOfKin: {
    titleId: 'AddMember.Step.kin',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'summary'
      }
    ],
    children: [
      {
        titleId: 'AddMember.Connect.Kin.title',
        subtitleId: 'AddMember.Connect.Kin.subtitle',
        Component: NextOfKin,
        validate: NextOfKin.validate
      }
    ]
  },
  summary: {
    titleId: 'AddMember.Step.summary',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'Register.Summary.title',
        subtitleId: 'Register.Summary.subtitle',
        Component: Summary,
        validate: Summary.validate,
        progress: false
      }
    ]
  }
});
