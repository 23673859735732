import React from 'react';
import get from 'lodash/fp/get';

import { LoanSubstepProps, LoanFormData } from 'LoanSharedTypes';

import { appName } from '@kwara/lib/src/utils';
import { fields as loanFields } from '@kwara/models/src/models/Loan';
import { SubscribedPaymentSelectField } from '@kwara/components/src/Form';
import { getTransactionTypes, contexts, TransactionChannels } from '@kwara/models/src/models/Transactions';

import { RepaymentPeriodFields, DurationField, getRepaymentTerms } from '.';

import styles from '../index.module.scss';

const fields = loanFields.disbursement;

export function LoanTermsFields(props: LoanSubstepProps<LoanFormData>) {
  const isNotInstantLoan = !get('product.instantLoan', props.data);
  const shownDisbursementMethod = appName.isMember && isNotInstantLoan;

  return (
    <div className={`pt4 bt b--light-grey-400`}>
      {!appName.isMember && (
        <>
          <h2>Terms</h2>
          <RepaymentPeriodFields {...props} />
        </>
      )}

      {isNotInstantLoan && (
        <div className={`${styles.row} pb4`}>
          <DurationField {...props} />
        </div>
      )}

      {shownDisbursementMethod && (
        <div className="flex-auto">
          <SubscribedPaymentSelectField
            labelId="LoanAdd.Disbursement.Method.label"
            config={{
              bank: fields.bank,
              bankBranch: fields.branch,
              accountNumber: fields.account,
              showBanksFieldsFor: [TransactionChannels.bankTransfer],
              methods: getTransactionTypes(contexts.LoanDisbursement).values.filter(
                ({ value }) => value !== TransactionChannels.mobileMpesaInstant
              )
            }}
            name={fields.mode}
            required
          />
        </div>
      )}
    </div>
  );
}

LoanTermsFields.validate = {
  repaymentPeriod: {
    isRequired: () => true,
    positiveInteger: true
  },
  loanDuration: {
    isRequired: (_: string, allData: LoanFormData) => !get('product.instantLoan', allData),
    custom: (target: string, allData: LoanFormData) => {
      const { repaymentPeriod } = allData;
      const { maxDurationValue } = getRepaymentTerms(allData);

      if (Number(target) > maxDurationValue) return 'MaxDurationExceeded';

      if (!(Number(repaymentPeriod) > 0)) return 'NaNError';

      if (Number(target) < Number(repaymentPeriod)) return 'extendLoanDuration';

      return null;
    },
    positiveInteger: true
  },
  [fields.mode]: {
    isRequired: (_: string, allData: LoanFormData) => appName.isMember && !get('product.instantLoan', allData)
  }
};
