import React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { segmentTrack } from '@kwara/components/src/Segment';

import { ActionType } from './hooks/useQuickAction';

import styles from './QuickAction.module.scss';

type QuickActionPropTypes = ActionType;

export function QuickAction({ icon: Icon, name, labelId, onClick }: QuickActionPropTypes) {
  function onRedirect() {
    segmentTrack(`Quick link ${name} clicked`);
    onClick();
  }

  return (
    <div className="flex flex-column items-center justify-center pointer" onClick={onRedirect}>
      <div className={styles['icon']}>
        <Icon />
      </div>
      <div className="tc mt2 ph2">
        <Text id={labelId} />
      </div>
    </div>
  );
}
