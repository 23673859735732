import React from 'react';
import cx from 'classnames';

import { segmentTrack } from '@kwara/components/src/Segment';
import { Text, Currency } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';
import { OverviewLabel } from '../OverviewLabel';

import styles from './index.module.scss';

type Props = {
  titleId: string,
  quantity: number,
  isShareCapitalCard?: boolean,
  segment?: string
};

export function MemberOverviewCard({ titleId, quantity, isShareCapitalCard, segment }: Props) {
  const [showInfo, setShowInfo] = React.useState(false);

  return (
    <div className={cx(styles.Container, 'pv3 ph2')}>
      <Text id={titleId} />
      <div className="flex items-center mt1">
        <div className={`flex items-end mr3 ${!showInfo ? styles.Blur : ''}`}>
          <div className="mobile-text-large">
            <Currency value={quantity} format="currency" />
          </div>
        </div>
        <Button
          glyphLeftId={showInfo ? 'hidden' : 'view'}
          onClick={() => {
            segmentTrack('Member overview clicked', { name: segment });
            setShowInfo(!showInfo);
          }}
        >
          <Text id={`MemberOverview.${showInfo ? 'hide' : 'show'}`} />
        </Button>
      </div>
      {isShareCapitalCard ? (
        <div className="w-70">
          <OverviewLabel labelId="MemberOverview.notWithdrawable" />
        </div>
      ) : null}
    </div>
  );
}
