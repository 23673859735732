import { hasMany } from 'spraypaint';
import queryString from 'query-string';

import { InferredModel } from 'GlobalTypes';

import Base from '../../Base';

import { BalanceSheetRowT } from './Row';
import { downloadExcel } from '../utils';

export type DownloadXlsxParams = {
  end_date: string;
};

export const BalanceSheet = Base.extend({
  static: {
    jsonapiType: 'abstract_balance_sheet_reports',
    endpoint: '/accounting/reports/balance_sheet',
    downloadXlsx(params: DownloadXlsxParams) {
      const queries = queryString.stringify(params);
      const url = `${BalanceSheet.url()}.xlsx?${queries}`;
      const opts = BalanceSheet.fetchOptions();
      const fileName = `balance_sheet.xlsx`;

      return downloadExcel({ fileName, url, opts });
    }
  },
  attrs: {
    rows: hasMany('abstract_balance_sheet_report_rows')
  }
});

export interface BalanceSheetT extends Omit<InferredModel<typeof BalanceSheet>, 'errors'> {
  rows: BalanceSheetRowT[];
}
