import config from '@kwara/lib/src/config';

import { APP_NAME } from '@kwara/lib/src/utils';

const api = {
  local: '//127.0.0.1:3000',
  sandbox: 'https://connect-api.sandbox.kwara.com',
  production: 'https://connect-api.kwara.com'
} as const;

export default config({ api, appName: APP_NAME.MEMBER });
