import { attr, belongsTo } from 'spraypaint';
import get from 'lodash/fp/get';
import join from 'lodash/fp/join';
import compact from 'lodash/fp/compact';
import pipe from 'lodash/fp/pipe';

import Base, { BaseModel } from './Base';
import { BankBranch, BankBranchT } from './BankBranch';

export const BankAccount = Base.extend({
  static: {
    jsonapiType: 'bank_accounts'
  },
  attrs: {
    accountNumber: attr(),
    mambuId: attr(),
    bankBranch: belongsTo({ type: BankBranch })
  },
  methods: {
    display() {
      return pipe(
        compact,
        join(' – ')
      )([get('bankBranch.bank.name', this), get('bankBranch.name', this), get('accountNumber', this)]);
    }
  }
});

export interface BankAccountT extends BaseModel<BankAccountT> {
  accountNumber: string;
  mambuId: string;
  bankBranch: BankBranchT;
}
