import React from 'react';
import { useHistory } from 'react-router-dom';
import { Text } from '@kwara/components/src/Intl';
import { Completion } from '@kwara/components/src/Completion';
import { Link } from '@kwara/components/src/Link';

import styles from './index.module.scss';

type EmptyModalProps = {
  to: string;
  titleId: string;
  subtitleId: string;
  actionId: string;
  cancelId?: string;
};

export const EmptyModal = ({ to, titleId, subtitleId, actionId, cancelId }: EmptyModalProps) => {
  const history = useHistory();

  return (
    <div className={styles.Container}>
      <div className="mt3">
        <Completion
          onConfirm={() => history.push(to)}
          subtitle={<Text id={subtitleId} />}
          type="info"
          buttonTextId={actionId}
          titleId={titleId}
        >
          {cancelId && (
            <div className="mv2">
              <Link to="/" size="medium" type="primary" active>
                <Text id={cancelId} />
              </Link>
            </div>
          )}
        </Completion>
      </div>
    </div>
  );
};
