import React from 'react';

export function IcnLoans() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#099250" />
      <g clipPath="url(#clip0_2553_27534)">
        <path
          d="M20.6909 21.3349C24.0916 21.3349 26.8583 18.5682 26.8583 15.1674C26.8583 11.7667 24.0916 9 20.6909 9C17.2901 9 14.5234 11.7667 14.5234 15.1674C14.5234 18.5682 17.2901 21.3349 20.6909 21.3349ZM20.6909 10.2891C23.3808 10.2891 25.5693 12.4775 25.5693 15.1674C25.5693 17.8574 23.3808 20.0458 20.6909 20.0458C18.001 20.0458 15.8125 17.8574 15.8125 15.1674C15.8125 12.4775 18.0009 10.2891 20.6909 10.2891Z"
          fill="white"
        />
        <path
          d="M20.3456 15.8123H21.0359C21.2516 15.8123 21.427 15.9877 21.427 16.2033C21.427 16.4189 21.2516 16.5943 21.0359 16.5943H20.0004C19.6444 16.5943 19.3559 16.8829 19.3559 17.2389C19.3559 17.5948 19.6444 17.8834 20.0004 17.8834H20.0462V17.9292C20.0462 18.2852 20.3348 18.5737 20.6907 18.5737C21.0467 18.5737 21.3353 18.2852 21.3353 17.9292V17.8562C22.1193 17.7145 22.716 17.0275 22.716 16.2033C22.716 15.2769 21.9623 14.5232 21.0359 14.5232H20.3456C20.1299 14.5232 19.9545 14.3478 19.9545 14.1322C19.9545 13.9165 20.1299 13.7411 20.3456 13.7411H21.3812C21.7371 13.7411 22.0257 13.4525 22.0257 13.0966C22.0257 12.7406 21.7371 12.4521 21.3812 12.4521H21.3353V12.4063C21.3353 12.0503 21.0467 11.7617 20.6908 11.7617C20.3348 11.7617 20.0462 12.0503 20.0462 12.4063V12.4793C19.2623 12.621 18.6655 13.308 18.6655 14.1322C18.6655 15.0586 19.4192 15.8123 20.3456 15.8123Z"
          fill="white"
        />
        <path
          d="M24.6141 23.1493L23.7443 24.0192C23.4304 23.3064 22.7172 22.8076 21.8897 22.8076H17.3778C15.6206 22.8076 14.8938 24.2382 14.7387 24.8787H13.7414V24.1425C13.7414 23.7865 13.4528 23.4979 13.0968 23.4979H9.64502C9.28907 23.4979 9.00049 23.7865 9.00049 24.1425V30.3557C9.00049 30.7117 9.28907 31.0003 9.64502 31.0003H13.0968C13.4528 31.0003 13.7414 30.7117 13.7414 30.3557V29.6195H22.6292C23.4306 29.6195 24.1844 29.3073 24.7515 28.7404L29.431 24.0608C29.6827 23.8091 29.6825 23.4007 29.431 23.1493C28.103 21.8213 25.9422 21.8213 24.6141 23.1493ZM12.4523 29.7112H10.2896V24.787H12.4523V29.7112H12.4523ZM23.8401 27.8288C23.5165 28.1523 23.0864 28.3304 22.6292 28.3304H13.7414V26.1677H15.3068C15.6627 26.1677 15.9513 25.8791 15.9513 25.5232C15.9513 24.7348 16.5888 24.0966 17.3778 24.0966H21.8897C22.2957 24.0966 22.6259 24.4269 22.6259 24.8328C22.6259 25.435 22.0705 25.566 21.9025 25.5689C21.9022 25.5689 21.9018 25.5689 21.9016 25.5689C21.8976 25.5689 21.8937 25.569 21.8897 25.569H19.5327C19.1767 25.569 18.8882 25.8575 18.8882 26.2135C18.8882 26.5695 19.1767 26.858 19.5327 26.858H21.89C22.4304 26.858 22.9387 26.6475 23.3217 26.2649L25.5257 24.0607C26.1922 23.3942 27.1963 23.2659 27.9928 23.6759L23.8401 27.8288Z"
          fill="white"
        />
        <path
          d="M29.2635 12.9828L30.6442 12.2924C30.9626 12.1333 31.0916 11.7461 30.9324 11.4277C30.7733 11.1093 30.3862 10.9803 30.0677 11.1395L28.687 11.8298C28.3686 11.989 28.2396 12.3761 28.3988 12.6945C28.5586 13.0141 28.9463 13.1413 29.2635 12.9828Z"
          fill="white"
        />
        <path
          d="M30.6442 18.0427L29.2635 17.3523C28.9452 17.1933 28.558 17.3222 28.3988 17.6406C28.2396 17.959 28.3686 18.3461 28.687 18.5053L30.0677 19.1956C30.3857 19.3545 30.773 19.2261 30.9324 18.9074C31.0916 18.589 30.9626 18.2018 30.6442 18.0427Z"
          fill="white"
        />
        <path
          d="M10.7378 12.2924L12.1185 12.9828C12.4357 13.1413 12.8234 13.0142 12.9832 12.6945C13.1424 12.3761 13.0134 11.989 12.695 11.8298L11.3142 11.1395C10.9958 10.9803 10.6087 11.1093 10.4495 11.4277C10.2903 11.7461 10.4194 12.1333 10.7378 12.2924Z"
          fill="white"
        />
        <path
          d="M11.3142 19.1957L12.695 18.5053C13.0134 18.3461 13.1424 17.959 12.9832 17.6406C12.824 17.3222 12.4368 17.1933 12.1185 17.3523L10.7378 18.0427C10.4194 18.2018 10.2903 18.589 10.4495 18.9074C10.609 19.2262 10.9963 19.3546 11.3142 19.1957Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2553_27534">
          <rect width="22" height="22" fill="white" transform="translate(9.00049 9)" />
        </clipPath>
      </defs>
    </svg>
  );
}
