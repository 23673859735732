type Announcement = {
  paragraph: string;
  points: string[];
};

function generateTexts(text: string, textSeparator: string, pointsSeparator: string) {
  const textParts = text.split(textSeparator);
  const segmentedAnnouncements = textParts.reduce((announcements: Announcement[], textPart) => {
    const [paragraph, ...points] = textPart.split(pointsSeparator);
    const announcement: Announcement = { paragraph, points };

    announcements.push(announcement);

    return announcements;
  }, []);

  return segmentedAnnouncements;
}

/**
 * @segmentText dynamically construct a string into object
 * of paragraphs and points. It transpile ```\n``` into a paragraph
 * and ```\t``` into a point.
 * @param text
 * @param textSeparator
 * @param pointsSeparator
 * @returns {Announcement[]}
 *
 * with the segmentText, we can generate any shape
 * of text e.g bullet points(\t), paragraphs(\n) or both
 */
export function segmentText(text: string, textSeparator = '\n', pointsSeparator = '\t'): Announcement[] {
  const formattedText = String(text)
    .replaceAll('\\n', '\n')
    .replaceAll('\\t', '\t')
    .replaceAll('\\r', '\r');
  /**
   * found no separator
   */
  if (!/[\n,\t,\r]/.test(formattedText)) return [{ paragraph: formattedText, points: [] }];

  /**
   * found separator
   */
  return generateTexts(formattedText, textSeparator, pointsSeparator);
}
