import * as React from 'react';

import { SubscribedPhoneField } from '@kwara/components/src/Form';
import { SubStepComponentProps } from '@kwara/components/src/Wizard/deprecated/Wizard';

export function MpesaContact({ StackChild }: SubStepComponentProps<{}>) {
  return (
    <StackChild>
      <SubscribedPhoneField disableDropdown name="phoneNumber" labelId="MpesaContact.amount.label" required />
    </StackChild>
  );
}

export const validateConfig = {
  phoneNumber: {
    isRequired: () => true,
    phoneNumber: true
  }
};

MpesaContact.validateConfig = validateConfig;
