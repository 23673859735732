// @flow

import * as React from 'react';

import type { UserDetails } from '@kwara/models/src';

import { type FormData, type InitialDetails } from '../RegistrationForm';

export const RedemptionStates = {
  init: 'init',
  validatingToken: 'validatingToken',
  validToken: 'validToken',
  invalidToken: 'invalidToken',
  submitting: 'submitting',
  created: 'created',
  submissionErrors: 'submissionErrors'
};

export type RedemptionState = $Values<typeof RedemptionStates>;

export type TokenErrorCode = 'REDEEMED_TOKEN' | 'EXPIRED_TOKEN';

export type RedemptionError = ?{ code: TokenErrorCode } | ?Error;

type InvitationValidityResult = {
  isValid: boolean,
  data?: { [string]: string },
  error?: typeof Error
};

type InvitationRedemptionResult = { isCreated: boolean, error?: typeof Error };

export type Invitation = {
  isValid: (id: ?string) => Promise<InvitationValidityResult>,
  redeem: (token: string, userDetails: UserDetails) => Promise<InvitationRedemptionResult>
};

type Props = {
  invitation: Invitation,
  inviteToken: ?string,
  ui: Node
};

type State = {
  state: RedemptionState,
  error: RedemptionError,
  userDetails: InitialDetails
};

export default class Redeemer extends React.Component<Props, State> {
  state = {
    state: RedemptionStates.init,
    error: null,
    userDetails: {
      firstName: '',
      lastName: ''
    }
  };

  componentDidMount() {
    this.validateInviteToken();
  }

  transition = async (stateId: RedemptionState, extraState: ?{ [string]: any } = {}) => {
    return new Promise(resolve => {
      this.setState(
        {
          ...extraState,
          state: stateId
        },
        resolve
      );
    });
  };

  validateInviteToken = async () => {
    const { invitation } = this.props;

    await this.transition(RedemptionStates.validatingToken);

    const validity = await invitation.isValid(this.props.inviteToken);

    this.tokenValidity(validity);
  };

  tokenValidity = ({ isValid, data = {}, error }: InvitationValidityResult) => {
    if (isValid) {
      // This prefills the form in case the api returns prefilled fields for known details of a user
      // For example a user that is already present in the system as a sacco member, receives an
      // invitation to sign up and create a profile as a member. In this case the API might return
      // the existing details we have about this user (for ex. their first name and last name)
      this.transition(RedemptionStates.validToken, {
        userDetails: data.attributes
      });
    } else {
      this.transition(RedemptionStates.invalidToken, { error });
    }
  };

  createUserAccount = async (formDetails: FormData) => {
    const { invitation } = this.props;
    const userDetails = {
      ...this.state.userDetails,
      ...formDetails
    };

    await this.transition(RedemptionStates.submitting, { userDetails });

    if (typeof this.props.inviteToken !== 'string') {
      return;
    }

    const result = await invitation.redeem(this.props.inviteToken, userDetails);

    if (result.isCreated) {
      await this.transition(RedemptionStates.created);
    } else {
      await this.transition(RedemptionStates.submissionErrors, {
        error: result.error
      });
    }
  };

  render() {
    const RedeemerUI = this.props.ui;

    return (
      <RedeemerUI
        currentState={this.state.state}
        error={this.state.error}
        userDetails={this.state.userDetails}
        onUserDetails={this.createUserAccount}
      />
    );
  }
}
