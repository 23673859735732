// @flow
import * as React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { DateTime } from 'luxon';
import cx from 'classnames';

import zIndices from '@kwara/lib/src/zIndices';
import Asset from '@kwara/components/src/Asset';

import styles from './DatePicker.module.css';
import { TextField, parse } from '.';

type ValidateDays = {
  name: string,
  disabledDays?: { before?: ?Date, after?: Date },
  dateValues?: { to: Date, from: Date }
};

type Props = ValidateDays & {
  disabled?: boolean,
  value: Date,
  size?: 'regular' | 'medium',
  onChange?: (evt: { target: { value: string } }) => void,
  onBlur?: () => void,
  component?: React.Node | (() => React.Node),
  inputPlaceholder?: ?string,
  leftGlyph?: string
};

const FORMAT = 'dd/MM/yyyy';
const PLACEHOLDER = 'DD/MM/YYYY';

const parseDate = str => {
  const d = DateTime.fromFormat(str, FORMAT);
  if (d.isValid) {
    return d.toJSDate();
  }

  return null;
};

const formatDate = date => {
  const d = DateTime.fromJSDate(date);
  if (d.isValid) {
    return d.toFormat(FORMAT);
  }

  return null;
};

//validateDays() expects name(from & to) and dateValues to work
//otherwise defaults to the default disabledDays passed to DatePicker

export const validateDays = ({ name, disabledDays, dateValues }: ValidateDays) => {
  if (!(disabledDays && name)) {
    return;
  }

  if (!dateValues) {
    return { disabledDays };
  }

  if (name === 'from') {
    return {
      disabledDays: {
        before: disabledDays.before,
        after: parse(dateValues.to)
      }
    };
  }

  if (name === 'to') {
    return {
      disabledDays: {
        before: parse(dateValues.from),
        after: disabledDays.after
      }
    };
  }
};

const DatePicker = ({
  onChange,
  onBlur,
  disabled,
  disabledDays,
  name,
  value,
  dateValues,
  size = 'medium',
  component = TextField,
  inputPlaceholder = PLACEHOLDER,
  leftGlyph
}: Props) => {
  return (
    <div className={styles.Wrapper}>
      <DayPickerInput
        classNames={{
          outside: styles.Outside,
          overlay: '',
          overlayWrapper: cx(styles.OverlayWrapper, zIndices.Modal)
        }}
        component={component}
        dayPickerProps={validateDays({ name, disabledDays, dateValues })}
        formatDate={formatDate}
        inputProps={{
          onBlur,
          name,
          disabled,
          placeholder: inputPlaceholder,
          size,
          autoComplete: 'off',
          leftGlyph,
          rightGlyph: Asset.Glyphs.Calendar
        }}
        parseDate={parseDate}
        onDayChange={onChange}
        value={value}
      />
    </div>
  );
};

export default DatePicker;
