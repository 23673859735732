import { attr } from 'spraypaint';
import Base, { BaseModel } from '../Base';

// @TODO Update when BE is ready
export const BranchMembershipStats = Base.extend({
  static: {
    jsonapiType: 'dashboard_branch_membership_stats',
    endpoint: '/dashboard/branch_membership_stats'
  },
  attrs: {
    branches: attr(),
    startDate: attr(),
    endDate: attr()
  }
});

export interface BranchMembershipStatsT extends BaseModel<BranchMembershipStatsT> {
  branches: [];
  startDate: string;
  endDate: string;
}
