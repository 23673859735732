import React from 'react';

import { LoanSubstepProps } from 'LoanSharedTypes';

import Banner from '@kwara/components/src/Banner';

import { Text } from '@kwara/components/src/Intl';
import { ERRORS } from '@kwara/lib/src/validator';
import { PotentialGuarantorType } from '@kwara/models/src/models/Guarantee';

import { AddGuarantorsButton } from './AddGuarantorsButton';
import { GuarantorListCard } from '../../components/GuarantorListCard';
import { State as CurrentScreenStatus } from './screenStatusState/reducer';
import { hasReachedRequiredGuarantees } from './hasReachedRequiredGuarantees';
import { AddGuarantorCard, FindGuarantorCard } from '../../components/AddGuarantor';
import { useAddGuarantorActions, AddGuarantorDataType } from './useAddGuarantorActions';

function AddGuarantorScreen(props: LoanSubstepProps<AddGuarantorDataType>) {
  const {
    guarantors,
    loanAmount,
    currentScreenStatus,
    totalAmountInGuarantee,
    selectedMember,
    onFindGuarantor,
    onSaveGuarantor,
    onRemoveGuarantor,
    onEditGuarantor,
    onAddMoreGuarantors,
    onAddFirstGuarantors,
    onErrorFindingGuarantor
  } = useAddGuarantorActions(props);

  return (
    <props.StackChild>
      <div className="w-100 ph3">
        {currentScreenStatus.showAddButton && (
          <AddGuarantorsButton
            ariaLabel="AddGuarantors"
            textId="LoanAdd.Connect.AddGuarantor.add"
            onClick={onAddFirstGuarantors}
          />
        )}

        {(currentScreenStatus.showListClose || currentScreenStatus.showListOpen) && (
          <GuarantorListCard
            guarantors={guarantors}
            onEdit={onEditGuarantor}
            open={currentScreenStatus.showListOpen}
            totalAmountInGuarantee={totalAmountInGuarantee}
          />
        )}

        {currentScreenStatus.showBannerError && (
          <Banner
            state="error"
            className="br0 w-100 mv2"
            innerClassName="flex justify-between items-center"
            text={<Text id="LoanAdd.Connect.FindGuarantor.error" />}
          />
        )}

        {currentScreenStatus.showFindGuarantorCard && (
          <FindGuarantorCard onFindGuarantor={onFindGuarantor} onErrorFindingGuarantor={onErrorFindingGuarantor} />
        )}

        {currentScreenStatus.showAddGuarantor && (
          <AddGuarantorCard
            member={selectedMember}
            loanAmount={loanAmount}
            onSaveGuarantor={onSaveGuarantor}
            onRemoveGuarantor={onRemoveGuarantor}
            totalAmountInGuarantee={totalAmountInGuarantee}
          />
        )}

        {currentScreenStatus.showAddMoreButton && (
          <AddGuarantorsButton
            ariaLabel="AddMoreGuarantors"
            textId="LoanAdd.Connect.AddGuarantor.addAnother"
            onClick={onAddMoreGuarantors}
          />
        )}
      </div>
    </props.StackChild>
  );
}

AddGuarantorScreen.validate = {
  currentScreenStatus: {
    custom(_: CurrentScreenStatus, allData: AddGuarantorDataType) {
      return allData.currentScreenStatus.showFindGuarantorCard ? '' : null;
    }
  },
  guarantors: {
    isRequired(_: Array<PotentialGuarantorType>, { product }: AddGuarantorDataType) {
      return !!Number(product.security?.required_guaranties);
    },
    custom(_: Array<PotentialGuarantorType>, { product, guarantors, amount }: AddGuarantorDataType) {
      const loanAmount = Number(amount);
      const requiredGuaranteesPercentage = Number(product.security?.required_guaranties);
      const guarantorsAmount = guarantors.reduce((total, guarantor) => total + Number(guarantor.amount), 0);

      if (!hasReachedRequiredGuarantees(guarantorsAmount, loanAmount, requiredGuaranteesPercentage)) {
        return ERRORS.rangeUnderflow;
      }

      return null;
    }
  }
};

export { AddGuarantorScreen };
