import * as React from 'react';
import cx from 'classnames';

type PrimitiveDivPropTypes = React.ComponentPropsWithRef<'div'>;
type ContainerElement = React.ElementRef<'div'>;

export const Container = React.forwardRef<ContainerElement, PrimitiveDivPropTypes>(function Container(
  { className, ...restProps },
  forwardedRef
) {
  return <div ref={forwardedRef} className={cx('ma0 pa3 w-100', className)} {...restProps} />;
});
