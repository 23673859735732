import React from 'react';
import { Text, Percent, Currency } from '@kwara/components/src/Intl';
import { CommonHeader } from '../../../../components/CommonHeader';

interface ProductDetailsProps {
  name: string;
  interest: number;
  amount: string;
}
export const ProductDetails = ({ name, interest, amount }: ProductDetailsProps) => {
  return (
    <>
      <div className="ml1 mh1">
        <div className="mobile-text-small">
          <Text id="LoanAdd.Summary.Loan.name" />
        </div>
        <div className="mobile-neutral-900 mobile-text-medium">
          <span>{name}</span>
        </div>
      </div>
      <CommonHeader labelIdLeft="LoanAdd.Summary.Loan.interestRate" labelIdRight="LoanAdd.Summary.Loan.amount" />
      <div className="flex">
        <div className="ml1 w-50 mobile-neutral-900">
          <Percent value={interest / 100} />
        </div>
        <div className="mobile-neutral-900">
          <Currency format="currency" value={parseFloat(amount)} />
        </div>
      </div>
    </>
  );
};
