import * as React from 'react';

import { If } from '@kwara/components/src/If/If';

import { SuggestedSaccoDetails } from '../SuggestedSaccoDetails';
import { ProgressiveLoader } from '../../../../components/ProgressiveLoader';
import { useProgressiveLoader } from '../../../../components/ProgressiveLoader/useProgressiveLoader';

type FindingSaccoPropTypes = {
  onCancel(): void;
};

export function FindingSacco({ onCancel }: FindingSaccoPropTypes) {
  const loaderRef = React.useRef<HTMLDivElement>(null);
  const { isLoading, percent } = useProgressiveLoader({
    loaderRef,
    route: '/saccoRecommendation/financialProducts/ageGroup?suggestedSaccoDetails'
  });

  return (
    <If
      condition={isLoading}
      do={
        <ProgressiveLoader
          ref={loaderRef}
          percent={percent}
          infoId="SaccoRecommendationFlow.FindingSacco.Loader.info"
        />
      }
      else={<SuggestedSaccoDetails onCancel={onCancel} />}
    />
  );
}
