import * as React from 'react';
import find from 'lodash/fp/find';
import get from 'lodash/fp/get';

import { Logger } from '@kwara/lib/src/logger';

function getBank({ bankCode, banks }) {
  return find(b => b.id === bankCode, banks);
}

export const Bank = ({ bankCode }) => {
  const store = Bank.appStore || {};
  const banks = store.banks;

  const bank = getBank({ bankCode, banks });

  if (!bank) {
    Logger.warn(`Missing bank details for bankCode: ${bankCode}`);
    return null;
  }

  return <span>{bank.name}</span>;
};

export const BankBranch = ({ bankCode, branchCode }) => {
  const store = BankBranch.appStore || {};
  const banks = store.banks;

  const bank = find(b => b.id === bankCode, banks);
  const branch = find(b => b.id === branchCode, get('bankBranches', bank));

  if (!branch) {
    Logger.warn(`Missing branch details for bankCode: ${bankCode}, bankBranch: ${branchCode}`);
    return null;
  }

  return <span>{branch.name}</span>;
};
