import React from 'react';

import { Membership } from './useGetMemberships';

import iconKwaraBrand from './IconKwaraBrand.png';

interface ItemPropsTypes extends Membership {
  meta?: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLLIElement>;
  className?: string;
}

export function Item({ className, meta, onClick, attributes }: ItemPropsTypes) {
  return (
    <li onClick={onClick} className={className}>
      <img alt="logo" src={attributes.logo_url || iconKwaraBrand} />
      <div>
        <p>{attributes.org_name}</p>
        <p className="mobile-text-small">{meta}</p>
      </div>
    </li>
  );
}
