import { attr, belongsTo } from 'spraypaint';
import axios from 'axios';

import { InferredModel } from 'GlobalTypes';

import { snakeCaseObjectKeys } from '@kwara/models/src';

import Base from './Base';

import { UserT } from './User';
import { SavingProductType } from './SavingProduct';
import Transaction, { TransactionType } from './Transaction';
import { SavingsTransactionReviewSet } from './TransactionReviewSet';
import createModelErrors, { createErrorsFromApiResponse } from './createModelErrors';

const SavingsTransaction = Transaction.extend({
  static: {
    jsonapiType: 'savings_transactions'
  },
  attrs: {
    chequeNumber: attr(),
    drawer: attr(),
    bankName: attr(),
    accountNumber: attr(),
    account: belongsTo(),
    bookingDate: attr(),
    valueDate: attr(),
    paymentMethod: attr(),
    fees: attr(),
    predefinedFeeKey: attr(),

    // Write
    glAccountCode: attr(),
    bankGlId: attr(),
    savingsId: attr(),
    reference: attr(),
    paymentMethodId: attr(),
    glLinkId: attr(),

    // Write - Transfers
    linkedAccountId: attr(),
    linkedAccountType: attr(),

    memberName: attr(),
    memberId: attr(),

    savingsTransactionReviewSet: belongsTo({
      type: SavingsTransactionReviewSet
    })
  },
  methods: {
    async adjust(data: AdjustPayloadT) {
      const url = `${Base.baseUrl}/savings_transactions/${this.id}/adjust`;
      const opts = SavingsTransaction.fetchOptions();
      const requestPayload = { data: { attributes: snakeCaseObjectKeys({ ...data }) } };

      try {
        await axios.post(url, requestPayload, opts);
        return true;
      } catch (error) {
        if (!!error.response?.data?.errors) {
          // Errors from API
          this.errors = createErrorsFromApiResponse(error.response.data);
          return false;
        }

        //All other errors
        this.errors = createModelErrors({
          base: 'APP_NETWORK_ERROR'
        });
        return false;
      }
    },
    async v1Adjust(savingsId: string, data: V1AdjustPayloadT) {
      const url = `${Base.fullBasePath()}/savings/${savingsId}/transactions/${this.id}/reverse`;
      const opts = SavingsTransaction.fetchOptions();
      const payload = { data: { attributes: snakeCaseObjectKeys({ ...data }) } };

      try {
        await axios.post(url, payload, opts);
        return true;
      } catch (error) {
        const apiError = error.response?.data;
        this.errors = apiError?.errors
          ? createErrorsFromApiResponse(error.response.data)
          : createModelErrors({ base: 'APP_NETWORK_ERROR' });
        return false;
      }
    }
  }
});

export const PendingSavingsTransaction = SavingsTransaction.extend({
  static: {
    jsonapiType: 'pending_savings_transactions'
  },
  attrs: {
    checker: attr(),
    state: attr(),
    user: belongsTo()
  },
  methods: {
    async transition(params: { event: 'approve' | 'reject'; raw_savings_transaction_ids: string[]; notes?: string }) {
      const url = PendingSavingsTransaction.url();
      const attributes = params;

      const options = {
        ...PendingSavingsTransaction.fetchOptions(),
        method: 'PUT',
        body: JSON.stringify({ data: { attributes } })
      };

      try {
        const response = await window.fetch(url, options);
        if (!response.ok) {
          const body = await response.json();

          this.errors = createErrorsFromApiResponse(body);

          return false;
        }

        return true;
      } catch (errors) {
        this.errors = createModelErrors({
          base: 'APP_NETWORK_ERROR'
        });

        return false;
      }
    }
  }
});

PendingSavingsTransaction.transactionsKey = 'raw_savings_transaction_ids';

type AdjustPayloadT = {
  notes: string;
  bookingDate: string;
};

type V1AdjustPayloadT = {
  notes: string;
  valueDate: string;
};

export interface SavingsTransactionType
  extends TransactionType,
    Omit<InferredModel<typeof SavingsTransaction>, 'errors'> {
  savingsId: string;
  account: SavingProductType;
  valueDate: Date;
  bookingDate: Date;
  glAccountCode: string;
  user: UserT;
  adjust(data: AdjustPayloadT): Promise<boolean>;
  v1Adjust(savingsId: string, data: V1AdjustPayloadT): Promise<boolean>;
}

export default SavingsTransaction;
