import * as React from 'react';
import { Field as FinalFormField } from 'react-final-form';

import { Field as BasicField } from '@kwara/components/src/Form';

import DatePicker from './DatePicker';

export const SubscribedDatePicker = ({
  name,
  required,
  titleId,
  labelId,
  errorBaseId,
  info,
  infoId,
  disabled,
  disabledDays,
  errorValues = {},
  values,
  value,
  inputPlaceholder,
  compact,
  margin,
  showInfo,
  leftGlyph
}: {
  name: string;
  required?: boolean;
  titleId?: string;
  labelId?: string;
  errorBaseId?: string;
  info?: React.ReactNode;
  infoId?: string;
  disabled?: boolean;
  disabledDays?: { before?: Date | null; after?: Date };
  errorValues?: {};
  values?: {};
  value?: string | Date;
  inputPlaceholder?: string | null;
  compact?: boolean;
  margin?: boolean;
  showInfo?: boolean;
  leftGlyph?: string;
}) => {
  return (
    <FinalFormField
      name={name}
      infoId={infoId}
      render={({ input, meta }) => {
        return (
          <BasicField
            name={input.name}
            labelId={labelId}
            titleId={titleId}
            error={meta.error && meta.touched}
            errorBaseId={errorBaseId}
            errorCodes={meta.error}
            values={{ ...values, ...errorValues }}
            info={info}
            infoId={infoId}
            required={required}
            compact={compact}
            margin={margin}
            showInfo={showInfo}
          >
            <DatePicker
              name={name}
              disabled={disabled}
              disabledDays={disabledDays}
              inputPlaceholder={inputPlaceholder}
              {...input}
              value={value ?? input.value}
              leftGlyph={leftGlyph}
            />
          </BasicField>
        );
      }}
    />
  );
};
