import * as React from 'react';
import cx from 'classnames';
import noop from 'lodash/fp/noop';

import { RequireAtLeastOne } from 'GlobalTypes';

import { If } from '@kwara/components/src/If/If';
import { Text, TranslationId } from '@kwara/components/src/Intl';
import { composeEvents } from '@kwara/lib/src/utils/composeEvents';

import styles from './Radio.module.scss';

type PrimitiveInputPropTypes = React.ComponentPropsWithoutRef<'input'> &
  RequireAtLeastOne<
    {
      /**
       * There are case where label don't need client
       * side translation e.g its coming from the server,
       * so we carter for it with either `label` | `labelId`.
       * When its labelId we translate and when its just
       * label then we don't carry out translation
       */
      labelId: TranslationId;
      label: TranslationId;
    },
    'label' | 'labelId'
  > &
  Partial<
    RequireAtLeastOne<{
      /**
       * There are case where title don't need client
       * side translation e.g its coming from the server,
       * so we carter for it with either `title` | `titleId`.
       * When its titleId we translate and when its just
       * title then we don't carry out translation
       */
      titleId: TranslationId;
      title: TranslationId;
    }>
  >;
type RadioElement = React.ElementRef<'input'>;
interface RadioPropTypes extends Omit<PrimitiveInputPropTypes, 'size'> {
  size: 'regular' | 'medium';
  labelValues?: Record<string, any>;
  inputOnChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const Radio = React.forwardRef<RadioElement, RadioPropTypes>(function Radio(props, forwardedRef) {
  const {
    size,
    labelId,
    label,
    titleId,
    title,
    className,
    onChange,
    labelValues,
    inputOnChange = noop,
    ...restProps
  } = props;
  const id = `${restProps.name}-${restProps.value}`;
  const headingId = titleId ?? labelId;
  const heading = title ?? label;
  const renderLabelId = !!titleId && !!labelId;
  const renderLabel = !!title && !!label;

  return (
    <label className={styles['container']} htmlFor={id}>
      <input
        {...restProps}
        ref={forwardedRef}
        id={id}
        type="radio"
        className={cx(styles['input'], className)}
        onChange={composeEvents<HTMLInputElement>(onChange, inputOnChange)}
      />

      <div className={cx(`pb2 dib flex kw-text-${size === 'regular' ? 'small' : 'regular'}`, styles['labelling'])}>
        <div className="flex flex-column">
          <div className="black">
            <If condition={!!headingId} do={<Text id={headingId} values={labelValues} />} />
            <If condition={!!heading} do={heading} />
          </div>

          <If
            condition={renderLabelId}
            do={
              <div className="grey-400 pt1">
                <Text id={labelId} />
              </div>
            }
          />

          <If condition={renderLabel} do={<div className="grey-400 pt1"> {label}</div>} />
        </div>
      </div>
    </label>
  );
});
