import { EnterOTP } from '../SecureFourPinUpdate/components/EnterOTP';
import { Identity } from '../SecureFourPinUpdate/components/Identity';

export const steps = {
  identity: {
    titleId: 'SecureFourPinUpdate.Identity.title',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'nextWithPromise',
        destination: 'enterOtp',
        onNext: Identity.verify
      }
    ],
    children: [
      {
        titleId: 'SecureFourPinUpdate.Identity.title',
        subtitleId: 'SecureFourPinUpdate.Identity.subtitle',
        Component: Identity,
        validate: Identity.validateConfig
      }
    ]
  },
  enterOtp: {
    titleId: 'SecureFourPinUpdate.EnterOTP.title',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'SecureFourPinUpdate.EnterOTP.title',
        Component: EnterOTP,
        validate: EnterOTP.validateConfig
      }
    ]
  }
};
