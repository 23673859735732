// @flow

import { attr, hasOne } from 'spraypaint';
import axios from 'axios';
import keys from 'lodash/fp/keys';
import includes from 'lodash/fp/includes';

import { Logger } from '@kwara/lib/src/logger';
import { handleDownload, blobHeaders } from '@kwara/lib/src/fileDownload';

import Base, { type BaseModel } from './Base';
import { Bank, type BankT } from './Bank';

const mimeTypesMaps = Object.freeze({
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
});

const mimeTypes = Object.freeze(keys(mimeTypesMaps));

const DirectDebitReportStates = Object.freeze({
  pending: 'pending',
  processing: 'processing',
  generated: 'generated',
  failed: 'failed'
});

type DirectDebitReportStatesT = $Values<typeof DirectDebitReportStates>;

type ReportsT = 'report' | 'batchTransactions';

export const DirectDebitReport = Base.extend({
  static: {
    jsonapiType: 'direct_debit_reports'
  },
  attrs: {
    dayOfMonth: attr(),
    originatingReferencePrefix: attr(),
    state: attr(),
    completedAt: attr(),
    createdByName: attr(),
    fee: attr(),
    filename: attr(),
    batchTransactionsFilename: attr(),
    startDate: attr(),
    collectingBank: hasOne({ type: Bank })
  },
  methods: {
    isFinished() {
      return includes(this.state, [DirectDebitReportStates.generated, DirectDebitReportStates.failed]);
    },
    isDownloadable(report: ReportsT = 'report') {
      switch (report) {
        case 'report':
          return this.state === DirectDebitReportStates.generated;
        case 'batchTransactions':
          return !!this.batchTransactionsFilename;
        default:
          throw `Invalid report type: ${report} `;
      }
    },
    onDownload(reportType: ReportsT = 'report') {
      const isBatchTransactionsReport = reportType === 'batchTransactions';
      const reportId = this.id;

      const opts = DirectDebitReport.fetchOptions();
      const options = blobHeaders(opts);

      const fileName = isBatchTransactionsReport ? this.batchTransactionsFilename : this.filename;

      const url = isBatchTransactionsReport
        ? `${DirectDebitReport.url()}/${reportId}/batch_transactions`
        : `${DirectDebitReport.url()}/${reportId}`;

      return axios(url, options)
        .then(res => {
          try {
            handleDownload({ data: res.data, fileName, mimeTypes });
          } catch (e) {
            Logger.log('Error reading direct debit report response', JSON.stringify(e));
            return Promise.reject(e);
          }
          // The main result of this call is a side effect (the file download kicking off in the
          // client) so we're not interested in returning any result apart from the success/failure
          // of the operation.
          return Promise.resolve();
        })
        .catch(e => {
          Logger.log('Error fetching direct debit data ', JSON.stringify(e));
          return Promise.reject(e);
        });
    }
  }
});

export interface DirectDebitReportT extends BaseModel<DirectDebitReportT> {
  dayOfMonth: string;
  originatingReferencePrefix: string;
  state: DirectDebitReportStatesT;
  completedAt: string;
  createdByName: string;
  startDate: string;
  filename: string;
  batchTransactionsFilename: string;
  fee: number;
  collectingBank: BankT;
  isDownloadable: () => boolean;
}
