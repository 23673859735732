import axios, { AxiosRequestConfig } from 'axios';

import { blobHeaders, handleDownload } from '@kwara/lib/src/fileDownload';

const mimeTypes = Object.keys({
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
});

type ArgType = {
  url: string;
  fileName: string;
  opts: Record<string, string>;
};

export async function downloadExcel({ fileName, url, opts }: ArgType) {
  const options = blobHeaders(opts) as AxiosRequestConfig;

  try {
    const response = await axios.get(url, options);
    handleDownload({ data: response.data, fileName, mimeTypes });
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
}
