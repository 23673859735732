import React, { useState, useCallback } from 'react';
import cx from 'classnames';
import posed from 'react-pose';
import noop from 'lodash/fp/noop';

import Asset from '@kwara/components/src/Asset';

import { If } from '@kwara/components/src/If/If';

import { ActionButton } from '..';

import styles from './index.module.scss';

const Expander = posed.div({
  collapsed: { height: 0, opacity: 0, flip: true },
  expanded: { height: '100%', opacity: 1, flip: true }
});

const Control = posed.div({ collapsed: { opacity: 1 }, expanded: { opacity: 0 } });

/**
 * useExpander
 */
type ExpanderStateT = 'expanded' | 'collapsed';
function useExpander(initialState: ExpanderStateT = 'collapsed') {
  const [state, setState] = useState<ExpanderStateT>(initialState);
  const toggle = useCallback(() => {
    setState(state === 'expanded' ? 'collapsed' : 'expanded');
  }, [state]);

  return [toggle, state, setState] as const;
}

/**
 * ExpanderBar
 */
type ExpanderBarPropTypes = {
  isExpanded: boolean;
  onClick?(p: boolean): void;
};
function ExpanderBar({ isExpanded, onClick = noop }: ExpanderBarPropTypes) {
  return (
    <Control
      className={`bt ${isExpanded ? 'primary-500' : 'b--light-grey-400'} w-100 flex justify-center ${styles.ExpandBar}`}
    >
      <ActionButton
        type="expand"
        col={Asset.Colours.primary500}
        data-testid="expanderBar"
        className={cx(styles.ExpandBtn, isExpanded && styles.ExpandBtn__open)}
        onClick={() => onClick(!isExpanded)}
      />
    </Control>
  );
}

/**
 * ExpanderRow
 */
type ExpanderRowPropTypes = {
  children: React.ReactNode;
  isExpanded?: boolean;
  isActive?: boolean;
  ariaLabel?: string;
};
function ExpanderRow({ children, isExpanded, ariaLabel, isActive = true }: ExpanderRowPropTypes) {
  const [toggle, state] = useExpander(isExpanded ? 'expanded' : 'collapsed');

  return (
    <If
      condition={!isActive}
      do={children}
      else={
        <div aria-label={ariaLabel}>
          <ExpanderBar
            /*@ts-ignore*/
            onClick={toggle}
            isExpanded={state === 'expanded'}
          />

          <Expander
            /*@ts-ignore*/
            pose={state}
            data-testid={`${state}Row`}
            className={styles.Expander}
          >
            <div className={styles.Row}>
              <div className={styles.RowContent}>{children}</div>
            </div>
          </Expander>
        </div>
      }
    />
  );
}

export { useExpander, ExpanderRow, ExpanderBar, Expander };
