// @flow
import * as React from 'react';
import classnames from 'classnames';

import { Text } from '@kwara/components/src/Intl';
import styles from './index.module.css';

type Props = {
  titleId: string,
  values?: { [key: string]: any },
  headerRight?: React.Node,
  children: React.Node
};

export default ({ titleId, values, headerRight, children }: Props) => (
  <section className={styles.Section}>
    <div className="bt b--light-grey-400">
      <div className={classnames(['flex items-center', styles.Header])}>
        <h3 className="kw-text-medium kw-weight-bold bg-white pr3 ">
          <Text id={titleId} values={values} />
        </h3>
        {headerRight ? <div className={classnames([styles.HeaderRight, 'bg-white pl3'])}>{headerRight}</div> : null}
        <div className="cf" />
      </div>
      <div>{children}</div>
    </div>
  </section>
);
