import * as React from 'react';
import cx from 'classnames';

import { ValueOf } from 'GlobalTypes';
import Button from '@kwara/components/src/Button';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { SpinningLoading } from '@kwara/components/src/Loadable';

export type Type = ValueOf<typeof Types>;

export type SharedCompletionProps = {
  type: Type;
  titleId?: string;
  analyticsId?: string;
  onConfirm?(): void;
  buttonTextId?: string;
  isLoading?: boolean;
  loaderColor?: string;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
};

export const Types = {
  good: 'good',
  bad: 'bad',
  info: 'info',
  neutral: 'neutral'
} as const;

interface CompletionBasePropTypes extends SharedCompletionProps {
  styles: any;
  textFontClasses?: { titleClassName: string; subtitleClassName: string };
}

export function CompletionBase(props: CompletionBasePropTypes) {
  const {
    onConfirm,
    subtitle,
    type,
    titleId,
    buttonTextId,
    styles,
    children,
    loaderColor = 'indigo500',
    analyticsId = '',
    isLoading = false,
    textFontClasses = { titleClassName: 'kw-text-big', subtitleClassName: 'kw-text-regular' }
  } = props;
  const buttonTranslationId = buttonTextId || `Completion.Confirm.${type}`;
  const titleTranslationId = titleId || `Completion.Title.${type}`;

  return (
    <div
      id={`kwaralytics-completion-confirmation-${type}-${analyticsId}`}
      className={`${styles.Container} ${styles[type]} center`}
    >
      <div className={`${styles.Inner} flex flex-column items-center`}>
        <div className={`${textFontClasses.titleClassName} kw-weight-bold pa0 ma0`}>
          <Text id={titleTranslationId} />
        </div>

        <If condition={!!subtitle} do={<p className={`${textFontClasses.subtitleClassName} pt1 ph3`}>{subtitle}</p>} />

        <div className={cx(styles.Footer, 'pt2 pb3')}>
          <If
            condition={isLoading}
            do={<SpinningLoading color={loaderColor} />}
            else={
              <If
                condition={onConfirm != undefined}
                do={
                  <Button
                    type="primary"
                    size="regular"
                    aria-label="Confirm"
                    className={cx(styles.RowSize, styles.Btn)}
                    onClick={onConfirm}
                  >
                    <div className={textFontClasses.subtitleClassName}>
                      <Text id={buttonTranslationId} />
                    </div>
                  </Button>
                }
              />
            }
          />

          {children}
        </div>
      </div>
    </div>
  );
}
