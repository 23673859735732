import * as React from 'react';

import { FormState } from '..';
import { usePurchaseAirtime } from '..';
import { ErrorStatus } from './ErrorStatus';
import { PendingStatus } from './PendingStatus';
import { SuccessStatus } from './SuccessStatus';

type StatusModalPropTypes = {
  formState: FormState;
  onCancel(): void;
};

export function StatusModal({ formState, onCancel }: StatusModalPropTypes) {
  const { isPending, isSuccessful } = usePurchaseAirtime();

  if (isPending) return <PendingStatus />;

  if (isSuccessful) return <SuccessStatus onCancel={onCancel} hasCashback={formState.hasCashback} />;

  return <ErrorStatus formState={formState} onCancel={onCancel} />;
}
