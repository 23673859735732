// @flow

import * as React from 'react';
import { NotificationContextConsumer, type NotificationContextType } from './NotificationProvider';

export type notification = NotificationContextType;

type Props = {
  children: (context: NotificationContextType) => React.Node
};

/*
  Allows anything in the app to display global
  notifications in the site header.

  Look at the stories to see how this should be used.
*/
export const AddNotification = ({ children }: Props) => (
  <NotificationContextConsumer>
    {({ displayError, displayMessage, displayWarning, dismiss }: NotificationContextType) =>
      children({ displayError, displayMessage, displayWarning, dismiss })
    }
  </NotificationContextConsumer>
);

export function WithNotification<P = {}>(
  Component: React.ComponentType<P>
): React.ComponentType<$Diff<P, { notification: NotificationContextType }>> {
  return function WrapperComponent(props: P) {
    return (
      <NotificationContextConsumer>
        {(methods: NotificationContextType) => <Component {...props} notification={methods} />}
      </NotificationContextConsumer>
    );
  };
}

type NotificationComponentProps = {
  type?: 'error' | 'message',
  children: React.Node,
  notification: NotificationContextType
};

class NotificationComponent extends React.Component<NotificationComponentProps, *> {
  componentDidMount() {
    const method =
      this.props.type === 'error' ? this.props.notification.displayError : this.props.notification.displayMessage;

    method(this.props.children);
  }

  render() {
    return null;
  }
}

export const Notification = WithNotification(NotificationComponent);
