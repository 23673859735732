// @flow

import { attr } from 'spraypaint';

import Base from './Base';

export const UtilityTypes = Object.freeze({
  RECEIPT: 'receipt',
  PAYMENT: 'payment'
});
export type UtilityTypesT = $Values<typeof UtilityTypes>;

export const UtilityTransaction = Base.extend({
  static: {
    jsonapiType: 'utility_transactions'
  },
  attrs: {
    amount: attr(),
    name: attr(),
    type: attr(),
    paymentMethod: attr(),
    glCode: attr(),
    reference: attr(),
    invoiceNumber: attr(),
    notes: attr()
  }
});
