// @flow

import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';

import Collapsible from '../../components/Collapsible';
import { DocumentList } from '../../components/DocumentList';

export const Documents = ({ member, className }) => (
  <Collapsible className={className} summary={<Text id="MemberDetail.documents" />}>
    <section>
      <DocumentList member={member} contexts={['members', 'loans']} attachments={member.attachments} />
    </section>
  </Collapsible>
);
