//@flow

import * as React from 'react';
import classnames from 'classnames';

import styles from './index.module.css';

type ListProps = {
  className?: string,
  children: React.Node
};

const InfoList = ({ className, children }: ListProps) => {
  return <dl className={classnames(styles.InfoList, className)}>{children}</dl>;
};

type ItemProps = {
  label: React.Node,
  value: React.Node,
  ariaLabel?: string
};

const Item = ({ label, value, ariaLabel }: ItemProps) => (
  <React.Fragment>
    <dt className={classnames(styles.label, 'grey-400')}>{label}:</dt>
    <dd aria-label={ariaLabel} className={classnames(styles.value, 'grey-500')}>
      {value}
    </dd>
  </React.Fragment>
);

InfoList.Item = Item;

export { InfoList as default, Item };
