import { useCallback } from 'react';

import { Store } from '../../models/Store';
import { useStore } from '../../models/Member';
import { MembershipsType, STATES } from './useGetMemberships';

type GroupedMemberships = {
  active: MembershipsType;
  inactive: MembershipsType;
};

export function useGroupMemberships() {
  const store = useStore() as Store;

  const groupMemberships = useCallback(
    (memberships: MembershipsType) => {
      const groupedMemberships = memberships.reduce(
        (acc: GroupedMemberships, curr) => {
          if (curr.attributes.state === STATES.ACTIVE) acc.active.push(curr);
          else acc.inactive.push(curr);
          return acc;
        },
        { active: [], inactive: [] }
      );

      const primarySaccoIndex = groupedMemberships.active.findIndex(
        m => m.attributes.org_permalink === store.organisation?.permalink
      );
      const [primarySacco] = groupedMemberships.active.splice(primarySaccoIndex, 1);
      groupedMemberships.active.unshift(primarySacco);

      return groupedMemberships;
    },
    [store.organisation?.permalink]
  );

  return groupMemberships;
}
