// @flow
import axios from 'axios';
import { attr, SpraypaintBase as SpraypaintBaseClass } from 'spraypaint';
import type { SpraypaintBase } from 'spraypaint';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { handleDownload, handleTextDownload } from '@kwara/lib/src/fileDownload';
import { Logger } from '@kwara/lib/src/logger';

import { parse } from '@kwara/lib/src/dates';

import createModelErrors, { createErrorsFromApiResponse } from './createModelErrors';

const Base = SpraypaintBaseClass.extend({
  static: {
    // jwtStorage: TOKEN_KEY,
    // baseUrl: API_BASE_URL,
    apiNamespace: '',
    create(attributes) {
      return new this(omitBy(attributes, isNil));
    },
    generateAuthHeader(token: string) {
      return `Bearer ${token}`;
    },
    downloadTextFileFromUrl(url, options, fileName) {
      return axios(url, options)
        .then(res => {
          try {
            handleTextDownload({ data: res.data, fileName, preview: true });
          } catch (e) {
            Logger.log(`Error reading ${fileName} response`, JSON.stringify(e));
            return Promise.reject(e);
          }
          return Promise.resolve();
        })
        .catch(e => {
          Logger.log(`Error fetching ${fileName} txt data`, JSON.stringify(e));
          return Promise.reject(e);
        });
    },
    downloadFileFromUrl(url, options, fileName) {
      return axios
        .get(url, options)
        .then(res => {
          try {
            handleDownload({ data: res.data, fileName, preview: true });
          } catch (e) {
            Logger.log(`Error reading ${fileName} response`, JSON.stringify(e));
            return Promise.reject(e);
          }
          // The main result of this call is a side effect (the file download kicking off in the
          // client) so we're not interested in returning any result apart from the success/failure
          // of the operation.
          return Promise.resolve();
        })
        .catch(e => {
          Logger.log(`Error fetching ${fileName} PDF data`, JSON.stringify(e));
          return Promise.reject(e);
        });
    }
    // logger: console
  },
  attrs: {
    updatedAt: attr({ persist: false }),
    createdAt: attr({ persist: false })
  },
  methods: {
    isSpraypaintInstance: true,
    updatedAtDate() {
      return parse(this.updatedAt);
    },
    createdAtDate() {
      return parse(this.createdAt);
    },
    // This is just so that we can call deserialize on any instance of any class
    // without checking the type. This saves us from adding conditionals when calling deserialize.
    // This is hipefully a temp solution while we work to sync reads/writes models so that there are
    // no discrepancies
    deserialize() {
      return this;
    }
  }
});

/**
 * If a non-422 status code in the 4xx range
 * is returned by the server when saving, Spraypaint
 * (formerly JSORM) throws an "invalid json" ResponseError.
 *
 * We patch `model.save()` so that we can catch this
 * error, parse the response body and turn it into
 * a `model.errors` object that is the same shape
 * as Spraypaint's.
 */

Base.prototype._originalSave = Base.prototype.save;

Base.prototype.save = async function(...args) {
  try {
    return await this._originalSave(...args);
  } catch (saveError) {
    try {
      const body = await saveError.response.clone().json();
      this.errors = body?.errors ? createErrorsFromApiResponse(body) : createModelErrors({ base: 'APP_NETWORK_ERROR' });
    } catch (parseError) {
      throw saveError;
    }
  }
};

Base.prototype._originalDestroy = Base.prototype.destroy;

Base.prototype.destroy = async function(...args) {
  try {
    return await this._originalDestroy(...args);
  } catch (saveError) {
    try {
      const body = await saveError.response.clone().json();
      this.errors = createErrorsFromApiResponse(body);
    } catch (parseError) {
      throw saveError;
    }
  }
};

/*
  This configures the Base models and all
  it's subclasses with:
  - local storage key containing the JWT
  - URL for the API
  - namespace for the API
*/
export const configureModels = function({ apiBaseUrl, jwtStorageKey }: { apiBaseUrl: string, jwtStorageKey: string }) {
  if (typeof apiBaseUrl !== 'string') {
    throw new Error('apiBaseUrl key must be provided');
  }

  if (typeof jwtStorageKey !== 'string') {
    throw new Error('jwtStorageKey key must be provided');
  }

  Base.baseUrl = apiBaseUrl;
  Base.jwtStorage = jwtStorageKey;
};

export const configureApiNamespace = function(namespace: string) {
  Base.apiNamespace = namespace;
};

export default Base;

export type IncludesT = string | (string | { [k: string]: string | string[] })[];

export type SelectT =
  | string[]
  | {
      [k: string]: string[]
    };

export type WhereT = ?{ [k: string]: ?string };

export interface BaseModel<T> extends SpraypaintBase<T> {
  id: string;
  isMarkedForDestruction: boolean;
  deserialize: () => T;
  createdAt: string;
  updatedAt?: string;
  passwordExpiryAt: string;
  passwordExpired: boolean;
  includes: (p: IncludesT) => T;
  where: (p: WhereT) => T;
}
