// @flow

import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import { ProfilePhoto } from '@kwara/components/src/ProfilePhoto';
import { useLoan } from '@kwara/models/src/models/request/hooks';

import { Metadata } from './Metadata';
import { Metrics } from './Metrics';
import { useStore } from '../../models/Member';

const includes = ['product'];
export const LoanOverview = ({ loanId }) => {
  const store = useStore();
  const response = useLoan(loanId, includes);

  return (
    <Loadable {...response}>
      {loan => (
        <div>
          <section className="mt1 pt3 mb1 flex justify-between items-center">
            <div>
              <Metadata loan={loan} />
              <h2 aria-label={'Overview Loan Id'} className="ma0 kw-text-super-large kw-weight-bold">
                {loan.id}
              </h2>
            </div>

            <ProfilePhoto size="regular" memberId={store.memberId} attachments={store.attachments} />
          </section>
          <Metrics loan={loan} />
          {loan.canMakeRepayment() && store.isMpesaLoanRepaymentEnabled ? (
            <div className="flex flex-column mb3">
              <Button type="primary" size="large" to={`/loans/${loan.id}/repayment`} aria-label="repay">
                <Text id="LoanRepayment.LoanDetail.button.label" />
              </Button>
            </div>
          ) : null}
        </div>
      )}
    </Loadable>
  );
};
