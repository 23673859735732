import * as React from 'react';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';

import { If } from '@kwara/components/src/If/If';
import { segmentTrack } from '@kwara/components/src/Segment';
import { Record, Section } from '@kwara/components/src/Summary';
import { LoanSubstepProps } from '@kwara/components/src/pages/LoanAdd';

import { GuarantorSummary } from '../GuarantorSummary';
import { ProductDetails } from '../ProductDetails';
import { RepaymentDetails } from '../RepaymentDetails';
import { DocumentList } from '../../../../components/DocumentList';

export const Summary = ({ data, StackChild, parentUrl }: LoanSubstepProps) => {
  const { guarantors, member, product, amount, repaymentPeriodUnit } = data;
  const interestRate = get('interestRate.percentage', product);
  const installments = data.loanInstallments;
  const duration = data.loanDuration;
  const showGuarantors = !isEmpty(guarantors);
  const editUrl = path => `${parentUrl}/${path}`;

  React.useEffect(() => {
    segmentTrack('Long term application flow: Summary');
  }, []);

  return (
    <StackChild>
      <Section titleId="LoanAdd.Summary.Loan.title" editUrl={editUrl('configure/1')}>
        <Record value={<ProductDetails name={product.name} interest={interestRate} amount={amount} />} />
      </Section>
      <Section titleId="LoanAdd.Summary.Repayment" editUrl={editUrl('configure/1')}>
        <Record
          value={
            <RepaymentDetails
              installments={installments}
              duration={duration}
              repaymentPeriodUnit={repaymentPeriodUnit}
            />
          }
        />
      </Section>
      <Section titleId="LoanAdd.Summary.Documents.title" editUrl={editUrl('documents/1')}>
        <Record
          value={<DocumentList size="tiny" member={member} contexts={['loans']} attachments={member.attachments} />}
        />
      </Section>
      <If
        condition={showGuarantors}
        do={
          <Section titleId="LoanAdd.Summary.Guarantors.title" editUrl={editUrl('addGuarantor/1')}>
            <Record value={<GuarantorSummary guarantors={guarantors} />} />
          </Section>
        }
      />
    </StackChild>
  );
};
