import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';

import { ValueOf } from 'GlobalTypes';

import Auth from '@kwara/models/src/lib/Auth';
import Button from '@kwara/components/src/Button';
import createValidator from '@kwara/lib/src/validator';

import { If } from '@kwara/components/src/If/If';
import { Link } from '@kwara/components/src/Link';
import { Text } from '@kwara/components/src/Intl';
import { Window } from '@kwara/components/src/Freshchat/util';
import { ModelErrorBanner } from '@kwara/components/src/ModelErrorBanner';

import { EnterPhoneNumber } from './components/EnterPhoneNumber';
import { StatusApprovalModal } from '../../components/StatusApprovalModal';
import { SCREEN_CONFIGS, SCREEN_TYPES, useUserStatus } from './hooks/useUserStatus';

import styles from './index.module.scss';

type StartPropsTypes = {
  auth: Auth;
  screenType?: ValueOf<typeof SCREEN_TYPES>;
};

export function Start({ auth, screenType = SCREEN_TYPES.LOGIN }: StartPropsTypes) {
  const history = useHistory();
  const { isProcessing, showModal, errors, modalStatus, onToggle, onCancel, onSubmit } = useUserStatus();

  const { title, subtitle, directions, button, footer, footerLink } = SCREEN_CONFIGS[screenType];

  return (
    <>
      <If
        condition={!!modalStatus}
        do={
          <StatusApprovalModal
            show={showModal}
            type={modalStatus?.type}
            titleId={modalStatus?.title}
            subtitleId={modalStatus?.subtitle}
            buttonId={modalStatus?.button}
            cancelButtonId={modalStatus?.cancelId}
            onConfirm={onToggle}
            onCancel={onCancel}
          />
        }
      />

      <Form
        onSubmit={onSubmit(auth, screenType)}
        validate={createValidator(EnterPhoneNumber.validateConfig)}
        render={({ handleSubmit, invalid }) => (
          <form onSubmit={handleSubmit} className={styles['container']}>
            <header className={styles['header']}>
              <div className={styles['overlay']}>
                <h1 className="kw-text-x-large">
                  <Text id={title} />
                </h1>

                <p>
                  <Text id={subtitle} />
                </p>
              </div>
            </header>

            <div className={styles['body']}>
              <EnterPhoneNumber directions={directions} />

              <If
                condition={!!errors?.length}
                do={<ModelErrorBanner className="mt4" errors={errors} state="error" />}
              />

              <div className="flex flex-column mt4">
                <Button
                  isSubmit
                  aria-label="Next"
                  type="primary"
                  size="medium"
                  className="fr"
                  disabled={isProcessing || invalid}
                  glyphRightId={isProcessing ? Button.Glyphs.Spinner : null}
                >
                  <Text id={button} />
                </Button>
              </div>

              <footer>
                <p className="mobile-text-medium">
                  <Text id={footer} />
                  &nbsp;
                  <Link active underline type="primary" onClick={() => history.replace({ pathname: footerLink.path })}>
                    <Text id={footerLink.text} />
                  </Link>
                </p>

                <p className="mobile-text-medium">
                  <Text id="Start.login.needHelp" />
                  &nbsp;
                  <Link
                    active
                    underline
                    type="primary"
                    onClick={() => {
                      if ((window as Window).fcWidget) (window as Window).fcWidget.open();
                    }}
                  >
                    <Text id="Start.login.clickHere" />
                  </Link>
                </p>
              </footer>
            </div>
          </form>
        )}
      />
    </>
  );
}
