import * as React from 'react';

type PrimitiveSvgPropTypes = React.ComponentPropsWithoutRef<'svg'>;
type IconInfoElement = React.ElementRef<'svg'>;

interface IconInfoPropTypes extends PrimitiveSvgPropTypes {}

export const IconInfo = React.forwardRef<IconInfoElement, IconInfoPropTypes>(function IconInfo(
  { fill, width, height, ...restProps },
  forwardedRef
) {
  return (
    <svg
      {...restProps}
      ref={forwardedRef}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4838_4738)">
        <path
          d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433286 8.00042 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34871 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0V0ZM10 18.3333C8.35183 18.3333 6.74066 17.8446 5.37025 16.9289C3.99984 16.0132 2.93174 14.7117 2.30101 13.189C1.67028 11.6663 1.50525 9.99075 1.82679 8.37425C2.14834 6.75774 2.94201 5.27288 4.10745 4.10744C5.27289 2.94201 6.75774 2.14833 8.37425 1.82679C9.99076 1.50525 11.6663 1.67027 13.189 2.301C14.7118 2.93173 16.0132 3.99984 16.9289 5.37025C17.8446 6.74066 18.3333 8.35182 18.3333 10C18.3309 12.2094 17.4522 14.3276 15.8899 15.8899C14.3276 17.4522 12.2094 18.3309 10 18.3333Z"
          fill={fill}
        />
        <path
          d="M9.99992 8.33203H9.16659C8.94557 8.33203 8.73361 8.41983 8.57733 8.57611C8.42105 8.73239 8.33325 8.94435 8.33325 9.16537C8.33325 9.38638 8.42105 9.59834 8.57733 9.75462C8.73361 9.9109 8.94557 9.9987 9.16659 9.9987H9.99992V14.9987C9.99992 15.2197 10.0877 15.4317 10.244 15.588C10.4003 15.7442 10.6122 15.832 10.8333 15.832C11.0543 15.832 11.2662 15.7442 11.4225 15.588C11.5788 15.4317 11.6666 15.2197 11.6666 14.9987V9.9987C11.6666 9.55667 11.491 9.13275 11.1784 8.82019C10.8659 8.50763 10.442 8.33203 9.99992 8.33203Z"
          fill={fill}
        />
        <path
          d="M10 6.66798C10.6904 6.66798 11.25 6.10833 11.25 5.41797C11.25 4.72761 10.6904 4.16797 10 4.16797C9.30964 4.16797 8.75 4.72761 8.75 5.41797C8.75 6.10833 9.30964 6.66798 10 6.66798Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4838_4738">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});

IconInfo.defaultProps = {
  fill: '#64748B',
  width: '20',
  height: '20'
};
