// @flow

import * as React from 'react';

import size from 'lodash/fp/size';

import { type LoanType } from '@kwara/models/src';
import Empty from '@kwara/components/src/Empty';
import { Text } from '@kwara/components/src/Intl';

import { useStore } from '../../models/Member';
import Section from '../../components/Section';

import { MainLoanCard } from '../../components/LoanCard';

type Props = {
  loans: LoanType[]
};

export const LoansList = ({ loans }: Props) => {
  const hasLoans = size(loans) > 0;
  const store = useStore();

  return (
    <Section titleId="Loans.title" values={{ orgCurrency: store.currency }}>
      {hasLoans ? (
        loans.map(loan => <MainLoanCard key={loan.id} data={loan} />)
      ) : (
        <Empty>
          <Text id="Loans.none" />
        </Empty>
      )}
    </Section>
  );
};
