import * as React from 'react';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { OrganisationT } from '@kwara/models/src/models/Organisation';

import { Illustration } from './Illustration';
import { OrganisationItem } from './OrganisationItem';
import { MODAL_STATUS, USER_STATUS } from '../../../lib/userStatus';

import { StatusApprovalModal } from '../../../components/StatusApprovalModal';

import iconEmpty from '../assets/IconEmptyList.svg';
import iconJoinSacco from '../assets/IconJoinSacco.svg';

const MIN_SEARCH_CHAR = 2;
const MODAL_STATUS_KEY = USER_STATUS.MEMBER_NONEXISTENT_AND_ORG_WITH_NO_FEES;

type SearchResultPropTypes = {
  searchValue: string;
  showModal: boolean;
  organisations: OrganisationT[];
  hideModal(): void;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadingOrganisation: React.Dispatch<React.SetStateAction<boolean>>;
};

export function SearchResult({
  organisations,
  showModal,
  searchValue,
  hideModal,
  setShowModal,
  setLoadingOrganisation
}: SearchResultPropTypes) {
  const hasSearchValue = !!searchValue.trim();
  const isSearchValueInValid = !hasSearchValue || searchValue.length <= MIN_SEARCH_CHAR;
  const visibleOrganisations = filter(org => org.name.toLowerCase().includes(searchValue.toLowerCase()), organisations);

  return (
    <If
      condition={isSearchValueInValid}
      do={<Illustration src={(iconJoinSacco as unknown) as string} id="Register.Organisations.Search.start" />}
      else={
        <div className="flex flex-column">
          <div className="mobile-text-medium mt3">
            <If condition={hasSearchValue} do={<Text id="Register.Organisations.Search.results" />} />
          </div>

          <If
            condition={isEmpty(visibleOrganisations)}
            do={<Illustration src={(iconEmpty as unknown) as string} id="Register.Organisations.Search.empty" />}
            else={
              <>
                <div className="flex flex-column items-left overflow-y-scroll">
                  {map(
                    org => (
                      <div className="pt2" key={org.id}>
                        <OrganisationItem
                          org={org}
                          setShowModal={setShowModal}
                          setLoadingOrganisation={setLoadingOrganisation}
                        />
                      </div>
                    ),
                    visibleOrganisations
                  )}
                </div>

                <StatusApprovalModal
                  show={showModal}
                  onConfirm={hideModal}
                  type={MODAL_STATUS[MODAL_STATUS_KEY].type}
                  titleId={MODAL_STATUS[MODAL_STATUS_KEY].title}
                  subtitleId={MODAL_STATUS[MODAL_STATUS_KEY].subtitle}
                  buttonId={MODAL_STATUS[MODAL_STATUS_KEY].button}
                />
              </>
            }
          />
        </div>
      }
    />
  );
}
