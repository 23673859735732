import { decorate, observable, computed } from 'mobx';
import filter from 'lodash/fp/filter';
import get from 'lodash/fp/get';
import sortBy from 'lodash/fp/sortBy';
import invoke from 'lodash/fp/invoke';

import { OrganisationSettingT } from '@kwara/models/src/models/OrganisationSetting';
import { Store as BaseStore } from '@kwara/models/src/lib/Store';
import { UserT } from '@kwara/models/src/models/User';
import { MambuTransactionChannelT } from '@kwara/models/src';
import { Logger } from '@kwara/lib/src/logger';
import { isNonProdEnv, isProd } from '@kwara/lib/src/utils';

export class Store extends BaseStore {
  profile: UserT = {} as UserT;
  refetch: () => void = () => {};
  transactionChannels: MambuTransactionChannelT[] = [];
  defaultJoiningFeeAccountPattern: string;

  updateProfile(p: UserT) {
    this.profile = p;
  }

  setRefetch(r: () => void) {
    this.refetch = r;
  }

  setTransactionChannels(channels: MambuTransactionChannelT[]) {
    this.transactionChannels = channels;
  }

  get is2faActivated() {
    return invoke('profile.hasActivatedMfa', this);
  }

  get isKBS() {
    const orgId = get('profile.organisation.id', this);
    const isKBSNonProd = isNonProdEnv && orgId === '6';
    const isKBSProd = isProd && orgId === '8';

    return isKBSNonProd || isKBSProd;
  }

  get isDynastySacco() {
    return get('profile.branch.id', this) === 'Dynasty_Sacco';
  }

  get isKwaraKenya() {
    return get('profile.branch.id', this) === 'arena_sacco';
  }

  get isE2E() {
    return get('profile.branch.id', this) === 'e2e_branch_kwarakenya';
  }

  get isKwaraKenyaOrE2E() {
    return this.isKwaraKenya || this.isE2E;
  }

  get isKaruraCommunitySacco() {
    return get('profile.branch.id', this) === 'Karura Community SACCO';
  }

  get isAAKSacco() {
    return get('profile.branch.id', this) === 'AAK_SACCO_MAIN';
  }

  get isUbunifu() {
    return get('profile.branch.id', this) === 'UBS';
  }

  get isMakBiashara() {
    return get('profile.branch.id', this) === 'kwara42_main';
  }

  get isApollosureSacco() {
    return get('profile.branch.id', this) === 'kwara27_main';
  }

  get isFirstAssetSacco() {
    return get('profile.branch.id', this) === 'kwara44_main';
  }

  get isMultipleSacco() {
    return get('profile.branch.id', this) === 'kwara26_main';
  }

  get isMerinetSacco() {
    return get('profile.branch.id', this) === 'kwara52_main';
  }

  get isOxfordSacco() {
    return get('profile.branch.id', this) === 'OX001';
  }

  get isTranwaySacco() {
    return get('profile.branch.id', this) === 'Tranway_main';
  }

  get isStonesToRubiesSacco() {
    return get('profile.branch.id', this) === 'kwara45_main';
  }

  get smsTriggers() {
    return get('profile.organisation.settings.sms_triggers', this) || {};
  }

  get dividendsFeatureLive() {
    return true;
  }

  get refinanceEnabled() {
    return !!(
      get('profile.organisation.bridgingDepositProductId', this) &&
      get('profile.organisation.bridgingLoanProductId', this)
    );
  }

  get branchName() {
    return get('profile.branch.name', this);
  }

  get branches() {
    return get('profile.organisation.branches', this) || [];
  }

  get organisation() {
    return get('profile.apiInstance', this);
  }

  get glTransactionChannels() {
    const allChannels = get('transactionChannels', this);
    const filtered = filter(c => c.isGlChannel, allChannels);
    return sortBy<MambuTransactionChannelT>(c => c.id, filtered);
  }

  get currency() {
    const curr = get('profile.organisation.currency', this);
    // We only want to do this check when the user is logged in, so we first
    // check if org is defined
    if (this.organisation != null && curr == null) {
      Logger.error(`Currency not set for ${this.organisation}`);
    }
    // we fallback to KES in case existing orgs currency haven't been set yet
    // https://github.com/getkwara/webapp/pull/1232#discussion_r484820629
    return curr || 'KES';
  }

  get accruedInterestEnabled() {
    return get('profile.organisation.configSettings.accrued_interest_enabled', this);
  }

  get isMakerCheckerEnabledApi() {
    return get('profile.organisation.configSettings.maker_checker_enabled', this);
  }

  get organisationSettings(): OrganisationSettingT {
    return get('profile.organisation.organisationSetting', this);
  }

  get loanDisbursalSettings() {
    return get('profile.organisation.settings.loan_disbursal_settings', this);
  }

  get defaultSavingsAccounts() {
    return get('profile.organisation.settings.member_creation.default_accounts', this) || [];
  }

  get fullMemberDetailsRequiredForLoanApplication() {
    // If no Organisation Settings are set in the backend,
    // default to true
    if (!this.organisationSettings) {
      return true;
    }

    return this.organisationSettings.fullMemberDetailsRequiredForLoanApplication;
  }

  get isSafaricomPhoneNumberValidationLive() {
    return false;
  }

  get joiningFeeAmount() {
    return get('profile.organisation.joiningFee', this);
  }

  get businessNumber() {
    return get('profile.organisation.businessShortCode', this);
  }

  get paymentInstructions() {
    return get('profile.organisation.joiningFeeType', this);
  }

  get joiningFeeAccountPattern() {
    return get('profile.organisation.joiningFeeAccount', this) || this.defaultJoiningFeeAccountPattern;
  }

  get isCrbCheckEnabled() {
    return get('profile.organisation.isCrbCheckEnabled', this);
  }

  get shareCapitalProductId() {
    return get('profile.organisation.shareCapitalProductId', this);
  }

  get isSasraDownloadLive() {
    return isNonProdEnv;
  }

  get isDepositTaking() {
    return !!get('profile.organisation.organisationSetting.depositTaking', this);
  }

  get isSasraRegulated() {
    return !!get('profile.organisation.organisationSetting.sasraRegulated', this);
  }
}

decorate(Store, {
  profile: observable,
  isKBS: computed
});

export const store = new Store();
