//@flow
import React from 'react';
import classnames from 'classnames';

import Asset from '@kwara/components/src/Asset';

import styles from './index.module.scss';

type PrimitiveDivProps = React.ComponentPropsWithoutRef<'div'>;
type BannerRef = React.ElementRef<'div'>;
interface BannerProps extends PrimitiveDivProps {
  text: string;
  state: 'error' | 'warning' | 'success';
  innerClassName?: string;
  hidden?: boolean;
  onDismiss?: React.MouseEventHandler<HTMLButtonElement>;
}

const VARIANTS = {
  error: {
    color: Asset.Colours.red700,
    className: 'bg-red-100 red-700'
  },
  warning: {
    color: Asset.Colours.info700,
    className: 'bg-info-100 info-700'
  },
  success: {
    color: Asset.Colours.white,
    className: 'bg-mobile-primary-500 white'
  }
};

export const MobileBanner = (() =>
  React.forwardRef<BannerProps, BannerRef>((props, forwardedRef) => {
    const { text, state, hidden, onDismiss, className, innerClassName, ...restProps } = props;

    return (
      <>
        {!hidden && (
          <div
            {...restProps}
            ref={forwardedRef}
            role="dialog"
            aria-label={`${state} banner`}
            className={classnames('w-100 flex justify-center', VARIANTS[state].className, className, styles.Container)}
          >
            <div className={classnames('flex justify-between items-center ph3 w-100', innerClassName)}>
              <p className="pa0 ma0">{text}</p>
              {onDismiss && (
                <div className="ml-auto">
                  <button
                    type="button"
                    aria-label="Dismiss"
                    onClick={onDismiss}
                    className="bn bg-transparent p0 m0 pointer"
                  >
                    <Asset id={Asset.Glyphs.CrossCircle} col={VARIANTS[state].color} />
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }))();
