// @flow

import { attr, belongsTo } from 'spraypaint';
import axios from 'axios';

import Base, { type BaseModel } from './Base';
import { OrganisationT } from './Organisation';

// Base model Spraypaint uses to format the response
// and used for the GET request (on Connect)
export const ConnectUserBase = Base.extend({
  static: {
    jsonapiType: 'connect_users',
    endpoint: '/users/profile'
  },
  attrs: {
    firstName: attr(),
    lastName: attr(),
    email: attr(),
    phone: attr(),
    pinLockedAt: attr(),
    memberId: attr(),
    referralScore: attr(),
    referralCode: attr(),
    referralAmountPerPoint: attr(),
    organisation: belongsTo()
  },
  methods: {
    isPinLocked() {
      return !!this.pinLockedAt;
    },
    unlockPin() {
      const url = `${Base.baseUrl}/members/${this.memberId}/connect_user/reset_pin`;
      const opts = ConnectUserBase.fetchOptions();

      return axios
        .put(url, {}, opts)
        .then(res => res.data)
        .then(res => ConnectUserBase.fromJsonapi(res.data, res.data));
    }
  }
});

// Used to generate the GET request for specific memberId (from Core)
export const ConnectUser = (memberId: string) =>
  ConnectUserBase.extend({
    static: {
      endpoint: `/members/${memberId}/connect_user`
    }
  });

export interface ConnectUserT extends BaseModel<ConnectUserT> {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  pinLockedAt: string;
  memberId: string;
  isPinLocked: () => boolean;
  unlockPin: () => Promise<ConnectUserT>;
  organisation: OrganisationT;
}
