import { attr, belongsTo, hasMany } from 'spraypaint';

import Base, { BaseModel } from './Base';
import { UserT } from './User';
import { TillTellerLinkT } from './TillTellerLink';

export const Till = Base.extend({
  static: {
    jsonapiType: 'tills'
  },
  attrs: {
    name: attr(),
    glAccountId: attr(),
    currentBalance: attr(),
    isPermitted: attr(),
    occupant: belongsTo(),
    tillTellerLinks: hasMany()
  }
});

export interface TillT extends BaseModel<TillT> {
  name: string;
  glAccountId: string;
  currentBalance: number;
  isPermitted: boolean;
  occupant?: UserT;
  tillTellerLinks: TillTellerLinkT[];
}
