import * as React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import noop from 'lodash/fp/noop';
import isEmpty from 'lodash/fp/isEmpty';

import { LoanClassificationT } from '@kwara/models/src';
import { Loadable } from '@kwara/components/src/Loadable';
import { SubscribedSelectField } from '@kwara/components/src/Form';
import { useLoanClassifications } from '@kwara/models/src/models/request/hooks';
import { getTranslationIdByApp } from '@kwara/components/src/Intl';

type SelectProps = {
  name: string;
  labelId: string;
  classifications: LoanClassificationT[];
  required: boolean;
  onChange?: () => void;
};

type SubOptionProps = {
  name: string;
  labelId: string;
  parentId: string;
  required: boolean;
  onChange?: () => void;
};

function ClassificationsSelect({ name, labelId, classifications, required, onChange = noop }: SelectProps) {
  return (
    <SubscribedSelectField required={required} name={name} labelId={labelId} inputOnChange={onChange}>
      <SubscribedSelectField.Option value="" />
      {map(
        c => (
          <SubscribedSelectField.Option key={c.id} value={c.id}>
            {c.code} - {c.name}
          </SubscribedSelectField.Option>
        ),
        classifications
      )}
    </SubscribedSelectField>
  );
}

function SubOptions({ name, labelId, parentId, onChange, required }: SubOptionProps) {
  const r = useLoanClassifications(parentId);

  return (
    <Loadable {...r}>
      {subLevelClassifications =>
        isEmpty(subLevelClassifications) ? null : (
          <ClassificationsSelect
            required={required}
            name={name}
            labelId={labelId}
            classifications={subLevelClassifications}
            onChange={onChange}
          />
        )
      }
    </Loadable>
  );
}
// !! Also used in LoanRefinance, LoanReschedule
export function LoanClassificationFields({ formProps, addData }) {
  const r = useLoanClassifications();

  React.useEffect(() => {
    if (r.data && !r.isLoading) {
      addData({ topLevelClassifications: r.data });
    }
  }, [r.data, r.isLoading, addData]);

  const { values, form } = formProps;
  const { classifications } = values;

  const level1 = get('[0]', classifications);
  const level2 = get('[1]', classifications);

  const resetChildren = () => {
    form.change('classifications.[1]', '');
    form.change('classifications.[2]', '');
  };

  const resetSubChildren = () => {
    form.change('classifications.[2]', '');
  };

  return (
    <div className={`pt4 bt b--light-grey-400`}>
      <Loadable {...r}>
        {topLevelClassifications => {
          return (
            <ClassificationsSelect
              required
              name="classifications.[0]"
              labelId={getTranslationIdByApp('LoanAdd.Configure.Classification.level1.label')}
              classifications={topLevelClassifications}
              onChange={resetChildren}
            />
          );
        }}
      </Loadable>

      {level1 ? (
        <SubOptions
          required
          name="classifications.[1]"
          labelId={'LoanAdd.Configure.Classification.level2.label'}
          parentId={level1}
          onChange={resetSubChildren}
        />
      ) : null}

      {level2 ? (
        <SubOptions
          required
          name="classifications.[2]"
          labelId={'LoanAdd.Configure.Classification.level3.label'}
          parentId={level2}
        />
      ) : null}
    </div>
  );
}

LoanClassificationFields.validate = {
  'classifications.[0]': {
    isRequired: () => true
  },
  'classifications.[1]': {
    isRequired: () => true
  },
  'classifications.[2]': {
    isRequired: () => true
  }
};
