import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { Completion, Types } from '@kwara/components/src/Completion';

import { analyticsId } from '../config';
import { usePurchaseAirtime } from '..';

type CashbackStatusPropTypes = {
  onCancel(): void;
};

export function CashbackStatus({ onCancel }: CashbackStatusPropTypes) {
  const { isPolling } = usePurchaseAirtime();

  return (
    <Completion
      type={Types.neutral}
      isLoading={isPolling}
      analyticsId={analyticsId}
      titleId="Airtime.Status.Cashback.title"
      subtitle={<Text id="Airtime.Status.Cashback.subTitle" />}
      buttonTextId="Airtime.Status.Cashback.close"
      onConfirm={onCancel}
    />
  );
}
