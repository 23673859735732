import { attr, belongsTo } from 'spraypaint';
import axios from 'axios';

import { ValueOf, InferredModel } from 'GlobalTypes';

import { snakeCaseObjectKeys } from '@kwara/models/src';

import Base from './Base';

import { UserT } from './User';
import { LoanProductType } from './LoanProduct';
import Transaction, { TransactionType } from './Transaction';
import { LoanTransactionReviewSet } from './TransactionReviewSet';
import createModelErrors, { createErrorsFromApiResponse } from './createModelErrors';

export const LoanTransactionTypes = Object.freeze({
  DISBURSEMENT: 'DISBURSEMENT',
  INTEREST_APPLIED: 'INTEREST_APPLIED',
  REPAYMENT: 'REPAYMENT',
  FEE: 'FEE'
});

export type LoanTransactionTypesT = ValueOf<typeof LoanTransactionTypes>;

type AdjustData = {
  notes: string;
  valueDate?: string;
};

const LoanTransaction = Transaction.extend({
  static: {
    jsonapiType: 'loan_transactions'
  },
  attrs: {
    amount: attr(),
    balance: attr(),
    type: attr(),
    notes: attr(),
    createdAt: attr(),
    bookingDate: attr(),
    valueDate: attr(),
    paymentMethod: attr(),
    bankName: attr(),
    accountNumber: attr(),
    account: belongsTo('loan_products'),
    chequeNumber: attr(),
    drawer: attr(),
    fees: attr(),

    // Write
    bankGlId: attr(),
    glAccountCode: attr(),
    loanId: attr(),
    reference: attr(),
    predefinedFeeKey: attr(),
    paymentMethodId: attr(),

    memberName: attr(),
    memberId: attr(),
    loanTransactionReviewSet: belongsTo({ type: LoanTransactionReviewSet })
  },
  methods: {
    async adjust(data) {
      const url = `${Base.baseUrl}/loan_transactions/${this.id}/adjust`;
      const opts = LoanTransaction.fetchOptions();
      const requestPayload = { data: { attributes: snakeCaseObjectKeys({ ...data }) } };

      try {
        await axios.post(url, requestPayload, opts);
        return true;
      } catch (error) {
        if (!!error.response?.data?.errors) {
          // Errors from API
          this.errors = createErrorsFromApiResponse(error.response.data);
          return false;
        }

        //All other errors
        this.errors = createModelErrors({
          base: 'APP_NETWORK_ERROR'
        });
        return false;
      }
    },
    async v1Adjust(loanId: string, data: AdjustData) {
      const url = `${Base.fullBasePath()}/loans/${loanId}/transactions/${this.id}/reverse`;
      const opts = LoanTransaction.fetchOptions();
      const payload = { data: { attributes: snakeCaseObjectKeys({ ...data }) } };

      try {
        await axios.post(url, payload, opts);
        return true;
      } catch (error) {
        const apiError = error.response?.data;
        this.errors = apiError?.errors
          ? createErrorsFromApiResponse(error.response.data)
          : createModelErrors({ base: 'APP_NETWORK_ERROR' });
        return false;
      }
    }
  }
});

export const PendingLoanTransaction = LoanTransaction.extend({
  static: {
    jsonapiType: 'pending_loan_transactions'
  },
  attrs: {
    bankBranch: attr(),
    user: belongsTo()
  },
  methods: {
    async transition(params: { event: 'approve' | 'reject'; raw_loan_transaction_ids: string[]; notes?: string }) {
      const url = PendingLoanTransaction.url();
      const attributes = params;

      const options = {
        ...PendingLoanTransaction.fetchOptions(),
        method: 'PUT',
        body: JSON.stringify({ data: { attributes } })
      };

      try {
        const response = await window.fetch(url, options);
        if (!response.ok) {
          const body = await response.json();

          this.errors = createErrorsFromApiResponse(body);

          return false;
        }

        return true;
      } catch (errors) {
        this.errors = createModelErrors({
          base: 'APP_NETWORK_ERROR'
        });

        return false;
      }
    }
  }
});

PendingLoanTransaction.transactionsKey = 'raw_loan_transaction_ids';

type AdjustPayloadT = {
  notes: string;
  bookingDate: string;
};
export interface LoanTransactionType extends TransactionType, Omit<InferredModel<typeof LoanTransaction>, 'errors'> {
  loanId: string;
  account: LoanProductType;
  valueDate: Date;
  bookingDate: Date;
  glAccountCode: string;
  user: UserT;
  adjust(data: AdjustPayloadT): Promise<boolean>;
  v1Adjust(loanId: string, data: AdjustData): Promise<boolean>;
}

export default LoanTransaction;
