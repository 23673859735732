import React from 'react';
import size from 'lodash/fp/size';

import { SavingType } from '@kwara/models/src';
import { ActionButton } from '@kwara/components/src/Button';
import { Stack } from '@kwara/components/src/Stack';
import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';
import Modal from '@kwara/components/src/Modal';

import { EmptyModal } from '../../components/EmptyModal';
import { SavingAccountCard } from '../../components/SavingAccountCard';

type SavingAccountsListProps = {
  savings: SavingType[];
};

export function SavingAccountsList({ savings }: SavingAccountsListProps) {
  const hasSavings = size(savings) > 0;

  return (
    <>
      <If
        condition={!hasSavings}
        do={
          <EmptyModal
            to="/"
            titleId={'SavingList.Empty.Modal.title'}
            subtitleId={'SavingList.Empty.Modal.subtitle'}
            actionId={'SavingList.Empty.Modal.action'}
          />
        }
        else={
          <Modal isOpen={true}>
            <div className="flex items-center self-start mh2">
              <ActionButton to="/overview/loans" hideBorder col="black" type="back" />
              <h3 className="kw-weight-bold kw-text-x-large ml3 mb3">
                <Text id={'SavingList.title'} />
              </h3>
            </div>
            <div className={'flex flex-column h-100 overflow-scroll'}>
              <Stack.Child>
                {savings.map(saving => (
                  <SavingAccountCard key={saving.name} savingAccount={saving} />
                ))}
              </Stack.Child>
            </div>
          </Modal>
        }
      />
    </>
  );
}
