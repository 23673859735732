import * as React from 'react';
import composeClassnames from 'classnames';

import zIndices from '@kwara/lib/src/zIndices';

import styles from './index.module.scss';
import { useBodyLocker } from './hooks/useBodyLocker';

type OverlayPropTypes = {
  opacityPercentage?: number;
  children: React.ReactNode;
};
/********************************************************************************************
 * @Overlay is a reusable ``` Overlay shadow-background ``` component
 * @opacityPercentage is prop to control the density of the background color.
 * Note, when you add a new opacityPercentage which is not defined in the css
 * module of the component, you must have to add it e.g:
 * ```
 *  .container--70 {
 *    background-color: rgba(65, 65, 65, 0.7); // And then you reduce 0.7 or increase it
 *    }
 * ```
 **********************************************************************************************/
export function Overlay(props: OverlayPropTypes) {
  useBodyLocker();

  return (
    <div
      className={composeClassnames(
        styles['container'],
        styles[`container--${props.opacityPercentage ?? 70}`],
        zIndices.Modal
      )}
    >
      {props.children}
    </div>
  );
}
