import { attr } from 'spraypaint';

import Base from './Base';
import { BaseI } from './_Base';

type FileType = 'CSV' | 'DOC' | 'DOCX' | 'JPEG' | 'JPG' | 'PDF' | 'PNG' | 'PPT' | 'PPTX' | 'TXT' | 'XLS' | 'XLSX';

export const LoanAttachment = Base.extend({
  static: {
    jsonapiType: 'loan_application_attachments'
  },
  attrs: {
    // WRITE
    attachment: attr(),

    // READ
    type: attr(),
    name: attr(),
    content: attr(),
    filename: attr()
  }
});

type WriteShape = {
  io: string;
  content_type: string;
  filename: string;
};

export interface LoanAttachmentT extends BaseI<LoanAttachmentT> {
  attachment: WriteShape;
  type: FileType;
  name: string;
  content: string;
  filename: string;
}
