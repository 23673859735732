import { useMemo } from 'react';

import { toDurationObjectUI } from '@kwara/models/src/models/util';

const ONE_MONTH = 1;
const THIRTY_DAYS = 30;
const FOUR_WEEKS = 4;

const DIVISOR = {
  MONTHS: ONE_MONTH,
  WEEKS: FOUR_WEEKS,
  DAYS: THIRTY_DAYS
};

/**
 * @derivedInstallmentMeta calculates minimum and maximum installment durations based on
 * input values and returns an object with these values along with a flag `renderable`
 * @param {string | null} [minDuration] - The `minDuration` parameter represents the minimum duration
 * for an installment. It is a string that can be converted into a duration object using the
 * `toDurationObjectUI` function.
 * @param {string | null} [maxDuration] - The `maxDuration` parameter represents the maximum duration
 * for an installment. It is used in the `derivedInstallmentMeta` function to calculate the maximum
 * installment value based on the provided duration.
 * @returns The function `derivedInstallmentMeta` is returning an object with properties `min`, `max`,
 * and `renderable`.
 */
function derivedInstallmentMeta(minDuration?: string | null, maxDuration?: string | null) {
  const { value: minInstallment, unit: minUnit } = toDurationObjectUI(minDuration);
  const { value: maxInstallment, unit: maxUnit } = toDurationObjectUI(maxDuration);
  const min = Math.ceil(minInstallment / DIVISOR[minUnit]) || ONE_MONTH;
  const max = Math.ceil(maxInstallment / DIVISOR[maxUnit]) || Infinity;
  const renderable = !maxDuration || max > ONE_MONTH;

  return { min, max, renderable };
}

function useInstallments(minDuration?: string | null, maxDuration?: string | null) {
  return useMemo(() => derivedInstallmentMeta(minDuration, maxDuration), [maxDuration, minDuration]);
}

export { derivedInstallmentMeta, useInstallments, ONE_MONTH };
