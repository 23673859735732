// @flow

import { attr } from 'spraypaint';

import Base, { type BaseModel } from './Base';

export const SmsBundle = Base.extend({
  static: {
    jsonapiType: 'sms_bundles'
  },
  attrs: {
    smsPlanId: attr(),
    numberOfSms: attr(),
    purchasePhoneNumber: attr()
  }
});

export interface SmsBundleT extends BaseModel<SmsBundleT> {
  smsPlanId: string;
  numberOfSms: number;
  purchasePhoneNumber: string;
}
