// @flow

import { DateRange } from './components/DateRange';
import { Email } from './components/Email';

export const steps = {
  dateRange: {
    titleId: 'Statement.Step.Date',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'email'
      }
    ],
    children: [
      {
        titleId: 'Statement.DateRange.title',
        Component: DateRange,
        validate: DateRange.validateConfig
      }
    ]
  },
  email: {
    titleId: 'Statement.Step.Email',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'Statement.Email.title',
        Component: Email,
        validate: Email.validateConfig
      }
    ]
  }
};
