// @flow

import * as React from 'react';
import cx from 'classnames';

import size from 'lodash/size';
import get from 'lodash/get';

import { appName } from '@kwara/lib/src/utils';
import { Text, type TranslationId } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';

import stylesCore from './indexCore.module.scss';
import stylesMobile from './indexMobile.module.scss';

//Returns true if value of the record is the DocumentList
// && it has no (non-profile-picture) attachments
const isEmptyDocumentList = element => {
  const attachments = get(element, 'props.value.props.attachments');
  return !!attachments && size(attachments) === 0;
};

const isValueNull = element => element.props.value == null && element.props.valueId == null;

const isEmptyRecord = element => isEmptyDocumentList(element) || isValueNull(element);

type NoRecordsMessageProps = {
  children: React.Node
};

export const NoRecordsMessage = ({ children }: NoRecordsMessageProps) => {
  return React.Children.toArray(children).every(isEmptyRecord) ? '-' : children;
};

type SectionProps = {
  titleId: TranslationId,
  children?: React.Node,
  editUrl?: string
};

export const Section = ({ titleId, children, editUrl }: SectionProps) => {
  const styles = appName.isMember ? stylesMobile : stylesCore;

  return (
    <section className={cx('bb b--light-grey-400', styles.Section)}>
      <h3 className={cx('ma0 kw-text-regular kw-weight-bold pb2', styles.Heading)}>
        <Text id={titleId} />
      </h3>
      <div
        className={cx('kw-text-regular grey-400', styles.Content, {
          ['bg-white pa3 br2']: appName.isMember
        })}
      >
        <div>
          <NoRecordsMessage>{children}</NoRecordsMessage>
        </div>
      </div>
      <div className={styles.EditColumn}>
        {editUrl ? (
          <Link to={editUrl} type="primary" underline active>
            <Text id="General.Edit" />
          </Link>
        ) : null}
      </div>
    </section>
  );
};

type RecordProps = {
  hidden?: boolean,
  labelId?: TranslationId,
  value?: ?React.Node,
  valueId?: TranslationId,
  values?: ?{ [string]: mixed }
};

export const Record = ({ hidden = false, labelId, value, valueId, values }: RecordProps) => {
  if (hidden) {
    return null;
  }

  const label = labelId ? <Text id={labelId} values={values} /> : null;
  const join = label ? ': ' : null;
  const data = valueId != null ? <Text id={valueId} values={values} /> : value;

  if (data == null) {
    return null;
  }

  return (
    <div className="ma0 mb1">
      {label}
      {join}
      {data}
    </div>
  );
};
