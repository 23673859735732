import map from 'lodash/fp/map';
import pickBy from 'lodash/fp/pickBy';
import negate from 'lodash/fp/negate';
import identity from 'lodash/fp/identity';
import isNumber from 'lodash/fp/isNumber';
import _isEmpty from 'lodash/fp/isEmpty';

type ConvertReturnee = <Returnee = any, E = any>(e: (el: E, index: number) => any, els: E[]) => Returnee;
type LodashMap = {
  convert: (v: any) => ConvertReturnee;
  [key: string]: any;
};

export const mapIndexed = ((map as unknown) as LodashMap).convert({ cap: false });

/**
 * @removeAt
 * Removes item from array by index. It does not mutate array
 */
export function removeAt(idx, arr = []) {
  return [...arr].filter((_e, i) => i !== idx);
}

/**
 * @isEmpty
 * lodash _isEmpty considers a number as
 * "empty", but our validation should not
 */
export function isEmpty(n: any) {
  return !isNumber(n) && _isEmpty(n);
}

export function onlyTruthyValues(collection: object) {
  return pickBy(identity, collection);
}

export const isFilled = negate(isEmpty);
