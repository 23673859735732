import React from 'react';

import { LoadingSkeleton } from '@kwara/components/src/Loadable/LoadingSkeleton';

import styles from './Loader.module.scss';
import iconKwaraBrand from './IconKwaraBrand.png';

export function Loader() {
  return (
    <ul className={styles['container']}>
      {[...new Array(3)].map((_, i) => (
        <li key={i.toString()}>
          <div className={styles['img-wrapper']}>
            <img alt="logo" src={iconKwaraBrand} />
          </div>
          <LoadingSkeleton shape="radius-horizone" width="100%" height="56px" noChildren />
        </li>
      ))}
    </ul>
  );
}
