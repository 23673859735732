import React from 'react';
import get from 'lodash/fp/get';
import Button from '@kwara/components/src/Button';
import { Text, Currency, Percent, Duration } from '@kwara/components/src/Intl';
import { type LoanProductType } from '@kwara/models/src';

import Instant from './assets/Instant.svg';
import LoanProduct from './assets/LoanProduct.svg';

import { LoanTypeTag } from './Components/Tag';
import { NewLoanDetailsList } from './Components/DetailsContent';

import { LoanCardBase } from './LoanCardBase';

const createDetailsContent = product => {
  const transId = 'LoanCard.NewLoanCard.Detail';
  return [
    <>
      <Text id={`${transId}.MaximumAmount`} />: {''}
      {product?.amount?.maximum ? (
        <Currency format="currency" value={product.amount.maximum} />
      ) : (
        <Text id={`${transId}.Unlimited`} />
      )}
    </>,
    <>
      <Text id={`${transId}.MinimumAmount`} />: {''}
      {product?.amount?.minimum ? (
        <Currency format="currency" value={product.amount.minimum} />
      ) : (
        <Text id={`${transId}.Unlimited`} />
      )}
    </>,
    <>
      <Text id={`${transId}.Collaterals`} />{' '}
      {<Text id={`${transId}.Collaterals.${String(product.security.collaterals_enabled)}`} />}
    </>,
    <>
      <Text id={`${transId}.Guarantors`} />{' '}
      {<Text id={`${transId}.Guarantors.${String(product.security.guarantors_enabled)}`} />}
    </>,
    <>
      <Text id={`${transId}.InterestRate`} />: {<Percent value={product.interestRate.percentage / 100} />}
    </>,
    <>
      <Text id={`${transId}.MaximumDuration`} />: {product.maxDuration ? <Duration value={product.maxDuration} /> : '-'}
    </>
  ];
};

export const LoanProductMobileCard = ({ product, to }: { product: LoanProductType, to: string }) => {
  const { name, instantLoan } = product;
  const eligibleAmount = get('eligibility.eligibleAmount', product);
  const detailsContent = createDetailsContent(product);

  return (
    <LoanCardBase
      className="mv3"
      title={name}
      icon={instantLoan ? Instant : LoanProduct}
      amountLabelId="LoanCard.EligibleAmount"
      amount={eligibleAmount || 0}
      tagComponent={instantLoan ? <LoanTypeTag instantLoan={instantLoan} /> : null}
      detailsContent={<NewLoanDetailsList detailsContent={detailsContent} />}
      footerContent={
        <Button data-testid={name.trim()} className="w-100 mh3 mv3" to={to} type="primary" size="large">
          <Text id="LoanCard.Action.Apply" />
        </Button>
      }
    />
  );
};
