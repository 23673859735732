import * as React from 'react';

import { SubscribedPinInputField, FOUR_DIGIT_PIN } from '@kwara/components/src/Form';
import { Text } from '@kwara/components/src/Intl';

export const PINInput = () => {
  return (
    <div className={'flex flex-column'}>
      <div className="pb4">
        <div className="pb3">
          <Text id="GuarantorshipRequest.Accept.enterPin" />
        </div>
        <SubscribedPinInputField name="pin" sensitiveValidation canHide required length={FOUR_DIGIT_PIN} />
      </div>
    </div>
  );
};
