// @flow

import * as React from 'react';
import getOr from 'lodash/fp/getOr';

import Button, { BackButton } from '@kwara/components/src/Button';
import Banner from '@kwara/components/src/Banner';
import Empty from '@kwara/components/src/Empty';
import { Stack, Child } from '@kwara/components/src/Stack';
import { Field, TextField } from '@kwara/components/src/Form';
import { Text } from '@kwara/components/src/Intl';
import { Auth } from '@kwara/models/src';

type Props<Permissions> = {
  auth: Auth<Permissions>
};

type State = {
  email: string,
  success: boolean,
  error: boolean
};

export class ForgetPassword<Permissions> extends React.Component<Props<Permissions>, State> {
  state = {
    email: '',
    success: false,
    error: false
  };

  handleChange = (e: SyntheticEvent<HTMLFormElement>) => {
    const email = getOr('', 'target.value', e);
    this.setState({ email });
  };

  handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ error: false, success: false });
    const { email } = this.state;
    const { auth } = this.props;
    auth
      .passwordForget({ email })
      .then(() => {
        this.setState({
          success: true
        });
      })
      .catch(() => {
        this.setState({
          error: true
        });
      });
  };

  render() {
    const { email, success, error } = this.state;
    return (
      <div className="flex flex-column h-100 justify-center">
        <Stack>
          <Child size="regular">
            <BackButton to={{ url: '/', routeName: 'Login' }} type="secondary" />
            <Empty>
              <div>
                <h2 className="grey-500">
                  <Text id="Password.Forget.title" />
                </h2>
                <form onSubmit={this.handleSubmit}>
                  <Field name="email" labelId="LogIn.email" size="medium">
                    <TextField name="email" size="regular" value={email} onChange={this.handleChange} />
                  </Field>
                  <Button isSubmit type="primary" size="regular" className="mb4" disabled={!email}>
                    <Text id="Password.Forget.submit" />
                  </Button>
                </form>

                {success && <Banner state="warning" text={<Text id="Password.Forget.submit.success" />} />}
                {error && <Banner state="error" text={<Text id="Password.Forget.submit.error" />} />}
              </div>
            </Empty>
          </Child>
        </Stack>
      </div>
    );
  }
}
