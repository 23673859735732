// @flow

import * as React from 'react';

import ActivityTimeline from '@kwara/components/src/ActivityTimeline';
import { Loadable } from '@kwara/components/src/Loadable';
import { useMember } from '@kwara/models/src/models/request/hooks';

import { useStore } from '../../models/Member';
import { CardLoader } from '../../components/CardLoader';

import Section from '../../components/Section';

const includes = ['activities'];

export const History = () => {
  const store = useStore();
  const response = useMember(store.memberId, includes);

  return (
    <Loadable {...response} loading={<CardLoader />}>
      {member => (
        <Section titleId="Activity.title">
          <ActivityTimeline activities={member.activities} />
        </Section>
      )}
    </Loadable>
  );
};
