// @flow

import axios from 'axios';

import { handleDownload, blobHeaders } from '@kwara/lib/src/fileDownload';
import { Logger } from '@kwara/lib/src/logger';

import Base from './Base';
import { type SavingType } from './Saving';
import { type LoanType } from './Loan';

export const SavingMemberStatement = Base.extend({
  static: {
    endpoint: '/savings_transactions.pdf',

    download({ from, to, saving }: { from: string, to: string, saving: SavingType }) {
      const url = `${SavingMemberStatement.url()}`;

      const fileName = `saving_statement_${from}_to_${to}`;
      const opts = SavingMemberStatement.fetchOptions();
      const options = {
        params: {
          'filter[from]': from,
          'filter[to]': to,
          'filter[savings_id]': saving.id
        },
        ...blobHeaders(opts)
      };

      return axios
        .get(url, options)
        .then(res => {
          try {
            handleDownload({ data: res.data, fileName, preview: true });
          } catch (e) {
            Logger.log('Error reading saving member statement PDF response', JSON.stringify(e));
            return Promise.reject(e);
          }
          return Promise.resolve();
        })
        .catch(e => {
          Logger.log('Error fetching saving member statement PDF data', JSON.stringify(e));
          return Promise.reject(e);
        });
    }
  }
});

export const LoanMemberStatement = Base.extend({
  static: {
    jsonapiType: 'loan_transactions.pdf',

    download({ from, to, loan }: { from: string, to: string, loan: LoanType }) {
      const url = `${LoanMemberStatement.url()}`;

      const fileName = `loan_statement_${from}_to_${to}`;
      const opts = LoanMemberStatement.fetchOptions();
      const options = {
        params: {
          'filter[from]': from,
          'filter[to]': to,
          'filter[loan_id]': loan.id
        },
        ...blobHeaders(opts)
      };

      return axios
        .get(url, options)
        .then(res => {
          try {
            handleDownload({ data: res.data, fileName, preview: true });
          } catch (e) {
            Logger.log('Error reading loan member statement PDF response', JSON.stringify(e));
            return Promise.reject(e);
          }
          return Promise.resolve();
        })
        .catch(e => {
          Logger.log('Error fetching loan member statement PDF data', JSON.stringify(e));
          return Promise.reject(e);
        });
    }
  }
});
