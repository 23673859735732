import React from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';

import './index.css';

SwiperCore.use([Pagination]);

interface SwipeablePropTypes extends Swiper {
  children: React.ReactNode;
}

/**
 * @Swipeable is a component that enables paginated views with the ability to swipe items left or right
 *
 * @sample
 * ```tsx
 *  <Swipeable>
 *   <h1 className="slide">Slide 1</h1>
 *   <h1 className="slide">Slide 2</h1>
 *   <h1 className="slide">Slide 3</h1>
 *   <h1 className="slide">Slide 4</h1>
 *  </Swipeable>
 * ```
 */
export function Swipeable({ children, ...rest }: SwipeablePropTypes) {
  return (
    <Swiper pagination={{ clickable: true }} spaceBetween={5} slidesPerView={1.15} {...rest}>
      {React.Children.map(children, child => (
        <SwiperSlide>{child}</SwiperSlide>
      ))}
    </Swiper>
  );
}
