import { attr, hasMany } from 'spraypaint';
import { ValueOf } from 'GlobalTypes';

import Base, { BaseModel } from './Base';
import { LoanAttachment, LoanAttachmentT } from './LoanAttachment';

export const Collateral = Base.extend({
  static: {
    jsonapiType: 'collaterals'
  },
  attrs: {
    amount: attr(),
    collateralNarration: attr(),
    assetName: attr(),
    assetBankName: attr(),
    assetBankBranch: attr(),
    assetBankAccount: attr(),
    landRegistration: attr(),
    insuranceCompany: attr(),
    insurancePolicyNumber: attr(),
    nseStockCds: attr(),
    realValue: attr(),
    forcedSellValue: attr(),
    attachments: hasMany({ type: LoanAttachment })
  },
  methods: {}
});

export const CollateralTypes = Object.freeze({
  LAND: 'land',
  STOCK: 'stock',
  BANK_ACCOUNT: 'bank',
  LIFE_INSURANCE: 'insurance'
});
export type CollateralTypeT = ValueOf<typeof CollateralTypes>;

export interface CollateralT extends BaseModel<CollateralT> {
  amount: string;
  accountNumber?: string;
  assetBankBranch?: string;
  assetBankName?: string;
  assetBankAccount?: string;
  id: string;
  insurancePolicyNumber?: string;
  insuranceCompany?: string;
  collateralNarration: string;
  assetName: CollateralTypeT;
  landRegistration: string;
  nseStockCds?: string;
  realValue: number;
  forcedSellValue: number;
  attachments: LoanAttachmentT;
}

export const CollateralFields = {
  id: 'id',
  amount: 'amount',
  type: 'assetName',
  bank: 'assetBankName',
  branch: 'assetBankBranch',
  account: 'assetBankAccount',
  narration: 'collateralNarration',
  insuranceNumber: 'insurancePolicyNumber',
  insuranceCompany: 'insuranceCompany',
  landRegistration: 'landRegistration',
  stockCds: 'nseStockCds',
  realValue: 'realValue',
  forcedSellValue: 'forcedSellValue'
};
