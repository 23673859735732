import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Logger } from '@kwara/lib/src/logger';
import { isTest } from '@kwara/lib/src/utils';
import { getWindowDimensions } from '@kwara/lib/src/hooks/useWindowDimensions';

const isEnabled = () => !isTest && !(Segment as any).appStore.isE2E;

export const useSegmentIdentify = auth => {
  const userId = auth.getUserId();

  useEffect(() => {
    if (isEnabled() && userId) {
      try {
        window && window.analytics && window.analytics.identify(userId);
      } catch (error) {
        Logger.error('[Segment error]', error);
      }
    }
  }, [userId]);
};

export const segmentTrack = (event: string, props = {}) => {
  if (!isEnabled()) {
    return;
  }

  try {
    window &&
      window.analytics &&
      window.analytics.track(event, {
        ...props,
        organisation_name: (Segment as any).appStore.branchName
      });
  } catch (error) {
    Logger.error('[Segment error]', error);
  }
};

type SegmentPropTypes = {
  children?: React.ReactNode;
  auth: any;
};

export const Segment = ({ auth, children }: SegmentPropTypes) => {
  const history = useHistory();

  useEffect(() => {
    let prevPath = null;

    return history.listen(location => {
      if (isEnabled() && location.pathname !== prevPath) {
        prevPath = location.pathname;

        try {
          const { width, height } = getWindowDimensions();
          window &&
            window.analytics &&
            window.analytics.page({ user_id: auth.getUserId(), screen_width: width, screen_height: height });
        } catch (error) {
          Logger.error('[Segment error]', error);
        }
      }
    });
  }, [history, auth]);

  return children ?? null;
};
