// @flow

import * as React from 'react';
import type { History } from 'history';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import merge from 'lodash/merge';
import size from 'lodash/fp/size';
import keys from 'lodash/fp/keys';
import first from 'lodash/fp/first';
import values from 'lodash/fp/values';
import indexOf from 'lodash/fp/indexOf';
import { type FormProps } from 'react-final-form';

import type { Location } from '@kwara/components/src/Actionable';
import type { LinkContext } from '@kwara/components/src/Button/linkContext';
import { type ValidationRules } from '@kwara/lib/src/validator';
import { Logger } from '@kwara/lib/src/logger';
import { Text, type TranslationId } from '@kwara/components/src/Intl';
import Modal from '@kwara/components/src/Modal';
import { segmentTrack } from '@kwara/components/src/Segment';
import { appName } from '@kwara/lib/src/utils';

import { WithNotification, type notification as notificationT } from '@kwara/components/src/Notification';
import { WizardHeader } from '@kwara/components/src/Wizard/deprecated/WizardHeader';
import { AutoDismissableCompletion } from '@kwara/components/src/Completion';
import Head from '@kwara/components/src/Head';

import { ConfirmCancel } from './ConfirmCancel';
import DelayedAnimation from './DelayedAnimation';
import Action, { type Action as ActionT, type ActionConfig } from './Action';
import FormSteps from './FormSteps';
import { type ComponentProps } from './SubStep';

const UNAUTHORIZED_ERROR = 'Unauthorized';

export type StackChildSize = 'regular' | 'medium' | 'wide' | 'widest' | 'stretch';
export interface SubStepComponentProps<T, CustomProps = void> extends ComponentProps<T> {
  customProps?: CustomProps;
  history: History;
  onChange: (data: T) => Promise<T>;
  formProps: FormProps<ComponentProps<T>>;
  disableNextButton: Function;
  enableNextButton: Function;
  data: T;
  addData: (updated: T) => void;
  addDataAndContinue: (updated: T) => void;
}

export type StepId = string;
export type StepPath = string;

const WizardType = {
  approval: 'approval',
  submission: 'submission'
};

export type ActionEvent<Payload> = {
  action: Action,
  payload: Payload
};

type GenericCustomProps = {
  [prop: string]: any
};

export type SequentialSubStep = {
  titleId?: TranslationId,
  subtitleId?: TranslationId,
  Component: React.ComponentType<*>,
  progress?: boolean,
  // validate?: ValidationRules // for some reason this crashes Flow. ch7945
  customProps?: GenericCustomProps
};

export type SequentialStep = {
  titleId?: TranslationId,
  subtitleId?: TranslationId,
  children: SequentialSubStep[],
  actions: ActionConfig[],
  isPermitted?: boolean
};

export type DirectStep = {
  titleId?: TranslationId,
  subtitleId?: TranslationId,
  Component: React.ComponentType<*>,
  validate?: ValidationRules,
  customProps?: GenericCustomProps,
  actions: ActionConfig[],
  isPermitted?: boolean
};

export type RenderableParentStep = {
  id: StepId,
  titleId?: TranslationId,
  subtitleId?: TranslationId,
  actions: ActionConfig[],
  isPermitted?: boolean
};

export type RenderableStep = {
  titleId?: TranslationId,
  subtitleId?: TranslationId,
  Component: React.ComponentType<*>,
  actions: ActionT[],
  progress?: boolean,
  validate?: ValidationRules,
  customProps?: GenericCustomProps,
  isPermitted?: boolean,
  parent?: RenderableParentStep,
  hideActions?: boolean,
  hideErrorBanner?: boolean
};

export type RenderableStepConfig = {
  [id: StepId]: RenderableStep
};

export type StepConfig = {
  [id: StepId]: DirectStep | SequentialStep
};

// Defines an interface for the ModalComponent that can be injected into the Wizard
// This is used for passing in a mock Modal when testing
type ModalComponentType = React.ComponentType<{
  isOpen: boolean,
  className?: string,
  children: React.Node
}>;

type Props<Data> = {
  analyticsId: string,
  animated?: boolean,
  bannerContent?: React.Node,
  EnterPIN?: React.Node,
  baseUrl?: string,
  cancelReturnsTo: Location,
  onCancel?: () => void,
  completionAutoConfirm?: boolean,
  completionTimeoutSecs?: number,
  currentStep: string,
  currentSubStep: ?number | ?string,
  history: History,
  initialData?: Data,
  location: Location & { state: ?{ from: LinkContext } },
  notification: notificationT,
  ModalComponent: ModalComponentType,
  onAction?: (event: ActionEvent<*>) => void,
  onReject?: (d: Data) => Promise<*>,
  onSubmit: (d: Data) => Promise<*>,
  onSubmitCompletion?: () => void,
  rejectSubtitleId?: TranslationId,
  requireCancelConfirm?: boolean,
  steps: StepConfig,
  startId: StepId,
  successSubtitleId?: TranslationId,
  successButtonId?: TranslationId,
  titleId: TranslationId,
  type?: $Values<typeof WizardType>,
  onRenderCustomCompletionScreen?: Function,
  showCompletion: boolean
};

const Completed = {
  Incomplete: 'Incomplete',
  Processing: 'Processing',
  Successful: 'Successful',
  Rejected: 'Rejected',
  Error: 'Error'
};

type CompletionState = $Values<typeof Completed>;

type State<Data> = {
  completed: CompletionState,
  error: ?typeof Error,
  data: Data,
  cancelReturnsTo: string,
  showConfirmCancel: boolean,
  showPinEntry: boolean
};

const parseCurrentSubStep = (subStep: ?string | ?number): ?number => parseInt(subStep, 10);
export const getSubstepFromMatchParams = ({ subStep }: { subStep: string }) =>
  subStep != null ? parseCurrentSubStep(subStep) : null;

// Some forms rely on Spraypaint instances as models.
// We use merge so that when we pass an instance (of Member, Loan or similar) we
// 1. mutate the instance itself by applying the new props, so that Spraypaint
//    will detect these changes (important when PATCHing)
// 2. recursively copy all nested relationships and instances,
//    so we don't lose track of nested relationships needed by Spraypaint
// 3. finally, we return a clone so that React detects the new model as a new object.
// Note that this will not override arrays, but will merge them, so that data: [1,2,3] updates: [1, 4]
// Will result in [1, 4, 3], see " Array and plain object properties are merged recursively" on
// https://lodash.com/docs/4.17.13#merge
// If you need to overwrite arrays atm the only way is mutating "data". Overwriting can be achieved
// here by using mergeWith and configuring a specific behaviour with arrays, but we must account
// for Spraypaint relationships when doing it. See ch6500
export const patchValues = (data, updates) => {
  // if the base data is an instance...
  if (data.isSpraypaintInstance) {
    // ...we mutate the instance to notify Spraypaint...
    // (merging is fixed in Lodash v.4.17.5 https://github.com/lodash/lodash/wiki/Changelog#v4175)
    /* eslint-disable-next-line prototype-pollution-security-rules/detect-merge-objects */
    const merged = merge(data, updates);
    // ...and we return a copy so that React state diffing always detects the change
    return merged.dup();
  }

  // For simple objects we just merge the two objects into a new one
  return {
    ...data,
    ...updates
  };
};

const getStepNumber = (currentStep, steps) => {
  const currentIndex = indexOf(currentStep, keys(steps));
  return currentIndex + 1;
};

export class Wizard extends React.Component<Props<*>, State<*>> {
  static defaultProps = {
    animated: false,
    ModalComponent: Modal,
    type: WizardType.submission
  };

  static Type = WizardType;

  constructor(props: Props<*>) {
    super(props);

    // TODO: Handle case where `data` prop will update?
    this.state = {
      completed: Completed.Incomplete,
      error: null,
      data: props.initialData != null ? props.initialData : {},
      showConfirmCancel: false,

      // This is specified as a prop but can be overriden
      // by an incoming link, using the `state` property
      cancelReturnsTo: get(this.props.location, 'state.from.url', props.cancelReturnsTo)
    };
  }

  componentDidUpdate() {
    Logger.log('Data', this.state.data);
  }

  rejectAndComplete = async () => {
    if (this.props.onReject == null) {
      throw new Error('onReject not supplied');
    }

    try {
      this.completed(Completed.Processing);

      await this.props.onReject(this.state.data);

      this.completed(Completed.Rejected);
    } catch (error) {
      this.completed(Completed.Error);
      this.setState({
        error
      });
    }
  };

  submitAndComplete = async () => {
    try {
      this.completed(Completed.Processing);

      await this.props.onSubmit(this.state.data);

      this.completed(Completed.Successful);
    } catch (errors) {
      const error = first(values(errors));
      this.completed(Completed.Error);
      if (appName.isMember && error && error.title === UNAUTHORIZED_ERROR) {
        this.setState({ showPinEntry: true });
      } else {
        this.setState({
          error: errors,
          showPinEntry: false
        });
      }
    }
  };

  cancel = () => {
    this.props.history.push(this.state.cancelReturnsTo);
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this.isCompletedState() && this.props.onSubmitCompletion && this.props.onSubmitCompletion();
  };

  completed = (completed: CompletionState) => {
    this.setState({
      completed
    });
  };

  completedSubtitle = () => {
    const { rejectSubtitleId, successSubtitleId } = this.props;
    const { completed } = this.state;

    if (completed === Completed.Successful && successSubtitleId) {
      return <Text id={successSubtitleId} values={this.state.data} />;
    } else if (completed === Completed.Rejected && rejectSubtitleId) {
      return <Text id={rejectSubtitleId} values={this.state.data} />;
    }

    return null;
  };

  renderAnimatedCompletion = ({ onCancel }: { onCancel: () => void }) => {
    return (
      <DelayedAnimation onDone={onCancel}>{({ hide }) => this.renderCompletion({ onCancel: hide })}</DelayedAnimation>
    );
  };

  renderCompletion = ({ onCancel }: { onCancel: () => void }) => {
    const { completed, data } = this.state;
    const { completionAutoConfirm = true, successButtonId } = this.props;

    return (
      <AutoDismissableCompletion
        autoconfirm={completionAutoConfirm}
        analyticsId={this.props.analyticsId}
        autoconfirmTimeoutSecs={4}
        type={
          completed === Completed.Successful
            ? AutoDismissableCompletion.Types.good
            : AutoDismissableCompletion.Types.bad
        }
        onConfirm={onCancel}
        subtitle={this.completedSubtitle()}
        values={data}
        buttonTextId={successButtonId}
      />
    );
  };

  renderCompletionBanner = (bannerContent: React.Node) => {
    const { notification } = this.props;
    notification.displayMessage(bannerContent);
    this.cancel();
  };

  handleCompletion = () => {
    const { animated, bannerContent, onRenderCustomCompletionScreen, showCompletion = true } = this.props;
    const onCancel = this.cancel;

    if (!showCompletion) {
      return null;
    }

    if (onRenderCustomCompletionScreen) {
      return onRenderCustomCompletionScreen(onCancel, this.state.data);
    }

    if (animated) {
      return this.renderAnimatedCompletion({ onCancel });
    }

    if (bannerContent) {
      return this.renderCompletionBanner(bannerContent);
    }

    return this.renderCompletion({ onCancel });
  };

  onChange = updates => {
    // This promise will resolve when the state has been
    // applied successfully
    return new Promise(resolve => {
      this.setState(
        state => ({
          // (merging is fixed in Lodash v.4.17.5 https://github.com/lodash/lodash/wiki/Changelog#v4175)
          /* eslint-disable-next-line prototype-pollution-security-rules/detect-merge-objects */
          data: merge(state.data, updates)
        }),
        resolve
      );
    });
  };

  defaultOnAction = ({ action, payload }: ActionEvent): void => {
    const { baseUrl, history } = this.props;

    switch (action.behavesAs) {
      case 'back':
        history.goBack();
        break;
      case 'nextWithPromise':
        this.completed(Completed.Processing);
        action
          .onNext(this.state.data, this.onChange)
          .then(() => {
            this.completed(Completed.Incomplete);
            this.setState({
              error: null
            });
            history.push(`${baseUrl}/${String(action.destinationPath)}`);
          })
          .catch(error => {
            this.completed(Completed.Incomplete);
            this.setState({
              error
            });
          });
        break;
      case 'next':
      case 'skip':
        history.push(`${baseUrl}/${String(action.destinationPath)}`);
        break;
      default:
        Logger.warn(`No action for "${action.behavesAs}"`, action, payload);
    }
  };

  isCompletedState = () => {
    return [Completed.Incomplete, Completed.Processing, Completed.Error].indexOf(this.state.completed) === -1;
  };

  confirmCancel = () => {
    this.setState({ showConfirmCancel: true });
  };

  remain = () => {
    this.setState({ showConfirmCancel: false });
  };

  render() {
    const {
      baseUrl,
      currentStep,
      currentSubStep,
      ModalComponent,
      EnterPIN,
      onAction = this.defaultOnAction,
      steps,
      startId,
      titleId,
      requireCancelConfirm = true
    } = this.props;

    const { completed, data, error, showConfirmCancel, showPinEntry } = this.state;
    const onCancel = requireCancelConfirm ? this.confirmCancel : this.cancel;
    const onRemain = this.remain;

    const handleAction = ({ action }: { action: Action }) => {
      if (action.behavesAs === 'complete' || action.behavesAs === 'approve') {
        // Segment
        segmentTrack(`${this.props.analyticsId} Submit Button Clicked`);

        this.submitAndComplete();
      } else if (action.behavesAs === 'reject') {
        this.rejectAndComplete();
      } else if (action.behavesAs === 'cancel') {
        onCancel();
      } else {
        onAction({ action, payload: data });
      }
    };

    const isCompletedState = this.isCompletedState();

    return (
      <>
        <ModalComponent
          isOpen
          className={isCompletedState ? 'justify-center' : ''}
          ariaLabel={this.props.analyticsId ? this.props.analyticsId + 'Wizard' : null}
        >
          <Head titleId={titleId} values={data} />

          {isCompletedState ? (
            this.handleCompletion()
          ) : (
            <>
              <ConfirmCancel hide={!showConfirmCancel} onCancel={this.cancel} onRemain={onRemain} />
              <WizardHeader
                titleId={titleId}
                onCancel={onCancel}
                values={data}
                currentStep={getStepNumber(currentStep, steps)}
                steps={size(steps)}
              />
              <FormSteps
                currentStep={currentStep}
                currentSubStep={parseCurrentSubStep(currentSubStep)}
                currentState={this.state.data}
                isProcessing={completed === Completed.Processing}
                steps={steps}
                startId={startId}
                parentUrl={baseUrl}
                error={error}
                setError={(err: ?typeof Error) => this.setState(prev => ({ ...prev, error: err }))}
                onAction={handleAction}
                onChange={this.onChange}
              />
            </>
          )}
        </ModalComponent>
        {showPinEntry ? (
          <EnterPIN history={this.props.history} onClose={() => this.setState({ showPinEntry: false })} />
        ) : null}
      </>
    );
  }
}

export default withRouter(WithNotification(Wizard));
