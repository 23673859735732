// @flow
import React from 'react';
import cx from 'classnames';
import sortBy from 'lodash/fp/sortBy';
import indexOf from 'lodash/fp/indexOf';
import isEmpty from 'lodash/fp/isEmpty';
import noop from 'lodash/fp/noop';
import map from 'lodash/map';
import pipe from 'lodash/fp/pipe';
import _values from 'lodash/fp/values';
import every from 'lodash/fp/every';
import defaultsDeep from 'lodash/fp/defaultsDeep';
import { Field as FinalFormField } from 'react-final-form';
import { type FieldRenderProps } from 'react-final-form';
import { DateTime } from 'luxon';

import { Text } from '@kwara/components/src/Intl';
import { getCurrentYear, getYear, getMonth, getDayOfMonth } from '@kwara/lib/src/dates';

import { TextField, Field } from '.';
import { InfoWrapper } from './Field';

import styles from './DatePickerInputs.module.css';
import { appName } from '@kwara/lib/src/utils';

type FieldName = 'day' | 'month' | 'year';

type DatePickerFields = $ReadOnlyArray<{
  name: FieldName,
  max: number,
  maxlength: number,
  min: number,
  labelId: string,
  placeholder: string
}>;

const fields: DatePickerFields = [
  {
    labelId: 'Dates.Day',
    name: 'day',
    max: 31,
    min: 1,
    maxlength: 2,
    placeholder: 'DD'
  },
  {
    labelId: 'Dates.Month',
    name: 'month',
    max: 12,
    min: 1,
    maxlength: 2,
    placeholder: 'MM'
  },
  {
    labelId: 'Dates.Year',
    name: 'year',
    max: getCurrentYear(),
    min: 1900,
    maxlength: 4,
    placeholder: 'YYYY'
  }
];

type Order = Array<FieldName>;
const defaultOrder: Order = ['day', 'month', 'year'];

const checkValues = (state, key) =>
  pipe(
    _values,
    every(v => v[key])
  )(state);

type OutputValue = { value: string, dirty: boolean };
type OutputValues = {
  day: OutputValue,
  month: OutputValue,
  year: OutputValue
};
type InputShape = $PropertyType<FieldRenderProps, 'input'>;
type OnChangeShape = {
  value: string,
  name: FieldName,
  input: InputShape
};

export const initialState = {
  values: {
    day: { dirty: false, value: '', blurred: false },
    month: { dirty: false, value: '', blurred: false },
    year: { dirty: false, value: '', blurred: false }
  },
  dirty: false,
  blurred: false,
  error: ''
};

const withState = Component =>
  class DatePickerState extends React.Component<
    PickerUIProps,
    { values: OutputValues, dirty: boolean, blurred: boolean, error: string }
  > {
    state = initialState;

    constructor(props: PickerUIProps) {
      super(props);
      const month = getMonth(props.initial);

      // So that a prefilled form gets validated anyway

      const dirty = !!this.props.initial;

      // see ch10722 - When the date field is initialized with
      // data, we we want to consider it both dirty and blurred,
      // so that it is validated properly and the value bubbles up
      // immediately (without having to blur all fields)
      this.state.dirty = dirty;
      this.state.blurred = dirty;
      this.state.values = defaultsDeep(this.state.values, {
        year: { value: getYear(props.initial) || '', dirty, blurred: false },
        month: {
          value: Number.isNaN(month) ? '' : month + 1,
          dirty,
          blurred: false
        },
        day: {
          value: getDayOfMonth(props.initial) || '',
          dirty,
          blurred: false
        }
      });
    }

    bubbleUpValues = ({ input }: { input: InputShape }) => {
      const { day, month, year } = this.state.values;
      const { dirty } = this.state;
      const dayValue = parseInt(day.value, 10);
      const monthValue = parseInt(month.value, 10);
      const yearValue = parseInt(year.value, 10);

      const allEmpty = isEmpty(day.value) && isEmpty(month.value) && isEmpty(year.value);

      // We fail early for clearly invalid values for day, month & year [ch5008]
      // We allow leading 0s so as not to prematurely show an error.
      // If a user leaves the field 0, it will be considered invalid in a later check [ch5888]
      if (dayValue < 0 || dayValue > 31) {
        return this.showError('FormErrors.error.invalidDay');
      }

      if (monthValue < 0 || monthValue > 12) {
        return this.showError('FormErrors.error.invalidMonth');
      }

      if (yearValue < 1 || yearValue > 2999) {
        return this.showError('FormErrors.error.invalidYear');
      }

      // Make sure the value bubbles up only after all three fields have been filled
      if (!dirty) {
        return;
      }

      // Make sure if all fields are empty the value is reset to null
      if (allEmpty) {
        this.setState({ error: '' });
        return input.onChange(null);
      }

      try {
        const d = DateTime.fromObject({
          day: dayValue,
          month: monthValue,
          year: yearValue
        });
        if (d.isValid) {
          this.setState({ error: '' });
          input.onChange(d.toISODate());
        } else {
          throw {};
        }
      } catch (_e) {
        this.showError('FormErrors.error.invalidDate');
        // if date is in an invalid state, its value is null
        input.onChange(null);
      }
    };

    onBlur = ({ name, input, value }: OnChangeShape) => {
      const values = {
        ...this.state.values,
        [name]: {
          ...this.state.values[name],
          value,
          blurred: true
        }
      };
      const blurred = checkValues(values, 'blurred');
      this.setState(
        {
          values,
          blurred
        },
        () => this.bubbleUpValues({ input })
      );
    };

    onChange = ({ value, name, input }: OnChangeShape): void => {
      const values = {
        ...this.state.values,
        [name]: {
          ...this.state.values[name],
          value,
          dirty: true
        }
      };
      const dirty = checkValues(values, 'dirty');
      this.setState(
        {
          values,
          dirty,
          error: '' // we make sure every validation will be re-evaluated from a clean slate
        },
        () => this.bubbleUpValues({ input })
      );
    };

    showError(msg: string) {
      this.setState({ error: msg });
    }

    render() {
      const { props, state } = this;
      return <Component {...props} state={state} onBlur={this.onBlur} onChange={this.onChange} />;
    }
  };

type PickerUIProps = {
  errorBaseId: string,
  initial: Date | string,
  labelId: string,
  infoId: string,
  required: boolean,
  name: string,
  onChange: (p: OnChangeShape) => void,
  onBlur: (p: OnChangeShape) => void,
  order?: Order,
  state: {
    dirty: boolean,
    values: OutputValues,
    error: string,
    blurred: boolean
  }
};

export function DatePickerInputs({
  errorBaseId = 'FormErrors',
  labelId,
  name,
  required,
  infoId,
  onChange,
  onBlur = noop,
  order = defaultOrder,
  state
}: PickerUIProps) {
  // order the D/M/Y fields based on the order passed to the component
  const inputFields: DatePickerFields = sortBy(f => indexOf(f.name, order), fields);

  return (
    <div className={styles.Container}>
      <FinalFormField name={name}>
        {({ meta, input }) => {
          // This is where we decide whether or not to surface the errror to the user.
          // This follows this logc:
          // 1. if an internal error (more relaxed rules) is thrown always show it as soon as it
          // happens. For example if a user types "77" in the month field, show the error straight
          // away.
          // 2. if an error coming from the external validation is passed down only show it when
          // ALL 3 fields are blurred. For ex. If the form only accepts dates in the future and the
          // user types "1977" in the year field, this error will be shown only when both day and
          // month has been entered and the year field is blurred

          const hasError =
            state.error || // => 1. (the private validation of this component fails)
            (meta.error && state.blurred); // => 2. (errors are coming from outside,
          // ie from the custom form validation)

          return (
            <>
              <input hidden type="text" value={input.value} readOnly /> <br />
              <p
                className={cx('ma0 grey-400', {
                  ['kw-text-regular']: appName.isSacco,
                  ['mobile-text-medium']: appName.isMember
                })}
              >
                <Text id={labelId} />
              </p>
              {map(inputFields, f => (
                <div
                  className={cx(styles.Input, {
                    [styles.InputYear]: f.name === 'year'
                  })}
                  key={f.name}
                >
                  <Field name={f.name} size="medium" required={required} labelId={f.labelId} margin={false}>
                    <TextField
                      name={f.name}
                      type="number"
                      inputMode="tel"
                      min={f.min}
                      max={f.max}
                      size="regular"
                      placeholder={f.placeholder}
                      value={state.values[f.name].value}
                      onBlur={e => {
                        onBlur({
                          name: f.name,
                          value: e.target.value,
                          input
                        });
                      }}
                      onChange={e => {
                        // Make sure no more than maxlength can be typed into the field
                        if (e.target.value.length <= f.maxlength) {
                          onChange({
                            name: f.name,
                            value: e.target.value,
                            input
                          });
                        }
                      }}
                    />
                  </Field>
                </div>
              ))}
              <div className="pb4 pt2">
                {/* Internal validation takes precedence */}
                <InfoWrapper
                  error={!!hasError}
                  errorId={state.error || `${errorBaseId}.error.${meta.error}`}
                  infoId={infoId}
                />
              </div>
            </>
          );
        }}
      </FinalFormField>
    </div>
  );
}

export default withState(DatePickerInputs);
