import { attr } from 'spraypaint';
import axios, { AxiosRequestConfig } from 'axios';

import { blobHeaders, handleDownload } from '@kwara/lib/src/fileDownload';

import Base, { BaseModel } from './Base';
import { AccountingReportT } from './AccountingReport';

const mimeTypesMaps = Object.freeze({
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
});
const mimeTypes = Object.freeze(Object.keys(mimeTypesMaps));

export const ReportTemplate = Base.extend({
  static: {
    jsonapiType: 'report_templates',
    endpoint: '/reporting/templates'
  },
  attrs: {
    name: attr(),
    key: attr(),
    description: attr()
  },
  methods: {
    async generate(accountingReport: AccountingReportT) {
      const url = `${Base.baseUrl}/reporting/sasra.xlsx`;
      const baseOpts = ReportTemplate.fetchOptions();
      const blobOpts = blobHeaders(baseOpts) as AxiosRequestConfig<any>;
      const fileName = `sasra_${this.key}`;

      const opts = { params: { report_template_id: this.id, accounting_report_id: accountingReport.id }, ...blobOpts };
      return axios.get(url, opts).then(res => {
        try {
          handleDownload({
            data: res.data,
            fileName,
            mimeTypes
          });
        } catch (e) {
          return Promise.reject(e);
        }

        return Promise.resolve();
      });
    }
  }
});

export interface ReportTemplateT extends BaseModel<ReportTemplateT> {
  name: string;
  key: string;
  description: string;
}
