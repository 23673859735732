// @flow

import * as React from 'react';

import Modal from '@kwara/components/src/Modal';
import { Stack, Child, type ChildProps } from '@kwara/components/src/Stack';

import Head from '../Head';

import { Actions, type Props as ActionsProps } from './Actions';
import Header, { type Props as HeaderProps } from './Header';
import { AutoDismissableCompletion } from '../Completion';

import styles from './index.module.scss';

export { Panel } from '@kwara/components/src/Panel';

type Props = ActionsProps &
  HeaderProps & {
    childSize?: $PropertyType<ChildProps, 'size'>,
    actions?: boolean,
    header?: boolean,
    children?: React.ChildrenArray<React.Element<typeof Panel> | React.Element<typeof AutoDismissableCompletion>> | null
  };

export const ActionModal = ({
  actions,
  confirmId,
  children,
  childSize,
  header,
  disabled,
  onCancel,
  onConfirm,
  hideConfirm = false,
  titleId
}: Props) => (
  <Modal isOpen>
    <div className={styles.Scrollable}>
      <Head titleId={titleId} />
      {header && <Header onCancel={onCancel} titleId={titleId} />}
      <Stack>
        <Child size={childSize}>{children ? children : null}</Child>
        <Child size="stretch">
          {actions ? (
            <Actions
              confirmId={confirmId}
              onCancel={onCancel}
              onConfirm={onConfirm}
              disabled={disabled}
              hideConfirm={hideConfirm}
            />
          ) : null}
        </Child>
      </Stack>
    </div>
  </Modal>
);

ActionModal.defaultProps = {
  actions: true,
  disabled: false,
  header: true
};
