import React from 'react';
import classnames from 'classnames';

import Actionable from '@kwara/components/src/Actionable';

import styles from './styles.module.css';

const classes = {
  base: `${styles.base}`,
  underline: 'underline',
  noUnderline: 'no-underline',
  small: 'kw-text-small',
  regular: 'kw-text-regular',
  medium: 'kw-text-medium',
  primary: 'indigo-500',
  secondary: 'grey-500',
  destructive: 'red-600',
  'primary-disabled': `${styles.disabled} indigo-300`,
  'secondary-disabled': `${styles.disabled} grey-300`,
  'destructive-disabled': `${styles.disabled} red-500`,
  'primary-inactive': `indigo-400`,
  'secondary-inactive': `grey-400`,
  'destructive-inactive': `red-400`
};

type Props = {
  disabled: boolean,
  to: string,
  size: 'small' | 'regular' | 'medium',
  type: 'primary' | 'secondary',
  underline: boolean,
  active: boolean
};

export const Link = ({
  className,
  Component = Actionable,
  disabled,
  active = true,
  size = 'regular',
  to,
  type = 'secondary',
  underline = true,
  ...rest
}: Props) => (
  <Component
    disabled={disabled}
    to={Component !== 'a' ? to : null}
    href={Component === 'a' ? to : null}
    {...rest}
    className={classnames(
      classes.base,
      'pointer',
      classes[size],
      disabled ? classes[`${type}-disabled`] : classes[type],
      underline ? classes.underline : classes.noUnderline,
      active ? classes[`${type}-active`] : classes[`${type}-inactive`],
      className
    )}
  />
);
