import * as React from 'react';
import { Field as FinalFormField } from 'react-final-form';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';

import { type TranslationId } from '@kwara/components/src/Intl';
import type { InputProps, FieldProps } from '@kwara/components/src/Form';
import { composeEvents } from '@kwara/lib/src/utils/composeEvents';

import { Field } from '..';
import styles from './SubscribedPhoneField.module.scss';
import { useCountryCode } from './useCountryCode';

interface SubscribedPhoneFieldProps extends PhoneInputProps, InputProps, FieldProps {
  country?: string;
  placeholderId?: TranslationId;
  disableDropdown?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEvent<HTMLInputElement>;
  showInfo?: boolean;
}

export function SubscribedPhoneField({
  country,
  compact = false,
  disabled,
  disableDropdown = false,
  error = false,
  errorBaseId,
  errorValues = {},
  hidden,
  info,
  infoId,
  margin,
  initialValue,
  labelId,
  name,
  placeholderId,
  required,
  titleId,
  values,
  onBlur,
  onChange,
  showInfo,
  ...restProps
}: SubscribedPhoneFieldProps) {
  const countryCode = useCountryCode();
  const currentCountryCode = country || countryCode;

  return (
    <FinalFormField
      name={name}
      initialValue={initialValue}
      placeholderId={placeholderId}
      render={({ input, meta }) => {
        if (!meta.visited && initialValue) input.onChange(initialValue);

        const showError = !!(meta.touched && meta.error) || error;

        return (
          <Field
            compact={compact}
            margin={margin}
            disabled={disabled}
            name={input.name}
            size="medium"
            labelId={labelId}
            titleId={titleId}
            error={showError}
            errorBaseId={errorBaseId}
            errorCodes={meta.error}
            info={info}
            infoId={infoId}
            required={required}
            hidden={hidden}
            values={{ ...values, ...errorValues }}
            showInfo={showInfo}
          >
            <PhoneInput
              {...restProps}
              country={input.value ? country : currentCountryCode}
              disableDropdown={disableDropdown}
              disabled={disabled}
              countryCodeEditable={false}
              onChange={composeEvents(
                value => input.onChange(`+${value}`),
                value => onChange?.(`+${value}`)
              )}
              onBlur={composeEvents(input.onBlur, onBlur)}
              containerClass={showError ? styles.errorContainer : ''}
              inputClass={styles.input}
              inputProps={{ id: name }}
              dropdownClass={styles.dropdown}
              buttonClass={styles.button}
              value={input.value}
              preferredCountries={['ke', 'za']}
              masks={{ ke: '... ... ...', za: '.. ... ....' }}
            />
          </Field>
        );
      }}
    />
  );
}
