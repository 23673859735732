// @flow
import React from 'react';
import cx from 'classnames';

import { Text } from '@kwara/components/src/Intl';

import styles from './index.module.scss';

const LoanStateToStyle = {
  ACTIVE_IN_ARREARS: 'Critical',
  ACTIVE_PARTIALLY_DISBURSED: 'Pending',
  ACTIVE: 'Success',
  APPROVED: 'Success',
  CLOSED_REFINANCED: 'Critical',
  CLOSED_REJECTED: 'Critical',
  CLOSED_RESCHEDULED: 'Critical',
  CLOSED_WITHDRAWN: 'Critical',
  CLOSED_WRITTEN_OFF: 'Critical',
  CLOSED_REPAID: 'Success',
  CLOSED: 'Pending',
  PARTIAL_APPLICATION: 'Pending',
  PENDING_APPROVAL: 'Pending'
};

type LoanCardTagProps = {
  titleID: string,
  className: string
};

const LoanCardTag = ({ titleID, className }: LoanCardTagProps) => {
  return (
    <span className={cx(styles.Tag, styles[className])}>
      <Text id={titleID} />
    </span>
  );
};

type LoanStateTagProps = {
  loanState: $Keys<LoanStateToStyle>
};

export const LoanStateTag = ({ loanState }: LoanStateTagProps) => {
  return <LoanCardTag titleID={`StatusTag.${loanState}`} className={LoanStateToStyle[loanState]} />;
};

type LoanTypeTagProps = {
  instantLoan: boolean
};

export const LoanTypeTag = ({ instantLoan }: LoanTypeTagProps) => {
  return (
    <LoanCardTag
      titleID={`LoanCard.LoanAvailability.${instantLoan ? 'Immediately' : 'Within24'}`}
      className={'Default'}
    />
  );
};

export const NewRequestTag = () => {
  return <LoanCardTag titleID="LoanCard.GuaranteeLoanCard.Tag.NewRequest" className={'Primary'} />;
};
