import React from 'react';
import { Text } from '@kwara/components/src/Intl';
import Rocket from './loading-rocket.gif';

export const RocketLoading = () => (
  <div className="h-100 v-100 bg-white flex flex-column justify-end items-center">
    <Text id="Login.loading" />
    <div className="flex h-50">
      <div className="self-end">
        <img alt="loading" src={Rocket} />
      </div>
    </div>
  </div>
);
