import { attr, belongsTo, hasOne } from 'spraypaint';
import axios from 'axios';
import find from 'lodash/fp/find';
import get from 'lodash/fp/get';

import { InferredModel } from 'GlobalTypes';

import Loan, { LoanType } from '../Loan';
import Base, { BaseModel } from '../Base';
import Member, { MemberType } from '../Member';
import cameliseObjectKeys from '../../lib/cameliseObjectKeys';

import { snakeCaseObjectKeys } from '../../lib/snakeCaseObjectKeys';

function normaliseGuarantorResponse({ data, included }) {
  const memberData = find(include => include.type === 'members', included);
  const member = new Member({ ...memberData.attributes, id: memberData.id });
  const guarantor = new Guarantee({ ...data.attributes, id: data.id });
  guarantor.member = member;

  return guarantor;
}

const GuarantorBase = Base.extend({
  static: {
    async create({ loanId, memberId, amount }) {
      const url = `${Loan.url(loanId)}/guarantors`;
      const attributes = snakeCaseObjectKeys({ memberId, amount });
      const opts = Loan.fetchOptions();
      const data = JSON.stringify({ data: { attributes } });

      return axios
        .post(url, data, opts)
        .then(res => res.data)
        .then(normaliseGuarantorResponse);
    }
  },
  attrs: {
    member: belongsTo('members'),
    memberId: attr(),
    amount: attr(),
    liability: attr(),
    state: attr(),
    dbLoan: hasOne({ type: 'loans' }),
    loan: hasOne({ type: 'loans' })
  },
  methods: {
    memberObject() {
      return cameliseObjectKeys(this.member);
    },
    fullName() {
      return this.member.fullName();
    },
    getState(accountHolderId: string) {
      if (this.state) return this.state;

      return get('member.id', this) === accountHolderId ? 'BORROWER' : 'GUARANTOR';
    },
    async remove(loanId: string) {
      const url = `${Loan.url(loanId)}/guarantors/${this.id}`;
      const opts = Loan.fetchOptions();

      return axios.delete(url, opts).then(() => true);
    },
    async edit({ loanId, amount }) {
      const url = `${Loan.url(loanId)}/guarantors/${this.id}`;
      const opts = Loan.fetchOptions();
      const data = { data: { attributes: { amount } } };

      return axios
        .put(url, data, opts)
        .then(res => res.data)
        .then(normaliseGuarantorResponse);
    }
  }
});

export const Guarantor = GuarantorBase.extend({
  static: {
    jsonapiType: 'guarantors'
  }
});

export const Guarantee = GuarantorBase.extend({
  static: {
    jsonapiType: 'guarantees'
  }
});

export const DeactivatedGuarantee = (loanId: string) =>
  Guarantee.extend({
    static: {
      endpoint: `/loans/${loanId}/deactivated_guarantors`
    }
  });

export const MemberGuarantee = (memberId: string) =>
  Guarantee.extend({
    static: {
      endpoint: `/members/${memberId}/guarantees`
    }
  });

export interface GuaranteeType extends BaseModel<GuaranteeType> {
  state: string;
  amount: number;
  loan: LoanType;
  dbLoan: LoanType;
  memberId: string;
  liability: string;
  fullName(): string;
  member: MemberType;
  memberObject(): MemberType;
  remove(id: string): Promise<boolean>;
  getState(accountHolderId: string): string;
}

export interface PotentialGuarantorType extends InferredModel<PotentialGuarantorType> {
  amount: number | string;
  member: MemberType;
  phoneNumber?: string;
  fullName?(): string;
  memberEncodedKey?: string;
}

export default Guarantee;
