import * as React from 'react';
import find from 'lodash/fp/find';

import { Attachments, AttachmentT } from '@kwara/models/src/models/Attachment';
import { ViewerModal } from '@kwara/components/src/UploadWidgetUI';
import { MemberType } from '@kwara/models/src/';

export const useAttachment = (id: string | null, memberId: string, attachments: AttachmentT[]) => {
  const [attachment, setAttachment] = React.useState({});
  const persisted = find(att => att.id === id, attachments);

  React.useEffect(() => {
    // if the attachment is already there just display it.
    if (persisted && persisted.content) {
      setAttachment(persisted);
      return;
    }

    // otherwise just make a new request and retrieve it server side
    if (id) {
      const att = new Attachments({ memberId });
      att.findOne({ id }).then(setAttachment);
      return;
    }

    setAttachment({});
  }, [id, memberId, persisted]);

  return [attachment] as AttachmentT[];
};

type VProps = {
  member?: MemberType;
  id: string;
  onCancel: () => void;
  disabled?: boolean;
  isClosed: boolean;
  isV1?: boolean;
  details: {
    name?: string;
    filesize?: number;
    type: string;
  };
};

// Given a member and an attachment ID this will fetch the attachment content
// If the member already contains the image content it just diplays it,
// if the content is yet to be fetched it will query the db to retrieve it.
export const Viewer = ({ member = {}, id, onCancel, isClosed, details, isV1 }: VProps) => {
  // TODO: handle error here
  const [attachment = {}] = useAttachment(id, member.id, member.attachments);

  return (
    <ViewerModal
      titleId={`DocumentUploads.${details.name}.label`}
      isClosed={isClosed}
      attachment={attachment}
      onCancel={onCancel}
      details={details}
      isV1={isV1}
    />
  );
};

type WVProps = {
  member: MemberType | null;
  children: (opts: { setShownAttachment: (attachment?: AttachmentT) => void }) => JSX.Element;
  isV1?: boolean;
};
// Wrap any component with this and pass down a member to make is possible to
// view documents. This will expose a setShownAttachment function that accepts an instance of
// Document (or any object with that shape) and will take care of retieving and displaying the
// image. Note: we need to pass a member because the attachment is ALWAYS linked to a member,
// so we need at least his ID to retrieve the document

// <WithViewer member={MemberType} />
//   {({ setShownAttachment }) => (
//      <div>
//         <button onClick={() => setShownAttachment(AttachmentT)}>View attachment</button>
//      </div>
//   )}
// </WithViewer>
//
// If member is not passed the children will be displayed and a noop will be passed
export const WithViewer = ({ member, children, isV1 }: WVProps) => {
  const [shownAttachment, setShownAttachment] = React.useState<AttachmentT>({} as AttachmentT);

  if (!member) {
    return children({ setShownAttachment: () => {} });
  }

  return (
    <>
      <Viewer
        isClosed={!shownAttachment.name}
        id={shownAttachment.id}
        details={shownAttachment}
        member={member}
        onCancel={() => setShownAttachment({} as AttachmentT)}
        isV1={isV1}
      />
      {children({ setShownAttachment })}
    </>
  );
};
