import { attr } from 'spraypaint';

import Base, { BaseModel } from './Base';

export const LoanApplicationFee = Base.extend({
  static: {
    jsonapiType: 'loan_application_fees'
  },
  attrs: {
    feeId: attr(),
    amount: attr()
  }
});

export interface LoanApplicationFeeT extends BaseModel<LoanApplicationFeeT> {
  feeId: string;
  amount?: number | null;
}
