// @flow
import { attr } from 'spraypaint';
import Base from './Base';

export const SavingsHabits = Base.extend({
  static: {
    jsonapiType: 'savings_habits'
  },
  attrs: {
    label: attr(),
    description: attr()
  }
});

export const MemberSavingsHabit = id =>
  SavingsHabits.extend({
    static: {
      jsonapiType: 'savings_habits',
      endpoint: `/members/${id}/savings_habit`
    }
  });

export type MemberSavingsHabitT = {
  label: string,
  description: string
};
