import React from 'react';
import cx from 'classnames';

import { MemberType } from '@kwara/models/src';
import { PotentialGuarantorType } from '@kwara/models/src/models/Guarantee';
import zIndices from '@kwara/lib/src/zIndices';
import { Text, Currency } from '@kwara/components/src/Intl';

import styles from './index.module.scss';

type GuarantorListCardProps = {
  guarantors: PotentialGuarantorType[];
  totalAmountInGuarantee: number;
  open: boolean;
  onEdit: (member: MemberType) => void;
};

export const GuarantorListCard = ({ guarantors, onEdit, open, totalAmountInGuarantee }: GuarantorListCardProps) => {
  return (
    <div className={cx(styles.Container, `ph3 ${zIndices.Banner} mb3 ${open ? styles.Open : styles.Close}`)}>
      <table className="w-100">
        {open ? (
          <>
            <thead className={cx(styles.Header, 'mobile-text-x-small ph2 pv3')}>
              <tr>
                <td className="pt4">
                  <Text id="AddGuarantorList.member" />
                </td>
                <td className="pt4">
                  <Text id="AddGuarantorList.amount" />
                </td>
              </tr>
            </thead>
            <tbody className={cx(styles.Body, 'mobile-text-small')}>
              {guarantors.map((guarantor, i) => (
                <tr key={i}>
                  <td className=" pv2">{guarantor.member.fullName()}</td>
                  <td>
                    <Currency format="currency" value={guarantor.amount} />
                  </td>
                  <td>
                    <span
                      className={styles.Action}
                      onClick={() => {
                        onEdit(guarantor.member);
                      }}
                    >
                      <Text id="AddGuarantorList.edit" />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </>
        ) : null}
        <tfoot className={cx(styles.Footer, 'mobile-text-medium b')}>
          <tr>
            <td className="pv2">
              <Text id="AddGuarantorList.totalAmount" />
            </td>
            <td colSpan={2}>
              <Currency format="currency" value={totalAmountInGuarantee} />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
