// @flow
import * as React from 'react';
import cx from 'classnames';
import size from 'lodash/fp/size';
import get from 'lodash/fp/get';
import filter from 'lodash/fp/filter';
import pipe from 'lodash/fp/pipe';

import { appName } from '@kwara/lib/src/utils';
import { Member, type LoanType } from '@kwara/models/src';
import { type LoanFormData } from '@kwara/components/src/pages/LoanAdd';
import { type SubStepComponentProps } from '@kwara/components/src/Wizard/deprecated/Wizard';
import { segmentTrack } from '@kwara/components/src/Segment';

import { LoanPurpose } from './LoanPurpose';
import { LoanBanner } from './LoanBanner';
import { TwoThirdsModal } from './TwoThirdsModal/TwoThirdsModal';
import { LoanTermsFields } from './LoanTermsFields';
import { LoanClassificationFields } from './LoanClassificationFields';
import { Installments } from './Installments';
import { PrincipalAmountField } from './PrincipalAmountField';
import styles from './index.module.scss';
import { avgMonthlyPayment } from './utils';

type ConfigureProps = SubStepComponentProps<LoanFormData>;

const INCOME_TOO_LOW = 'INCOME_TOO_LOW';

export function matchingLoans(data: LoanFormData = {}) {
  const store = Configure.appStore;
  const { product = {}, loans = [] } = data;
  const { id } = product;

  const canRefinance = (loan: LoanType) => store.refinanceEnabled && loan.canRefinance();
  const sameProduct = (loan: LoanType) => get('product.id', loan) === id;

  return pipe(filter(canRefinance), filter(sameProduct))(loans);
}

export function hasExactLoan(data: LoanFormData) {
  return size(matchingLoans(data)) > 0;
}

function useTwoThirdsRuleModal(error) {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  React.useEffect(() => {
    if (appName.isSacco && error && error.message === INCOME_TOO_LOW) {
      setIsOpen(true);
    }
  }, [error]);

  return { isOpen, onClose };
}

const getLoanPurposeForm = props => {
  return appName.isMember ? <LoanPurpose {...props} /> : <LoanClassificationFields {...props} />;
};

export const Configure = (props: ConfigureProps) => {
  const { StackChild, TextField, data, error, addDataAndContinue, onChange } = props;

  const { isOpen, onClose } = useTwoThirdsRuleModal(error);

  React.useEffect(() => {
    segmentTrack('Long term application flow: KYC page');
  }, []);

  return (
    <StackChild>
      <LoanBanner {...props} />
      {isOpen ? (
        <TwoThirdsModal
          data={data}
          TextField={TextField}
          onChange={onChange}
          addDataAndContinue={addDataAndContinue}
          onClose={onClose}
          onBypass={() => {
            addDataAndContinue({ bypass: true });
            onClose();
          }}
        />
      ) : null}

      {hasExactLoan(data) ? null : (
        <div className={cx('pb4', { [styles.row]: appName.isSacco })}>
          <PrincipalAmountField {...props} />
          {get('product.instantLoan', data) ? (
            <Installments
              TextField={TextField}
              minDuration={data.product.minDuration}
              maxDuration={data.product.maxDuration}
            />
          ) : (
            getLoanPurposeForm({ ...props })
          )}
          <LoanTermsFields {...props} />
        </div>
      )}
    </StackChild>
  );
};

Configure.validate = data => ({
  ...PrincipalAmountField.validate,
  ...LoanTermsFields.validate,
  ...(get('product.instantLoan', data) ? null : LoanPurpose.validate)
});

Configure.verifyTwoThirdsRule = async data => {
  if (!appName.isSacco) {
    return;
  }

  if (data.bypass) {
    return;
  }

  const member = await Member.select(['available_income,total_monthly_loan_payments'])
    .find(data.member.id)
    .then(res => res.data.deserialize());

  if (Number(member.availableIncome) < avgMonthlyPayment(data)) {
    throw { message: INCOME_TOO_LOW };
  }
};
