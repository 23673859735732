import * as React from 'react';

import { RecommendedOrganisationData } from 'RecommendedOrganisationTypes';

import Button from '@kwara/components/src/Button';

import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';

import { useSuggestSaccoContext } from '../..';
import { Container } from '../../../../layouts';
import { SaccoDetailsCard } from '../../components/SaccoDetailsCard';
import { useGetOrganizationDetails } from '../../hooks/useGetOrganizationDetails';

import styles from './index.module.scss';

type SuggestedSaccoDetailsPropTypes = {
  onCancel(): void;
};

export function SuggestedSaccoDetails({ onCancel }: SuggestedSaccoDetailsPropTypes) {
  const { apiData } = useSuggestSaccoContext();
  const { error, data, isLoading } = useGetOrganizationDetails(apiData.permalink);

  function onSaccoDetailsCardClickHandler(onNavigate: () => void) {
    onCancel();
    onNavigate();
  }

  return (
    <Loadable isLoading={isLoading} error={error} data={data}>
      {(orgDetails: RecommendedOrganisationData) => (
        <Container className={styles['container']}>
          <div>
            <h1 className="mobile-text-medium kw-weight-bold tc">
              <Text id="SaccoRecommendationFlow.SuggestedSaccoDetails.title" />
            </h1>

            <p className="mobile-text-medium tc pb4">
              <Text id="SaccoRecommendationFlow.SuggestedSaccoDetails.subTitle" values={{ name: orgDetails.name }} />
            </p>
          </div>

          <SaccoDetailsCard {...orgDetails} onClickHandler={onSaccoDetailsCardClickHandler} />

          <Button type="secondary" to="/organisations" className="black w-100 mt4">
            <Text id="SaccoRecommendationFlow.SuggestedSaccoDetails.pickSacco" />
          </Button>
        </Container>
      )}
    </Loadable>
  );
}
