// @flow

import { attr, belongsTo } from 'spraypaint';
import includes from 'lodash/fp/includes';

import { textHeaders, blobHeaders } from '@kwara/lib/src/fileDownload';

import { type MemberType } from '..';
import Base from './Base';
import { UtilityTypes } from './UtilityTransaction';

const { PAYMENT, RECEIPT } = UtilityTypes;

export const TillTransactionDeprecatedBase = Base.extend({
  static: {
    downloadTransactionsList() {
      const url = `${TillTransactionDeprecated.url()}.pdf`;
      const opts = TillTransactionDeprecated.fetchOptions();
      const options = blobHeaders(opts);

      return Base.downloadFileFromUrl(url, options);
    }
  },
  attrs: {
    type: attr(),
    amount: attr(),
    paymentMethod: attr()
  },
  methods: {
    isUtility() {
      return includes(this.type, [PAYMENT, RECEIPT]);
    },
    downloadTextReceipt() {
      const url = `${TillTransactionDeprecated.url()}/${this.id}.text`;
      const opts = TillTransactionDeprecated.fetchOptions();
      const options = textHeaders(opts);
      const fileName = `receipt_${this.id}`;

      return Base.downloadTextFileFromUrl(url, options, fileName);
    }
  }
});

export const TillTransactionDeprecated = TillTransactionDeprecatedBase.extend({
  static: {
    jsonapiType: 'till_transactions_deprecated'
  },
  attrs: {
    createdAt: attr(),
    updatedAt: attr(),
    member: belongsTo(),
    balance: attr(),
    accountNumber: attr(),
    bankName: attr(),
    bankBranch: attr(),
    reference: attr(),
    notes: attr(),
    enteredAt: attr(),
    name: attr(),
    invoiceNumber: attr()
  }
});

export type TillTransactionDeprecatedType = {
  id: string,
  createdAt: string,
  updatedAt: string,
  member: MemberType,
  type: string,
  amount: number,
  approvedAmount: number,
  requestedAmount: number,
  balance: number,
  paymentMethod: string,
  accountNumber: ?string,
  bankName: ?string,
  bankBranch: ?string,
  reference: string,
  notes: string,
  enteredAt: string,
  name?: string,
  invoiceNumber: string,
  status: 'APPROVED' | 'DECLINED' | 'PENDING',
  isUtility: () => boolean,
  downloadTextReceipt: () => Promise<null>
};

const TOPUP_TYPE = 'topup';

type GetTransactionAmountArgsType = {
  type: string,
  topupAmount?: number,
  otherAmount?: number
};

export function getTransactionAmount(args: GetTransactionAmountArgsType) {
  if (args.type === TOPUP_TYPE) {
    return args.topupAmount;
  }

  return args.otherAmount;
}
