// @flow

import React from 'react';
import cx from 'classnames';
import { FormattedNumberParts } from 'react-intl';
import reduce from 'lodash/fp/reduce';
import findIndex from 'lodash/fp/findIndex';
import reject from 'lodash/fp/reject';
import round from 'lodash/round';

import { FormattedNumber } from '@kwara/components/src/Intl';

import isWholeNumber from './isWholeNumber';
import styles from './Currency.module.css';

type PartsT = { type: string, value: string };

export function split(parts: PartsT[] = []): PartsT[][] {
  const index = findIndex(part => part.type === 'decimal', parts);

  if (index === -1) {
    return [parts, []];
  }
  return [parts.slice(0, index), parts.slice(index + 1)];
}

export function removeCommas(parts: PartsT[]): PartsT[] {
  return reject<PartsT>(part => part.type === 'group', parts);
}

export function toString(parts: PartsT[]): string {
  return reduce((acc, curr) => acc + curr.value, '', parts);
}

export type CurrencyProps = {
  value: ?number,
  currencyCode?: string,
  format?: ?'currency',
  hideDecimals?: boolean,
  size?: 'small'
};

const Currency = ({ value, currencyCode, format, hideDecimals = true, size }: CurrencyProps) => {
  const store = Currency.appStore || {};
  // If a code is passed that will take precedence. If not we automatically fall back to
  // the org currency, which is most likely to be the one we want.
  // otherwise fallback to KES, which is useful for storybook

  const currency = currencyCode || store.currency || 'KES';

  const prefix =
    format === 'currency' ? (
      <>
        <small>{currency}</small>{' '}
      </>
    ) : null; // Note the space after code

  const classNames = cx([styles.Numeric, { 'kw-text-small kw-weight-light': size === 'small' }]);

  return (
    <span className={classNames}>
      {prefix}
      {value != null ? (
        <FormattedNumberParts value={round(value, 2)} minimumFractionDigits="2">
          {parts => {
            const [integerParts, fractionalParts] = split(parts);

            return (
              <>
                <FormattedNumber value={toString(removeCommas(integerParts))} />
                {isWholeNumber(value) && hideDecimals ? null : (
                  <>
                    <small>
                      .
                      <FormattedNumber minimumIntegerDigits="2" value={toString(fractionalParts)} />
                    </small>
                  </>
                )}
              </>
            );
          }}
        </FormattedNumberParts>
      ) : (
        '-'
      )}
    </span>
  );
};

export default Currency;
