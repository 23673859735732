import React from 'react';
import cx from 'classnames';

import { Text, Currency } from '@kwara/components/src/Intl';
import Asset from '@kwara/components/src/Asset';
import { useNotificationContext } from '@kwara/components/src/Notification/NotificationProvider';
import { segmentTrack } from '@kwara/components/src/Segment';

import { CardWrapper } from '../CardWrapper';
import { useModalContext } from '../Modal/Context';
import { InfoModal } from './InfoModal';

import styles from './index.module.scss';

export const ReferralCode = ({ code, className }) => {
  const notification = useNotificationContext();
  const DISMISS_DURATION = 2000;

  React.useEffect(() => {
    if (notification.current) {
      const timer = setTimeout(() => {
        notification.dismiss();
      }, DISMISS_DURATION);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  const copyToClipBoard = code => {
    try {
      // Creates and removes a temporary textarea in the document to copy the code from
      const textField = document.createElement('textarea');
      textField.innerText = `Join Kwara: https://linktr.ee/kwara_app with code ${code}`;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();

      notification.displayMessage(<Text id="Referral.Code.copy.success" />);
    } catch (err) {
      notification.displayError(<Text id="Referral.Code.copy.fail" />);
    }
  };
  return (
    <div
      className={cx(styles.Referral, styles.br12, 'flex mobile-text-small justify-between ph2', className)}
      onClick={() => {
        segmentTrack('Copy Referral Code Clicked');
        !notification.current && copyToClipBoard(code);
      }}
    >
      <span className="mr1">{code}</span> <Asset id={Asset.Glyphs.Copy} col={Asset.Colours.primary500} />
    </div>
  );
};

export const PointsEarned = ({ points, className }) => (
  <div className={cx(styles.Points, styles.br12, ' mobile-text-small tc', className)}>{points}</div>
);

export type ReferralData = {
  referralData: { referralScore: string, referralCode: string, referralAmountPerPoint: string }
};
export const ReferralCard = ({ referralData }: ReferralData) => {
  const { setModal, removeModal } = useModalContext();
  const { referralScore, referralCode, referralAmountPerPoint } = referralData;

  return (
    <CardWrapper className={styles.Card}>
      <div className={cx(styles.GridAreaA, 'white mobile-text-medium')}>
        <Text id="Referral.Card.title" />
      </div>
      <div
        className={cx(styles.GridAreaB, 'white mobile-text-medium')}
        onClick={() => {
          setModal(<InfoModal onClose={removeModal} referralData={referralData} />);
        }}
      >
        <Asset id={Asset.Glyphs.Info} />
      </div>
      <div className={cx(styles.GridAreaC, 'white mobile-text-small')}>
        <Text id="Referral.Card.Points.title" />
        <PointsEarned points={referralScore} className="bg-white mobile-primary-500" />
      </div>
      <div className={cx(styles.GridAreaD, 'white mobile-text-small')}>
        <div className="flex justify-end">
          <div className="flex flex-column items-center">
            <Text id="Referral.Code.title" />
            <ReferralCode code={referralCode} className={'bg-white mobile-primary-500'} />
            <div className="mobile-text-x-small mobile-neutral-200">
              <Text id="Referral.Point.label" />
              <Currency format={'currency'} value={referralAmountPerPoint} />
            </div>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};
