import React, { useState } from 'react';
import get from 'lodash/fp/get';

import { useInterval } from '@kwara/models/src/models/request/hooks';
import { MpesaTransactionStatus } from '@kwara/models/src/models/MpesaTransaction';

import { Text } from '@kwara/components/src/Intl';
import { Completion, Types as CompletionTypes } from '@kwara/components/src/Completion';

export const UiDataForState = {
  [MpesaTransactionStatus.PENDING]: {
    titleId: 'MpesaTransaction.Completion.Pending.title',
    subtitleId: 'MpesaTransaction.Completion.Pending.subtitle',
    completionType: CompletionTypes.good,
    buttonId: 'MpesaTransaction.Completion.Success.button'
  },
  [MpesaTransactionStatus.SUCCEEDED]: {
    titleId: 'MpesaTransaction.Completion.Succeeded.title',
    subtitleId: 'MpesaTransaction.Completion.Succeeded.subtitle',
    completionType: CompletionTypes.good,
    buttonId: 'MpesaTransaction.Completion.Success.button'
  },
  [`${MpesaTransactionStatus.JOINING_FEE_SUCCEEDED}`]: {
    titleId: 'MpesaTransaction.Completion.Joining.Fee.Succeeded.title',
    subtitleId: 'MpesaTransaction.Completion.Joining.Fee.Succeeded.subtitle',
    completionType: CompletionTypes.good,
    buttonId: 'MpesaTransaction.Completion.Joining.Fee.Succeeded.button'
  },
  [MpesaTransactionStatus.FAILED]: {
    titleId: 'MpesaTransaction.Completion.Failed.title',
    subtitleId: 'MpesaTransaction.Completion.Failed.subtitle',
    completionType: CompletionTypes.bad,
    buttonId: 'MpesaTransaction.Completion.Success.button'
  },
  [MpesaTransactionStatus.TIMEOUT]: {
    titleId: 'MpesaTransaction.Completion.Timeout.title',
    subtitleId: 'MpesaTransaction.Completion.Timeout.subtitle',
    completionType: CompletionTypes.info,
    buttonId: 'MpesaTransaction.Completion.Success.button'
  },
  [MpesaTransactionStatus.CANCELLED]: {
    titleId: 'MpesaTransaction.Completion.Cancelled.title',
    subtitleId: 'MpesaTransaction.Completion.Cancelled.subtitle',
    completionType: CompletionTypes.info
  },
  [MpesaTransactionStatus.INSUFFICIENT_AMOUNT]: {
    titleId: 'MpesaTransaction.Completion.InsufficientAmount.title',
    subtitleId: 'MpesaTransaction.Completion.InsufficientAmount.subtitle',
    completionType: CompletionTypes.bad,
    buttonId: 'MpesaTransaction.Completion.Success.button'
  }
} as const;

const TRANSACTION_POLL_INTERVAL = 1000;

function useMpesaTransactionPolling(id: string, transactionHook, isLoggedIn: boolean) {
  const [isPolling, setIsPolling] = useState(true);
  const [uiType, setUiType] = useState(MpesaTransactionStatus.PENDING);
  const { refetch, data } = transactionHook(id);

  useInterval(
    () => {
      const { transactionStatus } = data;

      if (!transactionStatus) return;

      if (transactionStatus !== MpesaTransactionStatus.PENDING) {
        setIsPolling(false);

        if (transactionStatus === MpesaTransactionStatus.SUCCEEDED) {
          setUiType(isLoggedIn ? transactionStatus : MpesaTransactionStatus.JOINING_FEE_SUCCEEDED);
        } else {
          setUiType(transactionStatus);
        }
      }
      refetch();
    },
    isPolling ? TRANSACTION_POLL_INTERVAL : null
  );

  return { uiType, isPolling };
}

/**
 * @PollMpesaTransaction
 */
type PollMpesaTransactionType = {
  id: string;
  onConfirm(): void;
  analyticsId: string;
  isLoggedIn?: boolean;
  transactionHook(id: string, includes?: string[]): boolean;
};

export function PollMpesaTransaction(props: PollMpesaTransactionType) {
  const { id, onConfirm, analyticsId, transactionHook, isLoggedIn = true } = props;

  const { uiType, isPolling } = useMpesaTransactionPolling(id, transactionHook, isLoggedIn);

  const { titleId, subtitleId, completionType, buttonId } = get(uiType, UiDataForState);

  return (
    <Completion
      onConfirm={onConfirm}
      subtitle={<Text id={subtitleId} />}
      type={completionType}
      analyticsId={analyticsId}
      buttonTextId={buttonId}
      titleId={titleId}
      isLoading={isPolling}
    />
  );
}
