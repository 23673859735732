import * as React from 'react';
import cx from 'classnames';
import raw from 'raw.macro';
import zipObject from 'lodash/zipObject';

import { ValueOf } from 'GlobalTypes';

import styles from './index.module.scss';

/**
 * @Note Changing these files might not reload
 * them in the dev environment since the
 * raw.macro seems to aggressively cache them.
 *
 * @Run the script below to fix this
 * ```ts
 *  yarn clear:babel:cache && yarn run start:member
 * ```
 */
const assets = {
  Add: raw('./Glyph/Plus Circle.svg'),
  ArrowRight: raw('./Glyph/Arrow Right.svg'),
  ArrowLeft: raw('./Glyph/ArrowLeft.svg'),
  ArrowDown: raw('./Glyph/Arrow Down.svg'),
  ArrowUpRight: raw('./Glyph/Arrow Up Right.svg'),
  Business: raw('./Glyph/Business.svg'),
  Check: raw('./Glyph/Check.svg'),
  Contact: raw('./Glyph/Contact.svg'),
  ChevronDown: raw('./Glyph/ChevronDown.svg'),
  ChevronUp: raw('./Glyph/ChevronUp.svg'),
  Cross: raw('./Glyph/Cross.svg'),
  Dash: raw('./Glyph/Dash.svg'),
  DoubleArrow: raw('./Glyph/Double Arrow.svg'),
  Dropdown: raw('./Glyph/Dropdown.svg'),
  EllipsisVertical: raw('./Glyph/Ellipsis Vertical.svg'),
  Pencil: raw('./Glyph/Pencil.svg'),
  EyeClosed: raw('./Glyph/EyeClosed.svg'),
  Eye: raw('./Glyph/Eye.svg'),
  Printer: raw('./Glyph/Printer.svg'),
  Female: raw('./Glyph/Female.svg'),
  Kwara: raw('./Glyph/Kwara.svg'),
  Male: raw('./Glyph/Male.svg'),
  Moon: raw('./Glyph/Moon.svg'),
  Search: raw('./Glyph/Search.svg'),
  Share: raw('./Glyph/Share.svg'),
  Spinner: raw('./Glyph/Refresh.svg'),
  Other: raw('./Glyph/Other.svg'),
  TrashBin: raw('./Glyph/Trash Bin.svg'),
  Rocket: raw('./Glyph/Rocket.svg'),
  Ruby: raw('./Glyph/Ruby.svg'),
  Salaried: raw('./Glyph/Salaried.svg'),
  ShieldLock: raw('./Glyph/Shield Lock.svg'),
  Star: raw('./Glyph/Star.svg'),
  Stop: raw('./Glyph/Stop.svg'),
  Stopwatch: raw('./Glyph/Stopwatch.svg'),
  Sun: raw('./Glyph/Sun.svg'),
  Calendar: raw('./Glyph/Calendar.svg'),
  Info: raw('./Glyph/Mobile/Info.svg'),
  Copy: raw('./Glyph/Mobile/Copy.svg'),
  Download: raw('./Glyph/Mobile/Download.svg'),
  ArrowBackMobile: raw('./Glyph/Mobile/ArrowBack.svg'),
  DownMobile: raw('./Glyph/Mobile/DropdownDown.svg'),
  CrossCircle: raw('./Glyph/Mobile/CrossCircle.svg'),
  UpMobile: raw('./Glyph/Mobile/DropdownUp.svg'),
  Help: raw('./Glyph/Mobile/Help.svg')
};

const assetIds = Object.keys(assets);
const Glyphs = Object.freeze(zipObject(assetIds, assetIds));

const Colours = {
  white: 'white',
  grey300: 'grey300',
  info500: 'info500',
  info700: 'info700',
  red500: 'red500',
  red700: 'red700',
  teal500: 'teal500',
  teal700: 'teal700',
  indigo500: 'indigo500',
  primary500: 'primary500',
  black: 'black'
};

export type Glyph = ValueOf<typeof Glyphs>;
export type GlyphCols = ValueOf<typeof Colours>;

const getRawAssetById = (id: string) => assets[id];
export const getAssetDataUrlById = (id: Glyph) => `data:image/svg+xml,${escape(getRawAssetById(id))}`;

type AssetPropTypes = {
  className?: string;
  id: Glyph;
  size?: string;
  col?: ValueOf<typeof Colours>;
};

function Asset({ size = 'medium', className = '', id, col = Colours.white }: AssetPropTypes) {
  const assetStyle = size === 'small' ? styles.AssetSmall : styles.Asset;
  const classes = `dib ${className} ${assetStyle} ${styles[col]} ${styles[id]}`;

  return <span data-testid="asset" className={cx(classes)} dangerouslySetInnerHTML={{ __html: getRawAssetById(id) }} />;
}

Asset.Colours = Colours;
Asset.Glyphs = Glyphs;

export default Asset;
