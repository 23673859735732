// @flow

import * as React from 'react';

import filter from 'lodash/fp/filter';
import get from 'lodash/fp/get';

import ActivityTimeline from '@kwara/components/src/ActivityTimeline';
import { Loadable } from '@kwara/components/src/Loadable';
import { useMember } from '@kwara/models/src/models/request/hooks';

import { useStore } from '../../models/Member';
import Section from '../../components/Section';

const includes = ['activities'];
export const SavingActivity = ({ accountId }) => {
  const store = useStore();
  const response = useMember(store.memberId, includes);

  return (
    <Loadable {...response}>
      {member => {
        const savingActivities = filter({ type: 'savings', objectId: accountId }, get('activities', member));

        return (
          <Section titleId="Activity.title">
            <ActivityTimeline activities={savingActivities} />
          </Section>
        );
      }}
    </Loadable>
  );
};
