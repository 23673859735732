// @flow

import * as React from 'react';
import cx from 'classnames';

import { Loadable } from '@kwara/components/src/Loadable';
import { useMember } from '@kwara/models/src/models/request/hooks';

import { useStore } from '../../models/Member';
import { LogoutButton } from '../../components/LogoutButton';
import { PersonalDetails } from './PersonalDetails';
import { Identification } from './Identification';
import { NextOfKin } from './NextOfKin';
import { EmploymentDetails } from './EmploymentDetails';
import { Bank } from './Bank';
import { Documents } from './Documents';
import { CardLoader } from '../../components/CardLoader';

const includes = ['addresses', 'next_of_kin', 'id_documents', 'attachments'];

export const Profile = ({ auth }) => {
  const store = useStore();
  const response = useMember(store.memberId, includes);
  const borderClasses = 'pt3 pb3 bt b--light-grey-300';

  return (
    <Loadable {...response} loading={<CardLoader />}>
      {member => {
        return (
          <div className={'mt3 mb3'}>
            <PersonalDetails member={member} className={'pt2 pb3'} />
            <Identification member={member} className={borderClasses} />
            <NextOfKin member={member} className={borderClasses} />
            <EmploymentDetails member={member} className={borderClasses} />
            <Bank member={member} className={borderClasses} />
            <Documents member={member} className={borderClasses} />
            <LogoutButton auth={auth} className={cx('flex', borderClasses)} />
          </div>
        );
      }}
    </Loadable>
  );
};
