// @flow

const ValidAppearances = [
  'approve',
  'close',
  'back',
  'cancel',
  'nextWithPromise',
  'next',
  'reject',
  'review',
  'submit',
  'skip',
  'print',
  'verify',
  'buy'
];

type ValidAppearance =
  | 'approve'
  | 'close'
  | 'back'
  | 'cancel'
  | 'nextWithPromise'
  | 'next'
  | 'reject'
  | 'review'
  | 'submit'
  | 'skip'
  | 'print'
  | 'verify'
  | 'buy';

const CompletionBehaviours = ['cancel', 'complete', 'approve', 'reject', 'skip', 'print'];
type CompletionBehaviour = 'cancel' | 'complete' | 'approve' | 'reject' | 'skip' | 'print';

const ValidBehaviours = ['back', 'next', 'nextWithPromise', ...CompletionBehaviours];
type ValidBehaviour = 'back' | 'next' | 'nextWithPromise' | CompletionBehaviour;

export type ActionConfig = {
  appearsAs: ValidAppearance,
  behavesAs: ValidBehaviour,
  destination?: ?string,
  isHidden?: boolean,
  isPermitted?: boolean,
  destinationPath?: string,
  onNext?: (
    data: { [key: string]: mixed },
    onChange: <DataType = { [key: string]: mixed }>(updatedData: DataType) => Promise<unknown>
  ) => Promise<any>
};

type ActionConfigError = Error & {
  config?: ActionConfig
};

const createConfigError = (message: string, config: ActionConfig): ActionConfigError => {
  const error = new Error(message);
  (error: ActionConfigError).config = config;
  return error;
};

const assert = (test: boolean, message: string, config: ActionConfig) => {
  if (test === false) {
    throw createConfigError(message, config);
  }
};

const DEFAULTS = {
  destination: null,
  destinationPath: null,
  isPermitted: true,
  isHidden: false
};

export default class Action {
  constructor(params: ActionConfig) {
    const obj = { ...DEFAULTS, ...params };

    assert(ValidAppearances.includes(obj.appearsAs), `appearsAs must be one of: ${ValidAppearances.join(', ')}`, obj);

    assert(ValidBehaviours.includes(obj.behavesAs), `behavesAs must be one of: ${ValidBehaviours.join(', ')}`, obj);

    assert(
      typeof obj.isPermitted === 'boolean',
      `isPermitted must be boolean but was '${typeof obj.isPermitted}'`,
      obj
    );

    assert(typeof obj.isHidden === 'boolean', `isHidden must be boolean but was '${typeof obj.isHidden}'`, obj);

    assert(
      Action.isFinalStep(obj) || typeof obj.destination === 'string',
      `destination must be a string but was '${typeof obj.destination}'`,
      obj
    );

    Object.assign(this, obj);
  }

  appearsAs: ValidAppearance;
  behavesAs: ValidBehaviour;
  destination: ?string;
  destinationPath: ?string;
  isHidden: boolean;
  isPermitted: boolean;

  static isFinalStep(action: Action | ActionConfig) {
    return action.destination == null && CompletionBehaviours.includes(action.behavesAs);
  }
}
