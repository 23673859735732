//@flow
import * as React from 'react';
import isFunction from 'lodash/fp/isFunction';

import { If } from '@kwara/components/src/If/If';
import { Logger } from '@kwara/lib/src/logger';
import { hasErrors, type BaseRequestProps } from '@kwara/models/src/models/request';
import { ModelErrorBanner, type ModelErrorBannerPropTypes } from '@kwara/components/src/ModelErrorBanner';
import { AccessibleLoader } from '@kwara/components/src/AccessibleLoader';
import Loading from '@kwara/components/src/Loadable/Loading';

export { LoadingKwara } from './LoadingKwara';
export { LoadingSkeleton } from './LoadingSkeleton';
export { EMPTY } from '@kwara/models/src/models/request';
export { default as Loading, SpinningLoading } from './Loading';
export { default as LoadingFullScreen } from './LoadingFullScreen';
export type { RequestProps } from '@kwara/models/src/models/request';

/************************************************************* */
type Props<Data> = {
  loader: ?Promise<Data>,
  Loading: React.ComponentType<{}>,
  loaded: (loadedData: Data) => React.ReactNode,
  Error: React.ComponentType<{ error: ?Error }>
};

type State<Data> = { result: ?Data, error: ?Error, settled: boolean };

// THIS IS DEPRECATED AND NO LONGER MAINTAINED. PLEASE AVOID USING IT AS MUCH POSSIBLE
class DeprecatedLoadable extends React.Component<Props<*>, State<*>> {
  static defaultProps = { Loading, Error: AccessibleLoader };
  isUnmounted: boolean;
  state = { settled: false, result: null, error: null };

  componentDidMount() {
    this.waitForLoader();
    Logger.warn(
      `This component is deprecated. Switch the usage <DeprecatedLoadable /> to <Loadable /> or use another component.`
    );
  }

  componentDidUpdate({ loader }: Props<*>) {
    if (loader !== this.props.loader) {
      this.waitForLoader();
    }
  }

  async waitForLoader() {
    try {
      const result = await this.props.loader;

      /***
       * Check whether the component is still mounted before updating the state.
       * This avoids setting state on an unmounted comp when a user changes page
       * while the above promise is still pending. This is more of a quick-fix as
       * we should really cancel the this.props.loader promise above. Hence,we
       * will still log this error below so we are warned every time this happens
       * More info: https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
       **/

      if (!this.isUnmounted) {
        this.setState({ settled: true, result });
      } else {
        // eslint-disable-next-line no-console
        console.error(`Trying to set state on an unmounted Component: (check the ${this.constructor.name} Component)`);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      this.setState({ settled: true, error: error || { name: 'GENERAL_ERROR' } });
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const { Error, loaded, Loading, ...rest } = this.props;
    const { error, result, settled } = this.state;

    if (settled && error == null) {
      return loaded(result);
    }

    if (error != null) {
      return <Error {...rest} error={error} />;
    }

    return <Loading {...rest} error={error} />;
  }
}

/************************************************************* */
export type LoadableBasePropsT<T = any> = BaseRequestProps<T>;
interface LoadablePropTypes<T> extends LoadableBasePropsT<T> {
  loading?: React.ReactNode;
  errorBanner?: React.ReactNode;
  errorBannerProps?: ModelErrorBannerPropTypes;
  children?: React.ReactNode | ((t: T) => React.ReactNode);
  data?: T | {}; //We override``BaseRequestProps data``` because data is unnecessary if the children prop is not a function
}

function Loadable<T = any>(props: LoadablePropTypes<T>): React.ReactNode {
  const { data, error, isLoading, isError, children, errorBanner, loading, errorBannerProps = {} } = props;

  //IS LOADING OPERATION
  if (isLoading) {
    return loading ?? <AccessibleLoader isLoading={isLoading} itsTextLoader />;
  }

  //ERROR IS FOUND
  if (isError || hasErrors(error)) {
    return (
      <If
        condition={!!errorBanner}
        do={errorBanner}
        else={
          <div className="w-100 h-100 flex justify-center items-center">
            <ModelErrorBanner errors={error.response ?? error} {...errorBannerProps} />
          </div>
        }
      />
    );
  }

  //IS DONE LOADING, AND NO ERROR, AND THE CHILDREN PROP IS PASSED IN
  if (children != undefined) {
    return isFunction(children) ? children(data) : children;
  }

  //OTHER IF IS DONE LOADING, AND NO ERROR, AND NO CHILDREN PROP, THEN RETURN NULL
  return null;
}

function Error() {
  return <Loading error={{ name: 'GENERAL_ERROR' }} />;
}

export { Loadable, Error, DeprecatedLoadable };
