import mapValues from 'lodash/mapValues';

import { ModelErrors } from '..';

type Params = {
  [name: string]: string;
};

/**
 * Creates an Error that is the same shape as
 * those parsed into Spraypaint's (formerly JSORM) model.errors structure
 *
 */
export default function createModelErrors(errors: Params): ModelErrors {
  return mapValues(errors, (status, key) => ({
    attribute: key,
    code: status
  }));
}

type Meta = Partial<{
  attribute: string;
  message: string;
  fullMessage: string;
  row_index: number;
}>;

type JsonApiError = {
  id: string;
  status: string;
  code: string;
  title: string;
  detail: string;
  meta: Meta;
  source: { pointer?: string; parameter?: string };
};

export type JsonApiErrorPayload = {
  data?: unknown;
  errors: Partial<JsonApiError>[];
};

/**
 * See: https://github.com/jsonapi-suite/jsorm/blob/master/src/util/validation-error-builder.ts#L40
 */
export const createErrorsFromApiResponse = (payload: JsonApiErrorPayload = { data: null, errors: [] }): ModelErrors => {
  const errorsAccumulator = {};

  if (payload.errors) {
    payload.errors.forEach(error => {
      const meta: Meta = error.meta || ({} as Meta);
      const attribute = meta.attribute || error.code;

      errorsAccumulator[attribute] = {
        title: error.title,
        code: error.code,
        attribute: meta.attribute,
        message: meta.message,
        rawPayload: error,
        rowIndex: meta.row_index,
        fullMessage: attribute === 'base' ? meta.message : error.detail
      };
    });
  }

  return errorsAccumulator;
};
