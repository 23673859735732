import * as React from 'react';
import cx from 'classnames';

import { If } from '@kwara/components/src';
import { Text } from '@kwara/components/src/Intl';

import styles from './index.module.scss';

type RenderPropTypes = {
  condition: boolean;
  fallbackId: string;
  children: React.ReactNode;
};

export function Render({ condition, fallbackId, children }: RenderPropTypes) {
  return (
    <If
      condition={condition}
      do={children}
      else={
        <p className={cx('kw-text-small', styles['fallback'])}>
          <Text id={fallbackId} />
        </p>
      }
    />
  );
}
