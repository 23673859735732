//@flow
import React from 'react';

import styles from './Date.module.css';

type Props = {
  value: number
};

function humanFileSize(size) {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const num = Number(Math.pow(1024, i).toFixed(2));
  const val = ((size / num) * 1).toFixed(2);
  return val + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export default ({ value }: Props) => (
  <span className={styles.Numeric}>{value != null ? humanFileSize(value) : '-'}</span>
);
