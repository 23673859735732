// @flow
import * as React from 'react';

import { appName } from '@kwara/lib/src/utils';

import styles from './index.module.scss';
import image from './image.png';
import triangle from './triangle.png';
import imageMobile from './imageMobile.png';

type Props = {
  children: React.Node,
  className?: string,
  img?: 'default' | 'triangle'
};

function src(img) {
  if (img === 'triangle') {
    return triangle;
  }
  return appName.isSacco ? image : imageMobile;
}

export default ({ children, img, className = '' }: Props) => {
  if (appName.isSacco) {
    return (
      <EmptyCore img={img} className={className}>
        {children}
      </EmptyCore>
    );
  }

  return (
    <EmptyMobile img={img} className={className}>
      {children}
    </EmptyMobile>
  );
};

const EmptyCore = ({ children, img, className = '' }: Props) => (
  <div className={`${styles.Box} flex justify-stretch ${className}`}>
    <div className={`${styles.Message} flex-auto flex items-center kw-text-regular grey-400`}>{children}</div>
    <div className={`${styles.Image} ml-auto`}>
      <img className={styles.Png} src={src(img)} alt="" />
    </div>
  </div>
);

const EmptyMobile = ({ children, img, className = '' }: Props) => (
  <div className={`flex flex-column justify-center items-center ${className}`}>
    <div className={styles.Image}>
      <img className={styles.Png} src={src(img)} alt="empty" />
    </div>
    <div className="flex-auto flex items-center kw-text-regular grey-400">{children}</div>
  </div>
);
