// @flow

import { attr, hasOne } from 'spraypaint';
import axios from 'axios';

import { prepareFileForUpload } from '@kwara/lib/src/fileUpload';
import { formatIsoDate } from '@kwara/lib/src/dates';

import Base, { type BaseModel } from './Base';
import { type GeneralLedgerAccountT } from './GeneralLedgerAccount';

const States = Object.freeze({
  PENDING: 'pending',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  FAILED: 'failed'
});

export type StatesT = $Values<typeof States>;

export const ReconciliationStatement = Base.extend({
  static: {
    jsonapiType: 'reconciliation_statements',

    async uploadStatement({ glAccountId, from, to, file }: { glAccountId: string, from: Date, to: Date, file: File }) {
      const { name, type, content } = await prepareFileForUpload(file);

      const opts = this.fetchOptions();
      const data = {
        attributes: {
          name,
          type,
          statement_source: 'mpesa',
          content,
          gl_account_id: glAccountId,
          from: formatIsoDate(from),
          to: formatIsoDate(to)
        }
      };
      const url = `${this.url()}`;
      return axios.post(url, { data }, opts);
    }
  },
  attrs: {
    from: attr(),
    to: attr(),
    glAccountId: attr(),
    state: attr(),
    uploadFile: attr(),
    reportFile: attr(),
    glAccount: hasOne()
  },
  methods: {
    isGenerated() {
      return this.state === States.COMPLETED;
    }
  }
});

export interface ReconciliationStatementT extends BaseModel<ReconciliationStatementT> {
  from: string;
  to: string;
  glAccountId: string;
  isGenerated: () => boolean;
  state: StatesT;
  uploadFile: string;
  reportFile: string;
  glAccount: GeneralLedgerAccountT;
}
