import { InferredModel } from 'GlobalTypes';
import { attr } from 'spraypaint';

import Base from '../../Base';

export const PaymentMethod = Base.extend({
  static: {
    jsonapiType: 'payment_methods'
  },
  attrs: {
    name: attr(),
    key: attr()
  }
});

export interface PaymentMethodT extends Omit<InferredModel<typeof PaymentMethod>, 'errors'> {
  name: string;
  key: string;
}
