// @flow

import * as React from 'react';

import first from 'lodash/fp/first';

import { segmentTrack } from '@kwara/components/src/Segment';

import { useNewUserStore } from '../../models/NewUserStore';
import { RocketLoading } from '../../components/RocketLoading';
import { StatusApprovalModal } from '../../components/StatusApprovalModal';
import { Welcome } from '../../components/Welcome';

import { Login } from './Login';

export const LoginFourPIN = ({ auth, history }) => {
  const { storePhone: phone, storeOrganisation: organisation } = useNewUserStore();
  const { permalink, name } = organisation;
  const [loading, setLoading] = React.useState(false);
  const [showWelcome, setShowWelcome] = React.useState(false);
  const [userBlocked, setUserBlocked] = React.useState(false);
  const [errors, setErrors] = React.useState(null);
  const [errorValues, setErrorValues] = React.useState(null);
  const submit = async data => {
    const { pin } = data;
    setLoading(true);

    await auth
      .connectWithFourDigitLogIn({
        phone,
        pin
      })
      .then(() => {
        segmentTrack('User logged in', {
          phone,
          organisation: { permalink, name }
        });
        setLoading(false);
        setShowWelcome(true);
      })
      .catch(({ errors }) => {
        const error = first(errors);

        if (error?.code === 'INCORRECT_PIN') {
          const { attempts_left } = error?.meta;
          const errorMessage = `${attempts_left} attempt${attempts_left > 1 ? 's' : ''} left`;

          setErrorValues(errorMessage);
        }

        if (error?.code === 'PIN_LOCKED') {
          setUserBlocked(true);
        }
        setLoading(false);
        setErrors(errors);
      });
  };

  if (showWelcome) {
    return <Welcome />;
  }

  if (loading) {
    return <RocketLoading />;
  }

  return (
    <>
      <StatusApprovalModal
        show={userBlocked}
        type="bad"
        titleId={'StatusApprovalModal.denied.title'}
        subtitleId={'StatusApprovalModal.denied.subtitle'}
        buttonId={'StatusApprovalModal.denied.button'}
        onConfirm={() => history.push('/')}
      />
      <Login history={history} length={4} errors={errors} errorValues={errorValues} submit={submit} />
    </>
  );
};
