import * as React from 'react';
import cx from 'classnames';

import { If } from '../If/If';
import styles from './LoadingSkeleton.module.scss';

type Shape = 'radius-horizone' | 'radius-verticone' | 'block-horizone' | 'block-verticone' | 'circle' | 'default';

function getLoadingSkeletonStyles({ width, height, shape }: { width?: string; height?: string; shape: Shape }) {
  const styles = {
    circle: { width: width || '50px', height: height || '50px', borderRadius: '50%' },
    'radius-horizone': { width: width || '100%', borderRadius: '0.125rem', height: height || '1.25rem' },
    'radius-verticone': { width: width || '1.25rem', borderRadius: '0.125rem', height: height || '1.25rem' },
    'block-horizone': { width: width || '100%', height: height || '1.25rem' },
    'block-verticone': { width: width || '1.25rem', height: height || '1.25rem' },
    default: { width: '100%', borderRadius: '0.125rem' }
  };

  return styles[shape];
}

export type LoadingSkeletonPropTypes = {
  shape?: Shape;
  width?: string;
  height?: string;
  noChildren?: boolean;
};

export function LoadingSkeleton(props: LoadingSkeletonPropTypes) {
  const { shape = 'default', width, height, noChildren = false } = props;

  return (
    <div
      className={styles['skeleton']}
      data-testid="loadingSkeleton"
      style={getLoadingSkeletonStyles({ width, height, shape })}
    >
      <If condition={!noChildren} do="..." />
    </div>
  );
}

/**
 * Variations
 */
type LinearSkeletonUiPropTypes = {
  count?: number;
  className?: string;
};
export function LinearSkeletonUi({ count = 15, className }: LinearSkeletonUiPropTypes) {
  return (
    <div className={cx('w-100', className)}>
      {[...new Array(count)].map((_, i) => (
        <LoadingSkeleton key={i.toString()} shape="radius-horizone" width="100%" height="50px" noChildren />
      ))}
    </div>
  );
}
