import get from 'lodash/fp/get';
import find from 'lodash/fp/find';

import { BankT } from '@kwara/models/src/models/Bank';
import { BankBranchT } from '@kwara/models/src/models/BankBranch';

export class Store {
  public banks: BankT[] = [];
  public defaultJoiningFeeAccountPattern = '%FIRST_NAME% %LAST_NAME%';

  public setBanks(banks: BankT[]) {
    this.banks = banks;
  }

  public getBank(bankId: string) {
    return find((bank: BankT) => bank.id === bankId, this.banks);
  }

  public getBankBranch(bank: BankT, branchId: string) {
    return find((branch: BankT) => branch.id === branchId, get('bankBranches', bank)) as BankBranchT;
  }

  public get paymentInstructions() {
    return 'paybill';
  }

  public get isJoiningFeeRequired() {
    return true;
  }

  public get isPaybill() {
    return this.paymentInstructions === 'paybill';
  }

  public get isTill() {
    return this.paymentInstructions === 'till';
  }

  public get joiningFeeSettings() {
    return {
      isTill: this.isTill,
      isPaybill: this.isPaybill,
      /**
       * For some reason, the legacy code of
       * this Store initially written in js
       * try's to access an attribute that will
       * never exist. For Legacy purpose,
       * we r keeping it until its figure out
       */
      amount: (this as any).joiningFeeAmount,
      businessNumber: (this as any).businessNumber,
      accountNumberPattern: (this as any).joiningFeeAccountPattern
    };
  }
}
