import { attr } from 'spraypaint';

import { InferredModel } from 'GlobalTypes';

import Base from '../Base';

export interface FloatBalanceType extends InferredModel<FloatBalanceType> {
  amount: number;
  currency: string;
  provider: string;
  timestamp: string;
}

export const FloatBalance = Base.extend({
  static: {
    jsonapiType: 'float_balances',
    endpoint: '/mobile_payouts/float_balance'
  },
  attrs: {
    amount: attr(),
    currency: attr(),
    provider: attr(),
    timestamp: attr()
  }
});
