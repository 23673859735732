// @flow

import * as React from 'react';
import { useHistory } from 'react-router-dom';

import size from 'lodash/fp/size';

import Empty from '@kwara/components/src/Empty';
import { Text } from '@kwara/components/src/Intl';
import { ModelErrorBanner } from '@kwara/components/src/ModelErrorBanner';
import { If } from '@kwara/components/src/If/If';

import { useGuarantorshipRequest, useGuaranteedLoans } from '../../models/request';

import { useStore } from '../../models/Member';
import { declineGuarantorship } from '../../models/GuarantorshipRequest';
import Section from '../../components/Section';
import { StatusApprovalModal } from '../../components/StatusApprovalModal';
import { GuarantorshipRequestCard, GuaranteedLoanCard } from '../../components/LoanCard';

export const Guaranteed = () => {
  const store = useStore();
  const history = useHistory();

  const { data: guarantorshipRequest } = useGuarantorshipRequest();
  const { data: guaranteedLoans } = useGuaranteedLoans();

  const [showModal, setShowModal] = React.useState(false);

  const [guaranteedAmount, setGuaranteedAmount] = React.useState('');
  const [requestingUserName, setRequestingUserName] = React.useState('');
  const [requestingUserId, setRequestingUserId] = React.useState('');
  const [errors, setErrors] = React.useState('');

  const declineGuarantorshipLoan = async (guarantorshipRequestId, setShowModal) => {
    try {
      await declineGuarantorship(guarantorshipRequestId);
    } catch ({ errors }) {
      setErrors(errors);
    } finally {
      setShowModal(false);
      history.push('/');
    }
  };

  const onDecline = loan => {
    const { attributes, id: guarantorshipRequestId } = loan;
    const { guaranteed_amount, requesting_user_name } = attributes;

    setRequestingUserId(guarantorshipRequestId);
    setRequestingUserName(requesting_user_name);
    setGuaranteedAmount(guaranteed_amount);
    setShowModal(true);
  };

  const onAccept = loan => {
    history.push('/loan/acceptGuarantorship', { loan: loan });
  };

  const hasGuarantorshipRequest = size(guarantorshipRequest?.data) > 0;
  const hasGuaranteedLoans = size(guaranteedLoans?.data) > 0;

  return (
    <Section titleId="Guaranteed.title" values={{ orgCurrency: store.currency }}>
      <ModelErrorBanner className="mt4" errors={errors} state="error" />
      <If
        condition={hasGuarantorshipRequest}
        do={
          <GuarantorshipRequestList
            guarantorshipRequest={guarantorshipRequest}
            onAccept={onAccept}
            onDecline={onDecline}
          />
        }
      />
      <If
        condition={hasGuaranteedLoans}
        do={<GuaranteedLoansList guaranteedLoans={guaranteedLoans?.data} />}
        else={
          <Empty>
            <Text id="Guaranteed.none" />
          </Empty>
        }
      />
      <StatusApprovalModal
        show={showModal}
        type="bad"
        titleId="GuarantorshipRequest.Decline.title"
        subtitleId="GuarantorshipRequest.Decline.subtitle"
        subtitleValues={{ name: requestingUserName, guaranteedAmount }}
        buttonId="GuarantorshipRequest.Decline.action"
        cancelButtonId="GuarantorshipRequest.Decline.cancel"
        onCancel={() => history.push('/')}
        onConfirm={() => declineGuarantorshipLoan(requestingUserId, setShowModal)}
      />
    </Section>
  );
};

function GuarantorshipRequestList({ guarantorshipRequest, onAccept, onDecline }) {
  return guarantorshipRequest?.data.map(loan => {
    const { attributes, id: guarantorshipRequestId } = loan;

    return (
      <GuarantorshipRequestCard
        key={guarantorshipRequestId}
        data={attributes}
        isSelected
        isNewRequest
        onAccept={() => onAccept(loan)}
        onDecline={() => onDecline(loan)}
      />
    );
  });
}

function GuaranteedLoansList({ guaranteedLoans }) {
  return guaranteedLoans.map(loan => <GuaranteedLoanCard key={loan.id} data={loan} />);
}
