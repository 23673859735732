// @flow

import { attr } from 'spraypaint';
import pipe from 'lodash/fp/pipe';

import { Logger } from '@kwara/lib/src/logger';

import Base, { type BaseModel } from './Base';
import createModelErrors, { createErrorsFromApiResponse } from './createModelErrors';
import filterEmptyValues from '../lib/filterEmptyValues';

export const MpesaCredentials = Base.extend({
  static: {
    jsonapiType: 'mpesa_credentials',
    endpoint: '/credentials'
  },
  attrs: {
    c2bPublicKey: attr(),
    c2bPrivateKey: attr(),
    apiPasskey: attr(),
    businessShortCode: attr(),
    initiatorUsername: attr(),
    isC2bPrivateKeyFilled: attr(),
    isInitiatorPasswordFilled: attr(),
    b2cPaybill: attr(),
    active: attr(),
    organisationId: attr(),
    settings: attr()
  },
  methods: {
    async sendCredentials(id, params) {
      const url = `${MpesaCredentials.url()}/${id}`;
      const attributes = pipe(filterEmptyValues)({
        ...params
      });

      const options = {
        ...MpesaCredentials.fetchOptions(),
        method: 'PUT',
        body: JSON.stringify({ data: { attributes } })
      };

      try {
        const response = await window.fetch(url, options);
        if (!response.ok) {
          const body = await response.json();
          this.errors = createErrorsFromApiResponse(body);

          return false;
        }

        return true;
      } catch (errors) {
        Logger.error('Error updating credentials', JSON.stringify(errors));
        this.errors = createModelErrors({
          base: 'APP_NETWORK_ERROR'
        });

        return false;
      }
    }
  }
});

export interface MpesaCredentialsT extends BaseModel<MpesaCredentialsT> {
  c2bPublicKey: string;
  c2bPrivateKey: string;
  apiPasskey: string;
  businessShortCode: string;
  initiatorUsername: string;
  isC2bPrivateKeyFilled: boolean;
  isInitiatorPasswordFilled: boolean;
  b2cPaybill: string;
  active: boolean;
  organisationId: number;
  settings: {
    c2bChannel: string
  };
}
