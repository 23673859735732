import React from 'react';

import { throwError } from './throwError';

type ContextReturnType<ContextType> = [React.Provider<ContextType>, () => ContextType, React.Consumer<ContextType>];

export function createContext<ContextType>(displayName: string, byPass = false): ContextReturnType<ContextType> {
  const Context = React.createContext<ContextType>(null);
  Context.displayName = displayName;

  function useContext() {
    const context = React.useContext(Context);

    if (!context && !byPass) {
      throwError(
        'useContextError',
        'A component must be composed by a <Context/> before consuming useContext',
        useContext
      );
    }

    return context;
  }

  return [Context.Provider, useContext, Context.Consumer];
}
