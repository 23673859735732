// @flow

import { MpesaContact } from '../../components/Wizard/components/MpesaContact';
import { DepositAmount } from './components/DepositAmount';

export const steps = {
  amount: {
    titleId: 'SavingDeposit.Step.amount',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'contact'
      }
    ],
    children: [
      {
        titleId: 'SavingDeposit.DepositAmount.title',
        Component: DepositAmount,
        validate: DepositAmount.validateConfig
      }
    ]
  },
  contact: {
    titleId: 'SavingDeposit.Step.contact',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'MpesaContact.title',
        subtitleId: 'MpesaContact.subtitle',
        Component: MpesaContact,
        validate: MpesaContact.validateConfig
      }
    ]
  }
};
