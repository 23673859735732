import React, { useState, useEffect, useRef, useCallback } from 'react';
import cx from 'classnames';

import { ValueOf } from 'GlobalTypes';

import { If } from '@kwara/components/src/If/If';
import { useBoolean } from '@kwara/lib/src/hooks/useBoolean';
import { useUniqueIds } from '@kwara/lib/src/hooks/useUniqueIds';
import { useComposeRefs } from '@kwara/lib/src/hooks/useComposeRefs';
import { IconArrow } from '@kwara/components/src/Asset/IcnArrow.svg';
import { AccessibleIcon } from '@kwara/components/src/AccessibleIcon/AccessibleIcon';

import { ACCESSIBILITY_KEYS, manageAccordionAccessibility } from './utils/accessibility';

import styles from './index.module.scss';

type PrimitiveDivTypes = React.ComponentPropsWithoutRef<'div'>;
type AccordionElement = React.ElementRef<'div'>;
export interface AccordionPropTypes extends Omit<PrimitiveDivTypes, 'title' | 'onClick'> {
  title: React.ReactNode;
  fill?: string;
  metaDescription?: string;
  openOnMount?: boolean;
  children?: React.ReactNode;
  headerClassName?: string;
  bodyClassName?: string;
  closeOnOutClick?: boolean;
  defaultIcon?: boolean;
  closeAutomatically?: boolean;
  openAutomatically?: boolean;
  closeOnNodesMouseDown?: React.MutableRefObject<any>[];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const Accordion = React.forwardRef<AccordionElement, AccordionPropTypes>(function Accordion(
  props,
  forwardedRef
) {
  const {
    title,
    metaDescription,
    openOnMount,
    children,
    headerClassName,
    bodyClassName,
    onClick,
    closeAutomatically,
    openAutomatically,
    closeOnNodesMouseDown,
    fill,
    defaultIcon = true,
    closeOnOutClick = true,
    ...restProps
  } = props;

  const accordionRef = useRef<HTMLDivElement>(null);
  const composedRefs = useComposeRefs<HTMLDivElement>(accordionRef, forwardedRef);
  const [id, headingId, bodyId] = useUniqueIds(3);
  const [isOpen, { toggle, setToTrue, setToFalse }] = useBoolean(openOnMount);
  const [, setKey] = useState<ValueOf<typeof ACCESSIBILITY_KEYS>>(ACCESSIBILITY_KEYS.NONE);

  const mouseDownEventHandler = useCallback(
    (ev: React.SyntheticEvent | Event) => {
      const closeAutomaticallyOnNode =
        closeOnNodesMouseDown && closeOnNodesMouseDown.some(ref => ref.current.contains(ev.target as Node));

      if (
        closeAutomaticallyOnNode ||
        (isOpen && closeOnOutClick && !accordionRef.current?.contains?.(ev.target as Node))
      ) {
        setToFalse();
      }
    },
    [closeOnNodesMouseDown, closeOnOutClick, isOpen, setToFalse]
  );

  useEffect(() => {
    document.addEventListener('mousedown', mouseDownEventHandler);

    return () => {
      document.removeEventListener('mousedown', mouseDownEventHandler);
    };
  }, [mouseDownEventHandler]);

  /**
   * controlled hide accordion
   */
  useEffect(() => {
    if (closeAutomatically) setToFalse();
  }, [setToFalse, closeAutomatically]);

  /**
   * controlled shown accordion
   */
  useEffect(() => {
    if (openAutomatically) setToTrue();
  }, [setToTrue, openAutomatically]);

  return (
    <div {...restProps} ref={composedRefs} id={id} onKeyDown={manageAccordionAccessibility(setKey, setToTrue)}>
      <button
        type="button"
        data-testid="opener"
        id={headingId}
        aria-expanded={isOpen}
        aria-controls={bodyId}
        title={metaDescription}
        className={cx(styles['accordion__heading'], headerClassName)}
        onClick={ev => {
          toggle();
          onClick?.(ev);
        }}
      >
        <span>{title}</span>

        <If
          condition={defaultIcon}
          do={
            <span className={styles['icon-wrapper']}>
              <AccessibleIcon label={isOpen ? 'Click to close list' : 'Click to open list'}>
                <IconArrow fill={fill} className={styles[`icon-arrow--${isOpen ? 'open' : 'closed'}`]} />
              </AccessibleIcon>
            </span>
          }
        />
      </button>

      <If
        condition={isOpen}
        do={
          <div id={bodyId} role="region" aria-labelledby={headingId} className={bodyClassName}>
            {children}
          </div>
        }
      />
    </div>
  );
});
