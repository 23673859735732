// @flow

import * as React from 'react';
import cx from 'classnames';
import get from 'lodash/fp/get';
import trimEnd from 'lodash/fp/trimEnd';

import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import { useMember } from '@kwara/models/src/models/request/hooks';
import { capitalize } from '@kwara/models/src/models/Member';
import { segmentTrack } from '@kwara/components/src/Segment';

import { useStore } from '../../models/Member';
import { FeaturesCarousel } from '../../components/FeaturesCarousel';
import { MemberOverviewCarousel } from '../../components/MemberOverviewCarousel';
import { QuickActionsMenu } from '../../components/QuickActionsMenu';
import { CardLoader } from '../../components/CardLoader';
import styles from './index.module.scss';

const memberIncludes = [];

export const MemberOverview = () => {
  const store = useStore();

  const organisationName = store.organisationName;
  const memberResponse = useMember(store.memberId, memberIncludes);

  const referralData = {
    referralScore: get('profile.referralScore', store),
    referralCode: get('profile.referralCode', store),
    referralAmountPerPoint: get('profile.referralAmountPerPoint', store)
  };

  React.useEffect(() => {
    segmentTrack('User home page Loaded');
  }, []);

  return (
    <div className={styles.Layout}>
      <Loadable
        {...memberResponse}
        loading={
          <>
            <CardLoader />
            <br />
            <CardLoader />
          </>
        }
      >
        {member => {
          const totalSavings = Math.abs(member.totalSavings - member.shareCapitalAmount) || 0;
          return (
            <>
              <section className="bg-white pt3">
                <div className="flex justify-between mt3 ma3">
                  <div className="flex">
                    {store.organisation?.logoUrl && (
                      <div className={cx(styles.Image)}>
                        <img alt="logo" src={store.organisation?.logoUrl} />
                      </div>
                    )}
                    <div className="mh3">
                      <div className="mobile-text-large" aria-label="Sacco Name">
                        {organisationName}
                      </div>
                      <div className="mobile-text-medium">
                        <Text id="MemberOverview.hi" values={{ name: trimEnd(capitalize(member.firstName)) }} />
                      </div>
                      <div className="mobile-text-small">
                        <Text id="MemberOverview.memberId" values={{ id: member.id }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt4">
                  <QuickActionsMenu />
                </div>
              </section>
              <section className="bg-white mobile-text-medium mv3">
                <div className="pt3 pl3">
                  <MemberOverviewCarousel
                    totalSavings={totalSavings}
                    totalLoans={member.totalLoansBalance || 0}
                    shareCapitalAmount={member.shareCapitalAmount || 0}
                  />
                </div>
              </section>
              <section className="bg-white mobile-text-medium mv3">
                <div className="pt3 pl3">
                  <FeaturesCarousel referralData={referralData} />
                </div>
              </section>
            </>
          );
        }}
      </Loadable>
    </div>
  );
};
