//@flow
import React from 'react';
import get from 'lodash/fp/get';
import { Form } from 'react-final-form';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import createValidator from '@kwara/lib/src/validator';
import { ModelErrorBanner } from '@kwara/components/src/ModelErrorBanner';

import { isValidReferralCode } from '@kwara/lib/src/validator/validators';
import { FieldCard } from '../../components/FieldCard';
import { Navigation } from '../../components/Navigation';
import { useNewUserStore } from '../../models/NewUserStore';

const submit = async (phone, otp, data, auth, history, setErrors) => {
  const { referralCode: referral_code } = data;

  if (!referral_code) {
    return history.push('/secureFourPinUpdate');
  }

  const payload = {
    data: {
      attributes: {
        phone_number: phone,
        current_pin: otp,
        referral_code
      }
    }
  };

  try {
    await auth.sendReferralCode(payload);
    history.push('/secureFourPinUpdate');
  } catch (error) {
    setErrors(error);
  }
};

export const Referral = ({ auth, history }) => {
  const { storePhone, storeOTP, storeOrganisation } = useNewUserStore();
  const [errors, setErrors] = React.useState(null);

  return (
    <div className="bg-white flex flex-column h-100 w-100">
      <Navigation saccoName={get('name', storeOrganisation)} onClick={() => history.push('/')} />
      <Form
        onSubmit={data => submit(storePhone, storeOTP, data, auth, history, setErrors)}
        validate={createValidator(validateConfig)}
        render={({ handleSubmit, invalid, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit} className="flex flex-column h-100 justify-between pb5 ph3">
              <FieldCard
                name="referralCode"
                title="Referral.Input.Card.title"
                subtitle="Referral.Input.Card.subtitle"
              />
              {errors && <ModelErrorBanner className="mt4 mb4" errors={errors} state="error" />}

              <Button
                aria-label="Next"
                isSubmit
                disabled={invalid || submitting}
                glyphRightId={submitting ? Button.Glyphs.Spinner : null}
                type="primary"
                size="medium"
              >
                <Text
                  id={values.referralCode ? 'Referral.Input.Button.Next.label' : 'Referral.Input.Button.Skip.label'}
                />
              </Button>
            </form>
          );
        }}
      />
    </div>
  );
};

const validateConfig = {
  referralCode: {
    maxlength: 20,
    custom: referralCode => {
      if (isValidReferralCode(referralCode)) {
        return null;
      }
      return 'invalidReferralCode';
    }
  }
};
