import React from 'react';
import get from 'lodash/fp/get';

import { fields } from '@kwara/models/src/models/Loan';
import { addDays, getCurrentDate } from '@kwara/lib/src/dates';
import { Record, Section } from '@kwara/components/src/Summary';
import { LoanSubstepProps } from '@kwara/components/src/pages/LoanAdd';
import { Text, Currency, Percent, Date } from '@kwara/components/src/Intl';

import { totalAmountWithInteresRate } from '../../../../models/InstantLoan';

export const Summary = ({ data, StackChild, parentUrl }: LoanSubstepProps) => {
  const disbursementMode = get(fields.disbursement.mode, data);
  const interestPercentage = get('interestRate.percentage', data.product);
  const editUrl = (path: string) => `${parentUrl}/${path}`;

  return (
    <StackChild>
      <Section titleId="LoanAdd.Summary.Loan.title" editUrl={editUrl('configure/1')}>
        <Record labelId="LoanAdd.Summary.Loan.name" value={data.product.name} />
        <Record labelId="LoanAdd.Summary.Loan.amount" value={<Currency format="currency" value={data.amount} />} />
        <Record labelId="LoanAdd.Summary.Loan.interestRate" value={<Percent value={interestPercentage / 100} />} />
        <Record labelId="LoanAdd.Summary.Loan.monthlyInstallment" value={Math.floor(Number(data.installments))} />
      </Section>
      <Section titleId="LoanAdd.Summary.Disbursement.title" editUrl={editUrl('configure/2')}>
        <Record
          labelId="LoanAdd.Summary.Disbursement.method"
          value={<Text id={`TransactionMethod.${disbursementMode}`} />}
        />
        <Record labelId="LoanAdd.Summary.Disbursement.phone" value={data.member.phoneNumber} />
      </Section>
      <Section titleId="LoanAdd.Summary.Repayment">
        <Record
          labelId="LoanAdd.Summary.Repayment.method"
          value={<Text id={`TransactionMethod.${disbursementMode}`} />}
        />
        <Record
          labelId="InstantLoan.Summary.Repayment.total"
          value={
            <Currency
              format="currency"
              hideDecimals={false}
              value={totalAmountWithInteresRate(data.amount, interestPercentage)}
            />
          }
        />
        <Record
          labelId="InstantLoan.Summary.Repayment.dueDate"
          value={<Date value={addDays(getCurrentDate(), data.product.maxDurationUI().value)} />}
        />
      </Section>
    </StackChild>
  );
};
