import { InferredModel } from 'GlobalTypes';
import { attr } from 'spraypaint';

import Base from '../../Base';

export const PaymentMethodGlLink = Base.extend({
  static: {
    jsonapiType: 'payment_method_gl_links'
  },
  attrs: {
    name: attr()
  }
});

export const GlLink = (id: string) =>
  PaymentMethodGlLink.extend({
    static: {
      endpoint: `/payment_methods/${id}/gl_links`
    }
  });

export interface PaymentMethodGlLinkT extends Omit<InferredModel<typeof PaymentMethodGlLink>, 'errors'> {
  name: string;
}
