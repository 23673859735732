// @flow
import * as React from 'react';

import { type RenderableStepConfig } from '@kwara/components/src/Wizard/deprecated/Wizard';

import Item from './Item';
import styles from './index.module.scss';

type Props = {
  currentStep: number | string | void,
  currentSubStep?: ?number,
  subSteps: RenderableStepConfig,
  // Each array item is a step, containing another array of substep ids
  // e.g. [ ["about/1", "about/2"], ["contact/1"]]
  ordered: Array<string[]>,
  parentUrl: string
};

export type StepState = 'past' | 'current' | 'future';

const stepState = (currentStep: number, targetStep: number) => {
  if (currentStep === targetStep) {
    return 'current';
  } else if (currentStep > targetStep) {
    return 'past';
  }

  return 'future';
};

const WizardProgress = (props: Props) => {
  const { currentStep, currentSubStep, subSteps, ordered, parentUrl } = props;
  const stepOrdering = {};

  ordered.forEach((subStepIds, parentIndex) => {
    const parent = subSteps[subStepIds[0]].parent;
    stepOrdering[parent.id] = parentIndex;
  });

  return (
    <ul className={`flex pa0 h3 w-100 ${styles.Container}`}>
      {ordered.map((subStepIds, index) => {
        const parent = subSteps[subStepIds[0]].parent;
        const state = stepState(stepOrdering[currentStep], index);
        let progress;

        if (state === 'future') {
          progress = 0;
        } else if (subStepIds.length && state === 'current' && typeof currentSubStep === 'number') {
          progress = currentSubStep / subStepIds.length;
        } else {
          progress = 1;
        }

        return (
          <Item
            key={index + 1}
            number={index + 1}
            progress={progress}
            stepState={state}
            editUrl={`${parentUrl}/${parent.id}/1`}
            titleId={parent.titleId}
          />
        );
      })}
    </ul>
  );
};

export default WizardProgress;
