// @flow
import React from 'react';
import cx from 'classnames';
import get from 'lodash/fp/get';
import { type IntlShape, injectIntl } from 'react-intl';

import { appName } from '@kwara/lib/src/utils';
import { useComposeRefs } from '@kwara/lib/src/hooks/useComposeRefs';
import { composeEvents } from '@kwara/lib/src/utils/composeEvents';
import { getTranslation, Text as I18n } from '@kwara/components/src/Intl';
import Asset, { getAssetDataUrlById, type Glyph } from '@kwara/components/src/Asset';

import stylesCore from './TextFieldCore.module.scss';
import stylesMobile from './TextFieldMobile.module.scss';

type Size = 'regular' | 'medium';
type OptionalString = string | undefined;

const glyphTextClassMapping = {
  small: '',
  regular: 'kw-text-small-design-system'
};
/**************************************************************************
 * @param {
 * { isMemberApp:boolean, size:Size, className:OptionalString}
 * }
 * @AboutTheFunction This function constructs the different platform
 * properties needed by the component and then exposes those properties
 **************************************************************************/
function getPlatformProps({
  isMemberApp,
  size,
  className
}: {
  isMemberApp: boolean,
  size: Size,
  className: OptionalString
}) {
  const key = isMemberApp ? 'memberApp' : 'coreApp';
  const platformPropsRef = {
    //props for member app
    memberApp: {
      size: 'small',
      styles: stylesMobile,
      className: cx(className ?? 'mobile-text-medium bg-mobile-primary-50')
    },
    //props for core app
    coreApp: {
      size,
      styles: stylesCore,
      className: cx(`kw-text-${size}`, className)
    }
  };
  const propsRef = platformPropsRef[key];

  return {
    size: propsRef.size,
    className: propsRef.className,
    styles: propsRef.styles
  };
}

function composePlaceholder(
  intl: IntlShape,
  placeholderId: OptionalString,
  placeholder: OptionalString,
  values?: { [string]: mixed }
) {
  let textNode = null;
  if (placeholderId) {
    textNode = getTranslation(intl, placeholderId, values);
  } else if (placeholder) {
    textNode = placeholder;
  }
  return textNode;
}

function getGlyph(id, classes, glyphColClass, styles) {
  let node: React.ReactNode = null;
  if (id != null && Asset.Glyphs.hasOwnProperty(id)) {
    node = <Asset className={classes} id={id} col={Asset.Colours.grey300} />;
  } else if (typeof id === 'string') {
    node = (
      <span className={cx(get('TextGlyph', styles), glyphColClass, classes)}>
        <I18n id={id} />
      </span>
    );
  }
  return node;
}

function generateClearButton(onClear: React.MouseEventHandler<HTMLButtonElement>, styles = {}) {
  return (
    <button tabIndex={-1} className={`flex-none ${styles.rightGlyph} ${styles.ClearButton}`} onClick={onClear}>
      {getGlyph(Asset.Glyphs.Cross, styles)}
    </button>
  );
}

type PrimitiveInputProps = React.ComponentPropsWithoutRef<'input'>;
type TextFieldElement = React.ElementRef<'input'>;
interface PublicProps extends PrimitiveInputProps {
  size: Size;
  step?: string;
  error?: boolean;
  border?: boolean;
  compact?: boolean;
  clearable?: boolean;
  rightGlyph?: ?Glyph;
  isCurrency?: boolean;
  glyphColClass?: string;
  placeholderId?: string;
  'data-testid'?: string;
  rightAction?: React.Node;
  appearAsSelect?: boolean;
  values?: { [string]: mixed };
  leftGlyph?: ?Glyph | ?string;
  inputOnBlur?: (evt: SyntheticInputEvent<HTMLInputElement>) => void;
}
interface TextFieldProps extends PublicProps {
  intl: IntlShape;
}

const TextField = (() =>
  React.forwardRef<TextFieldProps, TextFieldElement>((props, forwardedRef) => {
    const {
      intl,
      name,
      size,
      value,
      error,
      border,
      values,
      compact,
      className,
      clearable,
      onBlur,
      inputOnBlur,
      leftGlyph,
      rightGlyph,
      rightAction,
      glyphColClass,
      placeholderId,
      appearAsSelect,
      ...restProps
    }: TextFieldProps = props;
    // Do not pass to input
    delete restProps['data-testid'];

    const inputRef = React.useRef<HTMLInputElement>(null);
    const composedRefs = useComposeRefs<HTMLInputElement>(inputRef, forwardedRef);

    const isClearable = clearable && value && value.length > 0;
    const platformProps = getPlatformProps({ isMemberApp: appName.isMember, size, className });
    const left = getGlyph(
      leftGlyph,
      cx('flex-none', platformProps.styles.leftGlyph, glyphTextClassMapping[platformProps.size]),
      glyphColClass
    );
    let right = isClearable
      ? generateClearButton(() => restProps.onChange({ target: { value: '' } }), platformProps.styles)
      : rightAction;
    right = rightGlyph
      ? getGlyph(
          rightGlyph,
          cx('flex-none', platformProps.styles.rightGlyph, glyphTextClassMapping[platformProps.size]),
          glyphColClass
        )
      : right;

    const containerClasses = [
      platformProps.styles.Container,
      `w-100 dib flex items-center ${border ? 'ba' : ''} ${error ? platformProps.styles.error : ''}`,
      { [platformProps.styles.hasGlyph]: !!leftGlyph || !!rightGlyph },
      platformProps.styles[platformProps.size],
      platformProps.className,
      compact ? platformProps.styles.compact : null
    ];

    return (
      <div
        className={cx(containerClasses)}
        data-testid={props['data-testid']}
        onClick={React.useCallback(() => {
          if (composedRefs.current?.focus === 'function') {
            composedRefs.current.focus();
          }
        }, [composedRefs])}
      >
        {left}
        <input
          {...restProps}
          id={name}
          name={name}
          value={value}
          ref={composedRefs}
          onBlur={composeEvents(inputOnBlur, onBlur)}
          placeholder={composePlaceholder(intl, placeholderId, restProps.placeholder, values)}
          className={cx([
            platformProps.styles.Input,
            'flex-auto',
            { [platformProps.styles.appearsAsSelect]: appearAsSelect }
          ])}
          style={appearAsSelect ? { backgroundImage: `url(${getAssetDataUrlById(Asset.Glyphs.DoubleArrow)})` } : {}}
        />
        <label htmlFor={name} className={cx('pointer', platformProps.styles['right-wrapper'])}>
          {right}
        </label>
      </div>
    );
  }))();

TextField.defaultProps = {
  type: 'text',
  border: true,
  error: false,
  disabled: false,
  inputMode: 'text',
  autoComplete: 'off',
  onBlur: e => e,
  inputOnBlur: e => e,
  onChange: () => {}
};

const Wrapped = (injectIntl(TextField): React.ComponentType<PublicProps>);

// focus() but the wrapped HoC doesn't allow this
//$FlowFixMe
Wrapped.prototype.focus = () => {};

export default Wrapped;
