// @flow

import * as React from 'react';

import { type SubStepComponentProps } from '@kwara/components/src/Wizard/deprecated/Wizard';
import { DatePickerInputs } from '@kwara/components/src/Form';

import * as recordValidations from '../../../../../webapp-sacco/src/lib/recordValidations';

export function NextOfKin(props: SubStepComponentProps<>) {
  const { StackChild, TextField, PhoneField, data } = props;

  return (
    <StackChild>
      <TextField
        name="kinFullName"
        errorBaseId="AddMember.Profile.Name"
        placeholderId="Register.NextOfKin.FullName.placeholder"
        labelId="Register.NextOfKin.FullName.label"
        required
      />
      <TextField
        name="kinRelationship"
        errorBaseId="AddMember.Profile.Name"
        placeholderId="Register.NextOfKin.Relationship.placeholder"
        labelId="Register.NextOfKin.Relationship.label"
        required
      />
      <PhoneField name="kinPhoneNumber" labelId="Register.Contact.Mobile.label" required />
      <DatePickerInputs
        name="kinDateOfBirth"
        placeholderId="AddMember.Personal.DateOfBirth.placeholder"
        labelId="AddMember.Personal.DateOfBirth.label"
        initial={data.kinDateOfBirth}
        required
      />
    </StackChild>
  );
}
NextOfKin.validate = {
  kinFullName: {
    isRequired: () => true,
    maxlength: 30,
    ...recordValidations.personName
  },
  kinRelationship: {
    isRequired: () => true,
    maxlength: 30,
    ...recordValidations.personName
  },
  kinPhoneNumber: {
    isRequired: () => true,
    phoneNumber: true
  },
  kinDateOfBirth: {
    isRequired: () => true,
    ...recordValidations.dateOfBirth
  }
};
