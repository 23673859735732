import * as React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';
import { useMemberSavings } from '@kwara/models/src/models/request/hooks';

import { useStore } from '../../models/Member';
import { SavingType } from '@kwara/models/src';
import { SavingAccountsList } from './SavingAccountsList';

const savingsIncludes = ['product', 'transactions'];
export const SelectSavings = () => {
  const store = useStore();
  const response = useMemberSavings(store.memberId, savingsIncludes);

  return <Loadable {...response}>{(savings: SavingType[]) => <SavingAccountsList savings={savings} />}</Loadable>;
};
