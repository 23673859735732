// @flow

import { attr } from 'spraypaint';

import Base, { type BaseModel } from './Base';

export type GlType = 'ASSET' | 'LIABILITY' | 'EQUITY' | 'INCOME' | 'EXPENSE';
export type UsageT = 'HEADER' | 'DETAIL';

export const GeneralLedgerAccount = Base.extend({
  static: {
    jsonapiType: 'gl_accounts',
    endpoint: '/general_ledger_accounts'
  },
  attrs: {
    name: attr(),
    description: attr(),
    type: attr(),
    usage: attr(),
    allowManualJournalEntries: attr(),
    /**
     * V1 attributes
     */
    glCode: attr()
  },
  methods: {}
});

export interface GeneralLedgerAccountT extends BaseModel<GeneralLedgerAccountT> {
  name: string;
  description: string;
  type: GlType;
  usage: UsageT;
  allowManualJournalEntries: boolean;
}

export interface V1GeneralLedgerAccountT extends GeneralLedgerAccountT {
  glCode: string;
}
