import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { If } from '@kwara/components/src/If/If';
import { Text, Currency } from '@kwara/components/src/Intl';
import { Completion, Types } from '@kwara/components/src/Completion';

import { analyticsId } from '../config';
import { usePurchaseAirtime } from '..';
import { CashbackStatus } from './CashbackStatus';
import { getCashbackAmount } from '../../../models/AirtimePurchases';

/**
 * List
 */
function List(props: { children: React.ReactElement }) {
  return (
    <li className="list none ma0 pa0 pt1">
      <b>{props.children}</b>
    </li>
  );
}

/**
 * SubTitle
 */
function SubTitle() {
  const {
    data: { phone_number, purchase_amount }
  } = usePurchaseAirtime();

  return (
    <ul className="pa0 ma0 bt pt1 white">
      <List>
        <Text id="Airtime.Status.Success.phoneNumber" values={{ phoneNumber: phone_number }} />
      </List>
      <List>
        <Text
          id="Airtime.Status.Success.amount"
          values={{ amount: <Currency format="currency" value={purchase_amount} /> }}
        />
      </List>
      <List>
        <Text
          id="Airtime.Status.Success.cashback"
          values={{ cashback: <Currency format="currency" value={getCashbackAmount(purchase_amount).formatted} /> }}
        />
      </List>
    </ul>
  );
}

/**
 * SuccessStatus
 */
type SuccessStatusPropTypes = {
  onCancel(): void;
  hasCashback: boolean;
};

export function SuccessStatus({ hasCashback, onCancel }: SuccessStatusPropTypes) {
  const [shownCashback, setShownCashback] = useState(false);
  const history = useHistory();
  const { isPolling } = usePurchaseAirtime();

  const buttonTextId = hasCashback ? 'Airtime.Status.Success.checkCashback' : 'Airtime.Status.Success.close';

  return (
    <If
      condition={shownCashback}
      do={<CashbackStatus onCancel={onCancel} />}
      else={
        <Completion
          type={Types.good}
          isLoading={isPolling}
          analyticsId={analyticsId}
          titleId="Airtime.Status.Success.title"
          subtitle={<SubTitle />}
          buttonTextId={buttonTextId}
          onConfirm={() => {
            if (hasCashback) setShownCashback(true);
            else history.push('/');
          }}
        />
      }
    />
  );
}
