import * as React from 'react';

import { SubStepComponentProps } from '@kwara/components/src/Wizard/latest/types/sharedTypes';

import { Container } from '../../../../layouts';
import { Picker } from '../../components/Picker';
import { useSuggestSaccoContext, FormState } from '../..';

import styles from './index.module.scss';

export function IncomeRange({ addData }: SubStepComponentProps<FormState>) {
  const { data, onSetIncomeRange, checkIsActiveIncomeRange } = useSuggestSaccoContext();

  return (
    <Container>
      <ul className={styles['list']}>
        {data.getIncomeRanges().map(({ name, value }, i) => (
          <li key={i.toString()}>
            <Picker isActive={checkIsActiveIncomeRange(value)} onClick={onSetIncomeRange(value, addData)}>
              {name}
            </Picker>
          </li>
        ))}
      </ul>
    </Container>
  );
}

IncomeRange.validate = {
  incomeRange: {
    isRequired: () => true
  }
};
