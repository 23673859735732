//@flow
import React from 'react';
import cx from 'classnames';

import { Text } from '@kwara/components/src/Intl';
import { SubscribedTextField } from '@kwara/components/src/Form';

import styles from './index.module.scss';

type Props = {
  name: string,
  title: string,
  subtitle: string,
  disabled?: boolean
};

export const FieldCard = ({ name, title, subtitle, disabled }: Props) => {
  return (
    <div className={cx(styles.Container, 'mb3')}>
      <div className={cx(styles.Content)}>
        <div className={cx(styles.Header)}>
          <div className={cx(styles.Title, 'mobile-text-regular flex items-center justify-between')}>
            <span className={styles.Line} />
            <span className="ph1">
              <Text id={title} />
            </span>
            <span className={styles.Line} />
          </div>
        </div>
        <div className={cx(styles.Body, 'flex hidden')}>
          <div className={cx(styles.Subtitle, 'mobile-text-small')}>
            <Text id={subtitle} />
          </div>
        </div>
        <div className={cx(styles.Footer)}>
          <SubscribedTextField
            name={name}
            disabled={disabled}
            className="bg-white"
            placeholderId="Referral.Input.Card.placeholderId"
            size="medium"
            sensitiveValidation
          />
        </div>
      </div>
    </div>
  );
};
