// @flow

import * as React from 'react';
import orderBy from 'lodash/orderBy';

import Empty from '@kwara/components/src/Empty';
import { Currency, Text } from '@kwara/components/src/Intl';

import InfoList from '../../components/InfoList';
import Section from '../../components/Section';
import Transaction, { Metadata } from '../../components/Transaction';

export const Transactions = ({ accounts, displayable = ['DEPOSIT'] }) => {
  const hasAccounts = accounts && accounts.length > 0;

  const empty = (
    <Empty>
      <Text id="SavingsTransactions.none" />
    </Empty>
  );

  if (!hasAccounts) {
    return empty;
  }

  const transactions = accounts
    .map(account =>
      account.transactions.map(transaction => {
        transaction.account = account;
        return transaction;
      })
    )
    .flatten();

  const displayableTransactions = transactions.filter(t => displayable.includes(t.type));

  const orderedTransactions = orderBy(displayableTransactions, ['createdAt'], ['desc']);

  const hasDisplayableTransactions = orderedTransactions.length > 0;

  const list = orderedTransactions.map(transaction => (
    <Transaction
      key={transaction.id}
      amount={transaction.amount}
      date={transaction.createdAt}
      metadata={<Metadata account={transaction.account} label="Account" />}
    >
      <InfoList className="kw-text-small">
        <InfoList.Item label={<Text id="SavingsTransactions.id" />} value={transaction.id} />
        {transaction.paymentMethod && (
          <InfoList.Item label={<Text id="SavingsTransactions.paymentMethod" />} value={transaction.paymentMethod} />
        )}
        <InfoList.Item
          label={<Text id="SavingsTransactions.balance" />}
          value={<Currency format="currency" value={transaction.balance} />}
        />
      </InfoList>
    </Transaction>
  ));

  return (
    <Section titleId="SavingsTransactions.title">
      <p className="ma0 pa0 kw-text-regular grey-400">
        <Text id="SavingsTransactions.repaymentView" values={{ number: displayableTransactions.length }} />
      </p>
      {hasDisplayableTransactions ? list : empty}
    </Section>
  );
};
