// @flow

import * as React from 'react';
import classnames from 'classnames';

import Actionable, { type Location } from '@kwara/components/src/Actionable';
import Asset, { type Glyph, type GlyphCols } from '@kwara/components/src/Asset';
import { appName } from '@kwara/lib/src/utils';
import { generatePlatformClassNames } from '@kwara/lib/src/utils/generatePlatformClassNames';

import { typeToIcon } from './ActionButton';
import { SimpleButton } from './SimpleButton';

import styles from './index.module.scss';

export { default as ActionButton } from './ActionButton';
export { default as BackButton } from './BackButton';

export type ButtonSizesT = 'small' | 'regular' | 'medium' | 'large';
interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  children: React.ChildrenArray<*>;
  className?: string;
  disabled?: boolean;
  glyphLeftId?: ?Glyph | ?string;
  glyphRightId?: ?Glyph | ?string;
  glyphColor?: GlyphCols;
  hidden?: boolean;
  isSubmit?: boolean;
  to?: Location;
  type?: 'primary' | 'secondary' | 'destructive';
  size?: ButtonSizesT;
  withLinkContext?: boolean;
  onClick?: () => void;
}

const resolveGlyph = (id: ?string): ?Glyph => {
  if (id && Asset.Glyphs[id]) {
    return Asset.Glyphs[id];
  } else if (id && typeToIcon[id]) {
    return typeToIcon[id];
  }

  return null;
};

const Button = ({
  children,
  className,
  disabled = false,
  glyphLeftId,
  glyphRightId,
  glyphColor: glyphColorProp,
  type = 'secondary',
  size = 'regular',
  hidden,
  ...props
}: ButtonProps) => {
  // This change is due to a decision made by the design team: https://app.shortcut.com/getkwara/story/36598/adopt-new-css-styling
  // We're keeping one size for all buttons in Core, but we're also keeping the size prop for Connect app.
  const sizePerApp = appName.isSacco ? 'regular' : size;
  const classes = generatePlatformClassNames({ sacco: [styles.br], member: ['br-pill'] }, [
    'inline-flex items-center link ba b--transparent dib v-mid',
    disabled ? styles.disabled : styles.enabled,
    styles[sizePerApp],
    appName.isSacco ? styles[type] : ''
  ]);

  const isGlyphOnly = React.Children.toArray(children).length === 0;

  if (type === 'primary') {
    if (appName.isMember) {
      classes.push(
        disabled ? styles['mobile-primary-disabled'] : '',
        styles['mobile-primary'],
        'white visited-white link-white',
        styles.glyphPrimary
      );
    } else {
      classes.push(
        disabled ? styles['websacco-primary-disabled'] : '',
        styles['websacco-primary'],
        'white visited-white link-white',
        styles.glyphPrimary
      );
    }
  } else if (type === 'destructive') {
    classes.push(disabled ? 'bg-red-100' : 'bg-red-500', 'white visited-white link-white');
  } else {
    classes.push(
      'bg-transparent',
      disabled ? 'light-grey-500 b--light-grey-400' : 'b--light-grey-500',
      styles.glyphSecondary
    );
  }

  if (isGlyphOnly) {
    classes.push(styles.glyphOnly);
  }

  let glyphCol;

  if (type === 'primary' || type === 'destructive') {
    glyphCol = Asset.Colours.white;
  } else if (disabled) {
    glyphCol = Asset.Colours.grey300;
  } else {
    glyphCol = Asset.Colours.black;
  }

  const glyphLeftIdResolved = resolveGlyph(glyphLeftId);
  const glyphLeft =
    glyphLeftIdResolved != null ? (
      <Asset size={sizePerApp} className={styles.glyphLeft} id={glyphLeftIdResolved} col={glyphColorProp || glyphCol} />
    ) : null;

  const glyphRightIdResolved = resolveGlyph(glyphRightId);
  const glyphRight =
    glyphRightIdResolved != null ? (
      <Asset
        size={sizePerApp}
        className={styles.glyphRight}
        id={glyphRightIdResolved}
        col={glyphColorProp || glyphCol}
      />
    ) : null;

  const inner = (
    <>
      {glyphLeft}
      <span className={styles.content}>
        {children} {isGlyphOnly}
      </span>
      {glyphRight}
    </>
  );

  const classNames = classnames(classes, className, 'hide-on-print', {
    [styles.hidden]: hidden
  });

  if (!props.to) {
    return (
      <SimpleButton {...props} className={classNames} disabled={disabled}>
        {inner}
      </SimpleButton>
    );
  }

  return (
    <Actionable {...props} className={classNames} disabled={disabled}>
      {inner}
    </Actionable>
  );
};

Button.Glyphs = Asset.Glyphs;

export default Button;
