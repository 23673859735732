import React from 'react';

export function composeEvents<E = Element>(...handlers: ((ev: React.SyntheticEvent<E>) => any)[]) {
  return function eventHandler(ev: React.SyntheticEvent<E>) {
    if (ev.defaultPrevented) return;

    handlers.filter(Boolean).forEach(function(handler) {
      handler(ev);
    });
  };
}
