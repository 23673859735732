import { attr, belongsTo } from 'spraypaint';

import Base, { BaseModel } from './Base';
import { GlAccountT, GlAccount } from './GlAccount';
import { ReportFieldT } from './ReportField';

export const ReportFieldGlAccountLink = Base.extend({
  static: {
    jsonapiType: 'report_field_gl_account_links',
    endpoint: '/reporting/field_gl_account_links'
  },
  attrs: {
    scalar: attr(),
    reportFieldId: attr(),
    glAccountId: attr(),
    reportField: belongsTo(),
    glAccount: belongsTo({ type: GlAccount })
  }
});

export interface ReportFieldGlAccountLinkT extends BaseModel<ReportFieldGlAccountLinkT> {
  scalar: number;
  reportFieldId: string;
  glAccountId: string;
  reportField: ReportFieldT;
  glAccount: GlAccountT;
}
