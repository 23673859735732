// @flow

import * as React from 'react';
import classnames from 'classnames';
import size from 'lodash/fp/size';
import keys from 'lodash/fp/keys';

import { mapIndexed } from '@kwara/lib/src/lodash';
import { Text } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';

type Props = {
  currentSection: string
};

type ItemProps = {
  selected: boolean,
  id: string
};

const Item = ({ selected, id }: ItemProps) => {
  const classes = ['ma0 pa0 pb2 dib', selected ? 'bb b--grey-500' : null];

  return (
    <li className={classnames(classes)}>
      <Link to={id} underline={false} active={selected}>
        <Text id={`LoanDetail.${id}`} />
      </Link>
    </li>
  );
};

const separator = <span className="grey-300 dib ph2"> &middot; </span>;

export const PageNavigation = ({ currentSection, sections }: Props) => {
  const sectionsKeys = keys(sections);
  return (
    <ul className={classnames('ma0 pa0 list-reset bb b--light-grey-300')}>
      {mapIndexed(
        (section, index) => (
          <React.Fragment key={section}>
            <Item key={section} id={section} selected={currentSection === section} />
            {index !== size(sections) - 1 ? separator : null}
          </React.Fragment>
        ),
        sectionsKeys
      )}
    </ul>
  );
};
