import { useState, useMemo } from 'react';

import { createContext } from '@kwara/lib/src/utils/createContext';
import { OrganisationT } from '@kwara/models/src/models/Organisation';

type NewUserStoreContextType = {
  storePhone: string;
  storeOrganisation: OrganisationT;
  storeOTP: string;
  payLaterEnabled: boolean;
  userSignupStatus: string;
  setStorePhone: React.Dispatch<React.SetStateAction<string>>;
  setStoreOTP: React.Dispatch<React.SetStateAction<string>>;
  setPayLaterEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setUserSignupStatus: React.Dispatch<React.SetStateAction<string>>;
  setStoreOrganisation: React.Dispatch<React.SetStateAction<OrganisationT>>;
};

const [NewUserStoreProvider, useNewUserStore] = createContext<NewUserStoreContextType>('NewUserStoreContext');

function useNewUserStoreContextValues() {
  const [storePhone, setStorePhone] = useState<string>('');
  const [storeOTP, setStoreOTP] = useState<string>('');
  const [payLaterEnabled, setPayLaterEnabled] = useState<boolean>(false);
  const [userSignupStatus, setUserSignupStatus] = useState<string>('');
  const [storeOrganisation, setStoreOrganisation] = useState<OrganisationT>({} as OrganisationT);

  return useMemo(
    () => ({
      storePhone,
      storeOTP,
      payLaterEnabled,
      userSignupStatus,
      storeOrganisation,
      setStorePhone,
      setStoreOTP,
      setPayLaterEnabled,
      setUserSignupStatus,
      setStoreOrganisation
    }),
    [payLaterEnabled, storeOTP, storeOrganisation, storePhone, userSignupStatus]
  );
}

export { NewUserStoreProvider, useNewUserStore, useNewUserStoreContextValues };
