import React from 'react';

import { Text, Currency, Date, Duration } from '@kwara/components/src/Intl';

import MainVector from './assets/MainVector.svg';

import { LoanStateTag } from './Components/Tag';
import { TwoColumnsDetails } from './Components/DetailsContent';

import { LoanCardBase } from './LoanCardBase';

type GuaranteedLoanData = {
  attributes: {
    loan_principal: string;
    loan_duration: string;
    guarantorship_request_date: string;
    guaranteed_amount: string;
    loan_state: string;
    loan_owner: string;
    loan_name: string;
  };
  id: string;
};

const createDetailsArray = (data: GuaranteedLoanData) => {
  const { id, attributes } = data;
  const { loan_principal, loan_duration, guarantorship_request_date } = attributes;

  const transId = 'LoanCard.GuaranteeLoanCard.Detail';

  const dateLabel = `${transId}.RequestDate`;
  return [
    { key: `${transId}.LoanID`, value: id },
    {
      key: `${transId}.Principal`,
      value: <Currency format="currency" value={loan_principal} />
    },
    {
      key: `${transId}.Duration`,
      value: <Duration value={loan_duration} />
    },
    { key: dateLabel, value: <Date value={guarantorship_request_date}></Date> }
  ];
};

type GuaranteedLoanCardProps = {
  data: GuaranteedLoanData;
};

export const GuaranteedLoanCard = ({ data }: GuaranteedLoanCardProps) => {
  const { attributes } = data;
  const { guaranteed_amount, loan_state, loan_owner, loan_name } = attributes;

  const detailsArray = createDetailsArray(data);

  return (
    <LoanCardBase
      isSelected={false}
      title={loan_name}
      subtitle={<Text id="LoanCard.GuaranteeLoanCard.Subtitle.For" values={{ guarantee: loan_owner }} />}
      icon={String(MainVector)}
      amountLabelId={'LoanCard.GuaranteeLoanCard.Guarantee'}
      amount={guaranteed_amount}
      tagComponent={<LoanStateTag loanState={loan_state} />}
      detailsContent={<TwoColumnsDetails details={detailsArray} />}
    />
  );
};
