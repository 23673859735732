import { attr } from 'spraypaint';

import Base, { type BaseModel } from './Base';

type PaymentSettingsT = {
  prepayment_settings: {
    futurePayments_acceptance: 'ACCEPT_OVERPAYMENTS',
    prepayment_acceptance: 'ACCEPT_PREPAYMENTS' | 'NO_PREPAYMENTS'
  }
};

export const LoanProductV2 = Base.extend({
  static: {
    jsonapiType: 'loan_products_v2'
  },
  attrs: {
    paymentSettings: attr(),
    isBridgingProduct: attr(),
    accountLinkingEnabled: attr(),
    linkableSavingsProductId: attr()
  }
});

export interface LoanProductV2Type extends BaseModel<LoanProductV2Type> {
  paymentSettings: PaymentSettingsT;
  isBridgingProduct: boolean;
  accountLinkingEnabled: boolean;
  linkableSavingsProductId: string;
}
