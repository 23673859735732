import * as React from 'react';
import map from 'lodash/map';

import { DocumentListBase, DocumentListPropTypes } from '@kwara/components/src/DocumentListBase';

import { DocumentItem } from './DocumentItem';

export function DocumentList({ size = 'default', ...restProps }: DocumentListPropTypes) {
  return (
    <DocumentListBase {...restProps} size={size}>
      {({ attachments, setShownAttachment }) => (
        <div>
          {map(attachments, attachment => (
            <DocumentItem
              key={attachment.id}
              size={size}
              attachment={attachment}
              setShownAttachment={setShownAttachment}
            />
          ))}
        </div>
      )}
    </DocumentListBase>
  );
}
