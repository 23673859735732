// @flow

import pipe from 'lodash/fp/pipe';
import split from 'lodash/fp/split';
import last from 'lodash/fp/last';
import nth from 'lodash/fp/nth';
import isNaN from 'lodash/fp/isNaN';
import size from 'lodash/fp/size';
import gt from 'lodash/fp/gt';
import __ from 'lodash/fp/__';
import { parsePhoneNumber } from 'libphonenumber-js';

import { isEmpty } from '@kwara/lib/src/lodash';
import { isFuture, startOfDay } from '@kwara/lib/src/dates';

import { containsComma, containsPlusSign, containsLetters } from './validators';

import { ERRORS, VALID, DEFAULT_MAX_CURRENCY, type ValidationResultT } from '.';

export const isNotFuture = (record: Date) => {
  if (isFuture(startOfDay(record))) {
    return ERRORS.future;
  }

  return VALID;
};

export const validNumber = (record: ?string) => {
  if (isEmpty(record)) {
    return VALID;
  }
  if (containsLetters(record)) {
    return ERRORS.notANumber;
  }

  if (containsComma(record)) {
    return ERRORS.noComma;
  }

  if (containsPlusSign(record)) {
    return ERRORS.noPlusSign;
  }

  if (Number(record) > DEFAULT_MAX_CURRENCY) {
    return ERRORS.rangeOverflow;
  }

  if (isNaN(Number(record))) {
    return ERRORS.notANumber;
  }

  if (last(record) === '.') {
    return ERRORS.endsWithDecimal;
  }

  return VALID;
};

export const max = (validationValue, record) => {
  if (validNumber(record) !== VALID) {
    return validNumber(record);
  }

  if (Number(record) > validationValue) {
    return ERRORS.rangeOverflow;
  }

  return VALID;
};

export const min = (validationValue, record) => {
  if (validNumber(record) !== VALID) {
    return validNumber(record);
  }

  if (Number(record) < validationValue) {
    return ERRORS.rangeUnderflow;
  }

  return VALID;
};

// The currency validation only allows *zero or positive* input
// in the correct format since currently all the forms only
// accept positive data. If there comes a time we need to allow
// non-positive currency input, we should create a separate record
// i.e. `negativeCurrency` with the appropriate checks
export const currency = (record: string): ValidationResultT => {
  if (validNumber(record) !== VALID) {
    return validNumber(record);
  }

  if (Number(record) < 0) {
    return ERRORS.isNegative;
  }

  const over2decimals = pipe(split('.'), nth(1), size, gt(__, 2))(record);

  if (over2decimals) {
    return ERRORS.decimals;
  }

  return VALID;
};

export const percentage = (record: string): ValidationResultT => {
  if (validNumber(record) !== VALID) {
    return validNumber(record);
  }

  if (Number(record) < 0) {
    return ERRORS.isNegative;
  }

  return VALID;
};

export const nonZero = (record: string) => {
  if (validNumber(record) !== VALID) {
    return validNumber(record);
  }

  if (Number(record) === 0) {
    return ERRORS.isZero;
  }

  return VALID;
};

export const positiveInteger = (record: string) => {
  if (validNumber(record) !== VALID) {
    return validNumber(record);
  }

  if (Number(record) % 1 !== 0) {
    return ERRORS.notPositiveInteger;
  }

  if (Number(record) <= 0) {
    return ERRORS.notPositiveInteger;
  }

  return VALID;
};

export const phoneNumberErrors = Object.freeze({
  // A generic fallback error
  invalid: 'invalidPhone',

  // NOTE: the following strings for each code are the ones libphonenumber returns
  // hence should not be be changed.
  // https://github.com/catamphetamine/libphonenumber-js#parsephonenumberstring-defaultcountry
  invalidCountry: 'INVALID_COUNTRY',
  notANumber: 'NOT_A_NUMBER',
  tooLong: 'TOO_LONG',
  tooShort: 'TOO_SHORT'
});
type PhoneNumberValidation = $Values<typeof phoneNumberErrors> | 'VALID';
export const phoneNumber = (record: string): PhoneNumberValidation => {
  // Apparently libphonenumber is not treating letters as invalid, so we have to test ourselves.
  // As a first quick check, allow only:
  // 1. numbers in any position
  // 2. "+" if in first position
  // 3. using "." or "-" as separator (but not if 2 of them are consecutive)
  // See [ch6364]
  const onlyValidChars = /^(\d|\+)(\d|\.\d|-\d)+$/;
  if (!onlyValidChars.test(record)) {
    return phoneNumberErrors.notANumber;
  }

  try {
    const num = parsePhoneNumber(record);
    if (num.isValid()) {
      return VALID;
    }

    // parsePhoneNumber throws an error in case it's invalid, so this should in theory never be
    // returned, but we provide a fallback just in case anything changes in the lib.
    // Better safe than sorry.
    return phoneNumberErrors.invalid;
  } catch (error) {
    // Errors are thwrown in the form of "ERROR: NOT_A_NUMBER", so we strip the "ERROR: " part
    // so we can append it to the translation strings in a more readable way.
    // AddMember.Contact.Number.error.NOT_A_NUMBER
    // instead of
    // AddMember.Contact.Number.error.ERROR:NOT_A_NUMBER
    return pipe(split(' '), last)(error);
  }
};
