import * as React from 'react';

import { Text, Percent } from '@kwara/components/src/Intl';

import { Container } from '../../layouts';

import iconLoaderBg from './iconLoaderBg.png';
import styles from './index.module.scss';

type ProgressiveLoaderElement = React.ElementRef<'div'>;
interface ProgressiveLoaderPropTypes extends React.ComponentPropsWithRef<'div'> {
  percent: number;
  infoId: string;
}

export const ProgressiveLoader = React.forwardRef<ProgressiveLoaderElement, ProgressiveLoaderPropTypes>(
  function ProgressiveLoader(props, forwardedRef) {
    return (
      <Container className={styles['container']}>
        <p>
          <Text id={props.infoId} />
        </p>

        <img alt="" src={iconLoaderBg} />

        <div className={styles['loader-wrapper']}>
          <div ref={forwardedRef} className={styles['loader']}></div>

          <Percent value={props.percent / 100} />
        </div>
      </Container>
    );
  }
);
