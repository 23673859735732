// @flow

import { attr } from 'spraypaint';

import Base, { type BaseModel } from './Base';

export const PhoneNumberVerification = Base.extend({
  static: {
    jsonapiType: 'phone_number_verifications',
    endpoint: '/phone_number_verification'
  },
  attrs: {
    phoneNumber: attr()
  }
});

export interface PhoneNumberVerificationT extends BaseModel<PhoneNumberVerificationT> {
  phoneNumber: string;
}
