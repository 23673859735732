import format from 'date-fns/format';
import minExternal from 'date-fns/min';
import endOfYear from 'date-fns/end_of_year';
import subYearsExternal from 'date-fns/sub_years';
import getDateExternal from 'date-fns/get_date';
import isAfterExternal from 'date-fns/is_after';
import getMonthExternal from 'date-fns/get_month';
import getYearExternal from 'date-fns/get_year';
import setMonthExternal from 'date-fns/set_month';
import setDateExternal from 'date-fns/set_date';
import addDaysExternal from 'date-fns/add_days';
import subDaysExternal from 'date-fns/sub_days';
import startOfYear from 'date-fns/start_of_year';
import isFutureExternal from 'date-fns/is_future';
import subMonthsExternal from 'date-fns/sub_months';
import addMonthsExternal from 'date-fns/add_months';
import startOfDayExternal from 'date-fns/start_of_day';
import startOfTomorrow from 'date-fns/start_of_tomorrow';
import startOfYesterday from 'date-fns/start_of_yesterday';
import differenceInDaysExternal from 'date-fns/difference_in_days';
import distanceInWordsExternal from 'date-fns/distance_in_words_strict';
import { DateTime, Duration, DurationLikeObject } from 'luxon';

export type MaybeDate = Date | DateTime | string | number;

const HUMAN_FORMAT = 'DD/MM/YYYY';
export const ISO_FORMAT = 'YYYY-MM-DD';

export const TIME = 'HH:mm:ss';
export const isAfter = isAfterExternal;
export const subYears = subYearsExternal;
export const subDays = subDaysExternal;
export const isFuture = isFutureExternal;
export const tomorrow = startOfTomorrow;
export const yesterday = startOfYesterday;
export const startOfDay = startOfDayExternal;
export const addMonths = addMonthsExternal;
export const subMonths = subMonthsExternal;
export const addDays = addDaysExternal;
export const setMonth = setMonthExternal;
export const setDate = setDateExternal;
export const min = minExternal;
export const getDayOfMonth = getDateExternal;
export const getMonth = getMonthExternal;

export function isValidDateObject(date: Date) {
  return !isNaN(date.valueOf());
}

function isValid(maybe: MaybeDate) {
  let date = maybe;

  if (['number', 'string'].includes(typeof date)) {
    date = new Date(date);
  }

  if (date instanceof Date && isValidDateObject(date)) return true;

  return false;
}

export function differenceInDays(now: MaybeDate, past: MaybeDate) {
  return differenceInDaysExternal(past, now) || null;
}

export function distanceInWords(now: MaybeDate, past: MaybeDate) {
  if (isValid(now) && isValid(past)) return distanceInWordsExternal(now, past);

  return null;
}

export function formatIsoDate(date: MaybeDate): string {
  if (date instanceof Date && isValidDateObject(date)) {
    return format(date, ISO_FORMAT);
  } else {
    throw new Error(`Cannot format date: ${String(date)}`);
  }
}

export function formatHumanDate(maybeDate: MaybeDate, pattern: string = HUMAN_FORMAT): string | null {
  const date = parse(maybeDate);

  if (date != null) {
    return format(date, pattern);
  }

  return null;
}

export function formatHumanTime(maybeDate: MaybeDate): string | null {
  return formatHumanDate(maybeDate, TIME);
}

/**
 * @parse
 * 
 * Tries to parse some form of date into a JS Date, returning
 * null if it's invalid

 * Tries: number (assumes ms), string (assumes ISO) and also
 * accepts instance of JS Date
 */
export function parse(maybeDate: MaybeDate): Date | null {
  if (maybeDate == null) return null;

  let date = maybeDate;

  if (typeof date === 'string') {
    date = DateTime.fromISO(date);
  }

  if (typeof date === 'number') {
    date = DateTime.fromMillis(date);
  }

  if (date instanceof Date) {
    date = DateTime.fromJSDate(date);
  }

  if (date instanceof DateTime) {
    return (date as DateTime).isValid ? (date as DateTime).toJSDate() : null;
  }

  return null;
}

export function getYear(maybeDate: MaybeDate): number | null {
  let date = maybeDate;

  if (typeof date === 'string') {
    date = new Date(date);
  }

  if (date instanceof Date && isValidDateObject(date)) return getYearExternal(date);

  return null;
}

export function toDuration(obj: DurationLikeObject): string | null {
  let res;

  try {
    res = Duration.fromObject(obj).toString();
  } catch (_) {
    res = undefined;
  }
  return res;
}

export function parseDuration(duration: string) {
  return Duration.fromISO(duration).toObject();
}

export function getCurrentYear() {
  return new Date().getFullYear();
}

export function getCurrentDate() {
  return new Date();
}

export function getCurrentDateFormatted() {
  return formatHumanDate(getCurrentDate());
}

export function getStartOfLastYear() {
  return formatHumanDate(startOfYear(subYears(getCurrentDate(), 1)));
}

export function getEndOfLastYear() {
  return formatHumanDate(endOfYear(subYears(getCurrentDate(), 1)));
}

export function getMonthInWord(date: MaybeDate, abbrMonth?: boolean): string | null {
  const month = formatHumanDate(date, 'MMM');

  if (month != null) return abbrMonth ? month.charAt(0) : month;

  return month;
}
