import split from 'lodash/fp/split';
import size from 'lodash/fp/size';
import first from 'lodash/fp/first';
import join from 'lodash/fp/join';

import { appName } from '@kwara/lib/src/utils';

export const getTranslationIdByApp = id => {
  if (appName.isSacco) {
    return id;
  }

  const all = split('.', id);
  const prefix = first(all);
  const rest = all.slice(1);
  if (size(rest) === 0) {
    return join('.', [prefix, 'Connect']);
  }
  return join('.', [prefix, 'Connect', join('.', rest)]);
};
