//@flow
import React, { useCallback } from 'react';
import { createContext } from '@kwara/lib/src/utils/createContext';

type ModalContextType = {
  modal: React.Node
};

type ModalProviderProps = {
  setModal: React.Dispatch<React.SetStateAction<React.ReactNode>>,
  children: React.ReactElement
};

const [ModalContextProvider, useModalContext] = createContext<ModalContextType>('ModalContext');

const ModalProvider = ({ setModal, children }: ModalProviderProps) => {
  const removeModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  return <ModalContextProvider value={{ setModal, removeModal }}>{children}</ModalContextProvider>;
};

export { useModalContext, ModalProvider };
