// @flow
import * as React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import map from 'lodash/map';

import { NotFound404 } from '@kwara/components/src/NotFound404';
import WizardProgress from '@kwara/components/src/Wizard/deprecated/WizardProgress';
import { PermissionDenied } from '@kwara/components/src/PermissionDenied';

import styles from './index.module.scss';

import { type ActionEvent, type StepConfig, type StepId, type RenderableStep } from './Wizard';
import SubStep from './SubStep';
import stepParser from './stepParser';

type Props<Data> = {
  currentStep: ?string,
  currentSubStep: ?number,
  currentState: Data,
  error: any,
  isProcessing?: boolean,
  parentUrl: string,
  onAction: (event: ActionEvent<Data>) => void,
  onChange: (data: Data) => Promise<void>,
  steps: StepConfig,
  startId: StepId,
  setError: (err: typeof Error | null) => void
};

const pathFor = (baseUrl, path) => `${baseUrl}/${path}`;

const RedirectWithQueryString = withRouter(({ to, location, ...rest }) => (
  <Redirect {...rest} to={{ ...location, pathname: to }} />
));

export default ({
  currentStep,
  currentSubStep,
  currentState,
  error,
  setError,
  isProcessing,
  parentUrl,
  onAction,
  onChange,
  steps,
  startId
}: Props<*>) => {
  const { subSteps, ordered, firstSubStepId } = stepParser({ steps, startId });

  const redirect = firstSubStepId ? (
    <RedirectWithQueryString exact from={parentUrl} to={pathFor(parentUrl, firstSubStepId)} />
  ) : null;

  return (
    <Switch>
      {redirect}
      {map(subSteps, (subStep: RenderableStep, id: StepId) => {
        const stepPath = pathFor(parentUrl, id);
        return (
          <Route
            key={stepPath}
            path={stepPath}
            render={() =>
              subStep.isPermitted !== false ? (
                <>
                  {subStep.progress === false ? null : (
                    <WizardProgress
                      currentStep={currentStep}
                      currentSubStep={currentSubStep}
                      ordered={ordered}
                      subSteps={subSteps}
                      parentUrl={parentUrl}
                    />
                  )}
                  <SubStep
                    className={styles.WizardSubstep}
                    currentState={currentState}
                    error={error}
                    setError={setError}
                    isProcessing={isProcessing}
                    onAction={onAction}
                    onChange={onChange}
                    subStep={subStep}
                    parentUrl={parentUrl}
                    fullPage
                  />
                </>
              ) : (
                <PermissionDenied />
              )
            }
          />
        );
      })}
      <Route path="/" component={NotFound404} />
    </Switch>
  );
};
