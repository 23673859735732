import * as React from 'react';

import Asset from '@kwara/components/src/Asset';

import { Text } from '@kwara/components/src/Intl';

export const Loading = <ErrorTypes extends any>({ error }: { error?: ErrorTypes }) => (
  <div className="w-100 h-100 flex justify-center items-center">
    <span className="">
      <Text id={error == null ? 'Loading.loading' : 'Loading.error'} />
    </span>
  </div>
);

export const SpinningLoading = ({ color = 'indigo500' }: { color?: string }) => {
  return (
    <div className="w-100 h-100 flex justify-center items-center">
      <Asset id="Spinner" col={color} />
    </div>
  );
};

export default Loading;
