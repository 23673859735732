// @flow
import * as React from 'react';
import cx from 'classnames';

import { Text, type TranslationId } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src';
import { appName } from '@kwara/lib/src/utils';
import Asset from '@kwara/components/src/Asset';

import { WizardProgressMobile } from '../WizardProgressMobile';
import styles from './index.module.scss';

type Props = {
  onCancel: () => void,
  titleId: TranslationId,
  values?: { [string]: any }
};

export const WizardHeader = props =>
  appName.isMember ? <WizardHeaderMobile {...props} /> : <WizardHeaderCore {...props} />;

const WizardHeaderMobile = ({ titleId, onCancel, values, currentStep, steps }: Props) => (
  <>
    <WizardProgressMobile steps={steps} currentStep={currentStep} />
    <header className={`mt2 h3 w-100 ph3 flex items-center bb b--light-grey-500 justify-between ${styles.container}`}>
      <div>
        <h3 data-testid="wizard-title-id" className="kw-weight-bold">
          <Text id={titleId} values={values} />
        </h3>
      </div>

      <div className={styles['mobile-container-col2']}>
        <Link
          onClick={() => {
            if (window.fcWidget) window.fcWidget.open();
          }}
          aria-label="Chat for help"
        >
          <Asset id={Asset.Glyphs.Help} col={Asset.Colours.black} />
        </Link>

        <Link onClick={onCancel} aria-label="Wizard Cancel">
          <Asset id={Asset.Glyphs.CrossCircle} col={Asset.Colours.black} />
        </Link>
      </div>
    </header>
  </>
);

const WizardHeaderCore = ({ titleId, onCancel, values, currentStep, steps }: Props) => (
  <header className={`mt2 h3 w-100 ph4 flex items-center bb b--light-grey-500 ${styles.container}`}>
    <div>
      <h3 data-testid="wizard-title-id" className="kw-weight-bold mv1">
        <Text id={titleId} values={values} />
      </h3>
      <div className={cx(styles.mobileProgress, 'grey-400 kw-text-small')}>
        <Text id="Wizard.steps" values={{ currentStep, steps }} />
      </div>
    </div>
    <div className="ml-auto">
      <Link onClick={onCancel} aria-label="Wizard Cancel">
        <Text id="WizardActions.cancel" />
      </Link>
    </div>
  </header>
);
