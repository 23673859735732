import { blobHeaders } from '@kwara/lib/src/fileDownload';
import Base from './Base';

export const ChequeTransactions = Base.extend({
  static: {
    jsonapiType: 'cheque_transactions',

    download(filterData) {
      const url = `${ChequeTransactions.url()}.pdf`;
      const { cheque_number } = filterData;
      const fileName = `cheque_transactions_${cheque_number}`;

      const opts = ChequeTransactions.fetchOptions();
      const options = {
        params: {
          ...filterData
        },
        ...blobHeaders(opts)
      };

      return Base.downloadFileFromUrl(url, options, fileName);
    }
  }
});
