import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useBoolean } from '@kwara/lib/src/hooks/useBoolean';

import { IcnLoans } from '../assets/IcnLoans.svg';
import { IcnRepay } from '../assets/IcnRepay.svg';
import { IcnSwitch } from '../assets/IcnSwitch.svg';
import { IcnDeposits } from '../assets/IconDeposits.svg';
import { IcnStatement } from '../assets/IcnStatement.svg';

export type ActionType = {
  name: string;
  labelId: string;
  onClick(): void;
  icon(): JSX.Element;
};

/**
 * @repositionArray repositions an array by moving the element at the
 * current day index to the beginning and adding a lower bound action at the beginning.
 * @param {ActionType} lowerBoundAction - The `lowerBoundAction` parameter is of type `ActionType`.
 * @param array - An array of ActionType objects.
 * @returns the modified array.
 * @note this modifies the original array
 */
function repositionArray(lowerBoundAction: ActionType, array: Array<ActionType>) {
  const today = new Date().getDay();

  if (today === 0) return array;

  const [element] = array.splice(Math.min(today - 1, array.length - 1), 1);
  array.unshift(element);
  array.unshift(lowerBoundAction);
  return array;
}

export function useQuickAction(shownSwitchSacco: boolean) {
  const history = useHistory();
  const [shownSaccos, { toggle }] = useBoolean();
  const actions = useMemo<Array<ActionType>>(() => {
    const QUICK_ACTIONS = [
      {
        labelId: 'QuickActionMenu.AddDeposit',
        name: 'deposit',
        icon: IcnDeposits,
        onClick: () => history.push('/savings/select')
      },
      {
        labelId: 'QuickActionMenu.InstantLoan',
        name: 'loans',
        icon: IcnLoans,
        onClick: () => history.push('/instantLoans/select')
      },
      {
        labelId: 'QuickActionMenu.RepayLoan',
        name: 'repay',
        icon: IcnRepay,
        onClick: () => history.push('/loans/repay')
      },
      {
        labelId: 'QuickActionMenu.ViewStatements',
        name: 'statement',
        icon: IcnStatement,
        onClick: () => history.push('/statement')
      }
    ];

    const SWITCH_SACCO_ACTION = {
      labelId: 'QuickActionMenu.SwitchSaccos',
      name: 'switch saccos',
      icon: IcnSwitch,
      onClick: toggle
    };

    if (shownSwitchSacco) return repositionArray(SWITCH_SACCO_ACTION, QUICK_ACTIONS);

    return QUICK_ACTIONS;
  }, [history, shownSwitchSacco, toggle]);

  return { shownSaccos, actions, toggle };
}
