import * as React from 'react';
import { appName } from '@kwara/lib/src/utils';

import { SelectBase } from '.';
import stylesMobile from './SelectMobile.module.scss';
import stylesCore from './SelectCore.module.scss';

export const Select = props => (appName.isMember ? <SelectMobile {...props} /> : <SelectCore {...props} />);

const SelectCore = props => {
  const { size } = props;

  return <SelectBase {...props} styles={stylesCore} className={`kw-text-${size}`} />;
};
const SelectMobile = props => {
  return <SelectBase {...props} styles={stylesMobile} size={'small'} className="mobile-text-medium" />;
};

export const Option = SelectBase.OptionBase;

Select.Option = Option;
