import { attr } from 'spraypaint';
import Base, { BaseModel } from '../Base';

export const Indicators = Base.extend({
  static: {
    jsonapiType: 'indicators',
    endpoint: '/dashboard/indicators'
  },
  attrs: {
    totalClients: attr(),
    totalActiveClients: attr(),
    totalActiveClientsPercentage: attr(),
    totalDeposits: attr(),
    grossLoanPortfolio: attr(),
    portfolioValueAtRisk: attr(),
    portfolioPercentAtRisk: attr()
  }
});

export interface IndicatorsT extends BaseModel<IndicatorsT> {
  totalClients: string;
  totalActiveClients: string;
  totalActiveClientsPercentage: string;
  totalDeposits: string;
  grossLoanPortfolio: string;
  portfolioValueAtRisk: string;
  portfolioPercentAtRisk: string;
}
