import React, { useState } from 'react';
import cx from 'classnames';
import { Redirect } from 'react-router-dom';

import Button from '@kwara/components/src/Button';

import { Text } from '@kwara/components/src/Intl';

import { useInstantPrompt } from '../../components/InstantPrompt';

import styles from './index.module.scss';

export const LogoutButton = ({ auth, className }) => {
  const [navigateToLanding, setNavigateToLanding] = useState(false);
  const { unmount } = useInstantPrompt();

  async function logOut() {
    await auth.logOutConnect();
    setNavigateToLanding(true);
    unmount();
    window.location.reload();
  }

  return (
    <div className={className}>
      {navigateToLanding && <Redirect to="/" />}

      <Button className={cx(styles.Button, 'ma0')} aria-label="Logout" onClick={logOut}>
        <Text id={'Login.logout'} />
      </Button>
    </div>
  );
};
