// @flow

import * as React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import styles from './index.module.css';
import logo from './logo.svg';

type LogoPropTypes = {
  className?: string,
  to?: string
};

const Logo = (props: LogoPropTypes) => (
  <div className={cx('flex items-center', props.className)}>
    <Link to={props.to ?? '/'}>
      <img alt="Kwara" className={styles.logo} src={logo} />
    </Link>
  </div>
);

export default Logo;
