import { useEffect } from 'react';

export function useBodyLocker() {
  useEffect(function lockBodyElementFromScrolling() {
    const bodyRef = document.getElementById('webappSaccoBodyEl');

    if (bodyRef && bodyRef.style) {
      bodyRef.style.overflow = 'hidden';
    }

    return function resetBodyElementOverflow() {
      if (bodyRef && bodyRef.style) {
        bodyRef.style.overflow = 'scroll';
      }
    };
  }, []);
}
