import React from 'react';
import cx from 'classnames';
import ReactModal from 'react-modal';

import zIndices from '@kwara/lib/src/zIndices';
import { isTest as __TEST__ } from '@kwara/lib/src/utils';

import styles from './index.module.scss';

interface BaseModalPropTypes {
  isOpen: boolean;
  children: React.ReactNode;
  ariaLabel?: string;
  className?: string;
  overlayClassName?: String;
  [key: string]: any;
}

export default function Modal(props: BaseModalPropTypes) {
  const { isOpen, children, ariaLabel, className, overlayClassName, ...restProps } = props;

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return function resetDOM() {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return (
    <ReactModal
      aria-modal="true"
      {...restProps}
      isOpen={isOpen}
      ariaHideApp={!__TEST__}
      shouldCloseOnEsc={false}
      aria={ariaLabel ? { label: ariaLabel } : {}}
      {...(!__TEST__ ? { appElement: document.getElementById('root') } : {})}
      className={`${styles.Modal} flex flex-column items-center ${className ?? ''}`}
      overlayClassName={cx(zIndices.Modal, styles.ModalOverlay, styles.ModalBackgroundCore, overlayClassName)}
    >
      {children}
    </ReactModal>
  );
}
