// @flow

import * as React from 'react';

import Empty from '@kwara/components/src/Empty';
import { Currency, Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';

import { useStore } from '../../models/Member';
import Account from '../../components/Account';
import InfoList from '../../components/InfoList';
import Section from '../../components/Section';
import { isAddMoneyButtonAvailable } from '../../lib/helpers';

export const SavingsList = ({ accounts }) => {
  const hasAccounts = accounts && accounts.length > 0;
  const store = useStore();

  return (
    <Section titleId="Savings.title" values={{ orgCurrency: store.currency }}>
      {hasAccounts ? (
        accounts.map(account => {
          return (
            <Account
              key={account.id}
              id={account.id}
              name={account.product.name}
              value={account.balance}
              type={Account.DisplayTypes.credit}
              status={account.state.current}
            >
              <InfoList>
                <InfoList.Item label={<Text id="Savings.id" />} value={account.id} ariaLabel={'Saving ID'} />
                <InfoList.Item
                  label={<Text id="Savings.accruedInterest" />}
                  value={<Currency format="currency" value={account.accruedInterest} />}
                />
              </InfoList>
              <div className="flex justify-between">
                <Button
                  size="medium"
                  glyphLeftId={Button.Glyphs.Eye}
                  aria-label="view"
                  to={`/savings/${account.id}/overview`}
                >
                  <Text id="General.View" />
                </Button>
                {isAddMoneyButtonAvailable(account, store) ? (
                  <Button size="medium" aria-label="deposit" type="primary" to={`/overview/${account.id}/deposit`}>
                    <Text id="SavingDeposit.SavingsList.button.label" />
                  </Button>
                ) : null}
              </div>
            </Account>
          );
        })
      ) : (
        <Empty>
          <Text id="Savings.none" />
        </Empty>
      )}
    </Section>
  );
};
