import isEmpty from 'lodash/fp/isEmpty';

import { useReducer } from 'react';

import { PotentialGuarantorType } from '@kwara/models/src/models/Guarantee';

import { TYPES } from './types';
import { screenStatusReducer, STATE_VALUES } from './reducer';

export function useScreenStatusReducer(guarantors: Array<PotentialGuarantorType>) {
  const [state, dispatch] = useReducer(
    screenStatusReducer,
    isEmpty(guarantors) ? STATE_VALUES.initial : STATE_VALUES.guarantorsList
  );

  function onFindGuarantor() {
    dispatch({
      type: TYPES.ON_FIND_GUARANTOR,
      payload: { guarantors }
    });
  }

  function onAddFirstGuarantor() {
    dispatch({
      type: TYPES.ON_ADD_FIRST_GUARANTOR,
      payload: { guarantors }
    });
  }

  function onEditGuarantor() {
    dispatch({
      type: TYPES.ON_EDIT_GUARANTOR,
      payload: { guarantors }
    });
  }

  function onRemoveGuarantor() {
    dispatch({
      type: TYPES.ON_REMOVE_GUARANTOR,
      payload: { guarantors }
    });
  }

  function onSaveGuarantor() {
    dispatch({
      type: TYPES.ON_SAVE_GUARANTOR,
      payload: { guarantors }
    });
  }

  function onFindGuarantorError() {
    dispatch({
      type: TYPES.ON_FIND_GUARANTOR_ERROR,
      payload: { guarantors }
    });
  }

  function onFindMoreGuarantors() {
    dispatch({
      type: TYPES.ON_FIND_MORE_GUARANTORS,
      payload: { guarantors }
    });
  }

  return {
    state,
    onFindMoreGuarantors,
    onFindGuarantor,
    onAddFirstGuarantor,
    onEditGuarantor,
    onRemoveGuarantor,
    onSaveGuarantor,
    onFindGuarantorError
  };
}
