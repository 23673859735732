// @flow
import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { Text, type TranslationId } from '@kwara/components/src/Intl';

import styles from './index.module.scss';

export type Props = {
  cancelId?: TranslationId,
  confirmId?: TranslationId,
  onCancel: () => void,
  onConfirm?: React.MouseEvent<HTMLButtonElement>,
  disabled: boolean,
  hideConfirm?: boolean
};

export const Actions = ({ cancelId, confirmId, onCancel, onConfirm, disabled, hideConfirm = false }: Props) => (
  <div className={`flex justify-center ${styles.Actions}`}>
    <Button className="mr2" onClick={onCancel} type="secondary" size="medium">
      <Text id={cancelId} />
    </Button>
    {hideConfirm ? null : (
      <Button disabled={disabled} onClick={onConfirm} type="primary" size="medium">
        <Text id={confirmId} />
      </Button>
    )}
  </div>
);

Actions.defaultProps = {
  cancelId: 'ActionModal.cancel',
  confirmId: 'ActionModal.confirm'
};
