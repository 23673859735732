import { AxiosError as AxiosBaseError } from 'axios';

import { ModelErrors } from 'GlobalTypes';

import createModelErrors, {
  createErrorsFromApiResponse,
  JsonApiErrorPayload
} from '@kwara/models/src/models/createModelErrors';

enum OUR_NETWORK_REQUEST_APIS {
  AXIOS = 'axios',
  /**
   * @FETCH
   * fetch is for legacy coverage of our
   * program using fetch. We encourage you
   *'to use the custom HttpService(built on-top of axios)
   * when making a new network request
   */
  FETCH = 'fetch',

  /**
   * @CUSTOM
   * custom means the error is already composed properly to
   * the shape that `createErrorsFromApiResponse` understands,
   * thereby it doesn't need to undergo either of the
   * services(fetch|axios) register to access the error
   */
  CUSTOM = 'custom'
}

export type FetchError = {
  response: {
    json(): Promise<JsonApiErrorPayload>;
  };
};

export type AxiosError = AxiosBaseError<JsonApiErrorPayload>;

type ApiType = 'axios' | 'fetch' | 'custom';
type ErrorType = AxiosError | FetchError | Error | JsonApiErrorPayload | null | undefined;
type GenerateErrorReturnType<T extends ApiType> = T extends 'fetch' ? Promise<ModelErrors> : ModelErrors;

class HttpErrorHandler {
  private async fetchApiErrorGenerator(error: FetchError | Error, fallbackErrorName: string) {
    const errorResponse = await (error as FetchError)?.response?.json?.();
    const itsServerError = !!errorResponse;

    if (itsServerError) return createErrorsFromApiResponse(errorResponse);
    return createModelErrors({ base: fallbackErrorName });
  }

  private axiosApiErrorGenerator(error: AxiosError | Error, fallbackErrorName: string) {
    const errorResponse = (error as AxiosError)?.response?.data;
    const itsServerError = !!errorResponse;

    if (itsServerError) return createErrorsFromApiResponse(errorResponse);
    return createModelErrors({ base: fallbackErrorName });
  }

  private customErrorGenerator(error: JsonApiErrorPayload | null | undefined, fallbackErrorName: string) {
    return error?.errors ? createErrorsFromApiResponse(error) : createModelErrors({ base: fallbackErrorName });
  }

  /**
   * @generateError
   *
   * @param error
   * @param api because our httpService uses axios
   * as the primary httpNetworkAPI, we will make "axios"
   * as the default value for the "api" parameter but
   * if you tend to use this with legacy fetchApi,
   * then thats the only time you need to explicitly
   * pass in the api argument. @note when its default,
   * it means the error is already composed properly to
   * the shape that `createErrorsFromApiResponse` understands
   * @param fallbackErrorName
   * @returns {ModelErrors | Promise<ModelErrors>}
   */
  public generateError<T extends ApiType = 'axios'>(
    error: ErrorType,
    api: T = 'axios' as T,
    fallbackErrorName = 'APP_NETWORK_ERROR'
  ): GenerateErrorReturnType<T> {
    if (!error) return createModelErrors({ base: fallbackErrorName });

    if (api == OUR_NETWORK_REQUEST_APIS.AXIOS) {
      return this.axiosApiErrorGenerator(error as AxiosError, fallbackErrorName);
    }

    if (api === OUR_NETWORK_REQUEST_APIS.FETCH) {
      return this.fetchApiErrorGenerator(error as FetchError, fallbackErrorName) as GenerateErrorReturnType<T>;
    }

    return this.customErrorGenerator(error as JsonApiErrorPayload, fallbackErrorName);
  }
}

export const httpErrorHandler = new HttpErrorHandler();
