import { attr, hasOne } from 'spraypaint';
import join from 'lodash/fp/join';

import { add } from '@kwara/lib/src/currency';

import Base, { type BaseModel } from './Base';
import { BankAccount, type BankAccountT } from './BankAccount';

export const DirectDebit = Base.extend({
  static: {
    jsonapiType: 'direct_debits'
  },
  attrs: {
    firstName: attr(),
    lastName: attr(),
    memberNo: attr(),
    amount: attr(),
    fee: attr(),
    dueDate: attr(),
    originatingReferencePrefix: attr(),
    bankAccount: hasOne({ type: BankAccount })
  },
  methods: {
    bankBranchCode() {
      return join('', [this.bankAccount.bankBranch.bank.code, this.bankAccount.bankBranch.code]);
    },

    amountPlusFee() {
      return add(this.amount, this.fee);
    },
    fullName() {
      return join(' ', [this.firstName, this.lastName]);
    },
    originatingReference() {
      return join('', [this.originatingReferencePrefix, this.memberNo]);
    }
  }
});

export interface DirectDebitT extends BaseModel<DirectDebitT> {
  firstName: string;
  lastName: string;
  memberNo: string;
  amount: number;
  fee: number;
  originatingReferencePrefix: string;
  bankAccount: BankAccountT;
}
