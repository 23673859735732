import * as React from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

import { RecommendedOrganisationData } from 'RecommendedOrganisationTypes';

import Button from '@kwara/components/src/Button';

import { If } from '@kwara/components/src/If/If';
import { Link } from '@kwara/components/src/Link';
import { segmentText } from '@kwara/lib/src/segmentText';
import { Accordion } from '@kwara/components/src/Accordion/Accordion';
import { Text, Currency, Duration, Percent } from '@kwara/components/src/Intl';

import { ImageLi } from '../../../components/ImageLi';

import iconWhiteKwaraLogo from '../../../assets/iconWhiteKwaraLogo.png';
import styles from './saccoDetailsCard.module.scss';

const sharedAccordionProps = {
  closeOnOutClick: false,
  className: 'mb3',
  fill: '#fff',
  headerClassName: cx(styles['sdc-accordion__header'], 'kw-text-medium'),
  bodyClassName: cx('mobile-text-small', styles['sdc-accordion__body'])
};

export type SaccoDetailsCardPropTypes = RecommendedOrganisationData & {
  onClickHandler(onNavigate: () => void): void;
};

export function SaccoDetailsCard(props: SaccoDetailsCardPropTypes) {
  const {
    onClickHandler,
    name,
    logoUrl,
    fuzzyMemberCount,
    joiningFee,
    about,
    memberRequirements,
    savingsInfo,
    loanInfo
  } = props;

  const history = useHistory();

  const aboutList = segmentText('\r'.concat(about?.description || '').replaceAll('\r\n', '\r'), '\n', '\r');
  const repaymentPeriodUnit = loanInfo?.repaymentPeriodUnit;

  function onJoinSacco() {
    onClickHandler(() => history.push('/members/create/about/1'));
  }

  return (
    <div className={styles['container']}>
      <div className={styles['row-1']}>
        <div className={styles['row-1__org-brand']}>
          <img src={logoUrl ?? iconWhiteKwaraLogo} alt="Logo" />
          <p>{name}</p>
        </div>

        <p className="mobile-text-small kw-weight-regular">
          <Text id="SaccoRecommendationFlow.SuggestedSaccoDetails.MemberBase.label" />
        </p>

        <p className="mobile-text-small kw-weight-bold">
          <Text
            id="SaccoRecommendationFlow.SuggestedSaccoDetails.MemberBase.value"
            values={{ membersCount: fuzzyMemberCount }}
          />
        </p>
      </div>

      <div className={styles['row-2']}>
        <Accordion
          {...sharedAccordionProps}
          title={<Text id="SaccoRecommendationFlow.SuggestedSaccoDetails.MemberRequirements.label" />}
        >
          <ul>
            <ImageLi>
              <Text
                id="SaccoRecommendationFlow.SuggestedSaccoDetails.MemberRequirements.sharesDeposit"
                values={{
                  sharesDeposit: <Currency format="currency" value={memberRequirements?.requiredSharesDeposit} />
                }}
              />
            </ImageLi>
            <ImageLi>
              <Text
                id="SaccoRecommendationFlow.SuggestedSaccoDetails.MemberRequirements.monthlyContribution"
                values={{
                  monthlyContribution: (
                    <Currency format="currency" value={memberRequirements?.requiredMonthlyContribution} />
                  )
                }}
              />
            </ImageLi>
            <ImageLi>
              <Text
                id="SaccoRecommendationFlow.SuggestedSaccoDetails.MemberRequirements.accountOpeningFees"
                values={{ accountOpeningFees: <Currency format="currency" value={joiningFee} /> }}
              />
            </ImageLi>
          </ul>
        </Accordion>

        <Accordion
          {...sharedAccordionProps}
          title={<Text id="SaccoRecommendationFlow.SuggestedSaccoDetails.InterestRates.label" />}
        >
          <ul>
            <ImageLi>
              <Text
                id="SaccoRecommendationFlow.SuggestedSaccoDetails.InterestRates.savingsPlan"
                values={{
                  percentage: <Percent value={savingsInfo?.interestRate || 0} />
                }}
              />
            </ImageLi>
            <ImageLi>
              <Text
                id="SaccoRecommendationFlow.SuggestedSaccoDetails.InterestRates.loan"
                values={{ percentage: <Percent value={loanInfo?.interestRate || 0} /> }}
              />
            </ImageLi>
          </ul>
        </Accordion>

        <Accordion
          {...sharedAccordionProps}
          title={<Text id="SaccoRecommendationFlow.SuggestedSaccoDetails.LoanRepaymentPolicy.label" />}
        >
          <ul>
            <ImageLi>
              <Text
                id="SaccoRecommendationFlow.SuggestedSaccoDetails.LoanRepaymentPolicy.repaymentPeriod"
                values={{
                  months: !!repaymentPeriodUnit ? <Duration value={repaymentPeriodUnit} /> : '-'
                }}
              />
            </ImageLi>
            <ImageLi>
              <Text
                id="SaccoRecommendationFlow.SuggestedSaccoDetails.LoanRepaymentPolicy.interestRate"
                values={{ percentage: <Percent value={loanInfo?.interestRate || 0} /> }}
              />
            </ImageLi>
            <ImageLi>
              <Text
                id="SaccoRecommendationFlow.SuggestedSaccoDetails.LoanRepaymentPolicy.loanAccessPeriod"
                values={{ time: '48 hours' }}
              />
            </ImageLi>
          </ul>
        </Accordion>

        <Accordion
          {...sharedAccordionProps}
          title={<Text id="SaccoRecommendationFlow.SuggestedSaccoDetails.AboutSacco.label" values={{ name }} />}
        >
          <If
            condition={!!about?.description}
            do={aboutList.map(({ paragraph, points }, i) => (
              <React.Fragment key={i.toString()}>
                <If condition={!!paragraph} do={<p className="kw-text-medium">{paragraph}</p>} />
                <If
                  condition={!!points.length}
                  do={
                    <ul>
                      {points?.map((text, i) => (
                        <ImageLi key={i.toString()}>{text}</ImageLi>
                      ))}
                    </ul>
                  }
                />
              </React.Fragment>
            ))}
          />

          <If
            condition={!!about?.homepage}
            do={
              <ImageLi>
                <span>
                  <Text id="SaccoRecommendationFlow.SuggestedSaccoDetails.AboutSacco.website" />
                </span>
                <Link Component="a" type="primary" to={about?.homepage} target="_blank" rel="noreferrer" underline>
                  {about?.homepage}
                </Link>
              </ImageLi>
            }
          />
        </Accordion>
      </div>

      <div className={styles['row-3']}>
        <Button className={styles['join-sacco-btn']} onClick={onJoinSacco}>
          <Text id="SaccoRecommendationFlow.SuggestedSaccoDetails.joinSacco" />
        </Button>
      </div>
    </div>
  );
}
