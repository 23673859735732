import React from 'react';
import cx from 'classnames';

import { If } from '@kwara/components/src/If/If';
import { zIndices } from '@kwara/lib/src/zIndices';

import { Swipeable } from '../Swipeable';
import { QuickAction } from './QuickAction';
import { Memberships } from '../Memberships';
import { useQuickAction } from './hooks/useQuickAction';
import { useGetMemberships } from '../Memberships/useGetMemberships';

import styles from './index.module.scss';

export function QuickActionsMenu() {
  const { data } = useGetMemberships();
  const { actions, shownSaccos, toggle } = useQuickAction(data?.length > 1);

  return (
    <>
      <Swipeable slidesPerView={3.15}>
        {actions.map(action => (
          <QuickAction {...action} key={action.labelId} />
        ))}
      </Swipeable>
      <If
        condition={shownSaccos}
        do={<Memberships toggle={toggle} className={cx(styles['memberships'], zIndices.Modal)} />}
      />
    </>
  );
}
