// @flow

import * as React from 'react';
import classnames from 'classnames';

import { Currency, Duration, Percent, Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import { type LoanType } from '@kwara/models/src';

import { StatisticStack } from '../../components/StatisticStack';
import styles from './Metrics.module.scss';

export const Metrics = ({ loan }: { laon: LoanType }) => {
  const border = 'bt b--light-grey-400';

  return (
    <>
      <section className={classnames(styles.PrimarySection)}>
        <Statistic
          title={<Text id="LoanDetail.Overview.principalAmount" />}
          value={<Currency value={loan.amount} />}
          color="teal-600"
          size={'superLarge'}
          containerClassName={border}
        />
        <Statistic
          title={<Text id="LoanDetail.Overview.principalBalance" />}
          value={<Currency value={loan.principal.balance} />}
          color="red-600"
          size={'superLarge'}
          containerClassName={border}
        />
        <Statistic
          title={<Text id="LoanDetail.Overview.interestAmount" />}
          value={<Currency value={0} />} //@TODO schedule data is needed getInterestAmount()
          size={'superLarge'}
          containerClassName={border}
        />
        <Statistic
          title={<Text id="LoanDetail.Overview.interestBalance" />}
          value={<Currency value={loan.interest.balance} />}
          size={'superLarge'}
          containerClassName={border}
        />
      </section>
      <section className={classnames(styles.SecondarySection)}>
        <StatisticStack
          title={<Text id="LoanDetail.Overview.loanProduct" />}
          value={loan.product.name}
          size={'regular'}
          containerClassName={border}
        />
        <StatisticStack
          title={<Text id="LoanDetail.Overview.interestRate" />}
          value={<Percent value={loan.interestRatePercent()} />}
          size={'regular'}
          containerClassName={border}
        />
        <StatisticStack
          title={<Text id="LoanDetail.Overview.duration" />}
          value={<Duration value={loan.duration} />}
          size={'regular'}
          containerClassName={border}
        />
        <StatisticStack
          title={<Text id="LoanDetail.Overview.frequency" />}
          value={<Duration value={loan.repaymentFrequency} />}
          size={'regular'}
          containerClassName={border}
        />
      </section>
    </>
  );
};
