// @flow
import * as React from 'react';

import type { RouterHistory, LocationShape } from 'react-router-dom';
import Auth from '@kwara/models/src/lib/Auth';
import { segmentTrack } from '@kwara/components/src/Segment';

import { SecureFourPinUpdate } from '../SecureFourPinUpdate';
import { steps } from './config';

type Props = {
  baseUrl: string,
  cancelReturnsTo: () => void,
  match: {
    params: {
      step: string,
      subStep: string
    }
  },
  history: RouterHistory,
  location: LocationShape,
  auth: Auth
};

const submit = async (data, auth) => {
  const { pin1: four_digit_pin, phone } = data;
  try {
    await auth.migrateToFourDigitsPin({
      data: { attributes: { four_digit_pin } }
    });
    segmentTrack('4 pin migration completed', { phone });
  } catch ({ errors }) {
    throw errors;
  }
};

const logOut = async auth => {
  await auth.logOutConnect();
  window.location.reload();
};

export const FourDigitPINUpdate = ({ baseUrl, cancelReturnsTo, match, history, auth }: Props) => {
  return (
    <SecureFourPinUpdate
      configSteps={steps}
      startId={'createPIN'}
      auth={auth}
      cancelReturnsTo={cancelReturnsTo}
      onCancel={() => logOut(auth)}
      baseUrl={baseUrl}
      match={match}
      history={history}
      onSubmit={submit}
    />
  );
};
