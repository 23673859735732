import axios, { AxiosRequestConfig } from 'axios';
import { attr, belongsTo } from 'spraypaint';

import { blobHeaders, handleDownload } from '@kwara/lib/src/fileDownload';

import Base, { BaseModel } from './Base';
import { UserT } from './User';

const mimeTypesMaps = Object.freeze({
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
});
const mimeTypes = Object.freeze(Object.keys(mimeTypesMaps));

export const OperationalReport = Base.extend({
  static: {
    jsonapiType: 'operational_reports',
    endpoint: '/reporting/operational'
  },
  attrs: {
    state: attr(),
    reportTemplateId: attr(),
    reportTemplateKey: attr(),
    name: attr(),
    toDate: attr(),
    filters: attr(),
    createdBy: belongsTo()
  },
  methods: {
    asOfDate() {
      return this.toDate || this.createdAt;
    },

    async download() {
      const url = `${OperationalReport.url()}/${this.id}.xlsx`;
      const opts = OperationalReport.fetchOptions();
      const options = blobHeaders(opts) as AxiosRequestConfig<any>;
      const fileName = `${this.name}_${this.createdAt}.xlsx`;

      return axios.get(url, options).then(res => {
        try {
          handleDownload({
            data: res.data,
            fileName,
            mimeTypes
          });
          return Promise.resolve(null);
        } catch (e) {
          return Promise.reject(e);
        }
      });
    }
  }
});

export interface OperationalReportT extends BaseModel<OperationalReportT> {
  id: string;
  name: string;
  state: string;
  toDate: string | Date;
  reportTemplateId: string;
  reportTemplateKey: string;
  createdBy: UserT;
  download(): Promise<null>;
  asOfDate(): string | Date;
}
