// @flow
import * as React from 'react';
import { Form } from 'react-final-form';
import get from 'lodash/fp/get';
import first from 'lodash/fp/first';

import { SubscribedPinInputField, FOUR_DIGIT_PIN } from '@kwara/components/src/Form';
import Button from '@kwara/components/src/Button';
import { Link } from '@kwara/components/src/Link';
import { Text } from '@kwara/components/src/Intl';
import createValidator from '@kwara/lib/src/validator';
import Modal from '@kwara/components/src/Modal';
import { ActionButton } from '@kwara/components/src/Button';

import { useStore } from '../../models/Member';

import auth from '../../lib/auth';

const { useState } = React;

const validateConfig = {
  pin: {
    isRequired: () => true,
    minlength: FOUR_DIGIT_PIN
  }
};

export const EnterPIN = ({ history, onClose }) => {
  const store = useStore();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  return (
    <Modal isOpen={true}>
      <ActionButton type="cancel" onClick={onClose} className="ml-auto mt3 mr3" />
      <div className={'flex flex-column h-100 pa3 bg-mobile-neutral-100'}>
        <h1 className={'mt4 kw-weight-bold kw-text-extra-large'}>
          <Text id={'EnterPIN.title'} />
        </h1>
        <p className={'mt1 grey-400 kw-text-regular'}>
          <Text id={'EnterPIN.subtitle'} />
        </p>
        <Form
          onSubmit={async data => {
            const { pin } = data;
            setIsProcessing(true);
            try {
              await auth.connectWithFourDigitLogIn({
                phone: store.member.phoneNumber || store.profile.phone,
                pin
              });
              onClose();
            } catch ({ errors }) {
              const firstError = first(errors);
              const title = get('title', firstError);
              setError(title);
            } finally {
              setIsProcessing(false);
            }
          }}
          initialValues={{}}
          validate={createValidator(validateConfig)}
          render={({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit} className={'flex flex-column mt2 h-100 justify-between'}>
              <div className={'flex flex-column'}>
                <SubscribedPinInputField name="pin" canHide={true} required length={FOUR_DIGIT_PIN} />

                {error ? <span className={'error-600 kw-text-x-small tc mb4'}>{error}</span> : null}
                <div className={'tc'}>
                  <Link
                    onClick={async () => {
                      history.replace({
                        pathname: '/forgotPin'
                      });
                    }}
                    type="primary"
                    underline={false}
                    aria-label="Forgot"
                    active
                  >
                    <Text id="Login.pin.reset" />
                  </Link>
                </div>
              </div>
              <div className="flex flex-column">
                <Button
                  isSubmit
                  disabled={isProcessing || invalid}
                  glyphRightId={isProcessing ? Button.Glyphs.Spinner : null}
                  type="primary"
                  size="large"
                  aria-label="Confirm"
                >
                  <Text id="SignUp.confirm" />
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    </Modal>
  );
};
