import throttle from 'lodash/throttle';

export class DetectUserActivity {
  private lastUserActivityTime: number;
  private root: EventTarget;
  private static eventNames = ['keypress', 'keydown', 'click', 'contextmenu', 'dblclick', 'scroll', 'touchstart'];

  constructor(root: HTMLElement | null = document.body) {
    if (root == null || !(root instanceof EventTarget)) throw new Error('body element is required');

    this.root = root;

    DetectUserActivity.eventNames.forEach(eventName => {
      this.root.addEventListener(eventName, this.updateUserActivity);
    });

    this.lastUserActivityTime = Date.now();
  }

  public destroy = () => {
    DetectUserActivity.eventNames.forEach(eventName => {
      this.root.removeEventListener(eventName, this.updateUserActivity);
    });
  };

  public updateUserActivity = throttle(() => {
    this.lastUserActivityTime = Date.now();
  }, 500);

  public getLastActivityTime = () => {
    return this.lastUserActivityTime;
  };
}
