import React, { useEffect } from 'react';

import { PurchaseAirtimeArg } from 'AirtimePurchaseTypes';

import { If } from '@kwara/components/src/If/If';
import { segmentTrack } from '@kwara/components/src/Segment';
import { SubscribedPhoneField } from '@kwara/components/src/Form';
import { SubStepComponentProps } from '@kwara/components/src/Wizard/deprecated/Wizard';

import { FormState } from '..';
import { AmountInfo } from './AmountInfo';
import { useStore } from '../../../models/Member';
import {
  MIN_PURCHASE_AMOUNT,
  MAX_PURCHASE_AMOUNT,
  getCashbackAmount,
  BUY_AIRTIME_FOR,
  RAW_MIN_CASHBACK
} from '../../../models/AirtimePurchases';

interface DataType extends PurchaseAirtimeArg {
  cachedAnotherPhoneNo?: string;
}

export function BuyingFor(props: SubStepComponentProps<DataType>) {
  const { StackChild, TextField, RadioGroup, addData, data, formProps } = props;

  const store = useStore();

  const values = formProps.values as FormState;
  const phoneNumber = store.profile?.phone;

  useEffect(() => {
    segmentTrack('Purchase Airtime page loaded');
  }, []);

  return (
    <StackChild>
      <RadioGroup
        required
        name="buyingFor"
        flex={false}
        items={[
          {
            labelId: 'Airtime.buyingFor.isForMe',
            value: BUY_AIRTIME_FOR.MY_SELF,
            labelValues: { phoneNumber },
            inputOnChange: () => addData({ airtimePhoneNumber: phoneNumber })
          },
          { labelId: 'Airtime.buyingFor.isForAnotherNumber', value: BUY_AIRTIME_FOR.ANOTHER }
        ]}
      />

      <If
        condition={values.buyingFor === BUY_AIRTIME_FOR.ANOTHER}
        do={
          <div className="mv3">
            <SubscribedPhoneField
              required
              disableDropdown
              margin={false}
              country="ke"
              name="airtimePhoneNumber"
              labelId="Airtime.buyingFor.phoneNumber"
              initialValue={(data as DataType).cachedAnotherPhoneNo || ''}
              onChange={(data: string) => addData({ cachedAnotherPhoneNo: data })}
            />
          </div>
        }
      />

      <TextField
        required
        isCurrency
        sensitiveValidation
        name="amount"
        type="number"
        labelId="Airtime.buyingFor.amount"
        leftGlyph="Currency.orgCurrency"
        inputMode="decimal"
        pattern="\\d*"
        info={<AmountInfo amount={values?.amount} hasCashback={values?.hasCashback} />}
        onChange={(data: React.ChangeEvent<HTMLInputElement>) => {
          if (getCashbackAmount(Number(data.target.value)).raw < RAW_MIN_CASHBACK) addData({ hasCashback: false });
          else addData({ hasCashback: true });
        }}
      />
    </StackChild>
  );
}

export const validateConfig = {
  airtimePhoneNumber: {
    isRequired: () => true,
    phoneNumber: true
  },
  amount: {
    isRequired: () => true,
    currency: true,
    nonZero: true,
    custom(target: string) {
      if (Number(target) > MAX_PURCHASE_AMOUNT) return 'rangeOverflow';

      if (Number(target) < MIN_PURCHASE_AMOUNT) return 'rangeUnderflow';

      return null;
    }
  }
};

BuyingFor.validateConfig = validateConfig;
