import { InferredModel } from 'GlobalTypes';
import { attr } from 'spraypaint';

import Base from '../../Base';

export const TrialBalanceRow = Base.extend({
  static: {
    jsonapiType: 'abstract_trial_balance_report_rows'
  },
  attrs: {
    glCode: attr(),
    glName: attr(),
    debitAmount: attr(),
    creditAmount: attr()
  }
});

export interface TrialBalanceRowT extends Omit<InferredModel<typeof TrialBalanceRow>, 'errors'> {
  glCode: string;
  glName: string;
  debitAmount: number;
  creditAmount: number;
}
