import React from 'react';
import cx from 'classnames';

import { SavingType } from '@kwara/models/src';
import { Text, Currency, Percent } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';
import Asset from '@kwara/components/src/Asset';

import icon from './assets/icon.png';
import styles from './index.module.scss';

type DetailsProps = {
  interest: string;
};

const Details = ({ interest }: DetailsProps) => {
  const details = [{ key: 'SavingsAccountCard.AccruedInterest', value: interest }];
  return (
    <>
      {details.map(detail => (
        <div className={cx(styles.TwoColumnsDetailsRow, 'flex mv1')} key={detail.key}>
          <div className={cx(styles.Key, 'flex items-center mh1')}>
            <Asset id={Asset.Glyphs.Kwara} />
            <div className="ml1">
              <Text id={detail.key} />:
            </div>
          </div>
          <div>
            <span>
              <Percent value={Number(detail.value) / 100} />
            </span>
          </div>
        </div>
      ))}
    </>
  );
};

type ActionsProps = {
  to: string;
};

const Actions = ({ to }: ActionsProps) => {
  return (
    <Button className="w-100 mh3 mv3" to={to} type="primary" size="large">
      <Text id="SavingsAccountCard.action" />
    </Button>
  );
};

type SavingAccountCardProps = {
  savingAccount: SavingType;
};

export function SavingAccountCard({ savingAccount }: SavingAccountCardProps) {
  const { id, name, balance, accruedInterest } = savingAccount;
  const [openDetails, setOpenDetails] = React.useState(false);

  return (
    <div className={styles.Container}>
      <div className={cx(styles.Header, 'flex items-center ma2')}>
        <img src={icon} alt="icon" />
        <div className="ml3">
          <div className="mobile-text-medium kw-weight-bold" aria-label="SavingsAccountName">
            {name}
          </div>
          <div className={cx(styles.Subtitle, 'mobile-text-small kw-weight-regular')}>{id}</div>
        </div>
      </div>
      <div className={cx(styles.Content, 'flex items-center justify-between mh3')}>
        <div className="mv3">
          <div className={cx(styles.AmountLabel, 'mobile-text-small kw-weight-regular')}>
            <Text id="SavingsAccountCard.subtitle" />
          </div>
          <div className="mobile-text-medium kw-weight-regular">
            <Currency format="currency" value={balance} />
          </div>
        </div>
        <Button
          className={styles.ShowDetailsButton}
          glyphRightId={openDetails ? Button.Glyphs.UpMobile : Button.Glyphs.DownMobile}
          onClick={() => setOpenDetails(!openDetails)}
          type={'secondary'}
          size={'regular'}
        >
          <Text id="LoanCard.Details" />
        </Button>
      </div>
      <div className={cx(styles.DetailsContainer, openDetails ? styles.Open : styles.Close, 'pa2')}>
        <Details interest={accruedInterest} />
      </div>
      <div className={cx(styles.Footer, 'flex')}>
        <Actions to={`/overview/${id}/deposit`} />
      </div>
    </div>
  );
}
