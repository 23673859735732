// @flow
import * as React from 'react';
import posed from 'react-pose';
import { spring } from 'popmotion';

const createSpring = props =>
  spring({
    ...props,
    stiffness: 500, // default: 500
    damping: 27, // default: 25
    restDelta: 0.5, // default: 0.5
    restSpeed: 10 // default: 10
  });

const Container = posed.div({
  visible: {
    y: '0',
    rotate: '0',
    delayChildren: 100,
    transition: createSpring
  },
  hidden: {
    y: window.innerHeight + 100,
    rotate: '-10deg',
    transition: createSpring
  }
});

type Props = {
  children: ({ hide: () => void }) => React.Node,
  onDone: () => void
};

type State = {
  done: boolean,
  state: 'stopped' | 'running' | 'completed',
  visible: boolean
};

export default class DelayedAnimation extends React.Component<Props, State> {
  state = {
    done: false,
    state: 'stopped',
    visible: false
  };

  componentDidMount() {
    setTimeout(() => this.setState({ visible: true }), 500);
  }

  hide = () => {
    this.setState({
      visible: false,
      done: true
    });
  };

  complete = () => {
    if (this.state.done) {
      this.props.onDone();
    }
  };

  render() {
    const { visible } = this.state;

    return (
      <Container onPoseComplete={this.complete} pose={visible ? 'visible' : 'hidden'}>
        {this.props.children({ hide: this.hide })}
      </Container>
    );
  }
}
