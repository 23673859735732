import * as React from 'react';
import { Field as FinalFormField } from 'react-final-form';
import noop from 'lodash/fp/noop';

import { TranslationId } from '@kwara/components/src/Intl';
import { InputProps, FieldProps } from '@kwara/components/src/Form';
import { composeEvents } from '@kwara/lib/src/utils/composeEvents';

import { TextField, Field } from '.';

export function toCurrency(x: number) {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

const useCurrencyToggle = ({ name, isCurrency: isEditable, type }) => {
  const [isEditing, setIsEditing] = React.useState(false);

  if (!isEditable) {
    return {
      getValue: val => val,
      isEditable,
      name,
      onFocus: noop,
      onBlur: noop,
      type
    };
  }

  if (!isEditing) {
    return {
      getValue: val => toCurrency(val),
      isEditable,
      name: name + '_ui',
      onFocus: () => setIsEditing(true),
      onBlur: noop,
      type: 'text'
    };
  }

  return {
    getValue: val => val,
    isEditable,
    name,
    onFocus: noop,
    onBlur: () => setIsEditing(false),
    type: 'number'
  };
};

type SubscribedTextFieldProps = InputProps &
  FieldProps & {
    leftGlyph?: string;
    rightGlyph?: string;
    placeholderId?: TranslationId;
    sensitiveValidation?: boolean;
  };

const SubscribedTextField = React.forwardRef((props, forwardedRef) => {
  const {
    compact = false,
    disabled,
    errorBaseId,
    errorValues = {},
    hidden,
    info,
    infoId,
    initialValue,
    labelId,
    labelContent,
    leftGlyph,
    margin,
    max,
    min,
    name: nameProp,
    inputOnBlur,
    placeholderId,
    required,
    rightGlyph,
    rightAction,
    titleId,
    type: typeProp = 'text',
    values,
    isCurrency,
    sensitiveValidation,
    size = 'medium',
    error = false,
    onChange,
    showInfo = true,
    ...rest
  }: SubscribedTextFieldProps = props;
  const { name, onBlur, onFocus, getValue, type } = useCurrencyToggle({
    isCurrency,
    name: nameProp,
    type: typeProp
  });

  return (
    <FinalFormField
      name={nameProp}
      type={type}
      size={size}
      placeholderId={placeholderId}
      infoId={infoId}
      initialValue={initialValue}
      render={({ input, meta }) => {
        const metaValidationType = sensitiveValidation ? meta.dirty : meta.touched;
        const showError = !!(metaValidationType && meta.error) || error;

        return (
          <Field
            compact={compact}
            margin={margin}
            disabled={disabled}
            error={showError}
            name={name}
            size={size}
            titleId={titleId}
            required={required}
            hidden={hidden}
            labelId={labelId}
            labelContent={labelContent}
            errorBaseId={errorBaseId}
            errorCodes={meta.error}
            infoId={infoId}
            values={{ ...values, ...errorValues }}
            info={info}
            showInfo={showInfo}
          >
            <TextField
              ref={forwardedRef}
              disabled={disabled}
              error={showError}
              leftGlyph={leftGlyph}
              max={max}
              min={min}
              placeholderId={placeholderId}
              rightGlyph={rightGlyph}
              rightAction={rightAction}
              size={size}
              type={type}
              values={values}
              inputOnBlur={inputOnBlur}
              {...input}
              {...rest}
              name={name}
              onBlur={e => {
                input.onBlur(e);
                onBlur();
              }}
              onFocus={e => {
                input.onFocus(e);
                onFocus();
              }}
              onChange={composeEvents(input.onChange, onChange)}
              value={getValue(input.value)}
            />
          </Field>
        );
      }}
    />
  );
});
export default SubscribedTextField;
