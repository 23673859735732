// @flow

import * as React from 'react';
import cx from 'classnames';
import map from 'lodash/fp/map';
import invoke from 'lodash/fp/invoke';

import { Text } from '@kwara/components/src/Intl';
import { type SubStepComponentProps } from '@kwara/components/src/Wizard/deprecated/Wizard';
import { NoRecordsMessage } from '@kwara/components/src/Summary';
import { Link } from '@kwara/components/src/Link';
import { formatHumanDate } from '@kwara/lib/src/dates';

import styles from './Summary.module.scss';
import { store } from '../../../models/Store';

const Section = ({ titleId, children, editUrl }: SectionProps) => (
  <section className={`${styles.Section}`}>
    <h3 className={`ma0 mobile-neutral-800 mobile-text-small pb2 ${styles.Heading}`}>
      <Text id={titleId} />
    </h3>
    <div className={cx('mobile-text-small', styles.Content)}>
      <NoRecordsMessage>{children}</NoRecordsMessage>
    </div>

    <div className={cx(styles.EditColumn, 'mobile-text-small')}>
      {editUrl ? (
        <Link to={editUrl} type="primary" underline={false} active>
          <Text id="General.Edit" />
        </Link>
      ) : null}
    </div>
  </section>
);

const Record = ({ hidden = false, labelId, value, valueId, values }: RecordProps) => {
  if (hidden) {
    return null;
  }
  const data = valueId != null ? <Text id={valueId} values={values} /> : value;
  if (data == null) {
    return null;
  }

  return (
    <div className="ma0 mb1 flex flex-column">
      {labelId && (
        <div className="mobile-neutral-600 mobile-text-x-small">
          <Text id={labelId} values={values} />
        </div>
      )}

      <div className="mobile-neutral-900 mobile-text-small">{data}</div>
    </div>
  );
};

export const Summary = ({ StackChild, Checkbox, data: member, parentUrl }: SubStepComponentProps<{}>) => {
  const editUrl = path => `${parentUrl}/${path}`;

  return (
    <StackChild>
      <Section titleId="Register.Summary.Section.personal" editUrl={editUrl('about/1')}>
        <Record labelId="AddMember.Profile.Name.label" value={invoke('fullName', member)} />
        <Record
          hidden={!member.gender}
          labelId="AddMember.Personal.Gender.label"
          value={<Text id={`Register.About.Gender.${member.gender}`} />}
        />
        <Record labelId="AddMember.Personal.DateOfBirth.label" value={invoke('formattedDateOfBirth', member)} />
      </Section>
      <Section titleId="Register.Summary.Section.contact" editUrl={editUrl('about/1')}>
        <Record labelId="Register.Contact.Mobile.label" value={member.phoneNumber} />
        <Record labelId="AddMember.Contact.Email.label" value={member.email} />
      </Section>
      <Section titleId="Register.Summary.Section.identification" editUrl={editUrl('identity/1')}>
        {map(
          r => (
            <Record labelId={`AddMember.Identity.Type.${r.type}`} value={r.documentId} key={r.documentId} />
          ),
          member.idDocuments
        )}

        <Record labelId="AddMember.Identity.KRA.label" value={member.kraPin} />
      </Section>

      <Section titleId="Register.Summary.Section.employment" editUrl={editUrl('identity/1')}>
        <Record
          labelId="AddMember.Employment.Type.label"
          value={<Text id={`Employment.Type.${member.employmentStatus}`} />}
        />

        <Record labelId="AddMember.Employment.Profession.label" value={member.profession} />
      </Section>
      <Section titleId="AddMember.Step.kin" editUrl={editUrl('nextOfKin/1')}>
        <Record labelId="AddMember.Profile.Name.label" value={member.kinFullName} />
        <Record labelId="AddMember.Personal.DateOfBirth.label" value={formatHumanDate(member.kinDateOfBirth)} />
        <Record labelId="Register.Contact.Mobile.label" value={member.kinPhoneNumber} />
        <Record labelId="Register.Summary.Section.nextOfKin.relation" value={member.kinRelationship} />
      </Section>

      {store.termsAndConditions ? (
        <div className="mt3">
          <Checkbox required compact size="regular" name="acceptsTerms" labelContent={<Terms />} />
        </div>
      ) : null}
    </StackChild>
  );
};

const Terms = () => (
  <div className="mobile-neutral-900 mobile-text-small flex flex-wrap items-baseline">
    <div className="no-underline pr1">
      <Text id="AddMember.Connect.Summary.terms1" />
    </div>
    <a href={store.termsAndConditions} target="_blank" rel="noopener noreferrer">
      <div className="mobile-primary-500">
        <Text id="AddMember.Connect.Summary.terms2" />
      </div>
    </a>
  </div>
);

Summary.validate = {
  acceptsTerms: {
    isRequired: () => !!store.termsAndConditions
  }
};
