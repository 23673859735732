// @flow

import * as React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';
import { useLoan } from '@kwara/models/src/models/request/hooks';

import { DocumentList } from '../../components/DocumentList';

const includes = ['member.attachments'];
export const LoanDocuments = ({ loanId }) => {
  const response = useLoan(loanId, includes);
  return (
    <Loadable {...response}>
      {loan => (
        <div className="mt4">
          <DocumentList member={loan.member} contexts={['loans']} attachments={loan.member.attachments} />
        </div>
      )}
    </Loadable>
  );
};
