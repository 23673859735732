// @flow
import * as React from 'react';
import { Form } from 'react-final-form';
import get from 'lodash/fp/get';
import first from 'lodash/fp/first';

import { SubscribedPhoneField } from '@kwara/components/src/Form';
import Button from '@kwara/components/src/Button';
import { Text, Currency } from '@kwara/components/src/Intl';
import createValidator from '@kwara/lib/src/validator';
import Modal from '@kwara/components/src/Modal';
import { MpesaTransactionRegistrationFee } from '@kwara/models/src/models/MpesaTransactionRegistrationFee';
import { formatPhoneNumber } from '@kwara/models/src/models/MpesaTransaction';
import { useMpesaTransactionRegistrationFee } from '@kwara/models/src/models/request/hooks';

import { Navigation } from '../../components/Navigation';
import { useNewUserStore } from '../../models/NewUserStore';
import { PollMpesaTransaction } from '../../components/PollMpesaTransaction';
import { StatusApprovalModal } from '../../components/StatusApprovalModal';
import illustration from './pay.gif';

const { useState } = React;

const validateConfig = {
  phoneNumber: {
    isRequired: () => true,
    phoneNumber: true
  }
};

export const JoiningFee = ({ history, auth }) => {
  const [transactionId, setTransactionId] = useState(null);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [applicationSaved, setApplicationSaved] = useState(false);

  const { storeOrganisation, storePhone, payLaterEnabled } = useNewUserStore();

  const { name, joiningFee } = storeOrganisation;
  const isUserLoggedIn = auth.isLoggedIn();

  const submit = async data => {
    setIsProcessing(true);
    const { phoneNumber } = data;
    const mpesaTransaction = new MpesaTransactionRegistrationFee({
      mPesaPhone: formatPhoneNumber(phoneNumber),
      phone: storePhone,
      orgPermalink: get('permalink', storeOrganisation)
    });

    const didSave = await mpesaTransaction.save();
    setIsProcessing(false);
    if (!didSave) {
      const firstError = first(Object.values(mpesaTransaction.errors));
      const detail = get('detail', firstError);
      setError(detail);
    }

    setTransactionId(mpesaTransaction.id);
  };

  return (
    <>
      <StatusApprovalModal
        show={applicationSaved}
        type="info"
        titleId={'StatusApprovalModal.applicationSaved.title'}
        subtitleId={'StatusApprovalModal.applicationSaved.subtitle'}
        buttonId={'StatusApprovalModal.applicationSaved.button'}
        onConfirm={() => history.push('/')}
      />

      <Modal isOpen className="justify-center pv4 overflow-scroll">
        {transactionId ? (
          <PollMpesaTransaction
            id={transactionId}
            transactionHook={useMpesaTransactionRegistrationFee}
            onConfirm={() => history.push('/')}
            isLoggedIn={isUserLoggedIn}
          />
        ) : (
          <>
            <Navigation saccoName={name} onClick={!payLaterEnabled ? () => history.push('/') : null} />

            <div className={'flex flex-column h-100 w-100 ph3'}>
              <h1 className={'base-neutral-900 kw-weight-bold mobile-text-large'}>
                <Text id={'JoiningFee.title'} />
              </h1>
              <p className={'mt1 mobile-neutral-600 kw-text-small'}>
                <Text
                  id={'JoiningFee.subtitle'}
                  values={{
                    name,
                    joiningFee: <Currency format="currency" value={joiningFee} />
                  }}
                />
              </p>
              <img src={illustration} alt="mpesa" className="center flex-auto" />

              <Form
                onSubmit={data => submit(data)}
                initialValues={{}}
                validate={createValidator(validateConfig)}
                render={({ handleSubmit, invalid }) => (
                  <form onSubmit={handleSubmit} className={'flex flex-column mv3 h-100 justify-between'}>
                    <div className={'flex flex-column'}>
                      <SubscribedPhoneField
                        disableDropdown
                        name="phoneNumber"
                        labelId="JoiningFee.phone.label"
                        required
                      />
                      {error ? <span className={'error-600 kw-text-x-small tc mb4'}>{error}</span> : null}
                    </div>
                    <div className="flex flex-column">
                      <Button
                        aria-label="PayNow"
                        isSubmit
                        disabled={isProcessing || invalid}
                        glyphRightId={isProcessing ? Button.Glyphs.Spinner : null}
                        type="primary"
                        size="large"
                      >
                        <Text id="JoiningFee.payNow.Button.label" />
                      </Button>

                      {payLaterEnabled && (
                        <Button
                          aria-label="PayLater"
                          className="mt2"
                          type="secondary"
                          size="large"
                          onClick={() => setApplicationSaved(true)}
                        >
                          <Text id="JoiningFee.payLater.Button.label" />
                        </Button>
                      )}
                    </div>
                  </form>
                )}
              />
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
