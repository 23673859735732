import * as React from 'react';
import map from 'lodash/fp/map';
import includes from 'lodash/fp/includes';
import isEmpty from 'lodash/fp/isEmpty';

import { SavingType } from '@kwara/models/src/';
import {
  getTransactionTypes,
  contexts,
  TransactionChannels,
  TransactionChannelT
} from '@kwara/models/src/models/Transactions';
import { fields } from '@kwara/models/src/models/Remittance';
import { Loadable } from '@kwara/components/src/Loadable';
import { useMember } from '@kwara/models/src/models/request/hooks';
import { Text } from '@kwara/components/src/Intl';
import * as banksLists from '@kwara/components/src/Bank/banks';

import { BankSelect, BranchSelect } from '../BankSelect';
import { MemberBankAccountSelect } from '../MemberBankAccountSelect';
import { SubscribedSelectField as SelectField, SubscribedTextField as TextField, Condition } from '..';

import { store } from '../../../../webapp-sacco/src/models/Store/Store';

const include = ['savings', 'savings.product'];
const SavingAccountSelect = ({
  name = 'accountId',
  memberId,
  disableIf = s => !s.isWithdrawable()
}: {
  name?: string;
  memberId: string;
  disableIf?: (s: SavingType) => boolean;
}) => {
  const r = useMember(memberId, include);
  return (
    <Loadable {...r}>
      {member => {
        const accounts = member.savings;

        if (isEmpty(accounts)) {
          return (
            <div className="mt1 mtb1">
              <Text id="Forms.TransactionForm.SavingAccount.empty" />
            </div>
          );
        }

        return (
          <SelectField name={name} labelId="Forms.TransactionForm.SavingAccount.label" required>
            <SelectField.Option translationId={`Forms.TransactionForm.SavingAccount.default`} value="" />
            {map(
              savingAccount => (
                <SelectField.Option value={savingAccount.id} key={savingAccount.id} disabled={disableIf(savingAccount)}>
                  {savingAccount.id} - {savingAccount.name} ({savingAccount.balance})
                </SelectField.Option>
              ),
              accounts
            )}
          </SelectField>
        );
      }}
    </Loadable>
  );
};

// This looks up the GL-related transaction channels,
// configured in Mambu per organisation.
const GlTransferSelect = ({
  name = 'glTransferId',
  required = true,
  labelId = 'GlTransferSelect.label'
}: {
  name?: string;
  required?: boolean;
  labelId?: string;
}) => {
  const glTransactionChannels = store.glTransactionChannels;

  if (isEmpty(glTransactionChannels)) {
    return null;
  }

  return (
    <SelectField required={required} name={name} labelId={labelId}>
      <SelectField.Option value=""></SelectField.Option>
      {map(
        channel => (
          <SelectField.Option key={channel.id} value={channel.id}>
            {channel.glCode} - {channel.glName}
          </SelectField.Option>
        ),
        glTransactionChannels
      )}
    </SelectField>
  );
};

interface ConfigT {
  bank?: string;
  bankLabelId?: string;
  bankBranch?: string;
  accountNumber?: string;
  memberBankAccountId?: string;
  methods?: { value: string }[];
  banks?: banksLists.Shape;
  showBankAccountSelectionFor?: TransactionChannelT[];
  showBanksFieldsFor?: TransactionChannelT[];
  showChequeFieldsFor?: TransactionChannelT[];
  showAccountSelectionFor?: TransactionChannelT[];
  disableAccountIf?: (s: SavingType) => boolean;
  placeholderId?: string;
  memberId?: string;
}

export const SubscribedPaymentSelectField = ({
  name = 'disbursement',
  labelId = 'TransactionMethod.label.default',
  config = {},
  required,
  inputOnChange,
  addData
}: {
  name?: string;
  labelId?: string;
  required?: boolean;
  placeholder?: string;
  inputOnChange?: (event: React.SyntheticEvent<HTMLSelectElement>) => void;
  config?: ConfigT;
  addData?: () => void;
}) => {
  const {
    bank = 'bank',
    bankLabelId,
    bankBranch = 'bankBranch',
    accountNumber = 'accountNumber',
    memberBankAccountId = fields.remittance.bankAccount,
    methods = getTransactionTypes(contexts.LoanRepayment).values,
    banks,
    placeholderId = `TransactionMethod.placeholder.default`,
    showBankAccountSelectionFor = [],
    showBanksFieldsFor = [TransactionChannels.directDebit, TransactionChannels.bankTransfer],
    showChequeFieldsFor = [],
    showAccountSelectionFor = [],
    disableAccountIf,
    memberId
  } = config;

  return (
    <>
      <SelectField name={name} labelId={labelId} required={required} inputOnChange={inputOnChange}>
        <SelectField.Option translationId={placeholderId} value="" />
        {map(
          o => (
            <SelectField.Option translationId={`TransactionMethod.${o.value}`} key={o.value} value={o.value} />
          ),
          methods
        )}
      </SelectField>

      <Condition when={name} evalCondition={val => includes(val, showChequeFieldsFor)}>
        <TextField name="drawer" labelId="Forms.TransactionForm.ChequeDrawer.label" required />
        <TextField name="chequeNumber" labelId="Forms.TransactionForm.ChequeNumber.label" required />
      </Condition>

      <Condition when={name} evalCondition={val => includes(val, showBankAccountSelectionFor)}>
        {memberId ? <MemberBankAccountSelect memberId={memberId} name={memberBankAccountId} addData={addData} /> : null}
      </Condition>

      <Condition when={name} evalCondition={val => includes(val, showBanksFieldsFor)}>
        <BankSelect name={bank} labelId={bankLabelId} banks={banks} />
        <Condition when={bank} evalCondition={() => true}>
          {({ input }) => {
            const { value: bankId } = input;
            return <BranchSelect name={bankBranch} bankId={bankId} />;
          }}
        </Condition>

        <TextField
          name={accountNumber}
          labelId="Forms.TransactionForm.Account.label"
          placeholderId="Forms.TransactionForm.Account.placeholder"
          required
        />
      </Condition>

      <Condition when={name} evalCondition={val => includes(val, showAccountSelectionFor)}>
        {memberId ? <SavingAccountSelect memberId={memberId} disableIf={disableAccountIf} /> : null}
      </Condition>
      <Condition when={name} is={TransactionChannels.glTransfer}>
        <GlTransferSelect />
      </Condition>
    </>
  );
};
