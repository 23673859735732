import { attr, hasMany } from 'spraypaint';

import { formatHumanDate } from '@kwara/lib/src/dates';
import { fullName } from '@kwara/models/src/models/Member';
import Base, { BaseModel } from '@kwara/models/src/models/Base';
import { EmploymentStatusT } from '@kwara/models/src/models/Member';
import { IdDocumentType, IdDocument } from '@kwara/models/src/models/IdDocument';

import { $api } from '../services';

export type WithdrawMembershipApplicationPayload = {
  phone_number: string;
  device_token: string;
};

export const MembershipApplications = Base.extend({
  static: {
    jsonapiType: 'membership_applications',
    async withdraw(payload: WithdrawMembershipApplicationPayload) {
      const response = await $api.httpDeleteRequest(`${MembershipApplications.url()}/cancel_membership_application`, {
        data: { data: { attributes: payload } }
      });

      return response;
    }
  },
  attrs: {
    orgPermalink: attr(),
    firstName: attr(),
    middleName: attr(),
    lastName: attr(),
    dateOfBirth: attr(),
    gender: attr(),
    email: attr(),
    phoneNumber: attr(),
    maritalStatus: attr(),
    profession: attr(),
    employmentStatus: attr(),
    employer: attr(),
    kin: attr(),
    attachments: attr(),
    idDocuments: hasMany('members_id_documents')
  },
  methods: {
    fullName() {
      return fullName([this.firstName, this.middleName, this.lastName]);
    },
    formattedDateOfBirth() {
      return this.dateOfBirth ? formatHumanDate(this.dateOfBirth) : null;
    }
  }
});

MembershipApplications.new = (props?: { idDocuments?: Array<IdDocumentType> }) => {
  const { idDocuments = [{} as IdDocumentType] } = props;
  const idDocumentsList = idDocuments.map(d => new IdDocument(d));

  return new MembershipApplications({ ...props, idDocuments: idDocumentsList });
};

type AttachmentT = {
  type: 'JPEG' | 'JPG' | 'PDF' | 'PNG';
  content: string;
};

export type AttachmentsT = Record<string, AttachmentT>;

export interface MembershipApplicationsType extends BaseModel<MembershipApplicationsType> {
  orgPermalink: string;
  firstName: string;
  lastName: string;
  middleName?: string | null;
  dateOfBirth: string;
  gender: string | null;
  email: string | null;
  phoneNumber: string;
  maritalStatus: string | null;
  profession: string | null;
  employmentStatus: EmploymentStatusT | null;
  employer: string | null;
  kin: string[] | null;
  attachments: AttachmentsT | null;
  idDocuments: IdDocumentType[];
}
