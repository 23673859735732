import join from 'lodash/fp/join';
import keys from 'lodash/fp/keys';
import getOr from 'lodash/fp/getOr';
import values from 'lodash/fp/values';

import { appName } from './utils';
import { Logger } from './logger';

// see https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
const mimeTypesMaps = Object.freeze({
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/pdf': 'pdf'
  // 'text/csv': 'csv' // currently disabled see ch9363
});

export const SupportedMimeTypes = Object.freeze(keys(mimeTypesMaps));
export const SupportedExtensions = Object.freeze(values(mimeTypesMaps));
export const UNKNOWN = 'UNKNOWN';
export const mimeTypeToExtension = (mimeType: string): string => getOr(UNKNOWN, mimeType, mimeTypesMaps);

type Opts = {
  headers?: any;
  responseType?: string;
};

function headers(opts: Opts): Opts {
  return { ...opts, headers: { ...opts.headers, accept: '*/*' } };
}

export function blobHeaders(opts: Opts): Opts {
  return { ...headers(opts), responseType: 'blob' };
}

export function textHeaders(opts: Opts): Opts {
  return { ...headers(opts), responseType: 'text/plain' };
}

export function csvHeaders(opts: Opts): Opts {
  return { ...headers(opts), responseType: 'csv' };
}

export function blobToFileDownload(
  blob: Blob,
  fileName: string = 'kwara-template.xlsx',
  preview: boolean = false
): void {
  const fileUrl = global.URL.createObjectURL(blob);

  /**
   * @element
   * This line:
   * `window.location.href = fileUrl;`
   * should be enough in most cases but the download seems to happen without the file extension
   * on Linux on ci. To make sure we can set both file extension and file name we generate an
   * anchor, inject it in the DOM, click on it and remove it.
   */
  const element = document.createElement('a');

  element.setAttribute('href', fileUrl);

  if (appName.isSacco) {
    element.setAttribute('target', '_blank');
    element.setAttribute('rel', 'noopener noreferrer');
  }

  if (!preview) element.setAttribute('download', fileName);

  element.style.display = 'none';
  document.body?.appendChild(element);
  element.click();
  document.body?.removeChild(element);
}

type HandleTextDownloadArgs = {
  data: string;
  fileName: string;
  preview?: boolean;
};

export function handleTextDownload({ data, fileName, preview }: HandleTextDownloadArgs): void {
  const blob = new Blob([data], { type: 'text/plain' });

  blobToFileDownload(blob, fileName, preview);
}

type HandleDownloadArgs = {
  data: Blob;
  mimeTypes?: readonly string[];
  fileName?: string;
  preview?: boolean;
};

/**
 * @binaryToBlob
 * To download the template we take the binary returned from the api, we instantiate
 * a Blob and point the browser to a new URL created from the blob object.
 *
 * @References
 * - https://github.com/getkwara/backend/pull/332
 * - https://blog.logrocket.com/programmatic-file-downloads-in-the-browser-9a5186298d5c/
 * - http://keyangxiang.com/2017/09/01/HTML5-XHR-download-binary-content-as-Blob/
 */
export function binaryToBlob(
  fileName?: string,
  mimeTypes = SupportedMimeTypes as string[],
  preview: boolean = false
): Promise<void> {
  return new Promise(resolve => {
    const { result } = this;
    const fileLength = result.length;
    const chunks = new Uint8Array(fileLength);

    for (let i = 0; i < fileLength; i++) {
      chunks[i] = result.charCodeAt(i);
    }

    const blob = new Blob([chunks], { type: join(', ', mimeTypes) });

    return resolve(blob);
  })
    .then(blob => blobToFileDownload(blob as Blob, fileName, preview))
    .catch(err => {
      Logger.log('Error storing template file ', JSON.stringify(err));

      return err;
    });
}

export function handleDownload({ data, fileName, mimeTypes, preview }: HandleDownloadArgs): void {
  const frb = new FileReader();

  frb.onload = function() {
    binaryToBlob.bind(frb)(fileName, mimeTypes, preview);
  };
  frb.readAsBinaryString(data);
}
