import { attr, belongsTo } from 'spraypaint';
import { ValueOf } from 'GlobalTypes';

import { blobHeaders } from '@kwara/lib/src/fileDownload';

import Base from './Base';
import { TillT } from './Till';
import { TillTransaction } from './TillTransaction';

export const TillSessionStatuses = Object.freeze({
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
  PENDING: 'PENDING'
});

export type TillSessionStatusT = ValueOf<typeof TillSessionStatuses>;

export const TillSession = Base.extend({
  static: {
    jsonapiType: 'till_sessions'
  },
  attrs: {
    createdAt: attr(),
    updatedAt: attr(),
    tellerName: attr(),
    tellerId: attr(),
    status: attr(),
    openingAmount: attr(), // Deprecated
    currentAmount: attr(), // Deprecated
    closingAmount: attr(), // Deprecated
    openingBalance: attr(),
    currentBalance: attr(),
    closingBalance: attr(),
    till: belongsTo()
  },
  methods: {
    tillTransactionsPdf() {
      const url = `${TillTransaction.url()}.pdf?filter[till_id]=${this.id}`;
      const opts = TillTransaction.fetchOptions();
      const options = blobHeaders(opts);

      return Base.downloadFileFromUrl(url, options);
    }
  }
});

export type TillSessionT = {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  tellerName: string;
  tellerId: string;
  status: TillSessionStatusT;
  openingAmount?: number;
  currentAmount: number;
  closingAmount?: number;
  openingBalance?: number;
  currentBalance?: number;
  closingBalance?: number;
  till: TillT;
};

export const CurrentTillSession = TillSession.extend({
  static: {
    endpoint: '/current_till_session'
  }
});
