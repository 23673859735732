// @flow
import * as React from 'react';
import uniqueId from 'lodash/uniqueId';

import styles from './index.module.scss';

const px = dim => `${dim}px`;

type Props = {
  diameter?: number,
  borderWidth?: number,
  percent: number
};

export default function Progress({ diameter = 28, borderWidth = 1, percent = 0 }: Props) {
  const dpx = px(diameter);
  const mid = diameter / 2;

  const maskId = `mask-${uniqueId()}`;

  let angle = percent * 360;
  // const t = 90;
  const w = mid;

  if (angle >= 360) {
    angle = 359.999;
  }

  const r = (angle * Math.PI) / 180;
  const x = Math.sin(r) * w;
  const y = Math.cos(r) * -w;
  const midr = angle > 180 ? 1 : 0;

  const animBar = `M 0 0 v -${w} A ${w} ${w} 1 ${midr} 1 ${x} ${y} z`;

  return (
    <svg
      className={styles.Progress}
      width={dpx}
      height={dpx}
      viewBox={`0 0 ${diameter} ${diameter}`}
      shapeRendering="geometricPrecision"
    >
      <defs>
        {/* In production make sure the mask id is discrete if you plan on using multiple instances on one page */}
        <mask id={maskId} x="0" y="0" width={dpx} height={dpx} maskUnits="userSpaceOnUse">
          {/* The outer shape hides everything outside of the circle
             This is necessary because the path used to create the bar does not perfectly
             match the shape of a circle. We render the path larger than needed and mask it's edges
             to create a perfect circle. */}
          <circle cx={mid} cy={mid} r={mid + 3} strokeWidth="0" fill="black" opacity="1" />

          {/* The middle shape defines the visible area */}
          <circle cx={mid} cy={mid} r={mid} strokeWidth="0" fill="white" opacity="1" />

          {/* The inner shape creates the hole in the center.
             The value `r` defines the radius of the hole. */}
          <circle
            className="progress-radial-mask-inner"
            cx={mid}
            cy={mid}
            r={mid - borderWidth}
            strokeWidth="0"
            fill="black"
            opacity="1"
          />
        </mask>
      </defs>
      <g mask={`url(#${maskId})`}>
        <circle className={styles.ProgressTrack} cx={mid} cy={mid} r={mid} strokeWidth="0" opacity="1" />
        <path className={styles.ProgressBar} transform={`translate(${mid}, ${mid})`} d={animBar} />
      </g>
    </svg>
  );
}
