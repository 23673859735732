/**
 * @zIndices
 *
 * Maps z-indices for different parts of the app
 * This avoids having z-indices hard-coded everywhere
 * making changing the levels difficult
 *
 * Currently the order of layers, from top to bottom
 *
 * Modal
 * Global Navigation
 * Everything else
 *
 *
 * @Important Alot files imports this util as a default import
 * therefore we had to carter for that by still keeping the default
 * export however, moving on, we encourage you to import it as a named import
 *
 */

export const zIndices = Object.freeze({
  Dialog: 'z-500',
  Modal: 'z-999',
  Banner: 'z-5',
  Navigation: 'z-4',
  DebugSwitchUser: 'z-3',
  OverlayMenus: 'z-2',
  Max: 'z-max',
  Base: ''
});

/**
 * Because zIndices.Max has a value of 2147483647,
 * in a situation where some library we use need a
 * number base maximum zindex, we then apply @MAXIMUM_NUMBER_Z_INDEX
 */
export const MAXIMUM_NUMBER_Z_INDEX = 2147483647;

export default zIndices;
