// @flow

import { attr, belongsTo } from 'spraypaint';

import Base, { type BaseModel } from './Base';
import { User, type UserT } from './User';

type LoanApplicationState = 'pending_approval' | 'approved';
type LoanApplicationEvent = 'approve' | 'reject';

export const LoanApplicationApproval = Base.extend({
  static: {
    jsonapiType: 'loan_application_approvals'
  },
  attrs: {
    state: attr(),
    user: belongsTo({ type: User })
  }
});

export interface LoanApplicationApprovalT extends BaseModel<LoanApplicationApprovalT> {
  state: {
    current: LoanApplicationState,
    permitted_events: LoanApplicationEvent[]
  };
  user: UserT;
}
