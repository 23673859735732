import * as React from 'react';
import get from 'lodash/fp/get';
import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';
import { TransactionChannels } from '@kwara/models/src/models/Transactions';

const glDirection = transaction => {
  let direction = null;
  switch (transaction.type) {
    case 'DEPOSIT':
      direction = 'from';
      break;
    case 'REPAYMENT':
      direction = 'from';
      break;
    case 'WITHDRAWAL':
      direction = 'to';
      break;
  }

  return direction ? <Text id={`General.Directions.${direction}`} /> : '';
};

export const TransactionItemType = ({ transaction }) => {
  const direction = glDirection(transaction);

  if (transaction.isGlChannel) {
    return (
      <>
        <Text id={`TransactionType.${transaction.type}`} /> {direction} <span>{transaction.paymentMethod}</span>
      </>
    );
  }
  if (transaction.paymentMethod === TransactionChannels.mpesa) {
    return (
      <>
        <Text id={`TransactionType.${transaction.type}`} /> <Text id={`TransactionDetail.to`} />{' '}
        <Text id={`TransactionMethod.${transaction.paymentMethod}`} />
      </>
    );
  }

  const firstFeeName = get('fees.[0].name', transaction);

  return (
    <>
      <If condition={!!transaction.paymentMethod} do={<span>{transaction.paymentMethod} </span>} />
      <Text id={`TransactionType.${transaction.type}`} />
      <If condition={!!firstFeeName} do={<span> - {firstFeeName}</span>} />
    </>
  );
};
