// @flow
import * as React from 'react';
import classnames from 'classnames';
import { type IntlShape, injectIntl } from 'react-intl';
import { appName } from '@kwara/lib/src/utils';

import { type TranslationId, getTranslation } from '@kwara/components/src/Intl';

import styles from './TextArea.module.css';

type PublicProps = {
  border?: boolean,
  compact?: boolean,
  disabled?: boolean,
  error?: boolean,
  name: string,
  onChange: (evt: { target: { value: string } }) => void,
  placeholderId?: TranslationId,
  size: 'regular' | 'medium',
  value: string
};

type Props = { intl: IntlShape } & PublicProps;

const borderCol = (disabled, error) => {
  if (error) {
    return 'b--red-500';
  }

  return disabled ? 'b--light-grey-300' : 'b--light-grey-500';
};

function getPlatformStyle(isMemberApp, size) {
  const key = isMemberApp ? 'memberApp' : 'coreApp';

  const platformProps = {
    memberApp: {
      className: 'w-100 bg-mobile-primary-50 mobile-text-medium bw0'
    },
    coreApp: {
      className: `w-100 dib ba bf--indigo-500 kw-text-${size}`
    }
  };

  const activeProps = platformProps[key];

  return {
    className: activeProps.className
  };
}

const TextField = ({
  compact = false,
  border = true,
  disabled = false,
  error = false,
  intl,
  placeholderId,
  size,
  value,
  name,
  ...rest
}: Props) => {
  const { className } = getPlatformStyle(appName.isMember, size);

  const inputClasses = [
    className,
    styles.base,
    styles[size],
    !compact && styles.isNotCompact,
    border ? borderCol(disabled, error) : 'b--transparent',
    styles.container
  ];

  const placeholder = placeholderId ? getTranslation(intl, placeholderId) : null;

  return (
    <div>
      <textarea
        disabled={disabled}
        id={name}
        name={name}
        placeholder={placeholder}
        className={classnames(inputClasses)}
        value={value}
        {...rest}
      />
    </div>
  );
};

export default (injectIntl(TextField): React.ComponentType<PublicProps>);
