// @flow

import { attr, belongsTo } from 'spraypaint';
import get from 'lodash/fp/get';
import compact from 'lodash/fp/compact';

import Base, { type BaseModel } from './Base';
import { type LoanClassificationT } from './LoanClassification';

export const LoanPurpose = Base.extend({
  static: {
    jsonapiType: 'loan_purposes'
  },
  attrs: {
    loanId: attr(),
    classification: belongsTo('loan_classifications')
  },
  methods: {
    classifications() {
      const deepest = this.classification;

      const child = get('id', deepest);
      const parent = get('parent.id', deepest);
      const grandParent = get('parent.parent.id', deepest);

      return compact([grandParent, parent, child]);
    }
  }
});

export interface LoanPurposeT extends BaseModel<LoanPurposeT> {
  loanId: string;
  classification: LoanClassificationT;
  classifications: () => string[];
}
