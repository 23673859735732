import * as React from 'react';
import find from 'lodash/fp/find';

import { AttachmentT, MemberType } from '@kwara/models/src';

import { UploadWidgetUI } from '@kwara/components/src/UploadWidgetUI';

import { WithViewer } from './Viewer';
import { convert, FileNameT } from '../utils';

type Props = {
  textId: string;
  infoId?: string;
  fileName: FileNameT;
  onChange: (p: AttachmentT | {}) => void;
  onRemove: (p: string) => void;
  checked: boolean;
  member: MemberType;
  style: any;
};

export function MemberUploadWidgetBase({ textId, fileName, onChange, onRemove, checked, member = {}, style }: Props) {
  return (
    <WithViewer member={member}>
      {({ setShownAttachment }) => (
        <UploadWidgetUI
          checked={checked}
          onView={() => setShownAttachment(find(o => o.name === fileName, member.attachments))}
          onRemove={() => onRemove(fileName)}
          onFileInputChange={e => {
            if (checked) {
              onRemove(fileName);
            }
            convert(e, fileName).then(onChange);
          }}
          labelId={textId}
          fileName={fileName}
          style={style}
        />
      )}
    </WithViewer>
  );
}
