// @flow

import * as React from 'react';
import map from 'lodash/fp/map';

import { type SubStepComponentProps } from '@kwara/components/src/Wizard/deprecated/Wizard';
import { DatePickerInputs } from '@kwara/components/src/Form';

import * as recordValidations from '../../../../../webapp-sacco/src/lib/recordValidations';

export const About = (props: SubStepComponentProps<>) => {
  const { StackChild, TextField, SelectField, PhoneField, data } = props;

  return (
    <StackChild>
      <TextField
        name="firstName"
        errorBaseId="AddMember.Profile.Name"
        placeholderId="AddMember.Profile.FirstName.placeholder"
        labelId="AddMember.Profile.FirstName.label"
        required
      />

      <TextField
        name="lastName"
        errorBaseId="AddMember.Profile.Name"
        placeholderId="AddMember.Profile.LastName.placeholder"
        labelId="AddMember.Profile.LastName.label"
        required
      />
      <TextField
        name="email"
        inputMode="email"
        labelId="AddMember.Contact.Email.label"
        placeholderId="AddMember.Contact.Email.placeholder"
      />

      <PhoneField name="phoneNumber" labelId="AddMember.Contact.PhoneNumber.label" initial required />
      <DatePickerInputs
        name="dateOfBirth"
        placeholderId="AddMember.Personal.DateOfBirth.placeholder"
        labelId="AddMember.Personal.DateOfBirth.label"
        initial={data.dateOfBirth}
        required
      />
      <SelectField name="gender" labelId="Register.About.Gender.select.title" required>
        <SelectField.Option translationId="Register.About.Gender.select" value="" />
        {map(
          gender => (
            <SelectField.Option
              key={gender.labelId}
              translationId={gender.labelId}
              value={gender.value}
            ></SelectField.Option>
          ),
          [
            {
              labelId: 'Register.About.Gender.FEMALE',
              value: 'FEMALE'
            },
            {
              labelId: 'Register.About.Gender.MALE',
              value: 'MALE'
            },
            {
              labelId: 'Register.About.Gender.NON_BINARY',
              value: 'NON_BINARY'
            },
            {
              labelId: 'Register.About.Gender.OTHER',
              value: 'OTHER'
            }
          ]
        )}
      </SelectField>
    </StackChild>
  );
};
About.validate = {
  firstName: {
    isRequired: () => true,
    maxlength: 30,
    ...recordValidations.personName
  },
  lastName: {
    isRequired: () => true,
    maxlength: 30,
    ...recordValidations.personName
  },
  gender: {
    isRequired: () => true
  },
  email: {
    email: true
  },
  phoneNumber: {
    isRequired: () => true,
    phoneNumber: true
  },
  dateOfBirth: {
    isRequired: () => true,
    ...recordValidations.dateOfBirth
  }
};
