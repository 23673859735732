import find from 'lodash/fp/find';
import get from 'lodash/fp/get';
import pipe from 'lodash/fp/pipe';

import { ERRORS as err } from '@kwara/lib/src/validator/';
import { IdDocumentType } from '@kwara/models/src/models/IdDocument';
import { getCurrentDate, getYear, isAfter, subYears, isFuture } from '@kwara/lib/src/dates';

import { IdentityType } from './types';

const dateOfBirth = {
  custom(value?: string) {
    if (!value) return err.valueMissing;

    if (isFuture(value)) return err.future;

    const year = getYear(value);

    if (isAfter(value, subYears(getCurrentDate(), 18))) return err.under18;

    if (year == null || isNaN(year) || year < 1900) return err.invalidYear;

    return null;
  }
};

const identityDocumentValue = {
  custom(id: string, allData: { identityType: IdentityType; idDocuments: IdDocumentType[] }) {
    const { idDocuments } = allData;

    const identityType = pipe(
      find((d: IdDocumentType) => d.documentId === id),
      get('type')
    )(idDocuments);

    if (identityType === 'PASSPORT') return /^\w+$/.test(id) ? null : err.passportFormat;
    else if (identityType === 'NATIONAL') return /^\d+$/.test(id) ? null : err.nationalFormat;
  },
  minlength: 5,
  maxlength: 15
};

const personName = { pattern: /^[ a-zA-Z\u00C0-\u017F'-]+$/i };

const positiveNumber = { pattern: /[0-9]/, min: 0 };

const alphaNumericWithForwardSlashAndDash = {
  pattern: /^[a-zA-Z0-9/-]+$/i,
  config(options: Record<string, any>) {
    return { ...options, pattern: this.pattern };
  }
};

const alphaNumeric = {
  pattern: /^[a-zA-Z0-9]+$/i,
  config(minlength: number, maxlength: number) {
    return { pattern: this.pattern, minlength, maxlength };
  }
};

export {
  dateOfBirth,
  identityDocumentValue,
  personName,
  positiveNumber,
  alphaNumeric,
  alphaNumericWithForwardSlashAndDash
};
