import React from 'react';

import { type LoanType } from '@kwara/models/src';

import { Currency, Duration } from '@kwara/components/src/Intl';

import { useStore } from '../../models/Member';

import Instant from './assets/Instant.svg';
import MainVector from './assets/MainVector.svg';

import { LoanStateTag } from './Components/Tag';
import { TwoColumnsDetails } from './Components/DetailsContent';
import { MainCardActions } from './Components/FooterContent';

import { LoanCardBase } from './LoanCardBase';

const createDetailsArray = data => {
  const { id, duration, amount, totalBalance, interestRate, interest } = data;
  const { percentage } = interestRate;
  const { balance } = interest;
  const transId = 'LoanCard.MainLoanCard.Detail';

  return [
    { key: `${transId}.LoanID`, value: id, labelId: 'LoanID' },
    {
      key: `${transId}.Principal`,
      value: <Currency format="currency" value={amount} />,
      labelId: 'Principal'
    },
    {
      key: `${transId}.TotalDue`,
      value: <Currency format="currency" value={totalBalance} />,
      labelId: 'TotalBalance'
    },
    { key: `${transId}.InterestRate`, value: `${percentage}%`, labelId: 'InterestRate' },
    {
      key: `${transId}.InterestAmount`,
      value: <Currency format="currency" value={balance} />,
      labelId: 'InterestAmount'
    },
    { key: `${transId}.Duration`, value: <Duration value={duration} />, labelId: 'Duration' }
  ];
};

type Props = {
  data: LoanType
};

export const MainLoanCard = ({ data, showMoreDetailsButton = true }: Props) => {
  const { id, name, state, totalDue, instantLoan } = data;
  const { current } = state;

  const detailsArray = createDetailsArray(data);

  const store = useStore();

  const canRepay = data.canMakeRepayment() && store.isMpesaLoanRepaymentEnabled;
  return (
    <LoanCardBase
      key={id}
      title={name}
      icon={instantLoan ? Instant : MainVector}
      amountLabelId="LoanCard.NextRepayment"
      amount={totalDue}
      tagComponent={<LoanStateTag loanState={current} />}
      detailsContent={<TwoColumnsDetails details={detailsArray} />}
      footerContent={<MainCardActions loanId={id} canRepay={canRepay} showMoreDetailsButton={showMoreDetailsButton} />}
    />
  );
};
