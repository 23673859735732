import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { Completion, Types } from '@kwara/components/src/Completion';

import { analyticsId } from '../config';
import { usePurchaseAirtime } from '..';

export function PendingStatus() {
  const { isPolling } = usePurchaseAirtime();

  return (
    <Completion
      type={Types.good}
      isLoading={isPolling}
      analyticsId={analyticsId}
      titleId="Airtime.Status.Pending.title"
      subtitle={<Text id="Airtime.Status.Pending.subTitle" />}
    />
  );
}
