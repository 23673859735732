import { attr } from 'spraypaint';
import { KeyOf } from 'GlobalTypes';
import Base, { BaseModel } from './Base';

export const IdDocument = Base.extend({
  static: {
    jsonapiType: 'members_id_documents'
  },
  attrs: {
    documentId: attr(),
    type: attr()
  }
});

export const StandardIdDocumentTypes = Object.freeze({
  NATIONAL: 'NATIONAL',
  PASSPORT: 'PASSPORT'
});
export type StandardIdDocumentType = KeyOf<typeof StandardIdDocumentTypes>;
// Custom Id document types exist only in some SACCOs.
// When a new one is added, related translation should also be added in en.js
export type CustomIdDocumentType = 'Historical Member ID';

export interface IdDocumentType extends BaseModel<IdDocumentType> {
  documentId: string;
  type: StandardIdDocumentType | CustomIdDocumentType;
}
