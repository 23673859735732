import { attr, hasMany, hasOne } from 'spraypaint';
import axios from 'axios';

import { RecommendArg, RecommendedOrganisationResolvedResponse } from 'RecommendedOrganisationTypes';

import { UserT } from './User';
import Base, { BaseModel } from './Base';
import { Branch, BranchT } from './Branch';
import { AppRole, AppRoleT } from './AppRole';
import { $api } from '../../../webapp-member/src/services';
import { OrganisationSetting, OrganisationSettingT } from './OrganisationSetting';

export const Organisation = Base.extend({
  static: {
    jsonapiType: 'organisations',
    async recommend({ financialProduct, financialProductCustom, incomeRange, ageGroup }: RecommendArg) {
      const url = `${Organisation.baseUrl}/v1/organisations/recommend`;
      const response = await $api.httpPostRequest<{}, RecommendedOrganisationResolvedResponse>(url, {
        data: {
          attributes: {
            desired_financial_product: financialProduct,
            desired_financial_product_custom: financialProductCustom,
            monthly_income_range: incomeRange,
            age_group: ageGroup
          }
        }
      });

      return response;
    },
    async getOrganizationDetails(permalink: string) {
      const url = `${Organisation.baseUrl}/v1/organisations/${permalink}`;
      const response = await $api.httpGetRequest(url);

      return response;
    }
  },
  attrs: {
    name: attr(),
    currency: attr(),
    users: hasMany(),
    appRoles: hasMany({ type: AppRole }),
    configSettings: attr(),
    joiningFee: attr(),
    joiningFeeType: attr(),
    joiningFeeAccount: attr(),
    businessShortCode: attr(),
    isCrbCheckEnabled: attr(),
    branches: hasMany({ type: Branch }),
    organisationSetting: hasOne({ type: OrganisationSetting }),
    smsBalance: attr(),
    smsBalancePercentage: attr(),
    totalSmsSent: attr(),
    bridgingLoanProductId: attr(),
    bridgingDepositProductId: attr(),
    shareCapitalProductId: attr(),
    settings: attr(),
    permalink: attr(),
    logoUrl: attr(),
    loanDisbursalSettings: attr(),
    ussdCode: attr(),
    fuzzyMemberCount: attr(),
    about: attr(),
    savingsInfo: attr(),
    loanInfo: attr(),
    memberRequirements: attr()
  },
  methods: {
    update(attributes) {
      const url = Organisation.url();
      const opts = Organisation.fetchOptions();
      const data = {
        data: { attributes }
      };

      return axios.patch(url, data, opts).then(res => res.data);
    }
  }
});

type ConfigSettingsT = {
  accrued_interest_enabled: boolean;
  maker_checker_enabled: boolean;
};

type GeneralSettingsT = {
  terms_and_conditions: string;
  password_expiry_period: number;
};

type LoanRecoverySettingsT = {
  enabled: boolean;
  threshold_duration: number;
  enabled_loan_product_ids: string[];
  savings_recovery_product_id: string;
};

type MemberSettingsT = {
  default_accounts: string[];
};

type SettingsT = {
  general?: GeneralSettingsT;
  loan_recovery?: LoanRecoverySettingsT;
  member_creation?: MemberSettingsT;
};

export type LoanDisbursalSettingsT = {
  number_required_disbursers: 1 | 2;
  disbursement_maker_checker_enabled: boolean;
};

export interface OrganisationT extends BaseModel<OrganisationT> {
  id: string;
  currency: string;
  name: string;
  users: UserT[];
  appRoles: AppRoleT[];
  configSettings: ConfigSettingsT;
  joiningFee: string;
  joiningFeeType: string;
  joiningFeeAccount: string;
  businessShortCode: string;
  branches: BranchT[];
  organisationSetting: OrganisationSettingT;
  smsBalance: number;
  smsBalancePercentage: number;
  totalSmsSent: string;
  bridgingLoanProductId: string;
  bridgingDepositProductId: string;
  shareCapitalProductId: string | number;
  settings: SettingsT;
  permalink: string;
  logoUrl?: string | null;
  isCrbCheckEnabled: boolean;
  update: (attributes: SettingsT) => Promise<void>;
  loanDisbursalSettings: LoanDisbursalSettingsT;
  ussdCode: number;
  fuzzyMemberCount: string;
  about: {
    description: string | null;
    homepage: string | null;
  };
  savingsInfo: {
    interestRate: string | null;
  };
  loanInfo: {
    interestRate: string | null;
    repaymentPeriodUnit: string | null;
  };
  memberRequirements: {
    requiredMonthlyContribution: string | null;
    requiredSharesDeposit: string | null;
  };
}
