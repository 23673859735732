import React from 'react';
import { FormattedMessage } from 'react-intl';
import { supportEmail } from '@kwara/components/src/text';

type FormatXMLElementFn<T, R = string | T | (string | T)[]> = (parts: Array<string | T>) => R;

export type TranslationValues = Record<
  string,
  | string
  | number
  | boolean
  | Date
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | React.ReactFragment
  | React.ReactPortal
  | FormatXMLElementFn<React.ReactNode, React.ReactNode>
  | null
  | undefined
>;

export type TranslationId = string;

type TextPropTypes = {
  id: TranslationId;
  values?: TranslationValues;
};

const EmailSupport = ({ translationId }) => (
  <a href={`mailto:${supportEmail}`} target="_blank" rel="noreferrer" style={{ color: 'black' }}>
    <Text id={translationId} />
  </a>
);

const valuesForErrorMessages = {
  here: <EmailSupport translationId="General.here" />,
  letUsKnow: <EmailSupport translationId="General.letUsKnow" />,
  customerSupport: <EmailSupport translationId="General.customerSupport" />
};

export default function Text(props: TextPropTypes) {
  const store = (Text as Record<string, any>).appStore || {};
  const currency = store.currency;

  /**
   * orgCurrency will be always available in the translations,
   * meaning its optional to pass it via translation json
   * unless you want to override
   */
  const values = {
    orgCurrency: currency,
    ...valuesForErrorMessages,
    ...props.values
  };

  return <FormattedMessage {...props} values={values} />;
}
