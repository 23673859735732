import { attr } from 'spraypaint';
import Base, { BaseModel } from '../Base';

type StatT = {
  active: number;
  inactive: number;
  runningTotal: number;
  date: string;
};
export const MemberBaseStats = Base.extend({
  static: {
    jsonapiType: 'dashboard_member_base_stats',
    endpoint: '/dashboard/member_base_stats'
  },
  attrs: {
    totalMonthlyMembers: attr()
  }
});

export interface MemberBaseStatsT extends BaseModel<MemberBaseStatsT> {
  totalMonthlyMembers: StatT[];
}
