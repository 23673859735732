import passport from './assets/passport.svg';
import signature from './assets/signature.svg';
import front from './assets/frontID.svg';
import back from './assets/backID.svg';
import kraPIN from './assets/kraPIN.svg';

export const passportDocuments = [
  {
    title: 'Register.Documents.passport.title',
    subtitle: 'Register.Documents.passport.subtitle',
    fileName: 'connectPassportSizePhoto',
    icon: passport,
    button: 'Register.Documents.button.browse.photos'
  },
  {
    title: 'Register.Documents.signature.title',
    subtitle: 'Register.Documents.signature.subtitle',
    fileName: 'memberSignature',
    icon: signature,
    button: 'Register.Documents.button.browse.photos'
  },
  {
    title: 'Register.Documents.travel.title',
    subtitle: 'Register.Documents.travel.subtitle',
    fileName: 'connectPassportDocumentPhoto',
    icon: front,
    button: 'Register.Documents.button.browse.photos'
  },
  {
    title: 'Register.Documents.krapin.title',
    subtitle: 'Register.Documents.krapin.subtitle',
    fileName: 'kraDocument',
    icon: kraPIN,
    button: 'Register.Documents.button.browse.photos'
  }
];

export const nationalDocuments = [
  {
    title: 'Register.Documents.passport.title',
    subtitle: 'Register.Documents.passport.subtitle',
    fileName: 'connectPassportSizePhoto',
    icon: passport,
    button: 'Register.Documents.button.browse.photos'
  },
  {
    title: 'Register.Documents.signature.title',
    subtitle: 'Register.Documents.signature.subtitle',
    fileName: 'memberSignature',
    icon: signature,
    button: 'Register.Documents.button.browse.photos'
  },
  {
    title: 'Register.Documents.front.title',
    subtitle: 'Register.Documents.front.subtitle',
    fileName: 'connectIdFront',
    icon: front,
    button: 'Register.Documents.button.browse.photos'
  },
  {
    title: 'Register.Documents.back.title',
    subtitle: 'Register.Documents.back.subtitle',
    fileName: 'connectIdBack',
    icon: back,
    button: 'Register.Documents.button.browse.photos'
  },
  {
    title: 'Register.Documents.krapin.title',
    subtitle: 'Register.Documents.krapin.subtitle',
    fileName: 'kraDocument',
    icon: kraPIN,
    button: 'Register.Documents.button.browse.photos'
  }
];
