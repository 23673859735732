// @flow

import * as React from 'react';

import first from 'lodash/fp/first';

import { USER_LOCKED } from '@kwara/models/src/lib/Api';
import { segmentTrack } from '@kwara/components/src/Segment';

import { useNewUserStore } from '../../models/NewUserStore';
import { StatusApprovalModal } from '../../components/StatusApprovalModal';

import { Login } from './Login';

const MODAL_STATUS = {
  USER_LOCKED: {
    type: 'bad',
    title: 'StatusApprovalModal.denied.title',
    subtitle: 'StatusApprovalModal.denied.subtitle',
    button: 'StatusApprovalModal.denied.button',
    next: '/'
  },
  USER_FOUR_DIGIT_PIN_NOT_SET: {
    type: 'good',
    title: 'StatusApprovalModal.setUpRequired.title',
    subtitle: 'StatusApprovalModal.setUpRequired.subtitle',
    button: 'StatusApprovalModal.setUpRequired.action',
    next: '/fourDigitPINUpdate'
  }
};
export const LoginFivePIN = ({ auth, history }) => {
  const { storePhone: phone, storeOrganisation: organisation } = useNewUserStore();
  const { permalink, name } = organisation;
  const [showModal, setShowModal] = React.useState(false);
  const [modalStatus, setModalStatus] = React.useState('USER_FOUR_DIGIT_PIN_NOT_SET');
  const [errors, setErrors] = React.useState(null);

  const submit = async data => {
    const { pin } = data;
    // Login with 5 PIN
    await auth
      .connectLogIn({
        phone,
        pin
      })
      .then(() => {
        segmentTrack('5 pin login', { phone, organisation: { permalink, name } });
        setShowModal(true);
      })
      .catch(({ errors }) => {
        const error = first(errors);

        if (error?.title.includes(USER_LOCKED)) {
          setModalStatus('USER_LOCKED');
          setShowModal(true);
        }
        setErrors(errors);
      });
  };

  return (
    <>
      <StatusApprovalModal
        show={showModal}
        type={MODAL_STATUS[modalStatus].type}
        titleId={MODAL_STATUS[modalStatus].title}
        subtitleId={MODAL_STATUS[modalStatus].subtitle}
        buttonId={MODAL_STATUS[modalStatus].button}
        onConfirm={() => {
          history.push(MODAL_STATUS[modalStatus].next);
          window.location.reload();
        }}
      />
      <Login history={history} length={5} errors={errors} submit={submit} />
    </>
  );
};
