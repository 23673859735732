import { useQuery } from 'react-query';

import { HttpErrorObject, ValueOf } from 'GlobalTypes';

import { _api } from '../../services';

export type State = ValueOf<typeof STATES>;
export type Membership = {
  id: string;
  type: string;
  attributes: {
    org_name: string;
    org_permalink: string;
    logo_url: string;
    state: State;
  };
};
export type MembershipsType = Array<Membership>;

enum STATES {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

const GET_MEMBERSHIPS_QUERY_KEY = 'v1GetMemberships';

function useGetMemberships() {
  const { isLoading, data, error } = useQuery<
    unknown,
    Array<HttpErrorObject>,
    Record<'data', Record<'data', MembershipsType>>
  >({
    queryFn: () => _api.httpGetRequest<MembershipsType>('/v1/memberships'),
    queryKey: GET_MEMBERSHIPS_QUERY_KEY
  });
  return { isLoading, data: data?.data?.data ?? null, error };
}

export { STATES, useGetMemberships };
