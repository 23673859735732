import * as React from 'react';
import cx from 'classnames';

import Button from '@kwara/components/src/Button';

import { Text } from '@kwara/components/src/Intl';

import { CardWrapper } from '../CardWrapper';

import iconAirtime from './assets/iconAirtime.svg';
import styles from './index.module.scss';

export function AirtimeCard() {
  return (
    <CardWrapper className={styles['container']}>
      <div className={styles['container__row1']}>
        <h3 className="mobile-text-medium kw-weight-bold">
          <Text id="Airtime.Card.title" />
        </h3>
        <p className="mobile-text-small kw-weight-regular">
          <Text id="Airtime.Card.subtitle" />
        </p>
      </div>
      <div className={styles['container__row2']}>
        <Button to="/airtime" className={cx('mobile-text-x-small', styles['buy-btn'])}>
          <Text id="Airtime.Card.button" />
        </Button>
        <img src={String(iconAirtime)} alt="" />
      </div>
    </CardWrapper>
  );
}
