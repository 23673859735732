import * as React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';

import { useOrganisations } from '../../models/request';
import { SearchableOrganisationsList } from './components/SearchableOrganisationsList';

export function OrganisationsList() {
  const request = useOrganisations();

  return (
    <Loadable {...request}>
      {data => <SearchableOrganisationsList organisations={data} errors={request.error} />}
    </Loadable>
  );
}
