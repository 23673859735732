import React, { useEffect } from 'react';

import Banner from '@kwara/components/src/Banner';

import { Text } from '@kwara/components/src/Intl';
import { segmentTrack } from '@kwara/components/src/Segment';
import { SubscribedPhoneField } from '@kwara/components/src/Form';
import { SubStepComponentProps } from '@kwara/components/src/Wizard/deprecated/Wizard';

export function PaymentOption({ StackChild }: SubStepComponentProps<{}>) {
  useEffect(() => {
    segmentTrack('Confirm Number Airtime page loaded');
  }, []);

  return (
    <StackChild>
      <Banner state="warning" innerClassName="mb3" text={<Text id="Airtime.Warning.chargesServiceSupport" />} />

      <SubscribedPhoneField required disableDropdown country="ke" name="paymentPhoneNumber" />
    </StackChild>
  );
}

export const validateConfig = {
  paymentPhoneNumber: {
    isRequired: () => true,
    paymentPhoneNumber: true
  }
};

PaymentOption.validateConfig = validateConfig;
