import { InferredModel } from 'GlobalTypes';
import { attr } from 'spraypaint';

import Base from '../../Base';

export const ProfitLossRow = Base.extend({
  static: {
    jsonapiType: 'abstract_profit_loss_report_rows'
  },
  attrs: {
    glCode: attr(),
    glName: attr(),
    accountType: attr(),
    balance: attr()
  }
});

export interface ProfitLossRowT extends Omit<InferredModel<typeof ProfitLossRow>, 'errors'> {
  glCode: string;
  glName: string;
  accountType: string;
  balance: number;
}
