import { useState, useMemo, useCallback, useEffect } from 'react';
import get from 'lodash/fp/get';
import { useHistory } from 'react-router-dom';

import { KeyOf, ValueOf, ModelError } from 'GlobalTypes';

import Auth from '@kwara/models/src/lib/Auth';
import cameliseObjectKeys from '@kwara/models/src/lib/cameliseObjectKeys';

import { segmentTrack } from '@kwara/components/src/Segment';
import { useBoolean } from '@kwara/lib/src/hooks/useBoolean';

import { useNewUserStore } from '../../../models/NewUserStore';
import { OPEN_MODAL, ERROR, MODAL_STATUS, STATUS_NEXT, USER_STATUS, ModalStatus } from '../../../lib/userStatus';

export enum SCREEN_TYPES {
  LOGIN = 'login',
  REGISTER = 'register'
}

export const SCREEN_CONFIGS = {
  login: {
    title: 'Start.login.title',
    subtitle: 'Start.login.subtitle',
    directions: 'Start.login.directions',
    button: 'Start.login.button',
    footer: 'Start.login.footer',
    footerLink: { text: 'Start.login.footerLink', path: '/register' }
  },
  register: {
    title: 'Start.register.title',
    subtitle: 'Start.register.subtitle',
    directions: null,
    button: 'Start.register.button',
    footer: 'Start.register.footer',
    footerLink: { text: 'Start.register.footerLink', path: '/' }
  }
};

type UseUserStatusReturnee = {
  isProcessing: boolean;
  showModal: boolean;
  errors: ModelError[];
  modalStatus: ValueOf<ModalStatus>;
  onToggle: () => void;
  onCancel: () => void;
  onSubmit(auth: Auth, screenType: ValueOf<typeof SCREEN_TYPES>): (data: { phone: string }) => void;
};

export function useUserStatus(): UseUserStatusReturnee {
  const history = useHistory();
  const [errors, setErrors] = useState<ModelError[]>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [modalStatusKey, setModalStatusKey] = useState<KeyOf<typeof MODAL_STATUS>>(null);
  const { setStoreOrganisation, setUserSignupStatus, setPayLaterEnabled, setStorePhone } = useNewUserStore();
  const [showModal, { toggle, setToTrue: setShowModalToTrue }] = useBoolean();

  const modalStatus = MODAL_STATUS[modalStatusKey];

  useEffect(() => setPayLaterEnabled(false), [setPayLaterEnabled]);

  /**
   * onToggle
   */
  const onToggle = useCallback(() => {
    const next = modalStatus.next;

    toggle();

    if (next) history.push(next);
  }, [history, modalStatus, toggle]);

  /**
   * onCancel
   */
  const onCancel = useCallback(() => {
    toggle();

    const cancelRoute = modalStatus.cancelRoute;

    if (cancelRoute) history.push(cancelRoute);
  }, [history, modalStatus, toggle]);

  /**
   * unknownNumberHandler
   */
  function unknownNumberHandler(phone_number: string) {
    segmentTrack('New user', { phone_number });
  }

  /**
   * onOpenModal
   */
  const onOpenModal = useCallback(
    (status: KeyOf<typeof MODAL_STATUS>) => {
      setShowModalToTrue();
      setModalStatusKey(status);
    },
    [setShowModalToTrue]
  );

  /**
   * submit
   */
  const asyncSubmit = useCallback(
    async ({ phone: phone_number }: { phone: string }, auth: Auth, screen = SCREEN_TYPES.LOGIN) => {
      setIsProcessing(true);

      try {
        const response = await auth.signupStatus({ phone_number });
        const {
          attributes: { status: plainStatus, organisation_data }
        } = response?.data;
        let status = plainStatus;

        if (organisation_data) setStoreOrganisation(cameliseObjectKeys(organisation_data));

        if (status === USER_STATUS.UNKNOWN_NUMBER) {
          unknownNumberHandler(phone_number);
          /**
           * Temporal fix to handle new user coming from Registration Screen
           */
          if (screen === SCREEN_TYPES.REGISTER) status = `REGISTER_${status}`;
        }
        setUserSignupStatus(status);

        if (get(status, STATUS_NEXT) === ERROR) setErrors(null);
        else if (get(status, STATUS_NEXT) === OPEN_MODAL && MODAL_STATUS[status] != undefined) onOpenModal(status);
        else history.push(get(status, STATUS_NEXT));
      } catch ({ errors }) {
        setErrors(errors as ModelError[]);
      } finally {
        setIsProcessing(false);
      }
    },
    [history, onOpenModal, setStoreOrganisation, setUserSignupStatus]
  );

  const onSubmit = useCallback(
    (auth: Auth, screenType: ValueOf<typeof SCREEN_TYPES>) => {
      return function(data: { phone: string }) {
        setStorePhone(get('phone', data));
        asyncSubmit(data, auth, screenType);
      };
    },
    [asyncSubmit, setStorePhone]
  );

  return useMemo(
    () => ({
      isProcessing,
      showModal,
      errors,
      modalStatus,
      onToggle,
      onCancel,
      onSubmit
    }),
    [errors, isProcessing, modalStatus, onCancel, onSubmit, onToggle, showModal]
  );
}
