import React, { useCallback, useRef, useEffect, useState } from 'react';
import cx from 'classnames';

import { If } from '@kwara/components/src/If/If';
import { Link } from '@kwara/components/src/Link';
import { Text } from '@kwara/components/src/Intl';
import { Child } from '@kwara/components/src/Stack';
import { useInterval } from '@kwara/models/src/models/request/hooks';
import { SubscribedPinInputField, SIX_DIGIT_OTP } from '@kwara/components/src/Form';

import { Identity } from './Identity';

import auth from '../../../lib/auth';
import styles from './EnterOTP.module.scss';

const SIXTY_SECONDS = 60;
const ONE_SECOND = 1000;

export type Data = {
  phone: string;
  otp?: string;
  device_token?: string;
  id_type?: string;
  id_number?: string;
};

type EnterOTPPropTypes = {
  data: Data;
  StackChild: typeof Child;
  onChange(data: Record<'device_token', string>): Promise<unknown>;
};

export function EnterOTP({ StackChild, onChange, data }: EnterOTPPropTypes) {
  const [timerCount, setTimerCount] = useState(SIXTY_SECONDS);
  const [resendOTPDisabled, setResendOTPDisabled] = useState(true);
  const [showResendDialog, setShowResendDialog] = useState(false);
  const otpInputRef = useRef<typeof SubscribedPinInputField | null>(null);

  function onCompleteCountdown() {
    setTimerCount(SIXTY_SECONDS);
    setShowResendDialog(true);
    setResendOTPDisabled(false);
  }

  useInterval(() => {
    if (timerCount > 0) setTimerCount(timerCount - 1);
    else onCompleteCountdown();
  }, ONE_SECOND);

  const addDash = useCallback(() => {
    if (otpInputRef.current) {
      const input = otpInputRef.current.elements[3].input;
      const span = document.createElement('span');
      span.textContent = '-';
      span.className = 'mr1';
      input.parentNode.insertBefore(span, input);
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(addDash, []);

  async function onResendOTP() {
    setTimerCount(SIXTY_SECONDS);
    setResendOTPDisabled(true);
    await Identity.verify(data, onChange);
  }

  return (
    <StackChild>
      <div className={cx(styles.Header, 'mb2 w-80 grey-400 mobile-text-small')}>
        <p className="mv0">
          <Text id="SecureFourPinUpdate.EnterOTP.subtitle" />
        </p>

        <p className="mv0">
          <Text id="SecureFourPinUpdate.EnterOTP.wait" />
        </p>
      </div>

      <div className={'flex flex-column'}>
        <SubscribedPinInputField
          ref={otpInputRef}
          name="otp"
          defaultSecret={false}
          inputStyle={{ width: 45, height: 45 }}
          length={SIX_DIGIT_OTP}
          required
        />

        <If
          condition={showResendDialog}
          do={
            <span className="flex">
              <Link onClick={onResendOTP} type="primary" underline={false} disabled={resendOTPDisabled} active>
                <Text id="SecureFourPinUpdate.EnterOTP.resend.action" />
              </Link>
            </span>
          }
        />
      </div>
    </StackChild>
  );
}

EnterOTP.verify = ({ phone, otp, device_token }: Data) => {
  const payload = { data: { attributes: { phone_number: phone, otp, device_token } } };

  return auth.verifyOTPV1(payload).catch(({ errors }) => {
    throw errors;
  });
};

EnterOTP.validateConfig = {
  otp: {
    isRequired: () => true,
    minlength: SIX_DIGIT_OTP
  }
};
