// @flow

import * as React from 'react';

import { Date, Text } from '@kwara/components/src/Intl';
import { ProfilePhoto } from '@kwara/components/src/ProfilePhoto';

import Collapsible from '../../components/Collapsible';
import { StatisticInfo } from './StatisticInfo';
import styles from './index.module.scss';

export const PersonalDetails = ({ member, className }) => (
  <Collapsible className={className} summary={<Text id="MemberDetail.personalDetails" />}>
    <section className={styles.InfoSection}>
      <StatisticInfo title={<Text id="MemberDetail.PersonalDetails.fullName" />} value={member.fullName()} />

      <ProfilePhoto memberId={member.id} attachments={member.attachments} size={'small'} isExpandable={false} />

      <StatisticInfo title={<Text id="MemberDetail.PersonalDetails.memberId" />} value={member.id} />

      <StatisticInfo
        title={<Text id="Register.About.Gender.select.title" />}
        value={member.gender ? <Text id={`Register.About.Gender.${member.gender}`} /> : null}
      />
      <StatisticInfo
        title={<Text id="MemberDetail.PersonalDetails.dateOfBirth" />}
        value={<Date value={member.dateOfBirth} />}
      />
      <StatisticInfo
        title={<Text id="MemberDetail.PersonalDetails.maritalStatus" />}
        value={
          member.maritalStatus ? (
            <Text id={`MemberDetail.PersonalDetails.maritalStatus.${member.maritalStatus}`} />
          ) : null
        }
      />
      <StatisticInfo title={<Text id="MemberDetail.PersonalDetails.phone" />} value={member.phoneNumber} />
      <StatisticInfo
        title={<Text id="MemberDetail.PersonalDetails.secondaryPhone" />}
        value={member.secondaryPhoneNumber}
      />
      <StatisticInfo title={<Text id="MemberDetail.PersonalDetails.email" />} breakWord={true} value={member.email} />
      <StatisticInfo
        title={<Text id="MemberDetail.PersonalDetails.postalAddress" />}
        value={member.address('postal')}
      />
      <StatisticInfo
        title={<Text id="MemberDetail.PersonalDetails.physicalAddress" />}
        value={member.address('physical')}
      />
    </section>
  </Collapsible>
);
