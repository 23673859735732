// @flow

import * as React from 'react';
import posed from 'react-pose';
import classnames from 'classnames';

import Asset, { getAssetDataUrlById } from '@kwara/components/src/Asset';

import styles from './index.module.css';

type Props = {
  children: ?React.Node,
  summary: React.Node
};

type ControlledCollapsibleProps = Props & {
  open: boolean,
  onClick: () => any,
  className?: string
};

// TODO: Don't rely on colour of "ChevronDown" SVG glyph.
//
// Safari macOS and iOS has a lot of issues with CSS transforms
// on embedded SVG elements. The SVG gets skewed, or does not
// rotate at all.
//
// We workaround this by using fetching data:uri of the asset
// and using an <img /> tag. The downside of this is that the SVG
// file must have the correct colour (grey-300) set within it
// as the colour of an SVG in an <img /> tag can't be changed.
//
const Arrow = posed.img({
  open: { rotate: 0 },
  closed: { rotate: 180 }
});

export const ControlledCollapsible = ({
  children,
  open = false,
  onClick = () => {},
  summary,
  className
}: ControlledCollapsibleProps) => {
  const pose = open ? 'open' : 'closed';
  const altText = open ? 'Close' : 'Open';

  return (
    <div className={styles.Collapsible}>
      <div onClick={onClick} className={classnames(styles.summary, 'flex', className)} aria-label="Expand">
        {summary}

        <Arrow
          className={classnames(styles.toggle, 'ml-auto')}
          pose={pose}
          src={getAssetDataUrlById(Asset.Glyphs.ChevronUp)}
          alt={altText}
        />
      </div>
      {open ? <div>{children}</div> : null}
    </div>
  );
};

class Collapsible extends React.Component<Props, *> {
  state = {
    open: false
  };

  toggle = () => {
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    return (
      <ControlledCollapsible
        onClick={this.toggle}
        open={this.state.open}
        summary={this.props.summary}
        className={this.props.className}
      >
        {this.props.children}
      </ControlledCollapsible>
    );
  }
}

export default Collapsible;
