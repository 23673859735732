// @flow

import * as React from 'react';
import cx from 'classnames';

import CardContainer from '@kwara/components/src/CardContainer';

import styles from './index.module.scss';

export const Panel = ({ children, className }: { children: React.Node, className?: string }) => (
  <CardContainer className={cx(styles.Panel, className)} full flex={false}>
    {children}
  </CardContainer>
);
