import * as React from 'react';

import { Portal } from '@kwara/components/src/Portal/Portal';
import { useComposeRefs } from '@kwara/lib/src/hooks/useComposeRefs';

type PrimitiveDivProps = React.ComponentPropsWithoutRef<'div'>;
type ModalWrapperElement = React.ElementRef<'div'>;
interface ModalWrapperPropTypes extends PrimitiveDivProps {
  onClose?: () => void;
  elementType?: string;
  closeOnOutClick?: boolean;
  isOpen?: boolean;
  meta?: string;
  exemptMouseEventRefs?: React.MutableRefObject<any>[];
}

export const ModalWrapper = React.forwardRef<ModalWrapperElement, ModalWrapperPropTypes>(function ModalWrapper(
  { onClose, elementType, children, meta, exemptMouseEventRefs, closeOnOutClick = false, isOpen = false, ...restProps },
  forwardedRef
) {
  const modalWrapperRef = React.useRef<HTMLDivElement>(null);
  const composedRefs = useComposeRefs<HTMLDivElement>(modalWrapperRef, forwardedRef);

  const mouseDownEventHandler = React.useCallback(
    (ev: React.SyntheticEvent | Event) => {
      if (!isOpen || !onClose || !closeOnOutClick) return;

      const shouldExemptNode =
        exemptMouseEventRefs && exemptMouseEventRefs.some(ref => ref.current.contains(ev.target as Node));

      if (!shouldExemptNode && !modalWrapperRef.current?.contains?.(ev.target as Node)) onClose();
    },
    [closeOnOutClick, isOpen, onClose, exemptMouseEventRefs]
  );

  React.useEffect(() => {
    document.addEventListener('mousedown', mouseDownEventHandler);

    return () => {
      document.removeEventListener('mousedown', mouseDownEventHandler);
    };
  }, [mouseDownEventHandler]);

  return (
    <Portal elementType={elementType}>
      <div
        role="dialog"
        aria-modal="true"
        title={meta}
        {...restProps}
        ref={composedRefs}
        onKeyDown={ev => {
          if (onClose && ev.key === 'Escape') onClose();
        }}
      >
        {children}
      </div>
    </Portal>
  );
});
