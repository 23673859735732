import * as React from 'react';

import { If } from '@kwara/components/src/If/If';
import { Text, Currency } from '@kwara/components/src/Intl';

import { getCashbackAmount, FORMATTED_MIN_CASHBACK, MIN_CASHBACKABLE_DEPOSIT } from '../../../models/AirtimePurchases';

function getCashback(amount?: string): number {
  if (!amount) return 0;

  const formattedAmount = getCashbackAmount(Number(amount)).formatted;
  const cashback = formattedAmount < FORMATTED_MIN_CASHBACK ? 0 : formattedAmount;

  return cashback;
}

export function AmountInfo({ amount, hasCashback }: { amount?: string; hasCashback?: boolean }) {
  return (
    <>
      <If
        condition={!amount}
        do={
          <p className="pa0 ma0 mobile-text-small">
            <Text id="Airtime.Card.startDepositInfo" />
          </p>
        }
        else={
          <If
            condition={Number(amount) < MIN_CASHBACKABLE_DEPOSIT}
            do={
              <p className="pa0 ma0 mobile-text-small">
                <Text
                  id="Airtime.Card.cashbackDepositInfo"
                  values={{ incrementAmount: MIN_CASHBACKABLE_DEPOSIT - Number(amount) }}
                />
              </p>
            }
          />
        }
      />

      <If
        condition={amount != undefined}
        do={
          <div className="pt2 kw-weight-bold black flex flex-column">
            <h3 className="pa0 ma0 mobile-text-medium">
              <Text id="Airtime.buyingFor.info.youllget" />
            </h3>

            <ul className="pa0 pl3 ma0 mobile-text-medium">
              <li>
                <Text
                  id="Airtime.buyingFor.info.airtime"
                  values={{
                    amount: <Currency format="currency" value={amount} />
                  }}
                />
              </li>

              <If
                condition={hasCashback}
                do={
                  <li>
                    <Text
                      id="Airtime.buyingFor.info.main"
                      values={{
                        amount: <Currency format="currency" value={getCashback(amount)} />
                      }}
                    />
                  </li>
                }
              />
            </ul>
          </div>
        }
      />
    </>
  );
}
