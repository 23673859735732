import React from 'react';

import { Swipeable } from '../Swipeable';
import { ReferralCard } from '../ReferralCard';
import { AirtimeCard } from '../AirtimeCard';

type FeaturesCarouselProps = {
  referralData: string;
};
export function FeaturesCarousel({ referralData }: FeaturesCarouselProps) {
  return (
    <Swipeable slidesPerView={1.12}>
      <AirtimeCard />
      <ReferralCard referralData={referralData} />
    </Swipeable>
  );
}
