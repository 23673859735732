// @flow
import * as React from 'react';
import classnames from 'classnames';

import Actionable from '@kwara/components/src/Actionable';
import Asset, { type GlyphCols } from '@kwara/components/src/Asset';

import styles from './ActionButton.module.css';
import glyphStyles from './index.module.scss';

const Types = {
  cancel: 'cancel',
  collapse: 'collapse',
  delete: 'delete',
  download: 'download',
  edit: 'edit',
  expand: 'expand',
  go: 'go',
  hidden: 'hidden',
  select: 'select',
  view: 'view',
  more: 'more',
  spinner: 'spinner'
};

type Props = {
  className?: string,
  type?: $Keys<typeof Types>,
  col?: GlyphCols,
  size?: string,
  hideBorder?: boolean,
  disabled?: boolean,
  'aria-label'?: string
};

export const typeToIcon = {
  add: Asset.Glyphs.Add,
  arrowDown: Asset.Glyphs.ArrowDown,
  cancel: Asset.Glyphs.Cross,
  expand: Asset.Glyphs.ChevronDown,
  collapse: Asset.Glyphs.ChevronUp,
  go: Asset.Glyphs.ArrowRight,
  back: Asset.Glyphs.ArrowLeft,
  backMobile: Asset.Glyphs.ArrowBackMobile,
  downloadMobile: Asset.Glyphs.Download,
  select: Asset.Glyphs.Check,
  view: Asset.Glyphs.Eye,
  hidden: Asset.Glyphs.EyeClosed,
  edit: Asset.Glyphs.Pencil,
  delete: Asset.Glyphs.TrashBin,
  downMobile: Asset.Glyphs.DownMobile,
  upMobile: Asset.Glyphs.UpMobile,
  down: Asset.Glyphs.ChevronDown,
  download: Asset.Glyphs.ArrowDown,
  more: Asset.Glyphs.EllipsisVertical,
  spinner: Asset.Glyphs.Spinner,
  trashBin: Asset.Glyphs.TrashBin
};

const ActionButton = ({
  className,
  type = 'go',
  size = 'default',
  col,
  hideBorder = false,
  disabled,
  ...props
}: Props) => {
  const actionButtonStyling = size === 'tiny' ? styles.ActionButtonTiny : styles.ActionButton;

  const classes = [
    actionButtonStyling,
    'link ba b--light-grey-500 bg-transparent flex items-center justify-center',
    hideBorder ? 'bn' : null
  ];

  return (
    <Actionable
      {...props}
      aria-label={props['aria-label'] || type}
      className={classnames(classes, className, {
        [styles.disabled]: disabled
      })}
      disabled={disabled}
    >
      <Asset
        className={glyphStyles[size]} //glyphStyles['default'] returns undefined
        id={typeToIcon[type]}
        col={col || Asset.Colours.indigo500}
        disabled={disabled}
      />
    </Actionable>
  );
};

ActionButton.Types = Types;

export default ActionButton;
