import React from 'react';
import cx from 'classnames';

import Asset from '@kwara/components/src/Asset';
import { Text } from '@kwara/components/src/Intl';

import styles from './index.module.scss';

type NewLoanDetailsListProps = {
  detailsContent: React.Node[]
};

export const NewLoanDetailsList = ({ detailsContent }: NewLoanDetailsListProps) => {
  return detailsContent?.map((element, i) => (
    <div className={'flex mv1'} key={i}>
      <div className={cx(styles.Key, 'flex items-center mh1')}>
        <Asset id={Asset.Glyphs.Kwara} />
        <div className="ml1">{element}</div>
      </div>
    </div>
  ));
};

type TwoColumnsDetailsProps = {
  details: { key: string, value: string, labelId: string }[]
};

export const TwoColumnsDetails = ({ details }: TwoColumnsDetailsProps) => {
  return details.map((element, i) => (
    <div className={cx(styles.TwoColumnsDetailsRow, 'flex mv1')} key={i}>
      <div className={cx(styles.Key, 'flex items-center mh1')}>
        <Asset id={Asset.Glyphs.Kwara} />
        <div className="ml1">
          <Text id={element.key} />:
        </div>
      </div>
      <div>
        <span aria-label={element.labelId}>{element.value}</span>
      </div>
    </div>
  ));
};
