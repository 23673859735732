// @flow
import * as React from 'react';
import cx from 'classnames';

import Asset from '@kwara/components/src/Asset';
import { Text, Currency } from '@kwara/components/src/Intl';

import { type ReferralData, ReferralCode, PointsEarned } from '.';
import illustration from './illustration.svg';
import styles from './index.module.scss';

type Props = {
  onClose: MouseEvent,
  referralData: ReferralData
};

export const InfoModal = ({ onClose, referralData }: Props) => {
  const { referralScore, referralCode, referralAmountPerPoint } = referralData;

  return (
    <div className="flex flex-column pa4">
      <div className="flex">
        <div className="mobile-text-medium mobile-neutral-700">
          <Text id="Referral.InfoModal.title" />
        </div>
        <div className="ml-auto" onClick={onClose} aria-label="Close">
          <Asset id={Asset.Glyphs.CrossCircle} className={styles.CloseButton} col={Asset.Colours.black} />
        </div>
      </div>
      <p className="mobile-text-small mobile-neutral-500">
        <Text
          id="Referral.InfoModal.description"
          values={{ amount: <Currency format={'currency'} value={referralAmountPerPoint} /> }}
        />
      </p>
      <img src={illustration} alt="referral" className={cx('center ', styles.InfoModalImg)} />
      <div className="flex justify-between mobile-text-small">
        <div className="flex flex flex-column mobile-primary-500">
          <Text id="Referral.Code.title" />
          <ReferralCode code={referralCode} className="bg-mobile-primary-50 mobile-primary-500" />
        </div>
        <div className="flex flex flex-column mobile-primary-200 items-end">
          <Text id="Referral.InfoModal.Points.title" />
          <PointsEarned points={referralScore} className="bg-mobile-primary-50 mobile-primary-500" />
        </div>
      </div>
      <div className="mobile-text-small mobile-neutral-400 tc pt3">
        <Text id="Referral.Point.label" />
        <Currency format={'currency'} value={referralAmountPerPoint} />
      </div>
    </div>
  );
};
