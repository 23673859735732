import { useState, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useInterval } from '@kwara/models/src/models/request/hooks';

const STARTING_PERCENT = 20;
const MAX_PERCENT = 100;
const INTERVAL = 1000;

type UseProgressiveLoaderArg = {
  loaderRef: React.MutableRefObject<HTMLDivElement>;
  loading?: boolean;
  route?: string;
};

export function useProgressiveLoader(arg: UseProgressiveLoaderArg) {
  const { loaderRef, route, loading } = arg;

  const [isLoading, setIsLoading] = useState(true);
  const [percent, setPercent] = useState(STARTING_PERCENT);
  const history = useHistory();

  const interval = percent <= MAX_PERCENT ? INTERVAL : null;

  /**
   * We update to route to track the current
   * step within the ageGroup route
   */
  useLayoutEffect(() => {
    if (route) history.push(route);
  }, [history, route]);

  useInterval(() => {
    if (!loaderRef.current) return;

    if (!loading && percent === MAX_PERCENT) setIsLoading(false);

    if (percent === MAX_PERCENT && loading) {
      loaderRef.current.style.width = `${STARTING_PERCENT}%`;
      setPercent(STARTING_PERCENT);
    }

    if (percent < MAX_PERCENT) {
      loaderRef.current.style.width = `${percent + STARTING_PERCENT}%`;
      setPercent(prevPercent => prevPercent + STARTING_PERCENT);
    }
  }, interval);

  return {
    isLoading,
    percent
  };
}
